import { Contact, Customer, CustomerExperienceTicket } from '@core/api';
import { CustomerExperienceTicketActionTypes, CustomerExperienceTicketActions } from './customer-experience-ticket.action';

export interface CustomerExperienceTicketHeaderState {
  channelInfo: string;
  contacts: Contact[];
  phoneNumber?: string;
  customers: Customer[];
  backgroundColor?: string;
  hasSubStatus: boolean;
  contactName?: string;
  contactId?: string;
  mailName?: string;
  cxChannelTypeId: string;
  ticket: CustomerExperienceTicket;
}

export const customerExperienceTicketHeaderState: CustomerExperienceTicketHeaderState = {
  channelInfo: null,
  contacts: [],
  phoneNumber: null,
  customers: [],
  backgroundColor: null,
  hasSubStatus: false,
  contactName: null,
  contactId: null,
  mailName: null,
  cxChannelTypeId: null,
  ticket: null
};

export function customerExperienceTicketHeaderReducer(
  state = customerExperienceTicketHeaderState, action: CustomerExperienceTicketActions
): CustomerExperienceTicketHeaderState {
  switch (action.type) {
    case CustomerExperienceTicketActionTypes.SET_CUSTOMER_EXPERIENCE_TICKET_HEADER:
      return {
        ...state,
        channelInfo: action.headerInfo.channelInfo,
        contacts: action.headerInfo.contacts,
        customers: action.headerInfo.customers,
        phoneNumber: action.headerInfo.phoneNumber,
        backgroundColor: getRandomColor(),
        contactId: action.headerInfo.contactId,
        contactName: action.headerInfo.contactName,
        mailName: action.headerInfo.mailName,
        cxChannelTypeId: action.headerInfo.cxChannelTypeId,
        ticket: action.headerInfo.ticket
      };
    case CustomerExperienceTicketActionTypes.SET_CUSTOMER_EXPERIENCE_TICKET_CLEAR_HEADER:
      return {
        ...state,
        channelInfo: null,
        contacts: [],
        phoneNumber: null,
        customers: [],
        backgroundColor: null,
        contactId: null,
        contactName: null,
        mailName: null,
        cxChannelTypeId: null,
        ticket: null
      };
    case CustomerExperienceTicketActionTypes.SET_CUSTOMER_EXPERIENCE_TICKET_SUB_STATUS:
      return {
        ...state,
        hasSubStatus: action.payload.hasSubStatus
      };
    default:
      return {
        ...state
      };

  }
}

const XOOI_COLORS = ['#564c98', '#4474b5', '#30529b', '#68b9c8'];

function getRandomColor(): string {
  return XOOI_COLORS[Math.floor(Math.random() * XOOI_COLORS.length)];
}
