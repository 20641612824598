<div class="max-w-full">
  <div class="card overflow-auto">
    <div class="bg-app-bar h-14 border-b sticky left-0 h" fxLayout="row" fxLayoutAlign="start center"
      [class.cursor-pointer]="collapsible" (click)="toggleCollapse($event)" [ngClass]="showIcon ? 'px-3' : 'px-6'">
      <div *ngIf="showIcon" class="rounded-full h-9 w-9 flex items-center justify-center bg-yellow-500"
        style="margin-right: 16px">
        <mat-icon class="opacity-50 text-white w-9" [icIcon]="pcTicket"></mat-icon>
      </div>
      <h2 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l" [class.border-r]="!collapse"
        fxHide.xs fxFlex="none" *ngIf="collapsible"><span>{{ 'CX.TICKETS'
          | translate }}</span></h2>
      <div class="bg-card rounded-full border px-4 ignore-toggle-click" fxFlex="400px" fxHide.xs fxFlex.lt-md="auto"
        fxLayout="row" fxLayoutAlign="start center" (click)="collapse = false">
        <ic-icon [icIcon]="icSearch" size="20px"></ic-icon>
        <input type="text" class="px-4 py-3 border-0 outline-none w-full bg-card" [formControl]="searchCtrl"
          [placeholder]="'GENERAL.SEARCH_ELLIPSIS' | translate">
      </div>
      <button mat-icon-button type="button" *ngIf="collapsible"><mat-icon
          [icIcon]="collapse ? icKeyboardArrowDown : icKeyboardArrowUp"></mat-icon>
      </button>

      <span fxFlex></span>

      <button mat-icon-button type="button" matTooltip="{{ 'CX.CLEAR_ALL_TICKET_PREVIEWS' | translate }}"
        (click)="clearPreviews()" *ngIf="hasPreviews">
        <mat-icon [icIcon]="icLayersClear"></mat-icon>
      </button>

      <net-mat-table-column-filter [columns]="columns" [tableColumnFilterConfig]="tableColumnFilterConfig">
      </net-mat-table-column-filter>
    </div>

    <div [hidden]="collapsible && collapse">

      <table @stagger [dataSource]="dataSource" class="w-full" mat-table matSort>
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <!-- code Column -->
          <ng-container *ngIf="column.property === 'ticketCode'" [matColumnDef]="column.property">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>
              {{ column.label }}
              <net-mat-header-filter *ngIf="column.filterable" [column]="column" [dataSource]="dataSource"
                [key]="column.key" [type]="matHeaderFilterType.search"></net-mat-header-filter>
            </th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{ row?.ticket['code']
              }}</td>
          </ng-container>

          <!-- contact Column -->
          <ng-container *ngIf="column.property === 'contact'" [matColumnDef]="column.property">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>
              {{ column.label }}
              <net-mat-header-filter *ngIf="column.filterable" [column]="column" [dataSource]="dataSource"
                [key]="column.key" [type]="matHeaderFilterType.search"></net-mat-header-filter>
            </th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              {{ row?.ticket?.contactName || row?.ticket?.contactInfo || '' }}
            </td>
          </ng-container>

          <!-- channelType Column -->
          <ng-container *ngIf="column.property === 'cxChannelType'" [matColumnDef]="column.property">
            <th *matHeaderCellDef mat-header-cell>
              {{ column.label }}
              <net-mat-header-filter *ngIf="column.filterable" [column]="column" [dataSource]="dataSource"
                [key]="column.key" [selectSource]="dataSource.$channelTypes"
                [type]="matHeaderFilterType.select"></net-mat-header-filter>
            </th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              <ng-container *ngIf="row?.ticket.cxChannel?.cxChannelType">
                  <ng-container [ngSwitch]="row?.ticket.cxChannel?.cxChannelType.cxChannelTypeId">
                    <mat-icon *ngSwitchCase="cxChannelType.WHATSAPP" [icIcon]="getChannelIcon(row?.ticket.cxChannel?.cxChannelType.cxChannelTypeId)"></mat-icon>
                    <mat-icon *ngSwitchCase="cxChannelType.EMAIL" [icIcon]="getChannelIcon(row?.ticket.cxChannel?.cxChannelType.cxChannelTypeId)" color="#0078d7"></mat-icon>
                    <mat-icon *ngSwitchCase="cxChannelType.FACEBOOK" [icIcon]="getChannelIcon(row?.ticket.cxChannel?.cxChannelType.cxChannelTypeId)"></mat-icon>
                    <mat-icon *ngSwitchCase="cxChannelType.INSTAGRAM" [icIcon]="getChannelIcon(row?.ticket.cxChannel?.cxChannelType.cxChannelTypeId)"></mat-icon>
                  </ng-container>
              </ng-container>
            </td>
          </ng-container>

          <!-- channelName Column -->
          <ng-container *ngIf="column.property === 'cxChannelName'" [matColumnDef]="column.property">
            <th *matHeaderCellDef mat-header-cell>
              {{ column.label }}
              <net-mat-header-filter *ngIf="column.filterable" [column]="column" [dataSource]="dataSource"
                [key]="column.key" [selectSource]="dataSource.$channels"
                [type]="matHeaderFilterType.select"></net-mat-header-filter>
            </th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              {{ row?.ticket.cxChannel?.name }}
            </td>
          </ng-container>

          <!-- subject Column -->
          <ng-container *ngIf="column.property === 'subject'" [matColumnDef]="column.property">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>
              {{ column.label }}
              <net-mat-header-filter *ngIf="column.filterable" [column]="column" [dataSource]="dataSource"
                [key]="column.key" [type]="matHeaderFilterType.search"></net-mat-header-filter>
            </th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{ row?.ticket[column.property]
              }}</td>
          </ng-container>

          <!-- Main Category Column -->
          <ng-container *ngIf="column.property === 'mainCategory'" [matColumnDef]="column.property">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>
              {{ column.label }}
              <net-mat-header-filter *ngIf="column.filterable" [column]="column" [dataSource]="dataSource"
                [key]="column.key" [selectSource]="dataSource.$mainCategories"
                [type]="matHeaderFilterType.select"></net-mat-header-filter>
            </th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              <div class="flex items-center justify-start">
                <span [style.backgroundColor]="row?.ticket.cxMainCategory?.backgroundColor" class="color-span"></span>
                <span>{{ row?.ticket.cxMainCategory?.name ?? '' }}</span>
              </div>
            </td>
          </ng-container>

          <!-- Category Column -->
          <ng-container *ngIf="column.property === 'category'" [matColumnDef]="column.property">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>
              {{ column.label }}
              <net-mat-header-filter *ngIf="column.filterable" [column]="column" [dataSource]="dataSource"
                [key]="column.key" [selectSource]="dataSource.$categories"
                [type]="matHeaderFilterType.select"></net-mat-header-filter>
            </th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              <div class="flex items-center justify-start">
                <span [style.backgroundColor]="row?.ticket.cxCategory?.backgroundColor" class="color-span"></span>
                <span>{{ row?.ticket.cxCategory?.name ?? '' }}</span>
              </div>
            </td>
          </ng-container>

          <!-- Priority Column -->
          <ng-container *ngIf="column.property === 'priority'" [matColumnDef]="column.property">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>
              {{ column.label }}
              <net-mat-header-filter *ngIf="column.filterable" [column]="column" [dataSource]="dataSource"
                [key]="column.key" [selectSource]="dataSource.$priorities"
                [type]="matHeaderFilterType.select"></net-mat-header-filter>
            </th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              <span *ngIf="row.ticket?.ticketPriority" class="net-label-status"
                [style.backgroundColor]="row?.ticket.ticketPriority?.color">
                {{ 'TicketPriority.'+row.ticket?.ticketPriority?.name | translate }}
              </span>
            </td>
          </ng-container>

          <!-- creationDate Column -->
          <ng-container *ngIf="column.property === 'ticketDate'" [matColumnDef]="column.property">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>
              {{ column.label }}
              <net-mat-header-filter *ngIf="column.filterable" [type]="matHeaderFilterType.date" [column]="column"
                [dataSource]="dataSource" [key]="column.key"></net-mat-header-filter>
            </th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{ row.ticket['createdOn']
              |
              momentFormat : 'DD MMMM YYYY HH:mm'}}</td>
          </ng-container>

          <!-- Completion Date Column -->
          <ng-container *ngIf="column.property === 'completionDate'" [matColumnDef]="column.property">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>
              {{ column.label }}
              <net-mat-header-filter *ngIf="column.filterable" [type]="matHeaderFilterType.date" [column]="column"
                [dataSource]="dataSource" [key]="column.key"></net-mat-header-filter>
            </th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{ row?.ticket[ column.property
              ] |
              momentFormat : 'DD MMMM YYYY HH:mm'}}</td>
          </ng-container>

          <!-- assignedDate Column -->
          <ng-container *ngIf="column.property === 'assignedDate'" [matColumnDef]="column.property">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>
              {{ column.label }}
              <net-mat-header-filter *ngIf="column.filterable" [type]="matHeaderFilterType.date" [column]="column"
                [dataSource]="dataSource" [key]="column.key"></net-mat-header-filter>
            </th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{ row?.ticket[ column.property
              ] |
              momentFormat : 'DD MMMM YYYY HH:mm'}}</td>
          </ng-container>

          <!-- status Column -->
          <ng-container *ngIf="column.property === 'status'" [matColumnDef]="column.property">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>
              {{ column.label }}
              <net-mat-header-filter *ngIf="column.filterable" [column]="column" [dataSource]="dataSource"
                [key]="column.key" [selectSource]="dataSource.$statuses"
                [type]="matHeaderFilterType.select"></net-mat-header-filter>
            </th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              <span class="net-label-status text-contrast-white"
                [style.backgroundColor]="row?.ticket.cxStatus?.backgroundColor ?? row?.ticket.cxCategoryStatus?.backgroundColor">
                {{ 'CxStatus.'+(row?.ticket.cxStatus?.name ?? row?.ticket.cxCategoryStatus?.name) |
                translate }}
              </span>
            </td>
          </ng-container>

          <!-- subStatus Column -->
          <ng-container *ngIf="column.property === 'subStatus'" [matColumnDef]="column.property">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>
              {{ column.label }}
              <net-mat-header-filter *ngIf="column.filterable" [column]="column" [dataSource]="dataSource"
                [key]="column.key" [selectSource]="dataSource.$subStatuses"
                [type]="matHeaderFilterType.select"></net-mat-header-filter>
            </th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              <ng-container *ngIf="row?.ticket.cxSubStatus">
                <span class="net-label-status text-contrast-white"
                  [style.backgroundColor]="row?.ticket.cxSubStatus?.backgroundColor">
                  {{ row?.ticket.cxSubStatus?.name }}
                </span>
              </ng-container>
            </td>
          </ng-container>

          <!-- assignee Column -->
          <ng-container *ngIf="column.property === 'assignee'" [matColumnDef]="column.property">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>
              {{ column.label }}
              <net-mat-header-filter *ngIf="column.filterable" [column]="column" [dataSource]="dataSource"
                [key]="column.key" [selectSource]="dataSource.$userList"
                [type]="matHeaderFilterType.select"></net-mat-header-filter>
            </th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              <net-profile-photo *ngIf="row?.ticket.assignedSystemUser"
                [matTooltip]="row?.ticket.assignedSystemUser.firstName + ' ' + row?.ticket.assignedSystemUser.lastName"
                [systemUser]="row?.ticket.assignedSystemUser" width="30" height="30" fontSize="12">
              </net-profile-photo>
            </td>
          </ng-container>

          <!-- linkedBy Column -->
          <ng-container *ngIf="column.type === 'linkedBy'" [matColumnDef]="column.property">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>
              {{ column.label }}
              <net-mat-header-filter *ngIf="column.filterable" [column]="column" [dataSource]="dataSource"
                [key]="column.key" [selectSource]="dataSource.$userList"
                [type]="matHeaderFilterType.select"></net-mat-header-filter>
            </th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              {{ row.createdUser?.firstName }} {{ row.createdUser?.lastName }}
            </td>
          </ng-container>

          <!-- uuid Column -->
          <ng-container *ngIf="column.type === 'uuid'" [matColumnDef]="column.property">
            <th *matHeaderCellDef mat-header-cell>
              {{ column.label }}
              <net-mat-header-filter [key]="column.key" [type]="matHeaderFilterType.select" [column]="column"
                [dataSource]="dataSource" [selectSource]="dataSource.linkTypes$"></net-mat-header-filter>
            </th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              {{ ticketLinkTypeName(row[column.property]) }}
            </td>
          </ng-container>

          <!-- button Column -->
          <ng-container *ngIf="column.type === 'button'" matColumnDef="actions">
            <th *matHeaderCellDef mat-header-cell>{{ column.label }}</th>
            <td *matCellDef="let row" class="w-10 text-secondary" mat-cell>
              <button (click)="$event.stopPropagation()" [matMenuTriggerData]="{ row : row }"
                [matMenuTriggerFor]="actionsMenu" mat-icon-button type="button">
                <mat-icon [icIcon]="icMoreHoriz"></mat-icon>
              </button>
            </td>
          </ng-container>

        </ng-container>

        <ng-container matColumnDef="not-found">
          <td *matFooterCellDef [colSpan]="visibleColumns.length" class="text-center" mat-footer-cell>
            {{'GENERAL.NO_RESULT_FOUND' | translate}}
          </td>
        </ng-container>
        <tr *matFooterRowDef="['not-found']" [class.hidden]="dataSource.loading !== false || dataSource.empty !== true"
          mat-footer-row></tr>

        <ng-container matColumnDef="loading">
          <td *matFooterCellDef [colSpan]="visibleColumns.length" class="text-center" mat-footer-cell>
            {{'GENERAL.LOADING' | translate}}
          </td>
        </ng-container>
        <tr *matFooterRowDef="['loading']" [class.hidden]="initiated === true" mat-footer-row></tr>
        <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
        <tr *matRowDef="let row; columns: visibleColumns;" @fadeInUp (click)="openPreview(row.ticket)"
          class="hover:bg-hover trans-ease-out cursor-pointer cursor-default" mat-row>
        </tr>
      </table>

      <net-full-spinner [active]="initiated === true && dataSource.loading"></net-full-spinner>
    </div>
    <mat-paginator [hidden]="collapsible && collapse" [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize"
      class="sticky left-0"></mat-paginator>
  </div>
</div>

<mat-menu #actionsMenu="matMenu" xPosition="before" yPosition="below">
  <ng-template let-row="row" matMenuContent>
    <button mat-menu-item (click)="openCxModule(row)" *ngIf="hasMenuCXPermitted">
      <mat-icon [icIcon]="mdiTicket"></mat-icon>
      <span>{{ 'GENERAL.OPEN_IN_CX_MODULE' | translate }}</span>
    </button>
    <button mat-menu-item *ngIf="hasDeleteCXLinkPermitted" (click)="deleteLink(row)">
      <mat-icon [icIcon]="icRemoveLink"></mat-icon>
      <span>{{ 'GENERAL.TICKET_LINK_REMOVE' | translate }}</span>
    </button>

  </ng-template>
</mat-menu>