import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Inject, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import {
  AccountInsertRequest,
  AccountInsertResponse,
  AccountService,
  Customer,
  CustomerExperienceLinkType,
  CustomerExperienceTicketLinksService,
  CustomerService,
  Email,
  ErrorCode,
  MailLinkType,
  MailService,
  Phone,
  SalesOrganization,
  SalesOrganizationService,
  SalesRouteDefinition,
  SalesRouteDefinitionFilterRequest,
  SalesRouteDefinitionService,
  SalesRouteDefinitionStatusType,
  SystemSettingType,
  SystemSettingValue,
  User
} from '@core/api';
import { getSystemSettingValue, getUser } from '@core/store';
import { combineLatest } from 'rxjs';

import icClose from '@iconify/icons-ic/twotone-close';
import icSearch from '@iconify/icons-ic/twotone-search';
import icSmartCard from '@iconify/icons-mdi/badge-account-horizontal';
import isPlusCircle from '@iconify/icons-mdi/plus-circle';

import Swal, { SweetAlertOptions } from 'sweetalert2';

import { fadeInUp400ms } from '../../animations/fade-in-up.animation';
import { stagger60ms } from '../../animations/stagger.animation';

import { CreateLeadlessCustomerWizardDialogComponent } from './create-leadless-customer-wizard-dialog/create-leadless-customer-wizard-dialog.component';
import { CreateLeadlessCustomerWizardDialogModel, CreateNewLeadlessCustomerModel } from './create-leadless-customer-wizard-dialog/create-leadless-customer-wizard-dialog.model';
import { openRouteNewTab } from '../../utils/open-route-new-tab';

@UntilDestroy()
@Component({
  selector: 'net-customer-search-dialog',
  templateUrl: './customer-search-dialog.component.html',
  styleUrls: ['./customer-search-dialog.component.scss'],
  animations: [stagger60ms, fadeInUp400ms]
})
export class CustomerSearchDialogComponent {
  @ViewChild('panelH') first: MatExpansionPanel;

  panelOpenState = false;
  loading = false;
  showLengthMessage = false;

  icClose = icClose;
  icSearch = icSearch;
  icSmartCard = icSmartCard;
  isPlusCircle = isPlusCircle;
  keyword: string;
  customers: Customer[];
  showButton = false;
  salesOrganizations: SalesOrganization[] = [];
  activeRoutes: SalesRouteDefinition[] = [];

  leadlessUsage: string | number | boolean | SystemSettingValue[];
  user: User;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { linkToMail: string, openNewTab?: boolean, linkToTicket?: string, phones: Phone[], emails: Email[] },
    private accountService: AccountService,
    private mailService: MailService,
    private breakpointObserver: BreakpointObserver,
    private customerService: CustomerService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<CustomerSearchDialogComponent>,
    private router: Router,
    private salesOrganizationService: SalesOrganizationService,
    private salesRouteDefinitionService: SalesRouteDefinitionService,
    private customerExperienceTicketLinksService: CustomerExperienceTicketLinksService,
    private store: Store,
    private translate: TranslateService
  ) {
    this.dialogRef.addPanelClass('customer-search-dialog');

    this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall]).subscribe(result => {
      // Make search step dialog full on mobile
      if (result.matches) {
        this.dialogRef.addPanelClass('cdk-overlay-full');
      } else if (!result.matches) {
        this.dialogRef.removePanelClass('cdk-overlay-full');
      }
    });

    combineLatest([
      this.store.select(getUser),
      this.store.select(getSystemSettingValue(SystemSettingType.ACCOUNT_USAGE_WITHOUT_LEAD_DRAFT))
    ]).pipe(untilDestroyed(this))
      .subscribe(([user, leadlessUsage]) => {
        this.user = user;
        this.leadlessUsage = leadlessUsage as boolean;

        this.salesOrganizationService.search({ systemUserId: this.user.userId }).pipe(untilDestroyed(this))
          .subscribe(salesOrganizations => {
            this.salesOrganizations = salesOrganizations.data;
          });
      });
  }

  createNew(customer?: Customer) {
    if (this.leadlessUsage) {
      const newCustomer: CreateNewLeadlessCustomerModel = {
        name: this.keyword,
        customerShortName: this.keyword.substring(0, 30),
        ...this.data?.phones?.length > 0 ? {
          phones: this.data.phones
        } : {},
        ...this.data?.emails?.length > 0 ? {
          emails: this.data.emails
        } : {}
      };

      const data: CreateLeadlessCustomerWizardDialogModel = {
        wizardStepsShown: {
          customerInfosStep: true,
          salesOrganizationsStep: true,
          salesRoutesStep: true
        },
        salesOrganization: null,
        salesRouteDefinitionId: null,
        customer: customer ?? newCustomer
      };

      if (this.salesOrganizations.length > 1) {
        data.wizardStepsShown.customerInfosStep = false;

        this.openLeadlessWizardDialog(data);
      } else {
        const request: SalesRouteDefinitionFilterRequest = {
          filter: {
            salesOrganizationId: this.salesOrganizations[0].salesOrganizationId
          }
        };

        this.searchSalesRouteDefinitions(request).subscribe(response => {
          const salesRouteDefinitions = response.data.results;
          const activeRoutes = salesRouteDefinitions.filter(route => route.salesRouteDefinitionStatusId === SalesRouteDefinitionStatusType.ACTIVE);

          if (activeRoutes.length > 1) {
            data.wizardStepsShown.customerInfosStep = data.wizardStepsShown.salesOrganizationsStep = false;
            data.salesOrganization = this.salesOrganizations[0];

            this.openLeadlessWizardDialog(data);
          } else if (activeRoutes.length === 1) {
            this.processOneActiveRoute(data, activeRoutes[0]);
          } else {
            const modalObj: SweetAlertOptions = {
              html: this.translate.instant('LEAD.ANY_ACTIVE_ROUTE_COULD_NOT_BE_FOUND'),
              icon: 'error',
              cancelButtonText: this.translate.instant('GENERAL.OK'),
              cancelButtonColor: '#4caf50'
            };

            this.openSwalModal(modalObj);
          }
        });
      }
    } else {
      const dataObj = customer ? { found: true, customer } : { found: false, keyword: this.keyword };

      this.dialogRef.close(dataObj);
    }
  }

  onSearch() {
    this.showLengthMessage = this.keyword && this.keyword.length < 3;

    if (!this.keyword) {
      return;
    }

    if (this.keyword.length < 3) {
      return;
    }

    this.loading = true;
    this.customers = null;

    this.customerService.search({
      filter: { customerName: this.keyword },
      pageSize: 500
    }).subscribe(response => {
      this.customers = response.data?.results;
    }).add(() => { this.loading = false; this.showButton = true; });
  }

  onFocus(index: number) {
    setTimeout(() => {
      const element = document.getElementById(index.toString());
      element?.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }, 500);
  }

  private openLeadlessWizardDialog(data: CreateLeadlessCustomerWizardDialogModel) {
    this.dialog.open(CreateLeadlessCustomerWizardDialogComponent, {
      data,
      disableClose: true,
      autoFocus: false,
      minHeight: '25em',
      minWidth: '35em',
      maxWidth: '40em'
    }).afterClosed().pipe(untilDestroyed(this))
      .subscribe((incomingData: AccountInsertResponse) => {
        if (incomingData) {
          if (this.data?.linkToMail) {
            this.mailLinkInsert(incomingData.accountId, incomingData.salesOrganizationId);
            return;
          }

          if (this.data?.linkToTicket) {
            const dataObj = {
              customer: {
                ...incomingData.customer,
                customerId: incomingData.customerId
              }
            };
            this.ticketLinkInsert(incomingData.accountId, incomingData.salesOrganizationId, dataObj);
            return;
          }

          this.routeToLeadDetail(incomingData.accountId);
        }
      });
  }

  private openSwalModal(obj: SweetAlertOptions) {
    return Swal.fire(obj);
  }

  private processOneActiveRoute(data: CreateLeadlessCustomerWizardDialogModel, activeRoute: SalesRouteDefinition) {
    const modalObj: SweetAlertOptions = {
      html: this.translate.instant('LEAD.CUSTOMER_WILL_BE_CREATED_DO_YOU_CONFIRM', { customerName: data.customer.name }),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#4caf50',
      confirmButtonText: this.translate.instant('GENERAL.CONFIRM'),
      cancelButtonText: this.translate.instant('GENERAL.CANCEL')
    };

    this.openSwalModal(modalObj).then(result => {
      if (result.isConfirmed) {
        const { name, customerShortName, phones, emails } = data.customer;
        const request: AccountInsertRequest = {
          salesOrganizationId: this.salesOrganizations[0].salesOrganizationId,
          salesRouteDefinitionId: activeRoute.salesRouteDefinitionId,
          customer: {
            name,
            customerShortName,
            phones,
            emails
          }
        };

        this.accountService.insert(request).subscribe(response => {
          if (response.success) {
            if (this.data?.linkToMail) {
              this.mailLinkInsert(response.data.accountId, request.salesOrganizationId);
              return;
            }

            if (this.data?.linkToTicket) {
              this.ticketLinkInsert(response.data.accountId, request.salesOrganizationId);
              return;
            }

            this.routeToLeadDetail(response.data.accountId);
          } else if ([ErrorCode.DUPLICATE_CUSTOMER_NAME, ErrorCode.DUPLICATE_CUSTOMER_SHORT_NAME].includes(response.errorCode as ErrorCode)) {
            data.wizardStepsShown.salesOrganizationsStep = data.wizardStepsShown.salesRoutesStep = false;

            data.salesOrganization = this.salesOrganizations[0];
            data.salesRouteDefinitionId = activeRoute.salesRouteDefinitionId;

            this.openLeadlessWizardDialog(data);
          }
        });
      }
    });
  }

  private mailLinkInsert(accountId: string, salesOrganizationId: string) {
    this.mailService.link.insert({
      mailId: this.data.linkToMail,
      follow: false,
      accountId,
      mailLinkTypeId: MailLinkType.CUSTOMER,
      salesOrganizationId,
    }).toPromise().then(() => this.dialogRef.close(true));
  }

  private ticketLinkInsert(accountId: string, salesOrganizationId: string, closeData?: {
    customer: {
      customerId: string;
      name: string;
      customerShortName: string;
    }
  }) {
    this.customerExperienceTicketLinksService.link.insert({
      ticketId: this.data?.linkToTicket,
      accountId,
      salesOrganizationId,
      ticketLinkTypeId: CustomerExperienceLinkType.CUSTOMER
    }).toPromise().then(() => this.dialogRef.close(closeData ?? true));
  }

  private routeToLeadDetail(accountId: string) {
    this.dialogRef.close();

    if (this.data?.openNewTab) {

      openRouteNewTab(
        this.router,
        ['leads', accountId]
      );
      return;

    }

    this.router.navigate(['leads', accountId]);
  }

  private searchSalesRouteDefinitions(request: SalesRouteDefinitionFilterRequest) {
    return this.salesRouteDefinitionService.search(request);
  }
}
