import { BoardInfo } from '@core/api/board-info/board-info.model';
import { Board, SocketSearchFilterRequest, SocketSearchRequest } from '@core/api/board/board.model';
import { CardHashtag } from '@core/api/card-hashtag/card-hashtag.model';
import { CardSystemUser } from '@core/api/card-system-user/card-system-user.model';
import { Card } from '@core/api/card/card.model';
import moment from 'moment';


export function applyFilter(section: BoardInfo, currentFilter: SocketSearchRequest) {
    // card status
    if (currentFilter.filter?.cardStatusId) {
        section.cards = section.cards.filter(_card => _card.cardStatusId === currentFilter.filter?.cardStatusId);
    }

    // assignedUser
    if (currentFilter.filter?.cardSystemUserIds) {
        if (currentFilter.filter?.cardSystemUserIds.length !== 0) {
            section.cards = section.cards.filter(_card => {
                for (const systemUser of _card.cardSystemUsers) {
                    if (currentFilter.filter?.cardSystemUserIds.includes(systemUser.systemUserId)) {
                        return true;
                    }
                }
                return false;
            });
        }
    }

    // createdBy
    if (currentFilter.filter?.cardCreatedBys) {
        if (currentFilter.filter?.cardCreatedBys.length !== 0) {
            section.cards = section.cards.filter(_card => {
                if (currentFilter.filter?.cardCreatedBys.includes(_card.createdUser.userId)) {
                    return true;
                }
                return false;
            });
        }
    }

    // done date
    if (currentFilter.filter?.cardDoneDateMin && currentFilter.filter?.cardDoneDateMax) {
        const min = currentFilter.filter?.cardDoneDateMin;
        const max = currentFilter.filter?.cardDoneDateMax;
        section.cards = section.cards.filter(_card => checkDateIsWithinRange(moment(_card.doneDate), min, max));
    }

    // due date
    if (currentFilter.filter?.cardDueDateMin && currentFilter.filter?.cardDueDateMax) {
        const min = currentFilter.filter?.cardDueDateMin;
        const max = currentFilter.filter?.cardDueDateMax;
        section.cards = section.cards.filter(_card => checkDateIsWithinRange(moment(_card.dueDate), min, max));
    }

    // reminder date
    if (currentFilter.filter?.cardReminderDateMin && currentFilter.filter?.cardReminderDateMax) {
        const min = currentFilter.filter?.cardReminderDateMin;
        const max = currentFilter.filter?.cardReminderDateMax;
        section.cards = section.cards.filter(_card => checkDateIsWithinRange(moment(_card.dueDate), min, max));
    }

    // createdOn
    if (currentFilter.filter?.cardCreatedOnMin && currentFilter.filter?.cardCreatedOnMax) {
        const min = currentFilter.filter?.cardCreatedOnMin;
        const max = currentFilter.filter?.cardCreatedOnMax;
        section.cards = section.cards.filter(_card => checkDateIsWithinRange(moment(_card.dueDate), min, max));
    }

    // hashtag
    if (currentFilter.filter?.boardHashtagInfoIds) {
        if (currentFilter.filter?.boardHashtagInfoIds.length !== 0) {
            section.cards = section.cards.filter(_card => {
                for (const hashtag of _card.cardHashtags) {
                    if (currentFilter.filter?.boardHashtagInfoIds.includes(hashtag.boardHashtagInfoId)) {
                        return true;
                    }
                }
                return false;
            });
        }
    }

    // document
    if (typeof currentFilter.filter?.attachmentFlag === 'boolean') {
        if (currentFilter.filter?.attachmentFlag) {
            section.cards = section.cards.filter(_card => _card.attachmentCount > 0);
        }
        else {
            section.cards = section.cards.filter(_card => _card.attachmentCount === 0);
        }
    }

    // description
    if (currentFilter.filter?.description) {
        section.cards = section.cards.filter(_card => checkDescription(_card.description, currentFilter.filter?.description));
    }

    // crm links
    if (currentFilter.filter?.leadDraftIds ||
        currentFilter.filter?.activityIds ||
        currentFilter.filter?.contactIds ||
        currentFilter.filter?.opportunityIds ||
        currentFilter.filter?.accountIds ||
        currentFilter.filter?.offerIds) {
        section.cards = section.cards.filter(_card => checkCRMLinks(_card.cardObjects, currentFilter));
    }
}

export function applyFilterForCard(_card: Card, currentFilter: SocketSearchRequest) {
    // card status
    if (currentFilter) {
        if (!checkCardComplete(_card.cardStatusId, currentFilter) && !_card.parentCardId) {
            return false;
        }
    }

    // due date
    if (currentFilter && currentFilter.filter?.cardDueDateMin && currentFilter.filter?.cardDueDateMax) {
        if (!checkDateIsWithinRange(moment(_card.dueDate), currentFilter.filter.cardDueDateMin, currentFilter.filter.cardDueDateMax)) {
            return false;
        }
    }

    // reminder date
    if (currentFilter && currentFilter.filter?.cardReminderDateMin && currentFilter.filter?.cardReminderDateMax) {
        if (!checkDateIsWithinRange(moment(_card.reminderDate), currentFilter.filter.cardReminderDateMin, currentFilter.filter.cardReminderDateMax)) {
            return false;
        }
    }

    // hashtag
    if (currentFilter && currentFilter.filter?.boardHashtagInfoIds) {
        if (!checkHashtag(_card.cardHashtags, currentFilter.filter.boardHashtagInfoIds)) {
            return false;
        }
    }

    // assigned user
    if (currentFilter && currentFilter.filter?.cardSystemUserIds) {
        if (!checkAssignedUserFilter(currentFilter.filter?.cardSystemUserIds, _card.cardSystemUsers)) {
            return false;
        }
    }

    return true;
}

export function checkAssignedUserFilter(assignedUser: string[], cardSystemUsers: CardSystemUser[]) {
    for (const systemUser of cardSystemUsers) {
        if (assignedUser.includes(systemUser.systemUserId)) {
            return true;
        }
    }
    return false;
}

export function checkDateIsWithinRange(newDate: moment.Moment, minDate: moment.Moment, maxDate: moment.Moment) {
    if (newDate.isAfter(minDate) && newDate.isBefore(maxDate)) {
        return true;
    }
    return false;
}

export function checkCardComplete(cardStatusId: string, currentFilter: SocketSearchRequest) {
    if (currentFilter.filter?.boardId && !currentFilter.filter?.cardStatusId) {
        return true;
    }

    if (currentFilter.filter?.cardStatusId === cardStatusId) {
        return true;
    }

    return false;
}

export function checkHashtag(cardHashtags: CardHashtag[], filterHashtags: string[]) {
    if (filterHashtags.length === 0) {
        return true;
    }

    for (const hashtag of cardHashtags) {
        if (filterHashtags.includes(hashtag.boardHashtagInfoId)) {
            return true;
        }
    }
    return false;
}

export function checkAttachment(card: Card, filter: SocketSearchFilterRequest, isDeleteOp?: boolean) {
    if (filter.attachmentFlag === undefined) {
        return true;
    }
    if (filter && filter.attachmentFlag) {
        // card.cardAttachments.length === 1 ise ve attachment silinmiş ise artık 'document: Yes' listesinde gösterilmemeli
        if (isDeleteOp && card.cardAttachments.length === 1) {
            return false;
        }

        if (card.cardAttachments.length > 0) {
            return true;
        }
    }

    if (filter && filter.attachmentFlag === false && card.cardAttachments.length === 0) {
        return true;
    }

    return false;
}

export function checkDescription(cardDesc: string, filterDesc: string) {
    if (!filterDesc) {
        return true;
    }
    if (cardDesc) {
        return cardDesc.includes(filterDesc);
    }
    return false;
}

export function filterSectionCards(board: Board, request: SocketSearchRequest) {
    if (request.filter.cardStatusId) {
        board.boardInfos.map(section => {
            section.cards = section.cards.filter(card => card.checkIsCardAdd || card.cardStatusId === request.filter.cardStatusId);
        });
    }

    return board;
}

export function checkCRMLinks(cardObjects: any, currentFilter: SocketSearchRequest) {
    let isVisible = true;
    if (currentFilter.filter) {
        if (currentFilter.filter.leadDraftIds) {
            isVisible = false;
            for (const cardObj of cardObjects) {
                if (cardObj.leadDraftId && currentFilter.filter.leadDraftIds.includes(cardObj.leadDraftId)) {
                    isVisible = true;
                    break;
                }
            }
        }
        if (isVisible && currentFilter.filter.activityIds) {
            isVisible = false;
            for (const cardObj of cardObjects) {
                if (currentFilter.filter.activityIds.includes(cardObj.activityId)) {
                    isVisible = true;
                    break;
                }
            }
        }

        if (isVisible && currentFilter.filter.contactIds) {
            isVisible = false;
            for (const cardObj of cardObjects) {
                if (cardObj.contactId && currentFilter.filter.contactIds.includes(cardObj.contactId)) {
                    isVisible = true;
                    break;
                }
            }
        }

        if (isVisible && currentFilter.filter.opportunityIds) {
            isVisible = false;
            for (const cardObj of cardObjects) {
                if (cardObj.opportunityId && currentFilter.filter.opportunityIds.includes(cardObj.opportunityId)) {
                    isVisible = true;
                    break;
                }
            }
        }

        if (isVisible && currentFilter.filter.accountIds) {
            isVisible = false;
            for (const cardObj of cardObjects) {
                if (cardObj.accountId && currentFilter.filter.accountIds.includes(cardObj.accountId)) {
                    isVisible = true;
                    break;
                }
            }
        }

        if (isVisible && currentFilter.filter.offerIds) {
            isVisible = false;
            for (const cardObj of cardObjects) {
                if (cardObj.offerId && currentFilter.filter.offerIds.includes(cardObj.offerId)) {
                    isVisible = true;
                    break;
                }
            }
        }
    }
    return isVisible;
}
