import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    ActivitySalesOrganizationMailSystemUsers, ActivitySalesOrganizationMailSystemUsersRequest,
    ActivityStaticRecipientUpdateListRequest, MailStaticRecipientUser, SalesOrganizationSettingInfoDetailUpdateRequest, SalesOrganizationSettingInfoSearch
} from './activity-static-recipient.model';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { Response } from '../api.model';
import { SalesOrganizationSettingInfoDetail } from '../sales-organization-setting-info/sales-organization-setting-info.model';

@Injectable({
    providedIn: 'root',
})
export class ActivityStaticRecipientService {
    constructor(private http: HttpClient) { }

    getSalesOrganizationMailSystemUsers(request: ActivitySalesOrganizationMailSystemUsersRequest): Observable<Response<MailStaticRecipientUser[]>> {
        return this.http.post<Response<MailStaticRecipientUser[]>>(environment.apiUrl + '/api/SalesOrganizationMailSystemUser/List', request);
    }

    updateSalesOrganizationMailSystemUser(request: ActivityStaticRecipientUpdateListRequest) {
        return this.http.post<Response<ActivitySalesOrganizationMailSystemUsers[]>>
            (environment.apiUrl + '/api/SalesOrganizationMailSystemUser/UpdateList', request);
    }

    getSalesOrganizationSettingInfoDetail(uuid: string) {
        const headers = new HttpHeaders({ 'content-type': 'application/json' });
        return this.http.post<Response<SalesOrganizationSettingInfoDetail[]>>(environment.apiUrl + '/api/SalesOrganizationSettingInfoSalesOrganizationSettingInfoDetail/List', JSON.stringify(uuid), { headers });
    }

    updateSalesOrganizationSettingInfoDetail(request: SalesOrganizationSettingInfoDetailUpdateRequest) {
        return this.http.post<Response<any[]>>
            (environment.apiUrl + '/api/SalesOrganizationSettingInfoSalesOrganizationSettingInfoDetail/UpdateValues', request);
    }

    getSaveAndSendSettingInfo(request: SalesOrganizationSettingInfoSearch) {
        return this.http.post<Response<SalesOrganizationSettingInfoDetail[]>>
            (environment.apiUrl + '/api/SalesOrganizationSettingInfoSalesOrganizationSettingInfoDetail/Search', request);
    }
}
