import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import { Response, OfferParameter, OfferParameterSearchRequest } from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class OfferParameterService {

  constructor(private http: HttpClient) {}

   search(filter: OfferParameterSearchRequest): Observable<Response<OfferParameter[]>> {
    return this.http.post<Response<OfferParameter[]>>(environment.apiUrl + '/api/OfferParameter/Search', filter);
  }
}
