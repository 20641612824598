import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';

import { Observable, of } from 'rxjs';

import { Response, PagedResponse, FilterRequest, CustomerType, CustomerTypeRequest, CustomerTypeSearchRequest } from '@core/api';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomerTypeService {
  constructor(private http: HttpClient) { }

  list(request: FilterRequest): Observable<PagedResponse<CustomerType>> {
    return this.http.post<PagedResponse<CustomerType>>(environment.apiUrl + '/api/CustomerType/List', request);
  }

  search(request: CustomerTypeSearchRequest): Observable<PagedResponse<CustomerType>> {
    return this.http.post<PagedResponse<CustomerType>>(environment.apiUrl + '/api/CustomerType/Search', request);
  }

  get(uuid: string): Observable<Response<CustomerType>> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<CustomerType>>(environment.apiUrl + '/api/CustomerType/Get', JSON.stringify(uuid), { headers });
  }

  insert(request: CustomerTypeRequest): Observable<Response<CustomerType>> {
    return this.http.post<Response<CustomerType>>(environment.apiUrl + '/api/CustomerType/Insert', request);
  }

  update(request: CustomerTypeRequest): Observable<Response<CustomerType>> {
    return this.http.post<Response<CustomerType>>(environment.apiUrl + '/api/CustomerType/Update', request);
  }

  bulkUpdate(request: CustomerType[]) {
    return this.http.post<Response<CustomerType[]>>(environment.apiUrl + '/api/CustomerType/BulkUpdate', request);
  }

  delete(request: any): Observable<Response<CustomerType>> {
    return this.http.post<Response<CustomerType>>(environment.apiUrl + '/api/CustomerType/Delete', request);
  }
}
