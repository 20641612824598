import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import { Response, OfferCarrierUnitType, OfferCarrierUnitTypeSearchRequest } from '@core/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OfferCarrierUnitTypeService {
  constructor(private http: HttpClient) { }

  search(request: OfferCarrierUnitTypeSearchRequest): Observable<Response<OfferCarrierUnitType[]>> {
    return this.http.post<Response<OfferCarrierUnitType[]>>(environment.apiUrl + '/api/OfferCarrierUnitType/Search', request);
  }
}
