<mat-select #salesOrganizationSelect [placeholder]="placeholder" [formControl]="selectCtrl" [multiple]="multiple"
    (openedChange)="openedChange($event)" (selectionChange)="onSelectionChange()" [compareWith]="compareWith">

    <mat-select-trigger *ngIf="selectCtrl?.value?.length > 1 && multiple"
        [matTooltipDisabled]="!(value.length > 1 && disabled)" [matTooltip]="getToolTip()"
        [matTooltipClass]="'sales-organization-tooltip'" [matTooltipPosition]="'below'">
        {{'MAIL_LIST.SALES_ORGANIZATION_SELECTED' | translate: {value:
        selectCtrl?.value?.length} }}
    </mat-select-trigger>

    <mat-option *ngIf="multiple" disabled="disabled" class="mat-option-button">
        <button mat-raised-button (click)="selectAll()">
            {{'GENERAL.SELECT_ALL' | translate }}
        </button>
        <button mat-raised-button (click)="deselectAll()">{{'GENERAL.REMOVE_ALL' |
            translate
            }}</button>
    </mat-option>


    <mat-option *ngFor="let item of salesOrganizations | orderBy: 'name': false"
        [value]="item.salesOrganizationId">{{item.name}}</mat-option>

</mat-select>