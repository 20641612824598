import {LOCALE_ID, Provider} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

const supportedLanguageList = ['en', 'tr', 'es', 'fr', 'de', 'nl'];

export class LocaleId extends String {
  constructor(private translate: TranslateService) {
    super();
  }

  toString(): string {
    const currentLang = this.translate.currentLang.slice(0, 2);
    return supportedLanguageList.some((value) => value === currentLang) ? currentLang : 'en';
  }

  valueOf(): string {
    return this.toString();
  }
}

export const LocaleProvider: Provider = {
  provide: LOCALE_ID,
  useClass: LocaleId,
  deps: [TranslateService],
};
