import moment from 'moment';
import Swal from 'sweetalert2';

import { Component, Inject, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  Customer, FinancialInquiry, FinancialInquiryRequest, FinancialInquiryService,
  FinancialInquiryStatusOption, TransactionCurrency, User
} from '@core/api';
import { Permission } from '@core/auth/auth.enum';
import { getUser, hasUserPermission } from '@core/store';
import icQueryBuilder from '@iconify/icons-ic/query-builder';
import icClose from '@iconify/icons-ic/twotone-close';
import icSave from '@iconify/icons-ic/twotone-save';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { FinancialInquiryFormDialog } from './financial-inquiry-form-dialog.model';

@UntilDestroy()
@Component({
  selector: 'net-financial-inquiry-form-dialog',
  templateUrl: './financial-inquiry-form-dialog.component.html',
  styleUrls: ['./financial-inquiry-form-dialog.component.scss']
})
export class FinancialInquiryFormDialogComponent implements OnDestroy {

  icSave = icSave;
  icClose = icClose;
  icQueryBuilder = icQueryBuilder;

  loading = false;
  dialogLoading = false;
  isFinanceUser = false;
  isSalesman = false;

  form: UntypedFormGroup;
  rejectExplationControl = new UntypedFormControl(null, Validators.required);

  defaultCurrency: TransactionCurrency;
  financialInquiry: FinancialInquiry;
  customer: Customer;
  opportunityId: string;
  user: User;

  status = FinancialInquiryStatusOption;
  years = this.generateArrayOfYears();
  validityDate = moment().add(3, 'M');
  taxNoPattern = /^[0-9]{10,11}$/;

  rejectionDialogRef: MatDialogRef<any>;
  @ViewChild('rejectFinancialInquiryDialog', { static: true }) rejectFinancialInquiryDialog: TemplateRef<HTMLElement>;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: FinancialInquiryFormDialog,
    private dialogRef: MatDialogRef<FinancialInquiryFormDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private financialInquiryService: FinancialInquiryService,
    private store: Store,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {
    this.store.select(getUser).pipe(untilDestroyed(this)).subscribe(user => this.user = user);

    this.store.select(hasUserPermission(Permission.FINANCIAL_INQUIRY_INSERT)).pipe(untilDestroyed(this))
      .subscribe(permissionCode => this.isSalesman = permissionCode);
    this.store.select(hasUserPermission(Permission.FINANCIAL_INQUIRY_UPDATE)).pipe(untilDestroyed(this))
      .subscribe(permissionCode => this.isFinanceUser = permissionCode);

    if (data) {
      this.customer = data.customer;
      this.opportunityId = data.opportunityId;

      this.initForm();

      if (data.financialInquiry) {
        this.getFinancialInquiry(data.financialInquiry.financialInquiryId);
      }
    }

  }

  initForm() {
    this.form = this.formBuilder.group({
      taxOffice: [null, Validators.required],
      taxNo: [null, { validators: [Validators.required, Validators.pattern(this.taxNoPattern)], updateOn: 'blur' }],
      title: [this.customer.name, Validators.required],
      companyCreateDate: [null],
      status: [null],
      fund: [null],
      fundType: ['TL'],
      expiry: [null],
      validityDate: [this.validityDate],
      note: [null],
    });

    this.syncValidator();
  }

  syncValidator() {
    this.form.enable();
    if (this.financialInquiry) {

      if (!this.isFinanceUser && this.isSalesman) {
        this.form.disable();
      }
      if (this.isFinanceUser) {
        this.form.get('taxOffice').disable();
        this.form.get('taxNo').disable();
        this.form.get('title').disable();

        this.form.get('companyCreateDate').setValidators([Validators.required]);
        this.form.get('status').setValidators([Validators.required]);
        this.form.get('fund').setValidators([Validators.required]);
        this.form.get('validityDate').setValidators([Validators.required]);
        this.form.get('note').setValidators([Validators.required]);

        this.form.updateValueAndValidity();
      }

    } else {

      if (!this.isFinanceUser && this.isSalesman) {
        this.form.get('companyCreateDate').disable();
        this.form.get('status').disable();
        this.form.get('fund').disable();
        this.form.get('expiry').disable();
        this.form.get('validityDate').disable();
        this.form.get('note').disable();
      }

      if (this.isFinanceUser) {
        this.form.disable();
      }

    }


    if (!this.isFinanceUser && !this.isSalesman) {
      this.form.disable();
    }
  }

  getFinancialInquiry(financialInquiryId: string) {
    this.dialogLoading = true;
    this.financialInquiryService.get(financialInquiryId).subscribe(response => {
      this.loading = false;
      if (response.success) {
        this.financialInquiry = response.data;
        this.updateForm();

        this.dialogLoading = false;
      }
    });
  }

  updateForm() {
    this.form.patchValue({
      taxOffice: this.financialInquiry.taxOffice,
      taxNo: this.financialInquiry.taxNo,
      title: this.financialInquiry.title,
      companyCreateDate: (this.financialInquiry && this.financialInquiry.companyCreateDate) ? moment(this.financialInquiry.companyCreateDate).year() : null,
      status: this.financialInquiry.status,
      fund: this.financialInquiry.fund,
      expiry: this.financialInquiry.expiry,
      validityDate: this.financialInquiry.validityDate ? this.financialInquiry.validityDate : this.validityDate,
      note: this.financialInquiry.note,
    });

    this.syncValidator();
  }

  onSubmit() {
    // Touch all form fields and trigger validate
    this.form.markAllAsTouched();

    // If form is valid, emit submit event
    if (this.form.valid) {
      this.financialInquiry ? this.updateFinancialInquiry() : this.insertFinancialInquiry();
    }
  }

  private getRequest(financialInquiryStatusId: string): FinancialInquiryRequest {

    const _expiry = this.form.get('expiry').value;
    // Create request object
    const request: FinancialInquiryRequest = {
      customerId: this.financialInquiry ? this.financialInquiry.customerId : this.data.customer.customerId,
      financialInquiryStatusId,
      taxOfficeId: this.form.get('taxOffice').value.taxOfficeId,
      taxNo: this.form.get('taxNo').value,
      title: this.form.get('title').value,
      companyCreateDate: moment(this.form.get('companyCreateDate').value, 'YYYY'),
      status: this.form.get('status').value,
      fund: this.form.get('fund').value,
      fundType: this.form.get('fundType').value,
      ...!!_expiry ? { expiry: _expiry } : {},
      validityDate: this.form.get('validityDate').value,
      opportunityId: this.financialInquiry ? this.financialInquiry.opportunityId : this.opportunityId,
      note: this.form.get('note').value,
    };

    if (this.financialInquiry) {
      request.financialInquiryId = this.financialInquiry.financialInquiryId;
    } else {

    }
    return request;
  }

  insertFinancialInquiry() {
    this.loading = true;
    const request = this.getRequest(FinancialInquiryStatusOption.RETURN);

    this.financialInquiryService.insert(request).subscribe(response => {
      if (response.success) {
        this.dialogRef.close(true);
      }
    }).add(() => this.loading = false);
  }

  updateFinancialInquiry() {
    this.loading = true;
    const financialInquiryStatusId = this.financialInquiry.financialInquiryStatusId === FinancialInquiryStatusOption.COMPLETE
      || this.financialInquiry.financialInquiryStatusId === FinancialInquiryStatusOption.UPDATED ?
      FinancialInquiryStatusOption.UPDATED : FinancialInquiryStatusOption.COMPLETE;
    const request = this.getRequest(financialInquiryStatusId);

    this.financialInquiryService.update(request).subscribe(response => {
      if (response.success) {
        this.dialogRef.close(true);
      }
    }).add(() => this.loading = false);
  }

  generateArrayOfYears() {
    const max = new Date().getFullYear();
    const min = max - 100;
    const years = [];

    for (let i = max; i >= min; i--) {
      years.push(i);
    }
    return years;
  }

  showStep() {
    if (this.financialInquiry && !this.isFinanceUser && (this.financialInquiry.financialInquiryStatusId === this.status.COMPLETE ||
      this.financialInquiry.financialInquiryStatusId === this.status.UPDATED)) {
      return true;
    }
    if (this.financialInquiry && this.isFinanceUser) {
      return true;
    }

    return false;
  }

  buttonDisabled() {
    if (!this.isFinanceUser) {
      this.form.disable();
      return true;
    }

    return false;
  }

  resetForm() {
    this.financialInquiry = null;
    this.form.enable();
  }

  cancelFinancialInquiry() {
    Swal.fire({
      title: 'Dikkat',
      html: this.translate.instant('GENERAL.FINANCIAL_INQUIRY_REQUEST_WILL_BE_CANCELLED_DO_YOU_WANT_TO_CONTINUE'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#4caf50',
      confirmButtonText: this.translate.instant('GENERAL.CONFIRM'),
      cancelButtonText: this.translate.instant('GENERAL.CANCEL')
    }).then((result) => {
      if (result.value) {
        this.loading = true;
        const request = this.getRequest(FinancialInquiryStatusOption.CANCELED);

        this.financialInquiryService.update(request).subscribe(response => {
          if (response.success) {
            this.dialogRef.close(true);
          }
        }).add(() => this.loading = false);
      }
    });
  }

  openRejectFinancialInquiryDialog() {
    this.rejectExplationControl.reset();
    this.rejectionDialogRef = this.dialog.open(this.rejectFinancialInquiryDialog, { autoFocus: false });
  }

  onSubmitRejectFinancialInquiry() {
    this.loading = true;
    const request = this.getRequest(FinancialInquiryStatusOption.REJECTED);
    request.redDescription = this.rejectExplationControl.value;

    this.financialInquiryService.update(request).subscribe(response => {
      if (response.success) {
        this.rejectionDialogRef.close();
        this.dialogRef.close(true);
      }
    }).add(() => this.loading = false);
  }

  close() {
    this.rejectionDialogRef.close();
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy() { }
}
