import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';

import { Observable } from 'rxjs';

import {
  Response,
  PagedResponse,
  FilterRequest,
  ServiceUnit,
  ServiceUnitRequest,
  ServiceUnitFilterRequest
} from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class ServiceUnitService {
  constructor(private http: HttpClient) { }

  search(request: ServiceUnitFilterRequest): Observable<PagedResponse<ServiceUnit>> {
    return this.http.post<PagedResponse<ServiceUnit>>(environment.apiUrl + '/api/ServiceUnit/Search', request);
  }

  list(request: FilterRequest): Observable<PagedResponse<ServiceUnit>> {
    return this.http.post<PagedResponse<ServiceUnit>>(environment.apiUrl + '/api/ServiceUnit/List', request);
  }

  get(uuid: string): Observable<Response<ServiceUnit>> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<ServiceUnit>>(environment.apiUrl + '/api/ServiceUnit/Get', JSON.stringify(uuid), { headers });
  }

  insert(request: ServiceUnitRequest): Observable<Response<ServiceUnit>> {
    return this.http.post<Response<ServiceUnit>>(environment.apiUrl + '/api/ServiceUnit/Insert', request);
  }

  update(request: ServiceUnitRequest): Observable<Response<ServiceUnit>> {
    return this.http.post<Response<ServiceUnit>>(environment.apiUrl + '/api/ServiceUnit/Update', request);
  }

  delete(request: ServiceUnit): Observable<Response<ServiceUnit>> {
    return this.http.post<Response<ServiceUnit>>(environment.apiUrl + '/api/ServiceUnit/Delete', request);
  }
}
