<div class="h-full bg-white">
    <div class="box-container w-full p-gutter text-center align-middle mt-auto mb-auto">
        <div class="w-full max-w-lg mx-auto mb-6">
            <svg xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 506.14 342.8">
                <defs>
                    <linearGradient id="linear-gradient" x1="429.84" y1="184.88" x2="423.85" y2="423.47"
                        gradientUnits="userSpaceOnUse">
                        <stop offset="0" stop-color="#010101" stop-opacity="0" />
                        <stop offset="0.95" stop-color="#010101" />
                    </linearGradient>
                    <linearGradient id="linear-gradient-2" x1="393.23" y1="228.16" x2="574.1" y2="218.76"
                        xlink:href="#linear-gradient" />
                    <linearGradient id="linear-gradient-3" x1="401.85" y1="303.48" x2="485.96" y2="303.48"
                        xlink:href="#linear-gradient" />
                    <linearGradient id="linear-gradient-4" x1="438.45" y1="167.14" x2="435.9" y2="268.9"
                        xlink:href="#linear-gradient" />
                    <linearGradient id="linear-gradient-5" x1="150.8" y1="118.37" x2="387.25" y2="118.37"
                        xlink:href="#linear-gradient" />
                    <linearGradient id="linear-gradient-6" x1="257.28" y1="281.92" x2="261.25" y2="437.49"
                        xlink:href="#linear-gradient" />
                    <linearGradient id="linear-gradient-7" x1="257.59" y1="359.34" x2="260.16" y2="473.07"
                        xlink:href="#linear-gradient" />
                    <linearGradient id="linear-gradient-8" x1="252.33" y1="303.26" x2="263.45" y2="408.87"
                        xlink:href="#linear-gradient" />
                    <linearGradient id="linear-gradient-9" x1="259.21" y1="251.82" x2="262.63" y2="163.74"
                        gradientUnits="userSpaceOnUse">
                        <stop offset="0" stop-color="#fff" stop-opacity="0" />
                        <stop offset="0.95" stop-color="#fff" />
                    </linearGradient>
                    <linearGradient id="linear-gradient-10" x1="270.73" y1="297.71" x2="178.37" y2="-6.72"
                        xlink:href="#linear-gradient" />
                    <linearGradient id="linear-gradient-11" x1="248.65" y1="210.45" x2="270.31" y2="335.87"
                        xlink:href="#linear-gradient-9" />
                    <linearGradient id="linear-gradient-12" x1="42.12" y1="256.5" x2="75.47" y2="257.35"
                        xlink:href="#linear-gradient" />
                    <linearGradient id="linear-gradient-13" x1="459.65" y1="256.39" x2="489.3" y2="257.53"
                        xlink:href="#linear-gradient" />
                    <linearGradient id="linear-gradient-14" x1="472.12" y1="301.92" x2="409.69" y2="298.5"
                        xlink:href="#linear-gradient" />
                    <linearGradient id="linear-gradient-15" x1="467.43" y1="333.84" x2="467.43" y2="351.23"
                        xlink:href="#linear-gradient" />
                    <linearGradient id="linear-gradient-16" x1="454.01" y1="304.75" x2="481.07" y2="304.75"
                        xlink:href="#linear-gradient-9" />
                    <linearGradient id="linear-gradient-17" x1="46.26" y1="301.92" x2="-16.17" y2="298.5"
                        xlink:href="#linear-gradient" />
                    <linearGradient id="linear-gradient-18" x1="41.57" y1="333.84" x2="41.57" y2="351.23"
                        xlink:href="#linear-gradient" />
                    <linearGradient id="linear-gradient-19" x1="28.15" y1="304.75" x2="55.2" y2="304.75"
                        xlink:href="#linear-gradient-9" />
                    <linearGradient id="linear-gradient-20" x1="194.29" y1="23.71" x2="214.09" y2="23.71"
                        xlink:href="#linear-gradient" />
                    <linearGradient id="linear-gradient-21" x1="325.98" y1="41.67" x2="345.78" y2="41.67"
                        xlink:href="#linear-gradient" />
                    <linearGradient id="linear-gradient-22" x1="177.77" y1="240.27" x2="192.31" y2="321.51"
                        xlink:href="#linear-gradient" />
                    <linearGradient id="linear-gradient-23" x1="407.23" y1="201.72" x2="421.77" y2="282.96"
                        xlink:href="#linear-gradient" />
                    <linearGradient id="linear-gradient-24" x1="63.32" y1="260.22" x2="77.86" y2="341.46"
                        xlink:href="#linear-gradient" />
                </defs>
                <path
                    d="M20.06,197.71c18.08-22,43.87-36.24,64.28-56.13,10.28-10,19.16-21.43,30-30.77,24.91-21.38,58.68-30.24,91.5-30.57s65.18,7.15,97,15.11c47.27,11.82,95.36,25.31,135.07,53.56,14.2,10.1,27.07,21.92,39.9,33.7,12.08,11.1,24.64,22.93,29.68,38.54,8.2,25.38-5.59,52-11,78.08-2.08,10.08-3,20.69-8.09,29.64-12,21.05-40.48,24.14-64.68,24.78l-255.45,6.73c-49.1,1.3-102.23,1.41-141.92-27.54C2,315,3.47,296.45,4.35,270.24,5.29,242.38.45,221.58,20.06,197.71Z"
                    transform="translate(-3.97 -41.7)" fill="#5c77ff" opacity="0.24" style="isolation:isolate" />
                <rect x="374.48" y="170.79" width="107.75" height="146.23" rx="24.67" fill="#5c77ff" />
                <rect x="374.48" y="170.79" width="107.75" height="146.23" rx="24.67" fill="url(#linear-gradient)" />
                <rect x="410.4" y="170.79" width="17.96" height="112.02" opacity="0.28"
                    fill="url(#linear-gradient-2)" />
                <rect x="396.72" y="285.38" width="92.36" height="35.06" rx="10.77" fill="#5c77ff" />
                <rect x="401.85" y="289.93" width="84.11" height="27.1" rx="9.04" fill="url(#linear-gradient-3)" />
                <rect x="408.69" y="186.47" width="57.01" height="61.57" fill="url(#linear-gradient-4)" />
                <path
                    d="M387.25,182.49l-34.59-2.36L323.78,96.18,206.19,78.44l-42.47,43.15L150.8,106.94l45-52.7L341.76,74Zm-31.34-6.6,24.44,1.66L338.64,78.1,197.58,59,156.7,106.9l7.2,8.15,40.7-41.36L327.12,92.17Z"
                    transform="translate(-3.97 -41.7)" fill="#5c77ff" />
                <path
                    d="M387.25,182.49l-34.59-2.36L323.78,96.18,206.19,78.44l-42.47,43.15L150.8,106.94l45-52.7L341.76,74Zm-31.34-6.6,24.44,1.66L338.64,78.1,197.58,59,156.7,106.9l7.2,8.15,40.7-41.36L327.12,92.17Z"
                    transform="translate(-3.97 -41.7)" fill="url(#linear-gradient-5)" />
                <polygon
                    points="389.39 120.41 351.84 25.64 195.94 2.44 139.39 67.9 151.57 82.3 151.57 110.08 149.3 110.08 149.3 83.13 136.39 67.88 195.04 0 353.47 23.58 391.5 119.57 389.39 120.41"
                    fill="#5c77ff" />
                <path
                    d="M393.66,180.36,359.07,178,330.19,94,212.6,76.3l-42.46,43.15-12.93-14.64,45-52.7,146,19.78Zm-31.33-6.61,24.43,1.67L345.06,76,204,56.85l-40.88,47.91,7.2,8.16L211,71.56,333.53,90Z"
                    transform="translate(-3.97 -41.7)" fill="#5c77ff" />
                <polygon
                    points="359 135.5 354.47 132.85 368.6 108.73 344.12 102.24 358.58 81.79 334.86 75.86 347.41 56.44 325.12 49.13 318.67 34.1 296.81 47.08 283.67 29.18 263.17 42.52 251.96 25.45 236.69 38.38 222.7 20.39 202.01 38.07 183.6 37.35 185.65 57.8 169.59 54.92 168.92 71.91 163.67 71.7 165.12 50.93 179.74 51.41 179.65 33.68 200.16 32.75 222.91 14.52 237.47 30.84 252.84 19.65 264.66 37.1 284.12 23.14 298.19 41.03 320.04 27.56 329 44.89 353.39 54.35 343.25 72.55 364.01 79.29 351.95 99.37 375.05 105.8 359 135.5"
                    fill="#5c77ff" />
                <rect x="89.14" y="115.04" width="333.72" height="207.98" rx="19.29" fill="#5c77ff" />
                <rect x="91.42" y="112.76" width="328.37" height="207.22" rx="19.1" fill="url(#linear-gradient-6)" />
                <rect x="97.36" y="123.21" width="317.67" height="182.75" rx="17.64" fill="#fff" />
                <rect x="118.54" y="149.38" width="87.18" height="49.59" fill="#5c77ff" opacity="0.24"
                    style="isolation:isolate" />
                <path
                    d="M436.35,367.28v7.35a9.85,9.85,0,0,1-9.87,9.87H88.93a9.85,9.85,0,0,1-9.87-9.87v-7.35a9.67,9.67,0,0,1,.45-2.95v0a9.83,9.83,0,0,1,9.42-6.89H426.48a9.81,9.81,0,0,1,9.41,6.89v0A9.38,9.38,0,0,1,436.35,367.28Z"
                    transform="translate(-3.97 -41.7)" fill="#5c77ff" />
                <path
                    d="M435.89,364.3v0a9.85,9.85,0,0,1-9.41,6.93H88.93a9.86,9.86,0,0,1-9.42-6.93v0a9.83,9.83,0,0,1,9.42-6.89H426.48A9.81,9.81,0,0,1,435.89,364.3Z"
                    transform="translate(-3.97 -41.7)" fill="url(#linear-gradient-7)" />
                <path
                    d="M204.85,315.47h98a0,0,0,0,1,0,0v4a9.8,9.8,0,0,1-9.8,9.8H214.65a9.8,9.8,0,0,1-9.8-9.8v-4A0,0,0,0,1,204.85,315.47Z"
                    fill="url(#linear-gradient-8)" />
                <rect x="38.67" y="173.98" width="16.4" height="165.27" fill="#5c77ff" />
                <rect x="113.69" y="138.12" width="87.18" height="49.59" rx="5.48" fill="#fff" stroke="#5c77ff"
                    stroke-miterlimit="10" stroke-width="0.76" />
                <rect x="214.6" y="150.66" width="87.18" height="49.59" rx="5.48" fill="#fff" stroke="#5c77ff"
                    stroke-miterlimit="10" stroke-width="0.76" />
                <rect x="314.77" y="148.19" width="93.71" height="56.21" fill="#5c77ff" opacity="0.24"
                    style="isolation:isolate" />
                <rect x="115.62" y="215.37" width="288.55" height="15.17" fill="#5c77ff" opacity="0.24"
                    style="isolation:isolate" />
                <rect x="115.62" y="245.36" width="288.55" height="15.17" fill="#5c77ff" opacity="0.24"
                    style="isolation:isolate" />
                <polygon points="54.72 230.57 463.1 260.41 463.1 288.36 54.32 262.56 54.72 230.57" fill="#5c77ff" />
                <polygon points="54.72 230.57 463.1 260.41 463.1 288.36 54.32 262.56 54.72 230.57"
                    fill="url(#linear-gradient-9)" />
                <path
                    d="M82.46,286.36l1,.07L83.13,290c.59-.52,2.58-2.21,3.81-3.3l1.2.1-3.36,2.82,3,4.46-1.17-.1-2.51-3.79-1,.77-.21,2.74-.95-.08Z"
                    transform="translate(-3.97 -41.7)" fill="#fff" />
                <path
                    d="M96,291.3,92.42,291l-.2,2.57,4,.31-.19.81-4.78-.36.56-7.27,4.73.36-.06.83-3.79-.3-.18,2.23,3.62.28Z"
                    transform="translate(-3.97 -41.7)" fill="#fff" />
                <path
                    d="M104.92,292l-3.62-.28-.2,2.57,4,.31-.19.81L100.1,295l.56-7.27,4.73.37-.06.82-3.79-.29-.17,2.22,3.61.28Z"
                    transform="translate(-3.97 -41.7)" fill="#fff" />
                <path
                    d="M109.54,288.45l2.76.22a2.1,2.1,0,0,1,2.21,2.3,2.14,2.14,0,0,1-2.6,2l-1.73-.13-.23,3-1-.07Zm.71,3.54,1.68.13c1,.08,1.53-.36,1.59-1.21s-.5-1.37-1.36-1.44l-1.71-.13Z"
                    transform="translate(-3.97 -41.7)" fill="#fff" />
                <path
                    d="M127.45,293.45c-.15,2-1.41,3.69-3.7,3.52-2.14-.17-3.2-1.91-3-4s1.5-3.65,3.71-3.47C126.46,289.65,127.62,291.29,127.45,293.45Zm-5.72-.43c-.12,1.56.56,3,2.13,3.12s2.47-1.2,2.59-2.75-.54-2.95-2.14-3.07S121.85,291.47,121.73,293Z"
                    transform="translate(-3.97 -41.7)" fill="#fff" />
                <path
                    d="M132.8,290.25l-.34,4.37c-.13,1.72.78,2.26,1.7,2.33,1.09.09,1.9-.36,2-2l.34-4.37,1,.07-.33,4.33c-.19,2.36-1.54,2.93-3.09,2.81s-2.77-.95-2.6-3.18l.34-4.39Z"
                    transform="translate(-3.97 -41.7)" fill="#fff" />
                <path d="M143.4,291.9l-2.34-.19.06-.82,5.66.44-.06.82-2.35-.18-.5,6.44-1-.07Z"
                    transform="translate(-3.97 -41.7)" fill="#fff" />
                <path
                    d="M283.56,301.91l-.34,4.37c-.13,1.71.77,2.25,1.7,2.33,1.09.08,1.9-.36,2-2l.34-4.37,1,.07-.34,4.32c-.18,2.36-1.54,2.94-3.08,2.82s-2.78-1-2.6-3.18l.34-4.4Z"
                    transform="translate(-3.97 -41.7)" fill="#fff" />
                <path
                    d="M292.19,309.88l.56-7.26,1.28.1c.91,1.78,2.9,5.57,3.24,6.38h0c0-.93.09-2.08.18-3.26l.22-2.84.91.07-.56,7.27-1.2-.1c-.84-1.65-2.9-5.72-3.29-6.56h0c0,.83-.1,2.06-.21,3.38L293.1,310Z"
                    transform="translate(-3.97 -41.7)" fill="#fff" />
                <path
                    d="M303.17,303.42l2.59.2a3.31,3.31,0,0,1,3.15,3.83,3.42,3.42,0,0,1-3.74,3.44l-2.56-.2Zm.46,6.53,1.53.12c1.71.13,2.61-1,2.74-2.68a2.47,2.47,0,0,0-2.31-2.95l-1.52-.12Z"
                    transform="translate(-3.97 -41.7)" fill="#fff" />
                <path
                    d="M317.6,308.44l-3.61-.28-.2,2.57,4,.3-.19.82-4.78-.37.56-7.27,4.73.36-.06.83-3.79-.29-.18,2.22,3.62.28Z"
                    transform="translate(-3.97 -41.7)" fill="#fff" />
                <path
                    d="M322.87,309l-.25,3.2-.95-.08.56-7.26,2.84.22c1.5.11,2.22,1,2.14,2.12a1.66,1.66,0,0,1-1.38,1.61c.59.2,1.06.69,1,1.94l0,.32a5,5,0,0,0,0,1.45l-.93-.07a4.72,4.72,0,0,1,0-1.58v-.15c.08-1-.18-1.5-1.48-1.6Zm.06-.81,1.58.12c1.11.09,1.63-.3,1.69-1.11s-.41-1.25-1.39-1.33l-1.69-.13Z"
                    transform="translate(-3.97 -41.7)" fill="#fff" />
                <path
                    d="M339.58,311.48a2.77,2.77,0,0,1-3.06,1.93c-2.17-.17-3.14-1.91-3-4s1.38-3.68,3.59-3.51a2.67,2.67,0,0,1,2.69,2.42l-1-.08a1.85,1.85,0,0,0-1.83-1.54c-1.61-.12-2.37,1.29-2.48,2.75s.39,3,2.07,3.11a1.88,1.88,0,0,0,2-1.19Z"
                    transform="translate(-3.97 -41.7)" fill="#fff" />
                <path
                    d="M350.09,310.66c-.16,2-1.41,3.7-3.7,3.52-2.14-.16-3.2-1.91-3-4s1.5-3.64,3.7-3.47S350.25,308.5,350.09,310.66Zm-5.73-.43c-.12,1.57.57,3,2.13,3.13s2.47-1.21,2.59-2.76-.54-2.94-2.13-3.07S344.48,308.69,344.36,310.23Z"
                    transform="translate(-3.97 -41.7)" fill="#fff" />
                <path
                    d="M354,314.66l.56-7.26,1.27.09c.92,1.79,2.9,5.57,3.25,6.39h0c0-.94.1-2.09.19-3.27l.22-2.83.91.07-.56,7.26-1.21-.09c-.84-1.65-2.89-5.72-3.28-6.57h0c0,.83-.11,2.06-.21,3.38l-.22,2.9Z"
                    transform="translate(-3.97 -41.7)" fill="#fff" />
                <path
                    d="M365,313.48a1.66,1.66,0,0,0,1.74,1.48c1.13.09,1.62-.4,1.67-1.09s-.26-1.17-1.69-1.63c-1.68-.55-2.23-1.18-2.15-2.2s.95-1.9,2.56-1.78,2.37,1.19,2.4,2.15l-1-.07A1.42,1.42,0,0,0,367,309c-.89-.07-1.41.29-1.46,1s.33,1,1.58,1.39c2,.67,2.34,1.5,2.26,2.44-.09,1.17-1,2-2.8,1.88a2.45,2.45,0,0,1-2.6-2.33Z"
                    transform="translate(-3.97 -41.7)" fill="#fff" />
                <path d="M375.32,309.83l-2.34-.18.06-.82,5.66.43-.06.83-2.35-.19-.5,6.45-1-.08Z"
                    transform="translate(-3.97 -41.7)" fill="#fff" />
                <path
                    d="M383,313.69l-.25,3.2-.95-.07.57-7.27,2.84.22c1.49.11,2.22,1,2.13,2.12A1.65,1.65,0,0,1,386,313.5c.58.2,1.06.69,1,1.95l0,.31a5,5,0,0,0,0,1.45l-.94-.07a4.79,4.79,0,0,1,0-1.58v-.14c.08-1-.18-1.51-1.49-1.61Zm.06-.81,1.58.12c1.12.09,1.63-.29,1.69-1.11s-.41-1.25-1.39-1.32l-1.69-.13Z"
                    transform="translate(-3.97 -41.7)" fill="#fff" />
                <path
                    d="M392.66,310.34l-.34,4.38c-.14,1.71.77,2.25,1.7,2.32,1.08.09,1.89-.35,2-2l.34-4.38,1,.08L397,315c-.18,2.36-1.54,2.94-3.09,2.82s-2.77-1-2.6-3.19l.34-4.39Z"
                    transform="translate(-3.97 -41.7)" fill="#fff" />
                <path
                    d="M407.2,316.71a2.77,2.77,0,0,1-3.06,1.93c-2.17-.17-3.14-1.91-3-4s1.39-3.67,3.59-3.5a2.66,2.66,0,0,1,2.69,2.42l-1-.08a1.82,1.82,0,0,0-1.82-1.53c-1.61-.13-2.37,1.28-2.48,2.74s.39,3,2.07,3.12a1.87,1.87,0,0,0,2-1.21Z"
                    transform="translate(-3.97 -41.7)" fill="#fff" />
                <path d="M413.06,312.75l-2.34-.18.07-.83,5.66.44-.07.82-2.35-.18-.5,6.45-1-.08Z"
                    transform="translate(-3.97 -41.7)" fill="#fff" />
                <path d="M421.1,312.54l-.56,7.27-1-.08.56-7.26Z" transform="translate(-3.97 -41.7)" fill="#fff" />
                <path
                    d="M431.71,317c-.15,2-1.41,3.69-3.69,3.51s-3.21-1.9-3-4,1.51-3.65,3.71-3.48S431.88,314.81,431.71,317Zm-5.72-.44c-.12,1.57.57,3,2.13,3.13s2.47-1.21,2.59-2.75-.54-2.95-2.14-3.08S426.11,315,426,316.54Z"
                    transform="translate(-3.97 -41.7)" fill="#fff" />
                <path
                    d="M435.59,321l.57-7.26,1.27.1c.91,1.78,2.9,5.57,3.25,6.38h0c0-.94.09-2.08.19-3.26l.21-2.84.92.07-.57,7.26-1.2-.09c-.84-1.65-2.9-5.72-3.28-6.56h0c0,.83-.11,2.06-.21,3.38l-.23,2.9Z"
                    transform="translate(-3.97 -41.7)" fill="#fff" />
                <path
                    d="M446.62,319.79a1.67,1.67,0,0,0,1.74,1.49c1.13.09,1.62-.41,1.67-1.1s-.27-1.17-1.69-1.62c-1.68-.56-2.23-1.19-2.15-2.21s.94-1.9,2.56-1.78a2.27,2.27,0,0,1,2.4,2.15l-1-.07a1.41,1.41,0,0,0-1.51-1.29c-.89-.07-1.41.28-1.46,1s.33,1,1.58,1.39c2,.66,2.34,1.49,2.26,2.43-.09,1.17-1,2-2.8,1.89a2.47,2.47,0,0,1-2.61-2.34Z"
                    transform="translate(-3.97 -41.7)" fill="#fff" />
                <polygon points="242.79 244.33 366.79 253.74 243.65 274.26 152.15 267.42 242.79 244.33"
                    fill="url(#linear-gradient-10)" />
                <polygon points="53.32 269.36 457.43 204.17 459.83 228.97 53.52 295.75 53.32 269.36" fill="#5c77ff" />
                <polygon points="53.32 269.36 457.43 204.17 459.83 228.97 53.52 295.75 53.32 269.36"
                    fill="url(#linear-gradient-11)" />
                <rect x="457.35" y="173.98" width="16.4" height="165.27" fill="#5c77ff" />
                <rect x="38.67" y="173.98" width="16.4" height="165.27" fill="url(#linear-gradient-12)" />
                <rect x="457.35" y="173.98" width="16.4" height="165.27" fill="url(#linear-gradient-13)" />
                <polygon
                    points="485.82 335.75 450.02 335.75 454.01 312.55 456.67 296.95 461.83 266.95 471.71 266.85 477.87 296.95 481.06 312.55 485.82 335.75"
                    fill="#5c77ff" />
                <polygon
                    points="485.82 335.75 450.02 335.75 454.01 312.55 456.67 296.95 461.83 266.95 471.71 266.85 477.87 296.95 481.06 312.55 485.82 335.75"
                    fill="url(#linear-gradient-14)" />
                <rect x="438.23" y="334.54" width="58.39" height="8" rx="4" fill="#5c77ff" />
                <rect x="438.23" y="334.54" width="58.39" height="8" rx="4" fill="url(#linear-gradient-15)" />
                <polygon points="481.06 312.55 454.01 312.55 456.67 296.95 477.87 296.95 481.06 312.55"
                    fill="url(#linear-gradient-16)" />
                <polygon
                    points="59.96 335.75 24.16 335.75 28.15 312.55 30.81 296.95 35.97 266.95 45.85 266.85 52.01 296.95 55.2 312.55 59.96 335.75"
                    fill="#5c77ff" />
                <polygon
                    points="59.96 335.75 24.16 335.75 28.15 312.55 30.81 296.95 35.97 266.95 45.85 266.85 52.01 296.95 55.2 312.55 59.96 335.75"
                    fill="url(#linear-gradient-17)" />
                <rect x="12.37" y="334.54" width="58.39" height="8" rx="4" fill="#5c77ff" />
                <rect x="12.37" y="334.54" width="58.39" height="8" rx="4" fill="url(#linear-gradient-18)" />
                <polygon points="55.2 312.55 28.15 312.55 30.81 296.95 52.01 296.95 55.2 312.55"
                    fill="url(#linear-gradient-19)" />
                <circle cx="204.19" cy="23.71" r="9.9" fill="#5c77ff" />
                <circle cx="335.21" cy="42.06" r="11.65" fill="#5c77ff" />
                <circle cx="204.19" cy="23.71" r="9.9" fill="url(#linear-gradient-20)" />
                <circle cx="207.61" cy="22.29" r="9.9" fill="#5c77ff" />
                <circle cx="335.88" cy="41.67" r="9.9" fill="url(#linear-gradient-21)" />
                <circle cx="339.3" cy="40.25" r="9.9" fill="#5c77ff" />
                <polygon points="159.76 79.89 166.17 77.75 163.97 75.02 157.56 77.44 159.76 79.89" fill="#5c77ff" />
                <polygon points="149.35 67.78 155.77 65.64 153.57 62.9 147.16 65.32 149.35 67.78" fill="#5c77ff" />
                <rect x="149.15" y="108.58" width="2.34" height="22.35" fill="#5c77ff" />
                <rect x="195.94" y="2.44" width="3.02" height="10.58" fill="#5c77ff" />
                <polygon points="149.01 65.04 149.35 67.78 139.05 69.79 139.05 66.77 149.01 65.04" fill="#5c77ff" />
                <polygon points="344.21 30.19 350.64 24.67 351.84 25.64 345.19 32.26 344.21 30.19" fill="#5c77ff" />
                <path
                    d="M156.22,183a3,3,0,0,1-1,1.61,2.32,2.32,0,0,1-2,.5,3,3,0,0,1-1.87-1,3.19,3.19,0,0,1-.7-2.08,8.32,8.32,0,0,1,1-3.7,15.1,15.1,0,0,1,2.07-3l-.09.27c.15-4.37.38-8.74.65-13.11.15,4.38.26,8.75.29,13.12v.11l-.09.16c-.55,1-1.06,2.07-1.51,3.1a9.36,9.36,0,0,0-.87,3.1,2,2,0,0,0,1.37,2.1A2.36,2.36,0,0,0,156.22,183Z"
                    transform="translate(-3.97 -41.7)" fill="#5c77ff" />
                <circle cx="152.36" cy="142.25" r="2.67" fill="#5c77ff" opacity="0.24" style="isolation:isolate" />
                <path
                    d="M91.7,314.51l.94-.15.57,3.54c.45-.65,2-2.75,2.93-4.1l1.19-.19-2.61,3.54,3.91,3.64-1.15.18-3.33-3.1-.79,1,.43,2.71-.94.15Z"
                    transform="translate(-3.97 -41.7)" fill="#fff" />
                <path
                    d="M106.06,316.15l-3.58.57.4,2.55,3.93-.63v.84l-4.74.75L100.93,313l4.69-.75.13.81-3.76.6.36,2.21,3.58-.58Z"
                    transform="translate(-3.97 -41.7)" fill="#fff" />
                <path
                    d="M114.85,314.75l-3.57.57.4,2.54,3.93-.63v.84l-4.74.76-1.15-7.2,4.69-.75.13.82-3.76.6.35,2.2,3.58-.57Z"
                    transform="translate(-3.97 -41.7)" fill="#fff" />
                <path
                    d="M118.53,310.22l2.73-.43a2.12,2.12,0,0,1,2.69,1.72,2.16,2.16,0,0,1-2.08,2.53l-1.7.27.47,3-1,.15ZM120,313.5l1.66-.26c1-.16,1.41-.72,1.27-1.55a1.3,1.3,0,0,0-1.66-1.09l-1.69.27Z"
                    transform="translate(-3.97 -41.7)" fill="#fff" />
                <path
                    d="M137.11,310.9c.32,2-.51,3.92-2.78,4.29-2.11.34-3.56-1.11-3.89-3.18s.61-3.9,2.8-4.24C135.26,307.44,136.77,308.76,137.11,310.9Zm-5.67.92c.25,1.55,1.25,2.79,2.8,2.54s2.12-1.75,1.88-3.28-1.21-2.74-2.8-2.49S131.2,310.29,131.44,311.82Z"
                    transform="translate(-3.97 -41.7)" fill="#fff" />
                <path
                    d="M141.56,306.54l.69,4.33c.28,1.7,1.28,2,2.2,1.87,1.08-.17,1.76-.79,1.5-2.46l-.7-4.33,1-.15.68,4.28c.38,2.34-.81,3.22-2.34,3.46s-2.92-.28-3.27-2.49l-.7-4.35Z"
                    transform="translate(-3.97 -41.7)" fill="#fff" />
                <path d="M152.25,305.67l-2.32.37-.13-.82,5.61-.89.13.81-2.33.38,1,6.38-1,.15Z"
                    transform="translate(-3.97 -41.7)" fill="#fff" />
                <path
                    d="M215,294.81l.69,4.33c.27,1.7,1.27,2,2.19,1.87,1.08-.17,1.77-.79,1.5-2.46l-.69-4.33,1-.15.68,4.28c.38,2.34-.81,3.22-2.34,3.46s-2.92-.28-3.27-2.49L214,295Z"
                    transform="translate(-3.97 -41.7)" fill="#fff" />
                <path
                    d="M225.2,300.55l-1.15-7.2,1.26-.2c1.31,1.52,4.13,4.74,4.65,5.45h0c-.22-.91-.39-2.05-.57-3.22l-.46-2.81.91-.14,1.15,7.2-1.19.19c-1.2-1.41-4.16-4.89-4.73-5.62h0c.18.81.38,2,.58,3.34l.46,2.87Z"
                    transform="translate(-3.97 -41.7)" fill="#fff" />
                <path
                    d="M234.37,291.71l2.56-.41a3.3,3.3,0,0,1,4,3,3.42,3.42,0,0,1-2.84,4.22l-2.53.4Zm2,6.23,1.51-.24c1.7-.27,2.3-1.61,2-3.24a2.47,2.47,0,0,0-2.93-2.33l-1.51.24Z"
                    transform="translate(-3.97 -41.7)" fill="#fff" />
                <path
                    d="M249.57,293.21l-3.57.57.4,2.55,3.93-.63v.84l-4.74.75-1.15-7.19,4.69-.75.13.81-3.76.6.36,2.21,3.57-.58Z"
                    transform="translate(-3.97 -41.7)" fill="#fff" />
                <path
                    d="M254.83,292.57l.51,3.17-.94.15-1.15-7.2,2.81-.45c1.48-.24,2.39.44,2.57,1.56a1.66,1.66,0,0,1-1,1.89c.62.06,1.2.43,1.39,1.67l.05.31a5,5,0,0,0,.33,1.41l-.93.15a5,5,0,0,1-.37-1.53l0-.15c-.17-1-.53-1.42-1.82-1.21Zm-.13-.8,1.56-.25c1.11-.18,1.52-.67,1.39-1.48s-.69-1.12-1.66-1l-1.67.26Z"
                    transform="translate(-3.97 -41.7)" fill="#fff" />
                <path
                    d="M271.65,291a2.77,2.77,0,0,1-2.52,2.59c-2.16.34-3.5-1.12-3.83-3.16s.49-3.89,2.67-4.24a2.67,2.67,0,0,1,3.19,1.72l-1,.15a1.83,1.83,0,0,0-2.13-1.07c-1.59.25-2,1.8-1.77,3.25s1.07,2.81,2.74,2.55a1.88,1.88,0,0,0,1.65-1.64Z"
                    transform="translate(-3.97 -41.7)" fill="#fff" />
                <path
                    d="M281.68,287.79c.32,2-.51,3.92-2.77,4.29s-3.56-1.11-3.9-3.18.62-3.89,2.8-4.24C279.83,284.33,281.34,285.65,281.68,287.79Zm-5.67.92c.25,1.55,1.26,2.79,2.81,2.54s2.12-1.75,1.87-3.28-1.21-2.74-2.8-2.49S275.77,287.18,276,288.71Z"
                    transform="translate(-3.97 -41.7)" fill="#fff" />
                <path
                    d="M286.39,290.77l-1.15-7.19,1.26-.21c1.3,1.53,4.12,4.74,4.64,5.45h0c-.22-.91-.39-2-.57-3.21l-.45-2.82.9-.14,1.15,7.2L291,290c-1.2-1.41-4.16-4.89-4.73-5.62h0c.18.81.38,2,.59,3.33l.46,2.88Z"
                    transform="translate(-3.97 -41.7)" fill="#fff" />
                <path
                    d="M296.83,287.05a1.67,1.67,0,0,0,2,1c1.12-.18,1.48-.78,1.37-1.46s-.53-1.07-2-1.18c-1.76-.15-2.44-.63-2.6-1.64s.48-2.07,2.08-2.33a2.27,2.27,0,0,1,2.83,1.53l-1,.16a1.42,1.42,0,0,0-1.77-.91c-.88.14-1.3.61-1.19,1.29s.55.88,1.86,1c2.13.17,2.62.9,2.77,1.84.18,1.16-.53,2.2-2.29,2.48a2.46,2.46,0,0,1-3.07-1.66Z"
                    transform="translate(-3.97 -41.7)" fill="#fff" />
                <path d="M306,281.09l-2.32.37-.13-.81,5.61-.9.13.81-2.33.38,1,6.38-1,.15Z"
                    transform="translate(-3.97 -41.7)" fill="#fff" />
                <path
                    d="M314.42,283.05l.51,3.16-.95.15-1.15-7.2,2.82-.45c1.48-.23,2.39.45,2.57,1.57a1.66,1.66,0,0,1-1,1.89c.62.06,1.19.42,1.39,1.66l.05.31a5.13,5.13,0,0,0,.33,1.42l-.93.14a5,5,0,0,1-.37-1.53l0-.14c-.17-1-.53-1.42-1.82-1.22Zm-.13-.81,1.56-.25c1.11-.18,1.52-.67,1.39-1.47s-.69-1.12-1.66-1l-1.68.27Z"
                    transform="translate(-3.97 -41.7)" fill="#fff" />
                <path
                    d="M323,277.54l.69,4.33c.27,1.7,1.27,2,2.2,1.87s1.76-.79,1.5-2.46l-.7-4.33,1-.15.69,4.28c.37,2.34-.82,3.21-2.35,3.46s-2.91-.28-3.27-2.49l-.69-4.36Z"
                    transform="translate(-3.97 -41.7)" fill="#fff" />
                <path
                    d="M338.63,280.33a2.76,2.76,0,0,1-2.52,2.59c-2.16.35-3.5-1.11-3.83-3.15s.49-3.9,2.67-4.25a2.66,2.66,0,0,1,3.18,1.72l-.95.15a1.84,1.84,0,0,0-2.13-1.07c-1.59.26-2,1.81-1.77,3.25s1.07,2.82,2.74,2.55a1.88,1.88,0,0,0,1.65-1.63Z"
                    transform="translate(-3.97 -41.7)" fill="#fff" />
                <path d="M343.4,275.11l-2.31.37-.14-.81,5.61-.9.13.82-2.33.37,1,6.38-1,.16Z"
                    transform="translate(-3.97 -41.7)" fill="#fff" />
                <path d="M351.17,273l1.15,7.2-.95.15-1.16-7.2Z" transform="translate(-3.97 -41.7)" fill="#fff" />
                <path
                    d="M362.53,274.87c.31,2-.51,3.92-2.78,4.28s-3.56-1.1-3.89-3.17.61-3.9,2.79-4.25S362.19,272.73,362.53,274.87Zm-5.67.92c.25,1.55,1.25,2.79,2.8,2.54s2.12-1.75,1.88-3.28-1.22-2.74-2.8-2.49S356.61,274.26,356.86,275.79Z"
                    transform="translate(-3.97 -41.7)" fill="#fff" />
                <path
                    d="M367.23,277.85l-1.15-7.2,1.26-.2c1.3,1.52,4.12,4.74,4.65,5.45h0c-.22-.91-.39-2-.58-3.22l-.45-2.81.9-.14,1.15,7.2-1.19.19c-1.2-1.41-4.15-4.89-4.72-5.62h0c.19.81.38,2,.59,3.33l.46,2.88Z"
                    transform="translate(-3.97 -41.7)" fill="#fff" />
                <path
                    d="M377.67,274.12a1.67,1.67,0,0,0,2.05,1c1.11-.17,1.47-.77,1.36-1.45s-.53-1.08-2-1.19c-1.76-.14-2.45-.62-2.61-1.64s.48-2.07,2.08-2.32a2.26,2.26,0,0,1,2.84,1.53l-1,.15a1.4,1.4,0,0,0-1.76-.9c-.88.14-1.31.61-1.2,1.29s.55.88,1.87,1c2.13.16,2.61.9,2.76,1.83.19,1.16-.52,2.21-2.28,2.49a2.47,2.47,0,0,1-3.08-1.66Z"
                    transform="translate(-3.97 -41.7)" fill="#fff" />
                <circle cx="181.5" cy="261.15" r="3.21" fill="url(#linear-gradient-22)" />
                <circle cx="410.97" cy="222.6" r="3.21" fill="url(#linear-gradient-23)" />
                <circle cx="67.06" cy="281.11" r="3.21" fill="url(#linear-gradient-24)" />
            </svg>
        </div>

        <h1 id="title" class="display-3 font-medium m-0"></h1>
        <h2 id="description" class="title m-0 mt-3 max-w-lg mx-auto"></h2>
        <div class="mx-auto mt-6" style="width: 150px;">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.0"
                id="Layer_1" x="0px" y="0px" viewBox="0 0 97 28" style="enable-background:new 0 0 97 28;"
                xml:space="preserve">
                <style type="text/css">
                    .st0 {
                        fill: #122C47;
                    }

                    .st1 {
                        fill: #34529A;
                    }

                    .st2 {
                        fill: #69B8C7;
                    }

                    .st3 {
                        fill: #564C97;
                    }

                    .st4 {
                        fill: #4674B4;
                    }
                </style>
                <g>
                    <g>
                        <path class="st0"
                            d="M45.3,2.9c5.9,0,10.8,4.8,10.8,10.8c0,5.9-4.8,10.8-10.8,10.8s-10.8-4.8-10.8-10.8    C34.6,7.8,39.4,2.9,45.3,2.9 M45.3,0.9c-7.1,0-12.8,5.7-12.8,12.8s5.7,12.8,12.8,12.8s12.8-5.7,12.8-12.8S52.4,0.9,45.3,0.9    L45.3,0.9z" />
                    </g>
                    <g>
                        <path class="st0"
                            d="M75.5,2.9c5.9,0,10.8,4.8,10.8,10.8c0,5.9-4.8,10.8-10.8,10.8c-5.9,0-10.8-4.8-10.8-10.8    C64.7,7.8,69.6,2.9,75.5,2.9 M75.5,0.9c-7.1,0-12.8,5.7-12.8,12.8s5.7,12.8,12.8,12.8s12.8-5.7,12.8-12.8S82.6,0.9,75.5,0.9    L75.5,0.9z" />
                    </g>
                    <g>
                        <rect x="92.8" y="0.9" class="st0" width="2.1" height="25.6" />
                    </g>
                    <g>
                        <polygon class="st1" points="15.1,26.6 10.8,22.5 15.1,18.2 19.3,22.4   " />
                    </g>
                    <g>
                        <rect x="14" y="-3.2" transform="matrix(0.6981 -0.716 0.716 0.6981 -5.3579 14.9749)" class="st0"
                            width="2.1" height="34" />
                    </g>
                    <g>
                        <rect x="-1.9" y="12.7" transform="matrix(0.7098 -0.7044 0.7044 0.7098 -5.2849 14.6295)"
                            class="st0" width="34" height="2.1" />
                    </g>
                    <g>
                        <polygon class="st2" points="6.3,18 2,13.9 6.3,9.6 10.5,13.8   " />
                    </g>
                    <g>
                        <polygon class="st3" points="15,9.4 10.7,5.2 15,1 19.3,5.2   " />
                    </g>
                    <g>
                        <polygon class="st4" points="23.7,17.9 19.5,13.8 23.7,9.5 28,13.7   " />
                    </g>
                </g>
            </svg>
        </div>
    </div>
</div>