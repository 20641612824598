import { AfterViewInit, Component } from '@angular/core';
import { User } from '@core/api';

@Component({
  selector: 'net-root',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements AfterViewInit {

  title: HTMLElement;
  user: User;
  culture: string;

  constructor() { }

  ngAfterViewInit(): void {
    this.title = document.querySelector('#title');

    this.user = JSON.parse(localStorage.getItem('api_user'));
    this.culture = this.user?.culture?.slice(0, 2) || 'en';

    if ('tr' === this.culture) {
      document.title = 'Bakımdayız - XOOI';
      this.title.innerHTML = 'Bakım Zamanı';
    } else {
      document.title = 'We are in maintenance - XOOI';
      this.title.innerHTML = 'Maintenance Time';
    }
  }

}
