import { LOCATION_INITIALIZED } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export const DEFAULT_LOCALE = 'en';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

export function ApplicationInitializerFactory(translate: TranslateService, injector: Injector) {
  return async () => {
    await injector.get(LOCATION_INITIALIZED, Promise.resolve(null));

    const user = JSON.parse(localStorage.getItem('api_user'));
    const lang = user?.culture ?? (['tr-TR', 'tr'].includes(navigator.language) ? 'tr' : DEFAULT_LOCALE);

    translate.addLangs([lang]);
    translate.setDefaultLang(lang);
    try {
      await translate.use(lang).toPromise();
    } catch (err) {
    }
  };
}
