import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import { Response } from '@core/api';
import { Observable } from 'rxjs';
import { Language, LanguageFilterRequest } from '@core/api';
import { LanguageRequest } from './language.model';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  constructor(private http: HttpClient) { }

  list(): Observable<Response<Language[]>> {
    return this.http.post<Response<Language[]>>(environment.apiUrl + '/api/Language/List', {});
  }

  search(request: LanguageFilterRequest): Observable<Response<Language[]>> {
    return this.http.post<Response<Language[]>>(environment.apiUrl + '/api/Language/Search', request);
  }

  insert(request: LanguageRequest): Observable<Response<Language>> {
    return this.http.post<Response<Language>>(environment.apiUrl + '/api/Language/Insert', request);
  }

  update(request: LanguageRequest): Observable<Response<Language>> {
    return this.http.post<Response<Language>>(environment.apiUrl + '/api/Language/Update', request);
  }

  setDefault(request: LanguageRequest): Observable<Response<Language>> {
    return this.http.post<Response<Language>>(environment.apiUrl + '/api/Language/SetDefault', request);
  }

  updateStatus(request: LanguageRequest): Observable<Response<Language>> {
    return this.http.post<Response<Language>>(environment.apiUrl + '/api/Language/UpdateStatus', request);
  }

  delete(request: LanguageRequest): Observable<Response<Language>> {
    return this.http.post<Response<Language>>(environment.apiUrl + '/api/Language/Delete', request);
  }

  get() {
    return this.http.get<any>('./assets/i18n/en.json');
  }

}
