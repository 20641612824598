<input [matTooltip]="tooltip" #input matInput [tabIndex]="tabIndex" [formControl]="inputCtrl" [matAutocomplete]="auto"
    [placeholder]="placeholder" autocomplete="off" (blur)="onBlur()"
    [matTooltip]="'GENERAL.PLEASE_SELECT_A_CUSTOMER' | translate" [matTooltipDisabled]="!!this.account">
<mat-icon class="dropdown-icon" [ngClass]="{'disabled': disabled}" [icIcon]="icArrowDropDown" matSuffix
    (click)="openPanel($event)"></mat-icon>
<mat-autocomplete #auto="matAutocomplete" [panelWidth]="panelWidth" [autoActiveFirstOption]="true"
    [displayWith]="displayName" (optionSelected)="onSelect($event)" (closed)="onClosed()">
    <mat-option *ngIf="filteredOpportunities && filteredOpportunities.length === 0 && inputCtrl.value?.length > 0"
        disabled>
        {{'OPPORTUNITY.OPPORTUNITY_NOT_FOUND' | translate}}</mat-option>
    <mat-option class="autocomplete-option" *ngFor="let opportunity of filteredOpportunities" [value]="opportunity">
        <div>{{ opportunity.code }} {{ opportunity.name }} </div>
        <ng-container [ngTemplateOutlet]="showOrganizationAndStatus ? organizationAndStatus : defaultTemplate"
            [ngTemplateOutletContext]="{opportunity: opportunity}">
        </ng-container>>
    </mat-option>
</mat-autocomplete>
<mat-progress-spinner *ngIf="!!loading" diameter="16" mode="indeterminate"></mat-progress-spinner>

<ng-template #defaultTemplate let-opportunity="opportunity">
    <div class="description">{{ opportunity.account?.salesRouteDefinition?.name }}
        <span *ngIf="opportunity.totalMonthlyRevenue || opportunity.totalForOnceRevenue"> -
            {{ (opportunity.totalMonthlyRevenue ? opportunity.totalMonthlyRevenue : opportunity.totalForOnceRevenue)
            | currency: opportunity.transactionCurrency?.isoCurrencyCode :'symbol-narrow' }}
        </span>
    </div>
</ng-template>

<ng-template #organizationAndStatus let-opportunity="opportunity">
    <div class="description">{{ opportunity.organization?.name }}
        <span *ngIf="opportunity.opportunityStatus?.name"> -
            {{ ('OpportunityStatus.' + opportunity.opportunityStatus.name) | translate }}
        </span>
    </div>
</ng-template>