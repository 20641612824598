import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { Subject } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import icHistory from '@iconify/icons-ic/history';
import icFolderOpen from '@iconify/icons-fa-solid/folder-open';
import icHelpOutline from '@iconify/icons-ic/help-outline';
import icKeyboardArrowUp from '@iconify/icons-ic/keyboard-arrow-up';
import icKeyboardArrowDown from '@iconify/icons-ic/keyboard-arrow-down';

import { Permission } from '@core/auth/auth.enum';

import { SystemUserParameter } from '@core/api/system-user-parameter/system-user-parameter.model';
import { SystemUserParameterService } from '@core/api/system-user-parameter/system-user-parameter.service';
import {
  User,
  DocumentService,
  DocumentHistoryFilterRequest,
  DocumentSetting,
  DocumentTypeEnum,
  Assignment
} from '@core/api';

import { DocumentUploadType } from '../document-upload/document-upload.component.enum';
import { DocumentUploadOptions } from '../document-upload/document-upload.component.model';
import { DocumentListComponent } from '../document-list/document-list.component';
import { DocumentHistoryComponent } from '../document-history/document-history.component';
import { DocumentListComponentType } from '../document-list/document-list.component.enum';
import { getUser, hasUserPermission } from '@core/store';
import { Store } from '@ngrx/store';

@UntilDestroy()
@Component({
  selector: 'net-assignment-document[assignment]',
  templateUrl: './assignment-document.component.html',
  styleUrls: ['./assignment-document.component.scss'],
})

export class AssignmentDocumentComponent implements OnInit, OnDestroy {

  user: User;
  historyFilter: DocumentHistoryFilterRequest;
  documentSetting: DocumentSetting;
  systemUserParameter: SystemUserParameter;

  showList = false;
  showHistory = false;
  hasUploadPermission = false;

  listType = DocumentListComponentType;
  documentSettingSubject = new Subject();
  systemUserParameterSubject = new Subject();

  icHistory = icHistory;
  icFolderOpen = icFolderOpen;
  icHelpOutline = icHelpOutline;
  icKeyboardArrowUp = icKeyboardArrowUp;
  icKeyboardArrowDown = icKeyboardArrowDown;

  uploadType = DocumentUploadType;
  uploadData: { [key: string]: string | Blob; } = {};
  uploaderOptions: DocumentUploadOptions = { autoUpload: true };

  @ViewChild(DocumentListComponent, { static: false }) list: DocumentListComponent;
  @ViewChild(DocumentHistoryComponent, { static: false }) history: DocumentHistoryComponent;

  @Input()
  set assignment(assignment: Assignment) {
    this._assignment = assignment;

    if (assignment) {
      this.refreshList();
      this.refreshHistory();
      this.refreshUploadData();
      this.refreshDocumentSettings();

      this.systemUserParameterSubject.next();

      assignment.account ? this.uploaderOptions.queryString =
        `?documentTypeId=${DocumentTypeEnum.Other}&assignmentId=${assignment.assignmentId}&accountId=${assignment.account.accountId}` :
        this.uploaderOptions.queryString = `?documentTypeId=${DocumentTypeEnum.Other}&assignmentId=${assignment.assignmentId}`;
    }
  }
  get assignment() { return this._assignment; }
  private _assignment;

  @Input() submit = false;

  constructor(
    private store: Store,
    private documentService: DocumentService,
    private systemUserParameterService: SystemUserParameterService,
  ) {
    // Subscribe system user parameter subject and refresh each subject
    this.systemUserParameterSubject
      .asObservable()
      .pipe(
        filter(() => !!this.assignment && !!this.user),
        switchMap(() => this.systemUserParameterService.search({
          assignmentId: this.assignment.assignmentId,
          systemUserId: this.user.userId,
        })
        )
      )
      .subscribe(response => this.systemUserParameter = response.data);
  }

  ngOnInit(): void {
    // Fetch current user
    this.store.select(getUser).pipe(untilDestroyed(this)).subscribe(user => {
      this.user = user;
      this.systemUserParameterSubject.next();
    });

    // Fetch user permissions
    this.store.select(hasUserPermission(Permission.DOCUMENT_UPLOAD)).pipe(untilDestroyed(this)).subscribe(permission => {
      if ((this.assignment.createdBy === this.user.userId || this.assignment.assignedUserId === this.user.userId)
        && permission === true) {
        this.hasUploadPermission = true;
      }
    });
  }

  refreshList(): void {
    // Do not try to refresh list when hidden
    if (!this.showList) {
      return;
    }

    // Make sure list reference exists
    if (!this.list) {
      return;
    }

    this.list.refresh.next({
      filter: {
        assignmentId: this.assignment.assignmentId,
      }
    });
  }

  refreshHistory(): void {
    // Do not try to refresh history when hidden
    if (!this.showHistory) {
      return;
    }

    // Make sure history reference exists
    if (!this.history) {
      return;
    }

    this.history.refresh.next({
      filter: {
        assignmentId: this.assignment.assignmentId,
      }
    });
  }

  refreshDocumentSettings(): void {
    // Do not try to refresh when assignment not exists
    if (!this.assignment) {
      return;
    }

    // Fetch document settings
    this.documentService.setting({
      filter: {
        salesOrganizationId: this.assignment.salesOrganizationId,
      }
    }).subscribe(response => {
      this.documentSetting = response.data.results[0];
      this.uploaderOptions.maxFileSize = this.documentSetting.maxSize;
    });
  }

  refreshUploadData(): void {
    // Do not try to refresh when assignment not exists
    if (!this.assignment) {
      return;
    }

    this.uploadData = {
      assignmentId: this.assignment.assignmentId
    };
  }

  toggleHistory(): void {
    this.showHistory = !this.showHistory;

    if (this.showHistory) {
      setTimeout(() => this.refreshHistory());
    }
  }

  toggleList(): void {
    this.showList = !this.showList;

    if (this.showList) {
      setTimeout(() => this.refreshList());
    }
  }

  onUploadDone() {
    this.refreshList();
    this.refreshHistory();
    this.systemUserParameterSubject.next();
  }

  onDeleteDone() {
    this.refreshHistory();
    this.systemUserParameterSubject.next();
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy(): void { }
}
