import { Initial } from '@core/api';

import {
    SystemSettingActions, SystemSettingActionTypes
} from '@core/store';

export interface SystemSettingState {
    systemSettings: Initial;
    isLoading: boolean;
    errorMessage: string;
    errorCode: string;
}

export const SystemSettingState: SystemSettingState = {
    systemSettings: JSON.parse(localStorage.getItem('system_setting')),
    isLoading: false,
    errorMessage: null,
    errorCode: null,
};

export function systemSettingReducer(state = SystemSettingState, action: SystemSettingActions): SystemSettingState {
    switch (action.type) {
        case SystemSettingActionTypes.GET_SYSTEM_SETTING:
            return {
                ...state,
                errorMessage: null,
                isLoading: true
            };
        case SystemSettingActionTypes.GET_SYSTEM_SETTING_SUCCESS:
            return {
                ...state,
                systemSettings: action.payload,
                errorMessage: null,
                errorCode: null,
                isLoading: false,
            };
        case SystemSettingActionTypes.GET_SYSTEM_SETTING_ERROR:
            return {
                ...state,
                isLoading: false,
                errorCode: action.payload.errorCode,
                errorMessage: action.payload.message
            };
        case SystemSettingActionTypes.SET_SYSTEM_SETTING:
            const updatedSettings = {
              ...state.systemSettings,
              [action.payload.key]: action.payload.value
            };

            return {
                ...state,
                systemSettings: updatedSettings
            };
        default:
            return state;
    }
}
