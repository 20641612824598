import { debounceTime, finalize, map, tap } from 'rxjs/operators';

import {
  Account,
  AccountService,
  AccountSourceService,
  AccountStatusService,
  CustomerTypeService,
  SalesOrganizationService,
  SalesRouteDefinitionService,
  SectorMainService,
  SegmentService,
  SystemUserService,
  User
} from '@core/api';
import { ApiDataSource } from '@core/api/api.data-source';
import { TranslateService } from '@ngx-translate/core';
import { WarningService } from '../warning/warning.service';
import { BehaviorSubject } from 'rxjs';

export class AccountDataSource extends ApiDataSource<Account> {

  public isOpenedNewTab = false;

  public $salesOrganizations = this.salesOrganizationService.search({ systemUserId: this.user.userId }).pipe(
    map(response => response.data),
    map(statuses => statuses.sort((a, b) => (a.name > b.name) ? 1 : -1).map(item => {
      return {
        key: item.salesOrganizationId,
        value: item.name
      };
    }))
  );

  public $sectorMains = this.sectorMainService.list().pipe(
    map(response => response.data),
    map(statuses => statuses.map(item => {
      return {
        key: item.sectorMainId,
        value: this.translate.instant('SectorMain.' + item.name)
      };
    }))
  );

  public $salesRouteCities = this.salesRouteDefinitionService.salesRouteCityList({}).pipe(
    map(response => response.data.sort((a, b) => a.name.localeCompare(b.name))),
    map(cities => cities.map(item => {
      return {
        key: item.cityId,
        value: this.translate.instant('City.' + item.name)
      };
    }))
  );

  public $salesRouteCountries = this.salesRouteDefinitionService.salesRouteCountryList({}).pipe(
    map(response => response.data.sort((a, b) => a.name.localeCompare(b.name))),
    map(countries => countries.map(item => {
      return {
        key: item.countryId,
        value: this.translate.instant('Country.' + item.name)
      };
    }))
  );

  public $salesRouteCounties = this.salesRouteDefinitionService.salesRouteCountyList({}).pipe(
    map(response => response.data.sort((a, b) => a.name.localeCompare(b.name))),
    map(countries => countries.map(item => {
      return {
        key: item.countyId,
        value: item.name
      };
    }))
  );

  public $salesRoutes = this.salesRouteDefinitionService.userRoutes().pipe(
    map(response => response.data.sort((a, b) => a.name.localeCompare(b.name))),
    map(route => route.map(item => {
      return {
        key: item.salesRouteDefinitionId,
        value: item.name
      };
    }))
  );

  public $accountStatuses = this.accountStatusService.filterSearch({ filter: { isContract: false } }).pipe(
    map(response => response.data),
    map(statuses => statuses.map(item => {
      const translatedStatus = this.translate.instant('AccountStatus.' + item.name);
      return {
        key: { accountStatusId: item.accountStatusId, salesOrganizationId: item.salesOrganization?.salesOrganizationId },
        // tslint:disable-next-line:max-line-length
        value: item.salesOrganization && item.salesOrganization?.shortName ? `${translatedStatus} - (${item.salesOrganization.shortName})` : translatedStatus,
      };
    }))
  );

  public $customerTypes = this.customerTypeService.list({}).pipe(
    map(response => response.data.results.sort((a, b) => b.orderBy - a.orderBy)),
    map(customerTypes => customerTypes.filter((customerType) => customerType.isUsed || customerType.enableFlag)),
    map(customerTypes => customerTypes.map(item => {
      return {
        key: item.customerTypeId,
        value: item.name
      };
    }))
  );

  public $routeOwners = this.systemUserService
    .userSearch({ filter: { salesOrganizationIds: this.user.salesOrganizations.map(item => item) } }).pipe(
      map(response => response.data.results),
      map(statuses => statuses.map(item => {
        return {
          key: item.systemUserId,
          value: [item.firstName, item.lastName].join(' '),
        };
      }))
    );

  public $enableCustomerTypes = this.customerTypeService.list({}).pipe(
    map(response => response.data.results.sort((a, b) => b.orderBy - a.orderBy)),
    map(customerTypes => !!customerTypes.find((customerType) => customerType.enableFlag === true))
  );

  public $warnings = this.warningService.search({
    orderBy: 'orderBy',
    orderType: 'ASC',
    filter: {
      isActive: true
    }
  }).pipe(
    map(response => response.data.results),
    map(warnings => warnings.map(warning => {
      return {
        key: warning.warningId,
        value: warning.name
      };
    }))
  );


  public $segments = this.segmentService.list().pipe(
    map(response => response.data),
    map(segments => segments.map(segment => {
      return {
        key: segment.segmentId,
        value: segment.name
      };
    })
    ));

  public $sources = this.accountSourceService.list().pipe(
    map(response => response.data.sort((a, b) =>
      this.translate.instant('AccountSource.' + a.name).localeCompare(this.translate.instant('AccountSource.' + b.name))
    )),
    map(sources => sources.map(item => {
      return {
        key: item.accountSourceId,
        value: this.translate.instant('AccountSource.' + item.name)
      };
    }))
  );

  public request$ = new BehaviorSubject<{}>(null);

  constructor(
    private accountService: AccountService,
    private sectorMainService: SectorMainService,
    private salesOrganizationService: SalesOrganizationService,
    private salesRouteDefinitionService: SalesRouteDefinitionService,
    private accountStatusService: AccountStatusService,
    private customerTypeService: CustomerTypeService,
    private systemUserService: SystemUserService,
    private user: User,
    private translate: TranslateService,
    private warningService: WarningService,
    private segmentService: SegmentService,
    private accountSourceService: AccountSourceService,
    protected initialFilter?: any
  ) {
    super(initialFilter);
  }

  load(): void {
    if (!this.isOpenedNewTab) {

      // Init filter with data source's default filter
      const filterValue: any = { ...this.initialFilter, ...this.filter };

      // If filter keyword exists, filter data
      if (this.keyword) {
        filterValue.searchText = this.keyword;
      }

      // Update loading state
      this.loadingSubject.next(true);

      // Create request parameters
      const request = this.getRequest();
      if (!request.orderType) {
        request.orderType = 'ASC';
        request.orderBy = 'customerShortName';
      }

      if (this.paginator?.pageSize) {
        request.pageSize = this.paginator.pageSize;
      }

      // Add filters to request
      request.filter = filterValue;

      // Fetch data
      this.accountService
        .search(request)
        .pipe(
          tap(() => this.request$.next(request)),
          debounceTime(400),
          finalize(() => this.loadingSubject.next(false))
        ).subscribe(response => {
          // Update count and data subjects
          this.dataSubject.next(response.data.results);
          this.dataCountSubject.next(response.data.rowCount);
          this.rowCount$.next(response.data.rowCount);

          // Update data source's empty based row count
          this.empty = response.data.rowCount === 0;
        });

    }
    return this.loadingSubject.next(false);
  }
}
