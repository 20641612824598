import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import { DocumentVisibilitySettingInfo, DocumentVisibilitySettingInfoRequest } from './document-setting-visibility.model';
import { Response } from '../api.model';

@Injectable({
  providedIn: 'root'
})
export class DocumentVisibilitySettingService {

  constructor(private http: HttpClient) { }

  getDocumentSettingsVisibility(request: DocumentVisibilitySettingInfoRequest): Observable<Response<DocumentVisibilitySettingInfo[]>> {
    return this.http.post<Response<DocumentVisibilitySettingInfo[]>>(environment.apiUrl + '/api/DocumentVisibilitySettingInfo/Search', request);
  }

  updateDocumentSettingsVisibility(request: DocumentVisibilitySettingInfo[]): Observable<Response<DocumentVisibilitySettingInfo[]>> {
    return this.http.post<Response<DocumentVisibilitySettingInfo[]>>(environment.apiUrl + '/api/DocumentVisibilitySettingInfo/Update', request);
  }

  updateDocumentSettingsVisibilityCount(uuid: string) {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post(environment.apiUrl + '/api/DocumentVisibilitySettingInfo/DocumentVisibilityCount', JSON.stringify(uuid), {headers});
  }
}
