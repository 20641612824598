import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import { Response, FilterRequest, ContractAgreementType } from '@core/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContractAgreementTypeService {
  constructor(private http: HttpClient) { }

  list(request: FilterRequest): Observable<Response<ContractAgreementType[]>> {
    return this.http.post<Response<ContractAgreementType[]>>(environment.apiUrl + '/api/ContractAgreementType/List', request);
  }
}
