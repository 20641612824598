import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';

import { Response, AccountRevenue, AccountRevenueRequest, } from '@core/api';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class AccountRevenueService {
  constructor(private http: HttpClient) { }

  list(uuid): Observable<Response<AccountRevenue>> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<AccountRevenue>>(environment.apiUrl + '/api/AccountRevenue/List', JSON.stringify(uuid), { headers });
  }

  getWithProductionAccountCode(request: AccountRevenueRequest): Observable<Response<AccountRevenue>> {
    return this.http.post<Response<AccountRevenue>>(environment.apiUrl + '/api/AccountRevenue/GetWithProductionAccountCode', request);
  }
}
