import { Component, HostListener, Input, AfterViewInit } from '@angular/core';
import { FileSaverService } from 'ngx-filesaver';

import { MailFile, MailService, MailSignalRService, SystemSettingType } from '@core/api';

import { fileIcon } from 'src/app/mail/mail.helper';
import { Store } from '@ngrx/store';
import { getSystemSettingValue } from '@core/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { HttpResponse } from '@angular/common/http';
import { ToastService } from '../../services/toast.service';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'net-mail-attachment[mailId][file]',
  templateUrl: './mail-attachment.component.html',
  styleUrls: ['./mail-attachment.component.scss']
})
export class MailAttachmentComponent implements AfterViewInit {
  loading = false;
  scanFlag = false;

  downloadedAttachmentId: string;

  @Input() file: MailFile;
  @Input() mailId: string;
  @Input() directDownload = false;

  constructor(
    private fileSever: FileSaverService,
    private mailService: MailService,
    private store: Store,
    private mailSignalRService: MailSignalRService,
    private toastService: ToastService,
    private translate: TranslateService
  ) { }

  @HostListener('click') onClick() {
    if (this.loading) {
      return;
    }

    this.loading = true;

    if (this.directDownload) {
      this.downloadMailIntegration();
      return;
    }
    if (!this.scanFlag) {
      this.downloadFile();
    } else {
      this.scanFile();
    }
  }

  get icon(): object | null {
    return fileIcon(this.file.fileName);
  }

  get extension(): string {
    return this.file.fileName.split('.').pop().toUpperCase();
  }

  ngAfterViewInit(): void {
    this.store.select(getSystemSettingValue(SystemSettingType.MAIL_SCAN)).pipe(untilDestroyed(this))
      .subscribe(scanFlag => {
        this.scanFlag = scanFlag as boolean;

        if (!this.scanFlag || this.directDownload) {
          return;
        }

        this.listenHub();

      });
  }

  downloadMailIntegration() {
    this.mailService.downloadMailIntegration(this.file['attachmentId'])
      .subscribe(
        (response) => {
          if (response.status !== 200) {
            console.error(`Downloading file '${this.file.fileName}' failed.`);
            this.loading = false;
            return;
          }

          this.fileSever.save(response.body, this.file.fileName);
          this.loading = false;

        },
        (error) => {
          console.error(error);
          this.loading = false;
        }
      );

  }

  downloadFile() {
    this.mailService.downloadFile({
      fileName: this.file.fileName,
      messageId: this.mailId,
    })
      .subscribe(
        (response) => {
          if (response.status !== 200) {

            console.error(`Downloading file '${this.file.fileName}' failed.`);
            this.loading = false;
            return;

          }

          this.fileSever.save(response.body, this.file.fileName);
          this.loading = false;

          if (this.scanFlag) {

            this.directDownload = true;

            this.file = {
              ...this.file,
              attachmentId: this.downloadedAttachmentId
            };

          }

        },
        (error) => {
          console.error(error);
          this.loading = false;
        }
      );
  }

  scanFile() {
    this.mailService.scanFile({
      fileName: this.file.fileName,
      messageId: this.mailId,
    }).subscribe((response) => this.downloadedAttachmentId = response.data.attachmentId);

  }

  listenHub() {
    this.mailSignalRService.getHubConnection().on('mail', (response: { attachmentId: string; fileName: string; safeFlag: boolean; }) => {

      if (!response?.attachmentId) {
        return;
      }

      if (this.downloadedAttachmentId !== response.attachmentId) {
        return;
      }

      if (response.safeFlag) {
        this.downloadFile();
      } else {
        this.toastService.error(this.translate.instant('MAIL.NOT_SAFE'));
      }

      this.loading = false;
    });
  }


}
