import {
  Component,
  Input,
  ViewChild,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  OnInit,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import {
  DynamicField,
  DynamicFieldEntityAsString,
  DynamicFieldPicklist,
  DynamicFieldService,
} from '@core/api';
import { debounceTime, map } from 'rxjs/operators';

@Component({
  selector: 'net-input-dynamic-field-picklist',
  templateUrl: './input-dynamic-field-picklist.component.html',
  styleUrls: ['./input-dynamic-field-picklist.component.scss'],
})
export class InputDynamicFieldPicklistComponent implements OnInit {
  pageSize = 100;

  @Input() entityName: DynamicFieldEntityAsString;
  _field: DynamicField;
  @Input() set fieldId(fieldId) {
    if (fieldId) {
      this.getDynamicField(fieldId);
    }
  }
  get field(): DynamicField {
    return this._field;
  }

  control = new UntypedFormControl();

  _picklistItems;
  @Input() set picklistItems(picklistItems) {
    if (picklistItems) {
      this._picklistItems = picklistItems;
    }
  }

  get picklistItems() {
    return this._picklistItems;
  }

  @Output() selectOn = new EventEmitter();

  @ViewChild('select') select: MatSelect;
  constructor(
    private dynamicFieldService: DynamicFieldService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void { }

  picklistValuesSearchWithKeyword(field: DynamicField) {
    const payload = {
      activeFlag: true,
      page: 1,
      pageSize: this.pageSize,
      filter: {
        dynamicFieldId: field.pickListId ?? field.dynamicFieldId,
        ...(field.selectedDynamicFieldPicklistValues
          ? {
            excludeDynamicFieldPickListIds:
              field.selectedDynamicFieldPicklistValues.map(
                (x) => x.dynamicFieldPickListId
              ),
          }
          : {}),
        name: field.picklistValuesKeyword,
        pagination: true,
      },
    };

    field.picklistValuesLoading = true;

    this.dynamicFieldService
      .picklistPaginationSearch(payload)
      .pipe(
        map((response) => response.data),
        map((data) => {
          field.currentPage = data.currentPage;
          field.pageCount = data.pageCount;
          field.rowCount = data.rowCount;
          const selected =
            field.selectedDynamicFieldPicklistValues?.filter((x) =>
              x.name
                .toLowerCase()
                .includes(field.picklistValuesKeyword.toLowerCase())
            ) ?? [];
          field.picklistValues = selected
            ? [...selected, ...data.results]
            : data.results;

          // Update field validity and trigger change detection on next cycle
          setTimeout(() => {
            // Update validity when picklist fetched
            const panel = this.select?.panel?.nativeElement as HTMLDivElement;
            if (panel) {
              panel.scrollTop = 0;
            }

            // Trigger change detection for initiate default values
            this.changeDetectorRef.detectChanges();
          });
          return field.picklistValues;
        })
      )
      .subscribe(() => {
        field.picklistValuesLoading = false;
      });
  }

  picklistValuesSearchWithScroll(field: DynamicField) {
    const pageSize = this.pageSize;
    const page = field.currentPage ? field.currentPage + 1 : 1;
    const payload = {
      activeFlag: true,
      page,
      pageSize,
      filter: {
        dynamicFieldId: field.pickListId ?? field.dynamicFieldId,
        ...(field.selectedDynamicFieldPicklistValues
          ? {
            excludeDynamicFieldPickListIds:
              field.selectedDynamicFieldPicklistValues.map(
                (x) => x.dynamicFieldPickListId
              ),
          }
          : {}),
        ...(field.picklistValuesKeyword
          ? { name: field.picklistValuesKeyword }
          : {}),
        pagination: true,
      },
    };

    field.picklistValuesLoading = true;

    this.dynamicFieldService
      .picklistPaginationSearch(payload)
      .pipe(
        map((response) => response.data),
        map((data) => {
          field.picklistValues = field.picklistValues
            ? [...new Set([...field.picklistValues, ...data.results])]
            : data.results;
          if (!field.picklistValuesKeyword) {
            field.picklistValuesFiltered = field.picklistValues;
            field.prevCurrentPage = data.currentPage;
            field.prevPageCount = data.pageCount;
            field.prevRowCount = data.rowCount;
          }
          field.currentPage = data.currentPage;
          field.pageCount = data.pageCount > 0 ? data.pageCount : data.currentPage;
          field.rowCount = data.rowCount;

          if (this.picklistItems) {
            this.control.patchValue(this.picklistItems, { emitEvent: true });
          }

          // Update field validity and trigger change detection on next cycle
          setTimeout(() => {
            // Trigger change detection for initiate default values
            this.changeDetectorRef.detectChanges();
          });
          return field.picklistValues;
        })
      )
      .subscribe(() => {
        field.picklistValuesLoading = false;
      });
  }

  checkKey(event) {
    if (event.code === 'Space') {
      event.stopPropagation();
    }
  }

  onSelectAllPicklistValues(field: DynamicField) {
    if (field.pageCount === field.currentPage) {
      field.selectedDynamicFieldPicklistValues = [
        ...new Set([
          ...field.selectedDynamicFieldPicklistValues,
          ...field.picklistValues,
        ]),
      ].filter(picklist => picklist.activeFlag || picklist.usedFlag);
      this.control.patchValue(
        field.selectedDynamicFieldPicklistValues.map(
          (value) => value.dynamicFieldPickListId
        )
      );
      return;
    }
    if (field.picklistValuesKeyword) {
      this.picklistSelectAllRequestWithKeyword(field);
    } else {
      this.picklistSelectAllRequest(field);
    }
  }

  onSelectedPickList(value: string, field: DynamicField) {
    const formControlId = field.dynamicFieldId;

    // if (!field.multipleValueFlag) {
    //   if (
    //     field.selectedDynamicFieldPicklistValues[0]?.dynamicFieldPickListId ===
    //     value
    //   ) {
    //     return;
    //   }
    //   field.selectedDynamicFieldPicklistValues = field.picklistValues.filter(
    //     (item) => item.dynamicFieldPickListId === value
    //   );
    //   return;
    // }

    const index =
      field.selectedDynamicFieldPicklistValues?.findIndex(
        (picklistValue) => picklistValue.dynamicFieldPickListId === value
      ) ?? -1;

    if (index === -1) {
      field.selectedDynamicFieldPicklistValues.push(
        field.picklistValues.find((val) => val.dynamicFieldPickListId === value)
      );
    } else {
      field.selectedDynamicFieldPicklistValues.splice(index, 1);
    }
  }

  onDeselectAllPicklistValues(field: DynamicField) {
    if (field.picklistValuesKeyword) {
      field.selectedDynamicFieldPicklistValues = [
        ...field.selectedDynamicFieldPicklistValues?.filter(
          (picklistValue) =>
            !picklistValue.name
              .toLowerCase()
              .includes(field.picklistValuesKeyword.toLowerCase())
        ),
      ];
      this.control.patchValue(
        field.selectedDynamicFieldPicklistValues.map(
          (value) => value.dynamicFieldPickListId
        ),
        { emitEvent: true }
      );
    } else {
      field.selectedDynamicFieldPicklistValues = [];
      this.control.patchValue([], { emitEvent: true });
    }
  }

  onClosedSelectPicklist(field: DynamicField) {
    if (field.picklistValuesKeyword) {
      field.pageCount = field.prevPageCount;
      field.currentPage = field.prevCurrentPage;
      field.rowCount = field.prevRowCount;
    }
    field.picklistValues = Object.values(
      [
        ...field.selectedDynamicFieldPicklistValues,
        ...field.picklistValuesFiltered,
      ].reduce((acc, cur) => Object.assign(acc, { [cur.name]: cur }), {})
    );
    field.picklistValuesKeyword = '';
    field.picklistValuesKeywordFormControl.setValue('', { emitEvent: false });
    this.control.patchValue(field.selectedDynamicFieldPicklistValues.map((value) => value.dynamicFieldPickListId));
    this.onSelectDynamicFieldPicklistItems(
      field.selectedDynamicFieldPicklistValues
    );
  }

  createPayload(dynamicFieldId, dynamicFieldPickListIds = null) {
    return {
      activeFlag: true,
      page: 1,
      pageSize: Array.isArray(dynamicFieldPickListIds)
        ? dynamicFieldPickListIds.length > 0
          ? dynamicFieldPickListIds.length
          : this.pageSize
        : this.pageSize,
      filter: {
        dynamicFieldId,
        ...(Array.isArray(dynamicFieldPickListIds)
          ? { dynamicFieldPickListIds }
          : dynamicFieldPickListIds
            ? { dynamicFieldPickListIds: [dynamicFieldPickListIds] }
            : {}),
        pagination: true,
      },
    };
  }

  request(field: DynamicField, ctrl) {
    let payload;
    field.selectedDynamicFieldPicklistValues = [];
    if (field.multipleValueFlag) {
      payload = this.createPayload(
        field.pickListId ?? field.dynamicFieldId,
        ctrl
      );
    } else {
      payload = this.createPayload(
        field.pickListId ?? field.dynamicFieldId,
        ctrl
      );
    }

    if (payload?.filter?.dynamicFieldPickListIds && payload?.filter?.dynamicFieldPickListIds.length > 0) {
      this.getSelectedDynamicFieldPicklistItems(field, payload);
    } else {
      this.picklistValuesSearchWithScroll(field);
    }
  }

  getSelectedDynamicFieldPicklistItems(field: DynamicField, payload) {
    field.picklistValuesLoading = true;

    this.dynamicFieldService
      .picklistPaginationSearch(payload)
      .pipe(
        map((response) => response.data),
        map((data) => {
          field.picklistValues = data.results;
          field.selectedDynamicFieldPicklistValues = data.results;
          return field.picklistValues;
        })
      )
      .subscribe(() => {
        this.picklistValuesSearchWithScroll(field);
      });
  }

  picklistSelectAllRequest(field: DynamicField) {
    const payload = {
      activeFlag: true,
      page: 1,
      pageSize: field.rowCount,
      filter: {
        dynamicFieldId: field.pickListId ?? field.dynamicFieldId,
        ...(field.selectedDynamicFieldPicklistValues
          ? {
            excludeDynamicFieldPickListIds:
              field.selectedDynamicFieldPicklistValues.map(
                (x) => x.dynamicFieldPickListId
              ),
          }
          : {}),
        name: field.picklistValuesKeyword,
        pagination: true,
      },
    };

    field.picklistValuesLoading = true;

    this.dynamicFieldService
      .picklistPaginationSearch(payload)
      .pipe(
        map((response) => response.data),
        map((data) => {
          const formControlId = field.dynamicFieldId;
          field.currentPage = data.currentPage;
          field.pageCount = data.pageCount;
          field.prevPageCount = data.pageCount;
          field.prevCurrentPage = data.currentPage;
          field.prevRowCount = data.rowCount;
          field.picklistValues = [
            ...field.selectedDynamicFieldPicklistValues,
            ...data.results,
          ];
          field.selectedDynamicFieldPicklistValues = [
            ...field.selectedDynamicFieldPicklistValues,
            ...data.results,
          ];
          field.picklistValuesFiltered = data.results;
          setTimeout(() => {
            this.control.patchValue(
              field.selectedDynamicFieldPicklistValues.map(
                (value) => value.dynamicFieldPickListId
              )
            );
            // Trigger change detection for initiate default values
            this.changeDetectorRef.detectChanges();
          });
          return field;
        })
      )
      .subscribe(() => {
        field.picklistValuesLoading = false;
      });
  }

  picklistSelectAllRequestWithKeyword(field: DynamicField) {
    const payload = {
      activeFlag: true,
      page: 1,
      pageSize: field.rowCount,
      filter: {
        dynamicFieldId: field.pickListId ?? field.dynamicFieldId,
        ...(field.selectedDynamicFieldPicklistValues
          ? {
            excludeDynamicFieldPickListIds:
              field.selectedDynamicFieldPicklistValues.map(
                (x) => x.dynamicFieldPickListId
              ),
          }
          : {}),
        name: field.picklistValuesKeyword,
        pagination: true,
      },
    };

    field.picklistValuesLoading = true;

    this.dynamicFieldService
      .picklistPaginationSearch(payload)
      .pipe(
        map((response) => response.data),
        map((data) => {
          const formControlId = field.dynamicFieldId;
          field.pageCount = field.prevPageCount;
          field.currentPage = field.prevCurrentPage;
          field.rowCount = field.prevRowCount;
          const selected =
            field.selectedDynamicFieldPicklistValues?.filter((x) =>
              x.name.includes(field.picklistValuesKeyword)
            ) ?? [];
          if (!field.picklistValuesFiltered) {
            field.picklistValuesFiltered = field.picklistValues;
          }
          field.picklistValues = selected
            ? [...selected, ...data.results]
            : data.results;
          if (
            field.selectedDynamicFieldPicklistValues &&
            field.selectedDynamicFieldPicklistValues.length > 0
          ) {
            field.selectedDynamicFieldPicklistValues = [
              ...field.selectedDynamicFieldPicklistValues,
              ...data.results,
            ];
            this.control.patchValue(
              field.selectedDynamicFieldPicklistValues.map(
                (value) => value.dynamicFieldPickListId
              )
            );
          } else {
            field.selectedDynamicFieldPicklistValues = field.picklistValues;
            this.control.patchValue(
              field.picklistValues.map((value) => value.dynamicFieldPickListId)
            );
          }

          setTimeout(() => {
            // Trigger change detection for initiate default values
            this.changeDetectorRef.detectChanges();
          });
          return field;
        })
      )
      .subscribe(() => {
        field.picklistValuesLoading = false;
      });
  }

  getDynamicField(dynamicFieldId) {
    this.dynamicFieldService.get(dynamicFieldId).subscribe((response) => {
      this._field = response.data;
      this.picklistItems
        ? this.request(this._field, this.picklistItems)
        : this.picklistValuesSearchWithScroll(this._field);
      this._field.picklistValuesKeywordFormControl = new UntypedFormControl();
      this._field.currentPage = 0;
      this._field.selectedDynamicFieldPicklistValues = [];
      this._field.picklistValuesKeywordFormControl.valueChanges
        .pipe(debounceTime(400))
        .subscribe((value: string) => {
          if (value.startsWith(' ')) {
            return;
          }
          this.field.picklistValuesKeyword = value;
          if (!!value) {
            this.picklistValuesSearchWithKeyword(this.field);
          } else {
            this.field.picklistValues = [...this.field.picklistValuesFiltered];
            this.field.picklistValues = Object.values(
              [...this.field.selectedDynamicFieldPicklistValues, ...this.field.picklistValuesFiltered]
                .reduce((acc, cur) => Object.assign(acc, { [cur.name]: cur }), {}));
            this.field.pageCount = this.field.prevPageCount;
            this.field.currentPage = this.field.prevCurrentPage;
            this.field.rowCount = this.field.prevRowCount;
            this.control.patchValue(this.field.selectedDynamicFieldPicklistValues.map((picklist) => picklist.dynamicFieldPickListId));
            this.changeDetectorRef.detectChanges();
          }
        });
    });
  }

  getPicklistValuesFiltered(field: DynamicField): DynamicFieldPicklist[] {
    return field.picklistValues?.filter(value => value.activeFlag || value.usedFlag);
  }

  onSelectDynamicFieldPicklistItems(selectedItems: DynamicFieldPicklist[]) {
    this.selectOn.emit({
      items: selectedItems.map((selected) => selected.dynamicFieldPickListId),
      multipleValueFlag: this.field.multipleValueFlag,
    });
  }
}
