import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CardProperty, CardPropertyRequest, Response } from '@core/api';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CardPropertyService {

  constructor(private http: HttpClient) { }

  insert(request: CardPropertyRequest): Observable<Response<CardProperty>> {
    return this.http.post<Response<CardProperty>>(environment.apiUrl + '/api/CardProperty/Insert', request);
  }
}
