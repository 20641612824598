import examples from 'libphonenumber-js/examples.mobile.json';
import { AsYouType, CountryCode, getExampleNumber } from 'libphonenumber-js';

export function maskPhone(country: CountryCode = 'TR', isLength: boolean = false) {
  // Create default input for TR
  const defaultInput = new AsYouType(country);

  // Update default input with example TR phone number
  defaultInput.input(getExampleNumber(country, examples)?.formatInternational());

  if (isLength) {
    const possibleLength = getExampleNumber(country, examples).nationalNumber.split('').length + 1;
    return { possibleLength };
  } else {
    return {
      guide: true,
      mask: (value) => {
        const valueInput = new AsYouType();

        // Update value input
        valueInput.input(value);

        // Init default template and calling code
        let hasSign = defaultInput.getNumber()?.formatInternational().indexOf('+') > -1;
        let template = defaultInput.getTemplate();

        // If value is possible number replace template and calling code with given phone number
        if (valueInput.getNumber() && valueInput.getNumber().isPossible()) {
          hasSign = valueInput.getNumber()?.formatInternational().indexOf('+') > -1;
          template = valueInput.getTemplate();
        }

        // Create mask from template
        return template.split('').map((char, index) => {
          if (index === 0 && hasSign) {
            return '+';
          }

          if (char === 'x') {
            return /\d/;
          }

          return char;
        });
      }
    };
  }
}
