import { SystemSettingType } from '@core/api';
import { SystemSettingState } from '@core/store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectSystemSettingsState = createFeatureSelector('systemSetting');

export const getSystemSetting = createSelector(
  selectSystemSettingsState,
  (state: SystemSettingState) => state?.systemSettings
);

export const getSystemSettingValue = (key: SystemSettingType) => createSelector(
  selectSystemSettingsState,
  (state: SystemSettingState) => {
    const systemSettingKeys = Object.keys(state.systemSettings);

    if (systemSettingKeys.length === 0 || !systemSettingKeys.includes(key)) {
      return false;
    }

    return state.systemSettings[key];
  },
);
