import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, DoCheck, ElementRef,
  EventEmitter, HostBinding, Input, OnDestroy, Optional, Output,
  Self, ViewChild
} from '@angular/core';
import { ControlValueAccessor, NgControl, UntypedFormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatFormFieldControl } from '@angular/material/form-field';
import { ApiService, Contact } from '@core/api';
import { Subject } from 'rxjs';
import icCancel from '@iconify/icons-ic/twotone-cancel';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';

import { COMMA, ENTER } from '@angular/cdk/keycodes';

@Component({
  selector: 'net-select-contact',
  templateUrl: './select-contact.component.html',
  styleUrls: ['./select-contact.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: SelectContactComponent
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectContactComponent implements OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<Contact[] | null>  {

  static nextId = 0;
  icCancel = icCancel;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  loading = false;
  focused = false;
  errorState = false;
  controlType = 'select-contact';
  describedBy = '';
  stateChanges = new Subject<void>();

  contacts: Contact[] = [];
  inputCtrl = new UntypedFormControl();

  @ViewChild('auto') auto: MatAutocomplete;
  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  @Input()
  get salesOrganizationId(): string { return this._salesOrganizationId; }
  set salesOrganizationId(salesOrganizationId: string) {
    this._salesOrganizationId = salesOrganizationId;
    if (!salesOrganizationId) {
      this.disabled = true;
    } else {
      this.disabled = false;
    }

    this.reset();

  }
  private _salesOrganizationId: string;

  @Input() tabIndex;

  @Input() panelWidth: string | number;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    if (value || !this.salesOrganizationId) {
      this.inputCtrl.disable();
    } else {
      this.inputCtrl.enable();
    }

    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): Contact[] | null { return this._value; }
  set value(value: Contact[] | null) {
    this._value = !!value ? value : [];
    this.onChange(value);
    this.stateChanges.next();
  }
  private _value: Contact[] = [];

  @Input()
  get click(): boolean { return this._click; }
  set click(value: boolean) {
    this._click = value;
    this.stateChanges.next();
  }
  private _click = true;

  @Output() selectionChange: EventEmitter<Contact> = new EventEmitter<Contact>();

  @HostBinding('id') id = `select-contact-${SelectContactComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  get shouldLabelFloat() { return this.focused || !this.empty; }

  get empty() { return !this.value; }

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private apiService: ApiService,
    private cdr: ChangeDetectorRef,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Material form field implementation
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    // Listen input control value changes and filter results
    this.inputCtrl.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      filter(value => typeof value === 'string'),
      map(value => value.toString().trim()),
      filter(value => value.length > 2),
      tap(() => {
        this.loading = true;
        this.contacts = [];
        // this.value = null;
      }),
      switchMap(value => this.apiService.contact.elasticSearch({
        filter: {
          contactFullName: value,
          ...this.salesOrganizationId ? { salesOrganizationIds: [this.salesOrganizationId] } : {}
        },
        pageSize: 50
      })),
      tap(() => this.loading = false)
    ).subscribe(response => {
      this.contacts = response.data.results.filter(contact => !this.value.map(c => c.contactId).includes(contact.contactId));
      this.cdr.detectChanges();
    });
  }

  onBlur() {
    // On blur with nothing selected reset component
    if (this.ngControl.dirty && !this.auto.isOpen && !this.value) {
      this.reset();
    }
  }

  onClosed() {
    // On panel close with nothing selected reset component
    if (this.ngControl.dirty && !this.value) {
      this.reset();
    }

    this.contacts = this.contacts.filter(contact => !this.value.map(c => c.contactId).includes(contact.contactId));
  }

  onSelect(event: MatAutocompleteSelectedEvent) {

    const selectedCont = this.value.find(c => c.contactId === event.option.value.contactId);

    if (selectedCont) {
      return;
    }

    this.value.push(event.option.value);
    this.onChange(this.value);
    this.input.nativeElement?.blur();
    this.inputCtrl.setValue(null);
  }

  remove(contact: Contact) {
    this.value = this.value.filter(c => c.contactId !== contact.contactId);
  }

  displayName = (contact: Contact) => contact ? contact.firstName + ' ' + contact.lastName : '';

  reset() {
    if (this.inputCtrl.pristine) {
      return;
    }

    this.value = null;
    this.contacts = [];
    this.inputCtrl.setValue(null);
    this.inputCtrl.markAsPristine();
  }

  onChange = (_: any) => { };
  onTouched = () => { };
  onContainerClick = () => { };
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');

  writeValue(value: Contact[]) {
    this.value = value;
    this.cdr.detectChanges();
  }

  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }
}
