import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { InitialService } from '@core/api';
import {
    GetSalesOrganizationSettingError, GetSalesOrganizationSettingSuccess,
    SalesOrganizationSettingActionTypes
} from '@core/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthService } from '@core/auth/auth.service';

@Injectable()
export class SalesOrganizationSettingEffects {
    constructor(
        private initialService: InitialService,
        private actions$: Actions,
        private authService: AuthService
    ) { }

    salesOrganizationSetting$ = createEffect(() => this.actions$.pipe(
        ofType(SalesOrganizationSettingActionTypes.GET_SALES_ORGANIZATION_SETTING),
        switchMap(() => this.initialService.initialSettings(this.authService.authUser.userId)),
        map(response => new GetSalesOrganizationSettingSuccess(response)),
        catchError(error => of(new GetSalesOrganizationSettingError(error)))
    ));
}
