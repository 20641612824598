import { combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Component, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { Permission } from '@core/auth/auth.enum';
import { getSystemSettingValue, hasUserPermission } from '@core/store';
import { SystemSettingType, SystemSettingValue } from '@core/api';
import icAdd from '@iconify/icons-ic/twotone-add';
import icBusinessCenter from '@iconify/icons-ic/twotone-business-center';
import icDateRange from '@iconify/icons-ic/twotone-date-range';
import icPersonPin from '@iconify/icons-ic/twotone-person-pin';
import icUserCircle from '@iconify/icons-ic/twotone-supervised-user-circle';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import {
  ActivityFormDialogComponent
} from '../activity-form-dialog/activity-form-dialog.component';
import { ContactFormDialogComponent } from '../contact-form-dialog/contact-form-dialog.component';
import {
  CustomerSearchDialogComponent
} from '../customer-search-dialog/customer-search-dialog.component';
import {
  CustomerSearchDialogResult
} from '../customer-search-dialog/customer-search-dialog.component.model';
import {
  OpportunityFormDialogComponent
} from '../opportunity-form-dialog/opportunity-form-dialog.component';
import { QuickActionComponentAnimations } from './quick-action.component.animations';
import { QuickActionButton } from './quick-action.component.model';

@UntilDestroy()
@Component({
  selector: 'net-quick-action',
  templateUrl: './quick-action.component.html',
  styleUrls: ['./quick-action.component.scss'],
  animations: [QuickActionComponentAnimations]
})
export class QuickActionComponent implements OnDestroy {

  icAdd = icAdd;
  display = false;
  isActive = true;

  buttons: QuickActionButton[] = [];
  activeButtons: QuickActionButton[] = [];

  @HostListener('document:click', ['$event']) documentClick(event) {
    if (false === this.elementRef.nativeElement.contains(event.target)) {
      this.hide();
    }
  }

  constructor(
    private elementRef: ElementRef,
    private router: Router,
    private dialog: MatDialog,
    private store: Store,
    private translate: TranslateService
  ) {
    combineLatest([
      this.store.select(hasUserPermission(Permission.ACCOUNT_INSERT)).pipe(untilDestroyed(this)),
      this.store.select(hasUserPermission(Permission.ACTIVITY_INSERT)).pipe(untilDestroyed(this)),
      this.store.select(hasUserPermission(Permission.OPPORTUNITY_INSERT)).pipe(untilDestroyed(this)),
      this.store.select(hasUserPermission(Permission.CONTACT_INSERT)).pipe(untilDestroyed(this)),
      this.store.select(hasUserPermission(Permission.LEAD_DRAFT_INSERT)).pipe(untilDestroyed(this)),
      this.store.select(getSystemSettingValue(SystemSettingType.ACCOUNT_USAGE_WITHOUT_LEAD_DRAFT)).pipe(untilDestroyed(this))
    ]).subscribe(([account, activity, opportunity, contact, leadDraft, leadlessUsage]) => {
      this.buttons = [];

      if (activity) {
        this.buttons.push({
          label: this.translate.instant('GENERAL.ACTIVITY'),
          icon: icDateRange,
          action: () => this.openDialog(ActivityFormDialogComponent)
        });
      }

      if (opportunity) {
        this.buttons.push({
          label: this.translate.instant('GENERAL.OPPORTUNITY'),
          icon: icBusinessCenter,
          action: () => this.openDialog(OpportunityFormDialogComponent)
        });
      }

      if (contact) {
        this.buttons.push({
          label: this.translate.instant('GENERAL.CONTACT'),
          icon: icPersonPin,
          action: () => this.openDialog(ContactFormDialogComponent)
        });
      }

      // If leadlessUsage is active, then show Customer field instead of Lead field
      if (leadlessUsage as boolean) {
        if (account) {
          this.buttons.push({
            label: this.translate.instant('GENERAL.CUSTOMER'),
            icon: icUserCircle,
            action: () => this.openDialog(CustomerSearchDialogComponent)
          });
        }
      } else if (leadDraft) {
        this.buttons.push({
          label: this.translate.instant('GENERAL.LEAD'),
          icon: icUserCircle,
          action: () => this.openLeadDraftInfoDialog()
        });
      }
    });

    this.router.events.pipe(
      filter<NavigationEnd>(event => event instanceof NavigationEnd),
      untilDestroyed(this)
    ).subscribe((result: NavigationEnd) => {
      this.isActive = result.url.includes('board/') ? false : true;
    });
  }

  private openDialog(dialog) {
    this.dialog
      .open(dialog, { autoFocus: false })
      .addPanelClass('cdk-overlay-full');
  }

  private openLeadDraftInfoDialog() {
    this.dialog.open(CustomerSearchDialogComponent)
      .afterClosed()
      .subscribe((result: CustomerSearchDialogResult) => {
        if (result) {
          let route = ['/leads-drafts/new'];

          if (result.found) {
            route = ['/leads-drafts', result.customer.leadDraft.leadDraftId];
          }

          this.router.navigate(route, { queryParams: { keyword: result.keyword } });
        }
      });
  }

  show() {
    this.display = true;
    this.activeButtons = this.buttons;
  }

  hide() {
    this.display = false;
    this.activeButtons = [];
  }

  toggle() {
    if (this.display) {
      this.hide();
    } else {
      this.show();
    }
  }

  onClick(button: QuickActionButton) {
    this.hide();
    button.action.call([]);
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy() { }
}
