<ng-container *ngIf="textMode">
  <input matInput [tabIndex]="tabIndex" [formControl]="inputCtrl">
</ng-container>
<ng-container *ngIf="!textMode">
  <input
    #input
    matInput
    matTooltip="{{'OFFER.PLEASE_SELECT_COUNTRY' | translate}}"
    [tabIndex]="tabIndex"
    [formControl]="inputCtrl"
    [matAutocomplete]="auto"
    [matTooltipDisabled]="!!this.country"
    [readonly]="!this.country"
    (blur)="onBlur()">
  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="displayName"
    [autoActiveFirstOption]="true"
    (optionSelected)="onSelect($event)"
    (closed)="onClosed()">
    <ng-container *ngIf="this.customs">
      <mat-option *ngFor="let custom of customs" [value]="custom">{{ custom.name }}</mat-option>
    </ng-container>
  </mat-autocomplete>
</ng-container>
<mat-progress-spinner *ngIf="loading === true" diameter="16" mode="indeterminate"></mat-progress-spinner>
