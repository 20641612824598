import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SystemUserParameter, SystemUserParameterFilter } from './system-user-parameter.model';
import { environment } from '@env/environment';
import { Response } from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class SystemUserParameterService {

  constructor(private http: HttpClient) { }

  search(request: SystemUserParameterFilter) {
    return this.http.post<Response<SystemUserParameter>>(environment.apiUrl + '/api/SystemUserParameter/GetSystemUserParameter', request);
  }

  updateMailAndNotification(request: SystemUserParameter) {
    return this.http.post<Response<SystemUserParameter>>(environment.apiUrl + '/api/SystemUserParameter/UpdateMailAndNotificationParemeter', request);
  }
}
