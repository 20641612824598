import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';

import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  Component, DoCheck, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, Optional, Output,
  Self, ViewChild, ChangeDetectorRef
} from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import {
  MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger
} from '@angular/material/autocomplete';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Language, LanguageService, LanguageDataPassService } from '@core/api';
import icArrowDropDown from '@iconify/icons-ic/twotone-arrow-drop-down';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '@core/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'net-select-language',
  templateUrl: './select-language.component.html',
  styleUrls: ['./select-language.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: SelectLanguageComponent
    }
  ]
})
export class SelectLanguageComponent implements OnDestroy, AfterViewInit, DoCheck, ControlValueAccessor, MatFormFieldControl<Language | null>  {
  static nextId = 0;

  loading = false;
  focused = false;
  errorState = false;
  controlType = 'select-language';
  describedBy = '';
  stateChanges = new Subject<void>();

  languages: Language[];
  inputCtrl = new UntypedFormControl();

  icArrowDropDown = icArrowDropDown;

  @ViewChild('auto') auto: MatAutocomplete;
  @ViewChild('input') input: ElementRef<HTMLInputElement>;
  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger }) inputAutoComplit: MatAutocompleteTrigger;

  @Input() isSystemLanguage: boolean;
  @Input() isSpecialLanguage: boolean;
  @Input() isActive: boolean;
  @Input() withCustomCode = false;
  @Input() tabIndex;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    if (value) {
      this.inputCtrl.disable();
    } else {
      this.inputCtrl.enable();
    }

    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): Language | null { return this._value; }
  set value(value: Language | null) {
    this._value = value;
    this.onChange(value);
    this.stateChanges.next();
  }
  private _value: Language;

  @Input() panelWidth: string | number;

  @Input() tooltip: string;

  @Output() optionSelected = new EventEmitter<Language>();

  @HostBinding('id') id = `select-language-${SelectLanguageComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  get shouldLabelFloat() { return this.focused || !this.empty; }

  get empty() { return !this.value; }

  private initDefaultOptions(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.languageService.search({
        systemFlag: this.isSystemLanguage,
        systemLanguageFlag: this.isSpecialLanguage,
        enabledFlag: this.isActive
      })
        .toPromise()
        .then((response) => {
          this.languages = response.data;
          const selectedLanguage = this.languages.find(language => language.isoCode === this.authService.authUser.culture
            || language.customCode === this.authService.authUser.culture || this.authService.authUser.culture.split('-')[0] === language.isoCode) ??
            this.languages.find(language => language.isoCode === 'en');

          this.writeValue(selectedLanguage);
        })
        .finally(() => resolve());
    });
  }

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private languageService: LanguageService,
    private languageDataPassService: LanguageDataPassService,
    private authService: AuthService,
    private translate: TranslateService,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Material form field implementation
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.languageDataPassService.changedLanguages.pipe(untilDestroyed(this)).subscribe(() => {
      this.initDefaultOptions();
    });
  }

  ngAfterViewInit() {
    // Init list with default options
    this.initDefaultOptions();

    // Listen input control value changes and filter results
    this.inputCtrl.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      filter(value => typeof value === 'string'),
      tap(() => {
        this.loading = true;
        this.languages = [];
        this.value = null;
      }),
      switchMap(value => this.languageService.search({
        systemFlag: this.isSystemLanguage,
        enabledFlag: this.isActive,
        searchText: value
      })),
      tap(() => this.loading = false)
    ).subscribe((response) => this.languages = response.data);
  }

  // tslint:disable-next-line:max-line-length
  displayName = (language: Language) => language ? (
    (this.withCustomCode && language.customCode) ? ( !this.isSpecialLanguage ?
      (language?.customCode?.toUpperCase() + ' - ' + this.translate.instant('LANGUAGE.' + language.name))
    : this.translate.instant('LANGUAGE.' + this.getSystemLanguageName(language)))
    : this.translate.instant('LANGUAGE.' + language.name)
    ) : ''

  reset() {
    if (this.inputCtrl.pristine) {
      return;
    }

    this.value = null;
    this.languages = [];
    this.inputCtrl.setValue(null);
    this.inputCtrl.markAsPristine();
    this.initDefaultOptions();
  }

  openPanel(evt): void {
    evt.stopPropagation();
    this.inputAutoComplit.openPanel();
  }

  onSelect(event: MatAutocompleteSelectedEvent) {
    this.value = event.option.value;
    this.input.nativeElement?.blur();

    this.optionSelected.emit(this.value);
  }

  onBlur() {
    // On blur with nothing selected reset component
    if (this.ngControl.dirty && !this.auto.isOpen && !this.value) {
      this.reset();
    }
  }

  onClosed() {
    // On panel close with nothing selected reset component
    if (this.ngControl.dirty && !this.value) {
      this.reset();
    }
  }

  getSystemLanguageName(language: Language) {
    const search = this.languages?.filter(lang => lang.name === language.name);
    if (search && search.length === 1) {
      return language.name;
    }

    return language.customCode.toUpperCase() + ' - ' + language.name;
  }

  onChange = (_: any) => { };
  onTouched = () => { };
  onContainerClick = () => { };
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');

  writeValue(value: Language) {
    this.value = value;
    this.inputCtrl.setValue(value);
  }

  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }

}
