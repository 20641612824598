import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';

import { Observable } from 'rxjs';

import { PagedResponse, Response, FilterRequest, Contact } from '@core/api';
import { Address, AddressFilterRequest, AddressRequest } from './address.model';

@Injectable({
    providedIn: 'root'
})
export class AddressService {
    constructor(private http: HttpClient) { }

    get(uuid: string, checkPermissions: boolean = true): Observable<Response<Address>> {
        const headers = new HttpHeaders({ 'content-type': 'application/json' });
        const params = new HttpParams({
            fromObject: { permissionControl: String(checkPermissions) }
        });
        return this.http.post<Response<Address>>(environment.apiUrl + '/api/Address/Get', JSON.stringify(uuid), { headers, params });
    }

    save(request: AddressRequest): Observable<Response<Address>> {
        return this.http.post<Response<Address>>(environment.apiUrl + '/api/Address/Insert', request);
    }

    update(request: AddressRequest): Observable<Response<Address>> {
        return this.http.post<Response<Address>>(environment.apiUrl + '/api/Address/Update', request);
    }

    search(request: AddressFilterRequest, checkPermissions: boolean = true): Observable<PagedResponse<Address>> {
        const headers = new HttpHeaders({ 'content-type': 'application/json' });
        const params = new HttpParams({
            fromObject: { permissionControl: String(checkPermissions) }
        });
        return this.http.post<PagedResponse<Address>>(environment.apiUrl + '/api/Address/Search', request, { headers, params });
    }

    delete(request: any): Observable<PagedResponse<Contact>> {
        return this.http.post<PagedResponse<Contact>>(environment.apiUrl + '/api/Address/Delete', request);
    }

}
