export enum BoardGroupType {
    Kanban = 'Kanban',
    Timeline = 'Timeline',
    Listview = 'Listview',
    Board = 'Board',
    Report = 'Report',
    ReportNotification = 'ReportNotification',
    Card = 'Card',
    JoinGroup = 'JoinGroup',
    RemoveFromGroup = 'RemoveFromGroup',
    JoinGroupForReconnect = 'JoinGroupForReconnect',
    UserComment = 'UserComment',
}

export enum BoardViewType {
    KanbanView = 'KanbanView',
    TimelineView = 'TimelineView',
    ListviewView = 'ListviewView',
    ReportView = 'ReportView'
}

export enum BoardListenerType {
    Board = 'Board',
    BoardInfo = 'BoardInfo',
    Card = 'Card',
    CardDetail = 'CardDetail',
}
