import moment from 'moment';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    Activity, ActivityCalendarFilterRequest, ActivityDownload, ActivityFilterRequest, ActivityRequest, PagedResponse,
    Response
} from '@core/api';
import { AuthService } from '@core/auth/auth.service';
import { environment } from '@env/environment';

import { openFileUrl } from '../../../shared/utils/open-file-url';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {
  constructor(private http: HttpClient) { }

  search(request: ActivityFilterRequest) {
    return this.http.post<PagedResponse<Activity>>(environment.apiUrl + '/api/Activity/Search', request).pipe(
      tap(response => response?.data?.results?.forEach(activity => this.activityTransformer(activity)))
    );
  }

  exportExcel(request: ActivityFilterRequest) {
    return this.http.post<Response<ActivityDownload[]>>(environment.apiUrl + '/api/Activity/Download', request.filter);
  }

  getCalendar(request: ActivityCalendarFilterRequest) {
    return this.http.post<Response<Activity[]>>(environment.apiUrl + '/api/Activity/GetCalendar', request).pipe(
      tap(response => response?.data?.forEach(activity => this.activityTransformer(activity)))
    );
  }

  get(uuid: string, leadDraftKey = ''): Observable<Response<Activity>> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<Activity>>(environment.apiUrl + '/api/Activity/Get' + leadDraftKey, JSON.stringify(uuid), { headers }).pipe(
      tap((response) => {
        response.data = this.activityTransformer(response.data);

        return response;
      })
    );
  }

  update(request: any) {
    return this.http.post<Response<Activity>>(environment.apiUrl + '/api/Activity/Update', request).pipe(
      switchMap(response => {
        if (response.success) {
          return this.get(response.data.activityId);
        }

        return of(response);
      })
    );
  }

  insert(request: ActivityRequest): Observable<Response<Activity>> {
    return this.http.post<Response<Activity>>(environment.apiUrl + '/api/Activity/Insert', request).pipe(
      switchMap(response => {
        if (response.success) {
          return this.get(response.data.activityId);
        }

        return of(response);
      })
    );
  }

  download(activity: Activity, type: string = 'preview', languageId: string, fileWindow?: Window, assignmentFlag: boolean = false): Promise<boolean> {
    return openFileUrl(
      environment.apiUrl + `/api/Activity/ShowPdf?activityId=${activity.activityId}
        &templateId=${activity.activityPdfTemplateId}&languageId=${languageId}&assignmentFlag=${assignmentFlag}`,
      { Authorization: 'Bearer ' + AuthService.getToken() },
      type,
      `${activity.account.name.split('.').join(' ')}-${activity.code}`,
      fileWindow
    );
  }

  activityTransformer(activity: Activity): Activity {
    ['createdOn', 'actualStartDate', 'actualEndDate', 'planningStartDate', 'planningEndDate'].forEach((key) => {
      const date = moment(activity[key]);
      activity[key] = date.isValid() ? date : null;
    });

    return activity;
  }
}
