import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, DoCheck, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl, UntypedFormControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { AccountStatus, AccountStatusService } from '@core/api';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'net-multi-select-survey-mail-account-status',
  templateUrl: './multi-select-survey-mail-account-status.component.html',
  styleUrls: ['./multi-select-survey-mail-account-status.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: MultiSelectSurveyMailAccountStatusComponent
    }
  ]
})
export class MultiSelectSurveyMailAccountStatusComponent implements OnInit, OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<string[]>  {

  static nextId = 0;
  stateChanges = new Subject<void>();
  selectCtrl = new UntypedFormControl();
  focused = false;
  errorState = false;
  describedBy = '';
  controlType = 'multi-select-account-status';


  accountStatuses: AccountStatus[] = [];

  @Input()
  get salesOrganizationId(): string { return this._salesOrganizationId; }
  set salesOrganizationId(value: string) {
    this._salesOrganizationId = value;
    if (value) {
      this.getAccountStatuses();
    }
  }
  private _salesOrganizationId: string;

  @Input() isDisabled = false;
  @Input() tabIndex;
  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    if (value) {
      this.selectCtrl.disable();
    } else {
      this.selectCtrl.enable();
    }
    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): string[] { return this.selectCtrl.value; }
  set value(value: string[]) {
    this.selectCtrl.setValue(value);
    this.onChange(value);
    this.stateChanges.next();
  }

  @ViewChild('accountStatusSelect') accountStatusSelect: MatSelect;
  @HostBinding('id') id = `multi-select-account-status-${MultiSelectSurveyMailAccountStatusComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  get shouldLabelFloat() { return this.focused || !this.empty; }

  get empty() { return !this.value; }


  constructor(
    private accountStatusService: AccountStatusService,
    private translate: TranslateService,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl
  ) {
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

  }

  ngOnInit(): void {
  }


  getAccountStatuses() {
    this.accountStatusService.search({ pageSize: 100, filter: { salesOrganizationId: this.salesOrganizationId } })
      .subscribe((response) => {
        this.accountStatuses = response.data.results;
        const positiveAccountIds = this.accountStatuses.filter(x => x.positiveFlag).map(x => x.accountStatusId);

        if (this.value && this.value.length > 0) {
          return;
        }

        this.selectCtrl.patchValue(positiveAccountIds);
        this.value = positiveAccountIds;
      });
  }

  onSelectionChange() {
    this.onChange(this.value);
    this.stateChanges.next();
  }

  openedChange(isOpened: boolean) {
    if (isOpened) {
      const panel = this.accountStatusSelect.panel.nativeElement;
      panel.scrollTop = 0;
    }
  }

  selectAll() {

    const allAccountStatuses = this.accountStatuses.map(s => s.accountStatusId);
    this.selectCtrl.patchValue(allAccountStatuses);
    this.value = allAccountStatuses;

  }


  getToolTip() {
    if (this.accountStatuses.length > 0) {
      return this.value.map(x =>
        this.translate.instant('AccountStatus.' + this.accountStatuses.find(y => y.accountStatusId === x).name)
      ).join('\n');
    }
  }

  deselectAll() {

    this.selectCtrl.patchValue(null);
    this.value = [];
  }


  compareWith = (one: string, two: string) => one && two && one === two;
  onChange = (_: any) => { };
  selectionChange = (_: any) => { };
  onTouched = () => { };
  onContainerClick = () => { };
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');
  writeValue = (value: string[]) => {
    this.value = value;
    this.selectCtrl.setValue(value);
  }

  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }
}
