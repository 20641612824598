import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import { Observable } from 'rxjs/internal/Observable';

import { FilterRequest, Response, FuelCompany } from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class FuelCompanyService {
  constructor(private http: HttpClient) { }

  list(request: FilterRequest): Observable<Response<FuelCompany[]>> {
    return this.http.post<Response<FuelCompany[]>>(environment.apiUrl + '/api/FuelCompany/List', request);
  }
}
