<div (keyup.escape)="close()" [class.show]="show$ | async" class="search">
  <button (click)="close()" class="ltr:right-12 rtl:left-12 top-12 absolute" color="primary" mat-icon-button
    type="button">
    <mat-icon [icIcon]="icClose"></mat-icon>
  </button>

  <div class="search-input-container">
    <input #input #trigger="matAutocompleteTrigger" [formControl]="searchCtrl" [matAutocomplete]="auto"
      (keyup.enter)="onKeyupEnter()" class="search-input" [placeholder]="'GENERAL.SEARCH_ELLIPSIS' | translate ">
    <p class="search-hint">{{'TOOLBAR.PRESS_ENTER_TO_SEARCH' | translate}}</p>
    <mat-autocomplete class="custom-shadow" #auto="matAutocomplete" [displayWith]="displayName"
      [autoActiveFirstOption]="false">
      <ng-container *ngIf="result">
        <mat-optgroup [label]="'GENERAL.CUSTOMERS' | translate" *ngIf="result.accounts">
          <mat-option disabled *ngIf="result.accounts.length === 0">{{'TOOLBAR.CUSTOMER_NOT_FOUND' | translate}}
          </mat-option>
          <mat-option #accountOption *ngFor="let item of result.accounts" [value]="{ type: 'account', content: item }"
            (click)="onSelect(accountOption)">
            <span class="block">
              {{ item.name }}
              <div class="float-right" *ngIf="item.accountHashtags && item.accountHashtags.length > 0">
                <span class="custom-tag background">
                  {{getHashtag(item.accountHashtags)}}
                </span>
              </div>
            </span>
            <span class="subtext">{{item.salesOrganization?.name}} <span
                *ngIf="item.productionAccountCode">({{item.productionAccountCode}})</span></span>
          </mat-option>
        </mat-optgroup>

        <mat-optgroup [label]="'GENERAL.CONTACTS' | translate" *ngIf="result.contacts">
          <mat-option disabled *ngIf="result.contacts.length === 0">{{'TOOLBAR.CONTACT_NOT_FOUND' | translate}}
          </mat-option>
          <mat-option #matOption *ngFor="let item of result.contacts; let index = index"
            [value]="{ type: 'contact', content: item }" (click)="onSelect(matOption)">
            <span class="block">
              {{ item.firstName }} {{ item.lastName }}
              <div class="float-right" *ngIf="item.contactHashtags && item.contactHashtags.length > 0">
                <span class="custom-tag background">
                  {{getHashtag(item.contactHashtags)}}
                </span>
              </div>
            </span>
            <span class="subtext">{{ item.customer?.name }} {{ item.jobTitle }}</span>
          </mat-option>
        </mat-optgroup>
      </ng-container>
    </mat-autocomplete>
    <mat-progress-spinner *ngIf="loading === true" mode="indeterminate" diameter="48"></mat-progress-spinner>
  </div>
</div>
<div (click)="close()" *ngIf="show$ | async" class="search-overlay"></div>

<ng-template #accountAlert>
  <div fxLayout="row" fxLayoutAlign="start center" class="mb-0" mat-dialog-title>
    <span class="text-sm">{{'TOOLBAR.WHICH_SALES_ORGANIZATION_WOULD_YOU_LIKE_TO_SEE_CONTACT_OF_THE_CUSTOMER'
      | translate}}
    </span>
    <button type="button" class="text-secondary" mat-dialog-close mat-icon-button>
      <mat-icon [icIcon]="icClose"></mat-icon>
    </button>
  </div>
  <mat-dialog-content class="pb-4" fxLayout="column">
    <h4 class="font-medium mt-4">{{'GENERAL.SALES_ORGANIZATIONS' | translate}}</h4>
    <div *ngFor="let account of selectedContact.customer.accounts"
      class="border border-solid border-gray-400 rounded-lg mt-2 hover:bg-hover">
      <button mat-icon-button class="w-full text-left	"
        (click)="onSelectContactWithAccount(selectedContact.contactId, account.accountId)" type="button">
        <mat-icon class="text-hint" [icIcon]="icKeyboardArrowRight"></mat-icon>
        <span class="account-alert-title">{{ account.salesOrganization.name }}</span>
      </button>
    </div>
  </mat-dialog-content>
</ng-template>
