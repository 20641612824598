import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CustomerExperienceSearchRequest } from './customer-experience.model';

@Injectable({
    providedIn: 'root',
})
export class CustomerExperienceService {

    private showRefreshListviewButtonSubject = new BehaviorSubject<boolean>(null);
    showRefreshListviewButton$ = this.showRefreshListviewButtonSubject.asObservable();

    private listviewFilterSubject = new BehaviorSubject<CustomerExperienceSearchRequest>(null);
    listviewFilterSubject$ = this.listviewFilterSubject.asObservable();

    clearRefreshListviewButton() {
        this.showRefreshListviewButtonSubject.next(null);
    }

    triggerRefreshButton() {
        this.showRefreshListviewButtonSubject.next(true);
    }

    setListviewFilter(filter: CustomerExperienceSearchRequest = {}) {
        this.listviewFilterSubject.next(filter);
    }

}
