import { Card, CardAttachment, CardComment, CardObject, DynamicFieldValue } from '@core/api';
import { BoardDetailFormDialogActionTypes, BoardDetailFormDialogActions } from './board-detail-form-dialog.action';

export interface BoardDetailFormDialogState {
    card: Card;
    description: string;
    reminderDate: moment.Moment;
    cardAttachments: CardAttachment[];
    cardObjects: CardObject[];
    childCards: Card[];
    dynamicFieldValues: DynamicFieldValue[];
    cardComments: CardComment[];
    commentCount: number;
    cardActionType: string;
}

export const boardDetailFormDialogState: BoardDetailFormDialogState = {
    card: null,
    description: null,
    reminderDate: null,
    cardAttachments: [],
    cardObjects: [],
    childCards: [],
    dynamicFieldValues: [],
    cardComments: [],
    commentCount: 0,
    cardActionType: null
};

export function boardDetailFormDialogReducer(state = boardDetailFormDialogState, action: BoardDetailFormDialogActions): BoardDetailFormDialogState {
    switch (action.type) {
        case BoardDetailFormDialogActionTypes.GET_BOARD_DETAIL_FORM_DIALOG:
            return {
                ...state
            };
        case BoardDetailFormDialogActionTypes.SET_BOARD_DETAIL_FORM_DIALOG_SUCCESS:
            return {
                ...state,
                card: action.payload,
                cardComments: action.payload.cardComments,
                commentCount: action.payload.commentCount,
            };
        case BoardDetailFormDialogActionTypes.SET_BOARD_DETAIL_FORM_DIALOG_ERROR:
            return {
                ...state
            };
        case BoardDetailFormDialogActionTypes.SET_BOARD_DETAIL_FORM_DIALOG_CLEAR:
            return {
                ...state,
                card: null
            };
        case BoardDetailFormDialogActionTypes.SET_BOARD_DETAIL_FORM_DIALOG_CARD_ACTION_TYPE:
            return {
                ...state,
                cardActionType: action.cardActionType
            };
        case BoardDetailFormDialogActionTypes.SET_BOARD_DETAIL_FORM_DIALOG_CARD_ATTACHMENT:
            return {
                ...state,
                cardAttachments: action.cardAttachments
            };
        case BoardDetailFormDialogActionTypes.SET_BOARD_DETAIL_FORM_DIALOG_CARD_CHILD_CARD:
            return {
                ...state,
                childCards: action.childCards
            };
        case BoardDetailFormDialogActionTypes.SET_BOARD_DETAIL_FORM_DIALOG_CARD_COMMENT:
            return {
                ...state,
                cardComments: action.cardComment.cardComments,
                commentCount: action.cardComment.commentCount
            };
        case BoardDetailFormDialogActionTypes.SET_BOARD_DETAIL_FORM_DIALOG_CARD_DESCRIPTION:
            return {
                ...state,
                description: action.cardDescription
            };
        case BoardDetailFormDialogActionTypes.SET_BOARD_DETAIL_FORM_DIALOG_CARD_DYNAMIC_FIELD:
            return {
                ...state,
                dynamicFieldValues: action.dynamicFieldValues
            };
        case BoardDetailFormDialogActionTypes.SET_BOARD_DETAIL_FORM_DIALOG_CARD_OBJECT:
            return {
                ...state,
                cardObjects: action.cardObjects
            };
        case BoardDetailFormDialogActionTypes.SET_BOARD_DETAIL_FORM_DIALOG_CARD_REMINDER_DATE:
            return {
                ...state,
                reminderDate: action.cardReminderDate
            };
        default:
            return {
                ...state
            };
    }
}
