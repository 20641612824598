<input [matTooltip]="tooltip" #input matInput [tabIndex]="tabIndex" [formControl]="inputCtrl" [matAutocomplete]="auto"
  [placeholder]="placeholder" autocomplete="new-password" (blur)="onBlur()">
<mat-icon class="dropdown-icon" [ngClass]="{'disabled': disabled}" [icIcon]="icArrowDropDown" matSuffix
  (click)="openPanel($event)"></mat-icon>
<mat-autocomplete #auto="matAutocomplete" [panelWidth]="panelWidth" [displayWith]="displayName"
  [autoActiveFirstOption]="true" (optionSelected)="onSelect($event)" (closed)="onClosed()">
  <ng-container *ngIf="this.filteredCountries">
    <mat-option *ngFor="let country of filteredCountries" [value]="country">
      {{ ("Country." + country.name) | translate}}
    </mat-option>
  </ng-container>
</mat-autocomplete>
<mat-progress-spinner *ngIf="!!loading" diameter="16" mode="indeterminate"></mat-progress-spinner>