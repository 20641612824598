import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class FileLoadingTranslationService {

  constructor(private translate: TranslateService) { }

  setTranslations() {
    localStorage.setItem('fileLoadingTranlations', JSON.stringify(this.translate.instant('GENERAL')));
  }

  removeTranslations() {
    localStorage.removeItem('fileLoadingTranlations');
  }
}
