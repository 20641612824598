import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Account, Opportunity, OpportunityMailRequest, OpportunityMailService, OpportunityRequest, OpportunityService } from '@core/api';

import { OpportunityDialogData } from './opportunity-form-dialog.component.model';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'net-opportunity-form-dialog',
  templateUrl: './opportunity-form-dialog.component.html',
  styleUrls: ['./opportunity-form-dialog.component.scss']
})
export class OpportunityFormDialogComponent {

  account: Account;
  opportunity: Opportunity;
  mailRequest: OpportunityMailRequest;

  loading = false;

  formChanged = false;
  translations: { [key: string]: string };

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: OpportunityDialogData,
    private dialogRef: MatDialogRef<OpportunityFormDialogComponent>,
    private opportunityService: OpportunityService,
    private opportunityMailService: OpportunityMailService,
    private translate: TranslateService
  ) {
    // Add general form dialog pane
    this.dialogRef.addPanelClass('form-dialog-pane');

    if (data && data.account) {
      this.account = data.account;
    }

    if (data && data.opportunity) {
      this.opportunity = data.opportunity;
    } else {
      this.opportunity = null;
    }

    translate.get(['GENERAL', 'OPPORTUNITY']).subscribe(translations => {
      this.translations = {
        ...translations.GENERAL,
        ...translations.OPPORTUNITY
      };
    });

    this.dialogRef.disableClose = true;
    this.dialogRef.backdropClick().subscribe(() => {
      this.confirmCloseDialog();
    });
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === 'Escape') {
        this.confirmCloseDialog();
      }
    });
  }

  onSave(request: OpportunityRequest) {
    this.loading = true;

    if (this.opportunity) {
      request.opportunityId = this.opportunity.opportunityId;

      this.opportunityService.update(request).subscribe(
        response => {
          this.dialogRef.close(response.data);
        }
      ).add(() => this.loading = false);
      return;
    }

    this.opportunityService.save(request).subscribe(
      response => {
        if (this.mailRequest) {
          this.mailRequest.opportunityId = response.data.opportunityId;

          this.sendOfferMail(this.mailRequest);
        }

        this.dialogRef.close(response.data);
      }
    ).add(() => this.loading = false);
  }

  private sendOfferMail(request: OpportunityMailRequest) {
    this.opportunityMailService.sendMail(request).subscribe(() => this.mailRequest = null);
  }

  closeOpportunityFormDialog() {
    this.confirmCloseDialog();
  }

  onFormChanged(changed: boolean) {
    this.formChanged = changed;
  }

  confirmCloseDialog() {
    if (this.formChanged) {
      Swal.fire({
        text: this.translations?.PAGE_LEAVE_WITHOUT_SAVING,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#F44336',
        cancelButtonColor: '#7a7a7a',
        confirmButtonText: this.translations?.YES,
        cancelButtonText: this.translations?.NO
      }).then((result) => {
        if (result.isConfirmed) {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }
}
