export enum SLABeginTimeType {
    ASSIGNED_DATE = '00278a9b-50b4-4e16-be66-ef2ba941994d',
    CREATION_DATE = '9dea4e4d-9df4-4294-834b-756606b469a7'
}

export enum SLAEndTimeType {
    FIRST_RESPONSE_DATE = '4f2b52ce-89ba-4b2f-a3f9-dd937efd314b',
    RESOLUTION_DATE = '5a241f5a-fa2c-4ad6-a2b2-7ebd6fa87337'
}

export enum SLADurationType {
    ONGOING = 0,
    MET = 1,
    BREACHED = 2
}
