import { of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DynamicFieldService } from '@core/api';
import { CustomListActionTypes, GetCustomList, GetCustomListError, GetCustomListSuccess } from './custom-list.actions';

@Injectable()
export class CustomListEffects {
    constructor(
        private dynamicFieldService: DynamicFieldService,
        private actions$: Actions
    ) { }

    getSettings$ = createEffect(() => this.actions$.pipe(
        ofType(CustomListActionTypes.GET_CUSTOM_LIST),
        mergeMap((request: GetCustomList) => this.dynamicFieldService.search(request.payload)),
        switchMap(response => of(new GetCustomListSuccess(response.data.results))),
        catchError((error) => of(new GetCustomListError(error)))
    ));
}
