import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { EventManager } from '@angular/platform-browser';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { Hotkey } from './hotkey.model';
import { HotkeyDialogComponent } from '../hotkey-dialog/hotkey-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class HotkeyService {
  readonly helpkey = 'h';

  hotkeys: Hotkey[] = [];
  dialogRef: MatDialogRef<HotkeyDialogComponent>;

  constructor(
    private eventManager: EventManager,
    @Inject(DOCUMENT) private document,
    private dialog: MatDialog
  ) {}

  add(keys: string, description?: string): Observable<Event> {
    const hotkey: Hotkey = { keys, description };

    this.hotkeys.push(hotkey);

    return new Observable(observer => {
      const handler = e => {
        if (e.target.tagName.match(/^(input|textarea)$/i) || e.target.hasAttribute('contenteditable')) {
          return;
        }

        e.preventDefault();

        if (this.dialogRef && hotkey.keys !== this.helpkey) {
          this.dialogRef.close();
        }

        observer.next(e);
      };

      const dispose = this.eventManager.addEventListener(
        this.document,
        'keydown.' + keys,
        handler
      );

      return () => {
        dispose();

        const _index = this.hotkeys.indexOf(hotkey);

        if (_index !== -1) {
          this.hotkeys.splice(_index, 1);
        }
      };
    }).pipe(debounceTime<Event>(150));
  }

  openDialog() {
    if (this.dialogRef) {
      this.dialogRef.close();
      return;
    }

    this.dialogRef = this.dialog.open(HotkeyDialogComponent, {
      data: this.hotkeys,
      autoFocus: false
    });

    this.dialogRef.afterClosed().subscribe(() => this.dialogRef = null);
  }

}
