import { Component, DoCheck, ElementRef, HostBinding, Input, OnDestroy, Optional, Self } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldControl } from '@angular/material/form-field';

import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

import { Subject } from 'rxjs';

import { ActivityPdfTemplateService, ActivityPdfTemplate } from '@core/api';

@Component({
  selector: 'net-select-activity-pdf-template[salesOrganizationId]',
  templateUrl: './select-activity-pdf-template.component.html',
  styleUrls: ['./select-activity-pdf-template.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: SelectActivityPdfTemplateComponent
    }
  ]
})
export class SelectActivityPdfTemplateComponent implements OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<ActivityPdfTemplate|null> {

  static nextId = 0;

  loading = false;
  focused = false;
  errorState = false;
  controlType = 'select-activity-pdf-template';
  describedBy = '';
  stateChanges = new Subject<void>();

  activityPdfTemplates: ActivityPdfTemplate[] = [];
  selectCtrl = new UntypedFormControl();

  @Input() tabIndex;

  @Input()
  get salesOrganizationId(): string { return this._salesOrganizationId; }
  set salesOrganizationId(value: string) {
    this._salesOrganizationId = value;

    if (value) {
      this.loading = true;
      this.activityPdfTemplates = [];
      this.activityPdfTemplateService.search({pageSize: 100, filter: {salesOrganizationId: value}}).subscribe(response => {
        this.activityPdfTemplates = response.data.results;

        if (!this.value) {
          const defaultTemplate = this.activityPdfTemplates.find(template => template.defaultTemplate);
          if (defaultTemplate) {
            this.selectCtrl.setValue(defaultTemplate);
          } else {
            this.selectCtrl.setValue(this.activityPdfTemplates[0]);
          }

          this.onChange(defaultTemplate ? defaultTemplate : this.activityPdfTemplates[0]);
          this.stateChanges.next();
        }
        this.loading = false;
      });
    }
    this.stateChanges.next();
  }
  private _salesOrganizationId: string;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    if (value) {
      this.selectCtrl.disable();
    } else {
      this.selectCtrl.enable();
    }

    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): ActivityPdfTemplate | null { return this.selectCtrl.value; }
  set value(value: ActivityPdfTemplate | null) {
    this.selectCtrl.setValue(value);
    this.onChange(value);
    this.stateChanges.next();
  }

  @HostBinding('id') id = `select-activity-pdf-template-${SelectActivityPdfTemplateComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  get shouldLabelFloat() { return this.focused || !this.empty; }

  get empty() { return !this.value; }

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private dialog: MatDialog,
    private activityPdfTemplateService: ActivityPdfTemplateService,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Material form field implementation
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  onSelectionChange() {
    this.onChange(this.value);
    this.stateChanges.next();
  }

  onChange = (_: any) => {};
  onTouched = () => {};
  compareWith = (one: ActivityPdfTemplate, two: ActivityPdfTemplate) => one && two && one.activityPdfTemplateId === two.activityPdfTemplateId;
  onContainerClick = () => {};
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');
  writeValue = (value: ActivityPdfTemplate) => this.value = value;
  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }
}
