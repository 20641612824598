import { AttachmentService, CardAttachmentService } from '@core/api';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
export class CustomUploadAdapter {
    loader: any;
    type: string;
    cardId: string;

    constructor(
        private attachmentService: AttachmentService,
        private cardAttachmentService: CardAttachmentService,
        loader,
        type: string,
        cardId?: string
    ) {
        this.loader = loader;
        this.type = type;
        this.cardId = cardId;
    }

    upload() {
        return this.loader.file
            .then(file => new Promise((resolve, reject) => {
                this._sendRequest(resolve, reject, file);
            }));
    }

    _sendRequest(resolve, reject, file) {
        const data = new FormData();
        data.append('uploadFiles', file);

        this.attachmentService.upload(this.type, data).pipe(untilDestroyed(this)).subscribe((response) => {
            if (!response || !response.success) {
                return reject();
            }

            resolve({
                default: 'xooi://attachment/' + response.data.attachmentId
            });

            if (this.type === 'Card' && this.cardId) {
                const cardId = this.cardId;
                // this.cardAttachmentService.insert({ cardId, attachmentId: response.data.attachmentId }).subscribe();
            }

        }, (err) => reject());
    }
}
