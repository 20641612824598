import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import {
  DashboardFilterSalesOrganization, DashboardFilterSettingSalesOrganizationRequest, DashboardHomeFilter,
  DashboardHomeResponse, SalesOrganizationDashBoard
} from '@core/api';
import { LeadsGridData, Response } from '../api.model';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';
import { leadsReportTableDataTransformer } from 'src/app/shared/utils/leads-report-table-data-transformer';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(
    private http: HttpClient,
    private translate: TranslateService
  ) { }

  getDashboardData(request: DashboardHomeFilter): Observable<Response<DashboardHomeResponse>> {
    return this.http.post<Response<DashboardHomeResponse>>(environment.apiUrl + '/api/Home/Dashboard', request).pipe(
      tap(response => response.data.tableData.results.forEach(lead => leadsReportTableDataTransformer(lead, this.translate)))
    );
  }

  getDashboardFilter(): Observable<Response<DashboardFilterSalesOrganization>> {
    return this.http.post<Response<DashboardFilterSalesOrganization>>(environment.apiUrl + '/api/DashboardFilter/Get', {});
  }

  setDashboardFilter(request: DashboardFilterSettingSalesOrganizationRequest): Observable<Response<any[]>> {
    return this.http.post<Response<any>>(environment.apiUrl + '/api/DashboardFilter/Set', request);
  }

  removeDashboardFilter(): Observable<Response<any[]>> {
    return this.http.post<Response<any>>(environment.apiUrl + '/api/DashboardFilter/Remove', {});
  }

  listSalesOrganizations(): Observable<Response<SalesOrganizationDashBoard[]>> {
    return this.http.post<Response<SalesOrganizationDashBoard[]>>(environment.apiUrl + '/api/DashboardFilter/ListSalesOrganizations', {});
  }

  setSalesOrganizations(request: any[]): Observable<Response<any[]>> {
    return this.http.post<Response<any>>(environment.apiUrl + '/api/DashboardFilter/SetSalesOrganizations', request);
  }

}

