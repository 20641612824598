import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { getUser } from '@core/store';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private auth: AuthService, private store: Store, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.auth.isAuthenticated()) {
      if (state.url !== '/auth/login' && state.url !== '/error-403') {
        localStorage.setItem('returnUrl', state.url);
      }

      this.router.navigate(['auth/login']);
      return false;
    }

    if (state.url !== '/auth/update-password') {
      this.store.select(getUser).subscribe(user => {
        if (user && (user.isPasswordExpired)) {
          this.router.navigate(['/auth/update-password']);
        }
      });
    }

    return true;
  }
}
