<mat-slider
  color="primary"
  thumbLabel
  [min]="min"
  [max]="max"
  [step]="step"
  [formControl]="inputCtrl"
  (change)="onSliderChange()">
</mat-slider>
<p class="slider-label" *ngIf="inputCtrl.value">
  <span class="prefix">{{ prefix }}</span>
  <span class="value">{{ inputCtrl.value }}</span>
</p>
