<div id="map" style="display: none;"></div>
<!-- Async script executes immediately and must be after any DOM elements used in callback. -->
<script
  src="https://maps.googleapis.com/maps/api/js?key=AIzaSyAk5GdlChIHWQJlCFPPlkH8d8hs5GdEInw&callback=initMap&libraries=places&v=weekly"
  async>
  </script>
<script type="text/javascript"
  src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"></script>
<form class="form-wrapper" [formGroup]="form" [class.page]="isPage" [class.dialog]="isDialog">
  <ng-template #content>
    <div fxLayout="column">
      <ng-container *ngIf="address !== undefined">
        <mat-form-field fxFlex>
          <mat-label>{{'ADDRESS.CUSTOMER' | translate}}</mat-label>
          <input matInput disabled [value]="customer.name" *ngIf="form.get('customer').disabled">
          <net-select-account formControlName="account" *ngIf="form.get('customer').enabled"></net-select-account>
        </mat-form-field>

        <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="24px">
          <mat-form-field fxFlex>
            <mat-label>{{'ADDRESS.ADDRESS_NAME' | translate}}</mat-label>
            <input formControlName="name" matInput maxlength="100">
          </mat-form-field>

          <mat-form-field fxFlex>
            <mat-label>{{'ADDRESS.ADDRESS_TYPE' | translate}}</mat-label>
            <mat-select formControlName="type">
              <mat-option [value]="addressType.OFFICE">{{('AddressTypeId.' + addressType.OFFICE) | translate}}
              </mat-option>
              <mat-option [value]="addressType.OPERATION">{{('AddressTypeId.' + addressType.OPERATION) | translate}}
              </mat-option>
              <mat-option [value]="addressType.PRIVATE">{{('AddressTypeId.' + addressType.PRIVATE) | translate}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <net-input-dynamic-field-values [columns]="columnCount" [entityId]="DynamicFieldEntityId.ADDRESS"
          [entityName]="DynamicFieldEntityAsString.ADDRESS" formControlName="dynamicFieldValues"
          [openSeparator]="openSeparator" uploadType="Account"
          [mode]="!address ? 'add' : 'edit'"></net-input-dynamic-field-values>

        <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="24px">
          <mat-form-field fxFlex=80>
            <mat-label>{{'ADDRESS.ADDRESS_COMPANY_SEARCH' | translate}}</mat-label>
            <input #input="matAutocompleteTrigger" [netAutocompletePosition]="input" type="text" matInput
              formControlName="addressFromGoogle" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete">
              <ng-container *ngIf="autoCompleteItems">
                <mat-option (click)="onSelectAddressFromGoogle(option)" *ngFor="let option of autoCompleteItems"
                  [value]="option.formatted_address">
                  {{option.name}} - {{option.formatted_address}}
                </mat-option>
              </ng-container>
            </mat-autocomplete>
          </mat-form-field>
          <mat-slide-toggle class="mt-2" fxFlex=20 #toggle [checked]="autoAdressSelect" (change)="addressToggle($event)"
            style="white-space: pre-line;" color="primary">
            {{'ADDRESS.MANUAL_SELECTION' | translate}}
          </mat-slide-toggle>
        </div>

        <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="24px">
          <mat-form-field fxFlex>
            <mat-label>{{'ADDRESS.COUNTRY' | translate}}</mat-label>
            <net-select-country formControlName="country"></net-select-country>
          </mat-form-field>

          <!-- RUSSIA -->
          <ng-container *ngIf="form.get('country').value?.countryLocationLevel &&
                               form.get('country').value?.countryLocationLevel.cityLocationLevel &&
                               !form.get('country').value?.countryLocationLevel.countyLocationLevel">
            <mat-form-field fxFlex>
              <mat-label>{{'ADDRESS.PROVINCE' | translate}}</mat-label>
              <net-select-city formControlName="city" [country]="form.get('country').value"></net-select-city>
            </mat-form-field>
          </ng-container>

          <!-- TURKEY -->
          <ng-container *ngIf="form.get('country').value?.countryLocationLevel &&
                               form.get('country').value?.countryLocationLevel.cityLocationLevel &&
                               form.get('country').value?.countryLocationLevel.countyLocationLevel">
            <mat-form-field fxFlex>
              <mat-label>{{'ADDRESS.PROVINCE' | translate}}</mat-label>
              <net-select-city formControlName="city" [country]="form.get('country').value"></net-select-city>
            </mat-form-field>

            <mat-form-field fxFlex>
              <mat-label>{{'ADDRESS.DISTRICT' | translate}}</mat-label>
              <net-select-county formControlName="county" [city]="form.get('city').value"></net-select-county>
            </mat-form-field>
          </ng-container>
        </div>

        <mat-form-field>
          <mat-label>{{'ADDRESS.FULL_ADDRESS' | translate}}</mat-label>
          <textarea matInput formControlName="address"></textarea>
        </mat-form-field>
      </ng-container>

      <ng-container *ngIf="address === undefined">
        <ngx-skeleton-loader fxFlex appearance="line"></ngx-skeleton-loader>
        <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="24px">
          <ngx-skeleton-loader fxFlex appearance="line"></ngx-skeleton-loader>
          <ngx-skeleton-loader fxFlex appearance="line"></ngx-skeleton-loader>
        </div>
        <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="24px">
          <ngx-skeleton-loader fxFlex appearance="line"></ngx-skeleton-loader>
          <ngx-skeleton-loader fxFlex appearance="line"></ngx-skeleton-loader>
          <ngx-skeleton-loader fxFlex appearance="line"></ngx-skeleton-loader>
        </div>
        <ngx-skeleton-loader fxFlex class="textarea" appearance="line"></ngx-skeleton-loader>
      </ng-container>

      <google-map [center]="location" [zoom]="zoom" fxFlex>
        <map-marker [position]="location" [options]="markerOptions" (dragEnd)="markerDragEnd($event)">
        </map-marker>
      </google-map>
    </div>
  </ng-template>

  <ng-template #action>
    <p class="text-hint text-xs">{{'GENERAL.FIELDS_MARKED_WITH_*_ARE_REQUIRED' | translate}}</p>
    <net-progress-button class="ml-auto mt-2" *ngIf="address !== undefined" [icon]="icSave" [active]="loading"
      (btnClick)="onSubmit()" text="{{'GENERAL.SAVE' | translate}}"></net-progress-button>
  </ng-template>

  <ng-template [ngIf]="isDialog">
    <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
      <h2 class="m-0" fxFlex="auto">
        <span *ngIf="address">{{ address.name }}</span>
        <span *ngIf="!address">{{'ADDRESS.NEW_ADDRESS' | translate}}</span>
      </h2>

      <button type="button" class="text-secondary" mat-dialog-close mat-icon-button>
        <mat-icon [icIcon]="icClose"></mat-icon>
      </button>
    </div>

    <mat-divider class="-mx-6 mb-2 text-border"></mat-divider>
    <mat-dialog-content>
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    </mat-dialog-content>
    <mat-dialog-actions>
      <ng-container [ngTemplateOutlet]="action"></ng-container>
    </mat-dialog-actions>
  </ng-template>
  <ng-template [ngIf]="isPage">
    <ng-container [ngTemplateOutlet]="content"></ng-container>
    <div fxLayout="row" fxLayoutAlign="start center" class="w-full">
      <ng-container [ngTemplateOutlet]="action"></ng-container>
    </div>
  </ng-template>
</form>