import { Response, SystemUserPicture, User } from '@core/api';
import { LoginRequest, LoginResponse } from '@core/auth/auth.model';
import { Action } from '@ngrx/store';

export enum AuthActionTypes {
    LOGIN = '[Auth] LOGIN',
    LOGIN_SUCCESS = '[Auth] LOGIN_SUCCESS',
    LOGIN_ERROR = '[Auth] LOGIN_ERROR',
    LOGOUT = '[Auth] LOGOUT',

    ME = '[Auth] ME',
    ME_SUCCESS = '[Auth] ME_SUCCESS',
    ME_ERROR = '[Auth] ME_ERROR',

    SESSION_UPDATE = '[Auth] SESSION_UPDATE',

    PROFILE_PHOTO = '[Auth] PROFILE_PHOTO',
}

export class Login implements Action {
    readonly type = AuthActionTypes.LOGIN;
    constructor(public payload: LoginRequest) { }
}

export class LoginSuccess implements Action {
    readonly type = AuthActionTypes.LOGIN_SUCCESS;
    constructor(public payload: LoginResponse) { }
}

export class LoginError implements Action {
    readonly type = AuthActionTypes.LOGIN_ERROR;
    constructor(public payload: Response<null>) { }
}

export class Me implements Action {
    readonly type = AuthActionTypes.ME;
    constructor() { }
}

export class MeSuccess implements Action {
    readonly type = AuthActionTypes.ME_SUCCESS;
    constructor(public payload: LoginResponse) { }
}

export class SessionUpdate implements Action {
    readonly type = AuthActionTypes.SESSION_UPDATE;
    constructor(public payload: { user: User, accessToken: string, refreshToken: string }) { }
}

export class MeError implements Action {
    readonly type = AuthActionTypes.ME_ERROR;
    constructor(public payload: Response<null>) { }
}

export class Logout implements Action {
    readonly type = AuthActionTypes.LOGOUT;
    constructor(public payload: { navigate: boolean, isReturn: boolean } = { navigate: true, isReturn: false }) { }
}

export class ProfilePhoto implements Action {
    readonly type = AuthActionTypes.PROFILE_PHOTO;
    constructor(public payload: SystemUserPicture) { }
}

export type AuthActions =
    Login |
    LoginSuccess |
    LoginError |
    Me |
    MeSuccess |
    MeError |
    SessionUpdate |
    Logout |
    ProfilePhoto;
