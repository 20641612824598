export const SECTION_PAGE_SIZE = 8;
export const SECTION_BOARDINFO_CARD_SIZE = 12;
export const LIST_CARD_SIZE = 50;

export const CUSTOMER_EXPERIENCE_QUICK_REPLY_VARIABLES = [
    '{{user_firstname}}', // Sisteme login olan kullanıcının adı
    '{{user_lastname}}', // Sisteme login olan kullanıcının soyadı
    '{{contact_firstname}}', // Mesaj atan kontağın(biliniyor ise) adı(sistemde kayıtlı ise kontak bilgisinden çekilmeli, kontak yok ise mail için mailde kayıtlı adı alınmalı)
    '{{contact_lastname}}', // Mesaj atan kontağın(biliniyor ise) soyadı(sistemde kayıtlı ise kontak bilgisinden çekilmeli, kontak yok ise mail için mailde kayıtlı soyadı alınmalı)
    '{{ticket_code}}', // İlgili ticket kodu
    '{{ticket_status}}', // İlgili ticket statüsü
    '{{ticket_category}}', // İlgili ticketa atalı kategori
    '{{ticket_subject}}' // İlgili ticketın subject'i
];
