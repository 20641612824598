<ng-container [formGroup]="form">
  <div fxLayout="column" [matTooltip]="'OPPORTUNITY.PLEASE_SELECT_A_BUSINESS_UNIT' | translate" matTooltipShowDelay="0"
    formArrayName="services" [matTooltipDisabled]="!!organizationId">
    <div fxLayout="row" fxLayoutAlign="space-around" fxLayoutGap="24px">
      <label fxFlex="20" class="text-hint service-form-zone">{{'GENERAL.SERVICE' | translate}}</label>
      <label fxFlex="20" *ngIf="getStaticFieldActiveFlag(staticFieldEnum.OPPORTUNITY_DETAIL_DETAIL_FIELD_USAGE)"
        class="text-hint service-detail-zone">{{'OPPORTUNITY.DETAIL' | translate}}</label>
      <label fxFlex="20" class="text-hint service-revenue-type-zone">{{'GENERAL.REVENUE_TYPE' | translate}}</label>
      <label fxFlex="20" class="text-hint service-unit-zone">{{'GENERAL.UNIT' | translate}}</label>
      <label fxFlex="20" *ngIf="getStaticFieldActiveFlag(staticFieldEnum.OPPORTUNITY_DETAIL_UNIT_PRICE_FIELD_USAGE)"
        class="text-hint service-unit-price-zone">{{'GENERAL.UNIT_PRICE' | translate}}</label>
      <label fxFlex="20" class="text-hint service-amount-zone">{{'GENERAL.AMOUNT' | translate}}</label>
      <label fxFlex="15" class="text-hint service-revenue-zone">{{'GENERAL.REVENUE' | translate}}</label>
      <div fxFlex="5">
        <button class="ml-2 add" type="button" mat-icon-button [class.disabled]="disabled" (click)="onClickAdd()">
          <ic-icon [icon]="icAdd" size="16px"></ic-icon>
        </button>
      </div>
    </div>
    <div class="input-item" fxLayout="row" fxLayoutAlign="space-around" fxLayoutGap="24px" [formGroupName]="index"
      *ngFor="let control of getServicesForm().controls; let index = index">
      <mat-select fxFlex="20" class="service-form-zone" formControlName="serviceId"
        [placeholder]="'GENERAL.SERVICE' | translate" [compareWith]="serviceCompareWith"
        (selectionChange)="setOrganizationMainServiceServiceId(control)">
        <mat-optgroup *ngFor="let mainService of mainServices;" [label]="mainService.mainServiceName"
          [hidden]="(mainService.services | inputOpportunityServices : selectedServices : getSelectedServiceId(index)).length === 0">
          <mat-option
            *ngFor="let service of mainService.services | inputOpportunityServicesAutoSelect : control : mainServices | inputOpportunityServices : selectedServices : getSelectedServiceId(index)"
            [value]="service.serviceId">
            {{service.serviceName}}
          </mat-option>
        </mat-optgroup>
      </mat-select>

      <div *ngIf="getStaticFieldActiveFlag(staticFieldEnum.OPPORTUNITY_DETAIL_DETAIL_FIELD_USAGE)" fxFlex="20"
        class="service-detail-zone" [matTooltip]="getTooltipText(control, 'detail', 17)"
        [matTooltipDisabled]="!getTooltipText(control, 'detail', 17)">
        <input maxlength="50" matInput [placeholder]="'GENERAL.DETAIL' | translate" formControlName="detail">
      </div>

      <mat-select fxFlex="20" class="service-revenue-type-zone" formControlName="revenueTypeId"
        [placeholder]="'GENERAL.REVENUE_TYPE' | translate" (selectionChange)="revenueTypeChange($event)"
        [compareWith]="serviceCompareWith">
        <mat-option *ngFor="let revenueType of revenueTypes" [value]="revenueType.revenueTypeId">
          {{ ('RevenueType.'+ revenueType.name) | translate}}
        </mat-option>
      </mat-select>

      <net-select-opportunity-service-unit fxFlex="20" class="service-unit-zone"
        [placeholder]="'GENERAL.UNIT' | translate" formControlName="unit" [disableTooltip]="!organizationId"
        [serviceId]="control.get('serviceId').value"></net-select-opportunity-service-unit>

      <net-input-money fxFlex="20"
        *ngIf="getStaticFieldActiveFlag(staticFieldEnum.OPPORTUNITY_DETAIL_UNIT_PRICE_FIELD_USAGE)"
        class="service-unit-price-zone" [currencyCode]="currencyCode" [placeholder]="'GENERAL.UNIT_PRICE' | translate"
        formControlName="unitPrice">
      </net-input-money>

      <net-input-number fxFlex="20" formControlName="metric" class="service-amount-zone"
        [placeholder]="metricLabel"></net-input-number>

      <net-input-money fxFlex="15" class="service-revenue-zone" placeholder={{revenueLabel}} formControlName="revenue"
        [currencyCode]="currencyCode">
      </net-input-money>
      <div fxFlex="5">
        <button type="button" class="remove ml-2" [class.disabled]="disabled" mat-icon-button
          (click)="onClickRemove(index)">
          <ic-icon [icon]="icMinus" size="16px"></ic-icon>
        </button>
      </div>
    </div>
  </div>
</ng-container>
<mat-progress-spinner *ngIf="loading" diameter="16" mode="indeterminate"></mat-progress-spinner>