<div class="fab-container" *ngIf="buttons.length > 0 && isActive">
  <button type="button" color="primary" class="toggler" mat-fab (click)="toggle()">
    <mat-icon [icIcon]="icAdd" [@toggler]="{value: display ? 'active' : 'inactive'}"></mat-icon>
  </button>
  <div class="button-container" [@stager]="display">
    <div class="item" *ngFor="let button of activeButtons" (click)="onClick(button)">
      <span class="label">{{ button.label }}</span>
      <button type="button" class="fab-secondary" color="secondary" mat-mini-fab>
        <mat-icon *ngIf="button.icon" [icIcon]="button.icon"></mat-icon>
      </button>
    </div>
  </div>
</div>
