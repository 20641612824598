import { Injectable } from '@angular/core';
import { ResultMessage } from '@core/api';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from './toast.service';
import { ToastType } from './toast.service.enum';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  constructor(private translate: TranslateService, private toastService: ToastService, private router: Router) { }

  showMessage(resultMessage: ResultMessage) {
    const type = resultMessage.resultMessageType.name.toLowerCase() as 'success' | 'error' | 'warning' | 'info' | 'question';

    if (this.router.url === '/auth/forgot-password') {
      if (type === 'success' || type === 'warning') {
        this.toastService.show(this.translate.instant('ResultMessage.' + resultMessage.message), type as ToastType);

        if (type === 'success') {
          this.router.navigate(['/auth/login']);
        }
      } else {
        Swal.fire({
          icon: type,
          html: this.translate.instant('ResultMessage.' + resultMessage.message),
          confirmButtonText: this.translate.instant('GENERAL.OK')
        });
      }
      return;
    }

    if (resultMessage.popupFlag) {
      Swal.fire({
        icon: type,
        html: this.translate.instant('ResultMessage.' + resultMessage.message),
        confirmButtonText: this.translate.instant('GENERAL.OK')
      });
    } else {
      this.toastService.show(this.translate.instant('ResultMessage.' + resultMessage.message), type as ToastType);
    }
  }
}
