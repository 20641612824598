import { UnsavedChangesActionTypes, UnsavedChangesActions } from './unsaved-changes.actions';

export interface UnsavedChangesState {
    hasUnsavedChanges: boolean;
}

export const initialUnsavedChangesState: UnsavedChangesState = {
    hasUnsavedChanges: false
};

export function unsavedChangesReducer(
    state = initialUnsavedChangesState,
    action: UnsavedChangesActions
): UnsavedChangesState {
    switch (action.type) {
        case UnsavedChangesActionTypes.SET_UNSAVED_CHANGES:
            return {
                ...state,
                hasUnsavedChanges: action.hasUnsavedChanges
            };

        default:
            return state;
    }
}
