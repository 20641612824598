import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class LanguageDataPassService {

  private languages = new Subject<void>();
  changedLanguages = this.languages.asObservable();

  constructor() { }

  languageStatusChange() {
    this.languages.next();
  }

}
