import { of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import {
    GetSystemSettingError, GetSystemSettingSuccess,
    SystemSettingActionTypes
} from '@core/store';
import { InitialService } from '@core/api';

@Injectable()
export class SystemSettingEffects {
    constructor(
        private initialService: InitialService,
        private actions$: Actions
    ) { }

    getSettings$ = createEffect(() => this.actions$.pipe(
        ofType(SystemSettingActionTypes.GET_SYSTEM_SETTING),
        mergeMap(() => this.initialService.initial()),
        switchMap(response => of(new GetSystemSettingSuccess(response))),
        catchError((error) => of(new GetSystemSettingError(error)))
    ));
}
