import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { MailPreviewModel } from './mail-preview.component.model';
import { Mail, MailLink } from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class MailPreviewComponentService {
  private previews: MailPreviewModel[] = [];
  private previewsSubject = new Subject<MailPreviewModel[]>();
  private hasPreviewSubject = new BehaviorSubject<boolean>(false);

  previews$ = this.previewsSubject.asObservable();
  hasPreview$ = this.hasPreviewSubject.asObservable();

  private updateSubjects() {
    this.previewsSubject.next(this.previews);
    this.hasPreviewSubject.next(this.previews.length > 0);
  }

  add(preview: MailPreviewModel) {
    if (this.isExists(preview.id)) {
      return;
    }

    this.previews.push(preview);
    this.updateSubjects();
  }

  addLink(link: MailLink) {
    // Make sure link has body to preview
    if (!link.body) {
      return;
    }
    this.add({
      ...link,
      id: link.mailLinkId,
      files: link.files,
    });
  }

  addMail(mail: Mail) {
    // Make sure mail has body to preview
    if (!mail.body) {
      return;
    }

    this.add({
      id: mail.mailId,
      mailId: mail.mailId,
      folderId: mail.folderId,
      to: mail.to,
      from: mail.from,
      subject: mail.subject,
      body: mail.body,
      files: mail.mailFileInformations,
      fromMailAdress: mail.fromMailAdress,
      createdBy: null,
    });
  }

  remove(preview: MailPreviewModel) {
    if (!this.isExists(preview.id)) {
      return;
    }

    this.previews = this.previews.filter((item) => preview.id !== item.id);
    this.updateSubjects();
  }

  clear() {
    this.previews = [];
    this.updateSubjects();
  }

  isExists(id: string) {
    return this.previews.some((item) => id === item.id);
  }
}
