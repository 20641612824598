<input
  #input
  matInput
  [tabIndex]="tabIndex"
  [formControl]="inputCtrl"
  [matAutocomplete]="auto"
  (blur)="onBlur()">
<mat-autocomplete
  #auto="matAutocomplete"
  [displayWith]="displayName"
  [autoActiveFirstOption]="true"
  (optionSelected)="onSelect($event)"
  (closed)="onClosed()">
  <ng-container *ngIf="this.cities">
    <mat-option *ngFor="let city of cities" [value]="city">{{ city.name }}</mat-option>
  </ng-container> 
</mat-autocomplete>
<mat-progress-spinner *ngIf="loading === true" diameter="16" mode="indeterminate"></mat-progress-spinner>
