<form [formGroup]="form">
    <div fxLayout="column" fxLayoutAlign="start center" mat-dialog-title>
        <div class="w-full">
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <h2>{{ "OPPORTUNITY.MAIL_SENDING" | translate }}</h2>
                
                <button class="text-secondary" (click)="closeAlertDialog(null, false, true)" mat-icon-button type="button">
                    <mat-icon [icIcon]="icClose"></mat-icon>
                </button>
            </div>

            <div class="subheading-2">{{ 'OPPORTUNITY.MAIL_SENDING_DESCRIPTION' | translate }}</div>
        </div>
    </div>

    <mat-divider class="-mx-6 mb-2 text-border"></mat-divider>

    <mat-dialog-content class="pb-4" fxLayout="column">

        <ng-container *ngIf="!isLoading else skeleton">
            <div class="font-medium subheading-2 mt-2">{{ 'OPPORTUNITY.CONTACTS' | translate }}</div>
            <div fxLayout="column" formArrayName="recipients">
                <ng-container *ngFor="let control of getRecipientsForm().controls; let index = index" [formGroupName]="index">
                    <div fxLayout="row">
                        <mat-checkbox formControlName="checked" color="primary" (change)="onChangeChecked($event, index, 'recipientsForm')">
                            {{control.get('name').value}}
                        </mat-checkbox>
                        <div *ngIf="control.get('emailCount')?.value === 0 && control.get('checked').value" fxFlexOffset="0.5em" fxLayoutAlign="start center"
                            class="cursor-pointer" (click)="openMissingMailDialog()">
                            <mat-icon [icIcon]="icWarning" [matTooltip]="'OPPORTUNITY.ADD_EMAIL_TOOLTIP_DESCRIPTION' | translate"
                                class="text-orange-300 text-xl"></mat-icon>
                        </div>
                    </div>
                </ng-container>
            </div>

            <div class="font-medium subheading-2 mt-2">{{'OPPORTUNITY.CC' | translate}}</div>
            <net-multi-select-cc-users [userGroups]="userGroupsForCc" [placeholder]="'OPPORTUNITY.USER_CHOICE' | translate"
                formControlName="ccUsers">
            </net-multi-select-cc-users>

            <mat-form-field class="mb-0">
                <mat-label>{{ "OPPORTUNITY.TEMPLATE" | translate }}</mat-label>
                <mat-select formControlName="template">
                    <mat-option *ngFor="let template of opportunityParameters" [value]="template.opportunityParameterId">
                        {{ template.templateName }} - {{ 'LANGUAGE.' + template.language.name | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>

        <ng-template #skeleton>
            <ngx-skeleton-loader appearance="line"></ngx-skeleton-loader>
            <ngx-skeleton-loader appearance="line"></ngx-skeleton-loader>
            <ngx-skeleton-loader appearance="line"></ngx-skeleton-loader>
            <ngx-skeleton-loader appearance="line"></ngx-skeleton-loader>
        </ng-template>
    </mat-dialog-content>

    <mat-dialog-actions class="items-start">
        <button class="ml-auto mr-2" color="secondary" mat-button (click)="closeAlertDialog(null, false, true)">{{'GENERAL.CANCEL' |
            translate}}</button>
        <button (click)="$event.stopPropagation()" *ngIf="transferredData.opportunity" [matMenuTriggerFor]="menu"
            class="mr-2" color="primary" fxFlex.gt-xs="125px" fxFlex.xs="100%" mat-raised-button type="button">
            <ic-icon [icon]="icPdf" class="ltr:-ml-1 rtl:-mr-1 ltr:mr-2 rtl:ml-2" inline="true" size="18px"></ic-icon>
            <span fxHide.lt-sm>{{"OPPORTUNITY.CREATE_PDF" | translate}}</span>
        </button>
        <net-progress-button [icon]="icSave" (btnClick)="closeAlertDialog(null, true)"
            [disabled]="controlIsAllUnchecked('recipientsForm') && form.get('ccUsers').value.length === 0"
            [text]="'GENERAL.SAVE_SEND' | translate">
        </net-progress-button>
    </mat-dialog-actions>
</form>

<mat-menu #menu="matMenu" xPosition="before" yPosition="below">
    <ng-template matMenuContent>
        <button (click)="onSave('download')" color="primary" mat-menu-item>
            <mat-icon [icIcon]="icDownload"></mat-icon>
            <span>{{"GENERAL.DOWNLOAD" | translate}}</span>
        </button>

        <button (click)="onSave('preview')" color="primary" mat-menu-item>
            <mat-icon [icIcon]="icPdfOutLine"></mat-icon>
            <span>{{"GENERAL.PREVIEW" | translate}}</span>
        </button>
    </ng-template>
</mat-menu>

<!-- Missing Mail Dialog -->
<ng-template #missingMailDialog>
    <div fxLayout="column" fxLayoutAlign="start center" mat-dialog-title>
        <div class="w-full">
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <h2>{{ "OPPORTUNITY.MISSING_EMAIL_INFORMATION" | translate }}</h2>
                
                <button class="text-secondary" (click)="closeAlertDialog('missingMail')" mat-icon-button type="button">
                    <mat-icon [icIcon]="icClose"></mat-icon>
                </button>
            </div>

            <div class="subheading-2">{{ 'OPPORTUNITY.MISSING_EMAIL_INFORMATION_DETAIL' | translate }}</div>
        </div>
    </div>

    <mat-divider class="-mx-6 mb-2 text-border"></mat-divider>

    <mat-dialog-content [formGroup]="missingMailForm" class="pb-4" fxLayout="column">
        <div fxLayout="column" formArrayName="recipients">
            <div fxLayout="row" *ngIf="getMissingMailFormArray('recipients').value.length > 0">
                <div fxFlex="50">{{ "OPPORTUNITY.CONTACTS" | translate }}</div>
                <div fxFlex="50">{{ "OPPORTUNITY.EMAIL" | translate }}</div>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center"
                *ngFor="let control of getMissingMailFormArray('recipients').controls; let index = index" [formGroupName]="index">
                <mat-checkbox fxFlex="50" formControlName="checked" color="primary" (change)="onChangeChecked($event, index, 'missingMailForm', 'recipients')">
                    {{ control.get('displayName').value }}
                </mat-checkbox>

                <mat-form-field fxFlex="50">
                    <mat-label>{{ "OPPORTUNITY.EMAIL" | translate }}</mat-label>
                    <input formControlName="email" matInput>
                </mat-form-field>
            </div>
        </div>

        <div fxLayout="column" formArrayName="ccUsers">
            <div fxLayout="row" *ngIf="getMissingMailFormArray('ccUsers').value.length > 0">
                <div fxFlex="50">{{ "OPPORTUNITY.CC" | translate }}</div>
                <div fxFlex="50">{{ "OPPORTUNITY.EMAIL" | translate }}</div>
            </div>
            
            <div fxLayout="row" fxLayoutAlign="start center"
                *ngFor="let control of getMissingMailFormArray('ccUsers').controls; let index = index" [formGroupName]="index">
                <mat-checkbox fxFlex="50" formControlName="checked" color="primary" (change)="onChangeChecked($event, index, 'missingMailForm', 'ccUsers')">
                    {{ control.get('displayName').value }}
                </mat-checkbox>

                <mat-form-field fxFlex="50">
                    <mat-label>{{ "OPPORTUNITY.EMAIL" | translate }}</mat-label>
                    <input formControlName="email" matInput>
                </mat-form-field>
            </div>
        </div>
    </mat-dialog-content> 

    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end">
        <button class="mr-4" color="secondary" mat-button (click)="closeAlertDialog('missingMail')">
            {{ 'GENERAL.CANCEL' | translate }}
        </button>
        <net-progress-button (btnClick)="onSave('missingMail')" [color]="'primary'" [disabled]="missingMailForm.invalid"
            [icon]="icSave" [text]="'GENERAL.SAVE' | translate"></net-progress-button>
    </mat-dialog-actions>
</ng-template>