import { ChangeDetectorRef, Component, DoCheck, ElementRef, HostBinding, Input, OnDestroy, Optional, Self } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldControl } from '@angular/material/form-field';

import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

import { Subject } from 'rxjs';

import { ServiceUnitService, ServiceUnit } from '@core/api';

@Component({
  selector: 'net-select-opportunity-service-unit',
  templateUrl: './select-opportunity-service-unit.component.html',
  styleUrls: ['./select-opportunity-service-unit.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: SelectOpportunityServiceUnitComponent
    }
  ]
})
export class SelectOpportunityServiceUnitComponent implements OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<ServiceUnit | null> {

  static nextId = 0;

  loading = false;
  focused = false;
  errorState = false;
  controlType = 'select-opportunity-service-unit';
  describedBy = '';
  stateChanges = new Subject<void>();

  serviceUnits: ServiceUnit[] = [];

  selectCtrl = new UntypedFormControl();

  @Input() disableTooltip = false;

  @Input() tabIndex;

  @Input()
  get serviceId(): string { return this._serviceId; }
  set serviceId(value: string) {
    this._serviceId = value;

    if (value) {
      this.loading = true;
      this.serviceUnits = [];
      this.changeDetectorRef.detectChanges();

      this.serviceUnitService.search({ pageSize: 100, filter: { serviceId: value } }).subscribe(response => {
        this.serviceUnits = response.data.results;

        if (!this.value && this.serviceUnits.length === 1) {
          this.selectCtrl.setValue(this.serviceUnits[0]);

          this.onChange(this.serviceUnits[0]);
          this.stateChanges.next();
        }

        if (this.value && -1 === this.serviceUnits.findIndex(serviceUnit => serviceUnit.serviceUnitId === this.value.serviceUnitId)) {
          this.selectCtrl.setValue(null);

          this.onChange(null);
          this.stateChanges.next();
        }

        this.loading = false;

        const destroyed = 'destroyed';
        if (!this.changeDetectorRef[destroyed]) {
          this.changeDetectorRef.detectChanges();
        }
      });
    }
    this.stateChanges.next();
  }
  private _serviceId: string;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    if (value) {
      this.selectCtrl.disable();
    } else {
      this.selectCtrl.enable();
    }

    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): ServiceUnit | null { return this.selectCtrl.value; }
  set value(value: ServiceUnit | null) {
    this.selectCtrl.setValue(value);
    this.onChange(value);
    this.stateChanges.next();
  }

  @HostBinding('id') id = `select-opportunity-service-unit-${SelectOpportunityServiceUnitComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  get shouldLabelFloat() { return this.focused || !this.empty; }

  get empty() { return !this.value; }

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private dialog: MatDialog,
    private serviceUnitService: ServiceUnitService,
    private changeDetectorRef: ChangeDetectorRef,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Material form field implementation
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  onSelectionChange() {
    this.onChange(this.value);
    this.stateChanges.next();
  }

  onChange = (_: any) => { };
  onTouched = () => { };
  compareWith = (one: ServiceUnit, two: ServiceUnit) => one && two && one.serviceUnitId === two.serviceUnitId;
  onContainerClick = () => { };
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');
  writeValue = (value: ServiceUnit) => this.value = value;
  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }
}
