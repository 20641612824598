import { CustomerExperienceTicketDetailChatHeaderModel } from '@core/api';
import { Action } from '@ngrx/store';

export enum CustomerExperienceTicketActionTypes {
    SET_CUSTOMER_EXPERIENCE_TICKET_HEADER = '[Customer Experience Ticket] SET_CUSTOMER_EXPERIENCE_TICKET_HEADER',
    SET_CUSTOMER_EXPERIENCE_TICKET_CLEAR_HEADER = '[Customer Experience Ticket] SET_CUSTOMER_EXPERIENCE_TICKET_CLEAR_HEADER',
    SET_CUSTOMER_EXPERIENCE_TICKET_SUB_STATUS = '[Customer Experience Ticket] SET_CUSTOMER_EXPERIENCE_TICKET_SUB_STATUS'
}

export class SetCustomerExperienceTicketHeader implements Action {
    readonly type = CustomerExperienceTicketActionTypes.SET_CUSTOMER_EXPERIENCE_TICKET_HEADER;
    constructor(public headerInfo: CustomerExperienceTicketDetailChatHeaderModel) { }
}

export class SetCustomerExperienceTicketClearHeader implements Action {
    readonly type = CustomerExperienceTicketActionTypes.SET_CUSTOMER_EXPERIENCE_TICKET_CLEAR_HEADER;
    constructor() { }
}

export class SetCustomerExperienceTicketSubStatus implements Action {
    readonly type = CustomerExperienceTicketActionTypes.SET_CUSTOMER_EXPERIENCE_TICKET_SUB_STATUS;
    constructor(public payload: { hasSubStatus: boolean }) { }
}

export type CustomerExperienceTicketActions =
    SetCustomerExperienceTicketHeader |
    SetCustomerExperienceTicketClearHeader |
    SetCustomerExperienceTicketSubStatus;
