import { DynamicField } from '@core/api';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class InputDynamicFieldValuesValidator {
  static picklistValue(field: DynamicField): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (field.picklistValues && control.value) {
        const values = Array.isArray(control.value) ? control.value : [control.value];

        const isValid = values.every(value => {
          const picklistValue = field.picklistValues.find(item => item.dynamicFieldPickListId === value);

          // Return required when picklist value is not active
          if (picklistValue && !picklistValue.activeFlag) {
            return false;
          }

          return true;
        });

        if (!isValid) {
          return { required: true };
        }
      }

      return null;
    };
  }
}
