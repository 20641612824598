import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    FilterRequest, Response, SalesOrganizationSettingInfo, SalesOrganizationSettingInfoRequest,
    SalesOrganizationSettingInfoSearchRequest
} from '@core/api';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class SalesOrganizationSettingInfoService {
  constructor(private http: HttpClient) { }

  static clearToken() {
    localStorage.removeItem('sales_organization_setting');
  }

  static setToken(salesOrganizationSettings: SalesOrganizationSettingInfo[]) {
     localStorage.setItem('sales_organization_setting', JSON.stringify(salesOrganizationSettings));
  }

  search(request: SalesOrganizationSettingInfoSearchRequest): Observable<Response<SalesOrganizationSettingInfo[]>> {
    return this.http.post<Response<SalesOrganizationSettingInfo[]>>(environment.apiUrl + '/api/SalesOrganizationSettingInfo/Search', request);
  }

  list(request: FilterRequest): Observable<Response<SalesOrganizationSettingInfo[]>> {
    return this.http.post<Response<SalesOrganizationSettingInfo[]>>(environment.apiUrl + '/api/SalesOrganizationSettingInfo/List', request)
      .pipe(tap(response => {
        if (response.success) {
          SalesOrganizationSettingInfoService.setToken(response.data);
        }
      }));
  }

  updateAll(request: SalesOrganizationSettingInfoRequest[]): Observable<Response<SalesOrganizationSettingInfo[]>> {
    return this.http.post<Response<SalesOrganizationSettingInfo[]>>(environment.apiUrl + '/api/SalesOrganizationSettingInfo/UpdateAll', request);
  }
}
