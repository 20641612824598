<mat-select [placeholder]="placeholder" [tabIndex]="tabIndex" [formControl]="selectCtrl"
    (selectionChange)="onSelectionChange()" panelClass="custom-panel" disableOptionCentering multiple>
    <mat-select-trigger *ngIf="selectCtrl.value && selectCtrl.value.length>1">
        {{'GENERAL.VALUE_USERS_SELECTED' | translate: {value: selectCtrl.value.length} }}
    </mat-select-trigger>
    <mat-option disabled="disabled" class="mat-option-button">
        <button mat-raised-button (click)="selectAll()">
            {{'GENERAL.SELECT_ALL' | translate}}
        </button>
        <button mat-raised-button (click)="deselectAll()">
            {{'GENERAL.REMOVE_ALL' | translate}}
        </button>
    </mat-option>
    <mat-option *ngFor="let systemUser of systemUsers"
        [disabled]="disabled || defaultSelectedUserIds?.includes(systemUser?.systemUserId)" [value]="systemUser">{{
        systemUser.firstName }} {{ systemUser.lastName }}
    </mat-option>
</mat-select>

<mat-progress-spinner *ngIf="loading === true" diameter="16" mode="indeterminate"></mat-progress-spinner>