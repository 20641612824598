import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';

import { Response, ActivityPurpose, ActivityPurposeSearchFilter, ActivityPurposeRequest } from '@core/api';
import { Observable } from 'rxjs/internal/Observable';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActivityPurposeService {
  constructor(private http: HttpClient) { }

  list(): Observable<Response<ActivityPurpose[]>> {
    return this.http.post<Response<ActivityPurpose[]>>(environment.apiUrl + '/api/ActivityPurpose/List', {});
  }

  get(uuid: string): Observable<Response<ActivityPurpose>> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<ActivityPurpose>>(environment.apiUrl + '/api/ActivityPurpose/Get', JSON.stringify(uuid), { headers });
  }

  update(request: ActivityPurposeRequest) {
    return this.http.post<Response<ActivityPurpose>>(environment.apiUrl + '/api/ActivityPurpose/Update', request).pipe(
      switchMap(response => {
        if (response.success) {
          return this.get(response.data.activityPurposeId);
        }

        return of(response);
      })
    );
  }

  bulkUpdate(request: ActivityPurposeRequest[]) {
    return this.http.post<Response<ActivityPurpose[]>>(environment.apiUrl + '/api/ActivityPurpose/BulkUpdate', request);
  }

  insert(request: ActivityPurposeRequest): Observable<Response<ActivityPurpose>> {
    return this.http.post<Response<ActivityPurpose>>(environment.apiUrl + '/api/ActivityPurpose/Insert', request).pipe(
      switchMap(response => {
        if (response.success) {
          return this.get(response.data.activityPurposeId);
        }

        return of(response);
      })
    );
  }

  search(request: ActivityPurposeSearchFilter): Observable<Response<ActivityPurpose[]>> {
    return this.http.post<Response<ActivityPurpose[]>>(environment.apiUrl + '/api/ActivityPurpose/Search', request);
  }

  delete(request: any): Observable<Response<ActivityPurpose>> {
    return this.http.post<Response<ActivityPurpose>>(environment.apiUrl + '/api/ActivityPurpose/Delete', request);
  }
}
