export enum OpportunityApprovalTypeEnum {
    DISCOUNT = '5f021c30-c383-4dcd-ad44-d2a03b9194b5',
    CREDIT_LIMIT = 'f509ca03-8123-4ac8-a3d8-853f8e3c3385',
    PROFITABILITY = '56bddc04-b76d-4489-9f73-2514eeaf44b1'
}

export enum OpportunityApprovalConfirmatory {
    APPROVAL_LIMITS = 'ApprovalLimits',
    HIERARCHY_LEVEL = 'HierarchyLevel',
    STATIC_USER = 'StaticUser'
}

export enum OpportunityApprovalStatusEnum {
    WAITING_FOR_APPROVAL = '5f021c30-c383-4dcd-ad44-d2a03b9194b5',
    APPROVED = 'f509ca03-8123-4ac8-a3d8-853f8e3c3385',
    REJECTED = '56bddc04-b76d-4489-9f73-2514eeaf44b1',
    CANCELLED = '09640bc2-3b7b-4e51-ac9b-9fb768d3a79c'
}

export enum OpportunityApprovalTypesEnum {
    PRICE_LIST = 1,
    CONTRACT = 2,
    INITIAL = 3
}
