import { finalize } from 'rxjs/operators';

import { ApiService, LeadsGridData, CustomerTypeService } from '@core/api';
import { ApiDataSource } from '@core/api/api.data-source';

export class LeadsReportTableDataSource extends ApiDataSource<LeadsGridData> {

  constructor(
    private customerTypeService: CustomerTypeService,
    private api: ApiService,
    protected initialFilter?: any
  ) {
    super(initialFilter);
  }

  load(): void {
    if (this.initialFilter && !this.initialFilter.firstRequest) {
      return;
    }

    // Init filter with data source's default filter
    const filter: any = { ...this.initialFilter, ...this.filter };

    // If filter keyword exists, filter data
    if (this.keyword) {
      filter.searchText = this.keyword;
    }

    // Update loading state
    this.loadingSubject.next(true);

    // Create request parameters
    const request = this.getRequest();

    if (this.paginator?.pageSize) {
      request.pageSize = this.paginator.pageSize;
    }
    // Add filters to request
    request.filter = filter;
    // Fetch data
    this.api.leadsReportTableData
      .search(request)
      .pipe(finalize(() => this.loadingSubject.next(false)))
      .subscribe(response => {
        // Update count and data subjects
        this.dataSubject.next(response.data.results.map(data => ({
          ...data, accountSource: data.accountSourceName
        })));
        this.dataCountSubject.next(response.data.rowCount);
        this.rowCount$.next(response.data.rowCount);
        // Update data source's empty based row count
        this.empty = response.data.rowCount === 0;
      });
  }
}
