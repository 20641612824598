import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Attachment, Response } from '@core/api';
import { environment } from '@env/environment';
import { FileSaverService } from 'ngx-filesaver';

@Injectable({
  providedIn: 'root'
})
export class AttachmentService {

  constructor(private http: HttpClient, private fileSaverService: FileSaverService) { }

  upload(request: string, formData?: any) {
    return this.http.post<Response<any>>(environment.apiUrl + '/api/Attachment/Upload?contentType=' + request, formData);
  }

  delete(attachmentId: string) {
    return this.http.post<Response<Attachment[]>>(environment.apiUrl + '/api/Attachment/Delete', { attachmentId });
  }

  download(id: string, fileName: string, preview?: boolean) {
    const headers = new HttpHeaders(
      { 'content-type': 'application/json' }
    );
    return this.http.post(environment.apiUrl + '/api/Attachment/Download', JSON.stringify(id), {
      headers,
      observe: 'response',
      responseType: 'blob'
    }).subscribe(data => {
      if (preview) {
        const type = fileName.substr(fileName.lastIndexOf('.') + 1).toLowerCase();

        if (type !== 'png' && type !== 'jpg' && type !== 'jpeg' && type !== 'pdf') {
          this.fileSaverService.save(data.body, fileName);
        } else if (type === 'pdf') {
          const file = new Blob([data.body], { type: 'application/' + fileName.substr(fileName.lastIndexOf('.') + 1) });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        } else {
          const file = new Blob([data.body], { type: 'image/' + fileName.substr(fileName.lastIndexOf('.') + 1) });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }
      } else {
        this.fileSaverService.save(data.body, fileName);
      }
    });
  }
}
