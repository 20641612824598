import { Injectable } from '@angular/core';
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

import { NotTranslatedService } from './not-translated.service';

@Injectable()
export class AppMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    this.notTranslatedService.notTranslated(params.key);
    return params.key.split('.').slice(1).join('.');
  }

  constructor(private notTranslatedService: NotTranslatedService) { }
}
