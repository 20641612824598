import { Observable } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    Response, SystemUserPicture, SystemUserPictureRequest, SystemUserPictureSearch
} from '@core/api';
import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root'
})
export class SystemUserPictureService {
    constructor(private http: HttpClient) { }

    list(): Observable<Response<SystemUserPicture[]>> {
        return this.http.post<Response<SystemUserPicture[]>>(environment.apiUrl + '/api/SystemUserPicture/List', {});
    }

    get(uuid: string): Observable<Response<SystemUserPicture>> {
        const headers = new HttpHeaders({ 'content-type': 'application/json' });
        return this.http.post<Response<SystemUserPicture>>(environment.apiUrl + '/api/SystemUserPicture/Get', JSON.stringify(uuid), { headers });
    }

    insert(formData: FormData): Observable<Response<SystemUserPicture>> {
        return this.http.post<Response<SystemUserPicture>>(environment.apiUrl + '/api/SystemUserPicture/Insert', formData);
    }

    search(request: SystemUserPictureSearch): Observable<Response<SystemUserPicture[]>> {
        return this.http.post<Response<SystemUserPicture[]>>(environment.apiUrl + '/api/SystemUserPicture/Search', request);
    }

    delete(request: SystemUserPicture): Observable<Response<SystemUserPicture>> {
        return this.http.post<Response<SystemUserPicture>>(environment.apiUrl + '/api/SystemUserPicture/Delete', request);
    }

    update(formData: FormData): Observable<Response<SystemUserPicture>> {
        return this.http.post<Response<SystemUserPicture>>(environment.apiUrl + '/api/SystemUserPicture/Update', formData);
    }

    updateColor(request: SystemUserPictureRequest): Observable<Response<SystemUserPicture>> {
        return this.http.post<Response<SystemUserPicture>>(environment.apiUrl + '/api/SystemUserPicture/UpdateColor', request);
    }
}
