import { CustomerExperienceChart, CustomerExperienceChartService, SystemUserService, User } from '@core/api';
import { ApiDataSource } from '../api.data-source';
import { of } from 'rxjs';
import { debounceTime, finalize, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

export class CustomerExperienceChartDataSource extends ApiDataSource<CustomerExperienceChart> {

    public isOpenedNewTab = false;

    public $status = of([
        { key: true, value: this.translate.instant('GENERAL.ACTIVE') },
        { key: false, value: this.translate.instant('GENERAL.PASSIVE') }
    ]);

    public $defaultStatus = of([
        { key: true, value: this.translate.instant('GENERAL.TRUE') },
        { key: false, value: this.translate.instant('GENERAL.FALSE') }
    ]);


    public $users = this.systemUserService
        .userSearch({ filter: { salesOrganizationIds: this.user.salesOrganizations.map(item => item) } }).pipe(
            map(response => response.data.results),
            map(statuses => statuses.map(item => {
                return {
                    key: item.systemUserId,
                    value: [item.firstName, item.lastName].join(' '),
                };
            }))
        );

    public $charts = this.chartService.list().pipe(
        map(response => response.data),
        map(charts => charts.map(chart => {
            return {
                key: chart.cxChartId,
                value: this.translate.instant('CX.' + chart.name)
            };
        }).sort((a, b) => a.value.localeCompare(b.value)))
    );

    constructor(
        private chartService: CustomerExperienceChartService,
        private translate: TranslateService,
        private systemUserService: SystemUserService,
        private user: User,
        protected initialFilter?: any,
    ) {
        super(initialFilter);
    }


    private filterReq: any;

    getFilter() {
        return this.filterReq;
    }

    load(): void {
        if (!this.isOpenedNewTab) {
            // Init filter with data source's default filter
            const filterValue: any = { ...this.initialFilter, ...this.filter };

            // If filter keyword exists, filter data
            if (this.keyword) {
                filterValue.searchText = this.keyword;
            }

            // Update loading state
            this.loadingSubject.next(true);

            // Create request parameters
            const request = this.getRequest();
            if (!request.orderType) {
                request.orderType = 'ASC';
                request.orderBy = 'orderBy';
            }

            if (this.paginator?.pageSize) {
                request.pageSize = this.paginator.pageSize;
            }

            // Add filters to request
            request.filter = filterValue;

            this.filterReq = { ...request };
            // Fetch data
            this.chartService
                .search(request)
                .pipe(
                    debounceTime(400),
                    finalize(() => this.loadingSubject.next(false))
                ).subscribe(response => {
                    // Update count and data subjects
                    this.dataSubject.next(response.data.results);
                    this.dataCountSubject.next(response.data.rowCount);

                    // Update data source's empty based row count
                    this.empty = response.data.rowCount === 0;
                });
        }

        return this.loadingSubject.next(false);
    }
}
