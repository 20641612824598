import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Service } from '@core/api';

@Pipe({
  name: 'inputOpportunityServicesAutoSelect',
})
export class InputOpportunityServicesOneSelectPipe implements PipeTransform {

  transform(items: Service[], form: UntypedFormGroup, mainService: any[]): Service[] {
    if (!items) {
      return items;
    }

    if (mainService.length > 1) {
      return items;
    }

    if (items.length  === 1) {
      form.get('serviceId').patchValue(items[0].serviceId, { emitEvent: true });
      form.get('organizationMainServiceServiceId').patchValue(items[0].id, { emitEvent: true });
    }

    return items;
  }

}
