import {
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, NgControl} from '@angular/forms';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatDialog} from '@angular/material/dialog';
import {MatFormFieldControl} from '@angular/material/form-field';

import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {FocusMonitor} from '@angular/cdk/a11y';
import {coerceBooleanProperty} from '@angular/cdk/coercion';

import {Subject} from 'rxjs';
import {debounceTime, filter, map, switchMap, tap} from 'rxjs/operators';

import icAdd from '@iconify/icons-ic/twotone-add';
import icCancel from '@iconify/icons-ic/twotone-cancel';

import {ApiService, Contact, Customer, SystemUser, SystemUserService, SystemUserStatusType, User} from '@core/api';

import {SystemUserAdapter} from '@core/api/api.adapter';

import {InputParticipantMode, ParticipantType} from './input-participant.component.enum';
import {Participant, Participants} from './input-participant.component.model';
import {ContactFormDialogComponent} from '../contact-form-dialog/contact-form-dialog.component';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {getUser} from '@core/store';
import {Store} from '@ngrx/store';

@UntilDestroy()
@Component({
  selector: 'net-input-participant[customer]',
  templateUrl: './input-participant.component.html',
  styleUrls: ['./input-participant.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: InputParticipantComponent
    }
  ]
})
export class InputParticipantComponent implements OnInit, OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<Participants | null> {

  static nextId = 0;

  loading = false;
  focused = false;
  errorState = false;
  controlType = 'input-participant';
  describedBy = '';
  stateChanges = new Subject<void>();
  separatorKeysCodes: number[] = [ENTER, COMMA];

  contacts: Contact[] = [];
  activeUser: User;
  systemUsers: SystemUser[] = [];
  filteredContacts: Contact[] = [];
  filteredSystemUsers: SystemUser[] = [];

  modes = InputParticipantMode;
  type = ParticipantType;
  inputCtrl = new UntypedFormControl();

  icAdd = icAdd;
  icCancel = icCancel;

  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  @Input() isDisabled = false;
  @Input() mode: InputParticipantMode = InputParticipantMode.BOTH;
  @Input() tabIndex: number;
  @Input() removeSelf = true;
  @Input() permissionControl = true;
  @HostBinding('id') id = `input-participant-${InputParticipantComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  @Output() participantSelection: EventEmitter<Participants> = new EventEmitter<Participants>();

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private dialog: MatDialog,
    private api: ApiService,
    private store: Store,
    private systemUserService: SystemUserService,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Material form field implementation
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  private _customer: Customer;

  @Input()
  get customer() {
    return this._customer;
  }

  set customer(customer: Customer) {
    this._customer = customer;
    if (!customer || this.optionalDisabled) {
      setTimeout(() => {
        this.disabled = true;
      });
    } else {
      setTimeout(() => {
        this.disabled = false;
      });
    }

    // Fetch all contact list
    if (customer) {
      this.api.contact.search({
        filter: {customerId: customer.customerId, enabled: true, permissionControl: this.permissionControl},
        pageSize: 500
      }).subscribe(response => {
        this.contacts = response.data.results;
        this.filteredContacts = this.contacts.filter(item => !this.value.contacts.some(selected => selected.contactId === item.contactId));
      });
    }
  }

  private _placeholder: string;

  @Input()
  get placeholder(): string {
    return this._placeholder || '';
  }

  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  private _required = false;

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private _disabled = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(disabled: boolean) {
    this._disabled = disabled;

    if (disabled) {
      this.inputCtrl.disable();
    } else {
      this.inputCtrl.enable();
    }

    this.stateChanges.next();
  }

  private _optionalDisabled = false;

  @Input()
  get optionalDisabled(): boolean {
    return this._optionalDisabled;
  }

  set optionalDisabled(disabled: boolean) {
    this._optionalDisabled = disabled;

    if (disabled) {
      if (!this.customer || this.optionalDisabled) {
        setTimeout(() => {
          this.disabled = true;
        });
      } else {
        setTimeout(() => {
          this.disabled = false;
        });
      }
    }
  }

  private _salesOrganizationId: string;

  @Input()
  get salesOrganizationId() {
    return this._salesOrganizationId;
  }

  set salesOrganizationId(value: string) {
    this._salesOrganizationId = value;
  }

  private _value: Participants = {
    contacts: [],
    systemUsers: []
  };

  @Input()
  get value(): Participants | null {
    return this._value;
  }

  set value(value: Participants | null) {
    this._value = value;
    this.onChange(value);
    this.stateChanges.next();
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get empty() {
    return !this.value;
  }

  ngOnInit() {
    if (this.isMode(InputParticipantMode.SYSTEM_USER)) {
      // Watch input changes and update filtered system users
      this.inputCtrl.valueChanges.pipe(
        filter(value => !!value && typeof value === 'string'),
        debounceTime(200),
        tap(() => this.loading = true),
        switchMap(value => {
          return this.systemUserService.userSearch({
            filter: {
              fullName: value,
              systemUserStatusIds: [SystemUserStatusType.ACTIVE],
              salesOrganizationId: this.salesOrganizationId
            }
          }).pipe(map(response => response.data.results));
        })
      ).subscribe(users => {
        this.filteredSystemUsers = users.filter(user => !this.value.systemUsers.some(selected => selected.systemUserId === user.systemUserId));
        this.loading = false;
      });

      // Fill logged user as first systemUser
      this.store.select(getUser).pipe(untilDestroyed(this)).subscribe(user => {
        this.activeUser = user;
        if (this.value.systemUsers.length === 0) {
          this.value.systemUsers.push(SystemUserAdapter.fromUser(user));
          this.updateValue();
        }
      });
    }

    // Listen input control value changes on contact mode and filter contact results
    if (this.isMode(InputParticipantMode.CONTACT)) {
      // Watch input changes and update filtered contact
      this.inputCtrl.valueChanges.pipe(
        debounceTime(200),
        map(value => {
          if (!value) {
            return this.contacts.filter(item => !this.value.contacts.some(selected => selected.contactId === item.contactId));
          }

          const keyword = value.replace(/İ/g, 'I').toLocaleUpperCase();
          const key = this.convertToLocaleValue(value).toLocaleUpperCase();

          return this.contacts
            .filter(item => !this.value.contacts.some(selected => selected.contactId === item.contactId))
            .filter(contact => this.convertToLocaleValue(`${contact.firstName} ${contact.lastName}`).includes(keyword) || this.convertToLocaleValue(`${contact.firstName} ${contact.lastName}`).includes(key));
        })
      ).subscribe(contacts => this.filteredContacts = contacts);
    }
  }

  isMode(mode: InputParticipantMode): boolean {
    if (InputParticipantMode.BOTH === this.mode) {
      return true;
    }

    return mode === this.mode;
  }

  convertToLocaleValue(value: string): string {
    return value.replace(/i|ı/gi, 'İ').replace(/ğ/gi, 'G').replace(/ç/gi, 'C').replace(/ş/gi, 'S').replace(/ö/gi, 'O').replace(/ü/gi, 'U').toLocaleUpperCase();
  }

  reset(focusout: boolean = false) {
    this.input.nativeElement.value = '';
    this.inputCtrl.setValue(null);

    if (focusout) {
      this.input.nativeElement?.blur();
    }
  }

  addParticipant(participant: Participant) {
    if (participant?.type) {
      switch (participant.type) {
        case 'contact':
          this.value.contacts.push(participant.contact);
          this.value.contacts.sort((a, b) => a.firstName.localeCompare(b.firstName));
          this.updateValue();
          break;

        case 'systemUser':
          this.value.systemUsers.push(participant.systemUser);
          this.value.systemUsers.sort((a, b) => a.firstName.localeCompare(b.firstName));
          this.updateValue();
          break;

        default:
          throw new Error('Unknown participant type selected');
      }
    }
  }

  add() {
    this.dialog.open(ContactFormDialogComponent, {autoFocus: false, data: {customer: this.customer}})
      .addPanelClass('cdk-overlay-full')
      .afterClosed()
      .subscribe((contact: Contact) => {
        if (contact) {
          this.api.contact.get(contact.contactId, this.permissionControl).subscribe(response => {
            this.contacts.push(response.data);
            this.addParticipant({
              type: ParticipantType.CONTACT,
              contact: response.data
            });
          });
        }
      });
  }

  onSelect(event: MatAutocompleteSelectedEvent) {
    this.addParticipant(event.option.value);
    this.reset(true);
  }

  onRemove(participant: Participant) {
    if (ParticipantType.CONTACT === participant.type) {
      const index = this.value.contacts.indexOf(participant.contact);

      if (index >= 0) {
        this.value.contacts.splice(index, 1);
        this.updateValue();
      }
      return;
    }

    if (ParticipantType.SYSTEM_USER === participant.type) {
      const index = this.value.systemUsers.indexOf(participant.systemUser);

      if (index >= 0) {
        this.value.systemUsers.splice(index, 1);
        this.updateValue();
      }
      return;
    }

    throw new Error('Unknown participant type');
  }

  onChange = (_: any) => {
  }

  onTouched = () => {
  }

  onContainerClick = () => {
  }

  registerOnChange = (fn: any) => this.onChange = fn;

  registerOnTouched = (fn: any) => this.onTouched = fn;

  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;

  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');

  writeValue(value: Participants) {
    if (value instanceof Object) {
      this.value = value;
    }
  }

  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;

      this.stateChanges.next();
    }
  }

  private updateValue() {
    this.onChange(this.value);
    this.participantSelection.emit(this.value);
    this.stateChanges.next();
  }

}
