import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import { Observable } from 'rxjs';

import { Response } from '@core/api';
import { SectorMain } from './sector-main.model';

@Injectable({
    providedIn: 'root'
})
export class SectorMainService {
    constructor(private http: HttpClient) { }

    list(): Observable<Response<SectorMain[]>> {
        return this.http.post<Response<SectorMain[]>>(environment.apiUrl + '/api/SectorMain/List', {});
    }
}
