import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { BoardSystemUser, BoardSystemUserRequest, PagedResponse, Response } from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class BoardSystemUserService {

  constructor(private http: HttpClient) { }

  update(request: BoardSystemUserRequest): Observable<Response<BoardSystemUser>> {
    return this.http.post<Response<BoardSystemUser>>(environment.apiUrl + '/api/BoardSystemUser/Update', request);
  }

  insert(request: BoardSystemUserRequest): Observable<Response<BoardSystemUser>> {
    return this.http.post<Response<BoardSystemUser>>(environment.apiUrl + '/api/BoardSystemUser/Insert', request);
  }

  delete(request: BoardSystemUserRequest): Observable<Response<BoardSystemUser>> {
    return this.http.post<Response<BoardSystemUser>>(environment.apiUrl + '/api/BoardSystemUser/Delete', request);
  }

  search(request: any): Observable<PagedResponse<BoardSystemUser>> {
    return this.http.post<PagedResponse<BoardSystemUser>>(environment.apiUrl + '/api/BoardSystemUser/Search', request);
  }
}
