import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

import { Account, Contact, Mail, MailFolder, MailIntegrationType, MailListRequest, MailService, MailSignalRService, MailTabType } from '@core/api';

@Injectable({
  providedIn: 'root'
})
@UntilDestroy()
export class MailLayoutService {
  mails: Mail[] = [];
  folder: MailFolder;
  selectedMail: Mail;

  hasMail = false;
  loading = false;
  isPaginationEnabled = false;

  private pageToken: string;
  private mailListRequest: MailListRequest;

  private _drawerOpenSubject = new BehaviorSubject<boolean>(false);
  drawerOpen$ = this._drawerOpenSubject.asObservable();
  isDrawerOpen = false;

  private mailsSubject = new BehaviorSubject<Mail[]>(null);
  mails$ = this.mailsSubject.asObservable();

  private selectedMailSubject = new ReplaySubject<Mail>(null);
  selectedMail$ = this.selectedMailSubject.asObservable();

  selectedMailAccountIdSubject = new ReplaySubject<string>(null);
  selectedMailAccountId$ = this.selectedMailAccountIdSubject.asObservable();

  selectedMailContactSubject = new ReplaySubject<Contact>(null);
  selectedMailContact$ = this.selectedMailContactSubject.asObservable();

  selectedMailNewContactSubject = new ReplaySubject<Contact>(null);
  selectedMailNewContact$ = this.selectedMailNewContactSubject.asObservable();

  // private activeTab = new BehaviorSubject<MailTabType>(localStorage.getItem('mail:tab:active') as MailTabType || MailTabType.INFO);
  private activeTab = new BehaviorSubject<MailTabType>(MailTabType.INFO);
  activeTab$ = this.activeTab.asObservable();

  private activeTabVisibility = new BehaviorSubject<boolean>(localStorage.getItem('mail:tab:visibility') !== 'false');
  activeTabVisibility$ = this.activeTabVisibility.asObservable();

  private folderSubject = new ReplaySubject<MailFolder>();
  folder$ = this.folderSubject.asObservable();

  private allFolderSubject = new ReplaySubject<MailFolder[]>();
  folders$ = this.allFolderSubject.asObservable();

  private searchListingSubject = new BehaviorSubject<boolean>(false);
  searchListing$ = this.searchListingSubject.asObservable();

  constructor(private router: Router, private mailService: MailService) {
    this.folder$.pipe(untilDestroyed(this)).subscribe(folder => this.folder = folder);
    this.drawerOpen$.pipe(untilDestroyed(this)).subscribe(status => this.isDrawerOpen = status);
    this.selectedMail$.pipe(untilDestroyed(this)).subscribe(mail => this.selectedMail = mail);
  }

  loadMails(request: MailListRequest): Promise<void> {
    return new Promise(resolve => {
      // Update mail list request
      this.mailListRequest = request;

      // Update search listing status
      this.searchListingSubject.next(!!request?.mailFilter?.searchText);

      // Fetch mail list
      this.mailService
        .listMessage(request)
        .subscribe(response => {
          if (!response.success) {
            return;
          }

          // Update private mail list
          this.mails = response.data.mailContent;

          // Update mail list states
          this.hasMail = this.mails.length > 0;
          this.pageToken = response.data.pageToken;
          this.isPaginationEnabled = !!this.pageToken;

          // Update mail list subject
          this.mailsSubject.next(this.mails);
        })
        .add(() => resolve());
    });
  }

  refreshMails() {
    const request: MailListRequest = { mailIntegrationType: MailService.getIntegrationType(MailIntegrationType.OUTLOOK) };

    switch (request.mailIntegrationType) {
      case MailIntegrationType.OUTLOOK:
        request.folderId = this.folder.id;
        break;
      case MailIntegrationType.GMAIL:
        request.folderName = this.folder.name;
        break;
    }

    this.loadMails(request);
  }

  loadMoreMails(): Promise<void> {
    // Prevent load more when pagination is not exists
    if (!this.isPaginationEnabled) {
      return Promise.resolve();
    }

    // Update page token for next page
    this.mailListRequest.page = this.pageToken;

    // Fetch next page and append to private mail list
    return new Promise<void>((resolve) => {
      this.mailService
        .listMessage(this.mailListRequest)
        .subscribe(response => {
          resolve();

          if (!response.success) {
            return;
          }

          this.loading = false;

          // Update private mail list
          this.mails = [...this.mails, ...response.data.mailContent];

          // Update mail list states
          this.hasMail = this.mails.length > 0;
          this.pageToken = response.data.pageToken;
          this.isPaginationEnabled = !!this.pageToken;

          // Update mail list subject
          this.mailsSubject.next(this.mails);
        });
    });
  }

  updateActiveTab(tab: MailTabType) {
    this.activeTab.next(tab);
    this.updateActiveTabVisibility(true);
    localStorage.setItem('mail:tab:active', tab);
  }

  updateActiveTabVisibility(visibility: boolean) {
    this.activeTabVisibility.next(visibility);
    localStorage.setItem('mail:tab:visibility', JSON.stringify(visibility));
  }

  toggleDrawer() {
    this._drawerOpenSubject.next(!this.isDrawerOpen);
  }

  fetchSelectedMail(messageId: string) {
    this.getMessage(messageId).subscribe(response => {
      if (!response.success) {
        return;
      }


      const mailFileInformations = response.data.mailFileInformations.map(file => {

        const isFileUploaded = file['contentId'] ? response.data.attachmentFileScanStatuses.find(s => s.contentId === file['contentId']) : null;

        return {
          ...file,
          directDownload: !!isFileUploaded,
          ...isFileUploaded ? { attachmentId: isFileUploaded?.attachmentId } : {}
        };
      });

      this.updateSelectedMail({
        ...response.data,
        mailFileInformations
      });
    });
  }

  getMessage(messageId: string) {
    return this.mailService.getMessage({ messageId });
  }

  resetSelectedMail() {
    this.selectedMailSubject.next(null);
    this.updateActiveTab(MailTabType.INFO);
    this.router.navigate(['/mail/inbox']);
  }

  updateSelectedMail(mail: Mail) {
    this.selectedMailSubject.next(mail);

    this.updateActiveTab(MailTabType.INFO);

    const queryParams: { [key: string]: any } = {};

    if (this.folder?.isDraft) {
      queryParams.isDraft = 'true';
    }

    this.router.navigate(['/mail', mail.folderId, mail.mailId], { queryParams });
  }

  updateFolder(folder: MailFolder) {
    this.folderSubject.next(folder);
  }

  updateAllFolders(folders: MailFolder[]) {
    this.allFolderSubject.next(folders);
  }

  openDrawer() {
    this._drawerOpenSubject.next(true);
  }

  closeDrawer() {
    this._drawerOpenSubject.next(false);
  }

  logout() {
    return new Promise<void>((resolve) => {
      this.mailService
        .logout()
        .subscribe(() => {
          this.clearMailData();
          resolve();
        });
    });
  }

  logoutWhenTokenExpired() {
    this.clearMailData();
    localStorage.removeItem('mail_integration_type');
    sessionStorage.clear();
    MailSignalRService.onLogout();
  }

  private clearMailData(): void {
    this.mails = [];
    this.hasMail = false;
    this.pageToken = null;
    this.isPaginationEnabled = false;
    this.mailsSubject.next(this.mails);
  }
}
