export enum ChartType {
    FIRST_RESPONSE_SLA = '8a79ca1e-c17a-4da8-9f94-eae38f0726d8',
    RESOLUTION_SLA = 'a5b3fdfd-f053-4eaa-b7f2-9c04648d6dac',
    RESPONSE_TIME = '63fed609-413b-45f4-b335-21c0590fbc45',
    TICKET_STATUS_DISTRIBUTION = '04f719f8-93d3-41b9-9e24-594b0ee9b4cb',
    SLA = 'ff8fb7ec-5b61-423e-a5da-10d87c88e963',
    FIRST_RESPONSE_SLA_OVERALL = 'f024b86a-8738-40e9-8509-fd6e287f0472',
    RESOLUTION_SLA_OVERALL = '421dc09c-da23-42b6-9284-84ec8d1cdf4d',
    CATEGORY_DISTRIBUTION = 'ce27625f-a491-4062-9d11-8788f32ae31c',
    MESSAGES = '9b3e5c0b-3d35-4144-8257-7a141f9dc85c',
    RESOLUTION_TIME = 'f75a912f-427d-4781-806f-0f2f78bb6e82',
    UNANSWERED = 'f1724b01-0464-4d06-947b-7a4ad66652b6',
    OPEN_TICKETS = 'b6cd13ea-034f-4a46-8c80-d0193161ff05',
    SOLVED_TICKETS = '7baacf46-ebb2-4a19-abf6-333900dbf7b5',
    TOTAL_TICKETS = 'f99ce405-2319-4cf2-b365-02e615313923'
}
