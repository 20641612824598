import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    CustomerExperienceSLATarget, CustomerExperienceSLATargetDeleteReqest, CustomerExperienceSLATargetInsertReqest, CustomerExperienceSLATargetSearchReqest,
    CustomerExperienceSLATargetSetStatusReqest,
    CustomerExperienceSLATargetUpdateReqest, PagedResponse, Response
} from '@core/api';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CustomerExperienceSLATargetService {
    constructor(private http: HttpClient) { }

    search(request: CustomerExperienceSLATargetSearchReqest): Observable<PagedResponse<CustomerExperienceSLATarget>> {
        return this.http.post<PagedResponse<CustomerExperienceSLATarget>>(environment.apiUrl + '/api/CxSlaTarget/Search', request);
    }

    insert(request: CustomerExperienceSLATargetInsertReqest): Observable<Response<CustomerExperienceSLATarget>> {
        return this.http.post<Response<CustomerExperienceSLATarget>>(environment.apiUrl + '/api/CxSlaTarget/Insert', request);
    }

    update(request: CustomerExperienceSLATargetUpdateReqest): Observable<Response<CustomerExperienceSLATarget>> {
        return this.http.post<Response<CustomerExperienceSLATarget>>(environment.apiUrl + '/api/CxSlaTarget/Update', request);
    }

    updateStatus(request: CustomerExperienceSLATargetSetStatusReqest): Observable<Response<CustomerExperienceSLATarget>> {
        return this.http.post<Response<CustomerExperienceSLATarget>>(environment.apiUrl + '/api/CxSlaTarget/SetStatus', request);
    }

    delete(request: CustomerExperienceSLATargetDeleteReqest): Observable<Response<CustomerExperienceSLATarget>> {
        return this.http.post<Response<CustomerExperienceSLATarget>>(environment.apiUrl + '/api/CxSlaTarget/Delete', request);
    }

    get(uuid: string): Observable<Response<CustomerExperienceSLATarget>> {
        const headers = new HttpHeaders({ 'content-type': 'application/json' });
        return this.http.post<Response<CustomerExperienceSLATarget>>(environment.apiUrl + '/api/CxSlaTarget/Get', JSON.stringify(uuid), { headers });
    }
}
