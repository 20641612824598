<div class="max-w-full">
  <div class="card overflow-auto">
    <div class="bg-app-bar px-6 h-16 border-b sticky left-0" fxLayout="row" fxLayoutAlign="start center">
      <div class="bg-card rounded-full border px-4" fxFlex="400px" fxFlex.lt-md="auto" fxHide.xs fxLayout="row"
           fxLayoutAlign="start center">
        <ic-icon [icIcon]="icSearch" size="20px"></ic-icon>
        <input [formControl]="searchCtrl" [placeholder]="'GENERAL.SEARCH_ELLIPSIS' | translate"
               class="px-4 py-3 border-0 outline-none w-full bg-card" type="text">
      </div>
      <span fxFlex></span>
      <net-mat-table-column-filter [columns]="columns" [isOrderActive]="false"
                                   [tableColumnFilterConfig]="tableColumnFilterConfig">
      </net-mat-table-column-filter>
      <button (click)="openFormDialog()" *ngIf="isAccount && hasUploadPermission" class="ml-4" color="primary"
              fxFlex="none"
              mat-mini-fab
              matTooltip="{{'GENERAL.ADD' | translate }}" type="button">
        <mat-icon [icIcon]="icAdd"></mat-icon>
      </button>
    </div>

    <table @stagger [dataSource]="dataSource" class="w-full" mat-table matSort>
      <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
        <!-- text Column -->
        <ng-container *ngIf="column.property === 'name'" [matColumnDef]="column.property">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ column.label }}</th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            <a (click)=" hasDownloadPermission ? clickDownload(row, $event) : null"
               [title]="'DOCUMENT.DOWNLOAD_FILE' | translate"
               class="download text-primary-500 underline">
              {{ row[column.property] }} {{ row['documentExtensionType'].name }}
            </a>
          </td>
        </ng-container>
        <!-- size Column -->
        <ng-container *ngIf="column.property === 'size'" [matColumnDef]="column.property">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ column.label }}</th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            {{ row[column.property] | formatFileSize }}</td>
        </ng-container>

        <!-- createdOn Column -->
        <ng-container *ngIf="column.property === 'createdOn'" [matColumnDef]="column.property">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            {{ column.label }}
            <net-mat-header-filter *ngIf="column.filterable" [column]="column" [dataSource]="dataSource"
                                   [key]="column.key" [type]="matHeaderFilterType.date"></net-mat-header-filter>
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            {{ row['createdOn'] | momentFormat:'DD MMMM YYYY HH:mm' }}</td>
        </ng-container>

        <!-- location Column -->
        <ng-container *ngIf="column.property === 'location'" [matColumnDef]="column.property">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ column.label }}</th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            <span *ngIf="row.opportunityId || row.activityId" [ngClass]="{
                'label-status-color-blue': row.opportunityId,
                'label-status-color-green': row.activityId
              }"
                  class="net-label-status">{{ row.opportunityId ? ('GENERAL.OPPORTUNITIES' | translate) : ('GENERAL.ACTIVITIES' | translate)
              }}</span>
          </td>
        </ng-container>

        <!-- createdUser Column -->
        <ng-container *ngIf="column.property === 'createdBy'" [matColumnDef]="column.property">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            {{ column.label }}
            <net-mat-header-filter *ngIf="column.filterable" [column]="column" [dataSource]="dataSource"
                                   [key]="column.key" [selectSource]="dataSource.$createBys"
                                   [type]="matHeaderFilterType.select"></net-mat-header-filter>
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            {{ row['createdUser']?.firstName }} {{ row['createdUser']?.lastName }}
          </td>
        </ng-container>

        <!-- source Column -->
        <ng-container *ngIf="column.property === 'documentSource' && isAccount" [matColumnDef]="column.property">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            {{ column.label }}
            <net-mat-header-filter *ngIf="column.filterable" [column]="column" [dataSource]="dataSource"
                                   [key]="column.key" [selectSource]="dataSource.$sources"
                                   [type]="matHeaderFilterType.select"></net-mat-header-filter>
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            {{ row.source ? (("GENERAL." + (row.source| uppercase)) | translate) : '' }}
          </td>
        </ng-container>

        <!-- button Column -->
        <ng-container *ngIf="column.type === 'button' && !isAccount" matColumnDef="actions">
          <th *matHeaderCellDef mat-header-cell>{{ column.label }}</th>
          <td *matCellDef="let row" class="w-10 text-secondary" mat-cell>
            <button (click)="clickDelete(row, $event)" *ngIf="checkDeletePermission(row)"
                    [matTooltip]="'GENERAL.REMOVE' | translate">
              <ic-icon [icon]="icRemove" class="text-red-500" size="15px"></ic-icon>
            </button>
          </td>
        </ng-container>

        <ng-container *ngIf="column.type === 'button' && isAccount" matColumnDef="actions">
          <th *matHeaderCellDef mat-header-cell>{{ column.label }}</th>
          <td *matCellDef="let row" class="w-10 text-secondary" mat-cell>
            <button (click)="$event.stopPropagation()"
                    [matMenuTriggerData]="{ row : row }"
                    [matMenuTriggerFor]="actionsMenu" mat-icon-button type="button">
              <mat-icon [icIcon]="icMoreHoriz"></mat-icon>
            </button>
          </td>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="not-found">
        <td *matFooterCellDef [colSpan]="visibleColumns.length" class="text-center" mat-footer-cell>
          {{"GENERAL.NO_RESULT_FOUND" | translate}}
        </td>
      </ng-container>
      <tr *matFooterRowDef="['not-found']" [class.hidden]="dataSource.loading !== false || dataSource.empty !== true"
          mat-footer-row></tr>

      <ng-container matColumnDef="loading">
        <td *matFooterCellDef [colSpan]="visibleColumns.length" class="text-center" mat-footer-cell> {{"GENERAL.LOADING"
          | translate}} </td>
      </ng-container>
      <tr *matFooterRowDef="['loading']" [class.hidden]="initiated === true" mat-footer-row></tr>

      <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
      <tr (click)="onContextMenuAction(row)" *matRowDef="let row; columns: visibleColumns;" @fadeInUp
          class="hover:bg-hover trans-ease-out cursor-pointer"
          mat-row></tr>

    </table>

    <net-mat-table-paginator [tableColumnFilterConfig]="tableColumnFilterConfig"></net-mat-table-paginator>
  </div>
</div>

<mat-menu #actionsMenu="matMenu" xPosition="before" yPosition="below">
  <ng-template let-row="row" matMenuContent>

    <button (click)="clickDownload(row, $event)"
            [disabled]="!hasDownloadPermission" color="primary"
            mat-menu-item>
      <mat-icon [icIcon]="icDownload"></mat-icon>
      <span>{{ 'GENERAL.DOWNLOAD' | translate }}</span>
    </button>

    <button (click)="clickDelete(row, $event)" *ngIf="(row?.source === 'Customer')"
            [disabled]="!hasDeletePermission" color="primary"
            mat-menu-item>
      <mat-icon [icIcon]="icDelete"></mat-icon>
      <span>{{ 'GENERAL.DELETE' | translate }}</span>

    </button>

    <button (click)="onContextMenuAction(row)" *ngIf="!(row?.source === 'Customer')" color="primary"
            mat-menu-item>
      <mat-icon [icIcon]="icOpenLink"></mat-icon>
      <span>{{ 'DOCUMENT.OPEN_LINK' | translate }}</span>
    </button>
  </ng-template>
</mat-menu>
