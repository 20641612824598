import { Component, DoCheck, ElementRef, HostBinding, Input, OnDestroy, Optional, Self } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';

import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

import { Subject } from 'rxjs';

import { SalesOrganizationRequest, SalesOrganization, SalesOrganizationService } from '@core/api';

@Component({
  selector: 'net-select-sales-organization',
  templateUrl: './select-sales-organization.component.html',
  styleUrls: ['./select-sales-organization.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: SelectSalesOrganizationComponent
    }
  ]
})
export class SelectSalesOrganizationComponent implements OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<SalesOrganization|null> {

  static nextId = 0;

  loading = false;
  focused = false;
  errorState = false;
  controlType = 'select-sales-organization';
  describedBy = '';
  stateChanges = new Subject<void>();

  salesOrganizations: SalesOrganization[] = [];
  selectCtrl = new UntypedFormControl();

  @Input() tabIndex;

  @Input() externalSalesOrganizations: SalesOrganization[];

  @Input()
  set systemUserId(value) {
    if (value) {
      this.fetchList(value);
    }
    this._systemUserId = value;
  }

  get systemUserId() {
    return this._systemUserId;
  }

  private _systemUserId: string;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    if (value) {
      this.selectCtrl.disable();
    } else {
      this.selectCtrl.enable();
    }

    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): SalesOrganization | null { return this.selectCtrl.value; }
  set value(value: SalesOrganization | null) {
    this.selectCtrl.setValue(value);
    this.onChange(value);
    this.stateChanges.next();
  }

  @Input()
  get removalIds(): string[] { return this._removalIds; }
  set removalIds(value) {
    this._removalIds = value;
  }
  private _removalIds: string[] = [];

  @HostBinding('id') id = `select-sales-organization-${SelectSalesOrganizationComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  get shouldLabelFloat() { return this.focused || !this.empty; }

  get empty() { return !this.value; }

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private salesOrganizationService: SalesOrganizationService,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Material form field implementation
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  onSelectionChange() {
    this.onChange(this.value);
    this.stateChanges.next();
  }

  private fetchList(systemUserId: string) {
    this.loading = true;
    const request: SalesOrganizationRequest = { systemUserId };
    this.salesOrganizationService.search(request).subscribe(response => {
      this.loading = false;
      this.salesOrganizations = response.data.filter(({ salesOrganizationId }) => !this.removalIds.includes(salesOrganizationId));

      this.synchronizeSalesOrganizations();

      if (this.salesOrganizations.length === 1) {
        this.value = this.salesOrganizations[0];
      }

      this.onSelectionChange();
    });
  }

  compareWith = (one: SalesOrganization, two: SalesOrganization) => one && two && one.salesOrganizationId === two.salesOrganizationId;

  private setSalesOrganization(salesOrganization: SalesOrganization) {
    this.value = salesOrganization;
    this.selectCtrl.disable();
  }

  private synchronizeSalesOrganizations() {
    if (this.externalSalesOrganizations) {
      this.salesOrganizations = this.salesOrganizations.filter(so =>
        this.externalSalesOrganizations.some(ex => ex.salesOrganizationId === so.salesOrganizationId)
      );
    }
  }

  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');
  writeValue = (value: SalesOrganization) => this.value = value;
  ngOnDestroy = () => this.stateChanges.complete();

  onChange = (_: any) => {
  }
  onTouched = () => {
  }
  onContainerClick = () => {
  }

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }
}
