import { AbstractControl, ValidationErrors } from '@angular/forms';

import { OpportunityService } from './input-opportunity-services.component.model';

export class InputOpportunityServiceValidators {
  static required(control: AbstractControl): ValidationErrors | null {
    let required = { required: true };

    const value: OpportunityService[] = control.value;
    if (!value) {
      return required;
    }

    // Check each items in given service list, allow if each rows do not empty
    const allValid = value.map(item => [
      !item.serviceId,
      !item.metric,
      !item.unit,
      item.detailRequired === true && !item.detail,
      item.unitPriceRequired === true && !item.unitPrice,
      item.revenue === null
    ].every(isEmpty => false === isEmpty)
    ).every(isValid => true === isValid);

    if (allValid) {
      required = null;
    }

    return required;
  }
}
