import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Pipe({
  name: 'image'
})
export class ImagePipe implements PipeTransform {

  constructor(private http: HttpClient) { }

  // iframe blob kullanımına izin vermediği için base64'e çevrilmesi gerekiyor
  transform(url: string, method: 'get' | 'post' = 'get', attachmentId?: string, convertToBase64 = false): Observable<string> {
    if (method === 'get') {
      return this.http.get(url, { responseType: 'blob' }).pipe(
        switchMap(blob => convertToBase64 ? this.blobToBase64(blob) : this.blobToObjectURL(blob))
      );
    }

    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post(url, JSON.stringify(attachmentId), { headers, responseType: 'blob' }).pipe(
      switchMap(blob => convertToBase64 ? this.blobToBase64(blob) : this.blobToObjectURL(blob))
    );
  }

  private blobToObjectURL(blob: Blob): Observable<string> {
    return new Observable(observer => {
      const objectURL = URL.createObjectURL(blob);
      observer.next(objectURL);
      observer.complete();
    });
  }

  private blobToBase64(blob: Blob): Observable<string> {
    return new Observable(observer => {
      const reader = new FileReader();
      reader.onload = () => {
        observer.next(reader.result as string);
        observer.complete();
      };
      reader.onerror = error => observer.error(error);
      reader.readAsDataURL(blob);
    });
  }

}
