import { Injectable } from '@angular/core';
import { BoardListColumnsTransferData, DynamicField, SocketSearchRequest } from '@core/api';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BoardDetailFilterService {
  private filterSubject = new BehaviorSubject<SocketSearchRequest>(null);
  filter$ = this.filterSubject.asObservable();

  private chosenHeaderFilterSubject = new BehaviorSubject<SocketSearchRequest>(null);
  chosenHeaderFilterSubject$ = this.chosenHeaderFilterSubject.asObservable();

  private refreshReportSubject = new BehaviorSubject<boolean>(null);
  refreshReport$ = this.refreshReportSubject.asObservable();

  private showRefreshReportButtonSubject = new BehaviorSubject<boolean>(null);
  showRefreshReportButton$ = this.showRefreshReportButtonSubject.asObservable();

  private isActiveDetailedFilterSubject = new Subject<SocketSearchRequest>();
  isActiveDetailedFilter$ = this.isActiveDetailedFilterSubject.asObservable();

  private getFilterColumnsSubject = new Subject<BoardListColumnsTransferData>();
  getFilterColumns$ = this.getFilterColumnsSubject.asObservable();

  public getViewNameSubject = new Subject<string>();
  getViewName$ = this.getViewNameSubject.asObservable();

  private getBoardCustomFieldsSubject = new Subject<DynamicField[]>();
  getBoardCustomFields$ = this.getBoardCustomFieldsSubject.asObservable();

  showDependencySubject = new BehaviorSubject<boolean>(false);

  constructor() { }

  updateFilter(data: SocketSearchRequest) {
    this.filterSubject.next(data);
  }

  updateHeaderFilter(data: SocketSearchRequest) {
    this.chosenHeaderFilterSubject.next(data);
  }

  getFilter() {
    return this.filterSubject.value;
  }

  refreshReport() {
    this.refreshReportSubject.next(true);
  }

  clearRefreshReport() {
    this.refreshReportSubject.next(null);
  }

  refreshReportButton() {
    this.showRefreshReportButtonSubject.next(true);
  }

  clearRefreshReportButton() {
    this.showRefreshReportButtonSubject.next(null);
  }

  activateDetailedFilter(data: SocketSearchRequest) {
    this.isActiveDetailedFilterSubject.next(data);
  }

  setFilterColumns(data: BoardListColumnsTransferData) {
    this.getFilterColumnsSubject.next(data);
  }

  setBoardCustomFields(data: DynamicField[]) {
    this.getBoardCustomFieldsSubject.next(data);
  }

  clear() {
    this.filterSubject.next(null);
  }
}
