import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

import { CustomDashboardCommunication, CustomDashboardCommunicationRequest, CustomDashboardCommunicationSystemUser, Response } from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class CustomDashboardCommunicationService {

  constructor(private http: HttpClient) { }

  insert(request: CustomDashboardCommunicationRequest): Observable<Response<CustomDashboardCommunication>> {
    return this.http.post<Response<CustomDashboardCommunication>>(environment.apiUrl + '/api/CustomDashboardCommunication/Insert', request);
  }

  list(customDashboardId: string): Observable<Response<any>> { // TODO any yerine type eklenecek
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<any>>( // TODO any yerine type eklenecek
        environment.apiUrl + '/api/CustomDashboardCommunication/List', JSON.stringify(customDashboardId), { headers }
    );
  }

  getSystemUserList(customDashboardId: string): Observable<Response<CustomDashboardCommunicationSystemUser[]>> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<CustomDashboardCommunicationSystemUser[]>>(
      environment.apiUrl + '/api/CustomDashboardCommunication/SystemUserList', JSON.stringify(customDashboardId), { headers }
    );
  }
}
