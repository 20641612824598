/**
 * hasClassParents
 *
 * An helper function work a like jQuery.parents
 * Check element's ancestors have given class name
 */
export default function hasClassParents(element: Element, className: string) {
  if (element.parentElement) {
    // Check match for given class name, if exists we can return
    if (element.parentElement.classList.contains(className)) {
      return true;
    }

    // If not matched, create recursive until found or reach root
    return hasClassParents(element.parentElement, className);
  }

  return false;
}
