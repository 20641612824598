export enum NotificationSourceType {
    Account = '0',
    LeadDraft = '1',
    Activity = '2',
    Opportunity = '3',
    Assignment = '4',
    Board = '5',
    Card = '6',
    CustomerNote = '7',
    Contact = '8',
    Report = '9'
}
