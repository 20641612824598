import { Component, OnDestroy } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { NavigationService } from '../services/navigation.service';
import { NavigationItem } from './navigation-item/navigation-item.interface';

@UntilDestroy()
@Component({
  selector: 'net-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnDestroy {

  items: NavigationItem[];

  constructor(
    private navigationService: NavigationService
  ) {
    this.navigationService.navigationItems$.pipe(untilDestroyed(this)).subscribe(response => {
      this.items = response;
    });
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy(): void { }
}
