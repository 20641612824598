export enum SurveyMailTypesOption {
    SALES_ORGANIZATION = 'df176ca6-5e12-4cfe-93ac-93f1d4a1cb82',
    WARNINGS = '570cbc4d-8831-4f07-bc8b-0567c4465f6e',
    CONTACT_LISTS = '778fa1ed-54d8-434a-9072-f70c0aad7051',
    ACTIVITIES = '4df8e32a-0529-405e-9394-3795f4defd89',
    SELECTED_CONTACTS = 'a3eb7be2-2fb0-47e8-a5c7-d4d3c3848d13',
}

export enum SurveyMailContactTypesOption {
    ALL_CONTACTS = 'a4eb0efa-755c-44ca-ba98-fcaff329e5e3',
    RANDOM = 'ad0aba2a-f6d6-4e6a-b86b-f3ce90364c15',
    CONTACT_TYPES = '9f84eeb1-d839-4bd4-aed5-a10be11250a7'
}

export enum SurveyMailRecurringOption {
    RECURRING = 0,
    ONLY_ONCE = 1,
    WHEN_ACTIVITY = 2,
  }

export enum EnumRepeatCycle {
    WEEKLY = 0,
    MONTHLY = 1,
    YEARLY = 2,
    CRON = 3
}

export enum SurveyScoreOptions {
    OPTION_0 = '4874580b-316f-4b35-830a-5dc39d261239',
    OPTION_1 = '78ee11f3-7373-444a-8666-38fad92d96b1',
    OPTION_2 = '488bfe66-5bd0-42f0-9671-18518e3379e3',
    OPTION_3 = '742bb57f-84d8-4ea3-a4b9-78e2447ccdb7',
    OPTION_4 = '73b20cbe-ce6c-4cda-9d26-13ca4b7be168',
    OPTION_5 = '2f1c7015-23f5-4205-9060-5c24c7390a33',
    OPTION_6 = '2a615672-7698-41e9-8d8b-5b982f7ce856',
    OPTION_7 = 'c642475c-60e9-4502-a129-78c580c106f4',
    OPTION_8 = '019a164c-7a8e-48b8-9b45-084645f258bd',
    OPTION_9 = '91f5ce7b-9b0d-49ef-8472-5a3e843fb131',
    OPTION_10 = 'cb8b74cc-e642-4847-8b0d-74af72814a7b',
}

export enum CustomFilterRangeType {
    Today = 0,
    NextWeek = 1,
    ThisWeek = 2,
    ThisMonth = 3,
    Prev30day = 4,
    Prev3month = 5,
    Prev1year = 6
}


