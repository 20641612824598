<input #input (blur)="onBlur()" [formControl]="inputCtrl" [matAutocomplete]="auto"
  [matTooltipDisabled]="!!this.customer" [matTooltip]="'GENERAL.PLEASE_SELECT_A_CUSTOMER' | translate"
  [readonly]="!this.customer || isDisabled" [tabIndex]="tabIndex" class="autocomplete-input" matInput>
<mat-autocomplete #auto="matAutocomplete" (closed)="onClosed()" (optionSelected)="onSelect($event)"
  [autoActiveFirstOption]="true" [displayWith]="displayName">
  <ng-container *ngIf="this.addresses">
    <mat-option *ngIf="addresses.length === 0 && !loading" [disabled]="isDisabled"
      disabled>{{"GENERAL.ADDRESS_NOT_FOUND" | translate}}</mat-option>
    <mat-option *ngFor="let address of addresses" [disabled]="isDisabled" [disabled]="isDisabled" [value]="address">
      <span>{{ address.name }}</span>
      <span class="address-detail" fxHide.lt-md> - {{ address.addressLine }}
        <ng-container *ngIf="address.county?.name && address.city?.name">
          {{ address.county.name}}/{{ address.city.name }}
        </ng-container>
      </span>
    </mat-option>
  </ng-container>
</mat-autocomplete>

<button (click)="add(value)" *ngIf="!disabled && editable && value" [disabled]="isDisabled" [hidden]="this.loading"
  class="edit-address" mat-icon-button type="button">
  <ic-icon [icon]="icEdit" size="16px"></ic-icon>
</button>

<button (click)="add()" *ngIf="!disabled" [disabled]="isDisabled" [hidden]="this.loading" class="add-address"
  mat-icon-button type="button">
  <ic-icon [icon]="icAdd" size="16px"></ic-icon>
</button>

<mat-progress-spinner *ngIf="loading === true" diameter="16" mode="indeterminate"></mat-progress-spinner>