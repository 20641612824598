import {
  Component, DoCheck, ElementRef, HostBinding, Input, OnDestroy, Optional, Self, ViewChild,
  OnInit, EventEmitter, Output, ChangeDetectionStrategy, ChangeDetectorRef
} from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatFormFieldControl } from '@angular/material/form-field';

import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';

import { ApiService, Contact, Customer } from '@core/api';
import { Observable } from 'rxjs/internal/Observable';
import { MatDialog } from '@angular/material/dialog';

import { ContactFormDialogComponent } from '../contact-form-dialog/contact-form-dialog.component';
import { ContactDialogData } from '../contact-form-dialog/contact-form-dialog.component.model';

import icAdd from '@iconify/icons-ic/twotone-add';
import icEdit from '@iconify/icons-ic/twotone-edit';

@Component({
  selector: 'net-input-customer-contact[customer]',
  templateUrl: './input-customer-contact.component.html',
  styleUrls: ['./input-customer-contact.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: InputCustomerContactComponent
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputCustomerContactComponent implements OnInit, OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<Contact | null> {

  static nextId = 0;

  loading = false;
  focused = false;
  errorState = false;
  controlType = 'select-contact';
  describedBy = '';
  stateChanges = new Subject<void>();

  icAdd = icAdd;
  icEdit = icEdit;

  contacts: Contact[] = [];
  inputCtrl = new UntypedFormControl();

  @ViewChild('auto') auto: MatAutocomplete;
  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  @Input() tabIndex;

  @Input() source: Observable<Contact[]>;

  @Input()
  get customer() { return this._customer; }
  set customer(customer: Customer) {
    this._customer = customer;
    this.reset();

    if (customer && !this.source) {
      this.loading = true;
      this.inputCtrl.disable();
      this.initDefaultOptions().finally(() => {
        this.loading = false;
        if (!this.disabled) {
          this.inputCtrl.enable();
        }
      });
    }
  }
  private _customer;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    if (value) {
      this.inputCtrl.disable();
    } else {
      this.inputCtrl.enable();
    }

    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): Contact | null { return this._value; }
  set value(value: Contact | null) {
    this._value = value;
    this.onChange(value);
    this.stateChanges.next();
  }
  private _value: Contact;

  @Input()
  get checkPermissions(): boolean { return this._checkPermissions; }
  set checkPermissions(value: boolean) {
    this._checkPermissions = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _checkPermissions = false;


  @Output() update: EventEmitter<Contact[]> = new EventEmitter<Contact[]>();

  @HostBinding('id') id = `select-contact-${InputCustomerContactComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  get shouldLabelFloat() { return this.focused || !this.empty; }

  get empty() { return !this.value; }

  private initDefaultOptions(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.api.contact.search({ filter: { customerId: this.customer.customerId, enabled: true, permissionControl: this.checkPermissions } }).toPromise()
        .then((response) => {
          this.contacts = response.data.results;
          this.selectFirst();
          this.cdr.detectChanges();
        })
        .finally(() => resolve());
    });
  }

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private api: ApiService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Material form field implementation
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    // Listen input control value changes and filter results
    this.inputCtrl.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      filter(value => typeof value === 'string'),
      tap(() => {
        this.loading = true;
        this.contacts = [];
        // this.value = null;
      }),
      switchMap(value => this.api.contact.search({
        filter: {
          enabled: true,
          customerId: this.customer.customerId,
          contactFullName: value,
          permissionControl: this.checkPermissions
        },
        pageSize: 20
      })),
      tap(() => this.loading = false)
    ).subscribe((response) => {
      this.contacts = response.data.results;
      cdr.detectChanges();
    });
  }

  ngOnInit() {
    if (this.source) {
      this.source.subscribe(contacts => {
        this.contacts = contacts;
        this.selectFirst();
      });
    }
  }

  displayName(contact: Contact) {
    if (!contact) {
      return '';
    }

    return `${contact.firstName} ${contact.lastName} - ${contact.jobTitle ? contact.jobTitle : ''}`;
  }

  add(value?: Contact) {
    let data: ContactDialogData = { customer: this.customer };
    if (value) {
      data = { customer: this.customer, contact: value };
    }

    data.checkPermissions = this.checkPermissions;

    this.dialog.open(ContactFormDialogComponent, { autoFocus: false, data })
      .addPanelClass('cdk-overlay-full')
      .afterClosed()
      .subscribe((contact: Contact) => {
        if (contact) {
          this.api.contact.get(contact.contactId, this.checkPermissions).subscribe(response => {
            if (value) {
              const index = this.contacts.findIndex(x => x.contactId === response.data.contactId);
              if (index !== -1) {
                this.contacts[index] = response.data;
              } else {
                this.contacts.unshift(response.data);
              }
            } else {
              this.contacts.unshift(response.data);
            }

            // Notify list update event
            this.update.emit(this.contacts);

            this.value = response.data;
            this.inputCtrl.setValue(this.value);
          });
        }
      });
  }

  reset() {
    if (this.inputCtrl.pristine) {
      return;
    }

    this.value = null;
    this.contacts = [];
    this.inputCtrl.setValue(null);
    this.inputCtrl.markAsPristine();

    this.initDefaultOptions();
  }

  selectFirst() {
    if (this.contacts.length === 1) {
      this.value = this.contacts[0];
      this.inputCtrl.setValue(this.value);
    } else if (!this.value) {
      this.value = null;
      this.inputCtrl.setValue(this.value);
    }
  }

  onSelect(event: MatAutocompleteSelectedEvent) {
    this.value = event.option.value;
    this.input.nativeElement?.blur();
  }

  onBlur() {
    // On blur with nothing selected reset component
    if (this.ngControl.dirty && !this.auto.isOpen && !this.value) {
      this.reset();
    }
  }

  onClosed() {
    // On panel close with nothing selected reset component
    if (this.ngControl.dirty && !this.value) {
      this.reset();
    }
  }

  onChange = (_: any) => { };
  onTouched = () => { };
  onContainerClick = () => { };
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');

  writeValue(value: Contact) {
    this.value = value;
    this.inputCtrl.setValue(value);
  }

  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }
}
