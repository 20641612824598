import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';

import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, DoCheck, ElementRef, EventEmitter, HostBinding, Input, KeyValueDiffers, OnDestroy, Optional, Output,
  Self, ViewChild
} from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import {
  MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger
} from '@angular/material/autocomplete';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Account, Opportunity, OpportunityService } from '@core/api';
import { Permission } from '@core/auth/auth.enum';
import { hasUserPermission } from '@core/store';
import icArrowDropDown from '@iconify/icons-ic/twotone-arrow-drop-down';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';

@UntilDestroy()
@Component({
  selector: 'net-select-opportunity-searchable',
  templateUrl: './select-opportunity-searchable.component.html',
  styleUrls: ['./select-opportunity-searchable.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: SelectOpportunitySearchableComponent
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectOpportunitySearchableComponent implements OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<Opportunity> {

  static nextId = 0;

  loading = false;
  focused = false;
  errorState = false;
  controlType = 'select-opportunity-searchable';
  describedBy = '';
  stateChanges = new Subject<void>();

  opportunities: Opportunity[] = [];
  filteredOpportunities: Opportunity[] = [];
  inputCtrl = new UntypedFormControl();

  icArrowDropDown = icArrowDropDown;

  @ViewChild('auto') auto: MatAutocomplete;
  @ViewChild('input') input: ElementRef<HTMLInputElement>;
  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger }) inputAutoComplete: MatAutocompleteTrigger;

  @Input() tabIndex: number;
  @Input() panelWidth: string | number = 'auto';
  @Input() tooltip: string;
  @Input() showOrganizationAndStatus = false;

  @Input()
  get account() { return this._account; }
  set account(account: Account) {
    this._account = account;

    if (account) {
      this.store.select(hasUserPermission(Permission.OPPORTUNITY_SEARCH)).pipe(untilDestroyed(this)).subscribe(permission => {
        if (permission) {
          this.opportunityService.search({
            filter: {
              accountId: account.accountId
            },
            orderType: 'DESC',
            orderBy: 'createdOn'
          }).subscribe(response => {
            this.opportunities = response.data.results;

            // this.value = null;
            // this.inputCtrl.setValue(null);

            if (this.value) {
              const selectedOpportunity = this.opportunities?.find(opp => opp.opportunityId === this.value.opportunityId);
              if (!selectedOpportunity) {
                this.opportunities.push(this.value);
              } else {
                this.value = selectedOpportunity;
              }
            }
            this.filteredOpportunities = this.opportunities;
            this.cdr.detectChanges();
          });
        } else {
          this.opportunities = this.value ? [this.value] : [];
          this.filteredOpportunities = this.opportunities;
        }
      });
    }
  }
  private _account: Account;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    if (value) {
      this.inputCtrl.disable();
    } else {
      this.inputCtrl.enable();
    }

    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): Opportunity { return this._value; }
  set value(value: Opportunity) {
    value = this.opportunities?.find(opportunity => opportunity.opportunityId === value?.opportunityId) ?? value;
    this._value = value;
    this.onChange(value);
    this.stateChanges.next();

    if (value && value.code) {
      this.inputCtrl.setValue(value);
    }
  }
  private _value: Opportunity;

  @Output() optionSelected = new EventEmitter<Opportunity>();

  @HostBinding('id') id = `select-offer-${SelectOpportunitySearchableComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  get shouldLabelFloat() { return this.focused || !this.empty; }

  get empty() { return !this.value; }

  differ: any;

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private opportunityService: OpportunityService,
    private cdr: ChangeDetectorRef,
    private store: Store,
    private differs: KeyValueDiffers,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Material form field implementation
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.differ = this.differs.find({}).create();

    this.inputCtrl.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      filter(value => typeof value === 'string'),
      tap(() => {
        this.loading = true;
        this.filteredOpportunities = [];
        // this.value = null;
      }),
    ).subscribe((keyword) => {
      keyword = keyword.toLowerCase();
      this.filteredOpportunities = this.opportunities
        .filter(opportunity => opportunity.name.toLowerCase().includes(keyword) ||
          opportunity.code.toLowerCase().includes(keyword));
      this.loading = false;
      cdr.detectChanges();
    });
  }

  displayName = (opportunity: Opportunity) => {
    return opportunity ? opportunity.code + ' ' + opportunity.name : '';
  }

  compareWith(one: Opportunity, two: Opportunity) {
    return one && two && one.opportunityId === two.opportunityId;
  }

  reset() {
    if (this.inputCtrl.pristine) {
      return;
    }

    this.value = null;
    this.inputCtrl.setValue(null);
    this.inputCtrl.markAsPristine();
    this.filteredOpportunities = this.opportunities;
  }

  openPanel(evt): void {
    evt.stopPropagation();
    this.inputAutoComplete.openPanel();
  }

  onSelect(event: MatAutocompleteSelectedEvent) {
    this.value = event.option.value;
    this.input.nativeElement?.blur();

    this.optionSelected.emit(this.value);
  }

  onBlur() {
    // On blur with nothing selected reset component
    if (this.ngControl.dirty && !this.auto.isOpen && !this.value) {
      this.reset();
    }
  }

  onClosed() {
    // On panel close with nothing selected reset component
    if (this.ngControl.dirty && !this.value) {
      this.reset();
    }
  }

  onChange = (_: any) => { };
  onTouched = () => { };
  onContainerClick = () => { };
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');
  writeValue = (value: Opportunity) => {
    this.value = value;
    this.inputCtrl.setValue(value);
  }

  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }

    const changes = this.differ.diff(this.account);
    if (changes) {
      changes.forEachChangedItem((record) => {
        if (record?.key === 'accountId') {
          this.value = null;
          this.inputCtrl.setValue(null);
        }
      });
    }
  }
}
