import { MatFormFieldControl } from '@angular/material/form-field';
import { ControlValueAccessor, FormControl, NgControl, UntypedFormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import {
  Self,
  Input,
  Output,
  OnInit,
  DoCheck,
  Optional,
  OnDestroy,
  Component,
  ViewChild,
  ElementRef,
  HostBinding,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';

import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';

import { Permission } from '@core/auth/auth.enum';
import { hasUserPermission } from '@core/store';
import { Assignment, AssignmentSearchRequest, AssignmentService, LeadDraft, } from '@core/api';

@UntilDestroy()
@Component({
  selector: 'net-input-assignment',
  templateUrl: './input-assignment.component.html',
  styleUrls: ['./input-assignment.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: InputAssignmentComponent
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputAssignmentComponent implements OnInit, OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<Assignment> {
  static nextId = 0;

  private existsFilter: AssignmentSearchRequest['filter'] = {};

  loading = false;
  focused = false;
  errorState = false;
  controlType = 'input-assignment';
  describedBy = '';
  stateChanges = new Subject<void>();
  hasSearchPermission = false;

  assignments: Assignment[] = [];
  defaultValue: Assignment = null;
  leadDrafts: LeadDraft[] = [];
  inputCtrl = new UntypedFormControl();

  @Input() tabIndex: number;

  @Input() filter: AssignmentSearchRequest['filter'] = {};
  @Input() panelWidth: string | number;
  @Input() loadWhenFilterExists: string[] | null = null;

  @Output() selectionChange: EventEmitter<Assignment> = new EventEmitter<Assignment>();

  @ViewChild('auto') auto: MatAutocomplete;
  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  @HostBinding('id') id = `input-assignment-${InputAssignmentComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    if (value) {
      this.inputCtrl.disable();
    } else {
      this.inputCtrl.enable();
    }

    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): Assignment | null { return this._value; }
  set value(value: Assignment | null) {
    this._value = value;
    this.onChange(value);
    this.stateChanges.next();
  }
  private _value: Assignment;

  get empty() { return !this.value; }

  get shouldLabelFloat() { return this.focused || !this.empty; }

  private search(filters: AssignmentSearchRequest['filter'] = {}, pageSize = 100) {
    return this.assignmentService.search({
      filter: {
        ...this.filter,
        ...filters,
      },
      orderBy: 'createdOn',
      orderType: 'DESC',
      pageSize,
    });
  }

  private initDefaultOptions() {
    this.assignments = [];

    // Prevent load contacts when permission is not granted
    if (!this.hasSearchPermission) {
      return;
    }

    // Make sure filters exists when load when defined
    if (this.filter && this.loadWhenFilterExists) {
      const filterKeys = Object.keys(this.filter);
      if (!this.loadWhenFilterExists.every((key) => filterKeys.includes(key) && this.filter[key])) {
        return;
      }
    }

    // Search contacts with filter
    this.loading = true;
    this.search().subscribe((response) => {
      this.loading = false;
      this.assignments = response.data.results;
      this.selectFirst();
      this.cdr.detectChanges();
    });
  }

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private store: Store,
    private assignmentService: AssignmentService,
    private cdr: ChangeDetectorRef,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Material form field implementation
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.store
      .select(hasUserPermission(Permission.ASSIGNMENT_SEARCH))
      .pipe(untilDestroyed(this))
      .subscribe(permission => this.hasSearchPermission = permission);

    // Listen input control value changes and filter results
    this.inputCtrl.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      filter(() => this.hasSearchPermission),
      filter(value => typeof value === 'string'),
      tap(() => {
        this.value = null;
        this.loading = true;
        this.assignments = [];
      }),
      switchMap(value => this.search({ searchText: value })),
      tap(() => this.loading = false)
    ).subscribe((response) => {
      this.assignments = response.data.results;
      this.cdr.detectChanges();
    });
  }
  ngOnInit() {
    this.initDefaultOptions();
  }

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }

    // Trigger reset when filter changes
    if (this.filter !== this.existsFilter) {
      this.existsFilter = this.filter;
      this.reset(true);
    }
  }

  displayName(assignment: Assignment) {
    if (!assignment) {
      return '';
    }

    return `${assignment.description} - ${assignment.assignedUser.firstName} ${assignment.assignedUser.lastName}`;
  }

  reset(force = false) {
    if (!force && this.inputCtrl.pristine) {
      return;
    }

    this.value = this.defaultValue;
    this.assignments = [];
    this.inputCtrl.setValue(this.defaultValue);
    this.inputCtrl.markAsPristine();

    this.initDefaultOptions();
  }

  writeValue(value: Assignment) {
    this.value = value;
    this.defaultValue = value;
    this.inputCtrl.setValue(value);
  }

  selectFirst() {
    if (this.assignments.length === 1) {
      this.value = this.assignments[0];
      this.inputCtrl.setValue(this.value);
    }
  }

  onSelect(event: MatAutocompleteSelectedEvent) {
    this.value = event.option.value;
    this.input.nativeElement?.blur();

    this.selectionChange.emit(this.value);
  }

  onBlur() {
    // On blur with nothing selected reset component
    if (this.ngControl.dirty && !this.auto.isOpen && !this.value) {
      this.reset();
    }
  }

  onClosed() {
    // On panel close with nothing selected reset component
    if (this.ngControl.dirty && !this.value) {
      this.reset();
    }
  }

  ngOnDestroy = () => this.stateChanges.complete();

  onChange = (_: any) => { };
  onTouched = () => { };
  onContainerClick = () => { };
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;

  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');
}
