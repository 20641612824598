import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BoardDetailFormDialogState } from '@core/store';

export const boardDetailFormDialog = createFeatureSelector('boardDetailFormDialog');

export const getBoardDetailFormDialog = createSelector(
    boardDetailFormDialog,
    (state: BoardDetailFormDialogState) => state
);

export const getBoardDetailFormDialogCard = createSelector(
    boardDetailFormDialog,
    (state: BoardDetailFormDialogState) => state.card
);

export const getBoardDetailFormDialogCardDescription = createSelector(
    boardDetailFormDialog,
    (state: BoardDetailFormDialogState) => state.description
);

export const getBoardDetailFormDialogCardAttachment = createSelector(
    boardDetailFormDialog,
    (state: BoardDetailFormDialogState) => state.cardAttachments
);

export const getBoardDetailFormDialogCardObject = createSelector(
    boardDetailFormDialog,
    (state: BoardDetailFormDialogState) => state.cardObjects
);

export const getBoardDetailFormDialogCardReminderDate = createSelector(
    boardDetailFormDialog,
    (state: BoardDetailFormDialogState) => state.reminderDate
);

export const getBoardDetailFormDialogCardComment = createSelector(
    boardDetailFormDialog,
    (state: BoardDetailFormDialogState) => ({ cardComments: state.cardComments, commentCount: state.commentCount })
);

export const getBoardDetailFormDialogCardDynamicField = createSelector(
    boardDetailFormDialog,
    (state: BoardDetailFormDialogState) => state.dynamicFieldValues
);

export const getBoardDetailFormDialogCardActionType = createSelector(
    boardDetailFormDialog,
    (state: BoardDetailFormDialogState) => state.cardActionType
);

export const getBoardDetailFormDialogCardChildCard = createSelector(
    boardDetailFormDialog,
    (state: BoardDetailFormDialogState) => state.childCards
);
