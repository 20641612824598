import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component, DoCheck, ElementRef, HostBinding, Input, OnDestroy,
  OnInit, Optional, Self, ViewChild
} from '@angular/core';
import { ControlValueAccessor, NgControl, UntypedFormControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { ApiService, ContactRoleGroup } from '@core/api';
import { SurveyMailContactTypeEnum } from '@core/api/survey-mail-type/survey-mail-contact-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'net-select-survey-mail-contact-type',
  templateUrl: './select-survey-mail-contact-type.component.html',
  styleUrls: ['./select-survey-mail-contact-type.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: SelectSurveyMailContactTypeComponent
    }
  ]
})
export class SelectSurveyMailContactTypeComponent implements OnInit, OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<string>  {

  static nextId = 0;
  stateChanges = new Subject<void>();
  selectCtrl = new UntypedFormControl();
  focused = false;
  errorState = false;
  describedBy = '';
  controlType = 'select-contact-type';

  surveyMailContactTypes$: Observable<ContactRoleGroup[]>;


  @Input() isSecond = false;
  @Input() isDisabled = false;
  @Input() tabIndex;
  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    if (value) {
      this.selectCtrl.disable();
    } else {
      this.selectCtrl.enable();
    }
    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): string { return this.selectCtrl.value; }
  set value(value: string) {
    this.selectCtrl.setValue(value);
    this.onChange(value);
    this.stateChanges.next();
  }

  @ViewChild('contactTypeSelect') contactTypeSelect: MatSelect;
  @HostBinding('id') id = `select-contact-type-${SelectSurveyMailContactTypeComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  get shouldLabelFloat() { return this.focused || !this.empty; }

  get empty() { return !this.value; }


  constructor(
    private apiService: ApiService,
    private translate: TranslateService,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl
  ) {
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.getContactType();

  }

  ngOnInit(): void {
  }

  getContactType() {
    this.surveyMailContactTypes$ = this.apiService.contactRoleGroup.list().pipe(
      map(response => response.data),
      map(type => {

        if (this.isSecond) {
          return [
            ...type.map(x => {
              return {
                contactRoleGroupId: x.contactRoleGroupId,
                name: this.translate.instant('ContactRoleGroup.' + x.name)
              };
            }),
            ...[
              {
                contactRoleGroupId: SurveyMailContactTypeEnum.DO_NOT_SEND,
                name: this.translate.instant('ContactRoleGroup.Do Not Send'),
              }, {
                contactRoleGroupId: SurveyMailContactTypeEnum.RANDOM,
                name: this.translate.instant('ContactRoleGroup.Random')
              }
            ]
          ];
        }

        return type.map(x => {
          return {
            contactRoleGroupId: x.contactRoleGroupId,
            name: this.translate.instant('ContactRoleGroup.' + x.name)
          };
        });
      })
    );
  }

  onSelectionChange() {
    this.onChange(this.value);
    this.stateChanges.next();
  }

  openedChange(isOpened: boolean) {
    if (isOpened) {
      const panel = this.contactTypeSelect.panel.nativeElement;
      panel.scrollTop = 0;
    }
  }


  compareWith = (one: string, two: string) => one && two && one === two;
  onChange = (_: any) => { };
  selectionChange = (_: any) => { };
  onTouched = () => { };
  onContainerClick = () => { };
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');
  writeValue = (value: string) => {
    this.value = value;
    this.selectCtrl.setValue(value);
  }

  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }

}
