import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { GetAccountSuccess, GetAccountError, AccountActionTypes, GetAccount } from './account.actions';
import { AccountService } from '@core/api';
import { of } from 'rxjs';


@Injectable()
export class AccountEffects {
    constructor(
        private actions$: Actions,
        private accountService: AccountService
    ) { }

    Account$ = createEffect(() => this.actions$.pipe(
        ofType(AccountActionTypes.GET_ACCOUNT),
        mergeMap((request: GetAccount) => this.accountService.get(request.payload)),
        switchMap(response => of(new GetAccountSuccess(response.data))),
        catchError((error) => of(new GetAccountError(error)))
    ));
}
