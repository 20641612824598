export enum ParticipantType {
  CONTACT = 'contact',
  SYSTEM_USER = 'systemUser'
}

export enum InputParticipantMode {
  BOTH = 'both',
  CONTACT = 'contact',
  SYSTEM_USER = 'systemUser'
}
