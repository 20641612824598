import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

import {
  PagedResponse,
  Response,
  BoardCardUserData,
  BoardInfoCardChart,
  BoardInfoCardsSearchRequest,
  BoardInfoUserReportSearchDataRequest,
  FusionCombinationDataSet,
  Board,
} from '@core/api';
import { BoardCustomFieldReportSearchRequest, BoardCustomFieldsChart } from './board-report.model';

@Injectable({
  providedIn: 'root',
})
export class BoardReportService {
  constructor(private http: HttpClient) { }

  boardInfoCardsChartData(request: BoardInfoCardsSearchRequest): Observable<Response<BoardInfoCardChart>> {
    return this.http.post<Response<BoardInfoCardChart>>(environment.apiUrl + '/api/BoardReport/BoardInfoCardsChartData', request);
  }

  cardAssignedUserReportData(request: BoardInfoUserReportSearchDataRequest): Observable<PagedResponse<BoardCardUserData>> {
    return this.http.post<PagedResponse<BoardCardUserData>>(environment.apiUrl + '/api/BoardReport/CardAssignedUserReportData', request);
  }

  cardCreateUserReportData(request: BoardInfoUserReportSearchDataRequest): Observable<PagedResponse<BoardCardUserData>> {
    return this.http.post<PagedResponse<BoardCardUserData>>(environment.apiUrl + '/api/BoardReport/CardCreateUserReportData', request);
  }
  boardInfoSectionChangeGraphData(request: BoardInfoCardsSearchRequest): Observable<Response<FusionCombinationDataSet[]>> {
    return this.http.post<Response<FusionCombinationDataSet[]>>(environment.apiUrl + '/api/BoardReport/BoardInfoSectionChangeGraphData', request);
  }

  boardCustomFieldsChartData(request: BoardCustomFieldReportSearchRequest): Observable<Response<BoardCustomFieldsChart>> {
    return this.http.post<Response<BoardCustomFieldsChart>>(environment.apiUrl + '/api/BoardReport/BoardCustomFieldsChartData', request);
  }

  getBoardWithBoardInfos(uuid: string): Observable<Board> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Board>(
      environment.apiUrl + '/api/BoardReport/GetBoardWithBoardInfos',
      JSON.stringify(uuid),
      { headers }
    );
  }
}
