import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';

import { Observable } from 'rxjs';

import { Response, PagedResponse, FilterRequest, Contract, ContractRequest } from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class ContractService {
  constructor(private http: HttpClient) { }

  search(request: FilterRequest): Observable<PagedResponse<Contract>> {
    return this.http.post<PagedResponse<Contract>>(environment.apiUrl + '/api/Contract/Search', request);
  }

  get(uuid: string): Observable<Response<Contract>> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<Contract>>(environment.apiUrl + '/api/Contract/Get', JSON.stringify(uuid), { headers });
  }

  insert(request: ContractRequest): Observable<Response<Contract>> {
    return this.http.post<Response<Contract>>(environment.apiUrl + '/api/Contract/Insert', request);
  }

  update(request: ContractRequest): Observable<Response<Contract>> {
    return this.http.post<Response<Contract>>(environment.apiUrl + '/api/Contract/Update', request);
  }
}
