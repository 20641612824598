import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { Initial, InitialSetting } from '@core/api';

@Injectable({
    providedIn: 'root'
})
export class InitialService {

    constructor(private http: HttpClient) { }

    initial() {
        return this.http.get<Initial>(environment.apiUrl + '/api/Home/Initial');
    }

    initialSettings(userId: string) {
        return this.http.get<InitialSetting[]>(`${environment.apiUrl}/api/Home/Initial/Settings?userId=${userId}`);
    }
}
