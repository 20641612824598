import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import { Observable } from 'rxjs';

import {
  Response,
  PagedResponse,
  FilterRequest,
  TaxOffice,
  TaxOfficeRequest,
  TaxOfficeFilterRequest
} from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class TaxOfficeService {
  constructor(private http: HttpClient) { }

  search(request: TaxOfficeFilterRequest): Observable<PagedResponse<TaxOffice>> {
    return this.http.post<PagedResponse<TaxOffice>>(environment.apiUrl + '/api/TaxOffice/Search', request);
  }

  list(request: FilterRequest): Observable<PagedResponse<TaxOffice>> {
    return this.http.post<PagedResponse<TaxOffice>>(environment.apiUrl + '/api/TaxOffice/List', request);
  }

  insert(request: TaxOfficeRequest): Observable<Response<TaxOffice>> {
    return this.http.post<Response<TaxOffice>>(environment.apiUrl + '/api/TaxOffice/Insert', request);
  }

  update(request: TaxOfficeRequest): Observable<Response<TaxOffice>> {
    return this.http.post<Response<TaxOffice>>(environment.apiUrl + '/api/TaxOffice/Update', request);
  }

  delete(request: TaxOffice): Observable<Response<TaxOffice>> {
    return this.http.post<Response<TaxOffice>>(environment.apiUrl + '/api/TaxOffice/Delete', request);
  }
}
