import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import { Response, DangerousGoods, DangerousGoodsSearchRequest } from '@core/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DangerousGoodsService {
  constructor(private http: HttpClient) { }

  search(request: DangerousGoodsSearchRequest): Observable<Response<DangerousGoods[]>> {
    return this.http.post<Response<DangerousGoods[]>>(environment.apiUrl + '/api/DangerousGoods/Search', request);
  }
}
