import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import { FilterRequest, ContractPriceListType, Response } from '@core/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContractPriceListTypeService {
  constructor(private http: HttpClient) { }

  list(request: FilterRequest): Observable<Response<ContractPriceListType[]>> {
    return this.http.post<Response<ContractPriceListType[]>>(environment.apiUrl + '/api/ContractPriceListType/List', request);
  }
}
