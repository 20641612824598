import { debounceTime, filter, switchMap, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import {
  AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnDestroy,
  OnInit, Output, SimpleChanges, ViewChild
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  Account, Address, AddressRequest, Country, Customer, CustomerAddressType,
  DynamicFieldEntityAsString,
  DynamicFieldEntityId, Guid, LocationService, processDynamicFieldValues
} from '@core/api';
import icClose from '@iconify/icons-ic/twotone-close';
import icSave from '@iconify/icons-ic/twotone-save';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';

import { GeocodeService } from '../../services/geocode.service';
import { Location } from '../../services/location-model';
import { GoogleTranslateService } from '../../services/google-translate.service';
import { DEFAULT_LOCALE } from 'src/app/translation.config';
import { TranslationService } from '../../services/translate/translation.service';
import { scrollToError } from '../../utils/scroll-to-error';
import { getCountries } from '@core/store';
import { Store } from '@ngrx/store';

@UntilDestroy()
@Component({
  selector: 'net-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss']
})
export class AddressFormComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {


  markerOptions: google.maps.MarkerOptions = { draggable: true };

  @Input() loading = false;
  @Input() customer: Customer = null;
  @Input() columnCount = 3;

  @Input()
  set mode(value: 'page' | 'dialog') {
    if (value === 'page') {
      this.isPage = true;
    }

    if (value === 'dialog') {
      this.isDialog = true;
    }
  }

  @Input()
  set address(address: Address) {
    if (address) {
      this.form.patchValue({
        name: address.name,
        type: address.addressTypeId,
        country: address.country,
        city: address.city,
        county: address.county,
        address: address.addressLine,
        dynamicFieldValues: address.dynamicFieldValues || [],
      }, { emitEvent: false, onlySelf: true });
      this.location = { lat: address.lat, lng: address.lng };

      if (address.customer) {
        this.form.get('customer').setValue(address.customer);
      }

      if (address.city?.cityId === Guid.EMPTY) {
        this.form.get('city').setValue(null);
      }

      if (address.county?.countyId === Guid.EMPTY) {
        this.form.get('county').setValue(null);
      }

      const countryValue = this.form.get('country').value;
      const cityValue = this.form.get('city').value;
      const countyValue = this.form.get('county').value;

      const hasCitySelection = this.isHaveCitySelection(countryValue);
      const hasCountySelection = this.isHaveCountySelection(countryValue);

      if (countryValue) {
        if (hasCitySelection && hasCountySelection) {
          const locationName = cityValue && countyValue ? `${cityValue.name}, ${countyValue.name}` : cityValue?.name || countryValue.name;
          this.zoom = cityValue && countyValue ? 15 : cityValue ? 8 : 5;
          this.handleLocationSearch(locationName);
        } else if (hasCitySelection && !hasCountySelection) {
          const locationName = cityValue ? cityValue.name : countryValue.name;
          this.zoom = cityValue ? 8 : 5;
          this.handleLocationSearch(locationName);
        } else {
          this.zoom = 5;
          this.handleLocationSearch(countryValue.name);
        }
      }
    } else {
      this.location = this.base;
      this.markerOptions.position = this.location;
      this.addressToCoordinates();
    }
    this._address = address;
  }
  get address(): Address { return this._address; }
  public _address: Address = undefined;

  @Output() save = new EventEmitter<AddressRequest>();

  form: UntypedFormGroup;

  isPage = false;
  isDialog = false;
  initial = false;
  autoCompleteItems = [];
  autoAdressSelect = false;
  openSeparator = false;

  addressType = CustomerAddressType;
  // countryTurkey = {
  //   countryId: CountryUUID.TURKEY,
  //   name: this.translate.instant('Country.Turkey'),
  // };

  // countryRussia = {
  //   countryId: CountryUUID.RUSSIA,
  //   name: this.translate.instant('Country.Russia'),
  // };

  base = { lat: 41.04051500730728, lng: 28.977036455701256 };
  location: Location;
  searchText: string;
  zoom = 15;

  DynamicFieldEntityId = DynamicFieldEntityId;
  DynamicFieldEntityAsString = DynamicFieldEntityAsString;

  @ViewChild('map') public mapElementRef: ElementRef;

  icSave = icSave;
  icClose = icClose;

  countries: { [key: string]: string };
  culture: string;

  countryList: Country[] = [];

  constructor(
    private ngZone: NgZone,
    private formBuilder: UntypedFormBuilder,
    private geocodeService: GeocodeService,
    private ref: ChangeDetectorRef,
    private locationService: LocationService,
    private translate: TranslateService,
    private translationService: TranslationService,
    private store: Store,
    private googleTranslateService: GoogleTranslateService,
  ) {

    this.store.select(getCountries()).subscribe((countries) => {
      this.countryList = countries;
    });

    const user = JSON.parse(localStorage.getItem('api_user'));
    this.culture = user?.culture?.slice(0, 2);
    this.culture === DEFAULT_LOCALE ? this.translate.get('Country').subscribe(response => this.countries = response) :
      this.translationService.getTranslation(DEFAULT_LOCALE).subscribe(response => this.countries = response['Country']);

    this.form = this.formBuilder.group({
      name: [null],
      customer: [this.customer, Validators.required],
      account: [null, Validators.required],
      type: [null],
      country: [null, Validators.required],
      city: [null],
      county: [null],
      address: [null, Validators.required],
      addressFromGoogle: [null],
      dynamicFieldValues: [null],
    }, { updateOn: 'change' });

    // Sync account changes with customer
    this.form.get('account')
      .valueChanges
      .pipe(filter(value => !!value))
      .subscribe((account: Account) => {
        this.form.get('customer').setValue(this.customer);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.address && changes.address.currentValue) {
      const addrInput = changes.address.currentValue;
      this.store.select(getCountries()).subscribe((countries) => {
        this.countryList = countries;
        const countryWithLocLv = this.countryList.find(c => c.countryId === addrInput.countryId);
        const addr = { ...addrInput, countryId: countryWithLocLv?.countryId, country: countryWithLocLv };
        this.address = addr;
      });
    }
  }

  private emitAddress() {
    const country = this.form.get('country').value;
    // Emit save event with address request object
    let request: AddressRequest = {
      name: this.form.get('name').value,
      customerId: this.form.get('customer').value.customerId,
      addressTypeId: this.form.get('type').value,
      countryId: country.countryId,
      enabled: this.address ? this.address.enabled : true,
      addressLine: this.form.get('address').value,
      lat: this.location.lat,
      lng: this.location.lng,
      dynamicFieldValues: processDynamicFieldValues(this.form.get('dynamicFieldValues')?.value)
    };

    // city veya county seçimi yok ise request'e dahil etme
    if (this.isHaveCitySelection(country)) {
      request = { ...request, cityId: this.form.get('city').value.cityId };
    }

    if (this.isHaveCountySelection(country)) {
      request = { ...request, countyId: this.form.get('county').value.countyId };
    }

    this.save.emit(request);
  }

  ngOnInit() {
    this.autoAdressSelect = false;
    this.form.controls.addressFromGoogle.enable();
    this.form.controls.address.disable();
    this.form.controls.country.disable();
    this.form.controls.city.disable();
    this.form.controls.county.disable();

    this.store.select(getCountries()).subscribe((countries) => {
      this.countryList = countries;
      if (this.address) {
        const countryWithLocLv = this.countryList.find(c => c.countryId === this.address.countryId);
        const addr = { ...this.address, countryId: countryWithLocLv?.countryId, country: countryWithLocLv };
        this.address = addr;
      }
    });

    this.form.get('country').valueChanges.subscribe(response => {
      const city = this.form.get('city');
      const county = this.form.get('county');

      if ((response && !this.isHaveLocationLevel(response)) || !response) {
        city.clearValidators();
        county.clearValidators();

        this.form.get('city').reset();
        this.form.get('county').patchValue('', { emitEvent: false });
      }

      if (response && this.isHaveCitySelection(response) && this.isHaveCountySelection(response)) {
        city.setValidators(Validators.required);
        county.setValidators(Validators.required);
      }

      if (response && this.isHaveCitySelection(response) && !this.isHaveCountySelection(response)) {
        city.setValidators(Validators.required);
      }

      city.updateValueAndValidity();
      county.updateValueAndValidity();

      if (response && this.autoAdressSelect) {
        this.searchText = response.name;
        this.zoom = 5;
        this.addressToCoordinates(true);
      }
    });

    this.form.get('city').valueChanges.subscribe(response => {
      if (response && this.autoAdressSelect) {
        this.searchText = response.name;
        this.zoom = 8;
        this.addressToCoordinates(true);
      }
    });

    this.form.get('county').valueChanges.subscribe(response => {
      if (response && this.autoAdressSelect) {
        this.searchText = this.form.get('city').value.name + ', ' + response.name;
        this.zoom = 15;
        this.addressToCoordinates(true);
      }
    });

    if (this.customer) {
      this.form.get('customer').setValue(this.customer);
      this.form.get('customer').disable();
      this.form.get('account').disable();
    }
  }

  addressToCoordinates(refreshLocation = false) {
    this.geocodeService.geocodeAddress(this.searchText).subscribe((response) => {
      if (refreshLocation && (response.status === google.maps.GeocoderStatus.OK)) {
        const locationRes = { lat: response.results[0].geometry.location.lat(), lng: response.results[0].geometry.location.lng() };
        this.location = locationRes;

        this.markerOptions.position = locationRes;
      }
      this.ref.detectChanges();
    });
  }

  onSubmit() {

    let valid = true;
    const country = this.form.get('country').value;
    const city = this.form.get('city').value;
    const county = this.form.get('county').value;
    const address = this.form.get('address').value;

    if (!country) {
      valid = false;
    }

    if (
      country &&
      this.isHaveCitySelection(country) &&
      this.isHaveCountySelection(country) &&
      (!city || !county || city.cityId === Guid.EMPTY || county.countyId === Guid.EMPTY)
    ) {
      valid = false;
    }
    if (
      country &&
      this.isHaveCitySelection(country) &&
      !this.isHaveCountySelection(country) &&
      (!city || city.cityId === Guid.EMPTY)
    ) {
      valid = false;
    }

    if (
      country &&
      this.isHaveCitySelection(country) &&
      this.isHaveCountySelection(country) &&
      (address === null || address === '')
    ) {
      valid = false;
    }

    // If form is valid, emit save event
    if (this.form.valid && valid) {
      this.emitAddress();
    } else {
      // Touch all form fields and trigger validate
      this.openSeparator = true;
      this.ref.detectChanges();
      this.form.markAllAsTouched();

      scrollToError();
      this.openSeparator = false;

      this.addressToggle({ checked: true }, 'save');
    }
  }

  markerDragEnd($event: MouseEvent) {
    this.location = JSON.parse(JSON.stringify($event)).latLng;
  }

  addressToggle(event, from?: string) {
    if (!event.checked) {
      this.autoAdressSelect = false;
      this.form.controls.addressFromGoogle.enable();
      this.form.controls.address.disable();
      this.form.controls.country.disable();
      this.form.controls.city.disable();
      this.form.controls.county.disable();
    } else {
      this.autoAdressSelect = true;
      this.form.controls.addressFromGoogle.disable();
      this.form.controls.address.enable();
      this.form.controls.country.enable();
      this.form.controls.city.enable();
      this.form.controls.county.enable();

      if (from) { // uyarı pop-up'ında OK tıklanınca boş alanlar kırmızı işaretlensin
        this.form.controls.city.markAsTouched();
        this.form.controls.county.markAsTouched();
      }
    }
  }

  ngAfterViewInit() {
    this.form.get('addressFromGoogle').valueChanges.pipe(
      untilDestroyed(this),
      tap(() => this.autoCompleteItems = []),
      filter((value: string) => !!value && value.length > 3 && typeof value === 'string'),
      debounceTime(300),
      switchMap(value => this.geocodeService.search(value)),
    ).subscribe((results: google.maps.places.PlaceResult[]) => {
      this.ngZone.run(() => {
        this.autoCompleteItems = results;
      });

    });
  }

  async onSelectAddressFromGoogle(option) {
    this.form.get('country').patchValue('', { emitEvent: false });
    this.form.get('city').patchValue('', { emitEvent: false });
    this.form.get('county').patchValue('', { emitEvent: false });
    this.form.get('address').setValue(option.formatted_address);

    const countryName = await this.getCountryName(option) as string;
    let cityName: string;
    let countyName: string;

    this.getAddressInfo(option).then((response: any) => {
      cityName = response.cityName;
      countyName = response.countyName;

      if (countryName) {
        this.locationService.countries({ filter: { countryName } }).subscribe((countryResponse) => {
          if (countryResponse.success && countryResponse.data.results.length > 0) {
            const country: Country = countryResponse.data.results[0];
            country.name = this.translate.instant('Country.' + country.name);
            this.form.get('country').patchValue(country, { emitEvent: false });

            if (this.isHaveCitySelection(country) && this.isHaveCountySelection(country)) {
              if (cityName) {
                this.locationService.cities({ filter: { cityName } }).subscribe((cityResponse) => {
                  if (cityResponse.data.results.length > 0) {
                    const city = cityResponse.data.results[0];
                    this.form.get('city').patchValue(city, { emitEvent: false });
                    this.location = JSON.parse(JSON.stringify(option)).geometry.location;

                    if (countyName) {
                      this.locationService.counties({ filter: { cityId: city.cityId, countyName } }).subscribe((countyResponse) => {
                        if (countyResponse.data.results.length > 0) {
                          const county = countyResponse.data.results[0];
                          this.form.get('county').patchValue(county, { emitEvent: false });

                          this.location = JSON.parse(JSON.stringify(option)).geometry.location;
                        } else {
                          this.addressWarning();
                        }
                      });
                    }
                    else {
                      this.addressWarning();
                    }

                  } else {
                    this.addressWarning();
                  }
                });
              } else {
                this.addressWarning();
              }
            } else if (this.isHaveCitySelection(country) && !this.isHaveCountySelection(country)) {
              if (cityName) {
                this.locationService.cities({ filter: { cityName } }).subscribe((cityResponse) => {
                  if (cityResponse.data.results.length > 0) {
                    const city = cityResponse.data.results[0];
                    this.form.get('city').patchValue(city, { emitEvent: false });
                    this.location = JSON.parse(JSON.stringify(option)).geometry.location;
                  } else {
                    this.addressWarning();
                  }
                });
              } else {
                this.addressWarning();
              }
            } else {
              this.location = JSON.parse(JSON.stringify(option)).geometry.location;
            }
          }
        });
      } else {
        this.addressWarning();
      }
    });

  }

  isHaveLocationLevel(country: Country) {
    return !!country?.countryLocationLevel;
  }

  isHaveCitySelection(country: Country) {
    return country?.countryLocationLevel?.cityLocationLevel?.name;
  }

  isHaveCountySelection(country: Country) {
    return country?.countryLocationLevel?.countyLocationLevel?.name;
  }


  private getAddressInfo(option) {
    return new Promise((resolve, reject) => {
      this.geocodeService.geocodeAddressWithPlaceId(option.place_id).subscribe((response) => {
        let county = null;
        let city = null;
        const country = response[0].address_components.find(element => element.types && element.types[0] === 'country');
        const dbCountry = this.countryList.find(c => c.sortName === country.short_name);

        if (dbCountry && this.isHaveCitySelection(dbCountry) && this.isHaveCountySelection(dbCountry)) {
          county = response[0].address_components
            .find((element) => element.types && element.types[0] === dbCountry.countryLocationLevel.countyLocationLevel.name);
          city = response[0].address_components
            .find((element) => element.types && element.types[0] === dbCountry.countryLocationLevel.cityLocationLevel.name);
        }
        else if (dbCountry && this.isHaveCitySelection(dbCountry) && !this.isHaveCountySelection(dbCountry)) {
          city = response[0].address_components
            .find(element => element.types && element.types[0] === dbCountry.countryLocationLevel.cityLocationLevel.name);
        }

        const addressInfo = { countyName: county?.long_name, cityName: city?.long_name };

        resolve(addressInfo);
      }, (err) => {
        reject(err);
      });
    });
  }

  async getCountryName(option) {
    return new Promise((resolve, reject) => {
      this.geocodeService.geocodeAddressWithPlaceId(option.place_id).subscribe(async (response) => {
        const country = response[0].address_components.find(element => element.types && element.types[0] === 'country');
        const countryName = Object.keys(this.countries).find(key => this.countries[key] === country.long_name);
        resolve(countryName);
      }, (err) => {
        reject(err);
      });
    });
  }

  addressWarning() {
    Swal.fire({
      icon: 'warning',
      text: this.translate.instant('ADDRESS.ADDRESS_FORMAT_WARNING'),
      confirmButtonText: this.translate.instant('GENERAL.OK')
    }).then(res => {
      if (res.isConfirmed && !this.autoAdressSelect) {
        this.addressToggle({ checked: true }, 'popup');
      }
    });
  }

  private handleLocationSearch(searchText: string): void {
    if (!this.location.lat && !this.location.lng) {
      this.searchText = searchText;
      this.addressToCoordinates(true);
    } else {
      this.addressToCoordinates();
    }
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy() { }
}
