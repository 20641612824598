import { DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class MatPaginatorIntlService extends MatPaginatorIntl {
  private decimalPipe = new DecimalPipe('tr');

  itemsPerPageLabel = 'Items Per Page';

  nextPageLabel = 'Next Page';
  previousPageLabel = 'Previous Page';

  firstPageLabel = 'First Page';
  lastPageLabel = 'Last Page';

  emptyResultLabel = 'No Results.';

  constructor(private translate: TranslateService) {
    super();

    this.translate.get('GENERAL','SURVEY').subscribe((translations) => {
      this.itemsPerPageLabel = translations.ITEMS_PER_PAGE;
      this.nextPageLabel = translations.NEXT_PAGE;
      this.previousPageLabel = translations.PREVIOUS_PAGE;
      this.firstPageLabel = translations.FIRST_PAGE;
      this.lastPageLabel = translations.LAST_PAGE;
      this.emptyResultLabel = translations.NO_RESULTS;
    });
  }

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return this.emptyResultLabel;
    }

    length = Math.max(length, 0);

    return this.translate.instant('GENERAL.TABLE_RESULT_RANGE', {
      total: this.decimalPipe.transform(length),
      current: (page + 1)
    });
  }
}
