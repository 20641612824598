import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';

import { Observable } from 'rxjs';
import { OpportunityInitials, OpportunityInitialsRequest, OpportunityInitialsSearchRequest, Response } from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class OpportunityInitialsService {

  constructor(private http: HttpClient) { }

  insert(request: OpportunityInitialsRequest): Observable<Response<OpportunityInitials>> {
    return this.http.post<Response<OpportunityInitials>>(environment.apiUrl + '/api/OpportunityInitials/Insert', request);
  }

  update(request: OpportunityInitialsRequest): Observable<Response<OpportunityInitials>> {
    return this.http.post<Response<OpportunityInitials>>(environment.apiUrl + '/api/OpportunityInitials/Update', request);
  }

  search(request: OpportunityInitialsSearchRequest): Observable<Response<OpportunityInitials>> {
    return this.http.post<Response<OpportunityInitials>>(environment.apiUrl + '/api/OpportunityInitials/Search', request);
  }

  delete(request: { opportunityInitialsId: string; }): Observable<Response<OpportunityInitials>> {
    return this.http.post<Response<OpportunityInitials>>(environment.apiUrl + '/api/OpportunityInitials/Delete', request);
  }

  check(uuid: string): Observable<Response<boolean>> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<boolean>>(environment.apiUrl + '/api/OpportunityInitials/Check', JSON.stringify(uuid), { headers });
  }
}
