import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import { Response, SalesOrganizationMailSettingsRequest, SalesOrganizationMailSettingsSearchRequest } from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class SalesOrganizationMailSettingsService {
  constructor(private http: HttpClient) { }

  update(request: SalesOrganizationMailSettingsRequest) {
    return this.http.post<Response<any>>(environment.apiUrl + '/api/SalesOrganizationMailSettings/Update', request);
  }

  insert(request: SalesOrganizationMailSettingsRequest) {
    return this.http.post<Response<any>>(environment.apiUrl + '/api/SalesOrganizationMailSettings/Insert', request);
  }

  test(request: SalesOrganizationMailSettingsRequest) {
    return this.http.post<Response<any>>(environment.apiUrl + '/api/SalesOrganizationMailSettings/Test', request);
  }

  search(request: SalesOrganizationMailSettingsSearchRequest) {
    return this.http.post<Response<any>>(environment.apiUrl + '/api/SalesOrganizationMailSettings/Search', request);
  }
}
