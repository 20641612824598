import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import {
    CustomerExperienceCategoryStatusInsertRequest, CustomerExperienceCategoryStatusUpdateRequest,
    CustomerExperienceCategoryStatusDeleteRequest, Response, CustomerExperienceCategoryStatus
} from '@core/api';

@Injectable({
    providedIn: 'root'
})
export class CustomerExperienceCategoryStatusService {

    constructor(private http: HttpClient) { }

    insert(request: CustomerExperienceCategoryStatusInsertRequest) {
        return this.http.post<Response<CustomerExperienceCategoryStatus>>(environment.apiUrl + '/api/CxCategoryStatus/Insert', request);
    }

    update(request: CustomerExperienceCategoryStatusUpdateRequest) {
        return this.http.post<Response<CustomerExperienceCategoryStatus>>(environment.apiUrl + '/api/CxCategoryStatus/Update', request);
    }

    delete(request: CustomerExperienceCategoryStatusDeleteRequest) {
        return this.http.post<Response<CustomerExperienceCategoryStatus>>(environment.apiUrl + '/api/CxCategoryStatus/Delete', request);
    }

}
