<input [matTooltip]="tooltip" #input matInput [tabIndex]="tabIndex" [formControl]="inputCtrl" [matAutocomplete]="auto"
    [placeholder]="placeholder" autocomplete="new-password" (blur)="onBlur()" autocomplete="off">
<mat-icon class="dropdown-icon" [ngClass]="{'disabled': disabled}" [icIcon]="icArrowDropDown" matSuffix
    (click)="openPanel($event)"></mat-icon>
<mat-autocomplete #auto="matAutocomplete" [panelWidth]="panelWidth" [displayWith]="displayName" (optionSelected)="onSelect($event)" (closed)="onClosed()">
    <ng-container *ngIf="languages">
        <mat-option *ngFor="let language of languages" [value]="language">
            <span *ngIf="withCustomCode && language.customCode; else nameContent">
                {{
                    !isSpecialLanguage ? (language.customCode | uppercase) + ' - ' + ('LANGUAGE.' + language.name | translate)
                    : ('LANGUAGE.' + getSystemLanguageName(language) | translate)
                }}
            </span>
            <ng-template #nameContent>{{ ('LANGUAGE.'+ language.name) | translate }}</ng-template>
        </mat-option>
    </ng-container>
</mat-autocomplete>
<mat-progress-spinner *ngIf="loading === true" diameter="16" mode="indeterminate"></mat-progress-spinner>