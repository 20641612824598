export enum MeetingStatusType {
  ACTIVE = '7911ba56-b757-41c7-b532-2efa5ca87ca8',
  COMPLETED = '2630d26a-10bc-422e-8ee2-2b6b17b9faec',
  CANCELED = '48fef464-6917-4c1c-a664-d032698c55b5',
  WAITING = 'c202d791-2531-4373-8d85-d0b1e6045fea',
  POSTPONED = 'd67c08ce-9eef-42a7-afe3-5f60b7ac85b3'
}

export enum MeetingParticipantStatus {
  OFFLINE = '1bf2a0f3-ef9f-40b4-b4b6-363b3edb6047',
  ONLINE = 'db763ccb-eecc-4acd-9895-d292a6dd6ccd',
  NOT_JOIN = 'a5bf3da8-a97b-4e3d-9614-a6858e70fa64'
}
