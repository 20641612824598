
export enum DynamicFieldConditionType {
    Equals = 'Equals',
    NotEquals = 'Not Equals',
    Contains = 'Contains',
    Null = 'Null',
    NotNull = 'Not Null',
    LessThan = 'Less than',
    GreaterThan = 'Greater than',
}

export enum DynamicFieldConditionTypeAsString {
    Equals = 'Equals',
    NotEquals = 'NotEquals',
    Contains = 'Contains',
    Null = 'Null',
    NotNull = 'NotNull',
    LessThan = 'LessThan',
    GreaterThan = 'GreaterThan',
}

export enum DynamicFieldConditionFieldSource {
    ServiceMainService = 'ServiceMainService',
    SalesOrganization = 'SalesOrganization',
    Organization = 'Organization',
    OpportunityType = 'OpportunityType',
    OpportunityStatus = 'OpportunityStatus',
    AccountStatus = 'AccountStatus',
    ActivityStatus = 'ActivityStatus',
    AssignmentStatus = 'AssignmentStatus'
}
