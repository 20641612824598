import { Observable } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivityPurposeTemplate, ActivityPurposeTemplateSearch, Response } from '@core/api';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class ActivityPurposeTemplateService {
  constructor(private http: HttpClient) { }

  list(): Observable<Response<ActivityPurposeTemplate[]>> {
    return this.http.post<Response<ActivityPurposeTemplate[]>>(environment.apiUrl + '/api/ActivityPurposeTemplate/List', {});
  }

  get(uuid: string): Observable<Response<ActivityPurposeTemplate>> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<ActivityPurposeTemplate>>(environment.apiUrl + '/api/ActivityPurposeTemplate/Get', JSON.stringify(uuid), { headers });
  }

  insert(formData: any): Observable<Response<ActivityPurposeTemplate>> {
    return this.http.post<Response<ActivityPurposeTemplate>>(environment.apiUrl + '/api/ActivityPurposeTemplate/Insert', formData);
  }

  search(request: ActivityPurposeTemplateSearch): Observable<Response<ActivityPurposeTemplate[]>> {
    return this.http.post<Response<ActivityPurposeTemplate[]>>(environment.apiUrl + '/api/ActivityPurposeTemplate/Search', request);
  }

  delete(request: ActivityPurposeTemplate): Observable<Response<ActivityPurposeTemplate>> {
    return this.http.post<Response<ActivityPurposeTemplate>>(environment.apiUrl + '/api/ActivityPurposeTemplate/Delete', request);
  }
}
