import { createFeatureSelector, createSelector } from '@ngrx/store';

import { CountryState } from './country.reducer';

export const selectCountryState = createFeatureSelector('country');

export const getCountries = (filterText?: string) => createSelector(
    selectCountryState,
    (state: CountryState) => filterText ? state.countries?.filter(item => item.name.includes(filterText)) : state.countries
);

export const getCountriesIsLoading = createSelector(
    selectCountryState,
    (state: CountryState) => state.isLoading
);
