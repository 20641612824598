import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import { FilterRequest, Response, AssignmentPriority } from '@core/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AssignmentPriorityService {
  constructor(private http: HttpClient) { }

  list(request: FilterRequest): Observable<Response<AssignmentPriority[]>> {
    return this.http.post<Response<AssignmentPriority[]>>(environment.apiUrl + '/api/AssignmentPriority/List', request);
  }
}
