import { Pipe, PipeTransform } from '@angular/core';
import { User } from '@core/api';
import { AuthService } from '@core/auth/auth.service';
import { DEFAULT_LOCALE } from 'src/app/translation.config';

@Pipe({
  name: 'currencyReplace'
})
export class CurrencyReplacePipe implements PipeTransform {

  constructor(private authService: AuthService) {
  }
  transform(value: string): string | null {
    if (!value) {
      return null;
    }

    const user: User = JSON.parse(AuthService.getUser());

    if (this.authService.numberLanguage !== user.culture.slice(0, 2)) {
      const tempCharacter = '**';
      value  = this.replaceAll(value, '.', tempCharacter);
      value = this.replaceAll(value, ',', '.' );
      value = this.replaceAll(value, tempCharacter, ',');
    }

    return value;
  }

  replaceAll(str: string, find: string, replace: string): string {
    return str.replace(new RegExp(find.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g'), replace);
  }
}
