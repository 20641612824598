import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

import { OpportunityExpectedDateHistorySearchRequest, Response, OpportunityExpectedDateHistory } from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class OpportunityExpectedDateHistoryService {
  constructor(private http: HttpClient) { }

  search(request: OpportunityExpectedDateHistorySearchRequest): Observable<Response<OpportunityExpectedDateHistory[]>> {
    return this.http.post<Response<OpportunityExpectedDateHistory[]>>(environment.apiUrl + '/api/OpportunityExpectedDateHistory/Search', request);
  }
}
