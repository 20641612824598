import { MatSelect } from '@angular/material/select';

export function scrollToError() {
    const container = document.querySelector('mat-dialog-content') || document.querySelector('mat-sidenav-content');
    const dom = container.getElementsByTagName('mat-form-field');

    for (let i = 0; i < dom.length; i++) {
      if (dom[i]?.classList.contains('ng-invalid') && !dom[i].classList.contains('mat-form-field-disabled')) {

        const chipListOnScreen = isChipListOnScreen(dom[i]);
        if (chipListOnScreen) {
            scroll(container, chipListOnScreen);
        }

        const matSelect: MatSelect = isMatSelectOnScreen(dom[i]);
        if (matSelect) {
            matSelect.focus();
        }

        const ckEditor = isCkEditorOnScreen(dom[i]) as HTMLElement ?? false;
        if (ckEditor && ckEditor.classList.contains('service-form-zone')) {
            ckEditor.focus();
            scroll(container, ckEditor);
        }

        const input = isInputOnScreen(dom[i]);
        if (input) {
            input.focus();
            // scroll(container, input);
        }

        const textarea = isTextareaOnScreen(dom[i]);
        if (textarea) {
            textarea.focus();
        }
        break;
      }
    }
}

export function isCkEditorOnScreen(dom) {
    return document.getElementsByTagName('ckEditor')[0];
}

export function isChipListOnScreen(dom) {
    return dom.getElementsByTagName('mat-chip-list')[0]?.getElementsByTagName('input')[0] ?? false;
}

export function isMatSelectOnScreen(dom) {
    return dom.getElementsByTagName('mat-select')[0] ?? false;
}

export function isInputOnScreen(dom) {
    return dom.getElementsByTagName('input')[0] ?? false;
}

export function isTextareaOnScreen(dom) {
    return dom.getElementsByTagName('textarea')[0] ?? false;
}

export function scroll(container, element) {
    container.scroll({top: element?.scrollTop, behavior: 'smooth'});
}
