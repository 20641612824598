import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';

import { Observable } from 'rxjs';

import {
  Response,
  PagedResponse,
  FilterRequest,
} from '@core/api';
import {
  SurveyMailTypeRequest,
  SurveyMailTypeFilterRequest,
  SurveyMailType,
  SurveyMailContactType
} from './survey-mail-type.model';
import { SurveyMailRequest } from '../survey-mail/survey-mail.model';

@Injectable({
  providedIn: 'root'
})
export class SurveyMailTypeService {
  constructor(private http: HttpClient) { }

  search(request: SurveyMailTypeFilterRequest): Observable<PagedResponse<SurveyMailType>> {
    return this.http.post<PagedResponse<SurveyMailType>>(environment.apiUrl + '/api/SurveyMailList/Search', request);
  }

  list(request: FilterRequest): Observable<Response<SurveyMailType[]>> {
    return this.http.post<Response<SurveyMailType[]>>(environment.apiUrl + '/api/SurveyMailList/SurveyMailListTypeList', request);
  }

  contactTypeList(request: FilterRequest): Observable<Response<SurveyMailContactType[]>> {
    return this.http.post<Response<SurveyMailContactType[]>>(environment.apiUrl + '/api/SurveyMailList/ContactTypeList', request);
  }

  get(uuid: string): Observable<Response<SurveyMailType>> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<SurveyMailType>>(environment.apiUrl + '/api/SurveyMailList/Get', JSON.stringify(uuid), { headers });
  }

  insert(request: SurveyMailTypeRequest): Observable<Response<SurveyMailType>> {
    return this.http.post<Response<SurveyMailType>>(environment.apiUrl + '/api/SurveyMailList/Insert', request);
  }

  update(request: SurveyMailTypeRequest): Observable<Response<SurveyMailType>> {
    return this.http.post<Response<SurveyMailType>>(environment.apiUrl + '/api/SurveyMailList/Update', request);
  }

  updateStatus(request: SurveyMailRequest): Observable<Response<SurveyMailType>> {
    return this.http.post<Response<SurveyMailType>>(environment.apiUrl + '/api/SurveyMailList/UpdateActiveStatus', request);
  }

  delete(surveyId): Observable<Response<SurveyMailType>> {
    return this.http.post<Response<SurveyMailType>>(environment.apiUrl + '/api/SurveyMailList/Delete', { surveyId });
  }
}
