import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

import { Response, PagedResponse, SalesCountyGroup, SalesCountyGroupRequest } from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class SalesCountyGroupService {
  constructor(private http: HttpClient) { }

  search(request?: any): Observable<PagedResponse<SalesCountyGroup>> {
    return this.http.post<PagedResponse<SalesCountyGroup>>(environment.apiUrl + '/api/SalesCountyGroup/Search', request);
  }

  get(salesOrganizationId?: string): Observable<Response<SalesCountyGroup>> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<SalesCountyGroup>>(environment.apiUrl + '/api/SalesCountyGroup/Get', JSON.stringify(salesOrganizationId), { headers });
  }

  update(request: SalesCountyGroupRequest) {
    return this.http.post<Response<SalesCountyGroup>>(environment.apiUrl + '/api/SalesCountyGroup/Update', request);
  }

  insert(request: SalesCountyGroupRequest) {
    return this.http.post<Response<SalesCountyGroup>>(environment.apiUrl + '/api/SalesCountyGroup/Insert', request);
  }
}
