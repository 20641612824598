import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { SurveyMailRecipient, SurveyMailRecipientsInsertRequest } from './survey-mail-recipients.model';
import { Observable } from 'rxjs';
import { Response } from '../api.model';

@Injectable({ providedIn: 'root' })
export class SurveyMailRecipientsService {

    constructor(private http: HttpClient) { }

    list(): Observable<Response<SurveyMailRecipient[]>> {
        return this.http.post<Response<SurveyMailRecipient[]>>(environment.apiUrl + '/api/SurveyMailRecipient/List', {});
    }

    bulkUpdate(request: SurveyMailRecipientsInsertRequest[]): Observable<Response<SurveyMailRecipient[]>> {
        return this.http.post<Response<SurveyMailRecipient[]>>(environment.apiUrl + '/api/SurveyMailRecipient/BulkUpdate', request);
    }
}
