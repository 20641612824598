import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import { ActivityFollowupStatus, Response } from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class ActivityFollowupStatusService {
  constructor(private http: HttpClient) { }

  list() {
    return this.http.post<Response<ActivityFollowupStatus[]>>(environment.apiUrl + '/api/ActivityFollowupStatus/List', {});
  }
}
