import { SalesOrganization } from '@core/api';
import { LeadDraftActions, LeadDraftActionTypes } from '@core/store';

export interface LeadDraftState {
    unpickedSalesOrganizations: SalesOrganization[];
    errorMessage: string;
    errorCode: string;
}

const initialState: LeadDraftState = {
    unpickedSalesOrganizations: null,
    errorMessage: null,
    errorCode: null
};

export function leadDraftReducer(state: LeadDraftState = initialState, action: LeadDraftActions) {
    switch (action.type) {
        case LeadDraftActionTypes.ADD_SALES_ORGANIZATION_AS_UNPICKED:
            const unpickedSO = action.payload.salesOrganization;

            return {
                ...state,
                unpickedSalesOrganizations: state.unpickedSalesOrganizations?.filter(so => {
                    return so.salesOrganizationId !== unpickedSO.salesOrganizationId;
                })
            };
        case LeadDraftActionTypes.FILTER_LEAD_DRAFT_SALES_ORGANIZATIONS_SUCCESS:
            return {
                ...state,
                unpickedSalesOrganizations: action.payload
            };
        case LeadDraftActionTypes.FILTER_LEAD_DRAFT_SALES_ORGANIZATIONS_ERROR:
            return {
                ...state,
                errorMessage: action.payload.message,
                errorCode: action.payload.errorCode
            };
        default:
            return state;
    }
}
