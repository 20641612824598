import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import { Observable } from 'rxjs';
import { FilterRequest, OpportunityType, PagedResponse } from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class OpportunityTypeService {

  constructor(private http: HttpClient) { }

  list(): Observable<PagedResponse<OpportunityType>> {
    return this.http.post<PagedResponse<OpportunityType>>(environment.apiUrl + '/api/OpportunityType/List', {});
  }

  search(filter: FilterRequest): Observable<PagedResponse<OpportunityType>>{
    return this.http.post<PagedResponse<OpportunityType>>(environment.apiUrl + '/api/OpportunityType/Search', filter);
  }
}
