<input class="currency-input" #input matInput [matTooltip]="tooltip" [tabIndex]="tabIndex" [formControl]="inputCtrl"
    [matAutocomplete]="auto" [placeholder]="placeholder" [matTooltipDisabled]="!!this.selectedCurrency"
    (blur)="onBlur()">
<mat-icon class="dropdown-icon" [icIcon]="icArrowDropDown" matSuffix (click)="openPanel($event)"></mat-icon>
<mat-autocomplete #auto="matAutocomplete" panelWidth="auto" [displayWith]="displayName" [autoActiveFirstOption]="true"
    [matTooltip]="tooltip" matTooltipPosition="above" (optionSelected)="onSelectionChange($event)"
    (closed)="onClosed()">
    <mat-option *ngFor="let currency of filteredCurrencies" [value]="currency.transactionCurrencyId">
        {{('TransactionCurrency.' + currency.currencyName) | translate}} - {{currency.isoCurrencyCode}}
    </mat-option>
</mat-autocomplete>