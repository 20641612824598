import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, DoCheck, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl, UntypedFormControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { FilterRequest, SalesRouteLevel, SalesRouteLevelService } from '@core/api';
import { Subject } from 'rxjs';

@Component({
  selector: 'net-select-sales-route-level',
  templateUrl: './select-sales-route-level.component.html',
  styleUrls: ['./select-sales-route-level.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: SelectSalesRouteLevelComponent
    }
  ]
})
export class SelectSalesRouteLevelComponent implements OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<SalesRouteLevel | null> {

  static nextId = 0;

  loading = false;
  focused = false;
  errorState = false;
  controlType = 'select-sales-route-level';
  describedBy = '';
  stateChanges = new Subject<void>();

  salesRouteLevels: SalesRouteLevel[] = [];
  selectCtrl = new UntypedFormControl();

  @Input() tabIndex;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    if (value) {
      this.selectCtrl.disable();
    } else {
      this.selectCtrl.enable();
    }

    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): SalesRouteLevel | null { return this.selectCtrl.value; }
  set value(value: SalesRouteLevel | null) {
    this.selectCtrl.setValue(value);
    this.onChange(value);
    this.stateChanges.next();
  }

  @Input()
  get salesOrganizationId(): string { return this._salesOrganizationId; }
  set salesOrganizationId(value: string) {
    this._salesOrganizationId = value;

    if (value) {
      this.getSalesRouteLevels();
    }
  }
  private _salesOrganizationId: string;

  @HostBinding('id') id = `select-sales-route-level-${SelectSalesRouteLevelComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  get shouldLabelFloat() { return this.focused || !this.empty; }

  get empty() { return !this.value; }

  constructor(
    private salesRouteLevelService: SalesRouteLevelService,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Material form field implementation
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  getSalesRouteLevels() {
    this.loading = true;
    const request: FilterRequest = {
      filter: {
        salesOrganizationId: this.salesOrganizationId
      },
      pageSize: 1000
    };

    this.salesRouteLevelService.search(request).subscribe(response => {
      this.salesRouteLevels = response.data.results.sort((a, b) => a.level - b.level);
      if (this.salesRouteLevels.length === 1) {
        this.value = this.salesRouteLevels[0];
      }
      this.loading = false;
    });
  }

  onSelectionChange() {
    this.onChange(this.value);
    this.stateChanges.next();
  }

  compareFn(first: SalesRouteLevel, second: SalesRouteLevel) {
    return first && second ? first.salesRouteLevelId === second.salesRouteLevelId : first === second;
  }

  onChange = (_: any) => { };
  onTouched = () => { };
  onContainerClick = () => { };
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');
  writeValue = (value: SalesRouteLevel) => this.value = value;
  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }
}
