import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  DoCheck,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { NgControl, UntypedFormControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { UserGroups, UserGroupsDetail } from '@core/api';
import { Subject } from 'rxjs';
import { debounceTime, pairwise, startWith } from 'rxjs/operators';

import { replaceTurkishCharacters } from 'src/app/shared/utils/replace-turkish-characters';

@Component({
  selector: 'net-multi-select-cc-users',
  templateUrl: './multi-select-cc-users.component.html',
  styleUrls: ['./multi-select-cc-users.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: MultiSelectCcUsersComponent,
    }
  ]
})
export class MultiSelectCcUsersComponent implements OnInit, DoCheck {
  static nextId = 0;
  stateChanges = new Subject<void>();
  selectCtrl = new UntypedFormControl();
  focused = false;
  errorState = false;
  describedBy = '';
  searchText = '';
  changedId: string;
  isOpened = false;

  @Input()
  get userGroups(): UserGroups[] { return this._userGroups; }
  set userGroups(value: UserGroups[]) {
    this._userGroups = value;
    this.filteredUserGroups = this._userGroups;
  }
  private _userGroups: UserGroups[] = [];
  public filteredUserGroups: UserGroups[] = [];

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    if (value) {
      this.selectCtrl.disable();
    } else {
      this.selectCtrl.enable();
    }
    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): string[] {
    return this.selectCtrl.value;
  }
  set value(value: string[]) {
    this.selectCtrl.setValue(value);
    this.onChange(value);
    this.stateChanges.next();
  }

  @ViewChild('userGroupsSelect') userGroupsSelect: MatSelect;
  @HostBinding('id')
  id = `multi-select-mail-list-${MultiSelectCcUsersComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get empty() {
    return !this.value;
  }

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl
  ) {
    _focusMonitor.monitor(_elementRef, true).subscribe((origin) => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.selectCtrl.valueChanges
      .pipe(startWith(null as string), pairwise(), debounceTime(1))
      .subscribe(([prev, next]: [string[], string[]]) => {
        if (prev?.length > 0 || next?.length > 0) {
          const changedValue = next?.find((val) => val === this.changedId);

          if (changedValue) {
            const prevChangedValue = prev?.findIndex(val => val === changedValue);

            if (prevChangedValue === -1) {
              prev.push(changedValue);
              this.value = prev;
              this.selectCtrl.patchValue(this.value);
              this.onSelectionChange();
            }
          } else {
            const prevChangedValue = prev?.findIndex(val => val === this.changedId);

            if (prevChangedValue !== -1) {
              prev.splice(prevChangedValue, 1);
              this.value = prev;
              this.selectCtrl.patchValue(this.value);
              this.onSelectionChange();
            }
          }
        }
      });
  }

  changeActiveItem(id: string) {
    this.changedId = id;
  }

  onSelectionChange() {
    this.onChange(this.value);
    this.selectionChange(this.value);
    this.stateChanges.next();
  }

  openedChange(isOpened: boolean) {
    if (isOpened) {
      const panel = this.userGroupsSelect.panel.nativeElement;
      panel.scrollTop = 0;
    }

    this.isOpened = isOpened;
    this.searchText = '';
    this.onKey(null);
    this.sortByChecked();
  }

  onKey(value: string) {
    this.filteredUserGroups = this.search(value || '');
  }

  checkKey(event: KeyboardEvent) {
    if (event.code === 'Space') {
      event.stopPropagation();
    }
  }

  private search(value: string) {
    if (value) {
      return this.userGroups
          .map(user => ({ groupId: user.groupId, groupName: user.groupName, detail: this._filter(user.detail, value) }))
          .filter(user => user.detail.length > 0);
    }

    return this.userGroups;
  }

  private _filter(detail: UserGroupsDetail[], value: string) {
    const filterValue = replaceTurkishCharacters(value).toLowerCase();

    return detail.filter(item => item.userName.toLowerCase().includes(filterValue));
  }

  private sortByChecked() {
    this.userGroups
      .forEach(user => {
        user.detail.sort((a, b) => {
          const indexA = this.value.indexOf(a.userId);
          const indexB = this.value.indexOf(b.userId);

          if (indexA !== -1 && indexB !== -1) {
            return indexA - indexB;
          } else if (indexA !== -1) {
            return -1;
          } else if (indexB !== -1) {
            return 1;
          } else {
            return 0;
          }
        });
      });
  }

  onChange = (_: any) => { };
  selectionChange = (_: any) => { };
  onTouched = () => { };
  onContainerClick = () => { };
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');
  writeValue = (value: string[]) => {
    this.value = value;
    this.selectCtrl.setValue(value);
  }

  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }
}
