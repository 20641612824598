import { MatFormFieldControl } from '@angular/material/form-field';
import { ControlValueAccessor, FormControl, NgControl, UntypedFormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import {
  Self,
  Input,
  Output,
  OnInit,
  DoCheck,
  Optional,
  OnDestroy,
  Component,
  ViewChild,
  ElementRef,
  HostBinding,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  KeyValueDiffers,
  KeyValueDiffer,
} from '@angular/core';

import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';

import { Permission } from '@core/auth/auth.enum';
import { hasUserPermission } from '@core/store';
import { Account, ApiService, Contact, ContactFilterRequest } from '@core/api';

@UntilDestroy()
@Component({
  selector: 'net-input-contact',
  templateUrl: './input-contact.component.html',
  styleUrls: ['./input-contact.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: InputContactComponent
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputContactComponent implements OnInit, OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<Contact | null> {
  static nextId = 0;


  loading = false;
  focused = false;
  errorState = false;
  controlType = 'input-contact';
  describedBy = '';
  stateChanges = new Subject<void>();
  hasSearchPermission = false;

  contacts: Contact[] = [];
  defaultValue: Contact = null;
  inputCtrl = new UntypedFormControl();

  @Input() showProperty = 'name';

  @Input() tabIndex: number;

  @Input() set filter(value: ContactFilterRequest['filter']) {
    this._filter = value;

    if (this._filter && this.loadWhenFilterExists) {
      const filterKeys = Object.keys(this.filter);
      if (!this.loadWhenFilterExists.every((key) => filterKeys.includes(key) && this.filter[key])) {
        return;
      }

      this.reset(true);
    }

  }

  get filter(): ContactFilterRequest['filter'] {
    return this._filter;
  }

  private _filter: ContactFilterRequest['filter'] = {};

  @Input() panelWidth: string | number = 'auto';
  @Input() loadWhenFilterExists: string[] | null = null;

  @Output() selectionChange: EventEmitter<Contact> = new EventEmitter<Contact>();

  @ViewChild('auto') auto: MatAutocomplete;
  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  @HostBinding('id') id = `input-contact-${InputContactComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  @Input() account: Account;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    if (value) {
      this.inputCtrl.disable();
    } else {
      this.inputCtrl.enable();
    }

    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): Contact | null { return this._value; }
  set value(value: Contact | null) {
    this._value = value;
    this.onChange(value);
    this.stateChanges.next();
  }
  private _value: Contact;

  get empty() { return !this.value; }

  get shouldLabelFloat() { return this.focused || !this.empty; }

  differ: KeyValueDiffer<any, any>;

  private search(filters: ContactFilterRequest['filter'] = {}, pageSize = 20) {
    return this.api.contact.elasticSearch({
      filter: {
        ...{ enabled: true, permissionControl: true },
        ...this.filter,
        ...filters,
      },
      orderBy: 'createdOn',
      orderType: 'DESC',
      pageSize,
    });
  }

  private initDefaultOptions(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.api.contact.elasticSearch({
        filter: {
          enabled: true,
          permissionControl: true,
          contactFullName: '',
          ...this.filter
        },
        pageSize: 20
      }).toPromise()
        .then((response) => {
          this.contacts = response.data.results;
          this.cdr.detectChanges();
        })
        .finally(() => resolve());
    });
  }

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private api: ApiService,
    private cdr: ChangeDetectorRef,
    private store: Store,
    private differs: KeyValueDiffers,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Material form field implementation
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.differ = this.differs.find({}).create();

    // Listen permission changes
    this.store
      .select(hasUserPermission(Permission.CONTACT_SEARCH))
      .pipe(untilDestroyed(this))
      .subscribe((permission) => this.hasSearchPermission = permission);

    // Listen input control value changes and filter results
    this.inputCtrl.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      filter(() => this.hasSearchPermission),
      filter(value => typeof value === 'string'),
      tap(() => {
        this.value = null;
        this.loading = true;
        this.contacts = [];
        // this.value = null;
      }),
      switchMap(value => this.api.contact.elasticSearch({
        filter: {
          enabled: true,
          permissionControl: true,
          contactFullName: value,
          ...this.filter
        },
        pageSize: 20
      })),
    ).subscribe((response) => {
      this.contacts = response.data.results;
      this.loading = false;
      this.cdr.detectChanges();
    });
  }

  ngOnInit() {
    this.initDefaultOptions();
  }

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }

  displayName(contact: Contact) {
    if (!contact) {
      return '';
    }

    return `${contact.firstName} ${contact.lastName}`;
  }

  reset(force = false) {
    if (!force && this.inputCtrl.pristine) {
      return;
    }

    this.value = force ? null : this.defaultValue;
    this.contacts = [];
    this.inputCtrl.setValue(force ? null : this.defaultValue);
    this.inputCtrl.markAsPristine();

    this.initDefaultOptions();
  }

  writeValue(value: Contact) {
    this.value = value;
    this.defaultValue = value;
    this.inputCtrl.setValue(this.value);
  }

  selectFirst() {
    if (this.contacts.length === 1) {
      this.value = this.contacts[0];
      this.inputCtrl.setValue(this.value);
    }
  }

  onSelect(event: MatAutocompleteSelectedEvent) {
    this.value = event.option.value;
    this.input.nativeElement?.blur();

    this.selectionChange.emit(this.value);
  }

  onBlur() {
    // On blur with nothing selected reset component
    if (this.ngControl.dirty && !this.auto.isOpen && !this.value) {
      this.reset();
    }
  }

  onClosed() {
    // On panel close with nothing selected reset component
    if (this.ngControl.dirty && !this.value) {
      this.reset();
    }
  }

  ngOnDestroy = () => this.stateChanges.complete();

  onChange = (_: any) => { };
  onTouched = () => { };
  onContainerClick = () => { };
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;

  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');
}
