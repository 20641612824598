import { Component } from '@angular/core';

import { Subject } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

import { DocumentHistory, DocumentHistoryFilterRequest, DocumentService, DocumentTypeEnum } from '@core/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'net-document-history',
  templateUrl: './document-history.component.html',
  styleUrls: ['./document-history.component.scss']
})
export class DocumentHistoryComponent {

  loading = false;

  customOppDocIds = [
    DocumentTypeEnum.OPP_DOC_ONE,
    DocumentTypeEnum.OPP_DOC_TWO,
    DocumentTypeEnum.OPP_DOC_THREE,
    DocumentTypeEnum.OPP_DOC_FOUR
  ];

  customCusDocIds = [
    DocumentTypeEnum.CUS_DOC_ONE,
    DocumentTypeEnum.CUS_DOC_TWO,
    DocumentTypeEnum.CUS_DOC_THREE,
    DocumentTypeEnum.CUS_DOC_FOUR,
    DocumentTypeEnum.CUS_DOC_FIVE
  ];

  entries: DocumentHistory[] = [];
  refresh: Subject<DocumentHistoryFilterRequest> = new Subject();

  constructor(private documentService: DocumentService, private translate: TranslateService) {
    this.refresh
      .asObservable()
      .pipe(
        filter(request => !!request),
        tap(() => this.loading = true),
        switchMap(request => this.documentService.history({...request, orderType: 'DESC'}))
      )
      .subscribe(response => {
        this.loading = false;

        if (response.success && response.data.results) {
          this.entries = response.data.results;
        }
      });
  }

  getHistoryType(item: DocumentHistory): string {
    switch (item.documentHistoryType.name) {
      case 'Insert': return this.translate.instant('GENERAL.ADDED');
      case 'Delete': return this.translate.instant('GENERAL.DELETED');
      default: return 'N/A';
    }
  }

  getTranslate(documentHistory: DocumentHistory) {
    if (this.customOppDocIds.includes(documentHistory.documentTypeId as DocumentTypeEnum)) {
      return this.translate.instant('OPPORTUNITY.' + documentHistory.documentType.name);
    } else if (this.customCusDocIds.includes(documentHistory.documentTypeId as DocumentTypeEnum)) {
      return this.translate.instant('ACCOUNT.' + documentHistory.documentType.name);
    } else {
      return this.translate.instant('DocumentType.' + documentHistory.documentType.name);
    }
  }
}
