import { debounceTime, finalize, map } from 'rxjs/operators';

import { ApiDataSource } from '@core/api/api.data-source';
import { CustomListTable, DynamicFieldService, OrderType } from '@core/api';
import { TranslateService } from '@ngx-translate/core';

export class CustomListTableDataSource extends ApiDataSource<CustomListTable> {

    orderBy = 'createdOn';
    orderType = 'ASC' as OrderType;

    constructor(
        private dynamicFieldService: DynamicFieldService,
        private translate: TranslateService,
        public initialFilter?: any) {
        super(initialFilter);
    }

    load(): void {
        // Update loading state
        this.loadingSubject.next(true);

        // Create request parameters
        const request: any = {
            filter: { ...this.initialFilter, ...this.filter },
            ...this.getRequest()
        };

        if (!request.orderBy) {
            this.orderBy = 'createdOn';
            this.orderType = 'ASC' as OrderType;
            request.orderBy = this.orderBy;
            request.orderType = this.orderType;
        }

        if (this.paginator?.pageSize) {
            request.pageSize = this.paginator.pageSize;
        }

        // Fetch data
        this.dynamicFieldService.customList.search(request).pipe(
            debounceTime(400),
            finalize(() => this.loadingSubject.next(false))
        ).subscribe(response => {
            // Update count and data subjects
            this.dataSubject.next(response.data.results);
            this.dataCountSubject.next(response.data.rowCount);

            // Update data source's empty based row count
            this.empty = response.data.rowCount === 0;
        });

        this.loadingSubject.next(false);
    }
}
