import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, first } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import icKeyboardArrowRight from '@iconify/icons-ic/twotone-keyboard-arrow-right';

import {
  NavigationDropdown,
  NavigationItem,
  NavigationLink
} from '../../navigation/navigation-item/navigation-item.interface';
import { NavigationService } from '../../services/navigation.service';
import { dropdownAnimation } from '../../../shared/animations/dropdown.animation';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { GetRecentPageSuccess, getAssignmentCount, getMailUnreadCount, getNotificationCount, getRecentPage } from '@core/store';

@UntilDestroy()
@Component({
  selector: 'net-sidenav-item',
  templateUrl: './sidenav-item.component.html',
  styleUrls: ['./sidenav-item.component.scss'],
  animations: [dropdownAnimation]
})
export class SidenavItemComponent implements OnInit, OnChanges, OnDestroy {

  @Input() level: number;
  @Input() isCollapseOpen: boolean;
  @Input()
  get item(): NavigationItem { return this._item; }
  set item(value: NavigationItem) {
    this._item = value;
    this.updateBadge();
  }
  private _item: NavigationItem;

  isOpen: boolean;
  isActive: boolean;
  icKeyboardArrowRight = icKeyboardArrowRight;

  assignmentCount: number;
  mailUnreadCount: number;
  notificationCount: number;

  isLink = this.navigationService.isLink;
  isDropdown = this.navigationService.isDropdown;
  isSubheading = this.navigationService.isSubheading;

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    private navigationService: NavigationService,
    private translate: TranslateService,
    private store: Store
  ) { }

  @HostBinding('class')
  get levelClass() {
    return `item-level-${this.level}`;
  }

  ngOnInit() {
    this.router.events.pipe(
      filter<NavigationEnd>(event => event instanceof NavigationEnd),
      filter(() => this.isDropdown(this.item)),
      untilDestroyed(this)
    ).subscribe(() => this.onRouteChange());

    this.navigationService.openChange$.pipe(
      filter(() => this.isDropdown(this.item)),
      untilDestroyed(this)
    ).subscribe(item => this.onOpenChange(item));

    this.store.select(getNotificationCount).pipe(untilDestroyed(this)).subscribe(notificationCount => {
      this.notificationCount = notificationCount;
      this.updateBadge();
    });

    this.store.select(getAssignmentCount).pipe(untilDestroyed(this)).subscribe(assignmentCount => {
      this.assignmentCount = assignmentCount;
      this.updateBadge();
    });

    this.store.select(getMailUnreadCount).pipe(untilDestroyed(this)).subscribe(mailUnreadCount => {
      this.mailUnreadCount = mailUnreadCount;
      this.updateBadge();
    });
  }

  updateBadge() {
    if (this.item?.label === this.translate.instant('GENERAL.TASKS') && this.item.badge.value !== this.assignmentCount) {
      this.item.badge = {
        value: this.assignmentCount > 0 ? this.assignmentCount.toString() : '',
        background: '#ff4946',
        color: '#ffeeee'
      };
    }

    if (this.item?.label === this.translate.instant('GENERAL.NOTIFICATIONS') && this.item.badge.value !== this.notificationCount) {
      this.item.badge = {
        value: this.notificationCount > 0 ? this.notificationCount.toString() : '',
        background: '#ff4946',
        color: '#ffeeee'
      };
    }

    if (this.item?.label === this.translate.instant('MENU.MAIL') && this.item.badge.value !== this.mailUnreadCount) {
      this.item.badge = {
        value: this.mailUnreadCount > 0 ? this.mailUnreadCount.toString() : '',
        background: '#ff4946',
        color: '#ffeeee'
      };
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.hasOwnProperty('item') && this.isDropdown(this.item)) {
      this.onRouteChange();
    }
  }

  toggleOpen() {
    this.isOpen = !this.isOpen;
    this.navigationService.triggerOpenChange(this.item as NavigationDropdown);
    this.cd.markForCheck();
  }

  onOpenChange(item: NavigationDropdown) {
    if (this.isChildrenOf(this.item as NavigationDropdown, item)) {
      return;
    }

    if (this.hasActiveChilds(this.item as NavigationDropdown)) {
      return;
    }

    if (this.item !== item) {
      this.isOpen = false;
      this.cd.markForCheck();
    }
  }

  onRouteChange() {
    if (this.hasActiveChilds(this.item as NavigationDropdown)) {
      this.isActive = true;
      this.isOpen = true;
      this.navigationService.triggerOpenChange(this.item as NavigationDropdown);
      this.cd.markForCheck();
    } else {
      this.isActive = false;
      this.isOpen = false;
      this.navigationService.triggerOpenChange(this.item as NavigationDropdown);
      this.cd.markForCheck();
    }
  }

  isChildrenOf(parent: NavigationDropdown, item: NavigationDropdown) {
    if (parent.children.indexOf(item) !== -1) {
      return true;
    }

    return parent.children
      .filter(child => this.isDropdown(child))
      .some(child => this.isChildrenOf(child as NavigationDropdown, item));
  }

  hasActiveChilds(parent: NavigationDropdown) {
    return parent.children.some(child => {
      if (this.isDropdown(child)) {
        return this.hasActiveChilds(child);
      }

      if (this.isLink(child) && !this.isFunction(child.route)) {
        return this.router.isActive(child.route as string, false);
      }
    });
  }

  isFunction(prop: NavigationLink['route']) {
    return prop instanceof Function;
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy(): void { }
}
