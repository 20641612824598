import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import {
  CardStatus,
  Response
} from '@core/api';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class CardStatusService {

  constructor(private http: HttpClient) { }

  list(): Observable<Response<CardStatus[]>> {
    return this.http.post<Response<CardStatus[]>>(environment.apiUrl + '/api/CardStatus/List', {});
  }
}
