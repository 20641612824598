import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import { OpportunityStatusAccountStatusSearchRequest, Response, PagedResponse, OpportunityStatusAccountStatus, OpportunityStatusAccountStatusRequest } from '@core/api';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class OpportunityStatusAccountStatusService {
  constructor(private http: HttpClient) { }

  search(request?: OpportunityStatusAccountStatusSearchRequest): Observable<PagedResponse<OpportunityStatusAccountStatus>> {
    return this.http.post<PagedResponse<OpportunityStatusAccountStatus>>(environment.apiUrl + '/api/OpportunityStatusAccountStatus/Search', request);
  }

  insert(request?: OpportunityStatusAccountStatusRequest): Observable<Response<OpportunityStatusAccountStatus>> {
    return this.http.post<Response<OpportunityStatusAccountStatus>>(environment.apiUrl + '/api/OpportunityStatusAccountStatus/Insert', request);
  }

  update(request?: OpportunityStatusAccountStatusRequest): Observable<Response<OpportunityStatusAccountStatus>> {
    return this.http.post<Response<OpportunityStatusAccountStatus>>(environment.apiUrl + '/api/OpportunityStatusAccountStatus/Update', request);
  }

  delete(request?: OpportunityStatusAccountStatus): Observable<Response<OpportunityStatusAccountStatus>> {
    return this.http.post<Response<OpportunityStatusAccountStatus>>(environment.apiUrl + '/api/OpportunityStatusAccountStatus/Delete', request);
  }
}
