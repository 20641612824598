import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import { Observable } from 'rxjs';

import { Response } from '@core/api';
import { AccountSource } from './account-source.model';

@Injectable({
    providedIn: 'root'
})
export class AccountSourceService {
    constructor(private http: HttpClient) { }

    list(): Observable<Response<AccountSource[]>> {
        return this.http.post<Response<AccountSource[]>>(environment.apiUrl + '/api/AccountSource/List', {});
    }
}

