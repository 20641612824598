import { Account } from '@core/api';
import { AccountActionTypes, AccountActions } from './account.actions';

export interface AccountState {
    account: Account;
    errorMessage: string;
    errorCode: string;
}

export const AccountState: AccountState = {
    account: null,
    errorMessage: null,
    errorCode: null,
};

export function accountReducer(state = AccountState, action: AccountActions): AccountState {
    switch (action.type) {
        case AccountActionTypes.GET_ACCOUNT:
            return {
                ...state,
            };
        case AccountActionTypes.GET_ACCOUNT_SUCCESS:
            return {
                ...state,
                account: action.payload
            };
        case AccountActionTypes.GET_ACCOUNT_ERROR:
            return {
                ...state,
                errorCode: action.payload.errorCode,
                errorMessage: action.payload.message
            };
        case AccountActionTypes.SET_ACCOUNT_AS_NULL:
            return {
                ...state,
                account: null
            };
        default:
            return state;
    }
}
