import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { STEP_STATE, StepperSelectionEvent } from '@angular/cdk/stepper';
import { skip } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
  AccountInsertRequest,
  AccountService,
  CustomerCheckNameRequest,
  CustomerService,
  ErrorCode,
  SalesOrganization,
  SalesRouteDefinition,
  SalesRouteDefinitionFilterRequest,
  SalesRouteDefinitionService,
  SalesRouteDefinitionStatusType,
  User
} from '@core/api';
import { getUser, hasUserPermission } from '@core/store';
import { Permission } from '@core/auth/auth.enum';

import icClose from '@iconify/icons-ic/twotone-close';

import { CreateLeadlessCustomerWizardDialogModel } from './create-leadless-customer-wizard-dialog.model';

@UntilDestroy()
@Component({
  selector: 'net-create-leadless-customer-wizard-dialog',
  templateUrl: './create-leadless-customer-wizard-dialog.component.html',
  styleUrls: ['./create-leadless-customer-wizard-dialog.component.scss']
})
export class CreateLeadlessCustomerWizardDialogComponent implements OnInit, AfterViewInit {

  icClose = icClose;

  user: User;
  activeRoutes: SalesRouteDefinition[] = [];
  filteredActiveRoutes: SalesRouteDefinition[] = [];

  // Steps
  salesOrganizationsStep: boolean;
  salesRoutesStep: boolean;
  customerInfosStep: boolean;

  customerInfosGroup = this.formBuilder.group({
    name: [null as string, Validators.required],
    customerShortName: [null as string, Validators.required],
    phones: [null]
  });
  salesOrganizationsGroup = this.formBuilder.group({
    salesOrganization: [null as SalesOrganization, Validators.required]
  });
  salesRouteDefinitionsGroup = this.formBuilder.group({
    salesRouteDefinitionId: [null as string, Validators.required]
  });

  // Search Text for Active Routes
  searchText = '';
  customerNameParam = '';

  previousStepCount = 0;
  isAtStart: boolean;
  isAtEnd: boolean;

  existingIds: string[] = [];

  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('searchInput') searchInputRef!: ElementRef<HTMLInputElement>;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: CreateLeadlessCustomerWizardDialogModel,
    private accountService: AccountService,
    private customerService: CustomerService,
    private cdr: ChangeDetectorRef,
    private dialogRef: MatDialogRef<CreateLeadlessCustomerWizardDialogComponent>,
    private formBuilder: FormBuilder,
    private salesRouteDefinitionService: SalesRouteDefinitionService,
    private store: Store
  ) {
    this.store.select(getUser).pipe(untilDestroyed(this)).subscribe(user => this.user = user);
  }

  ngOnInit(): void {
    const { wizardStepsShown, salesOrganization, salesRouteDefinitionId, customer } = this.data;

    this.salesOrganizationsStep = wizardStepsShown.salesOrganizationsStep;
    this.salesRoutesStep = wizardStepsShown.salesRoutesStep;
    this.customerInfosStep = wizardStepsShown.customerInfosStep;

    this.customerNameParam = customer.name;

    if ('customerId' in customer) {
      this.existingIds = customer.accounts.map(account => account.salesOrganization.salesOrganizationId);
    } else {
      this.customerInfosGroup.patchValue(customer);
    }

    if (this.salesOrganizationsStep) {
      this.salesOrganizationsGroup.valueChanges.pipe(untilDestroyed(this), skip(1)).subscribe(formValues => {
        if (this.salesRouteDefinitionsGroup.touched) {
          this.salesRouteDefinitionsGroup.patchValue({ salesRouteDefinitionId: null });
        }

        this.getSalesRoutes(formValues.salesOrganization);
      });
    } else if (!this.salesOrganizationsStep && this.salesRoutesStep) {
      this.salesOrganizationsGroup.patchValue({ salesOrganization });

      this.getSalesRoutes(salesOrganization);
    } else {
      this.salesOrganizationsGroup.patchValue({ salesOrganization });
      this.salesRouteDefinitionsGroup.patchValue({ salesRouteDefinitionId });
    }
  }

  ngAfterViewInit(): void {
    this.stepper._getIndicatorType = () => STEP_STATE.NUMBER;
    this.previousStepCount = this.stepper.steps.length;
    this.detectStartEndSteps();
    this.cdr.detectChanges();

    this.stepper.steps.forEach((step, i, thisArray) => {
      const element = document.querySelectorAll<HTMLElement>('.mat-step-icon')[i];

      if (element) {
        if (i <= this.stepper.selectedIndex) {
          element.style.backgroundColor = this.getStepColor(i);
        } else {
          element.style.backgroundColor = '#e0e7f1';
          element.style.color = '#bdc5ce';
        }
      }

      if (i <= this.stepper.selectedIndex && i < thisArray.length) {
        const stepLine = document.querySelectorAll<HTMLElement>('.mat-stepper-horizontal-line')[i];
        if (stepLine) {
          stepLine.style.borderTopColor = this.getStepColor(i);
        }
      }
    });
  }

  createCustomer() {
    const salesOrganizationId = this.salesOrganizationsGroup.value.salesOrganization.salesOrganizationId;
    const salesRouteDefinitionId = this.salesRouteDefinitionsGroup.value.salesRouteDefinitionId;
    const customer = this.data.customer;

    const request: AccountInsertRequest = {
      salesOrganizationId,
      salesRouteDefinitionId
    };

    if ('customerId' in customer) {
      request.customerId = customer.customerId;
      request.customer = {
        name: customer.name,
        customerShortName: customer.name.substring(0, 30),
        phones: customer.phones,
        emails: customer.emails
      };
    } else {
      const customerInfos = this.customerInfosGroup.value;

      request.customer = {
        name: customerInfos.name,
        customerShortName: customerInfos.customerShortName,
        phones: customerInfos.phones,
        emails: customer.emails
      };
    }

    this.accountService.insert(request).pipe(untilDestroyed(this)).subscribe(response => {
      if (response.success) {
        this.dialogRef.close(response.data);
      } else if ([ErrorCode.DUPLICATE_CUSTOMER_NAME, ErrorCode.DUPLICATE_CUSTOMER_SHORT_NAME].includes(response.errorCode as ErrorCode)) {
        this.customerInfosStep = true;

        this.cdr.detectChanges();

        const _customerInfosStep = this.findStepById('customer-infos-step');
        _customerInfosStep.stepFound.select();
        _customerInfosStep.stepFound.completed = false;

        this.customerInfosGroup.valueChanges.pipe(untilDestroyed(this)).subscribe(formValues => {
          _customerInfosStep.stepFound.completed = false;
          this.customerNameParam = formValues.name;
        });
        this.updateStepColor(_customerInfosStep.stepIndex, { backgroundColor: this.getStepColor(_customerInfosStep.stepIndex), color: '#fff' });
      }
    });
  }

  controlCustomerName() {
    if (this.customerInfosGroup.valid) {
      const { name, customerShortName } = this.customerInfosGroup.value;
      const customerInfosStep = this.findStepById('customer-infos-step');

      const request: CustomerCheckNameRequest = {
        name,
        customerShortName
      };

      this.customerService.checkName(request).subscribe(response => {
        if (response.success) {
          customerInfosStep.stepFound.completed = true;

          this.stepper.next();
        } else {
          customerInfosStep.stepFound.completed = false;
        }
      });
    }
  }

  onStepChange(event: StepperSelectionEvent) {
    this.updateStepColors(event);
    this.detectStartEndSteps(event);
  }

  // Active Routes Step Search Functions
  onKey() {
    const lowercaseSearchText = this.searchText.toLocaleLowerCase('tr-TR');

    this.filteredActiveRoutes = this.activeRoutes.filter(option => {
      const nameMatches = option.name.toLocaleLowerCase('tr-TR').includes(lowercaseSearchText);
      const displayNameMatches = option.assignedUser.displayName.toLocaleLowerCase('tr-TR').includes(lowercaseSearchText);
      return nameMatches || displayNameMatches;
    });
  }

  checkKey(event: KeyboardEvent) {
    if (event.code === 'Space') {
      event.stopPropagation();
    }
  }

  openedChange(isOpened: boolean) {
    if (isOpened) {
      this.searchInputRef.nativeElement.focus();
    }

    this.searchText = '';
    this.onKey();
  }
  // Active Routes Step Search Functions

  private getSalesRoutes(salesOrganization: SalesOrganization) {
    this.store.select(hasUserPermission(Permission.ACCOUNT_FILTER_SALES_ORGANIZATION)).subscribe(hasAccountFilterSOPermission => {
      const { salesOrganizationId } = salesOrganization;
      const hierarchyFound = this.user.userStructures?.find(item => item.salesOrganizationId === salesOrganizationId);

      if (hasAccountFilterSOPermission || hierarchyFound) {
        const request: SalesRouteDefinitionFilterRequest = {
          filter: {
            salesOrganizationId,
            systemUserStructureId: hierarchyFound?.systemUserStructureId
          }
        };

        this.salesRouteDefinitionService.search(request).subscribe(response => {
          const salesRoutes = response.data.results;
          this.activeRoutes = salesRoutes.filter(route => route.salesRouteDefinitionStatusId === SalesRouteDefinitionStatusType.ACTIVE);
          this.filteredActiveRoutes = this.activeRoutes;
        });
      } else {
        if (this.filteredActiveRoutes.length > 0) {
          this.filteredActiveRoutes = [];
        }
      }
    });
  }

  private getStepColor(index: number) {
    const stepColors = ['#68b9c8', '#564c98', '#4474b5', '#30529b'];

    return stepColors[index % stepColors.length];
  }

  private updateStepColors(event: StepperSelectionEvent) {
    const stepIconStyle = {
      backgroundColor: null,
      color: null
    };

    if (event.selectedIndex > event.previouslySelectedIndex) {
      stepIconStyle.color = '#fff';

      for (let i = event.previouslySelectedIndex + 1; i <= event.selectedIndex; i++) {
        stepIconStyle.backgroundColor = this.getStepColor(i);

        this.updateStepColor(i, stepIconStyle);
      }
    } else {
      stepIconStyle.backgroundColor = '#e0e7f1';
      stepIconStyle.color = '#bdc5ce';

      const currentStepCount = this.stepper.steps.length;
      const startingIndex = event.previouslySelectedIndex + (currentStepCount > this.previousStepCount ? currentStepCount - this.previousStepCount : 0);
      this.previousStepCount = currentStepCount;

      for (let i = startingIndex; i > event.selectedIndex; i--) {
        this.updateStepColor(i, stepIconStyle);
      }
    }
  }

  private updateStepColor(i: number, style: { backgroundColor: string; color: string; }) {
    const { backgroundColor, color } = style;

    const stepIcon = document.querySelectorAll<HTMLElement>('.mat-step-icon')[i];
    if (stepIcon) {
      stepIcon.style.backgroundColor = backgroundColor;
      stepIcon.style.color = color;
    }

    if (i < (this.stepper.steps.length - 1)) {
      const stepLine = document.querySelectorAll<HTMLElement>('.mat-stepper-horizontal-line')[i];
      if (stepLine) {
        stepLine.style.borderTopColor = backgroundColor;
      }
    }
  }

  private detectStartEndSteps(event?: StepperSelectionEvent) {
    this.isAtStart = (event?.selectedIndex ?? this.stepper.selectedIndex) === 0;
    this.isAtEnd = (event?.selectedIndex ?? this.stepper.selectedIndex) === this.stepper.steps.length - 1;
  }

  private findStepById(id: string) {
    let stepIndex: number;
    const stepFound = this.stepper.steps.find((step, index) => {
      const stepId = (step.content.elementRef.nativeElement as HTMLElement).parentElement.id;
      stepIndex = index;
      return stepId === id;
    });

    return { stepFound, stepIndex };
  }
}
