import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';

import { Observable } from 'rxjs';

import {
  Response,
  PagedResponse,
  FilterRequest,
} from '@core/api';

import {
  SurveyType,
  SurveyTypeRequest,
  SurveyTypeFilterRequest
} from './survey-type.model';

@Injectable({
  providedIn: 'root'
})
export class SurveyTypeService{
  constructor(private http: HttpClient) { }

  search(request: SurveyTypeFilterRequest): Observable<PagedResponse<SurveyType>> {
    return this.http.post<PagedResponse<SurveyType>>(environment.apiUrl + '/api/SurveyType/Search', request);
  }

  list(request: FilterRequest): Observable<Response<SurveyType[]>> {
    return this.http.post<Response<SurveyType[]>>(environment.apiUrl + '/api/Survey/SurveyTypeList', request);
  }

  get(uuid: string): Observable<Response<SurveyType>> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<SurveyType>>(environment.apiUrl + '/api/SurveyType/Get', JSON.stringify(uuid), { headers });
  }

  insert(request: SurveyTypeRequest): Observable<Response<SurveyType>> {
    return this.http.post<Response<SurveyType>>(environment.apiUrl + '/api/SurveyType/Insert', request);
  }

  update(request: SurveyTypeRequest): Observable<Response<SurveyType>> {
    return this.http.post<Response<SurveyType>>(environment.apiUrl + '/api/SurveyType/Update', request);
  }

  delete(request: SurveyType): Observable<Response<SurveyType>> {
    return this.http.post<Response<SurveyType>>(environment.apiUrl + '/api/SurveyType/Delete', request);
  }
}
