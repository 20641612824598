import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import { Observable } from 'rxjs';

import { PagedResponse, Sector, FilterRequest } from '@core/api';

@Injectable({
    providedIn: 'root'
})
export class SectorService {
    constructor(private http: HttpClient) { }

    search(sectorMainId: string): Observable<PagedResponse<Sector>> {
        const request: FilterRequest = { filter: { sectorMainId } };
        return this.http.post<PagedResponse<Sector>>(environment.apiUrl + '/api/Sector/Search', request);
    }
}
