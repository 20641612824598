<button type="button" class="add-phone" mat-icon-button (click)="add()" *ngIf="!disabled">

  <ic-icon [icon]="icAdd" size="16px"></ic-icon>

</button>

<ng-container [formGroup]="form">

  <div fxLayout="column" formArrayName="phones" matTooltip="{{'GENERAL.PLEASE_SELECT_CUSTOMER_FIRST' | translate}}"
    [matTooltipDisabled]="!!customer">

    <div class="input-phone" fxLayout="row" fxLayoutGap="5px" [formGroupName]="index"
      *ngFor="let control of getPhonesFormControls(); let index = index">

      <mat-select formControlName="type" placeholder="{{'GENERAL.TYPE' | translate}}" fxFlex="15"
        (selectionChange)="control.controls.internal.patchValue(null)">
        <mat-option *ngFor="let item of typeOptions" [value]="item.type">{{ item.label }}</mat-option>
      </mat-select>

      <net-select-country [disabled]="disabled" [tooltip]="control.value.country?.name"
        placeholder="{{'GENERAL.COUNTRY' | translate}}" [panelWidth]="'auto'"
        (optionSelected)="onChangeCountry(control)" fxFlex="25" formControlName="country"></net-select-country>

      <div fxFlex="60" fxLayout="row">
        <input #phone class="hide-input-number-arrow"
          [type]="control.value.country? (typeControl(control.value.country?.sortName) ? 'tel' :'number') :'tel'"
          fxFlex="65" matInput formControlName="phone" placeholder="{{'GENERAL.PHONE_NUMBER' | translate}}"
          [textMask]="textMaskCheck(control)" (keyup)="onKeyupCountryCode(control)" (focus)="focusInInput(phone, index)"
          (focusout)="focusOutInput(index)">
        <input *ngIf="checkIsInputTypeOffice(control)" class="ml-4 hide-input-number-arrow" type="number" fxFlex="35"
          matInput (keydown)="onKeydown($event)" formControlName="internal"
          placeholder="{{'GENERAL.INTERNAL_NUMBER' | translate}}">
      </div>

      <button type="button" class="remove-phone" mat-icon-button (click)="remove(index)">
        <ic-icon [icon]="icMinus" size="16px"></ic-icon>
      </button>

    </div>
  </div>

</ng-container>