import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import { Observable } from 'rxjs';

import { PagedResponse } from '@core/api';
import { Competitor, CompetitorFilterRequest } from './competitor.model';

@Injectable({
    providedIn: 'root'
})
export class CompetitorService {
    constructor(private http: HttpClient) { }

    search(request: CompetitorFilterRequest): Observable<PagedResponse<Competitor>> {
        return this.http.post<PagedResponse<Competitor>>(environment.apiUrl + '/api/Competitor/Search', request);
    }
}
