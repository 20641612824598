import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';

import { Observable } from 'rxjs';

import {
  Response,
  PagedResponse
} from '@core/api';
import {
  Survey,
  SurveyRequest,
  SurveyFilterRequest,
  MailLogoPath
} from './survey.model';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {
  constructor(private http: HttpClient) { }

  search(request: SurveyFilterRequest): Observable<PagedResponse<Survey>> {
    return this.http.post<PagedResponse<Survey>>(environment.apiUrl + '/api/Survey/Search', request);
  }

  list(): Observable<PagedResponse<Survey>> {
    return this.http.post<PagedResponse<Survey>>(environment.apiUrl + '/api/Survey/List', null);
  }

  get(uuid: string): Observable<Response<Survey>> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<Survey>>(environment.apiUrl + '/api/Survey/Get', JSON.stringify(uuid), { headers });
  }

  insert(request: SurveyRequest): Observable<Response<Survey>> {
    return this.http.post<Response<Survey>>(environment.apiUrl + '/api/Survey/Insert', request);
  }

  update(request: SurveyRequest): Observable<Response<Survey>> {
    return this.http.post<Response<Survey>>(environment.apiUrl + '/api/Survey/Update', request);
  }

  updateStatus(request: SurveyRequest): Observable<Response<Survey>> {
    return this.http.post<Response<Survey>>(environment.apiUrl + '/api/Survey/UpdateActiveStatus', request);
  }

  delete(request: Survey): Observable<Response<Survey>> {
    return this.http.post<Response<Survey>>(environment.apiUrl + '/api/Survey/Delete', request);
  }

  createAnswer(surveyContactMailId, surveyScoreId): Observable<Response<any>> {
    return this.http.post<Response<any>>(environment.apiUrl + '/api/SurveyAnswer/CreateAnswer', {}, { params: { surveyContactMailId, surveyScoreId } });
  }

  saveFeedback(surveyContactMailId, feedback): Observable<Response<any>> {
    return this.http.post<Response<any>>(environment.apiUrl + '/api/SurveyAnswer/SaveFeedback', { surveyContactMailId, feedback });
  }

  unsubscribe(surveyContactMailId): Observable<Response<any>> {
    return this.http.post<Response<any>>(environment.apiUrl + '/api/SurveyAnswer/Unsubscribe', { surveyContactMailId }, { params: { surveyContactMailId } });
  }

  getLogoPaths(): Observable<Response<MailLogoPath[]>> {
    return this.http.post<Response<MailLogoPath[]>>(environment.apiUrl + '/api/Survey/GetSurveyCompanyLogos', {});
  }

}
