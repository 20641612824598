import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '@core/auth/auth.service';
@Pipe({
  name: 'normalizeDecimalSeparator'
})
export class NormalizeDecimalSeparatorPipe implements PipeTransform {
  get separatorSymbols() {
    const isTr = this.authService.numberLanguage === 'tr';
    const [thousandsSeparatorSymbol, decimalSymbol] = [
      isTr ? '.' : ',',
      isTr ? ',' : '.',
    ];
    return [thousandsSeparatorSymbol, decimalSymbol];
  }
  public constructor(private authService: AuthService) {}

  transform(value: string | number): string {
    if (!value) {
      return '';
    }

    const splittedValues = value.toString().split('.');

    const numberPart = splittedValues[0];
    const decimalPart = splittedValues[1];
    const thousands = /\B(?=(\d{3})+(?!\d))/g;

    return (
      numberPart.replace(thousands, this.separatorSymbols[0]) +
      (decimalPart ? this.separatorSymbols[1] + decimalPart : '')
    );
  }

}
