import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export interface DynamicTranslateParamModel {
  [key: string]: {
    value: string;
    translate: boolean;
  };
}

export interface DynamicTranslateDescriptionModel {
  text: string;
  param: DynamicTranslateParamModel[];
  translate: boolean;
}

export interface DynamicTranslateModel {
  description: DynamicTranslateDescriptionModel;
  extraDescription: DynamicTranslateDescriptionModel;
}

@Pipe({
  name: 'dynamicTranslate'
})
export class DynamicTranslatePipe implements PipeTransform {
  constructor(private translate: TranslateService) { }

  transform(translation: string | DynamicTranslateModel, translateModule: string, missingText = '[MISSING]', useExtraDescription = false): string {
    if (!translation) {
      return missingText;
    }

    if (typeof translation === 'string') {
      translation = this.getTranslationObjectFromString(translation);
    }

    const isTextNull = useExtraDescription ? !translation.extraDescription?.text : !translation.description?.text;
    if (isTextNull) {
      return missingText;
    }

    if (useExtraDescription && translation.extraDescription?.param?.length) {
      return this.getTranslateWithParams(translation.extraDescription, translateModule);
    } else if (!useExtraDescription && translation.description?.param?.length) {
      return this.getTranslateWithParams(translation.description, translateModule);
    }

    const descriptionText = useExtraDescription ? translation.extraDescription?.text : translation.description?.text;
    const shouldTextTranslate = useExtraDescription ? translation.extraDescription.translate : translation.description.translate;
    return shouldTextTranslate ? this.translate.instant(translateModule + '.' + descriptionText) : descriptionText;
  }

  private getTranslateWithParams(description: DynamicTranslateDescriptionModel, translateModule: string): string {
    const params = this.translateParams(description.param, translateModule);
    return this.translate.instant(translateModule + '.' + description.text, params);
  }

  private getTranslationObjectFromString(translation: string): DynamicTranslateModel {
    if (translation[0] === '"') {
      translation = translation.slice(1, -1);
    }

    return JSON.parse(translation);
  }

  // Convert param model array to one param model
  private translateParams(params: DynamicTranslateParamModel[], translateModule: string): DynamicTranslateParamModel {
    return params.reduce((obj, param) => {
      const [key, value] = Object.entries(param)[0];

      if (value.value.split(', ').length > 1) {
        const translatedValues: string[] = value.value.split(', ').map(val => {

          if (val.includes('.')) {
            return value.translate ? this.translate.instant(val) : val;
          }
        });
        return (obj[key] = translatedValues.join(', ') as any, obj);
      }

      if (value.value.includes('.')) {
        return (obj[key] = value.translate ? this.translate.instant(value.value) : value.value, obj);
      }

      return (obj[key] = value.translate ? this.translate.instant(translateModule + '.' + value.value) : value.value, obj);
    }, {});
  }
}

