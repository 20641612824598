<div class="history-container border-t-1 mt-2" fxFlex="100" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
  <ng-container *ngIf="!loading">
    <ul class="overflow-auto" *ngIf="entries.length > 0">
      <li *ngFor="let item of entries" class="border-t pt-3 pb-3" [ngClass.xs]="'small-text'">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.gt-sm="space-between center" class="text-secondary text-xs"
          fxLayoutGap="10px">
          <span fxFlex="auto">{{ item.transactionBy }} -
              {{ getTranslate(item) }} -
              {{ item.documentName }} -
              {{ getHistoryType(item) }}
          </span>
          <span class="created-on pr-3" fxLayoutAlign="and start" *ngIf="item.createdOn">
            {{item.createdOn | momentFormat:'DD MMMM YYYY HH:mm' }}
          </span>
        </div>
      </li>
    </ul>
    <p class="w-full text-center" *ngIf="entries.length === 0">{{'GENERAL.NO_RESULT_FOUND' | translate}}</p>
  </ng-container>
  <ng-container *ngIf="loading">
    <ngx-skeleton-loader fxFlex animation="pulse" count="3" appearance="line"></ngx-skeleton-loader>
  </ng-container>
</div>
