import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, DoCheck, ElementRef,
  EventEmitter, HostBinding, Input, OnDestroy, Optional, Output, Self, ViewChild, OnInit, KeyValueDiffers, SimpleChanges, KeyValueDiffer
} from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatFormFieldControl } from '@angular/material/form-field';

import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { hasUserPermission } from '@core/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';

import { Permission } from '@core/auth/auth.enum';
import { ApiService, LeadDraft, LeadFilterRequest } from '@core/api';

@UntilDestroy()
@Component({
  selector: 'net-input-lead-draft',
  templateUrl: './input-lead-draft.component.html',
  styleUrls: ['./input-lead-draft.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: InputLeadDraftComponent
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputLeadDraftComponent implements OnInit, OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<LeadDraft | null> {
  static nextId = 0;


  loading = false;
  focused = false;
  errorState = false;
  controlType = 'input-lead-draft';
  describedBy = '';
  hasSearchPermission = false;
  stateChanges = new Subject<void>();

  leadDrafts: LeadDraft[] = [];
  defaultValue: LeadDraft = null;
  inputCtrl = new UntypedFormControl();

  @Input() tabIndex: number;
  @Input() panelWidth: string | number = 'auto';
  @Input() loadWhenFilterExists: string[] | null = null;
  @Input() set filter(value: LeadFilterRequest['filter']) {
    this._filter = value;

    if (this._filter && this.loadWhenFilterExists) {
      const filterKeys = Object.keys(this.filter);
      if (!this.loadWhenFilterExists.every((key) => filterKeys.includes(key) && this.filter[key])) {
        return;
      }

      this.reset(true);
    }

  }

  get filter(): LeadFilterRequest['filter'] {
    return this._filter;
  }

  private _filter: LeadFilterRequest['filter'] = {};

  @Output() selectionChange: EventEmitter<LeadDraft> = new EventEmitter<LeadDraft>();

  @ViewChild('auto') auto: MatAutocomplete;
  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  @HostBinding('id') id = `input-lead-draft-${InputLeadDraftComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    if (value) {
      this.inputCtrl.disable();
    } else {
      this.inputCtrl.enable();
    }

    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): LeadDraft | null { return this._value; }
  set value(value: LeadDraft | null) {
    this._value = value;
    this.onChange(value);
    this.stateChanges.next();
  }
  private _value: LeadDraft;

  get shouldLabelFloat() { return this.focused || !this.empty; }

  get empty() { return !this.value; }

  differ: KeyValueDiffer<any, any>;

  private search(filters: LeadFilterRequest['filter'] = {}, pageSize = 100) {
    return this.api.leadDraft.search({
      filter: {
        ...this.filter,
        ...filters,
      },
      orderBy: 'createdOn',
      orderType: 'DESC',
      pageSize,
    });
  }


  private initDefaultOptions(): Promise<void> {

    if (this.filter && this.loadWhenFilterExists) {
      const filterKeys = Object.keys(this.filter);
      if (!this.loadWhenFilterExists.every((key) => filterKeys.includes(key) && this.filter[key])) {
        return;
      }
    }

    return new Promise<void>((resolve) => {
      this.api.leadDraft.search({
        filter: {
          ...this.filter,
          companyName: ''
        },
        pageSize: 100
      }).toPromise()
        .then((response) => {
          this.leadDrafts = response.data.results;
          this.cdr.detectChanges();
        })
        .finally(() => resolve());
    });
  }

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private api: ApiService,
    private cdr: ChangeDetectorRef,
    private store: Store,
    private differs: KeyValueDiffers,
    @Optional() @Self() public ngControl: NgControl
  ) {
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.differ = this.differs.find({}).create();

    this.store
      .select(hasUserPermission(Permission.LEAD_DRAFT_SEARCH))
      .pipe(untilDestroyed(this))
      .subscribe((permission) => this.hasSearchPermission = permission);

    // Listen input control value changes and filter results
    this.inputCtrl.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      filter(() => this.hasSearchPermission),
      filter(value => typeof value === 'string'),
      tap(() => {
        this.loading = true;
        this.leadDrafts = [];
        // this.value = null;
      }),
      switchMap(value => this.search({ companyName: value })),
    ).subscribe((response) => {
      this.leadDrafts = response.data.results;
      this.loading = false;
      cdr.detectChanges();
    });
  }

  displayName = (leadDraft: LeadDraft) => leadDraft ? `${leadDraft.companyName}` : '';

  reset(force = false) {
    if (!force && this.inputCtrl.pristine) {
      return;
    }

    this.value = force ? null : this.defaultValue;
    this.leadDrafts = [];
    this.inputCtrl.setValue(force ? null : this.defaultValue);
    this.inputCtrl.markAsPristine();

    this.initDefaultOptions();
  }

  onSelect(event: MatAutocompleteSelectedEvent) {
    this.value = event.option.value;
    this.input.nativeElement?.blur();

    this.selectionChange.emit(this.value);
  }

  onBlur() {
    // On blur with nothing selected reset component
    if (this.ngControl.dirty && !this.auto.isOpen && !this.value) {
      this.reset();
    }
  }

  onClosed() {
    // On panel close with nothing selected reset component
    if (this.ngControl.dirty && !this.value) {
      this.reset();
    }
  }

  onChange = (_: any) => { };
  onTouched = () => { };
  onContainerClick = () => { };
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');

  writeValue(value: LeadDraft) {
    this.value = value;
    this.defaultValue = value;
    this.inputCtrl.setValue(value);
  }

  ngOnInit() {
    this.initDefaultOptions();
  }

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }

  }

  ngOnDestroy = () => this.stateChanges.complete();
}
