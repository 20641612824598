import { Router, UrlCreationOptions } from '@angular/router';
import { environment } from '@env/environment';

/**
 * Open route in new tab with given commands and navigation extras
 *
 * A helper method to reduce duplication of code,
 * takes same parameters as router.navigate,
 * opens route in new tab with window.open
 */
export function openRouteNewTab(router: Router, commands: any[], navigationExtras?: UrlCreationOptions): void {
  const tree = router.createUrlTree(commands, navigationExtras);
  const url = router.serializeUrl(tree);

  // Log urlTree to console in development mode
  if (!environment.production) {
    console.debug('Open route in new tab', {url, tree});
  }

  window.open(url, '_blank');
}
