<div mat-dialog-title>
  <form class="w-full text-center pb-4" (ngSubmit)="onSearch()">
    <h1 @fadeInUp class="display-1 mt-8 mb-2">{{"LEAD.SEARCH_CUSTOMER" | translate}}</h1>
    <p class="text-hint mb-6">{{"LEAD.SEARCH_AMONG_ALL_CUSTOMERS_AND_FIND_YOUR_CUSTOMER" | translate}}</p>
    <div @fadeInUp
      class="border rounded-full bg-card py-2 ltr:pl-6 rtl:pr-6 ltr:pr-3 rtl:pl-3 text-secondary max-w-xl mx-auto shadow-8"
      fxLayout="row" fxLayoutAlign="start center">
      <ic-icon [icon]="icSearch" fxFlex="none" size="24px"></ic-icon>
      <input
        class="border-0 ltr:ml-4 rtl:mr-4 placeholder:text-secondary subheading-2 outline-none font-medium w-0 bg-card"
        fxFlex="auto" [placeholder]="'LEAD.TYPE_THE_CUSTOMER_NAME' | translate" type="text" autofocus [ngModelOptions]="{standalone: true}"
        [(ngModel)]="keyword" netUpperCase>
      <p class="text-hint mr-4" *ngIf="showLengthMessage">{{"GENERAL.PLEASE_TYPE_AT_LEAST_3_LETTERS" | translate}}</p>
      <button class="rounded-full" color="primary" fxFlex="none" mat-raised-button type="submit">{{"GENERAL.SEARCH" | translate | uppercase }}</button>
    </div>
  </form>
  <button type="button" class="text-secondary button-close" mat-dialog-close mat-icon-button>
    <mat-icon [icIcon]="icClose"></mat-icon>
  </button>
</div>
<mat-dialog-content #mainContent fxLayout="row" fxLayoutAlign="start top" class="pb-4">
  <div class="result-container">
    <ng-template [ngIf]="customers">
      <mat-accordion class="pl-4 pr-4 pb-3">
        <mat-expansion-panel #panelH *ngFor="let customer of customers; let index = index;"
          (opened)="customer.isOpen = true;" (closed)="customer.isOpen = false">
          <mat-expansion-panel-header *ngIf="(!customer.isOpen)" (click)="onFocus(index)">
            <mat-panel-title class="items-center break-words">{{ customer.name }}</mat-panel-title>
          </mat-expansion-panel-header>
          <div [id]="index" [hidden]="!customer.isOpen" fxLayout="column" class="pl-4 pr-4 pb-3">
            <div fxLayout="row">
              <div class="mr-4" fxFlex="3" fxLayoutAlign="center">
                <button type="button" mat-icon-button>
                  <ic-icon class="text-yellow-500" [icon]="icSmartCard" size="24px"></ic-icon>
                </button>

              </div>
              <div fxFlex="97" fxLayout="column" fxLayoutGap="10px">
                <div (click)="panelH.toggle()" fxLayout="column" class="pb-1">
                  <div class="font-medium p-1 pl-2 bg-info" style="cursor: pointer;"> {{ customer.name }}
                    <span class="text-xs mat-hint" *ngIf="customer.sectorMain?.name"><br />
                      {{"LEAD.SECTOR" | translate}}: <span class="font-medium">{{ ('SectorMain.' + customer.sectorMain.name) | translate }}</span>
                    </span>
                  </div>
                </div>
                <div *ngFor="let account of customer.accounts" class="pb-2 shadow-sm" fxLayout="column"
                  fxLayoutGap="12px">
                  <div class="font-medium text-xs py-1 px-2 bg-sub-info">{{ account.salesOrganization?.name }}</div>
                  <div fxLayout="row" fxLayoutGap="10px">
                    <div fxLayout="column" fxFlex="55">
                      <div class="mat-hint text-xs pl-2">{{"LEAD.SALES_ROUTE_RESPONSIBLE_PERSON" | translate}}</div>
                      <div class="font-medium text-xs pl-2">{{ account.salesRouteDefinition?.assignedUser?.firstName}}
                        {{account.salesRouteDefinition?.assignedUser?.lastName}}</div>
                    </div>
                    <div fxFlex="45" fxLayout="column">
                      <div class="mat-hint text-xs">{{"LEAD.CITY_DISTRICT" | translate}}</div>
                      <div class="font-medium text-xs">{{ account.mainAddress?.city?.name}}
                        {{account.mainAddress?.county?.name}}</div>
                    </div>
                  </div>
                </div>
                <div *ngIf="!(customer.accounts | includes: salesOrganizations: 'salesOrganization.salesOrganizationId': 'salesOrganizationId')"
                  fxLayout="row" style="zoom: 80%" fxLayoutAlign="end center">
                  <button class="mt-2" type="button" mat-raised-button color="primary"
                    (click)="createNew(customer)">
                    <mat-icon style="zoom:80%" [icIcon]="isPlusCircle"></mat-icon> {{ "LEAD_QUALIFICATION.CREATE_" + (leadlessUsage ? "CUSTOMER" : "LEAD") | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </ng-template>
    <mat-progress-spinner *ngIf="loading" mode="indeterminate" diameter="42" class="mx-auto mt-6">
    </mat-progress-spinner>
  </div>

</mat-dialog-content>
<mat-divider *ngIf="showButton" class="mx-6 mb-4 border-b-2"></mat-divider>
<mat-dialog-actions *ngIf="showButton">
  <button class="block my-2 mx-auto" color="primary" mat-raised-button (click)="createNew()">
    {{"LEAD.CREATE_A_NEW_" + (leadlessUsage ? "CUSTOMER" : "LEAD") | translate}}
  </button>
</mat-dialog-actions>
