<div class="chat-message-parent">
  <div class="xs:mb-6 md:mb-5 flex" [ngClass]="{ 'justify-start flex-row-reverse': self && !isLog }">
    <!-- avatar -->
    <ng-container *ngIf="self">
      <div class="ml-4" [ngClass]="{ 'ml-9': followUp }">
        <div *ngIf="!hideAvatar()" class="outline-none">
          <net-profile-photo [systemUser]="user" width="38" height="38" fontSize="14">
            <div channelInfo [ngClass]="{'private-note': isPrivateNote}"
              class="absolute flex items-center bg-transparent justify-center bottom-0 -right-1 rounded-full w-4 h-4">
              <mat-icon [icIcon]="getChannelIcon()" class="text-sm default-icon-color"></mat-icon>
            </div>
          </net-profile-photo>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!self">
      <div class="mr-4" [ngClass]="{ 'ml-9': followUp }">
        <div *ngIf="!hideAvatar()" class="outline-none">
          <net-profile-photo *ngIf="headerInfo?.contacts.length > 0; else noContact" width="38" height="38"
            fontSize="14" [externalBgColor]="headerInfo?.backgroundColor"
            [initials]="headerInfo?.contacts[0]?.firstName?.charAt(0) + headerInfo?.contacts[0]?.lastName?.charAt(0)">
            <div channelInfo
              class="absolute flex items-center bg-transparent justify-center bottom-0 -right-1 rounded-full w-4 h-4">
              <mat-icon [icIcon]="getChannelIcon()" class="text-sm default-icon-color"></mat-icon>
            </div>
          </net-profile-photo>

          <ng-template #noContact>
            <div class="flex items-center justify-center w-9 h-9 bg-cover bg-center rounded-full relative"
              style="background-color: #f0f0f0;">
              <mat-icon [icIcon]="icPerson" size="24px" style="color: #979fa2;"></mat-icon>
              <div
                class="absolute flex items-center bg-transparent justify-center bottom-0 -right-1 rounded-full w-4 h-4">
                <mat-icon [icIcon]="getChannelIcon()" class="text-sm default-icon-color"></mat-icon>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </ng-container>

    <!-- message -->
    <div class="items-end"
      [ngClass]="{'flex-1': message?.ticketMessage?.cxChannel?.cxChannelTypeId === cxChannelType.EMAIL}">
      <!--bubble-->
      <ng-container #textMessage *ngIf="!isLog">
        <ng-container [ngSwitch]="message?.ticketMessage?.cxChannel?.cxChannelTypeId">

          <!-- Mail Message -->
          <ng-container *ngSwitchCase="cxChannelType.EMAIL" [ngTemplateOutlet]="mailMessage">
          </ng-container>

          <!-- WhatsApp Message -->
          <ng-container *ngSwitchCase="cxChannelType.WHATSAPP" [ngTemplateOutlet]="whatsappMessage">
          </ng-container>

          <!-- Facebook Message -->
          <ng-container *ngSwitchCase="cxChannelType.FACEBOOK" [ngTemplateOutlet]="whatsappMessage">
          </ng-container>

          <!-- Instagram Message -->
          <ng-container *ngSwitchCase="cxChannelType.INSTAGRAM" [ngTemplateOutlet]="whatsappMessage">
          </ng-container>

        </ng-container>

      </ng-container>

      <ng-container #privateNote *ngIf="isPrivateNote">
        <div class="group chat-bubble p-4 rounded transition duration-500 max-w-md" [ngClass]="getBubbleClass()">

          <div class="flex">
            <p [innerHtml]="message.ticketPrivateNote?.note" class="mt-1 private-note-content" style="font-size: 14px;">
            </p>

            <!-- context menu -->
            <div *ngIf="!isPreview" class="w-5 h-5 ml-auto cursor-pointer more-icon"
              [matMenuTriggerData]="{ message : message, self: self }" [matMenuTriggerFor]="actionsMenu">
              <mat-icon [icIcon]="icMoreHoriz"></mat-icon>
            </div>
          </div>

          <div class="flex items-center justify-end -mb-4">

            <!--date-->
            <div class="date">
              <p class="text-xxs whitespace-pre">
                {{ (message.createdOn) | momentFormat:'DD.MM.YYYY - HH:mm' }}
              </p>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container #log *ngIf="isLog && message.ticketLog">
        <div class="max-w-md relative ml-9 mb-2 py-1 px-4 rounded-full transition duration-500 log-bubble">

          <!--content-->
          <p class="mt-1 chat-description outline-none text-xs"
            [innerHtml]="message.ticketLog.construct | dynamicTranslate: 'CommunicationType': message.ticketLog.description">
          </p>

          <div class="flex items-center justify-end absolute right-4 mt-2">

            <!--date-->
            <div class="date">
              <p class="text-xxs whitespace-pre">
                {{ message.createdOn | momentFormat:'DD.MM.YYYY - HH:mm'}}
              </p>
            </div>
          </div>

          <div *ngIf="!isPreview && permissions.splitTicket && canBeSplit()"
            class="w-5 h-5 cursor-pointer absolute flex items-center -right-6 log-action"
            [matMenuTriggerFor]="logActionsMenu">
            <mat-icon [icIcon]="icMoreHoriz"></mat-icon>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<mat-menu #actionsMenu="matMenu" xPosition="before" yPosition="below">
  <ng-template let-message="message" let-self="self" matMenuContent>
    <button (click)="copy(message)" color="primary" mat-menu-item>
      <mat-icon [icIcon]="icCopy"></mat-icon>
      <span>{{'CX.COPY' | translate}}</span>
    </button>

    <button *ngIf="permissions.createTask" (click)="createTask(message)" color="primary" mat-menu-item>
      <mat-icon [icIcon]="icCreateTask"></mat-icon>
      <span>{{'CX.CREATE_TASK' | translate}}</span>

    </button>

    <button *ngIf="!self && permissions.createTicket" (click)="createTicket(message)" color="primary" mat-menu-item>
      <mat-icon [icIcon]="icCreateTicket"></mat-icon>
      <span>{{'CX.CREATE_TICKET' | translate}}</span>

    </button>
  </ng-template>
</mat-menu>

<mat-menu #logActionsMenu="matMenu" xPosition="after" yPosition="below">
  <ng-template matMenuContent>
    <button (click)="splitTicket()" color="primary" mat-menu-item>
      <mat-icon [icIcon]="icSplit"></mat-icon>
      <span>{{'CX.SPLIT_TICKETS' | translate}}</span>
    </button>
  </ng-template>
</mat-menu>

<ng-template #mailMessage>

  <div class="group chat-bubble p-4 rounded transition duration-500" [ngClass]="getBubbleClass()">

    <div class="flex items-start">

      <p>{{ message?.ticketMessage?.message?.subject || ('MAIL.ENTRY_NO_SUBJECT' | translate) }}</p>

      <div class="flex items-end items-center ml-auto">
        <button class="mr-3 flex items-center justify-center mail-view-button " (click)="openMailViewer()"
          mat-raised-button>{{'CX.MAIL_VIEW' |translate}}</button>

        <!-- context menu -->
        <div *ngIf="!isPreview" class="w-5 h-5 ml-auto cursor-pointer more-icon"
          [matMenuTriggerData]="{ message : message, self: self }" [matMenuTriggerFor]="actionsMenu">
          <mat-icon [icIcon]="icMoreHoriz"></mat-icon>
        </div>
      </div>

    </div>

    <div>
      <p><strong>{{ 'MAIL.FROM' | translate }}: </strong>
        <ng-container *ngIf="!self">{{ message?.ticketMessage?.contactName }}
          - {{ message?.ticketMessage?.contactInfo }}</ng-container>
        <ng-container *ngIf="self">{{ message?.ticketMessage?.cxChannel?.cxChannelInfo }}</ng-container>

      </p>
      <p><strong>{{ 'MAIL.TO' | translate }}: </strong>
        <ng-container *ngIf="!self">{{ formattedRecipients(message?.ticketMessage?.to) }}</ng-container>
        <ng-container *ngIf="self">{{ headerInfo.mailName }}</ng-container>
      </p>

      <p *ngIf="message?.ticketMessage?.cc"><strong>{{ 'MAIL.CC' | translate }}: </strong>
        <ng-container *ngIf="!self">{{ formattedRecipients(message?.ticketMessage?.cc, true) }}</ng-container>
        <ng-container *ngIf="self">{{ headerInfo.mailName }}</ng-container>
      </p>

      <p><strong>{{ 'MAIL.DATE' | translate }}: </strong>
        {{message?.ticketMessage?.message?.messageTime | momentFormat:'DD.MM.YYYY - HH:mm' }}
      </p>
    </div>

    <!-- content -->
    <div class="flex flex-col">
      <p class="outline-none" [innerHTML]="message.ticketMessage.message.text">
      </p>
    </div>

    <div *ngIf="message.ticketMessage?.ticketMessageAttachments?.length > 0"
      class="flex flex-row flex-wrap gap-2 w-full px-2">
      <net-customer-experience-mail-attachment *ngFor="let file of message.ticketMessage.ticketMessageAttachments"
        mode='chatView' [directDownload]="true" [file]="file.attachment">
      </net-customer-experience-mail-attachment>
    </div>

    <div class="flex items-center justify-end -mb-4">

      <!--date-->
      <div class="date">
        <p class="text-xxs whitespace-pre">
          {{ ( isAgentMessage() ? (message.createdOn) :
          (message.ticketMessage.message.messageTime) ) |
          momentFormat:'DD.MM.YYYY - HH:mm'}}
        </p>
      </div>
    </div>

  </div>

</ng-template>

<ng-template #whatsappMessage>

  <div class="group chat-bubble p-4 rounded transition duration-500 max-w-md word-break" [ngClass]="getBubbleClass()">

    <div class="flex">
      <!-- content -->
      <div class="flex flex-col">
        <p class="outline-none whitespace-pre-line" [innerHTML]="isImage() ?
                                          message.ticketMessage.message.caption : message.ticketMessage.message.text">
        </p>
        <ng-container *ngIf="isImage()">
          <div class="group relative m-2">
            <img loading="lazy" [src]="message.ticketMessage.message.previewUrl" class="rounded-lg" />
          </div>
        </ng-container>

        <ng-container *ngIf="isButtonReply()">
          <div class="flex flex-col">
            <p class="outline-none whitespace-pre-line" [innerHTML]="message.ticketMessage.message['title']">
            </p>
          </div>
        </ng-container>


        <ng-container *ngIf="isDocument()">
          <div class="group relative mr-2">
            <div class="flex items-center">
              <button class="download-btn" type="button" mat-icon-button
                (click)="handleDocument(message.ticketMessage.message.url)">
                <mat-icon [icIcon]="icDownload"></mat-icon>
              </button>
              <span class="ml-2">{{ message.ticketMessage.message.filename }}</span>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="isQuickReply()">
          <div class="group relative m-2">
            <div class="flex flex-col">
              <p class="outline-none whitespace-pre-line" [innerHTML]="message.ticketMessage.message['content'].text">
              </p>
            </div>

            <div *ngIf="message.ticketMessage.message['options'].length > 0" class="grid-container mt-2">
              <div class="child-component-grid">
                <button
                  *ngFor="let button of message.ticketMessage.message['options']; let last = last; let index = index"
                  [type]="button.type" [ngClass]="{'col-span-2':last && index % 2 === 0 }"
                  mat-stroked-button>{{button.title}}</button>

              </div>
            </div>

          </div>
        </ng-container>


        <ng-container *ngIf="isVideo()">
          <div class="group relative m-2">
            <video controls #videoPlayer>
              <source [src]="message.ticketMessage.message.url" type="video/mp4" />
              {{ 'CX.BROWSER_NOT_SUPPORTED' | translate }}
            </video>
          </div>
        </ng-container>

        <ng-container *ngIf="isSticker()">
          <div class="group relative m-2">
            <picture class="flex w-40 h-auto">
              <source type="image/webp" [srcset]="message.ticketMessage.message.url">
              <img loading="lazy" [src]="message.ticketMessage.message.url" />
            </picture>
          </div>
        </ng-container>
      </div>

      <!-- context menu -->
      <div *ngIf="!isPreview" class="w-5 h-5 ml-auto cursor-pointer more-icon"
        [matMenuTriggerData]="{ message : message, self: self }" [matMenuTriggerFor]="actionsMenu">
        <mat-icon [icIcon]="icMoreHoriz"></mat-icon>
      </div>
    </div>

    <div class="flex items-center justify-end -mb-4">

      <!--date-->
      <div class="date">
        <p class="text-xxs whitespace-pre">
          {{ ( isAgentMessage() ? (message.createdOn) :
          (message.ticketMessage.message.messageTime) ) |
          momentFormat:'DD.MM.YYYY - HH:mm'}}
        </p>
      </div>

      <!--read receipt-->
      <!-- <div class="read-receipt ml-2" *ngIf="self && !isPrivateNote">
                                      <mat-icon *ngIf="message.status === 'waiting'" [icIcon]="icWaiting"></mat-icon>
                                      <mat-icon *ngIf="message.status === 'sent'" [icIcon]="icCheck"></mat-icon>
                                      <mat-icon class="seen" *ngIf="message.status === 'seen'" [icIcon]="icDoubleCheck"></mat-icon>
                                  </div> -->

    </div>
  </div>

</ng-template>