import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';

import { Observable } from 'rxjs';
import { ContractFuel, ContractFuelRequest, Response } from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class ContractFuelService {
  constructor(private http: HttpClient) { }

  get(uuid: string): Observable<Response<ContractFuel>> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<ContractFuel>>(environment.apiUrl + '/api/ContractFuel/Get', JSON.stringify(uuid), { headers });
  }

  insert(request: ContractFuelRequest): Observable<Response<ContractFuel>> {
    return this.http.post<Response<ContractFuel>>(environment.apiUrl + '/api/ContractFuel/Insert', request);
  }

  update(request: ContractFuelRequest): Observable<Response<ContractFuel>> {
    return this.http.post<Response<ContractFuel>>(environment.apiUrl + '/api/ContractFuel/Update', request);
  }
}
