export enum AccountStatusType {
  OFFER = 'bbd79684-75ee-4dd1-9fd4-91143db04fa9',
  CANCEL = 'd1fc2c15-8e76-4535-a830-086b308e6285',
  RETURN = 'b10d3d47-1f45-4a5a-acfa-902f31f334aa',
  PLANNED = '15a81589-d304-41c8-acd8-7c38f4530315',
  ONGOING = 'd4d4bc33-ef3b-4bf1-a8a2-44dcf51f3083',
  ASSIGNED = '2e352b47-7cbd-42da-9374-5929f70418fe',
  CUSTOMER = 'be870534-5a85-4e3e-867b-e85f0fe61717',
  AGREEMENT = 'b82d3b30-d94a-410a-9061-a2f0f6af8f1f',
  LEAD_LOST = '584a472b-7e8a-43d3-b3c6-f9b6ac925153',
  RESCHEDULE = '0d488a77-0001-4a4b-90e5-39beeb440df1',
  FIRST_CONTACT = '0ebf8556-401f-41ab-bb62-4b2645638e7e',
  CUSTOMER_LOST = '2820a6ad-b70e-4006-9ce6-f9464a0c46b6',
}

export enum AccountStatusTypeName {
  OFFER = 'Teklif',
  CANCEL = 'Potansiyel Yok',
  RETURN = 'Satıştan Geri Gönderildi',
  PLANNED = 'Satış Aktivitesi Planlandı',
  ONGOING = 'İş Fırsatı',
  ASSIGNED = 'Satışa Yönlendirildi',
  CUSTOMER = 'Müşteri',
  AGREEMENT = 'Sözleşme Tanımlama',
  LEAD_LOST = 'Müşteri Fırsatı Kaybı',
  RESCHEDULE = 'İleri Tarihli İş Fırsatı',
  FIRST_CONTACT = 'Görüşme',
  CUSTOMER_LOST = 'Müşteri Kaybı',
}
