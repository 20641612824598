import { Card, CardAttachment, CardCommentStateReturnValue, CardObject, DynamicFieldValue } from '@core/api';
import { Action } from '@ngrx/store';

export enum BoardDetailFormDialogActionTypes {
    GET_BOARD_DETAIL_FORM_DIALOG = '[Board Detail Form Dialog] GET_BOARD_DETAIL_FORM_DIALOG',
    SET_BOARD_DETAIL_FORM_DIALOG_SUCCESS = '[Board Detail Form Dialog] SET_BOARD_DETAIL_FORM_DIALOG_SUCCESS',
    SET_BOARD_DETAIL_FORM_DIALOG_ERROR = '[Board Detail Form Dialog] SET_BOARD_DETAIL_FORM_DIALOG_ERROR',
    SET_BOARD_DETAIL_FORM_DIALOG_CLEAR = '[Board Detail Form Dialog] SET_BOARD_DETAIL_FORM_DIALOG_CLEAR',
    SET_BOARD_DETAIL_FORM_DIALOG_CARD_ACTION_TYPE = '[Board Detail Form Dialog] SET_BOARD_DETAIL_FORM_DIALOG_CARD_ACTION_TYPE',
    SET_BOARD_DETAIL_FORM_DIALOG_CARD_NAME = '[Board Detail Form Dialog] SET_BOARD_DETAIL_FORM_DIALOG_CARD_NAME',
    SET_BOARD_DETAIL_FORM_DIALOG_CARD_DESCRIPTION = '[Board Detail Form Dialog] SET_BOARD_DETAIL_FORM_DIALOG_CARD_DESCRIPTION',
    SET_BOARD_DETAIL_FORM_DIALOG_CARD_REMINDER_DATE = '[Board Detail Form Dialog] SET_BOARD_DETAIL_FORM_DIALOG_CARD_REMINDER_DATE',
    SET_BOARD_DETAIL_FORM_DIALOG_CARD_ATTACHMENT = '[Board Detail Form Dialog] SET_BOARD_DETAIL_FORM_DIALOG_CARD_ATTACHMENT',
    SET_BOARD_DETAIL_FORM_DIALOG_CARD_OBJECT = '[Board Detail Form Dialog] SET_BOARD_DETAIL_FORM_DIALOG_CARD_OBJECT',
    SET_BOARD_DETAIL_FORM_DIALOG_CARD_DYNAMIC_FIELD = '[Board Detail Form Dialog] SET_BOARD_DETAIL_FORM_DIALOG_CARD_DYNAMIC_FIELD',
    SET_BOARD_DETAIL_FORM_DIALOG_CARD_COMMENT = '[Board Detail Form Dialog] SET_BOARD_DETAIL_FORM_DIALOG_CARD_COMMENT',
    SET_BOARD_DETAIL_FORM_DIALOG_CARD_CHILD_CARD = '[Board Detail Form Dialog] SET_BOARD_DETAIL_FORM_DIALOG_CARD_CHILD_CARD',
}

export class SetBoardDetailFormDialog implements Action {
    readonly type = BoardDetailFormDialogActionTypes.GET_BOARD_DETAIL_FORM_DIALOG;
    constructor(public payload: { cardId: string; boardId: string; }) { }
}

export class GetBoardDetailFormDialogSuccess implements Action {
    readonly type = BoardDetailFormDialogActionTypes.SET_BOARD_DETAIL_FORM_DIALOG_SUCCESS;
    constructor(public payload: Card) { }
}

export class GetBoardDetailFormDialogError implements Action {
    readonly type = BoardDetailFormDialogActionTypes.SET_BOARD_DETAIL_FORM_DIALOG_ERROR;
    constructor(public payload: any) { }
}

export class GetBoardDetailFormDialogClear implements Action {
    readonly type = BoardDetailFormDialogActionTypes.SET_BOARD_DETAIL_FORM_DIALOG_CLEAR;
}

export class GetBoardDetailFormDialogCardActionType implements Action {
    readonly type = BoardDetailFormDialogActionTypes.SET_BOARD_DETAIL_FORM_DIALOG_CARD_ACTION_TYPE;
    constructor(public cardActionType: string) { }
}

export class GetBoardDetailFormDialogCardName implements Action {
    readonly type = BoardDetailFormDialogActionTypes.SET_BOARD_DETAIL_FORM_DIALOG_CARD_NAME;
    constructor(public cardName: string) { }
}

export class GetBoardDetailFormDialogCardDescription implements Action {
    readonly type = BoardDetailFormDialogActionTypes.SET_BOARD_DETAIL_FORM_DIALOG_CARD_DESCRIPTION;
    constructor(public cardDescription: string) { }
}

export class GetBoardDetailFormDialogCardReminderDate implements Action {
    readonly type = BoardDetailFormDialogActionTypes.SET_BOARD_DETAIL_FORM_DIALOG_CARD_REMINDER_DATE;
    constructor(public cardReminderDate: moment.Moment) { }
}

export class GetBoardDetailFormDialogCardAttachment implements Action {
    readonly type = BoardDetailFormDialogActionTypes.SET_BOARD_DETAIL_FORM_DIALOG_CARD_ATTACHMENT;
    constructor(public cardAttachments: CardAttachment[]) { }
}

export class GetBoardDetailFormDialogCardObject implements Action {
    readonly type = BoardDetailFormDialogActionTypes.SET_BOARD_DETAIL_FORM_DIALOG_CARD_OBJECT;
    constructor(public cardObjects: CardObject[]) { }
}

export class GetBoardDetailFormDialogCardDynamicField implements Action {
    readonly type = BoardDetailFormDialogActionTypes.SET_BOARD_DETAIL_FORM_DIALOG_CARD_DYNAMIC_FIELD;
    constructor(public dynamicFieldValues: DynamicFieldValue[]) { }
}

export class GetBoardDetailFormDialogCardComment implements Action {
    readonly type = BoardDetailFormDialogActionTypes.SET_BOARD_DETAIL_FORM_DIALOG_CARD_COMMENT;
    constructor(public cardComment: CardCommentStateReturnValue) { }
}

export class GetBoardDetailFormDialogCardChildCard implements Action {
    readonly type = BoardDetailFormDialogActionTypes.SET_BOARD_DETAIL_FORM_DIALOG_CARD_CHILD_CARD;
    constructor(public childCards: Card[]) { }
}



export type BoardDetailFormDialogActions =
    SetBoardDetailFormDialog |
    GetBoardDetailFormDialogSuccess |
    GetBoardDetailFormDialogError |
    GetBoardDetailFormDialogClear |
    GetBoardDetailFormDialogCardActionType |
    GetBoardDetailFormDialogCardName |
    GetBoardDetailFormDialogCardDescription |
    GetBoardDetailFormDialogCardReminderDate |
    GetBoardDetailFormDialogCardAttachment |
    GetBoardDetailFormDialogCardObject |
    GetBoardDetailFormDialogCardDynamicField |
    GetBoardDetailFormDialogCardComment |
    GetBoardDetailFormDialogCardChildCard;
