import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatFormFieldControl } from '@angular/material/form-field';

import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';

import { SystemUser, SystemUserService, SystemUserStatusType } from '@core/api';

@Component({
  selector: 'net-select-user[salesOrganizationIds]',
  templateUrl: './select-user.component.html',
  styleUrls: ['./select-user.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: SelectUserComponent
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectUserComponent implements OnInit, OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<SystemUser | null> {

  static nextId = 0;

  loading = false;
  focused = false;
  errorState = false;
  controlType = 'select-user';
  describedBy = '';
  stateChanges = new Subject<void>();

  users: SystemUser[] = [];
  copyUsers: SystemUser[] = [];
  inputCtrl = new UntypedFormControl();

  @ViewChild('auto') auto: MatAutocomplete;
  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  @Input() isDisabled = false;
  @Input() tabIndex;
  @HostBinding('id') id = `select-user-${SelectUserComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private systemUserService: SystemUserService,
    private changeDetectorRef: ChangeDetectorRef,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Material form field implementation
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  private _placeholder: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  private _required = false;

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private _disabled = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    if (value) {
      this.inputCtrl.disable();
    } else {
      this.inputCtrl.enable();
    }

    this._disabled = value;
    this.stateChanges.next();
  }

  private _value: SystemUser;

  @Input()
  get value(): SystemUser | null {
    return this._value;
  }

  set value(value: SystemUser | null) {
    this._value = value;
    this.onChange(value);
    this.stateChanges.next();
  }

  private _salesOrganizationIds: string[];

  @Input()
  get salesOrganizationIds(): string[] {
    return this._salesOrganizationIds;
  }

  set salesOrganizationIds(value: string[]) {
    this._salesOrganizationIds = value;

    if (value) {
      this.loading = true;
      this.users = [];
      this.inputCtrl.disable();

      this.initDefaultOptions().finally(() => {
        this.loading = false;
        if (!this.disabled) {
          this.inputCtrl.enable();
        }

        this.changeDetectorRef.detectChanges();
      });
    }
    this.stateChanges.next();
  }

  private _filters: string[];

  @Input()
  get filters(): string[] | null {
    return this._filters;
  }

  set filters(filters: string[] | null) {
    this._filters = filters;
    if (filters && this.copyUsers) {
      this.users = this.copyUsers.filter(user => !this.filters.includes(user.systemUserId));
    }
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get empty() {
    return !this.value;
  }

  ngOnInit() {
    // Listen input control value changes and filter results
    this.inputCtrl.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      filter(value => typeof value === 'string'),
      tap(() => {
        this.loading = true;
        this.users = [];
        this.value = null;
      }),
      switchMap(value => {
        const request = {
          filter: {
            fullName: value,
            salesOrganizationIds: this.salesOrganizationIds,
            systemUserStatusIds: [SystemUserStatusType.ACTIVE]
          },
          pageSize: 100
        };

        if (!value) {
          delete request.filter.fullName;
        }
        return this.systemUserService.userSearch(request);
      }),
      tap(() => this.loading = false)
    ).subscribe((response) => {
      this.users = response.data.results.sort((a, b) => a.firstName.localeCompare(b.firstName));
      this.copyUsers = this.users;

      if (this.filters) {
        this.users = this.users.filter(user => !this.filters.includes(user.systemUserId));
      }
      this.changeDetectorRef.detectChanges();
    });
  }

  displayName = (users: SystemUser) => users ? users.firstName + ' ' + users.lastName : '';

  reset() {
    if (this.inputCtrl.pristine) {
      return;
    }

    this.value = null;
    this.users = [];
    this.inputCtrl.setValue(null);
    this.inputCtrl.markAsPristine();
    this.initDefaultOptions();
  }

  onSelect(event: MatAutocompleteSelectedEvent) {
    this.value = event.option.value;
    this.input.nativeElement?.blur();
  }

  onBlur() {
    // On blur with nothing selected reset component
    if (this.ngControl.dirty && !this.auto.isOpen && !this.value) {
      this.reset();
    }
  }

  onClosed() {
    // On panel close with nothing selected reset component
    if (this.ngControl.dirty && !this.value) {
      this.reset();
    }
  }

  onChange = (_: any) => {
  }

  onTouched = () => {
  }

  onContainerClick = () => {
  }

  registerOnChange = (fn: any) => this.onChange = fn;

  registerOnTouched = (fn: any) => this.onTouched = fn;

  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;

  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');

  writeValue(value: SystemUser) {
    this.value = value;
    this.inputCtrl.setValue(value);
  }

  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }

  private initDefaultOptions(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.systemUserService.userSearch(
        {
          filter: { salesOrganizationIds: this.salesOrganizationIds, systemUserStatusIds: [SystemUserStatusType.ACTIVE] },
          pageSize: 100
        })
        .toPromise()
        .then((response) => {
          this.users = response.data.results.sort((a, b) => a.firstName.localeCompare(b.firstName));
          this.copyUsers = this.users;

          if (this.filters) {
            this.users = this.copyUsers.filter(user => !this.filters.includes(user.systemUserId));
          }

          this.changeDetectorRef.detectChanges();
        })
        .finally(() => resolve());
    });
  }
}
