import { debounceTime, finalize, map } from 'rxjs/operators';
import { ApiDataSource } from '../api.data-source';
import {
    CustomerExperienceCategoriesService, CustomerExperienceChannelSettingsService, CustomerExperienceLink,
    CustomerExperienceLinkType, CustomerExperienceMainCategoriesService, CustomerExperienceTicketLinksService,
    CustomerExperienceTicketService,
    SystemUserService,
    User
} from '@core/api';
import { of } from 'rxjs';
import { KeyValue } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

export class CustomerExperienceTicketLinkDataSource extends ApiDataSource<CustomerExperienceLink> {
    public isOpenedNewTab = false;

    // Link type option key value list
    linkTypes$ = of<KeyValue<string, string>[]>([
        { key: CustomerExperienceLinkType.CARD, value: this.translate.instant('GENERAL.MAIL_LINK_TYPE_CARD') },
        { key: CustomerExperienceLinkType.CONTACT, value: this.translate.instant('GENERAL.MAIL_LINK_TYPE_CONTACT') },
        { key: CustomerExperienceLinkType.ACTIVITY, value: this.translate.instant('GENERAL.MAIL_LINK_TYPE_ACTIVITY') },
        { key: CustomerExperienceLinkType.CUSTOMER, value: this.translate.instant('GENERAL.MAIL_LINK_TYPE_CUSTOMER') },
        { key: CustomerExperienceLinkType.ASSIGNMENT, value: this.translate.instant('GENERAL.MAIL_LINK_TYPE_ASSIGNMENT') },
        { key: CustomerExperienceLinkType.LEAD_DRAFT, value: this.translate.instant('GENERAL.MAIL_LINK_TYPE_LEAD_DRAFT') },
        { key: CustomerExperienceLinkType.OPPORTUNITY, value: this.translate.instant('GENERAL.MAIL_LINK_TYPE_OPPORTUNITY') },
    ]);

    public $userList = this.systemUserService
    .userSearch({ filter: { salesOrganizationIds: this.user.salesOrganizations.map(item => item) } }).pipe(
        map(response => response.data.results),
        map(users => users.map(item => {
            return {
                key: item.systemUserId,
                value: [item.firstName, item.lastName].join(' '),
            };
        }))
    );

    public $channelTypes = this.channelService.getChannelTypes().pipe(
        map(response => response.data),
        map(chTypes => chTypes.map(channelType => {
            return {
                key: channelType.cxChannelTypeId,
                value: channelType.name
            };
        }))
    );

    public $channels = this.channelService.search({
        orderBy: 'createdOn',
        orderType: 'ASC',
        page: 1,
        pageSize: 1000,
        filter: {}
    }).pipe(
        map(response => response.data.results),
        map(channels => channels.map(channel => {
            return {
                key: channel.cxChannelId,
                value: channel.name
            };
        }))
    );

    public $mainCategories = this.mainCategoryService.search({
        orderBy: 'orderBy',
        orderType: 'ASC',
        page: 1,
        pageSize: 1000,
        filter: {}
    }).pipe(
        map(response => response.data.results),
        map(mainCategories => mainCategories.map(mainCategory => {
            return {
                key: mainCategory.cxMainCategoryId,
                value: mainCategory.name
            };
        }))
    );

    public $categories = this.categoryService.search({
        orderBy: 'orderBy',
        orderType: 'ASC',
        page: 1,
        pageSize: 1000,
        filter: {}
    }).pipe(
        map(response => response.data.results),
        map(categories => categories.map(category => {
            return {
                key: category.cxCategoryId,
                value: category.name
            };
        }))
    );

    public $statuses = this.categoryService.statusSearch({
        page: 1,
        pageSize: 1000,
        orderBy: 'createdOn',
        orderType: 'ASC',
        filter: {}
    }).pipe(
        map(response => response.data.results),
        map(statuses => statuses.filter(status => !status['parentCxStatusId'])),
        map(filteredStatuses => filteredStatuses.map(status => {
            return {
                key: status.cxStatusId,
                value: this.translate.instant('CxStatus.' + status.name)
            };
        }))
    );

    public $subStatuses = this.categoryService.statusSearch({
        page: 1,
        pageSize: 1000,
        orderBy: 'createdOn',
        orderType: 'ASC',
        filter: {}
    }).pipe(
        map(response => response.data.results),
        map(statuses => statuses.filter(status => status['parentCxStatusId'])),
        map(filteredStatuses => filteredStatuses.map(status => {
            return {
                key: status.cxStatusId,
                value: this.translate.instant('CxStatus.' + status.name)
            };
        }))
    );

    public $priorities = this.ticketService.listPriority().pipe(
        map(response => response.data),
        map(priorities => priorities.map(priority => {
            return {
                key: priority.ticketPriorityId,
                value: this.translate.instant('TicketPriority.' + priority.name)
            };
        }))
    );

    private filterReq: any;

    constructor(
        private ticketService: CustomerExperienceTicketService,
        private channelService: CustomerExperienceChannelSettingsService,
        private mainCategoryService: CustomerExperienceMainCategoriesService,
        private categoryService: CustomerExperienceCategoriesService,
        private customerExperienceTicketLinksService: CustomerExperienceTicketLinksService,
        private systemUserService: SystemUserService,
        private user: User,
        private translate: TranslateService,
        protected initialFilter?: any,
    ) {
        super(initialFilter);
    }

    load(): void {
        if (!this.isOpenedNewTab) {
            // Init filter with data source's default filter
            const filterValue: any = { ...this.initialFilter, ...this.filter };

            // If filter keyword exists, filter data
            if (this.keyword) {
                filterValue.searchText = this.keyword;
            }

            // Update loading state
            this.loadingSubject.next(true);

            // Create request parameters
            const request = this.getRequest();
            if (!request.orderType) {
                request.orderType = 'ASC';
                request.orderBy = 'createdOn';
            }

            if (this.paginator?.pageSize) {
                request.pageSize = this.paginator.pageSize;
            }

            // Add filters to request
            request.filter = filterValue;

            this.filterReq = { ...request };

            // Fetch data
            this.customerExperienceTicketLinksService.link
                .search(request)
                .pipe(
                    debounceTime(400),
                    finalize(() => this.loadingSubject.next(false))
                ).subscribe(response => {
                    // Update count and data subjects
                    this.dataSubject.next(response.data.results);
                    this.dataCountSubject.next(response.data.rowCount);

                    // Update data source's empty based row count
                    this.empty = response.data.rowCount === 0;
                });

        }

        return this.loadingSubject.next(false);
    }

}
