<form (ngSubmit)="onSubmit()" [formGroup]="form">
    <div class="p-6" fxLayout="column" fxLayoutGap="16px">
        <div fxFlex="auto" fxLayout="column">
            <h2 mat-dialog-title>{{'TOOLBAR.PASSWORD_UPDATE' | translate}}</h2>
            <mat-form-field fxFlex="grow">
                <mat-label>{{'TOOLBAR.OLD_PASSWORD' | translate}}</mat-label>
                <input type="password" formControlName="oldPassword" matInput type="password">
                <mat-error *ngIf="form.get('oldPassword').hasError('required')">
                    {{'TOOLBAR.PLEASE_TYPE_YOUR_OLD_PASSWORD' | translate}}
                </mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="grow">
                <mat-label>{{'TOOLBAR.NEW_PASSWORD' | translate}}</mat-label>
                <input matInput formControlName="password" autocorrect="off" autocomplete="current-password"
                    autocapitalize="off" spellcheck="false" type="password">
                <mat-icon [icIcon]="icHelp" color="primary"
                    [matTooltip]="'TOOLBAR.PASSWORD_MUST_BE_AT_LEAST_CHARACTERS_AND_CONTAIN_AT_LEAST_ONE_LOWERCASE_LETTER' | translate"
                    matSuffix></mat-icon>
                <mat-error *ngIf="form.get('password').hasError('required')">
                    {{'TOOLBAR.PLEASE_TYPE_YOUR_NEW_PASSWORD' | translate}}
                </mat-error>
                <mat-error *ngIf="form.get('password').hasError('pattern')">
                    {{'TOOLBAR.PLEASE_CREATE_A_STRONG_PASSWORD' | translate}}
                </mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="grow">
                <mat-label>{{'TOOLBAR.CONFIRM_NEW_PASSWORD' | translate}}</mat-label>
                <input matInput formControlName="confirmPassword" autocorrect="off" autocomplete="current-password"
                    autocapitalize="off" spellcheck="false" type="password" [errorStateMatcher]="matcher"> 
                    <mat-error *ngIf="form.hasError('notSame')">
                        {{'TOOLBAR.PASSWORD_NOT_MATCH' | translate}}
                    </mat-error>
            </mat-form-field>
        </div>
        <mat-dialog-actions class="items-start">
            <button class="ml-auto" color="secondary" mat-button [mat-dialog-close]="false">{{'GENERAL.CANCEL' | translate}}</button>
            <net-progress-button [icon]="icSave" [block]="true" [active]="loading" text="{{'GENERAL.SAVE' | translate}}"></net-progress-button>
        </mat-dialog-actions>
    </div>
</form>
