import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import { FilterRequest, PagedResponse, ActivityStatus } from '@core/api';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class ActivityStatusService {
  constructor(private http: HttpClient) { }

  list(request?: FilterRequest): Observable<PagedResponse<ActivityStatus>> {
    return this.http.post<PagedResponse<ActivityStatus>>(environment.apiUrl + '/api/ActivityStatus/List', request);
  }
}
