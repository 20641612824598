import { debounceTime, finalize, map } from 'rxjs/operators';

import {
  SystemUserService,
  User
} from '@core/api';
import { ApiDataSource } from '@core/api/api.data-source';
import { TranslateService } from '@ngx-translate/core';

import { SurveyService } from './survey.service';
import { Observable } from 'rxjs';
import { Survey } from './survey.model';
import { SurveyTypeService } from '../survey-type/survey-type.service';

export class SurveyDataSource extends ApiDataSource<Survey> {

  public isOpenedNewTab = false;

  public $surveyTypes = this.surveyTypeService.list({}).pipe(
    map(response => response.data),
    map(types => types.map(item => {
      return {
        key: item.surveyTypeId,
        value: item.name
      };
    }))
  );

  private $users = this.getUsersBySalesOrganizations();
  public $createBys = this.$users;

  public $status = this.translate.get(['GENERAL']).pipe(
    map(translations => (
      [
        { key: false, value: translations.GENERAL.PASSIVE },
        { key: true, value: translations.GENERAL.ACTIVE }
      ]))
  );

  constructor(
    private surveyService: SurveyService,
    private surveyTypeService: SurveyTypeService,
    private translate: TranslateService,
    private systemUserService: SystemUserService,
    private user: User,
    protected initialFilter?: any
  ) {
    super(initialFilter);
  }

  load(): void {
    if (!this.isOpenedNewTab) {

      // Init filter with data source's default filter
      const filter: any = { ...this.initialFilter, ...this.filter };

      // console.log("Initial Filter ==== >", filter);

      // If filter keyword exists, filter data
      if (this.keyword) {
        filter.searchText = this.keyword;
      }

      // Update loading state
      this.loadingSubject.next(true);

      // Create request parameters
      const request = this.getRequest();

      if (this.paginator?.pageSize) {
        request.pageSize = this.paginator.pageSize;
      }

      // Add filters to request
      request.filter = filter;

      // Fetch data
      this.surveyService
        .search(request)
        .pipe(
          debounceTime(400),
          finalize(() => this.loadingSubject.next(false))
        ).subscribe(response => {
          // Update count and data subjects
          this.dataSubject.next(response.data.results);
          // console.log(this.dataSubject)
          this.dataCountSubject.next(response.data.rowCount);

          // Update data source's empty based row count
          this.empty = response.data.rowCount === 0;
        });

    }
    return this.loadingSubject.next(false);
  }

  getUsersBySalesOrganizations(): Observable<any> {
    return this.systemUserService.userSearch({
      filter: {
        salesOrganizationIds: this.user.salesOrganizations.map(item => item),
      }
    }).pipe(
      map(response => response.data.results),
      map(statuses => statuses.map(item => {
        return {
          key: item.systemUserId,
          value: `${item.firstName} ${item.lastName}`
        };
      }))
    );
  }
}
