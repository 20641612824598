import { User } from '@core/api';
import { LoginTokens } from '@core/auth/auth.model';
import { AuthActions, AuthActionTypes } from '@core/store';

export interface AuthState {
    user: User;
    tokens: LoginTokens;
    isLoading: boolean;
    errorMessage: string;
    errorCode: string;
}

export const initialState: AuthState = {
    user: JSON.parse(localStorage.getItem('api_user')),
    tokens: {
        accessToken: localStorage.getItem('api_token'),
        refreshToken: localStorage.getItem('api_refresh_token'),
    },
    isLoading: false,
    errorMessage: null,
    errorCode: null,
};

export function authReducer(state = initialState, action: AuthActions): AuthState {
    switch (action.type) {
        case AuthActionTypes.LOGIN:
        case AuthActionTypes.ME:
            return {
                ...state,
                errorCode: null,
                errorMessage: null,
                isLoading: true
            };
        case AuthActionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                user: { ...action.payload.data.user, permissions: action.payload.data.permissions },
                tokens: {
                    accessToken: action.payload.data.accessToken,
                    accessTokenExpires: action.payload.data.accessTokenExpires,
                    refreshToken: action.payload.data.refreshToken,
                    refreshTokenExpires: action.payload.data.refreshTokenExpires
                },
                errorCode: null,
                errorMessage: null,
                isLoading: false,
            };
        case AuthActionTypes.LOGIN_ERROR:
        case AuthActionTypes.ME_ERROR:
            return {
                ...state,
                isLoading: false,
                errorCode: action.payload.errorCode,
                errorMessage: action.payload.message
            };
        case AuthActionTypes.ME_SUCCESS:
            return {
                ...state,
                user: { ...action.payload.data.user, permissions: action.payload.data.permissions },
                tokens: {
                    accessToken: localStorage.getItem('api_token'),
                    accessTokenExpires: action.payload.data.accessTokenExpires,
                    refreshToken: localStorage.getItem('api_refresh_token'),
                    refreshTokenExpires: action.payload.data.refreshTokenExpires
                },
                errorCode: null,
                errorMessage: null,
                isLoading: false,
            };
        case AuthActionTypes.SESSION_UPDATE:
            return {
                ...state,
                user: { ...action.payload.user },
                tokens: {
                    accessToken: action.payload.accessToken,
                    refreshToken: action.payload.refreshToken,
                },
                errorCode: null,
                errorMessage: null,
                isLoading: false,
            };
        case AuthActionTypes.PROFILE_PHOTO:
            return {
                ...state,
                user: { ...state.user, ...{ systemUserPicture: action.payload } },
            };
        case AuthActionTypes.LOGOUT:
            return {
                user: null,
                tokens: null,
                isLoading: false,
                errorMessage: null,
                errorCode: null,
            };
        default:
            return state;
    }

}
