import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import {
  AccountService, ActivityService, ApiService, AssignmentService, CardService, Customer,
  CustomerExperienceLink, CustomerExperienceLinkInsertRequest, CustomerExperienceLinkType,
  OpportunityService, PagedResponse, Response, SalesOrganization,
  TicketLinkSearchRequest
} from '@core/api';
import { catchError, filter, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomerExperienceTicketLinksService {

  private linkChangeSubject = new ReplaySubject<{ type: 'add' | 'remove', ticketLinkId: string, link?: any }>();
  linkChange$ = this.linkChangeSubject.asObservable();

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private cardService: CardService,
    private accountService: AccountService,
    private activityService: ActivityService,
    private assignmentService: AssignmentService,
    private opportunityService: OpportunityService
  ) { }

  link = {

    search: (request: TicketLinkSearchRequest): Observable<PagedResponse<CustomerExperienceLink>> => {
      return this.http.post<PagedResponse<CustomerExperienceLink>>(`${environment.apiUrl}/api/TicketLink/Search`, request);
    },

    insert: (request: CustomerExperienceLinkInsertRequest, notify = true): Observable<Response<CustomerExperienceLink>> => {
      return this.http.post<Response<CustomerExperienceLink>>(`${environment.apiUrl}/api/TicketLink/Insert`, request)
        .pipe(
          tap(response => {
            if (!response.success) {
              return;
            }

            // Notify subject when link change
            if (notify) {
              this.linkChangeSubject.next({ type: 'add', ticketLinkId: response.data.ticketLinkId, link: response.data });
            }
          })
        );
    },

    inputsFromLinks: (links: CustomerExperienceLink[]): Observable<CustomerExperienceLink[]> => {

      const subject = new Subject<CustomerExperienceLink[]>();

      Promise.allSettled(
        links.map(link => {
          if (link?.activityId) {

            return this.activityService.get(link.activityId)
              .pipe(
                filter((response) => response.success),
                map((response) => ({
                  typeId: CustomerExperienceLinkType.ACTIVITY,
                  ticketLinkId: link.ticketLinkId,
                  account: response.data.account,
                  activity: response.data,
                } as CustomerExperienceLink)),
                catchError((error) => {
                  console.error('Activity fetch failed.', { error, link });
                  throw error;
                }),
              )
              .toPromise();
          }

          if (link?.contactId) {

            return this.apiService.contact.get(link.contactId, true)
              .pipe(
                filter((response) => response.success),
                map((response) => ({
                  typeId: CustomerExperienceLinkType.CONTACT,
                  ticketLinkId: link.ticketLinkId,
                  contact: response.data,
                  salesOrganization: this.lookupSalesOrganization(link?.salesOrganization?.salesOrganizationId, response.data),
                } as CustomerExperienceLink)),
                catchError((error) => {
                  console.error('Contact fetch failed.', { error, link });
                  throw error;
                }),
              )
              .toPromise();
          }

          if (link?.assignmentId) {
            return this.assignmentService.get(link.assignmentId)
              .pipe(
                filter((response) => response.success),
                map((response) => ({
                  typeId: CustomerExperienceLinkType.ASSIGNMENT,
                  ticketLinkId: link.ticketLinkId,
                  account: response.data.account,
                  assignment: response.data,
                } as CustomerExperienceLink)),
                catchError((error) => {
                  console.error('Assignment fetch failed.', { error, link });
                  throw error;
                }),
              )
              .toPromise();
          }

          if (link?.opportunityId) {
            return this.opportunityService.get(link.opportunityId)
              .pipe(
                filter((response) => response.success),
                map((response) => ({
                  typeId: CustomerExperienceLinkType.OPPORTUNITY,
                  ticketLinkId: link.ticketLinkId,
                  account: response.data.account,
                  opportunity: response.data,
                } as CustomerExperienceLink)),
                catchError((error) => {
                  console.error('Opportunity fetch failed.', { error, link });
                  throw error;
                }),
              )
              .toPromise();

          }

          if (link?.cardId) {
            return this.cardService.get({
              cardId: link.cardId,
              boardId: link?.card?.boardInfo?.boardId
            }).pipe(
              filter((response) => response.success),
              map((response) => ({
                typeId: CustomerExperienceLinkType.CARD,
                ticketLinkId: link.ticketLinkId,
                card: response.data
              } as CustomerExperienceLink)),
              catchError((error) => {
                console.error('Card fetch failed.', { error, link });
                throw error;
              }),
            )
              .toPromise();
          }

          if (link?.leadDraftId) {
            return this.apiService.leadDraft.get(link.leadDraftId)
              .pipe(
                filter((response) => response.success),
                map((response) => ({
                  typeId: CustomerExperienceLinkType.LEAD_DRAFT,
                  ticketLinkId: link.ticketLinkId,
                  leadDraft: response.data,
                  salesOrganization: { salesOrganizationId: link?.salesOrganization?.salesOrganizationId },
                } as CustomerExperienceLink)),
                catchError((error) => {
                  console.error('Lead draft fetch failed.', { error, link });
                  throw error;
                }),
              )
              .toPromise();

          }

          if (link?.accountId) {
            return this.accountService.get(link.accountId)
              .pipe(
                filter((response) => response.success),
                map((response) => ({
                  typeId: CustomerExperienceLinkType.CUSTOMER,
                  ticketLinkId: link.ticketLinkId,
                  account: response.data
                } as CustomerExperienceLink)),
                catchError((error) => {
                  console.error('Account fetch failed.', { error, link });
                  throw error;
                }),
              )
              .toPromise();
          }


        })
      ).then(
        (result) => {
          const inputs = result
            .filter((item) => 'fulfilled' === item.status)
            .map((item) => 'fulfilled' === item.status ? item.value : null);

          // Return inputs to subject
          subject.next(inputs);
        },
        (err) => subject.error(err)
      );

      return subject.asObservable();
    },
    delete: (request: { ticketLinkId: string }, notify = true): Observable<Response<CustomerExperienceLink>> => {
      return this.http.post<Response<CustomerExperienceLink>>(`${environment.apiUrl}/api/TicketLink/Delete`, request).pipe(
        tap(response => {
          if (!response.success) {
            return;
          }

          // Notify subject when link change
          if (notify) {
            this.linkChangeSubject.next({ type: 'remove', ticketLinkId: request.ticketLinkId });
          }
        })
      );
    }
  };

  private lookupSalesOrganization(salesOrganizationId: string, input: { customer: Customer }): SalesOrganization | null {
    const account = input.customer?.accounts?.find((item) => salesOrganizationId === item.salesOrganizationId);
    if (!account) {
      return null;
    }

    return account.salesOrganization;
  }

}
