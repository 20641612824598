import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClientIpModel } from './client-ip.model';

@Injectable({
    providedIn: 'root',
})
export class ClientIpService {

private httpClient: HttpClient;

    ipAddress: string;

    constructor( private httpBackend: HttpBackend) {
        this.httpClient = new HttpClient(this.httpBackend);
    }

    getClientIpAddress(): Promise<ClientIpModel> {
        return this.httpClient.get<ClientIpModel>('https://jsonip.com').toPromise();
    }

    getIpAddress(): Promise<string> {
        return new Promise((resolve) => {
            if (this.ipAddress) {
                resolve(this.ipAddress);
                return;
            }
            this.getClientIpAddress().then((response) => {
                this.ipAddress = response.ip;
            }).finally(() => resolve(this.ipAddress));
        });
    }
}
