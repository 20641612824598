import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

import { Router } from '@angular/router';
import { Response } from '@core/api/api.model';
import { IS_REQUEST_BEFORE_LOGOUT } from '@core/auth/auth.enum';
import { AuthService } from '@core/auth/auth.service';
import { MailLayoutService } from 'src/app/mail/containers/mail-layout.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private auth: AuthService, private mailLayoutService: MailLayoutService, private router: Router) { }

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private static addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  private handle401(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // If auth service continues to refreshing wait until complete
    if (this.isRefreshing) {
      return this.refreshTokenSubject
        .pipe(
          filter(token => token != null),
          take(1),
          switchMap(token => {
            return next.handle(AuthInterceptor.addToken(request, token));
          }),
          catchError((err) => {
            this.auth.logout(true, true);
            return EMPTY;
          })
        );
    }

    // Start token refresh process
    this.isRefreshing = true;
    this.refreshTokenSubject.next(null);

    return this.auth.refreshToken()
      .pipe(
        switchMap(() => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(AuthService.getToken());
          return next.handle(AuthInterceptor.addToken(request, AuthService.getToken()));
        }),
        catchError((err) => {
          this.auth.logout(false, true);
          return EMPTY;
        })
      );
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Do not intercept API's auth requests
    if (request.url.match(AuthService.AUTH_REGEX)) {
      return next.handle(request);
    }

    // If auth token exists, add token to request
    if (AuthService.getToken()) {
      request = AuthInterceptor.addToken(request, AuthService.getToken());
    }

    return next.handle(request).pipe(catchError(errorObj => {
      if (
        errorObj.error.error instanceof HttpErrorResponse &&
        errorObj.error.error.status === 401 &&
        !request.context.get(IS_REQUEST_BEFORE_LOGOUT) &&
        AuthService.getRefreshToken()
      ) {
        return this.handle401(request, next);
      }
      return throwError(errorObj.error);
    }),
    switchMap(event => {
      if (event instanceof HttpResponse) {
        const response: Response<any> = event.body;

        if (response?.errorCode && (Number(response.errorCode) === 408 || Number(response.errorCode) === 409)) {
          this.mailLayoutService.logoutWhenTokenExpired();
          this.router.navigate(['mail/login']);
          return EMPTY;
        }
      }
      return of(event);
    })
    );
  }
}

