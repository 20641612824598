import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import {
  CustomerExperienceInteractionsRequestModel, CustomerExperienceInteractionsModel,
  Response, CustomerExperienceInteractionsFormatModel
} from '@core/api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomerExperienceInteractionsService {

  constructor(
    private http: HttpClient
  ) { }

  getInteractions(request: CustomerExperienceInteractionsRequestModel): Observable<CustomerExperienceInteractionsFormatModel[]> {
    return this.http.post<Response<CustomerExperienceInteractionsFormatModel[]>>(environment.apiUrl + '/api/TicketInteraction/GetInteractions', request).pipe(
      map(response => this.transformInteractions(response.data))
    );
  }

  private transformInteractions(interactions: CustomerExperienceInteractionsModel[]) {

    const formatterInteractions: CustomerExperienceInteractionsFormatModel[] = [];
    interactions.map(interaction => {
      switch (interaction.interactionType) {
        case 'Activity':
          interaction.activities.map(activity => {
            formatterInteractions.push({
              activity,
              createdOn: activity.createdOn,
              interactionType: 'Activity'
            });
          });
          break;
        case 'Opportunity':
          interaction.opportunities.map(opportunity => {
            formatterInteractions.push({
              opportunity,
              createdOn: opportunity.createdOn,
              interactionType: 'Opportunity'
            });
          });
          break;
        case 'Ticket':
          interaction.tickets.map(ticket => {
            formatterInteractions.push({
              ticket,
              createdOn: ticket.createdOn,
              interactionType: 'Ticket'
            });
          });
          break;
      }
    });

    return formatterInteractions;
  }
}
