import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';

import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, DoCheck, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, Optional, Output,
  Self, ViewChild
} from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import {
  MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger
} from '@angular/material/autocomplete';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Account, Offer, OfferService } from '@core/api';
import { Permission } from '@core/auth/auth.enum';
import { hasUserPermission } from '@core/store';
import icArrowDropDown from '@iconify/icons-ic/twotone-arrow-drop-down';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';

@UntilDestroy()
@Component({
  selector: 'net-select-offer-searchable',
  templateUrl: './select-offer-searchable.component.html',
  styleUrls: ['./select-offer-searchable.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: SelectOfferSearchableComponent
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectOfferSearchableComponent implements OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<Offer> {

  static nextId = 0;

  loading = false;
  focused = false;
  errorState = false;
  controlType = 'select-offer-searchable';
  describedBy = '';
  stateChanges = new Subject<void>();

  offers: Offer[] = [];
  filteredOffers: Offer[] = [];
  inputCtrl = new UntypedFormControl();

  icArrowDropDown = icArrowDropDown;

  @ViewChild('auto') auto: MatAutocomplete;
  @ViewChild('input') input: ElementRef<HTMLInputElement>;
  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger }) inputAutoComplete: MatAutocompleteTrigger;

  @Input() tabIndex: number;
  @Input() panelWidth: string | number;
  @Input() tooltip: string;

  @Input()
  get account() { return this._account; }
  set account(account: Account) {
    this._account = account;

    if (account) {
      this.store.select(hasUserPermission(Permission.OFFER_SEARCH)).pipe(untilDestroyed(this)).subscribe(permission => {
        if (permission) {
          this.offerService.search({
            filter: {
              accountId: account.accountId
            },
            orderType: 'DESC',
            orderBy: 'createdOn'
          }).subscribe(response => {
            this.offers = response.data.results;

            if (this.value) {
              const hasValue = this.offers?.find(offer => offer.offerId === this.value.offerId);
              if (!hasValue) {
                this.offers.push(this.value);
              }
            }

            this.filteredOffers = this.offers;

            this.cdr.detectChanges();
          });
        } else {
          this.offers = this.value ? [this.value] : [];
          this.filteredOffers = this.offers;
        }
      });
    }
  }
  private _account: Account;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    if (value) {
      this.inputCtrl.disable();
    } else {
      this.inputCtrl.enable();
    }

    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): Offer { return this._value; }
  set value(value: Offer) {
    this._value = value;
    this.onChange(value);
    this.stateChanges.next();
  }
  private _value: Offer;

  @Output() optionSelected = new EventEmitter<Offer>();

  @HostBinding('id') id = `select-offer-${SelectOfferSearchableComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  get shouldLabelFloat() { return this.focused || !this.empty; }

  get empty() { return !this.value; }

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private offerService: OfferService,
    private cdr: ChangeDetectorRef,
    private store: Store,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Material form field implementation
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.inputCtrl.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      filter(value => typeof value === 'string'),
      tap(() => {
        this.loading = true;
        this.filteredOffers = [];
        // this.value = null;
      }),
    ).subscribe((keyword) => {
      keyword = keyword.toLowerCase();
      this.filteredOffers = this.offers
        .filter(offer => offer.originCountry.name.toLowerCase().includes(keyword) ||
          offer.destinationCountry.name.toLowerCase().includes(keyword) ||
          offer.code.toLowerCase().includes(keyword));

      this.loading = false;
      cdr.detectChanges();
    });
  }

  displayName = (offer: Offer) => offer ? offer.code + ' ' + offer.originCountry.name + ' - ' + offer.destinationCountry.name : '';


  compareWith(one: Offer, two: Offer) {
    return one && two && one.offerId === two.offerId;
  }

  reset() {
    if (this.inputCtrl.pristine) {
      return;
    }

    this.value = null;
    this.inputCtrl.setValue(null);
    this.inputCtrl.markAsPristine();
    this.filteredOffers = this.offers;
  }

  openPanel(evt): void {
    evt.stopPropagation();
    this.inputAutoComplete.openPanel();
  }

  onSelect(event: MatAutocompleteSelectedEvent) {
    this.value = event.option.value;
    this.input.nativeElement?.blur();

    this.optionSelected.emit(this.value);
  }

  onBlur() {
    // On blur with nothing selected reset component
    if (this.ngControl.dirty && !this.auto.isOpen && !this.value) {
      this.reset();
    }
  }

  onClosed() {
    // On panel close with nothing selected reset component
    if (this.ngControl.dirty && !this.value) {
      this.reset();
    }
  }

  onChange = (_: any) => { };
  onTouched = () => { };
  onContainerClick = () => { };
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');
  writeValue = (value: Offer) => {
    this.value = value;
    this.inputCtrl.setValue(value);
  }

  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }
}
