import { Component, DoCheck, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldControl } from '@angular/material/form-field';

import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

import { Subject } from 'rxjs';

import { PaymentTermService, PaymentTerm, Organization } from '@core/api';

@Component({
  selector: 'net-select-payment-term',
  templateUrl: './select-payment-term.component.html',
  styleUrls: ['./select-payment-term.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: SelectPaymentTermComponent
    }
  ]
})
export class SelectPaymentTermComponent implements  OnInit, OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<PaymentTerm> {

  static nextId = 0;

  loading = false;
  focused = false;
  errorState = false;
  controlType = 'select-payment-term';
  describedBy = '';
  stateChanges = new Subject<void>();

  paymentTerms: PaymentTerm[] = [];
  selectCtrl = new UntypedFormControl();

  @Input() tabIndex;

  @Input()
  get serviceId() { return this._serviceId; }
  set serviceId(value: string) {
    this._serviceId = value;
  }
  private _serviceId: string;

  @Input()
  get organization() { return this._organization; }
  set organization(value: Organization) {
    this._organization = value;
  }
  private _organization: Organization;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    if (value) {
      this.selectCtrl.disable();
    } else {
      this.selectCtrl.enable();
    }

    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): PaymentTerm { return this.selectCtrl.value; }
  set value(value: PaymentTerm) {
    this.selectCtrl.setValue(value);
    this.onChange(value);
    this.stateChanges.next();
  }

  @HostBinding('id') id = `select-payment-term-${SelectPaymentTermComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  get shouldLabelFloat() { return this.focused || !this.empty; }

  get empty() { return !this.value; }

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private dialog: MatDialog,
    private paymentTermService: PaymentTermService,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Material form field implementation
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    this.loading = true;
    const filter = { organizationId: this.organization.organizationId, serviceId: this.serviceId };
    this.paymentTermService.search(filter).subscribe(response => {
      this.loading = false;
      this.paymentTerms = response.data;
    });
  }

  onSelectionChange() {
    this.onChange(this.value);
    this.stateChanges.next();
  }

  onChange = (_: any) => {};
  onTouched = () => {};
  compareWith = (one: PaymentTerm, two: PaymentTerm) => one && two && one.paymentTermId === two.paymentTermId;
  onContainerClick = () => {};
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');
  writeValue = (value: PaymentTerm) => this.value = value;
  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }
}
