import { AfterContentInit, Directive, ElementRef, HostBinding, Input, NgZone } from '@angular/core';
import SimpleBar from 'simplebar';

@Directive({
  selector: '[netScrollbar]',
  exportAs: 'netScrollbarDirective'
})
export class ScrollbarDirective implements AfterContentInit {
  @HostBinding('class') class = 'vex-scrollbar';
  @Input() options: Partial<any>;

  scrollbarRef: SimpleBar;

  constructor(private _element: ElementRef,
              private zone: NgZone) {
  }

  ngAfterContentInit() {
    this.zone.runOutsideAngular(() => {
      this.scrollbarRef = new SimpleBar(this._element.nativeElement, this.options);
    });
  }
}
