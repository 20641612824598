import { Component, Inject } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import icClose from '@iconify/icons-ic/twotone-close';

import { Hotkey } from '../services/hotkey.model';

@Component({
  selector: 'net-hotkey-dialog',
  templateUrl: './hotkey-dialog.component.html',
  styleUrls: ['./hotkey-dialog.component.scss'],
  providers: [TitleCasePipe]
})
export class HotkeyDialogComponent {

  icClose = icClose;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: Hotkey[],
    private titleCase: TitleCasePipe
  ) {}

  get hotkeys(): Hotkey[] {
    return this.data.map(item => ({
      keys: this.keyView(item.keys),
      description: item.description
    }));
  }

  keyView(keys: string): string {
    return keys
      .split('.')
      .map(key => this.titleCase.transform(key))
      .join(' + ');
  }

}
