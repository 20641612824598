import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { combineLatest } from 'rxjs';
import {
  ApiService,
  Contact,
  MailAddRangeRequest,
  MailAddRangeRequestDetail,
  CustomerEmailType,
  OpportunityMailRecipientsFormModel,
  OpportunityMailRecipientsModel,
  OpportunityMailRequest,
  OpportunityMailService,
  OpportunityMissingMailUserInputsFormModel,
  OpportunityParameter,
  OpportunitySendingMailTransferredDataModel,
  OpportunityService,
  SystemUser,
  SystemUserService,
  SystemUserStatusType,
  UserGroups
} from '@core/api';
import { TranslateService } from '@ngx-translate/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import icSave from '@iconify/icons-ic/twotone-save';
import icPdf from '@iconify/icons-ic/twotone-picture-as-pdf';
import icDownload from '@iconify/icons-mdi/download';
import icPdfOutLine from '@iconify/icons-mdi/file-pdf-outline';
import icWarning from '@iconify/icons-mdi/alert-circle';
import icClose from '@iconify/icons-ic/twotone-close';

import { FileLoadingTranslationService } from 'src/app/shared/services/translate/file-loading-translation.service';
import { createFileWindow } from 'src/app/shared/utils/open-file-url';
import { MatCheckboxChange } from '@angular/material/checkbox';

@UntilDestroy()
@Component({
  selector: 'net-opportunity-sending-mail-dialog',
  templateUrl: './opportunity-sending-mail-dialog.component.html',
  styleUrls: ['./opportunity-sending-mail-dialog.component.scss'],
})
export class OpportunitySendingMailDialogComponent implements OnInit {
  icSave = icSave;
  icPdf = icPdf;
  icDownload = icDownload;
  icPdfOutLine = icPdfOutLine;
  icWarning = icWarning;
  icClose = icClose;

  transferredData: OpportunitySendingMailTransferredDataModel;

  form = this.formBuilder.group({
    recipients: this.formBuilder.array([]),
    ccUsers: [[]],
    template: [[], Validators.required]
  });

  missingMailForm = this.formBuilder.group({
    recipients: this.formBuilder.array([]),
    ccUsers: this.formBuilder.array([])
  });

  contactUsers: Contact[];
  systemUsers: SystemUser[];
  userGroupsForCc: UserGroups[] = [
    {
      groupId: 'contacts',
      groupName: this.translate.instant('GENERAL.CONTACTS'),
      detail: []
    },
    {
      groupId: 'systemUsers',
      groupName: this.translate.instant('GENERAL.EMPLOYEES'),
      detail: []
    }
  ];
  opportunityParameters: OpportunityParameter[] = [];

  downloading = false;
  isLoading = true;

  @ViewChild('missingMailDialog', { static: true }) missingMailDialog: TemplateRef<HTMLElement>;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: OpportunitySendingMailTransferredDataModel,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<OpportunitySendingMailDialogComponent>,
    private missingMailDialogRef: MatDialogRef<any>,
    private formBuilder: UntypedFormBuilder,
    private fileLoadingTranslationService: FileLoadingTranslationService,
    private api: ApiService,
    private systemUserService: SystemUserService,
    private translate: TranslateService,
    private opportunityService: OpportunityService,
    private opportunityMailService: OpportunityMailService
  ) {
    this.transferredData = data;
    this.opportunityParameters = data.opportunityParameters;
  }

  ngOnInit(): void {
    this.form.get('template').patchValue(this.opportunityParameters[0].opportunityParameterId);

    this.getUserGroupsForCc();
  }

  getUserGroupsForCc() {
    combineLatest([
      this.api.contact.search({
        filter: {
          customerId: this.transferredData.account.customerId
        }
      }),
      this.systemUserService.search({
        filter: {
          salesOrganizationId: this.transferredData.account.salesOrganizationId
        }
      })
    ]).pipe(untilDestroyed(this)).subscribe(([_contactUsers, _systemUsers]) => {
      const contactsFound = this.userGroupsForCc.find(el => el.groupId === 'contacts');
      const systemUsersFound = this.userGroupsForCc.find(el => el.groupId === 'systemUsers');

      this.contactUsers = _contactUsers.data.results.filter(contact => contact.enabled);
      this.systemUsers = _systemUsers.data.results.filter(systemUser => systemUser.systemUserStatusId === SystemUserStatusType.ACTIVE)
        .sort((a, b) => a.firstName.localeCompare(b.firstName));

      this.contactUsers.forEach(user => {
        const indexFound = this.transferredData.contacts.contacts.findIndex(item => item.contactId === user.contactId);

        if (indexFound === -1) {
          contactsFound.detail.push({
            userId: user.contactId,
            userName: `${user.firstName} ${user.lastName}`,
            emailCount: user.emails?.length
          });
        }
      });

      this.systemUsers.forEach(user => {
        systemUsersFound.detail.push({
          userId: user.systemUserId,
          userName: `${user.firstName} ${user.lastName}`
        });
      });

      this.findOpportunityUsers(this.transferredData);
    });
  }

  findOpportunityUsers(transferredData: OpportunitySendingMailTransferredDataModel) {
    this.getRecipientsForm().clear();

    if (transferredData.contacts.contacts.length > 0) {
      transferredData.contacts.contacts.forEach(contact => {
        const contactFound = this.contactUsers.find(el => el.contactId === contact.contactId);

        this.getRecipientsForm().push(
          this.createRecipientsForm({
            contactId: contact.contactId,
            name: `${contact.firstName} ${contact.lastName}`,
            checked: true,
            emailCount: contactFound.emails.length
          })
        );
      });
    }

    if (transferredData.contacts.systemUsers.length > 0) {
      transferredData.contacts.systemUsers.forEach(systemUser => {
        this.getRecipientsForm().push(
          this.createRecipientsForm({
            systemUserId: systemUser.systemUserId,
            name: `${systemUser.firstName} ${systemUser.lastName}`,
            checked: true
          })
        );
      });
    }

    this.isLoading = false;
  }

  getRecipientsForm(): FormArray<AbstractControl<OpportunityMailRecipientsFormModel>> {
    return this.form.get('recipients') as FormArray<AbstractControl<OpportunityMailRecipientsFormModel>>;
  }

  getMissingMailFormArray(name: string): FormArray<AbstractControl<OpportunityMissingMailUserInputsFormModel>> {
    return this.missingMailForm.get(name) as FormArray<AbstractControl<OpportunityMissingMailUserInputsFormModel>>;
  }

  createRecipientsForm(item: OpportunityMailRecipientsFormModel): UntypedFormGroup {
    return this.formBuilder.group({
      contactId: [item ? item.contactId : null],
      systemUserId: [item ? item.systemUserId : null],
      name: [item ? item.name : null],
      checked: [item ? item.checked : null],
      emailCount: [item ? item.emailCount : null]
    });
  }

  createMissingMailFormControls(item: OpportunityMissingMailUserInputsFormModel): UntypedFormGroup {
    return this.formBuilder.group({
      contactId: [item.contactId],
      displayName: [item.displayName],
      checked: [item.checked],
      email: [{ value: null, disabled: !item.checked }, item.checked ? [Validators.required, Validators.email] : null],
      ccFlag: [item.ccFlag]
    });
  }

  openMissingMailDialog() {
    this.getMissingMailFormArray('recipients').clear();
    this.getMissingMailFormArray('ccUsers').clear();

    const recipients: OpportunityMailRecipientsFormModel[] = this.getRecipientsForm().value;

    const chosenCcUsers: string[] = this.form.get('ccUsers').value;
    const mappedCcUsers: OpportunityMailRecipientsFormModel[] = this.userGroupsForCc.find(group => group.groupId === 'contacts').detail.filter(item =>
      chosenCcUsers.includes(item.userId)
    ).map(item =>
      ({
        contactId: item.userId,
        name: item.userName,
        emailCount: item.emailCount,
        checked: true,
        ccFlag: true
      })
    );

    const combinedRecipients = [...recipients, ...mappedCcUsers];
    combinedRecipients.filter(recipient => recipient.emailCount === 0).forEach(filtered => {
      if (filtered.ccFlag) {
        this.getMissingMailFormArray('ccUsers').push(
          this.createMissingMailFormControls({
            contactId: filtered.contactId,
            displayName: filtered.name,
            checked: filtered.checked,
            ccFlag: filtered.ccFlag
          })
        );
      } else {
        this.getMissingMailFormArray('recipients').push(
          this.createMissingMailFormControls({
            contactId: filtered.contactId,
            displayName: filtered.name,
            checked: filtered.checked,
            ccFlag: filtered.ccFlag
          })
        );
      }
    });

    this.missingMailDialogRef = this.dialog.open(this.missingMailDialog, { autoFocus: false });
  }

  onChangeChecked(status: MatCheckboxChange, index: number, type: string, recipientType?: string) {
    switch (type) {
      case 'recipientsForm':
        // this.controlIsAllUnchecked(type);
        break;

      case 'missingMailForm':
        const recipientControlFound = this.getRecipientsForm().controls.find(recipient =>
          recipient.get('contactId').value === this.getMissingMailFormArray(recipientType).at(index).get('contactId').value
        );

        if (recipientControlFound) {
          recipientControlFound.get('checked').setValue(status.checked);
        } else {
          const ccUsersFormControl = this.form.get('ccUsers');
          const currentCcUsers: string[] = ccUsersFormControl.value;

          if (status.checked) {
            ccUsersFormControl.setValue([...currentCcUsers, this.getMissingMailFormArray(recipientType).at(index).get('contactId').value]);
          } else {
            const updatedCcUsers = currentCcUsers.filter(userId => userId !== this.getMissingMailFormArray(recipientType).at(index).get('contactId').value);
            ccUsersFormControl.setValue(updatedCcUsers);
          }
        }

        const missingMailControlFound = this.getMissingMailFormArray(recipientType).at(index).get('email');

        if (status.checked) {
          missingMailControlFound.enable();
          missingMailControlFound.setValidators([Validators.required, Validators.email]);
        } else {
          missingMailControlFound.disable();
          missingMailControlFound.setValue(null);
        }

        this.controlIsAllUnchecked(type);
        break;
    }
  }

  onSave(value: string) {
    switch (value) {
      case 'download':
      case 'preview':
        this.download(value);
        break;

      case 'missingMail':
        this.submitMissingMail();
        break;
    }
  }

  closeAlertDialog(dialog?: string, submit = false, cancel = false) {
    switch (dialog) {
      case 'missingMail':
        this.missingMailDialogRef.close();
        break;

      default:
        const missingMailIndex = this.getRecipientsForm().value.findIndex(recipient =>
          recipient.checked && recipient.emailCount === 0
        );

        const ccUsers: string[] = this.form.get('ccUsers').value;
        const missingCcMailIndex = this.userGroupsForCc.find(group => group.groupId === 'contacts').detail.findIndex(user =>
          ccUsers.includes(user.userId) && user.emailCount === 0
        );

        if ((missingMailIndex > -1 || missingCcMailIndex > -1) && !cancel) {
          this.openMissingMailDialog();
        } else {
          this.dialogRef.close(submit ? this.getRequestForSubmit() : null);
        }
        break;
    }
  }

  controlIsAllUnchecked(formType: string) {
    let isAllUnchecked = false;

    switch (formType) {
      case 'recipientsForm':
        const recipients = this.getRecipientsForm().value;
        isAllUnchecked = recipients.every(recipient => !recipient.checked);
        break;

      case 'missingMailForm':
        const missingMailRecipients = this.getMissingMailFormArray('recipients').value;
        const missingMailCcUsers = this.getMissingMailFormArray('ccUsers').value;
        isAllUnchecked = [...missingMailRecipients, ...missingMailCcUsers].every(recipient => !recipient.checked);

        if (isAllUnchecked) {
          this.missingMailForm.markAsTouched();
          this.missingMailForm.setErrors({ incorrect: true });
        }
        break;
    }

    return isAllUnchecked;
  }

  private getRequestForSubmit() {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      const recipients: OpportunityMailRecipientsFormModel[] = this.form.get('recipients').value;
      const mappedRecipients: OpportunityMailRecipientsModel[] = recipients.filter(item => item.checked).map(filteredItem => {
        const obj: OpportunityMailRecipientsModel = {
          ccFlag: false
        };

        if (filteredItem.contactId) {
          obj.contactId = filteredItem.contactId;
        } else {
          obj.systemUserId = filteredItem.systemUserId;
        }

        return obj;
      });

      const ccUsers: string[] = this.form.get('ccUsers').value;
      const mappedCcUsers: OpportunityMailRecipientsModel[] = ccUsers.map(item => {
        const obj: OpportunityMailRecipientsModel = {
          ccFlag: true
        };

        const systemUserFound = this.systemUsers.find(user => user.systemUserId === item);

        if (systemUserFound) {
          obj.systemUserId = item;
        } else {
          obj.contactId = item;
        }

        return obj;
      });

      const request: OpportunityMailRequest = {
        opportunityId: null,
        templateId: this.form.get('template').value,
        mailRecipients: [...mappedRecipients, ...mappedCcUsers]
      };

      return request;
    }
  }

  private download(action: string) {
    this.downloading = true;
    this.fileLoadingTranslationService.setTranslations();
    // Create new file window
    const fileWindow = createFileWindow();
    // Helper for start activity download
    const download = () => {
      this.opportunityService
        .download(
          this.transferredData.opportunity,
          action,
          fileWindow,
          this.form.get('template').value
        )
        .catch(() => fileWindow.close())
        .finally(() => {
          this.downloading = false;
          this.fileLoadingTranslationService.removeTranslations();
        });
    };

    if (this.transferredData.opportunity) {
      download();
      return;
    }
  }

  private submitMissingMail() {
    const missingMailRecipientsFormValue = this.getMissingMailFormArray('recipients').value;
    const missingMailCcUsersFormValue = this.getMissingMailFormArray('ccUsers').value;
    const emails: MailAddRangeRequestDetail[] = [...missingMailRecipientsFormValue, ...missingMailCcUsersFormValue].filter(recipient => recipient.checked)
      .map(recipient =>
        ({
          contactId: recipient.contactId,
          mailName: recipient.email,
          emailTypeId: CustomerEmailType.CORPORATE
        })
      );

    const request: MailAddRangeRequest = {
      emails
    };

    this.opportunityMailService.updateMail(request).subscribe(response => {
      const savedEmails = response.data;

      savedEmails.forEach(item => {
        const savedMailIndexFound = this.getRecipientsForm().value.findIndex(recipient =>
          recipient.contactId === item.contactId
        );

        this.getRecipientsForm().at(savedMailIndexFound).get('emailCount').setValue(1);

        const savedCcIndexFound = this.userGroupsForCc.find(group => group.groupId === 'contacts').detail.find(user =>
          user.userId === item.contactId
        );

        if (savedCcIndexFound) {
          savedCcIndexFound.emailCount = 1;
        }

        this.closeAlertDialog('missingMail');
      });
    });
  }
}
