import { createFeatureSelector, createSelector } from '@ngrx/store';

import { SalesOrganizationSettingState } from '@core/store';
import { SalesOrganizationActivitySettingType, SalesOrganizationAdvancedSettingType } from '@core/api';

export const selectSettingsState = createFeatureSelector('salesOrganizationSetting');

export const getSalesOrganizationSettings = createSelector(
  selectSettingsState,
  (state: SalesOrganizationSettingState) => state.salesOrganizationSettings
);

export const getSalesOrganizationSettingError = createSelector(
  selectSettingsState,
  (state: SalesOrganizationSettingState) => ({ errorCode: state.errorCode, errorMessage: state.errorMessage })
);

export const getSalesOrganizationSettingIsLoading = createSelector(
  selectSettingsState,
  (state: SalesOrganizationSettingState) => state.isLoading
);

export const getSalesOrganizationSettingValue = (key: SalesOrganizationAdvancedSettingType | SalesOrganizationActivitySettingType, salesOrganizationId: string) => createSelector(
  selectSettingsState,
  (state: SalesOrganizationSettingState) => {

    const salesOrganizationSetting = state.salesOrganizationSettings?.find(x => x.salesOrganizationId === salesOrganizationId);

    if (!salesOrganizationSetting) {
      return false;
    }

    return salesOrganizationSetting[key] ?? false;
  },
);

export const isOpenSwitchSalesOrganizations = (key: string) => createSelector(
  selectSettingsState,
  (state: SalesOrganizationSettingState) => {

    if (!state.salesOrganizationSettings || state.salesOrganizationSettings.length === 0) {
      return false;
    }

    const keyValues = state.salesOrganizationSettings?.map(x => {
      if (x[key]) {
        return x[key];
      }
    });

    return keyValues?.find(x => x) ?? false;
  });
