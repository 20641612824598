import { DynamicFieldValue } from '@core/api';

export function processDynamicFieldValues(fields): DynamicFieldValue[] {
  if (false === Array.isArray(fields)) {
    return [];
  }

  return fields.map(field => {

    if (field?.nameValues) {
      field = {
        ...field,
        multipleValueFlag: true
      };
    } else if (field?.nameValue) {
      field = {
        ...field,
        multipleValueFlag: false
      };
    }

    return field;
  });
}
