
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

import {
  Response,
  NotificationTemplate,
  FilterRequest,
} from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class NotificationTemplateService {

  constructor(private http: HttpClient) { }

  list(request: FilterRequest): Observable<Response<NotificationTemplate[]>> {
    return this.http.post<Response<NotificationTemplate[]>>(environment.apiUrl + '/api/NotificationIcon/List', request);
  }
}
