import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { BaseResponse, FilterRequest, PagedResponse, Response } from '@core/api';
import { CustomerExperienceMainCategory, CustomerExperienceMainCategoryRequest } from './customer-experience-main-categories.model';

@Injectable({
    providedIn: 'root'
})
export class CustomerExperienceMainCategoriesService {
    constructor(private http: HttpClient) {
    }

    get(request: string) {
        const headers = new HttpHeaders({ 'content-type': 'application/json' });
        return this.http.post<Response<CustomerExperienceMainCategory>>(environment.apiUrl + '/api/CxMainCategory/Get', JSON.stringify(request), { headers });
    }

    insert(request: CustomerExperienceMainCategoryRequest) {
        return this.http.post<Response<CustomerExperienceMainCategory>>(environment.apiUrl + '/api/CxMainCategory/Insert', request);
    }

    list(request: FilterRequest = {}) {
        return this.http.post<Response<CustomerExperienceMainCategory[]>>(environment.apiUrl + '/api/CxMainCategory/List', request);
    }

    search(request: any) {
        return this.http.post<PagedResponse<CustomerExperienceMainCategory>>(environment.apiUrl + '/api/CxMainCategory/Search', request);
    }

    setOrderBy(request: CustomerExperienceMainCategoryRequest) {
        return this.http.post<Response<CustomerExperienceMainCategory[]>>(environment.apiUrl + '/api/CxMainCategory/SetOrderBy', request);
    }

    setStatus(request: { cxMainCategoryId: string, activeFlag: boolean }) {
        return this.http.post<Response<any>>(environment.apiUrl + '/api/CxMainCategory/SetStatus', request);
    }

    update(request: CustomerExperienceMainCategoryRequest) {
        return this.http.post<Response<CustomerExperienceMainCategory>>(environment.apiUrl + '/api/CxMainCategory/Update', request);
    }

    delete(request: CustomerExperienceMainCategoryRequest) {
        return this.http.post<BaseResponse>(environment.apiUrl + '/api/CxMainCategory/Delete', request);
    }

}
