import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { BoardFavory, BoardFavoryRequest, Response } from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class BoardFavoryService {

  constructor(private http: HttpClient) { }

  setFavory(request: BoardFavoryRequest): Observable<Response<BoardFavory>> {
    return this.http.post<Response<BoardFavory>>(environment.apiUrl + '/api/BoardFavory/SetFavory', request);
  }

  unSetFavory(request: BoardFavoryRequest): Observable<Response<BoardFavory>> {
    return this.http.post<Response<BoardFavory>>(environment.apiUrl + '/api/BoardFavory/UnSetFavory', request);
  }
}
