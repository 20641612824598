import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotTranslatedService {
  notTranslated(key: string) {
    console.info('Not translated', key);
  }
}
