<div class="box-container" [class.disabled]="disabled">
  <div class="box card" [class.active]="this.value === service.LAND" [class.disabled]="isDisabled(service.LAND)"
    (click)="onClick(service.LAND)">
    <ic-icon [icon]="icLocalShipping"></ic-icon>
    <p>{{'OFFER.LAND' | translate}}</p>
  </div>

  <div class="box card" [class.active]="this.value === service.SEA" [class.disabled]="isDisabled(service.SEA)"
    (click)="onClick(service.SEA)">
    <ic-icon [icon]="icDirectionsBoat"></ic-icon>
    <p>{{'OFFER.SEA' | translate}}</p>
  </div>

  <div class="box card" [class.active]="this.value === service.AIR" [class.disabled]="isDisabled(service.AIR)"
    (click)="onClick(service.AIR)">
    <ic-icon [icon]="icLocalAirport"></ic-icon>
    <p>{{'OFFER.AIR' | translate}}</p>
  </div>
</div>