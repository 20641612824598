
import { of } from 'rxjs';
import { catchError, mergeMap, switchMap, tap } from 'rxjs/operators';
import moment from 'moment';

import { Injectable } from '@angular/core';
import { AuthService } from '@core/auth/auth.service';
import { AuthActionTypes, Login, LoginError, LoginSuccess } from '@core/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

@Injectable()
export class AuthEffects {
    constructor(
        private auth: AuthService,
        private actions$: Actions,
    ) { }

    login$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActionTypes.LOGIN),
        mergeMap((request: Login) => this.auth.login(request.payload)),
        tap(response => {
            this.auth.persistToken(response);
        }),
        switchMap(response => {
            if (response.success) {
                const locale = response.data.user.culture;
                moment.locale(locale);

                return of(new LoginSuccess(response));
            } else {
                return of(new LoginError(response as any));
            }
        }),
        catchError((error) => of(new LoginError(error)))
    ));
}

