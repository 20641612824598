export enum MatHeaderFilterType {
  date,
  search,
  select,
  minMax,
  oneSelect,
  paginationSelect,
  extendedDate,
  oneSelectWithoutAutoComplete
}

export enum DateRangeType {
  next,
  prev
}
