import { Component, OnDestroy, OnInit } from '@angular/core';
import { SystemSettingType } from '@core/api';
import { getSystemSettingValue } from '@core/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Style, StyleService } from '../services/style.service';

@UntilDestroy()
@Component({
  selector: 'net-guest-layout',
  templateUrl: './guest-layout.component.html',
  styleUrls: ['./guest-layout.component.scss']
})
export class GuestLayoutComponent implements OnInit, OnDestroy {
  templateFlag = true;
  bgVideo: any;
  bgImage: any;

  constructor(
    private style: StyleService,
    private store: Store
  ) {
    this.store.select(getSystemSettingValue(SystemSettingType.LOGIN_VIDEO)).pipe(untilDestroyed(this)).subscribe(result => {
      this.bgVideo = result;
    });

    this.store.select(getSystemSettingValue(SystemSettingType.LOGIN_IMAGE)).pipe(untilDestroyed(this)).subscribe(result => {
      this.bgImage = result;
    });
  }

  ngOnInit() {
    this.style.setStyle(this.templateFlag ? Style.light : Style.dark);
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy() { }
}
