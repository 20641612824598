import {
  Component, OnInit, OnDestroy, DoCheck, Input,
  HostBinding, ElementRef, Optional, Self, ChangeDetectorRef
} from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { FocusMonitor } from '@angular/cdk/a11y';
import { Guid, SalesRouteDefinition } from '@core/api';
import { debounceTime, pairwise, startWith } from 'rxjs/operators';

@Component({
  selector: 'net-multi-select-sales-route',
  templateUrl: './multi-select-sales-route.component.html',
  styleUrls: ['./multi-select-sales-route.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: MultiSelectSalesRouteComponent
    }
  ]
})
export class MultiSelectSalesRouteComponent implements OnInit, OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<string[]> {

  static nextId = 0;

  loading = false;
  focused = false;
  errorState = false;
  controlType = 'multi-select-report-sales-route';
  describedBy = '';
  stateChanges = new Subject<void>();
  searchText = '';
  selectedValue: string;

  selectCtrl = new UntypedFormControl();
  filteredSalesRoutes: SalesRouteDefinition[];
  changedSalesId: string;
  clickSelectAll: boolean;

  Guid = Guid;

  @Input() hasUser = false;

  @Input() tabIndex;

  @Input() hasUndefinedOption = false;

  @Input()
  get salesRoutes(): SalesRouteDefinition[] { return this._salesRoutes; }
  set salesRoutes(value: SalesRouteDefinition[]) {
    this._salesRoutes = value;
    this.filteredSalesRoutes = value;
    this.cdr.detectChanges();
  }
  private _salesRoutes: SalesRouteDefinition[];

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    if (value) {
      this.selectCtrl.disable();
    } else {
      this.selectCtrl.enable();
    }
    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get showMatTooltip(): boolean { return this._showMatTooltip; }
  set showMatTooltip(value: boolean) {
    this._showMatTooltip = value;
    this.stateChanges.next();
  }
  private _showMatTooltip = false;

  @Input()
  get value(): string[] { return this.selectCtrl.value; }
  set value(value: string[]) {
    this.selectCtrl.setValue(value);
    this.onChange(value);
    this.stateChanges.next();
  }

  @HostBinding('id') id = `multi-select-report-sales-route-${MultiSelectSalesRouteComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  get shouldLabelFloat() { return this.focused || !this.empty; }

  get empty() { return !this.value; }

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private cdr: ChangeDetectorRef,
    @Optional() @Self() public ngControl: NgControl
  ) {
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.selectCtrl.valueChanges
      .pipe(startWith(null as string), pairwise(), debounceTime(1))
      .subscribe(([prev, next]: [string[], string[]]) => {
        if (prev?.length > 0 && !this.clickSelectAll) {
          const changedValue = next?.find((val) => val === this.changedSalesId);
          if (changedValue) {
            const prevChangedValue = prev.findIndex((val) => val === changedValue);
            if (prevChangedValue === -1) {
              prev.push(changedValue);
              this.value = prev;
              this.selectCtrl.patchValue(this.value);
              this.onSelectionChange();
            }
          } else {
            const prevChangedValue = prev?.findIndex((val) => val === this.changedSalesId);
            if (prevChangedValue !== -1) {
              prev.splice(prevChangedValue, 1);
              this.value = prev;
              this.selectCtrl.patchValue(this.value);
              this.onSelectionChange();
            }
          }
        }
      });
  }

  onSelectionChange() {
    this.clickSelectAll = false;
    this.onChange(this.value);
    this.selectionChange(this.value);
    this.stateChanges.next();
  }

  openedChange() {
    const value = '';
    this.onKey(value);
  }

  selectAll() {
    this.clickSelectAll = true;
    const values = this.filteredSalesRoutes.map(route => route.salesRouteDefinitionId);
    const newValues = [];
    values.forEach((val) => {
      if (!this.value?.includes(val)) {
        newValues.push(val);
      }
    });
    this.value = this.value ? [...this.value, ...newValues] : newValues;
    this.selectCtrl.patchValue(this.value);
  }

  deselectAll() {
    const searchText = '';
    this.onKey(searchText);
    this.value = [];
    this.selectCtrl.patchValue(this.value);
    this.onSelectionChange();
    this.selectedValue = '';
  }

  onKey(value) {
    this.searchText = value;
    this.filteredSalesRoutes = this.search(value);

    if (this.selectCtrl.value?.length === 1) {
      const selectedRoute = this.salesRoutes.find((user) => user.salesRouteDefinitionId === this.value[0]);
      this.selectedValue = selectedRoute.name;
    }
  }

  search(value: string) {
    const filter = value.toLowerCase();
    return this.hasUser ? this.salesRoutes
      .filter(option => `${option.name} ${option.assignedUser?.firstName} ${option.assignedUser?.lastName}`.toLowerCase().includes(filter)) :
      this.salesRoutes.filter(option => option.name.toLowerCase().includes(filter));
  }

  changeActiveItem(salesRouteId: string) {
    this.changedSalesId = salesRouteId;
  }

  onChange = (_: any) => { };
  selectionChange = (_: any) => { };
  onTouched = () => { };
  onContainerClick = () => { };
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');
  writeValue = (value: string[]) => {
    this.value = value;
    this.selectCtrl.setValue(value);
  }

  getToolTip() {
    if (!this.value || this.value.length < 2) {
      return null;
    }

    return this.filteredSalesRoutes.filter(s => this.value.includes(s.salesRouteDefinitionId))
      .map(s => `${s.name} ${this.hasUser ? (' - ' + s.assignedUser?.firstName + ' ' + s.assignedUser?.lastName) : ''}`).join('\n');
  }

  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }
}
