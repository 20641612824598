import { ChangeDetectorRef, Directive, HostBinding, OnDestroy } from '@angular/core';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ConfigService } from '../../layout/services/config.service';

@UntilDestroy()
@Directive({
  selector: '[netContainer]'
})
export class ContainerDirective implements OnDestroy {

  @HostBinding('class.container') enabled: boolean;

  constructor(private configService: ConfigService,
              private cd: ChangeDetectorRef) {
    this.configService.config$.pipe(
      map(config => config.boxed),
      distinctUntilChanged(),
      untilDestroyed(this)
    ).subscribe(boxed => {
      this.enabled = boxed;
      this.cd.markForCheck();
    });
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy(): void {}
}
