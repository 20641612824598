import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import { Response, GoodsType, GoodsTypeSearchRequest } from '@core/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GoodsTypeService {
  constructor(private http: HttpClient) { }

  search(request: GoodsTypeSearchRequest): Observable<Response<GoodsType[]>> {
    return this.http.post<Response<GoodsType[]>>(environment.apiUrl + '/api/GoodsType/Search', request);
  }
}
