import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, UntypedFormBuilder, Validators } from '@angular/forms';

import { CardRequest } from '@core/api';

import icSave from '@iconify/icons-ic/twotone-save';
import icClose from '@iconify/icons-ic/twotone-close';
import icWarning from '@iconify/icons-ic/twotone-warning';

@Component({
  selector: 'net-board-card-form',
  templateUrl: './board-card-form.component.html',
  styleUrls: ['./board-card-form.component.scss'],
})
export class BoardCardFormComponent {
  icSave = icSave;
  icClose = icClose;
  icWarning = icWarning;

  form = this.formBuilder.group({
    board: [null, Validators.required],
    section: [null, Validators.required],
    name: [null, Validators.required],
  });

  isPage = false;
  isDialog = false;

  @Input() loading = false;
  @Output() save = new EventEmitter<CardRequest>();

  @Input()
  set mode(value: 'page' | 'dialog') {
    if (value === 'page') {
      this.isPage = true;
    }

    if (value === 'dialog') {
      this.isDialog = true;
    }
  }

  constructor(private formBuilder: UntypedFormBuilder) { }

  onSubmit() {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }

    this.save.emit({
      boardId: this.form.get('board').value?.boardId,
      boardInfoId: this.form.get('section').value?.boardInfoId,
      name: this.form.get('name').value,
    });
  }
}
