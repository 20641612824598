import { Account, Response } from '@core/api';
import { Action } from '@ngrx/store';

export enum AccountActionTypes {
    GET_ACCOUNT = '[Lead Detail] GET_ACCOUNT',
    GET_ACCOUNT_SUCCESS = '[Lead Detail] GET_ACCOUNT_SUCCESS',
    GET_ACCOUNT_ERROR = '[Lead Detail] GET_ACCOUNT_ERROR',
    SET_ACCOUNT_AS_NULL = '[Lead Detail] SET_ACCOUNT_AS_NULL'
}

export class GetAccount implements Action {
    readonly type = AccountActionTypes.GET_ACCOUNT;
    constructor(public payload: string) { }
}

export class GetAccountSuccess implements Action {
    readonly type = AccountActionTypes.GET_ACCOUNT_SUCCESS;
    constructor(public payload: Account) { }
}
export class GetAccountError implements Action {
    readonly type = AccountActionTypes.GET_ACCOUNT_ERROR;
    constructor(public payload: Response<null>) { }
}

export class SetAccountAsNull implements Action {
    readonly type = AccountActionTypes.SET_ACCOUNT_AS_NULL;
}

export type AccountActions = GetAccount | GetAccountSuccess | GetAccountError | SetAccountAsNull;
