import { AbstractControl, ValidationErrors } from '@angular/forms';

import { InputActivityAssignment } from './input-activity-assignment.component.model';

export class InputActivityAssignmentValidators {
  static required(control: AbstractControl): ValidationErrors | null {
    let required = { required: true };

    const value: InputActivityAssignment[] = control.value;
    if (!value) {
      return null;
    }

    // Check each items in given assignment list, allow if each rows do not empty
    const allValid = value.map(item => [
      !!item.description && (!item.assignedUser?.systemUserId || !item.dueDate)
    ].every(isEmpty => false === isEmpty)
    ).every(isValid => true === isValid);

    if (allValid) {
      required = null;
    }

    return required;
  }
}
