import { Injectable } from '@angular/core';
import { Board, BoardReportService } from '@core/api';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { BoardDetailReportActionTypes, GetBoardWithBoardInfos, SetBoardWithBoardInfosError, SetBoardWithBoardInfosSuccess } from '@core/store';

@Injectable()
export class BoardDetailReportEffects {
    constructor(
        private boardReportService: BoardReportService,
        private actions$: Actions
    ) { }

    getReportBoard$ = createEffect(() => this.actions$.pipe(
        ofType(BoardDetailReportActionTypes.GET_BOARD_REPORT_WITH_BOARD_INFOS),
        mergeMap((action: GetBoardWithBoardInfos) => this.boardReportService.getBoardWithBoardInfos(action.boarId)),
        switchMap((board: Board) => of(new SetBoardWithBoardInfosSuccess(board.boardInfos))),
        catchError((error) => of(new SetBoardWithBoardInfosError(error)))
    ));
}
