import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseResponse, FilterRequest, PagedResponse, PagedResponseData, Response, SystemUser } from '@core/api';
import { environment } from '@env/environment';
import {
  CustomDashboard,
  CustomDashboardInsertRequest,
  CustomDashboardSearch,
  CustomDashboardSearchRequest,
  CustomDashboardTemplate,
  CustomDashboardTypeList,
  CustomDashboardUpdateOrdersRequest,
  CustomDashboardUpdateRequest,
  CustomDashboardUpdateUsersRequest,
  User,
  GetUrlValues
} from '@core/api/custom-dashboard-management/custom-dashboard-management.model';

@Injectable({
  providedIn: 'root'
})
export class CustomDashboardManagementService {

  constructor(private http: HttpClient) {
  }

  list(userId?: string) {
    return this.http.get<Response<CustomDashboard[]>>(`${environment.apiUrl}/api/CustomDashboard/List?userId=${userId}`);
  }

  updateUsers(request: CustomDashboardUpdateUsersRequest) {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<PagedResponse<CustomDashboardSearch>>(environment.apiUrl + '/api/CustomDashboard/UpdateUsers', request, { headers });
  }

  updateOrders(request: CustomDashboardUpdateOrdersRequest) {
    return this.http.post<BaseResponse>(environment.apiUrl + '/api/CustomDashboard/UpdateOrder', request);
  }

  updateStatus(request: string) {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<PagedResponseData<any>>>(environment.apiUrl + '/api/CustomDashboard/UpdateStatus', JSON.stringify(request), { headers });
  }

  update(request: CustomDashboardUpdateRequest) {
    return this.http.post<Response<PagedResponseData<CustomDashboardSearch>>>(environment.apiUrl + '/api/CustomDashboard/Update', request);
  }

  get(request: string) {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<CustomDashboardSearch>>(environment.apiUrl + '/api/CustomDashboard/Get', JSON.stringify(request), { headers });
  }

  insert(request: CustomDashboardInsertRequest) {
    return this.http.post<Response<PagedResponseData<CustomDashboardSearch>>>(environment.apiUrl + '/api/CustomDashboard/Insert', request);
  }

  listUsers() {
    return this.http.post<Response<User[]>>(environment.apiUrl + '/api/CustomDashboard/ListUsers', {});
  }

  listType() {
    return this.http.post<Response<PagedResponseData<CustomDashboardTypeList>>>(environment.apiUrl + '/api/CustomDashboardType/List', {});
  }

  listTemplates() {
    return this.http.post<Response<CustomDashboardTemplate[]>>(environment.apiUrl + '/api/CustomDashboardTemplate/List', {});
  }

  getUrl(dashboardId: string) {
    const headers = new HttpHeaders({ 'content-type': 'text/plain', responseType: 'json' });
    return this.http.get<GetUrlValues>(environment.apiUrl + '/api/CustomDashboard/GetUrl/' + dashboardId, { headers });
  }

  search(request: CustomDashboardSearchRequest) {
    return this.http.post<Response<PagedResponseData<CustomDashboardSearch>>>(environment.apiUrl + '/api/CustomDashboard/Search', request);
  }

  userSearch(filter?: FilterRequest) {

    return this.http.post<PagedResponse<SystemUser>>(environment.apiUrl + '/api/SystemUser/UserSearch', filter);
  }

  delete(request: string) {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<PagedResponse<SystemUser>>(environment.apiUrl + '/api/CustomDashboard/Delete', JSON.stringify(request), { headers });
  }

  setFavorite(customDashboardId: string) {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<CustomDashboard>>(environment.apiUrl + '/api/CustomDashboard/SetFavorite', JSON.stringify(customDashboardId), { headers });
  }

}
