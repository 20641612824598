import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

import { AuthService } from '@core/auth/auth.service';
import { hasUserPermission } from '@core/store';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap, } from 'rxjs/operators';
import { Permission } from '../auth.enum';

@Injectable({
  providedIn: 'root'
})
export class UserPermissionGuard implements CanActivate {

  constructor(private store: Store, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean>|boolean {

    let hasPermission: boolean;

    // If role not defined for route, skip verification
    if (!route.data.permission) {
      return true;
    }

    this.store.select(hasUserPermission(route.data.permission)).pipe(
      tap(permission => {
        hasPermission = permission;
        const extras = this.router.getCurrentNavigation()?.extras;
        if (!permission) {
          // if the navigation was made from the login page, redirects to the '/notification' page
          if (extras?.state?.fromLogin || (extras?.replaceUrl && route.data.permission === Permission.HOME_DASHBOARD)) {
            this.router.navigate(['/notification']);
          } else {
            this.router.navigate(['error-403']);
          }
        }
      })
    ).subscribe();

    return hasPermission;
  }
}
