import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

import {
  BoardCustomField,
  Response,
  PagedResponse,
  BoardCustomFieldOrderBy,
  BoardCustomFieldRequest,
  BoardCustomFieldSearchRequest,
  DynamicFieldFilterRequest,
  PagedResponseData,
  DynamicField,
  BoardInfoCardsSearchRequest,
  BoardDynamicFieldReport,
  DynamicFieldUpdateOrderItem,
  BaseResponse,
  DynamicFieldPicklist,
} from '@core/api';

@Injectable({
  providedIn: 'root',
})
export class BoardCustomFieldService {
  constructor(private http: HttpClient) { }

  insert(request: BoardCustomFieldRequest): Observable<Response<BoardCustomField>> {
    return this.http.post<Response<BoardCustomField>>(
      environment.apiUrl + '/api/BoardCustomField/Insert',
      request
    );
  }

  update(request: BoardCustomFieldRequest): Observable<Response<BoardCustomField>> {
    return this.http.post<Response<BoardCustomField>>(
      environment.apiUrl + '/api/BoardCustomField/Update',
      request
    );
  }

  updateStatusBoard(boardId: string, dynamicFieldId: string): Observable<Response<DynamicField>> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<DynamicField>>(environment.apiUrl + `/api/BoardDynamicField/UpdateStatus/${boardId}`,
      JSON.stringify(dynamicFieldId), { headers });
  }


  delete(request: BoardCustomFieldRequest): Observable<Response<BoardCustomField>> {
    return this.http.post<Response<BoardCustomField>>(
      environment.apiUrl + '/api/BoardCustomField/Delete',
      request
    );
  }

  updateOrder(boardId: string, request: DynamicFieldUpdateOrderItem) {
    return this.http.post<BaseResponse>(environment.apiUrl + `/api/BoardDynamicField/UpdateOrder/${boardId}`, request);
  }

  deleteBoard(boardId: string, dynamicFieldId: string): Observable<Response<DynamicField>> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<DynamicField>>(
      environment.apiUrl + `/api/BoardDynamicField/Delete/${boardId}`,
      JSON.stringify(dynamicFieldId), { headers });
  }


  search(request: BoardCustomFieldSearchRequest): Observable<PagedResponse<DynamicField>> {
    return this.searchBoard(request.filter.boardId, request);
  }

  searchBoard(boardId: string, request: BoardCustomFieldSearchRequest): Observable<PagedResponse<DynamicField>> {
    return this.http.post<PagedResponse<DynamicField>>(
      environment.apiUrl + `/api/BoardDynamicField/Search/${boardId}`,
      request
    );
  }

  existingPicklists(boardId: string): Observable<Response<DynamicFieldPicklist[]>> {
    return this.http.post<Response<DynamicFieldPicklist[]>>(
      environment.apiUrl + `/api/BoardDynamicField/ExistedPicklists/${boardId}`, {}
    );
  }

  boardDynamicFieldReport(request: BoardInfoCardsSearchRequest): Observable<Response<BoardDynamicFieldReport[]>> {
    return this.http.post<Response<BoardDynamicFieldReport[]>>(environment.apiUrl + '/api/BoardReport/BoardDynamicFieldReport', request);
  }

  boardDynamicFieldSearch(request?: DynamicFieldFilterRequest) {
    return this.http.post<Response<PagedResponseData<DynamicField>>>(environment.apiUrl + '/api/BoardDynamicField/Search', request);
  }

  orderByBulkUpdate(request: BoardCustomFieldOrderBy[]): Observable<Response<BoardCustomField[]>> {
    return this.http.post<Response<BoardCustomField[]>>(
      environment.apiUrl + '/api/BoardCustomField/OrderByBulkUpdate',
      request
    );
  }
}
