import { AbstractControl, ValidationErrors } from '@angular/forms';

export class ValidateParticipant {
  static contactRequired(control: AbstractControl): ValidationErrors | null {
    if (control.value && control.value.contacts && control.value.contacts.length > 0) {
      return null;
    }

    return { contactRequired: true };
  }
}
