import { LeadsGridData } from '@core/api';
import { TranslateService } from '@ngx-translate/core';

export function leadsReportTableDataTransformer(lead: LeadsGridData, translate: TranslateService): LeadsGridData {

    if (lead.diffHour || lead.diffMinute) {

        if (lead.diffHour && lead.diffMinute) {

            lead.createDateDiff = translate.instant('DASHBOARD.TABLE_HOURS_MINUTES_AGO', {
                hour: lead.diffHour,
                minute: lead.diffMinute
            });

        } else if (lead.diffHour) {

            lead.createDateDiff = translate.instant('DASHBOARD.TABLE_HOURS_AGO', {
                hour: lead.diffHour
            });

        } else {

            lead.createDateDiff = translate.instant('DASHBOARD.TABLE_MINUTES_AGO', {
                minute: lead.diffMinute
            });

        }

        return lead;
    }

    if (lead.diffMinute === 0) {
        lead.createDateDiff = translate.instant('DASHBOARD.TABLE_MINUTES_AGO', {
            minute: 0
        });

        return lead;
    }

    if (lead.diffYear) {

        if (lead.diffMonth) {

            if (lead.diffDay) {

                lead.createDateDiff = translate.instant('DASHBOARD.TABLE_YEARS_MONTHS_DAYS_AGO', {
                    year: lead.diffYear,
                    month: lead.diffMonth,
                    day: lead.diffDay
                });

            } else {

                lead.createDateDiff = translate.instant('DASHBOARD.TABLE_YEARS_MONTHS_AGO', {
                    year: lead.diffYear,
                    month: lead.diffMonth
                });
            }

        } else {

            if (lead.diffDay) {

                lead.createDateDiff = translate.instant('DASHBOARD.TABLE_YEARS_DAYS_AGO', {
                    year: lead.diffYear,
                    day: lead.diffDay
                });

            } else {

                lead.createDateDiff = translate.instant('DASHBOARD.TABLE_YEARS_AGO', {
                    year: lead.diffYear
                });

            }
        }

    } else {

        if (lead.diffMonth) {

            if (lead.diffDay) {

                lead.createDateDiff = translate.instant('DASHBOARD.TABLE_MONTHS_DAYS_AGO', {
                    month: lead.diffMonth,
                    day: lead.diffDay
                });

            } else {

                lead.createDateDiff = translate.instant('DASHBOARD.TABLE_MONTHS_AGO', {
                    month: lead.diffMonth
                });

            }

        } else {

            if (lead.diffDay) {

                lead.createDateDiff = translate.instant('DASHBOARD.TABLE_DAYS_AGO', {
                    day: lead.diffDay
                });

            } else {

                lead.createDateDiff = '';

            }

        }

    }

    return lead;
}
