<form [formGroup]="form">
    <h2 mat-dialog-title>{{'ACTIVITY.MAIL_SENDING' | translate}}</h2>
    <mat-dialog-content class="pb-4" fxLayout="column">

        <ng-container *ngIf="!isLoading else skeleton">

            <span>{{'ACTIVITY.MAIL_SENDING_DESCRIPTION' | translate}}</span>
            <h4 class="font-medium mt-4"> {{'ACTIVITY.PARTICIPANTS' | translate}} </h4>

            <div fxLayout="column" formArrayName="sendUsers">
                <ng-container *ngFor="let control of getSendUserForm().controls; let index = index"
                    [formGroupName]="index">
                    <mat-checkbox formControlName="checked" color="primary">
                        {{control.get('name').value}}
                    </mat-checkbox>
                </ng-container>
            </div>

            <h4 class="font-medium mt-4">{{'ACTIVITY.CC' | translate}}</h4>
            <mat-form-field>
                <mat-label>{{'ACTIVITY.USER_CHOICE' | translate}}</mat-label>
                <net-multi-select-system-user [defaultSelectedUserIds]="defaultSelectedUserIds"
                    [salesOrganizationId]="request.account?.salesOrganizationId" formControlName="ccUsers">
                </net-multi-select-system-user>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{"ACTIVITY.PDF_THEME" | translate}}</mat-label>
                <net-select-activity-pdf-template [salesOrganizationId]="request?.account?.salesOrganizationId"
                    formControlName="activityPdfTemplate"></net-select-activity-pdf-template>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{'PROFILE.SELECTED_LANGUAGE' | translate}}</mat-label>
                <net-select-language formControlName="language" [withCustomCode]="true" [isSpecialLanguage]="true"
                    [isActive]="true" [placeholder]="'LANGUAGE.SELECT_LANGUAGE' | translate"></net-select-language>
            </mat-form-field>
            <div class="pb-5" fxFlex="100">
                <mat-checkbox fxFlex="50" formControlName="assigmentFlag" color="primary">
                    {{'ACTIVITY.Include Activity Tasks in Pdf' | translate}}
                </mat-checkbox>

                <mat-checkbox *ngIf="activity?.activityId" fxFlex="50" formControlName="documentFlag" color="primary">
                    {{'ACTIVITY.Include Documents in Pdf' | translate}}
                </mat-checkbox>
            </div>
            <mat-form-field *ngIf="form.get('documentFlag').value">
                <mat-label>{{'ACTIVITY.SELECTED_DOCUMENTS' | translate}}</mat-label>
                <net-multi-select-document formControlName="documentList" [activityId]="activity?.activityId">
                </net-multi-select-document>
            </mat-form-field>

        </ng-container>

        <ng-template #skeleton>
            <ngx-skeleton-loader appearance="line"></ngx-skeleton-loader>
            <ngx-skeleton-loader appearance="line"></ngx-skeleton-loader>
            <ngx-skeleton-loader appearance="line"></ngx-skeleton-loader>
            <ngx-skeleton-loader appearance="line"></ngx-skeleton-loader>
        </ng-template>


    </mat-dialog-content>

    <mat-dialog-actions class="items-start">

        <button class="ml-auto mr-2" color="secondary" mat-button (click)="closeAlertDialog()">{{'GENERAL.CANCEL' |
            translate}}</button>
        <button (click)="$event.stopPropagation()"
            *ngIf="activity && activity.activityStatusId === activityStatus.COMPLETED" [matMenuTriggerFor]="menu"
            class="mr-2" color="primary" fxFlex.gt-xs="125px" fxFlex.xs="100%" mat-raised-button type="button">
            <ic-icon [icon]="icPdf" class="ltr:-ml-1 rtl:-mr-1 ltr:mr-2 rtl:ml-2" inline="true" size="18px"></ic-icon>
            <span fxHide.lt-sm>{{"ACTIVITY.CREATE_PDF" | translate}}</span>
        </button>
        <net-progress-button [icon]="icSave" (btnClick)="closeAlertDialog(true)"
            [text]="'GENERAL.SAVE_SEND' | translate">
        </net-progress-button>
    </mat-dialog-actions>
</form>

<mat-menu #menu="matMenu" xPosition="before" yPosition="below">
    <ng-template matMenuContent>
        <button (click)="request.activityAction = 'download'; onSave(request)" color="primary" mat-menu-item>
            <mat-icon [icIcon]="icDownload"></mat-icon>
            <span>{{"GENERAL.DOWNLOAD" | translate}}</span>
        </button>

        <button (click)="request.activityAction = 'preview'; onSave(request)" color="primary" mat-menu-item>
            <mat-icon [icIcon]="icPdfOutLine"></mat-icon>
            <span>{{"GENERAL.PREVIEW" | translate}}</span>
        </button>
    </ng-template>
</mat-menu>