import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import {
  Response,
  PagedResponse,
  Document,
  DocumentDetail,
  DocumentSetting,
  DocumentHistory,
  DocumentExtensionType,
  DocumentFilterRequest,
  DocumentSettingFilterRequest,
  DocumentHistoryFilterRequest,
  OpportunityDocumentList
} from '@core/api';

import { FileSaverService } from 'ngx-filesaver';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  readonly uploadUrl = `${environment.apiUrl}/api/Document/Upload`;

  constructor(private http: HttpClient, private fileSaverService: FileSaverService) { }

  list(request: DocumentFilterRequest): Observable<PagedResponse<DocumentDetail>> {
    return this.http.post<PagedResponse<DocumentDetail>>(environment.apiUrl + '/api/Document/SearchMain', request);
  }

  listOpportunityDocuments(uuid: string): Observable<Response<OpportunityDocumentList[]>> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<OpportunityDocumentList[]>>(environment.apiUrl + '/api/Document/ListOpportunityDocuments',
      JSON.stringify(uuid), { headers });
  }

  uploadWithQueryString(request: any , formData?: any){
    return this.http.post<any>(environment.apiUrl + '/api/Document/Upload' + request, formData);
  }

  updateWithQueryString(request: any , formData?: any){
    return this.http.post<any>(environment.apiUrl + '/api/Document/Update' + request, formData);
  }

  download(id: string, fileName: string, preview?: boolean) {
    const headers = new HttpHeaders(
      { 'content-type': 'application/json' }
    );
    return this.http.post(environment.apiUrl + '/api/Document/Download', JSON.stringify(id), {
      headers,
      observe: 'response',
      responseType: 'blob'
    }).subscribe(data => {
      if (preview) {
        const type = fileName.substr(fileName.lastIndexOf('.') + 1).toLowerCase();

        if (type !== 'png' && type !== 'jpg' && type !== 'jpeg' && type !== 'pdf') {
          this.fileSaverService.save(data.body, fileName);
        } else if (type === 'pdf') {
          const file = new Blob([data.body], { type: 'application/' + fileName.substr(fileName.lastIndexOf('.') + 1) });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        } else {
          const file = new Blob([data.body], { type: 'image/' + fileName.substr(fileName.lastIndexOf('.') + 1) });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }
      } else {
        this.fileSaverService.save(data.body, fileName);
      }
    });
  }

  delete(documentId: string) {
    return this.http.post<Response<Document[]>>(environment.apiUrl + '/api/Document/Delete', {documentId});
  }

  setting(request: DocumentSettingFilterRequest) {
    return this.http.post<PagedResponse<DocumentSetting>>(environment.apiUrl + '/api/DocumentSetting/Search', request);
  }

  history(request: DocumentHistoryFilterRequest) {
    return this.http.post<PagedResponse<DocumentHistory>>(environment.apiUrl + '/api/DocumentHistory/Search', request);
  }

  extensionType(): Observable<Response<DocumentExtensionType[]>> {
    return this.http.post<Response<DocumentExtensionType[]>>(environment.apiUrl + '/api/DocumentExtensionType/List', {});
  }

  // @TODO remove after delete file-upload/document services
  searchMain(request) {
    return this.list(request);
  }
}
