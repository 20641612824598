import { ErrorHandler, Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { ErrorLogRequest, ErrorLogService, User } from '@core/api';
import { ClientIpService } from '@core/api/client-ip/client-ip.service';
import { AuthService } from '@core/auth/auth.service';
import { Observable, ReplaySubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import packageJson from '../../../../package.json';

@Injectable()
export class CustomErrorHandlerService extends ErrorHandler {
    errorLogSubject$: ReplaySubject<ErrorLogRequest> = new ReplaySubject<ErrorLogRequest>(1);
    errorLog$: Observable<ErrorLogRequest> = this.errorLogSubject$.asObservable();
    version: string = packageJson.version;

    constructor(
        private errorLogService: ErrorLogService,
        private platform: Platform,
        private clientIpAddressService: ClientIpService
    ) {
        super();

        this.errorLog$.pipe(debounceTime(5000)).subscribe((data: ErrorLogRequest) => {
            this.clientIpAddressService.getIpAddress().then((ipAddress) => {
                data.ipAddress = ipAddress;
                const browserInfo = navigator['userAgentData']?.brands?.map(browser => {
                    return `(${browser.brand} - ${browser.version})`;
                });
                const _platform = navigator['userAgentData']?.platform;
                data.actionName = browserInfo ? `${_platform} - ${browserInfo.join(' - ')}` : this.getBrowserInformation();
                this.insertErrorLog(data);
            }).finally(() => { });
        });
    }

    handleError(obj: any) {
        // this.rollbar.error(obj.error?.originalError || obj);
        const user: User = JSON.parse(AuthService.getUser());
        const request: ErrorLogRequest = {
            code: 'FE',
            params: window.location.href,
            userId: user ? user.userId : null,
            userName: user ? user.userName : null
        };
        if (obj.request) {
            request.exception = obj.request?.body;
            request.statusCode = obj?.error?.status;
            request.exceptionMessage = obj?.error?.message;
            request.innerExceptionMessage = obj?.error?.error?.errors?.$.join(':::');
            request.result = this.version;
        } else {
            request.exceptionMessage = obj?.message;
            request.innerExceptionMessage = obj?.stack;
            request.result = this.version;
        }

        if (!obj.request && obj?.task?.target?.id === 'GTMscript') {
            request.exceptionMessage = 'Google tag manager ID not provided.';
        }


        this.errorLogSubject$.next(request);

        super.handleError(obj);
    }

    getBrowserInformation() {
        const browserName = this.detectBrowserName() + ' v:' + this.detectBrowserVersion();
        switch (true) {
            case this.platform.ANDROID:
                return browserName + ' - Android - ' + navigator.platform;

            case this.platform.IOS:
                return browserName + ' - IOS - ' + navigator.platform;

            default:
                return browserName + ' - PC - ' + navigator.platform;
        }
    }

    detectBrowserName() {
        const agent = window.navigator.userAgent.toLowerCase();
        switch (true) {
            case agent.indexOf('edg') > -1:
                return 'Edge';
            case agent.indexOf('opr') > -1 && !!(window as any).opr:
                return 'Opera';
            case agent.indexOf('chrome') > -1 && !!(window as any).chrome:
                return 'Chrome';
            case agent.indexOf('trident') > -1:
                return 'Internet Explorer';
            case agent.indexOf('firefox') > -1:
                return 'firefox';
            case agent.indexOf('safari') > -1:
                return 'Safari';
            default:
                return 'Unknow Browser';
        }
    }

    detectBrowserVersion() {
        const userAgent = navigator.userAgent;
        let tem;
        let matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

        if (/trident/i.test(matchTest[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
            return 'IE ' + (tem[1] || '');
        }
        if (matchTest[1] === 'Chrome') {
            tem = userAgent.match(/\b(OPR|Edg)\/(\d+)/);
            if (tem != null) {
                return tem.slice(1).join(' ').replace('OPR', 'Opera');
            }
        }
        matchTest = matchTest[2] ? [matchTest[1], matchTest[2]] : [navigator['appName'], navigator['appVersion'], '-?'];
        tem = userAgent.match(/version\/(\d+)/i);
        if (tem != null) {
            matchTest.splice(1, 1, tem[1]);
        }
        return matchTest[1];
    }

    insertErrorLog(request: ErrorLogRequest) {
        if (request.innerExceptionMessage && request.innerExceptionMessage.length > 3) {
            this.errorLogService.insert(request).subscribe(response => {

            });
        }
    }
}
