import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { environment } from '@env/environment';
import { Translation, TranslationFilter, TranslationRequest } from './translation.model';
import { Observable } from 'rxjs';
import { PagedResponse, Response } from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class TranslationService implements TranslateLoader {

  constructor(private http: HttpClient) { }

  getTranslation(culture?: string) {
    const user = JSON.parse(localStorage.getItem('api_user'));

    if (!culture) {
      culture = user?.culture || (navigator.language === 'tr' ? 'tr' : 'en');
    }

    return this.http.get<any>(environment.apiUrl + '/api/Translation/All?culture=' + culture ?? 'en', {});
  }

  search(request: TranslationFilter): Observable<PagedResponse<Translation>> {
    return this.http.post<PagedResponse<Translation>>(environment.apiUrl + '/api/Translation/Search', request);
  }

  update(request: TranslationRequest): Observable<Response<Translation>> {
    return this.http.post<Response<Translation>>(environment.apiUrl + '/api/Translation/Update', request);
  }
}
