import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, DoCheck, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl, UntypedFormControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { ActivityType, ActivityTypeService } from '@core/api';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'net-multi-select-survey-mail-activity-type',
  templateUrl: './multi-select-survey-mail-activity-type.component.html',
  styleUrls: ['./multi-select-survey-mail-activity-type.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: MultiSelectSurveyMailActivityTypeComponent
    }
  ]
})
export class MultiSelectSurveyMailActivityTypeComponent implements OnInit, OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<string[]>  {

  static nextId = 0;
  stateChanges = new Subject<void>();
  selectCtrl = new UntypedFormControl();
  focused = false;
  errorState = false;
  describedBy = '';
  controlType = 'multi-select-activity-type';
  searchText = '';

  activityTypes: ActivityType[] = [];

  @Input() isDisabled = false;
  @Input() tabIndex;
  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    if (value) {
      this.selectCtrl.disable();
    } else {
      this.selectCtrl.enable();
    }
    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): string[] { return this.selectCtrl.value; }
  set value(value: string[]) {
    this.selectCtrl.setValue(value);
    this.onChange(value);
    this.stateChanges.next();
  }

  @ViewChild('activityTypeSelect') activityTypeSelect: MatSelect;
  @HostBinding('id') id = `multi-select-activity-type-${MultiSelectSurveyMailActivityTypeComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  get shouldLabelFloat() { return this.focused || !this.empty; }

  get empty() { return !this.value; }


  constructor(
    private activityTypeService: ActivityTypeService,
    private translate: TranslateService,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl
  ) {
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.getActivityTypes();
  }

  ngOnInit(): void {
  }

  getActivityTypes() {
    if (!this.activityTypes.length) {
      this.activityTypeService.list({}).subscribe((response) => {
        this.activityTypes = response.data.results;
      });
    }
  }

  onSelectionChange() {
    this.onChange(this.value);
    this.stateChanges.next();
  }

  openedChange(isOpened: boolean) {
    if (isOpened) {
      const panel = this.activityTypeSelect.panel.nativeElement;
      panel.scrollTop = 0;
    }
  }

  selectAll() {

    const allActivityType = this.activityTypes.map(x => x.activityTypeId);
    this.selectCtrl.patchValue(allActivityType);
    this.value = allActivityType;

  }

  deselectAll() {

    this.selectCtrl.patchValue(null);
    this.value = [];
  }

  getToolTip() {
    if (this.activityTypes.length > 0) {
      return this.value.map(x =>
        this.translate.instant('ActivityType.' + this.activityTypes.find(y => y.activityTypeId === x).name)
      ).join('\n');
    }
  }
  compareWith = (one: string, two: string) => one && two && one === two;
  onChange = (_: any) => { };
  selectionChange = (_: any) => { };
  onTouched = () => { };
  onContainerClick = () => { };
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');
  writeValue = (value: string[]) => {
    this.value = value;
    this.selectCtrl.setValue(value);
  }

  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }
}
