import { Board, BoardInfo } from '@core/api';
import { Action } from '@ngrx/store';

export enum BoardDetailReportActionTypes {
    GET_BOARD_REPORT_WITH_BOARD_INFOS = '[Board Report] GET_BOARD_REPORT_WITH_BOARD_INFOS',
    SET_BOARD_REPORT_WITH_BOARD_INFOS_SUCCESS = '[Board Report] SET_BOARD_REPORT_WITH_BOARD_INFOS_SUCCESS',
    SET_BOARD_REPORT_WITH_BOARD_INFOS_ERROR = '[Board Report] SET_BOARD_REPORT_WITH_BOARD_INFOS_ERROR',
    SET_BOARD_REPORT_BOARD = '[Board Report] SET_BOARD_REPORT_BOARD'
}

export class GetBoardWithBoardInfos implements Action {
    readonly type = BoardDetailReportActionTypes.GET_BOARD_REPORT_WITH_BOARD_INFOS;
    constructor(public boarId: string) { }
}

export class SetBoardWithBoardInfosSuccess implements Action {
    readonly type = BoardDetailReportActionTypes.SET_BOARD_REPORT_WITH_BOARD_INFOS_SUCCESS;
    constructor(public boardInfos: BoardInfo[]) { }
}

export class SetBoardWithBoardInfosError implements Action {
    readonly type = BoardDetailReportActionTypes.SET_BOARD_REPORT_WITH_BOARD_INFOS_ERROR;
    constructor(public payload: any) { }
}

export class SetBoardReportBoard implements Action {
    readonly type = BoardDetailReportActionTypes.SET_BOARD_REPORT_BOARD;
    constructor(public board: Board) { }
}

export type BoardReportActions =
    GetBoardWithBoardInfos |
    SetBoardWithBoardInfosSuccess |
    SetBoardWithBoardInfosError |
    SetBoardReportBoard;
