import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import {
  NavigationDropdown,
  NavigationItem,
  NavigationLink,
  NavigationSubheading
} from '../navigation/navigation-item/navigation-item.interface';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private navigationItems = new BehaviorSubject<NavigationItem[]>([]);
  navigationItems$ = this.navigationItems.asObservable();

  private _openChangeSubject = new Subject<NavigationDropdown>();
  openChange$ = this._openChangeSubject.asObservable();

  constructor() {}

  updateNavigationItems(data: NavigationItem[]) {
    this.navigationItems.next(data);
  }

  triggerOpenChange(item: NavigationDropdown) {
    this._openChangeSubject.next(item);
  }

  isLink(item: NavigationItem): item is NavigationLink {
    return item.type === 'link';
  }

  isDropdown(item: NavigationItem): item is NavigationDropdown {
    return item.type === 'dropdown';
  }

  isSubheading(item: NavigationItem): item is NavigationSubheading {
    return item.type === 'subheading';
  }
}
