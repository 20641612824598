import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import { Observable } from 'rxjs';

import { TranslationSection } from './translation-section.model';
import { Response } from '../api.model';

@Injectable({
  providedIn: 'root'
})
export class TranslationSectionService {
  constructor(private http: HttpClient) { }

  list(request: { costomFlag?: boolean }): Observable<Response<TranslationSection[]>> {
    return this.http.post<Response<TranslationSection[]>>(environment.apiUrl + '/api/TranslationSection/List', request);
  }
}
