import { AfterViewInit, ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { TableColumnFilterConfig } from '../mat-table-column-filter/mat-table-column-filter.component';
import { UserConfigService } from '@core/user-config/user-config.service';
import { saveUserPageSizeVisibility, updateUserPageSizeVisibility } from '../../utils/mat-table-user-config';
import { MatPaginator } from '@angular/material/paginator';
@Component({
  selector: 'net-mat-table-paginator',
  templateUrl: './mat-table-paginator.component.html',
  styleUrls: ['./mat-table-paginator.component.scss']
})

export class MatTablePaginatorComponent implements AfterViewInit {

  @Input() pageSize = 10;
  @Input() hidden = false;
  @Input() pageSizeOptions = [5, 10, 20, 50];
  @Input() tableColumnFilterConfig: TableColumnFilterConfig;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private userConfig: UserConfigService,
    private cdr: ChangeDetectorRef
  ) {

  }

  ngAfterViewInit(): void {
    this.pageSize = this.getPageSize();
    this.cdr.detectChanges();
  }

  getPageSize() {
    return updateUserPageSizeVisibility(this.tableColumnFilterConfig.visibilityId, this.userConfig, this.pageSize);

  }

  setPageSize(event) {
    saveUserPageSizeVisibility(this.tableColumnFilterConfig.visibilityId, this.userConfig, event.pageSize);
  }
}
