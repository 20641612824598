import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import { BoardSystemUserProperty, BoardSystemUserPropertyRequest, Response } from '@core/api';

@Injectable({
    providedIn: 'root'
})
export class BoardSystemUserPropertyService {

    constructor(private http: HttpClient) { }

    get(request: BoardSystemUserPropertyRequest): Observable<Response<BoardSystemUserProperty>> {
        return this.http.post<Response<BoardSystemUserProperty>>(`${environment.apiUrl}/api/BoardSystemUserProperty/Get`, request);
    }

    update(request: BoardSystemUserPropertyRequest): Observable<Response<BoardSystemUserProperty>> {
        return this.http.post<Response<BoardSystemUserProperty>>(`${environment.apiUrl}/api/BoardSystemUserProperty/Update`, request);
    }
}
