import * as moment from 'moment';
import { combineLatest } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { debounceTime, filter, map, pairwise, startWith } from 'rxjs/operators';

import { KeyValue } from '@angular/common';
import { ChangeDetectorRef, Component, HostListener, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ApiDataSource } from '@core/api/api.data-source';
import icFilter from '@iconify/icons-ic/twotone-filter-alt';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { TableColumn } from '../../interfaces/table-column.interface';
import { DateRangeType, MatHeaderFilterType } from './mat-header-filter.component.enum';
import { getDynamicFieldValueSearch } from '../../utils/dynamic-entity-fields';
import { DynamicField, DynamicFieldEntityAsString, DynamicFieldValueSearch, DynamicFieldValueType } from '@core/api';
import { replaceTurkishCharacters } from '../../utils/replace-turkish-characters';

@UntilDestroy()
@Component({
  selector: 'net-mat-header-filter[type][column][dataSource]',
  templateUrl: './mat-header-filter.component.html',
  styleUrls: ['./mat-header-filter.component.scss'],
})
export class MatHeaderFilterComponent implements OnInit {

  isOpen = false;
  icFilter = icFilter;

  filters;
  options: KeyValue<string | {}, string>[];
  activeTemplate: TemplateRef<Element>;
  dateRangeTypes = DateRangeType;

  searchCtrl = new UntypedFormControl();
  filteredOptions: KeyValue<string | {}, string>[];
  searchText = '';
  changedId: string | {};

  selectCtrl = new UntypedFormControl();
  oneselectCtrl = new UntypedFormControl();
  oneselectWithoutAutoCompleteCtrl = new UntypedFormControl();
  autoCtrl = new UntypedFormControl('');
  picklistItems;
  _picklistItems;

  dateCtrl = new UntypedFormGroup({
    min: new UntypedFormControl(),
    max: new UntypedFormControl()
  });

  minMaxGroup = new UntypedFormGroup({
    min: new UntypedFormControl(),
    max: new UntypedFormControl()
  });

  @ViewChild('dateFilter', { static: true }) dateFilterTemplate: TemplateRef<Element>;
  @ViewChild('extendedDateFilter', { static: true }) extendedDateFilterrTemplate: TemplateRef<Element>;
  @ViewChild('searchFilter', { static: true }) searchFilterTemplate: TemplateRef<Element>;
  @ViewChild('selectFilter', { static: true }) selectFilterTemplate: TemplateRef<Element>;
  @ViewChild('minMaxFilter', { static: true }) minMaxFilterTemplate: TemplateRef<Element>;
  @ViewChild('oneSelectFilter', { static: true }) oneSelectFilterTemplate: TemplateRef<Element>;
  @ViewChild('paginationSelect', { static: true }) paginationSelectTemplate: TemplateRef<Element>;
  @ViewChild('oneSelectWithoutAutoComplete', { static: true }) oneSelectWithoutAutoCompleteTemplate: TemplateRef<Element>;

  @Input() type: MatHeaderFilterType;
  @Input() dataSource: ApiDataSource<any>;
  @Input() selectSource: Observable<KeyValue<string, string>[]>;
  @Input() selectCache = true;
  @Input() key: string;
  @Input() dateRangeType = DateRangeType.prev;
  @Input() dateFormat = 'YYYY-MM-DDTHH:mm:ssZ';
  @Input() dynamicFieldId: DynamicField;
  @Input() isSelectAllEnabled = false;
  @Input() entityName: DynamicFieldEntityAsString;

  @Input()
  get column(): TableColumn<any> { return this._column; }
  set column(column: TableColumn<any>) {
    if (this._column) {
      this._column = { ...column, isFilterActive: this._column.isFilterActive };
      return;
    }

    this._column = column;
  }
  private _column: TableColumn<any>;


  @Input()
  get reset(): boolean { return this._reset; }
  set reset(value: boolean) {
    if (value) {
      this.resetFormController();
      this.applyFilter(false);
    }
    this._reset = value;
  }
  private _reset: boolean;

  @HostListener('click', ['$event']) onClick(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  constructor(
    private cdr: ChangeDetectorRef
  ) { }

  get value() {
    switch (this.type) {
      case MatHeaderFilterType.extendedDate: return this.dateCtrl.value;
      case MatHeaderFilterType.date: return this.dateCtrl.value;
      case MatHeaderFilterType.search: return this.searchCtrl.value;
      case MatHeaderFilterType.select: return this.selectCtrl.value;
      case MatHeaderFilterType.minMax: return this.minMaxGroup.value;
      case MatHeaderFilterType.oneSelect: return this.oneselectCtrl.value;
      case MatHeaderFilterType.paginationSelect: return this.searchCtrl.value;
      case MatHeaderFilterType.oneSelectWithoutAutoComplete: return this.oneselectWithoutAutoCompleteCtrl.value;
    }

    throw Error('Unknown value type');
  }

  get isEmpty(): boolean {
    const value = this.value;
    switch (this.type) {
      case MatHeaderFilterType.date:
      case MatHeaderFilterType.extendedDate:
      case MatHeaderFilterType.minMax:
        return !value.min || !value.max;

      default: return [null, undefined, ''].includes(value);
    }
  }

  ngOnInit(): void {
    // Make sure select filter have source
    if ((this.type === MatHeaderFilterType.select || this.type === MatHeaderFilterType.oneSelect) && !this.selectSource) {
      throw Error('Please provide select filer with select source.');
    }

    // Decide active filter template
    switch (this.type) {
      case MatHeaderFilterType.extendedDate:
        this.activeTemplate = this.extendedDateFilterrTemplate;
        break;
      case MatHeaderFilterType.date:
        this.activeTemplate = this.dateFilterTemplate;
        break;

      case MatHeaderFilterType.search:
        this.activeTemplate = this.searchFilterTemplate;
        break;

      case MatHeaderFilterType.select:
        this.activeTemplate = this.selectFilterTemplate;
        break;

      case MatHeaderFilterType.minMax:
        this.activeTemplate = this.minMaxFilterTemplate;
        break;

      case MatHeaderFilterType.oneSelect:
        this.activeTemplate = this.oneSelectFilterTemplate;
        break;

      case MatHeaderFilterType.paginationSelect:
        this.activeTemplate = this.paginationSelectTemplate;
        break;

      case MatHeaderFilterType.oneSelectWithoutAutoComplete:
        this.activeTemplate = this.oneSelectWithoutAutoCompleteTemplate;
        break;

      default: throw Error('Unknown value type');
    }

    this.selectCtrl.valueChanges
      .pipe(startWith(null as string), pairwise(), debounceTime(1))
      .subscribe(([prev, next]: [any[], any[]]) => {
        if (prev?.length > 0 && next?.length > 0) {
          const changedValue = next.find((val) => val === this.changedId);
          if (changedValue) {
            const prevChangedValue = prev.findIndex((val) => val === changedValue);
            if (prevChangedValue === -1) {
              prev.push(changedValue);
              this.selectCtrl.patchValue(prev);
            }
          } else {
            const prevChangedValue = prev.findIndex((val) => val === this.changedId);
            if (prevChangedValue !== -1) {
              prev.splice(prevChangedValue, 1);
              this.selectCtrl.patchValue(prev);
            }
          }
        }
      });

    this.autoCtrl.valueChanges.pipe(
      filter(value => !!value && typeof value === 'string'),
      map(value => value.toString().trim()),
      filter(value => value.length > 2),
      debounceTime(300)).subscribe(value => this.onKey(value));

    this.oneselectCtrl.valueChanges.subscribe((value) => {
      const selectedOption = this.options?.find(option => option.key === value);
      this.autoCtrl.patchValue(selectedOption);
    });

    this.oneselectWithoutAutoCompleteCtrl.valueChanges.subscribe((value) => {
      const selectedOption = this.options?.find(option => option.key === value);
      this.autoCtrl.patchValue(selectedOption);
    });

    this.dataSource.filter$.pipe(untilDestroyed(this))
      .subscribe(filters => {
        this.filters = filters;
        this.column.isFilterActive = this.checkHeaderForDataSourceFilter(filters);
      });
  }

  checkKey(event) {
    if (event.code === 'Space') {
      event.stopPropagation();
    }
  }

  // tslint:disable-next-line:no-shadowed-variable
  private checkLoadFilter(filter: { [key: string]: string | {} }) {
    if (!filter || Object.keys(filter).length < 1) {
      this.searchText = '';
      this.resetFormController();
      return;
    }

    this.setHeaderForDataSourceFilter(filter);
  }

  // tslint:disable-next-line:no-shadowed-variable
  private setHeaderForDataSourceFilter(filter: { [key: string]: string | {} }) {
    const isFilterActive = this.checkHeaderForDataSourceFilter(filter);
    if (!isFilterActive) {
      this.resetFormController();
      return;
    }

    this.setFormsForFilter(filter);
  }

  // tslint:disable-next-line:no-shadowed-variable
  private checkHeaderForDataSourceFilter(filter: { [key: string]: string | {} }): boolean {
    if ([null, undefined].includes(filter)) {
      return false;
    }

    // Check dynamic filter search active when column is dynamic
    if (this.column.isDynamic) {
      if (!Array.isArray(filter?.dynamicFieldValueSearches)) {
        return false;
      }

      return filter.dynamicFieldValueSearches.some(item => item.dynamicFieldId === this.column.dynamicFieldId);
    }

    const key = this.key ?? this.column.property as string;
    let isRangeFilterAvailable = false;

    if (this.type === MatHeaderFilterType.minMax || this.type === MatHeaderFilterType.date || this.type === MatHeaderFilterType.extendedDate) {
      isRangeFilterAvailable = !!filter[`${key}Min`] || !!filter[`${key}Max`];
    }

    return isRangeFilterAvailable || ![null, undefined].includes(filter[key]);
  }

  // tslint:disable-next-line:no-shadowed-variable
  private setFormsForFilter(filter: { [key: string]: string | {} }) {
    if (this.column.isDynamic) {
      // When dynamic field search is not found do nothing
      if (!Array.isArray(filter?.dynamicFieldValueSearches)) {
        return;
      }

      // Extract value from dynamic field search
      const search = (filter.dynamicFieldValueSearches as DynamicFieldValueSearch[]).find(field => field.dynamicFieldId === this.column.dynamicFieldId);
      if (!search) {
        throw Error(`Dynamic field search not found for dynamic filter with id ${this.column.dynamicFieldId}`);
      }

      // Extract value from field search
      let value;
      switch (search.valueType) {
        case DynamicFieldValueType.TEXT:
          value = search.textValue;
          break;

        case DynamicFieldValueType.PICKLIST:
          value = search.guidValues ?? search.guidValue ?? null;
          break;

        case DynamicFieldValueType.DATE:
        case DynamicFieldValueType.DATETIME:
          value = {
            min: search.dateValueMin,
            max: search.dateValueMax
          };
          break;

        case DynamicFieldValueType.DECIMAL:
        case DynamicFieldValueType.INTEGER:
          value = {
            min: search.numberValueMin,
            max: search.numberValueMax
          };
          break;
      }

      // Apply filter value to controllers
      switch (this.type) {
        case MatHeaderFilterType.search:
          this.searchCtrl.patchValue(value);
          break;
        case MatHeaderFilterType.date:
        case MatHeaderFilterType.extendedDate:
          this.dateCtrl.patchValue(value);
          break;
        case MatHeaderFilterType.minMax:
          this.minMaxGroup.patchValue(value);
          break;
        case MatHeaderFilterType.select:
        case MatHeaderFilterType.paginationSelect:
          this.selectCtrl.patchValue(value);
          break;
        case MatHeaderFilterType.oneSelect:
          this.oneselectCtrl.patchValue(value);
          break;
        case MatHeaderFilterType.oneSelectWithoutAutoComplete:
          this.oneselectWithoutAutoCompleteCtrl.patchValue(value);
      }

      return;
    }

    const key = this.key ?? this.column.property as string;
    const filterValue = filter[key];
    let rangeFilterValue = {};

    if (this.type === MatHeaderFilterType.minMax || this.type === MatHeaderFilterType.date || this.type === MatHeaderFilterType.extendedDate) {
      rangeFilterValue = {
        [`${key}Min`]: filter[`${key}Min`] ?? '',
        [`${key}Max`]: filter[`${key}Max`] ?? '',
      };
    }

    switch (this.type) {
      case MatHeaderFilterType.search:
        this.searchCtrl.patchValue(filterValue);
        break;
      case MatHeaderFilterType.select:
      case MatHeaderFilterType.paginationSelect:
        const selectedValues = this.getSelectedValues(filterValue).map(value => value?.key);
        this.selectCtrl.patchValue(selectedValues);
        break;
      case MatHeaderFilterType.extendedDate:
      case MatHeaderFilterType.date:
        this.dateCtrl.patchValue({
          min: rangeFilterValue[`${key}Min`],
          max: rangeFilterValue[`${key}Max`]
        });
        break;
      case MatHeaderFilterType.minMax:
        this.minMaxGroup.patchValue({
          min: rangeFilterValue[`${key}Min`],
          max: rangeFilterValue[`${key}Max`]
        });
        break;
      case MatHeaderFilterType.oneSelect:
        this.oneselectCtrl.patchValue(filterValue);
        break;
      case MatHeaderFilterType.oneSelectWithoutAutoComplete:
        this.oneselectWithoutAutoCompleteCtrl.patchValue(filterValue);
    }
  }

  private applyFilter(reset = true) {
    const key = this.key ?? this.column.property as string;
    const value = this.value;
    const filters = [];
    const dynamicFieldValueSearches = Array.isArray(this.filters?.dynamicFieldValueSearches) ? [...this.filters?.dynamicFieldValueSearches] : [];
    this.picklistItems = this._picklistItems;
    this.cdr.detectChanges();
    if (this.column.isDynamic) {
      let search;
      const index = dynamicFieldValueSearches.findIndex(item => item?.dynamicFieldId === this.column.dynamicFieldId);
      const hasValue = !(!value || ((value.hasOwnProperty('min') && !value.min) && (value.hasOwnProperty('max') && !value.max)));
      const dynamicField = this.dataSource.dynamicFields.find(field => field.dynamicFieldId === this.column.dynamicFieldId);

      if (!dynamicField) {
        throw Error(`Dynamic field with column dynamic field id ${this.column.dynamicFieldId} is not found`);
      }

      if (hasValue) {
        switch (this.type) {
          case MatHeaderFilterType.extendedDate:
          case MatHeaderFilterType.date:
            search = getDynamicFieldValueSearch(
              {
                min: value.min ? moment(value.min).startOf('day').format(this.dateFormat) : null,
                max: value.max ? moment(value.max).endOf('day').format(this.dateFormat) : null,
              },
              dynamicField,
            );
            break;

          default:
            search = getDynamicFieldValueSearch(value, dynamicField);
            break;
        }

        // Push or update field value search
        if (index === -1) {
          dynamicFieldValueSearches.push(search);
        } else {
          dynamicFieldValueSearches[index] = search;
        }
      }

      // if (!hasValue) {
      //   dynamicFieldValueSearches.splice(index, 1);
      // }
    } else {
      switch (this.type) {
        case MatHeaderFilterType.date:
        case MatHeaderFilterType.extendedDate:
          filters.push({ key: `${key}Min`, value: value.min ? moment(value.min).startOf('day').format(this.dateFormat) : null });
          filters.push({ key: `${key}Max`, value: value.max ? moment(value.max).endOf('day').format(this.dateFormat) : null });
          break;

        case MatHeaderFilterType.minMax:
          filters.push({ key: `${key}Min`, value: value.min });
          filters.push({ key: `${key}Max`, value: value.max });
          break;

        default:
          filters.push({ key, value });
          break;
      }
    }

    if (dynamicFieldValueSearches.length > 0) {
      filters.push({ key: 'dynamicFieldValueSearches', value: dynamicFieldValueSearches });
    } else {
      filters.push({ key: 'dynamicFieldValueSearches', value: null });
    }

    this.dataSource.applyFilters(filters, reset);
  }

  displayFn(option: KeyValue<string | {}, string>): string {
    return option && option.value ? option.value : '';
  }

  onSelectOption(option: KeyValue<string | {}, string>) {
    this.oneselectCtrl.patchValue(option.key);
  }

  apply(): void {
    this.toggle();
    this.applyFilter();
  }

  clean(): void {
    this.searchText = '';
    this.picklistItemsSelected([]);
    this.toggle();

    // Reset form controllers
    this.resetFormController();

    this.applyFilter();
    this.cdr.detectChanges();
  }

  onKey(value) {
    this.searchText = value;
    this.filteredOptions = this.search(value);
  }

  search(value: string) {
    const filterValue = replaceTurkishCharacters(value).toUpperCase();
    let filteredOptions = this.options?.filter(option => replaceTurkishCharacters(option.value).toUpperCase().includes(filterValue));

    if (this.type === MatHeaderFilterType.select && this.isSelectAllEnabled && filteredOptions) {
      const selectedOptions = (this.value || []).map(item => {
        const isHave = this.options.find(opt => opt.key === item);
        return isHave || null;
      }).filter(Boolean);

      filteredOptions = [...new Set([...filteredOptions, ...selectedOptions])];
    }

    return filteredOptions;
  }

  changeActiveItem(id: {} | string) {
    this.changedId = id;
  }

  openedChange() {
    const value = '';
    this.onKey(value);
  }

  resetFormController() {
    switch (this.type) {
      case MatHeaderFilterType.date: this.dateCtrl.setValue({ min: '', max: '' }); break;
      case MatHeaderFilterType.extendedDate: this.dateCtrl.setValue({ min: '', max: '' }); break;
      case MatHeaderFilterType.minMax: this.minMaxGroup.setValue({ min: '', max: '' }); break;
      case MatHeaderFilterType.search: this.searchCtrl.setValue(''); break;
      case MatHeaderFilterType.select: this.selectCtrl.setValue(''); break;
      case MatHeaderFilterType.oneSelect:
        this.oneselectCtrl.setValue('');
        this.autoCtrl.setValue('');
        break;
      case MatHeaderFilterType.oneSelectWithoutAutoComplete:
        this.oneselectWithoutAutoCompleteCtrl.setValue('');
        this.autoCtrl.setValue('');
        break;

    }

    this.column.isFilterActive = false;
  }

  toggle(): void {
    this.isOpen = !this.isOpen;
    if (!this.isOpen) {
      return;
    }

    if (this.type !== MatHeaderFilterType.select && this.type !== MatHeaderFilterType.oneSelect
      && this.type !== MatHeaderFilterType.oneSelectWithoutAutoComplete) {
      this.dataSource.filter$.pipe(untilDestroyed(this)).subscribe(filterValue => this.checkLoadFilter(filterValue));
      return;
    }

    combineLatest([
      this.dataSource.filter$,
      this.selectSource
    ]).pipe(untilDestroyed(this))
      .subscribe(([filterValue, options]: [{}, KeyValue<string, string>[]]) => {
        if (!this.selectCache || !this.options) {
          this.options = options;
          this.filteredOptions = this.options;
        }

        this.checkLoadFilter(filterValue);
      });
  }

  getSelectedValues(selectList, onClick = false): KeyValue<string, string>[] {
    return selectList.map((val) => {
      return this.options?.find((option) => {
        if (typeof option.key !== 'string' && !onClick) {
          if (val['salesOrganizationId']) {
            return Object.keys(option.key).every(key => val[key] && val[key] === option.key[key]);
          }

          if (typeof option.key === 'number') {
            return option.key === val;
          }

          return Object.keys(option.key).some(key => val[key] && val[key] === option.key[key]);
        }

        return option.key === val;
      });
    });
  }

  getSelectedValuesString(): string {
    return this.getSelectedValues(this.selectCtrl.value, true).map((val) => val.value).toString();
  }

  applyDateRange(range: string) {
    let min = moment();
    const max = moment();

    switch (range) {
      case 'nextWeek':
        min.add(1, 'week').weekday(0).startOf('day').toDate();
        max.add(1, 'week').weekday(6).endOf('day').toDate();
        break;
      case 'thisWeek':
        min.weekday(0).startOf('day').toDate();
        max.weekday(6).endOf('day').toDate();
        break;
      case 'thisMonth':
        const beginDate = new Date(moment().year(), moment().month(), 1, 0, 0, 0, 0);
        min = moment(beginDate);
        max.endOf('month').toDate();
        break;
      case 'prev30day':
        min.subtract(30, 'days');
        break;

      case 'prev3month':
        min.subtract(3, 'months');
        break;

      case 'prev1year':
        min.subtract(365, 'days');
        break;

      case 'month':
        min.startOf('month');
        max.endOf('month');
        break;

      case 'next3month':
        max.add(3, 'months');
        break;

      case 'year':
        min.startOf('year');
        max.endOf('year');
        break;

      default: throw Error('Unknown date range');
    }

    this.dateCtrl.patchValue({ min, max });

    const key = this.key ?? this.column.property as string;
    this.addDateFilterToDatasource(key, range);
    this.apply();
  }

  addDateFilterToDatasource(key: string, range: string): void {
    const keyIndex = this.dataSource.dateFilterRange.findIndex(x => x.key === key);

    if (keyIndex === -1) {
      this.dataSource.dateFilterRange.push({
        key,
        range,
        keyMax: `${key}Max`,
        keyMin: `${key}Min`,
        dateFormat: this.dateFormat
      });
    } else {
      this.dataSource.dateFilterRange[keyIndex] = {
        key,
        range,
        keyMax: `${key}Max`,
        keyMin: `${key}Min`,
        dateFormat: this.dateFormat
      };
    }
  }

  picklistItemsSelected(event) {
    if (!this.filters) {
      this.filters = [];
    }
    if (!this.filters?.dynamicFieldValueSearches) {
      this.filters.dynamicFieldValueSearches = [];
    }
    const fil = this.filters?.dynamicFieldValueSearches?.find(f => f.dynamicFieldId === this.dynamicFieldId);
    if (!fil) {
      if (!event.items || event.items.length === 0) { return; }
      this.filters.dynamicFieldValueSearches.push({
        dynamicFieldId: this.dynamicFieldId,
        guidValues: event.items,
        multiplePickListFlag: event.multipleValueFlag,
        valueType: 5
      });
    } else {
      if (event.items && event.items.length > 0) {
        fil.guidValues = event.items;
      } else {
        const index = this.filters?.dynamicFieldValueSearches?.findIndex(f => f.dynamicFieldId === this.dynamicFieldId);
        this.filters.dynamicFieldValueSearches.splice(index, 1);
      }
    }

    this._picklistItems = event.items;
    // this.selectCtrl.patchValue(event.items, {emitEvent: false})
  }

  selectAll() {
    this.selectCtrl.patchValue(this.filteredOptions.map(value => value.key));
  }

  deselectAll() {
    this.selectCtrl.patchValue(null);
  }
}
