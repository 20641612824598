import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { environment } from '@env/environment';

import {
  Response,
  FilterRequest,
  SystemSetting,
  SystemSettingType,
  SystemSettingUpdateRequest,
  SystemSettingUpdateAllRequest,
  SystemSettingMailProviderType,
  Initial,
  SystemSettingTypeAsString,
} from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class SystemSettingService {
  private systemSettings: SystemSetting[] = [];

  constructor(private http: HttpClient) { }

  list(request?: FilterRequest): Observable<Response<SystemSetting[]>> {
    return this.http.post<Response<SystemSetting[]>>(environment.apiUrl + '/api/SystemSetting/List', request);
  }

  search(request?: FilterRequest): Observable<Response<SystemSetting[]>> {
    return this.http.post<Response<SystemSetting[]>>(environment.apiUrl + '/api/SystemSetting/Search', request);
  }

  updateAll(request: SystemSettingUpdateAllRequest[]): Observable<Response<SystemSetting[]>> {
    return this.http.post<Response<SystemSetting[]>>(environment.apiUrl + '/api/SystemSetting/UpdateAll', request);
  }

  update(request: SystemSettingUpdateRequest): Observable<Response<SystemSetting[]>> {
    return this.http.post<Response<SystemSetting[]>>(environment.apiUrl + '/api/SystemSetting/Update', request);
  }

  getSystemSetting(systemSettingId: SystemSettingTypeAsString): Observable<SystemSetting> {
    // When system settings loaded before, return from cache
    const cached = this.systemSettings.find((item) => systemSettingId === item.systemSettingId);
    if (cached) {
      return of(cached);
    }

    return this.http
      .post<Response<SystemSetting>>(
        `${environment.apiUrl}/api/SystemSetting/Get`,
        JSON.stringify(systemSettingId),
        { headers: new HttpHeaders({ 'content-type': 'application/json' }) }
      )
      .pipe(
        map((response) => response.data),
        tap((systemSetting) => this.systemSettings.push(systemSetting))
      );
  }

  isMailProviderSelected(systemSettingValueId: SystemSettingMailProviderType): Observable<boolean> {
    return this.getSystemSetting(SystemSettingTypeAsString.MAIL_PROVIDERS)
      .pipe(
        map((systemSetting) => {
          const value = systemSetting.lookupValues.find((item) => systemSettingValueId === item.systemSettingValueId);

          if (!value) {
            return false;
          }

          return true === value.selected;
        })
      );
  }
}
