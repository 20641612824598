import moment from 'moment';
import { SystemUser } from '../system-user/system-user.model';
import { CustomerExperienceMainCategory, FilterRequest } from '@core/api';


export enum CustomerExperienceCategoryStatusUsage {
  CUSTOM = '2e45e0b6-9929-4a15-a48e-26922236bec6',
  DEFINED = '6e996cdd-03d4-452a-a1e7-8e562576bb7f'
}

export enum BaseStatus {
  OPEN = '7b8872d2-7649-4002-b1bb-1760a530aee2',
  ASSIGNED = '70c7a568-5ef9-4b57-9d78-a91207a37f60',
  INPROGRESS = '26b77f64-6bd8-4502-96b6-fd7f455f61bb',
  SOLVED = 'a6cc3167-b39f-4054-9a0a-d5bc723fe8ab'
}

export interface BaseStatusModel {
  cxStatusId: string;
  name: string;
  readOnlyFlag: boolean;
  cxStatusDefaultTheme: {
    cxStatusDefaultThemeId: string;
    cxStatusId: string;
    backgroundColor: string;
    orderBy: number;
    readOnlyFlag: boolean;
  };
}

interface CustomerExperienceBaseCategory {
  name?: string;
  backgroundColor?: string;
  code?: string;
  orderBy?: number;
  createdUser?: SystemUser;
  createdBy?: string;
  createdOn?: moment.Moment;
  readOnlyFlag?: boolean;
  activeFlag?: boolean;
  usedFlag?: boolean;
}

export interface CustomerExperienceCategory extends CustomerExperienceBaseCategory {
  cxCategoryId?: string;
  cxCategoryStatusUsageId?: string;
  cxMainCategoryId?: string;
  cxMainCategory?: CustomerExperienceMainCategory;
  cxCategoryStatuses?: CustomerExperienceCategoryStatus[];
  cxStatusDefaultTheme?: CustomerExperienceStatusDefaultTheme;
}

export interface CustomerExperienceStatusDefaultTheme {
  cxStatusDefaultThemeId?: string;
  cxStatusId?: string;
  backgroundColor?: string;
  orderBy?: number;
  createdOn?: moment.Moment;
  readOnlyFlag?: boolean;
}

export interface CustomerExperienceCategoryRequest {
  cxCategoryId?: string;
  name?: string;
  backgroundColor?: string;
  orderBy?: number;
  cxCategoryStatusUsageId?: string;
  activeFlag?: boolean;
  usedFlag?: boolean;
  cxMainCategoryId?: string;
  cxCategoryStatuses?: { cxCategoryStatusId: string; orderBy: number; activeFlag: boolean; name: string; backgroundColor: string; }[];
}

export interface CustomerExperienceCategoryStatus {
  activeFlag?: boolean;
  backgroundColor: string;
  createdOn?: moment.Moment;
  cxCategoryId?: string;
  cxCategoryStatusId?: string;
  cxStatus?: {
    createdOn: moment.Moment;
    cxStatusId: string;
    name: string;
    parentCxStatus: {
      createdOn: moment.Moment;
      cxStatusId: string;
      name: string;
      readOnlyFlag: boolean;
    };
    parentCxStatusId: string;
    readOnlyFlag: boolean;
    subStatuses?: {
      createdOn: moment.Moment;
      cxStatusId: string;
      name: string;
      parentCxStatus: {
        createdOn: moment.Moment;
        cxStatusId: string;
        name: string;
        readOnlyFlag: boolean;
      };
      parentCxStatusId: string;
      readOnlyFlag: boolean;
    };
  };
  cxStatusId?: string;
  name: string;
  orderBy?: number;
  readOnlyFlag?: boolean;
  usedFlag?: boolean;

  subStatuses?: CustomerExperienceCategorySubStatus[];
  parentCxStatusId?: string;
}

export interface CustomerExperienceCategorySubStatus extends CustomerExperienceCategoryStatus {
  cxParentStatusId?: string;
}

export interface CustomerExperienceStatusSearchRequest extends FilterRequest {
  filter?: {
    cxParentStatusId?: string;
    cxCategoryId?: string;
    activeFlag?: boolean;
  };
}
export interface CustomerExperienceStatusRequest {
  cxCategoryStatusId?: string;
  cxStatusId?: string;
  cxParentStatusId?: string;
  activeFlag: boolean;
  backgroundColor: string;
  name: string;
  orderBy: number;
}

export interface CustomerExperienceCategoryStatusFilter {
  name: string;
  cxCategoryStatusIds: string[];
}
