import { ActivityActions, ActivityActionType } from '@core/store';

export interface ActivityState{
  currentStatus: boolean;
}

export const ActivityState: ActivityState = {
  currentStatus: false
};

export function activityReducer(state = ActivityState, action: ActivityActions): ActivityState{
  switch (action.type){
    case ActivityActionType.ACTIVITY_INSERT_STATUS:
      return {
        ...state,
        currentStatus: action.message
      };
    default:
      return state;
  }
}
