import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

import * as signalR from '@microsoft/signalr';
import { AuthService } from '@core/auth/auth.service';
import { CustomConsole } from 'src/app/shared/utils/custom-console';
import { MailService } from '@core/api';

@Injectable({
    providedIn: 'root'
})
export class NotificationSignalRService {

    public static connectionId: string;
    console = new CustomConsole();

    private hubConnection: signalR.HubConnection;

    public startConnection = () => {

        this.hubConnection = new signalR.HubConnectionBuilder()
            // tslint:disable-next-line:max-line-length
            .withUrl(`${environment.socketUrl}/notificationhub?x-client=${environment.socketTenant}`, {
                accessTokenFactory: () => AuthService.getToken(),
                transport: signalR.HttpTransportType.WebSockets,
                skipNegotiation: true
            })
            .configureLogging(signalR.LogLevel.Information)
            .build();

        this.hubConnection.keepAliveIntervalInMilliseconds = 1000 * 60 * 10;
        this.hubConnection.serverTimeoutInMilliseconds = 1000 * 60 * 60;

        this.hubConnection.onclose((err) => {
            if (err) {
                if (err.stack.includes('WebSocket closed with status code: 1006 ()')) { setTimeout(() => { location.reload(); }, 1000); }
                setTimeout(() => {
                    this.hubConnection.start()
                        .then(() => this.console.info('Websocket Connection Established'))
                        .catch(error => this.console.error('SignalR Connection Error: ', error));
                }, 500);
            }
        });

        this.hubConnection
            .start()
            .then(() => {
                this.getConnectionId();
                this.console.info('[NotificationSignalR] Connection started');
            })
            .catch(err => this.console.info('Error while starting connection: ' + err));
    }

    public getConnectionId = () => {
        this.hubConnection.on('GetConnectionId', (connectionId: string) => {
            console.info('[NotificationSignalR] received GetConnectionId.', { connectionId });
            NotificationSignalRService.connectionId = connectionId;
            console.info('Connection Id', connectionId);
        });
    }

    public getHubConnection(): signalR.HubConnection {
        return this.hubConnection;
    }

    public disconnectNotificationListener = () => {
        if (!this.hubConnection) {
            return;
        }
        this.hubConnection?.stop();
    }

    constructor() {
    }
}
