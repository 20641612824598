import { InitialSetting, SalesOrganizationSettingInfo } from '@core/api';

import {
    SalesOrganizationSettingActions, SalesOrganizationSettingActionTypes
} from '@core/store';

export interface SalesOrganizationSettingState {
    salesOrganizationSettings: InitialSetting[];
    isLoading: boolean;
    errorMessage: string;
    errorCode: string;
}

export const salesOrganizationSettingState: SalesOrganizationSettingState = {
    salesOrganizationSettings: JSON.parse(localStorage.getItem('sales_organization_setting')),
    isLoading: false,
    errorMessage: null,
    errorCode: null,
};

export function salesOrganizationSettingReducer(state = salesOrganizationSettingState, action: SalesOrganizationSettingActions): SalesOrganizationSettingState {
    switch (action.type) {
        case SalesOrganizationSettingActionTypes.GET_SALES_ORGANIZATION_SETTING:
            return {
                ...state,
                salesOrganizationSettings: null,
                errorMessage: null,
                errorCode: null,
                isLoading: true
            };
        case SalesOrganizationSettingActionTypes.GET_SALES_ORGANIZATION_SETTING_SUCCESS:
            return {
                ...state,
                salesOrganizationSettings: action.payload,
                errorMessage: null,
                errorCode: null,
                isLoading: false,
            };
        case SalesOrganizationSettingActionTypes.GET_SALES_ORGANIZATION_SETTING_ERROR:
            return {
                ...state,
                isLoading: false,
                errorCode: action.payload.errorCode,
                errorMessage: action.payload.message
            };
        case SalesOrganizationSettingActionTypes.GET_SALES_ORGANIZATION_SETTING_CLEAR:
            return {
                salesOrganizationSettings: null,
                isLoading: false,
                errorMessage: null,
                errorCode: null,
            };
        default:
            return state;
    }
}
