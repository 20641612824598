export enum MailIntegrationType {
  OUTLOOK = 0,
  GMAIL = 1
}

export enum MailTabType {
  INFO = 'info',
  LINK = 'link',
  TIMELINE = 'timeline'
}

export enum MailLinkType {
  CARD = 'dd2e3f54-e224-4306-a012-0a280a1dd8bc',
  CONTACT = 'd8a76d1b-cdea-4592-a2f1-6f1f0944f11f',
  ACTIVITY = '353ff959-e925-4e1f-b71c-a344ddcd8ed5',
  CUSTOMER = 'cff74faf-7cbf-4c86-ab6b-65e888c57ee7',
  ASSIGNMENT = '88aaf649-cd98-441d-b6c7-bd21180ad1bd',
  LEAD_DRAFT = 'da06ec61-bf46-4df0-ac3c-38f34c4de20e',
  OPPORTUNITY = '6365e123-0299-4c04-8302-405bd73a8daa',
}
