<ng-template #routeDisplay let-route="route">
  {{ route.name }}
  <span class="text-hint" *ngIf="route.assignedUser">
    - {{ [route.assignedUser.firstName, route.assignedUser.lastName].join(' ') }}
  </span>
  <span fxHide.lt-md="" class="text-hint" *ngIf="route.description"> - {{ route.description }}</span>
</ng-template>

<mat-select #salesRoute [tabIndex]="tabIndex" [formControl]="selectCtrl" [compareWith]="compareWith"
  (selectionChange)="onSelectionChange()" (click)="scrollTop(salesRoute)" (closed)="clearFilteredList()">

  <input matInput class="mt-4 ml-4 mb-2" *ngIf="salesRouteDefinitions.length > 0" [placeholder]="'GENERAL.SEARCH_ELLIPSIS' | translate" [(ngModel)]="searchText"
    (ngModelChange)="onKey($event)" (keydown)="checkKey($event)">

  <mat-option *ngIf="salesRouteDefinitions.length === 0" disabled>{{"GENERAL.ROUTE_NOT_FOUND" | translate}}</mat-option>

  <mat-select-trigger>
    <ng-container *ngIf="value" [ngTemplateOutlet]="routeDisplay" [ngTemplateOutletContext]="{ route: value }">
    </ng-container>
  </mat-select-trigger>

  <mat-option *ngFor="let route of filteredSalesRoutes" [value]="route">
    <ng-container [ngTemplateOutlet]="routeDisplay" [ngTemplateOutletContext]="{ 'route': route }"></ng-container>
  </mat-option>
  
</mat-select>

<mat-progress-spinner *ngIf="loading === true" diameter="16" mode="indeterminate"></mat-progress-spinner>