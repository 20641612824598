<mat-chip-list #chipList #origin="matAutocompleteOrigin" matAutocompleteOrigin [disabled]="disabled">
  <mat-chip
    *ngFor="let item of value"
    [removable]="true"
    (removed)="onRemoveChip(item)">
    {{ item.tag }}
    <ic-icon [icon]="icCancel" [inline]="true" matChipRemove></ic-icon>
  </mat-chip>
  <input
    #input
    [tabIndex]="tabIndex"
    [formControl]="inputCtrl"
    [matAutocomplete]="auto"
    [matAutocompleteConnectedTo]="origin"
    [matChipInputFor]="chipList"
    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
    [matChipInputAddOnBlur]="false"
    [placeholder]="placeholder"
    [readOnly]="loading"
    (matChipInputTokenEnd)="onAddToken($event)"
    (focus)="reset()">
</mat-chip-list>
<mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelect($event)" [autoActiveFirstOption]="true">
  <ng-container *ngIf="this.hashtags">
    <mat-option *ngFor="let item of hashtags | orderBy: 'tag': false" [value]="item">{{ item.tag }}  </mat-option>
  </ng-container>
</mat-autocomplete>
<mat-progress-spinner *ngIf="loading === true" diameter="16" mode="indeterminate"></mat-progress-spinner>
