import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

import { PagedResponse, AirPort, FilterRequest } from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class AirPortService {
  constructor(private http: HttpClient) { }

  search(request: FilterRequest): Observable<PagedResponse<AirPort>> {
    return this.http.post<PagedResponse<AirPort>>(environment.apiUrl + '/api/AirPort/Search', request);
  }
}
