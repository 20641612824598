import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Response } from '@core/api/api.model';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class RedisCacheService {

  constructor(private http: HttpClient) { }

  redisFlushDatabases(redisCacheType: string): Observable<Response<any>> {
    return this.http.post<Response<any>>(environment.apiUrl + `/api/RedisCache/RedisFlushDatabases?redisCacheType=${redisCacheType}`, {});
  }
}
