import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import { Observable } from 'rxjs/internal/Observable';
import { ContractInfoType, FilterRequest, Response } from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class ContractInfoTypeService {
  constructor(private http: HttpClient) { }

  list(request: FilterRequest): Observable<Response<ContractInfoType[]>> {
    return this.http.post<Response<ContractInfoType[]>>(environment.apiUrl + '/api/ContractInfoType/List', request);
  }
}
