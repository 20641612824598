import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'net-full-spinner',
  templateUrl: './full-spinner.component.html',
  styleUrls: ['./full-spinner.component.scss']
})
export class FullSpinnerComponent {

  @HostBinding('class.active') @Input() active = false;

  constructor() { }
}
