import { KeyValue } from '@angular/common';

import { of } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { ApiDataSource } from '@core/api/api.data-source';
import { MailLink, MailLinkSearchRequest, MailLinkType, MailService, OrderType } from '@core/api';

export class MailLinkDataSource extends ApiDataSource<MailLink> {
  orderBy = 'createdOn'; // TODO replace with mailDate
  orderType = 'DESC' as OrderType;

  // Link type option key value list
  linkTypes$ = of<KeyValue<string, string>[]>([
    { key: MailLinkType.CARD, value: this.translate.instant('GENERAL.MAIL_LINK_TYPE_CARD') },
    { key: MailLinkType.CONTACT, value: this.translate.instant('GENERAL.MAIL_LINK_TYPE_CONTACT') },
    { key: MailLinkType.ACTIVITY, value: this.translate.instant('GENERAL.MAIL_LINK_TYPE_ACTIVITY') },
    { key: MailLinkType.CUSTOMER, value: this.translate.instant('GENERAL.MAIL_LINK_TYPE_CUSTOMER') },
    { key: MailLinkType.ASSIGNMENT, value: this.translate.instant('GENERAL.MAIL_LINK_TYPE_ASSIGNMENT') },
    { key: MailLinkType.LEAD_DRAFT, value: this.translate.instant('GENERAL.MAIL_LINK_TYPE_LEAD_DRAFT') },
    { key: MailLinkType.OPPORTUNITY, value: this.translate.instant('GENERAL.MAIL_LINK_TYPE_OPPORTUNITY') },
  ]);

  constructor(
    private translate: TranslateService,
    private mailService: MailService,
    public initialFilter: MailLinkSearchRequest['filter'] = {},
  ) {
    super(initialFilter);
  }

  load(): void {
    // Init filter with data source's default filter
    const filter: MailLinkSearchRequest['filter'] = { ...this.initialFilter, ...this.filter };

    // If filter keyword exists, filter data
    if (this.keyword) {
      filter.searchText = this.keyword;
    }

    // Update loading state
    this.loadingSubject.next(true);

    // Create request parameters
    const request: MailLinkSearchRequest = this.getRequest();

    if (this.paginator?.pageSize) {
      request.pageSize = this.paginator.pageSize;
    }

    // Add filters to request
    request.filter = filter;

    // Fetch data
    this.mailService
      .link
      .search(request, false, true)
      .pipe(finalize(() => this.loadingSubject.next(false)))
      .subscribe(response => {
        // Update count and data subjects
        this.dataSubject.next(response.data.results);
        this.dataCountSubject.next(response.data.rowCount);
      });
  }
}
