export enum BoardActionType {
    BoardNameUpdate = 'BoardNameUpdate',
    BoardHashtagAdd = 'BoardHashtagAdd',
    BoardHashtagUpdate = 'BoardHashtagUpdate',
    BoardUserEdit = 'BoardUserEdit',
    BoardCustomFieldAdd = 'BoardCustomFieldAdd',
    BoardCustomFieldDelete = 'BoardCustomFieldDelete',
    BoardCustomFieldSetActiveFlag = 'BoardCustomFieldSetActiveFlag',
    BoardCardAddRange = 'BoardCardAddRange',
    BoardSetAdmin = 'BoardSetAdmin',
    BoardGetCardCount = 'BoardGetCardCount'
}
