import { Component, Input, OnInit } from '@angular/core';
import { MailService, SystemSettingType } from '@core/api';
import { getSystemSettingValue } from '@core/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { FileSaverService } from 'ngx-filesaver';
import { fileIcon } from 'src/app/mail/mail.helper';

import icDocument from '@iconify/icons-ic/baseline-assignment';
import icDownloadFill from '@iconify/icons-mingcute/download-fill';
import { humanizeFileSize } from 'src/app/shared/utils/humanize-file-size';

@UntilDestroy()
@Component({
  selector: 'net-customer-experience-mail-attachment',
  templateUrl: './customer-experience-mail-attachment.component.html',
  styleUrls: ['./customer-experience-mail-attachment.component.scss']
})
export class CustomerExperienceMailAttachmentComponent implements OnInit {

  icDownloadFill = icDownloadFill;
  icDocument = icDocument;

  loading = false;
  scanFlag = false;
  fileName: string;

  downloadedAttachmentId: string;

  @Input() file: {
    attachmentId: string;
    fileName: string;
    fileSize: number;
    extension: string;
  };
  @Input() directDownload = false;
  @Input() mode: 'chatView' | 'mailView' = 'mailView';

  constructor(
    private fileSaver: FileSaverService,
    private mailService: MailService,
    private store: Store,
  ) {
    this.store.select(getSystemSettingValue(SystemSettingType.MAIL_SCAN)).pipe(untilDestroyed(this))
      .subscribe(scanFlag => {
        this.scanFlag = scanFlag as boolean;
      });
  }

  ngOnInit() {
    this.fileName = [this.file.fileName, this.file.extension.toLowerCase()].join('');
  }

  download() {
    if (this.loading) {
      return;
    }

    this.loading = true;

    if (this.directDownload) {
      this.downloadMailIntegration();
      return;
    }
  }

  get icon(): object | null {
    return fileIcon(this.file.extension) ?? icDocument;
  }

  get extension(): string {
    return this.file.extension.toUpperCase();
  }

  downloadMailIntegration() {
    this.mailService.downloadMailIntegration(this.file['attachmentId'])
      .subscribe(
        (response) => {
          if (response.status !== 200) {
            console.error(`Downloading file '${this.fileName}' failed.`);
            this.loading = false;
            return;
          }

          this.fileSaver.save(response.body, this.fileName);
          this.loading = false;

        },
        (error) => {
          console.error(error);
          this.loading = false;
        }
      );

  }

  get fileSize(): string {
    return humanizeFileSize(this.file.fileSize, 0);
  }
}

