import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

import { BaseResponse, OpportunityCopySummary, OpportunityCopySummaryUpdateOrderRequest, OpportunityCopySummaryUpdateRequest, Response } from '@core/api';

@Injectable({
  providedIn: 'root',
})
export class OpportunityCopySummaryService {
  constructor(private http: HttpClient) {}

  list(salesOrganizationId: string): Observable<Response<OpportunityCopySummary[]>> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<OpportunityCopySummary[]>>(
      environment.apiUrl + '/api/CopySummarySettingInfo/List',
      JSON.stringify(salesOrganizationId),
      { headers }
    );
  }

  listUpdate(request: OpportunityCopySummaryUpdateRequest): Observable<Response<OpportunityCopySummary[]>> {
    return this.http.post<Response<OpportunityCopySummary[]>>(environment.apiUrl + '/api/CopySummarySettingInfo/ListUpdate', request);
  }

  updateOrder(request: OpportunityCopySummaryUpdateOrderRequest): Observable<BaseResponse> {
    return this.http.post<BaseResponse>(environment.apiUrl + '/api/CopySummarySettingInfo/UpdateOrder', request);
  }
}
