
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

import {
  City,
  Country,
  Response,
  UserRoutes,
  PagedResponse,
  SalesRouteDefinition,
  SalesRouteCityListRequest,
  SalesRouteDefinitionRequest,
  SalesRouteCountryListRequest,
  SalesRouteDefinitionFilterRequest,
  SalesRouteCountyListRequest,
  County,
  SalesRouteDefinitionDownload
} from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class SalesRouteDefinitionService {
  constructor(private http: HttpClient) { }

  search(request?: SalesRouteDefinitionFilterRequest): Observable<PagedResponse<SalesRouteDefinition>> {
    return this.http.post<PagedResponse<SalesRouteDefinition>>(environment.apiUrl + '/api/SalesRouteDefinition/Search', request);
  }

  exportExcel(request?: SalesRouteDefinitionFilterRequest): Observable<Response<SalesRouteDefinitionDownload>> {
    return this.http.post<Response<SalesRouteDefinitionDownload>>(environment.apiUrl + '/api/SalesRouteDefinition/Download', request.filter);
  }

  get(salesRouteDefinitionId?: string): Observable<Response<SalesRouteDefinition>> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    // tslint:disable-next-line:max-line-length
    return this.http.post<Response<SalesRouteDefinition>>(environment.apiUrl + '/api/SalesRouteDefinition/Get', JSON.stringify(salesRouteDefinitionId), { headers });
  }

  update(request: any): Observable<Response<SalesRouteDefinition>> {
    return this.http.post<Response<SalesRouteDefinition>>(environment.apiUrl + '/api/SalesRouteDefinition/Update', request);
  }

  insert(request: any): Observable<Response<SalesRouteDefinition>> {
    return this.http.post<Response<SalesRouteDefinition>>(environment.apiUrl + '/api/SalesRouteDefinition/Insert', request);
  }

  delete(request: SalesRouteDefinitionRequest): Observable<Response<SalesRouteDefinition>> {
    return this.http.post<Response<SalesRouteDefinition>>(environment.apiUrl + '/api/SalesRouteDefinition/Delete', request);
  }

  salesRouteCityList(request?: SalesRouteCityListRequest): Observable<Response<City[]>> {
    return this.http.post<Response<City[]>>(environment.apiUrl + '/api/SalesRouteDefinition/SalesRouteCityList', request);
  }

  salesRouteCityListForLeadDraft(request?: SalesRouteCityListRequest): Observable<Response<City[]>> {
    return this.http.post<Response<City[]>>(environment.apiUrl + '/api/SalesRouteDefinition/SalesRouteCityListForLeadDrafts', request);
  }

  salesRouteCountryList(request?: SalesRouteCountryListRequest): Observable<Response<Country[]>> {
    return this.http.post<Response<Country[]>>(environment.apiUrl + '/api/SalesRouteDefinition/SalesRouteCountryList', request);
  }

  salesRouteCountyList(request?: SalesRouteCountyListRequest): Observable<Response<County[]>> {
    return this.http.post<Response<County[]>>(environment.apiUrl + '/api/SalesRouteDefinition/SalesRouteCountyList', request);
  }

  userRoutes(): Observable<Response<UserRoutes[]>> {
    return this.http.post<Response<UserRoutes[]>>(environment.apiUrl + '/api/SalesRouteDefinition/UserRoutes', {});
  }
}
