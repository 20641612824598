import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FilterRequest, PagedResponse, Response } from '../api.model';
import { environment } from '@env/environment';
import { Segment, SegmentUpdateRequest } from './segment.model';

@Injectable({
    providedIn: 'root'
})
export class SegmentService {

    constructor(private http: HttpClient) { }

    list() {
        const request: FilterRequest = {
            orderType: 'ASC',
            orderBy: 'orderBy'
        };
        return this.http.post<Response<Segment[]>>(environment.apiUrl + '/api/Segment/List', request);
    }

    updateList(request: SegmentUpdateRequest[]) {
        return this.http.post<Response<Segment[]>>(environment.apiUrl + '/api/Segment/UpdateList', request);
    }

    delete(request: { segmentId: string }) {
        return this.http.post<Response<Segment>>(environment.apiUrl + '/api/Segment/Delete', request);
    }

}
