import { AccountStatus, SalesRouteDefinition, SectorMain, SurveyMail } from '@core/api';
import { NetPromoterScoreReportActionTypes, NetPromoterScoreReportActions } from './net-promoter-score-report.action';

export interface NetPromoterScoreReportState {
    salesRouteDefinitions: SalesRouteDefinition[];
    industries: SectorMain[];
    accountStatuses: AccountStatus[];
    salesOrganizationId: string;
    mailList: SurveyMail[];
}

export const netPromoterScoreState: NetPromoterScoreReportState = {
    salesRouteDefinitions: [],
    industries: [],
    accountStatuses: [],
    salesOrganizationId: null,
    mailList: []
};

export function netPromoterScoreReportReducer(state = netPromoterScoreState, action: NetPromoterScoreReportActions): NetPromoterScoreReportState {

    switch (action.type) {
        case NetPromoterScoreReportActionTypes.SET_NET_PROMOTER_SCORE_REPORT_SALES_ROUTE_DEFINITION:
            return {
                ...state,
                salesRouteDefinitions: action.salesRouteDefinitions
            };
        case NetPromoterScoreReportActionTypes.SET_NET_PROMOTER_SCORE_REPORT_SECTOR_MAIN:
            return {
                ...state,
                industries: action.industries
            };
        case NetPromoterScoreReportActionTypes.SET_NET_PROMOTER_SCORE_REPORT_ACCOUNT_STATUS:
            return {
                ...state,
                accountStatuses: action.accountStatuses,
                salesOrganizationId: action.salesOrganizationId
            };
        case NetPromoterScoreReportActionTypes.SET_NET_PROMOTER_SCORE_REPORT_MAIL_LIST:
            return {
                ...state,
                mailList: action.mailList
            };
        default:
            return {
                ...state
            };
    }

}
