import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import { FilterRequest, SeaPort, PagedResponse } from '@core/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SeaPortService {
  constructor(private http: HttpClient) { }

  search(request: FilterRequest): Observable<PagedResponse<SeaPort>> {
    return this.http.post<PagedResponse<SeaPort>>(environment.apiUrl + '/api/SeaPort/Search', request);
  }
}
