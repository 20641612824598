import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component, DoCheck, ElementRef, HostBinding, Input, OnDestroy,
  OnInit, Optional, Self, ViewChild
} from '@angular/core';
import { ControlValueAccessor, NgControl, UntypedFormControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { SalesOrganization, SalesOrganizationService } from '@core/api';
import { AuthService } from '@core/auth/auth.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'net-select-survey-mail-sales-organization',
  templateUrl: './select-survey-mail-sales-organization.component.html',
  styleUrls: ['./select-survey-mail-sales-organization.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: SelectSurveyMailSalesOrganizationComponent
    }
  ]
})
export class SelectSurveyMailSalesOrganizationComponent implements OnInit, OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<string | string[]>  {

  static nextId = 0;
  stateChanges = new Subject<void>();
  selectCtrl = new UntypedFormControl();
  focused = false;
  errorState = false;
  describedBy = '';
  controlType = 'multi-select-sales-organization';


  salesOrganizations: SalesOrganization[] = [];

  @Input() multiple = false;
  @Input() isDisabled = false;
  @Input() tabIndex;
  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    if (value) {
      this.selectCtrl.disable();
    } else {
      this.selectCtrl.enable();
    }
    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): string | string[] { return this.selectCtrl.value; }
  set value(value: string | string[]) {
    this.selectCtrl.setValue(value);
    this.onChange(value);
    this.stateChanges.next();
  }

  @ViewChild('salesOrganizationSelect') salesOrganizationSelect: MatSelect;
  @HostBinding('id') id = `multi-select-sales-organization-${SelectSurveyMailSalesOrganizationComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  get shouldLabelFloat() { return this.focused || !this.empty; }

  get empty() { return !this.value; }


  constructor(
    private salesOrganizationService: SalesOrganizationService,
    private authService: AuthService,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl
  ) {
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.getSalesOrganizationList();
  }

  ngOnInit(): void {
  }


  getSalesOrganizationList() {
    if (!this.salesOrganizations.length) {
      this.salesOrganizationService.search({
        systemUserId: this.authService.authUser.userId
      }).pipe(
        map(response => response.data),
        tap(salesOrganizations => {
          if (salesOrganizations.length === 1 && !this.value) {
            this.value = this.multiple ? [salesOrganizations[0].salesOrganizationId] : salesOrganizations[0].salesOrganizationId;
          }
        }),
        untilDestroyed(this)
      ).subscribe(salesOrganizations => this.salesOrganizations = salesOrganizations);
    }
  }

  getToolTip() {
    if (this.salesOrganizations.length > 0 && Array.isArray(this.value)) {
      return this.value.map(x => this.salesOrganizations.find(y => y.salesOrganizationId === x).name).join('\n');
    }
  }

  onSelectionChange() {
    this.onChange(this.value);
    this.stateChanges.next();
  }

  openedChange(isOpened: boolean) {
    if (isOpened) {
      const panel = this.salesOrganizationSelect.panel.nativeElement;
      panel.scrollTop = 0;
    }
  }

  selectAll() {

    const allSalesOrganizations = this.salesOrganizations.map(s => s.salesOrganizationId);
    this.selectCtrl.patchValue(allSalesOrganizations);
    this.value = allSalesOrganizations;

  }

  deselectAll() {

    this.selectCtrl.patchValue(null);
    this.value = [];
  }


  compareWith = (one: string, two: string) => one && two && one === two;
  onChange = (_: any) => { };
  selectionChange = (_: any) => { };
  onTouched = () => { };
  onContainerClick = () => { };
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');
  writeValue = (value: string) => {
    this.value = value;
    this.selectCtrl.setValue(value);
  }

  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }

}
