import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';

import { Observable } from 'rxjs';

import {
  Response,
  PagedResponse,
  FilterRequest,
} from '@core/api';
import {
  SurveyReport,
  SurveyReportRequest,
  SurveyReportFilterRequest,
  SurveyReportDownload
} from './survey-report.model';

@Injectable({
  providedIn: 'root'
})
export class SurveyReportService {
  constructor(private http: HttpClient) { }

  search(request: SurveyReportFilterRequest): Observable<PagedResponse<SurveyReport>> {
    return this.http.post<PagedResponse<SurveyReport>>(environment.apiUrl + '/api/Report/NpsReportTableData', request);
  }

  exportExcel(request: SurveyReportFilterRequest): Observable<Response<SurveyReportDownload[]>> {
    return this.http.post<Response<SurveyReportDownload[]>>(environment.apiUrl + '/api/Report/NpsReportTableDataDownload', request.filter);
  }

  scoreChart(request: any): Observable<Response<any>> {
    return this.http.post<Response<any>>(environment.apiUrl + '/api/Report/NpsReportScoreChart', request);
  }

  trendChart(request: any): Observable<Response<any>> {
    return this.http.post<Response<any>>(environment.apiUrl + '/api/Report/NpsTrendChartData', request);
  }
}
