import { Pipe, PipeTransform } from '@angular/core';
import { AccountStatus } from '@core/api';

@Pipe({
  name: 'npsAccountStatusBySalesOrganization'
})
export class NpsAccountStatusBySalesOrganizationPipe implements PipeTransform {

  transform(list: any[], salesOrganizationId: string): AccountStatus[] {
    if (!list || !salesOrganizationId) {
      return [];
    }

    return list.map(accountStatus => {
      if (accountStatus.accountStatusProperties) {
        const selectedAccountStatus = accountStatus.accountStatusProperties
          .find(accountStatusPropertity => accountStatusPropertity.salesOrganizationId === salesOrganizationId);
        if (selectedAccountStatus) {
          accountStatus = {
            ...accountStatus,
            name: selectedAccountStatus.name
          };
        }
      }
      return accountStatus;
    });
  }

}
