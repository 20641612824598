<net-board-card-form mode="dialog" [loading]="loading" (save)="onCardSave($event)"
    *ngIf="types.CARD === type"></net-board-card-form>

<net-contact-form mode="dialog" [loading]="loading" [isAccountEnabled]="true" [contact]="null" [newContact]="contact"
    [customer]="account" (save)="onContactSave($event)" *ngIf="types.CONTACT === type"></net-contact-form>

<net-activity-form mode="dialog" [isAccountEnabled]="true" [account]="account" [contact]="contact" [loading]="loading"
    [activity]="null" (save)="onActivitySave($event)" *ngIf="types.ACTIVITY === type"></net-activity-form>

<net-assignment-form mode="dialog" [isAccountEnabled]="true" [account]="account" [loading]="loading"
    (save)="onAssignmentSave($event)" [ticketDetail]="ticketDetail"
    *ngIf="types.ASSIGNMENT === type"></net-assignment-form>

<net-opportunity-form mode="dialog" [account]="account" [contact]="contact" [isAccountEnabled]="true"
    [loading]="loading" [opportunity]="null" (save)="onOpportunitySave($event)"
    *ngIf="types.OPPORTUNITY === type"></net-opportunity-form>