<mat-select
  (selectionChange)="onSelectionChange()"
  [compareWith]="compareWith"
  [disabled]="isDisabled"
  [formControl]="selectCtrl"
  [tabIndex]="tabIndex">
  <mat-option *ngFor="let type of activityTypes"
              [value]="type.activityTypeId">{{ ("ActivityType." + type.name) | translate }}</mat-option>
</mat-select>

<mat-progress-spinner *ngIf="loading === true" diameter="16" mode="indeterminate"></mat-progress-spinner>
