import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: '[netUpperCase]'
})
export class UpperCaseDirective {
  constructor(private control: NgControl, private translate: TranslateService) { }

  @HostListener('input', ['$event']) onInputChange($event) {
    this.control.control.setValue($event.target.value.toLocaleUpperCase(this.translate.currentLang?.slice(0, 2)));
  }
}
