import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { AuthService } from '@core/auth/auth.service';

@Directive({
  selector: '[netNumbersOnly]'
})
export class NumbersOnlyDirective implements OnInit {
  @Input() allowDecimals = true;
  @Input() allowSign = false;
  @Input() allowClear = false;
  @Input() decimalSeparator;
  @Input() decimalDigitCount: number;
  @Input() formName: UntypedFormControl;

  previousValue = '';

  // --------------------------------------
  //  Regular expressions
  integerUnsigned = '^[0-9]*$';
  integerSigned = '^-?[0-9]+$';
  decimalUnsigned = '^[0-9]+(.[0-9]+)?$';
  decimalSigned = '^-?[0-9]+(.[0-9]+)?$';

  constructor(private hostElement: ElementRef, private authService: AuthService) {}

  /**
   * Event handler for host's change event
   * @param e Event handler for host's change event
   */
  @HostListener('change', ['$event']) onChange(e) {
    this.validateValue(this.hostElement.nativeElement.value);
  }

  /**
   * Event handler for host's paste event
   * @param e Event handler for host's paste event
   */
  @HostListener('paste', ['$event']) onPaste(e) {
    // get and validate data from clipboard
    const copyValue: string = e?.clipboardData?.getData('text/plain');
    const value = (this.decimalSeparator === ',') ? copyValue.replace(',', '.') : copyValue;
    this.validateValue(value);
    e.preventDefault();
  }

  /**
   * Event handler for host's keydown event
   * @param e Event handler for host's keydown event
   */
  @HostListener('keydown', ['$event']) onKeyDown(e: KeyboardEvent) {
    const key = this.getName(e);
    const target = e.target as HTMLInputElement;
    const originalValue: string = target.value;
    const cursorPosition: number = target.selectionStart;
    const controlOrCommand = (e.ctrlKey === true || e.metaKey === true);
    const signExists = originalValue.includes('-');
    const separatorExists = originalValue.includes(this.decimalSeparator);

    // allowed keys apart from numeric character
    const allowedKeys = [
      'Backspace', 'ArrowLeft', 'ArrowRight', 'Escape', 'Tab'
    ];

    // when decimals are allowed, add
    // decimal separator to allowed codes when
    // its position is not close to the the sign (-. and .-)
    const separatorIsCloseToSign = (signExists && cursorPosition <= 1);
    if (this.allowDecimals && !separatorIsCloseToSign && !separatorExists) {
      this.decimalSeparator === '.' ? allowedKeys.push('.') : allowedKeys.push(',');
    }

    // when minus sign is allowed, add its
    // key to allowed key only when the
    // cursor is in the first position, and
    // first character is different from
    // decimal separator
    const firstCharacterIsSeparator = (originalValue.charAt(0) !== this.decimalSeparator);
    if (this.allowSign && !signExists &&
      firstCharacterIsSeparator && cursorPosition === 0) {

      allowedKeys.push('-');
    }

    // allow some non-numeric characters
    if (allowedKeys.indexOf(key) !== -1 ||
      // Allow: Ctrl+A and Command+A
      (key === 'a' && controlOrCommand) ||
      // Allow: Ctrl+C and Command+C
      (key === 'c' && controlOrCommand) ||
      // Allow: Ctrl+V and Command+V
      (key === 'v' && controlOrCommand) ||
      // Allow: Ctrl+X and Command+X
      (key === 'x' && controlOrCommand)) {
      // let it happen, don't do anything
      return;
    }

    // save value before keydown event
    this.previousValue = originalValue;
    const isNumber = (new RegExp(this.integerUnsigned)).test(key);
    const isDecimalDigitLegit = this.decimalDigitCount && originalValue.split(this.decimalSeparator)[1]
      ? originalValue.split(this.decimalSeparator)[1].length < this.decimalDigitCount
      : true;

    if (isNumber && isDecimalDigitLegit) { return; } else { e.preventDefault(); }
  }

  /**
   * Test whether value is a valid number or not
   */
  validateValue(value: string): void {
    // choose the appropiate regular expression
    let regex: string;
    if (!this.allowDecimals && !this.allowSign) { regex = this.integerUnsigned; }
    if (!this.allowDecimals && this.allowSign) { regex = this.integerSigned; }
    if (this.allowDecimals && !this.allowSign) { regex = this.decimalUnsigned; }
    if (this.allowDecimals && this.allowSign) { regex = this.decimalSigned; }

    // when a numbers begins with a decimal separator,
    // fix it adding a zero in the beginning
    const firstCharacter = value.charAt(0);
    if (firstCharacter === this.decimalSeparator) {
      value = 0 + value;
    }

    // when a numbers ends with a decimal separator,
    // fix it adding a zero in the end
    const lastCharacter = value.charAt(value.length - 1);
    if (lastCharacter === this.decimalSeparator) {
      value = value + 0;
    }

    // test number with regular expression, when
    // number is invalid, replace it with a zero

    const valid: boolean = (new RegExp(regex)).test(value);
    if (this.formName) {
      this.formName.setValue(valid ? value : this.allowClear ? '' : 0);
    } else {
      this.hostElement.nativeElement.value = valid ? value : this.allowClear ? '' : 0;
    }
  }

  /**
   * Get key's name
   */
  getName(e): string {
    if (e.key) {
      return e.key;
    }

    // Fallback for old browsers
    if (e.keyCode && String.fromCharCode) {
      switch (e.keyCode) {
        case 8: return 'Backspace';
        case 9: return 'Tab';
        case 27: return 'Escape';
        case 37: return 'ArrowLeft';
        case 39: return 'ArrowRight';
        case 188: return ',';
        case 190: return '.';
        case 109: return '-'; // minus in numbpad
        case 173: return '-'; // minus in alphabet keyboard in firefox
        case 189: return '-'; // minus in alphabet keyboard in chrome
        default: return String.fromCharCode(e.keyCode);
      }
    }
  }

  ngOnInit() {
    if (!this.decimalSeparator) {
      this.decimalSeparator = 'tr' === this.authService.numberLanguage ? ',' : '.';
    }
  }
}
