export enum EnumRepeatCycle {
    Weekly = 1,
    Monthly = 2
}

export enum DayOption {
    Day = 1,
    Weekday = 2
}

export enum FirstLastOption {
    First = 1,
    Last = 2
}
