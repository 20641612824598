import icImage from '@iconify/icons-ic/twotone-image';
import icTextSnippet from '@iconify/icons-ic/twotone-text-snippet';
import icPictureAsPdf from '@iconify/icons-ic/twotone-picture-as-pdf';
import { MailLinkType } from '@core/api';
import { TranslateService } from '@ngx-translate/core';

export function fileIcon(name: string): object | null {
  switch (name.split('.').pop().toLowerCase()) {
    case 'pdf':
      return icPictureAsPdf;

    case 'png':
    case 'jpg':
    case 'gif':
    case 'jpeg':
      return icImage;

    case 'txt':
    case 'doc':
    case 'xls':
    case 'docx':
    case 'xlsx':
      return icTextSnippet;

    default: return null;
  }
}

export function getMailLinkTypeName(mailLinkTypeId: string, translate: TranslateService): string {
  switch (mailLinkTypeId) {
    case MailLinkType.CARD: return translate.instant('GENERAL.MAIL_LINK_TYPE_CARD');
    case MailLinkType.CONTACT: return translate.instant('GENERAL.MAIL_LINK_TYPE_CONTACT');
    case MailLinkType.CUSTOMER: return translate.instant('GENERAL.MAIL_LINK_TYPE_CUSTOMER');
    case MailLinkType.ACTIVITY: return translate.instant('GENERAL.MAIL_LINK_TYPE_ACTIVITY');
    case MailLinkType.LEAD_DRAFT: return translate.instant('GENERAL.MAIL_LINK_TYPE_LEAD_DRAFT');
    case MailLinkType.ASSIGNMENT: return translate.instant('GENERAL.MAIL_LINK_TYPE_ASSIGNMENT');
    case MailLinkType.OPPORTUNITY: return translate.instant('GENERAL.MAIL_LINK_TYPE_OPPORTUNITY');
  }

  return 'N/A';
}
