<input
  #input
  matInput
  [matTooltip]="'GENERAL.PLEASE_SELECT_CITY' | translate"
  [tabIndex]="tabIndex"
  [formControl]="inputCtrl"
  [matAutocomplete]="auto"
  [matTooltipDisabled]="!!this.selectedCity"
  [readonly]="!this.selectedCity"
  (blur)="onBlur()">
<mat-autocomplete
  #auto="matAutocomplete"
  [displayWith]="displayName"
  [autoActiveFirstOption]="true"
  (optionSelected)="onSelect($event)"
  (closed)="onClosed()">
  <ng-container *ngIf="this.counties">
    <mat-option *ngFor="let county of counties" [value]="county">{{ county.name }}</mat-option>
  </ng-container>
</mat-autocomplete>
<mat-progress-spinner *ngIf="loading === true" diameter="16" mode="indeterminate"></mat-progress-spinner>
