import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';

import { InputCompetitorComponent } from '../components/input-competitor/input-competitor.component';
import { InputCustomerEmailsComponent } from '../components/input-customer-emails/input-customer-emails.component';
import { InputCustomerPhonesComponent } from '../components/input-customer-phones/input-customer-phones.component';
import { InputHashtagComponent } from '../components/input-hashtag/input-hashtag.component';
import { InputMoneyComponent } from '../components/input-money/input-money.component';
import { InputOpportunityServicesComponent } from '../components/input-opportunity-services/input-opportunity-services.component';
import { InputParticipantComponent } from '../components/input-participant/input-participant.component';
import { InputSliderComponent } from '../components/input-slider/input-slider.component';
import { InputSpecialDayComponent } from '../components/input-special-day/input-special-day.component';
import { InputOfferServiceComponent } from '../../lead/lead-detail/lead-detail-offer-form/input-offer-service/input-offer-service.component';
import { InputCustomerAddressComponent } from '../components/input-customer-address/input-customer-address.component';
import { InputCustomerContactComponent } from '../components/input-customer-contact/input-customer-contact.component';
import { InputNumberComponent } from '../components/input-number/input-number.component';
import { InputLeadDraftComponent } from '../components/input-lead-draft/input-lead-draft.component';
import { InputContactComponent } from '../components/input-contact/input-contact.component';
import { InputAssignmentComponent } from '../components/input-assignment/input-assignment.component';

import { SelectActivityTypeComponent } from '../components/select-activity-type/select-activity-type.component';
import { SelectActivityPurposeComponent } from '../components/select-activity-purpose/select-activity-purpose.component';
import { SelectCountryComponent } from '../components/select-country/select-country.component';
import { SelectCityComponent } from '../components/select-city/select-city.component';
import { SelectClearanceComponent } from '../components/select-clearance/select-clearance.component';
import { SelectContactRoleGroupComponent } from '../components/select-contact-role-group/select-contact-role-group.component';
import { SelectCountyComponent } from '../components/select-county/select-county.component';
import { SelectNeighborhoodComponent } from '../components/select-neighborhood/select-neighborhood.component';
import { SelectOrganizationComponent } from '../components/select-organization/select-organization.component';
import { SelectSalesRouteComponent } from '../components/select-sales-route/select-sales-route.component';
import { SelectSalutationComponent } from '../components/select-salutation/select-salutation.component';
import { SelectTimeComponent } from '../components/select-time/select-time.component';
import { SelectCustomComponent } from '../../lead/lead-detail/lead-detail-offer-form/select-custom/select-custom.component';
import { SelectDangerousGoodsComponent } from '../../lead/lead-detail/lead-detail-offer-form/select-dangerous-goods/select-dangerous-goods.component';
import { SelectGoodsTypeComponent } from '../../lead/lead-detail/lead-detail-offer-form/select-goods-type/select-goods-type.component';
import { SelectVehicleTypeComponent } from '../../lead/lead-detail/lead-detail-offer-form/select-vehicle-type/select-vehicle-type.component';
import { SelectIncotermComponent } from '../../lead/lead-detail/lead-detail-offer-form/select-incoterm/select-incoterm.component';
import { SelectOfferUnitComponent } from '../components/select-offer-unit/select-offer-unit.component';
import { SelectFuelCountyComponent } from '../components/select-fuel-county/select-fuel-county.component';
import { SelectFuelCityComponent } from '../components/select-fuel-city/select-fuel-city.component';
import { SelectActivityComponent } from '../components/select-activity/select-activity.component';
import { SelectBoardLinkComponent } from '../../board/dialogs/board-detail-form-dialog/select-board-link/select-board-link.component';
import { SelectSeaPortComponent } from '../components/select-sea-port/select-sea-port.component';
import { SelectCarryingFeatureComponent } from '../components/select-carrying-feature/select-carrying-feature.component';
import { SelectSeaLineComponent } from '../components/select-sea-line/select-sea-line.component';
import { SelectPaymentTermComponent } from '../components/select-payment-term/select-payment-term.component';
import { SelectAirPortComponent } from '../components/select-air-port/select-air-port.component';
import { SelectAirLineComponent } from '../components/select-air-line/select-air-line.component';
import { SelectOpportunityStatusComponent } from '../components/select-opportunity-status/select-opportunity-status.component';
import { SelectOpportunityTypeComponent } from '../components/select-opportunity-type/select-opportunity-type.component';
import { SelectLanguageComponent } from '../components/select-language/select-language.component';
import { SelectTaxOfficeComponent } from '../components/select-tax-office/select-tax-office.component';
import { SelectAccountComponent } from '../components/select-account/select-account.component';
import { SelectSalesOrganizationComponent } from '../components/select-sales-organization/select-sales-organization.component';
import { SelectOpportunitySearchableComponent } from '../components/select-opportunity-searchable/select-opportunity-searchable.component';
import { SelectBoardComponent } from '../components/select-board/select-board.component';
import { SelectBoardCardComponent } from '../components/select-board-card/select-board-card.component';
import { SelectSurveyComponent } from 'src/app/management/survey-management/components/select-survey/select-survey.component';
import { SelectListTypeComponent } from 'src/app/management/survey-management/components/select-list-type/select-list-type.component';
import { SelectSurveyMailSalesOrganizationComponent } from 'src/app/management/survey-management/components/select-survey-mail-sales-organization/select-survey-mail-sales-organization.component';
import { MultiSelectSurveyMailAccountStatusComponent } from 'src/app/management/survey-management/components/multi-select-survey-mail-account-status/multi-select-survey-mail-account-status.component';
import { MultiSelectSurveyMailWarningComponent } from 'src/app/management/survey-management/components/multi-select-survey-mail-warning/multi-select-survey-mail-warning.component';
import { SelectSurveyMailContactFilterSetComponent } from 'src/app/management/survey-management/components/select-survey-mail-contact-filter-set/select-survey-mail-contact-filter-set.component';
import { MultiSelectSurveyMailActivityTypeComponent } from 'src/app/management/survey-management/components/multi-select-survey-mail-activity-type/multi-select-survey-mail-activity-type.component';
import { SelectSurveyMailMainContactTypeComponent } from 'src/app/management/survey-management/components/select-survey-mail-main-contact-type/select-survey-mail-main-contact-type.component';
import { SelectSurveyMailContactTypeComponent } from 'src/app/management/survey-management/components/select-survey-mail-contact-type/select-survey-mail-contact-type.component';
import { SelectContactComponent } from '../components/select-contact/select-contact.component';
import { MultiSelectSalesRouteComponent } from '../components/multi-select-sales-route/multi-select-sales-route.component';
import { SelectBoardSectionComponent } from '../components/select-board-section/select-board-section.component';
import { MultiSelectUserComponent } from '../components/multi-select-user/multi-select-user.component';
import { SelectCustomerExperienceChannelComponent } from '../components/select-customer-experience-channel/select-customer-experience-channel.component';
import { SelectCustomerExperienceChannelTypeComponent } from '../components/select-customer-experience-channel-type/select-customer-experience-channel-type.component';
import { InputEditorCkComponent } from '../components/input-editor-ck/input-editor-ck.component';
import { SelectCustomerExperienceCategoriesComponent } from '../components/select-customer-experience-categories/select-customer-experience-categories.component';
import { InputLeadContactPhoneComponent } from '../components/input-lead-contact-phone/input-lead-contact-phone.component';
import { SelectCustomerExperienceChartComponent } from '../components/select-customer-experience-chart/select-customer-experience-chart.component';
import { SelectRoleComponent } from '../components/select-role/select-role.component';
import { SelectPermissionComponent } from '../components/select-permission/select-permission.component';
import { SelectUserComponent } from '../components/select-user/select-user.component';
import { SelectOrganizationalStructureComponent } from '../components/select-organizational-structure/select-organizational-structure.component';
import { SelectSalesRouteLevelComponent } from 'src/app/management/sales-organization-management/select-sales-route-level/select-sales-route-level.component';

/**
 * Fix for the MatInput required asterisk
 * @see https://github.com/angular/components/issues/2574#issuecomment-486656158
 */
export default function MaterialRequiredPatch() {
  const requiredImplementation = {
    get(): boolean {
      if (this._required) {
        return this._required;
      }

      // The required attribute is set
      // when the control return an error from validation with an empty value
      if (
        this.ngControl &&
        this.ngControl.control &&
        this.ngControl.control.validator
      ) {
        const emptyValueControl = Object.assign({}, this.ngControl.control);
        (emptyValueControl as any).value = null;

        const validators = (this.ngControl.control.validator(emptyValueControl) || {});
        return 'required' in validators || 'contactRequired' in validators;
      }

      return false;
    },

    set(value: boolean) {
      this._required = coerceBooleanProperty(value);
    }
  };

  // Default types
  Object.defineProperty(MatInput.prototype, 'required', requiredImplementation);
  Object.defineProperty(MatSelect.prototype, 'required', requiredImplementation);

  // Custom input components
  Object.defineProperty(InputCompetitorComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(InputCustomerEmailsComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(InputCustomerPhonesComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(InputHashtagComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(InputMoneyComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(InputOpportunityServicesComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(InputParticipantComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(InputSliderComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(InputSpecialDayComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(InputOfferServiceComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(InputCustomerAddressComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(InputCustomerContactComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(InputLeadDraftComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(InputNumberComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(InputContactComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(InputAssignmentComponent.prototype, 'required', requiredImplementation);

  Object.defineProperty(SelectAccountComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectActivityTypeComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectActivityPurposeComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectCityComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectClearanceComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectContactRoleGroupComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectCountryComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectCountyComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectNeighborhoodComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectOrganizationComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectSalesRouteComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectSalutationComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectTimeComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectCustomComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectDangerousGoodsComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectGoodsTypeComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectOfferUnitComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectVehicleTypeComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectIncotermComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectFuelCountyComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectFuelCityComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectActivityComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectBoardLinkComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectSeaPortComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectCarryingFeatureComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectSeaLineComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectPaymentTermComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectAirPortComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectAirLineComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectOpportunityStatusComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectOpportunityTypeComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectLanguageComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectTaxOfficeComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectBoardComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectBoardCardComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectSalesOrganizationComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectOpportunitySearchableComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectSurveyComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectListTypeComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectSurveyMailSalesOrganizationComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(MultiSelectSurveyMailAccountStatusComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(MultiSelectSurveyMailWarningComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectSurveyMailContactFilterSetComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(MultiSelectSurveyMailActivityTypeComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectSurveyMailMainContactTypeComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectSurveyMailContactTypeComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectContactComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(MultiSelectSalesRouteComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectBoardSectionComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(MultiSelectUserComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectCustomerExperienceChannelComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectCustomerExperienceChannelTypeComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(InputEditorCkComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectCustomerExperienceCategoriesComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(InputLeadContactPhoneComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectCustomerExperienceChartComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectRoleComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectPermissionComponent.prototype, 'required', requiredImplementation);

  Object.defineProperty(SelectUserComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectOrganizationalStructureComponent.prototype, 'required', requiredImplementation);
  Object.defineProperty(SelectSalesRouteLevelComponent.prototype, 'required', requiredImplementation);
}
