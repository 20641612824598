import { AbstractControl, FormControl, ValidationErrors, Validators } from '@angular/forms';
import { InputCustomerEmailsValidation, InputCustomerPhonesValidation } from './input-customer-emails-and-phones.model';


export class InputCustomerEmailsAndPhonesValidators {
  static validateEmails(isRequired: boolean = true) {
    return (control: AbstractControl): ValidationErrors | null => {
      const value: InputCustomerEmailsValidation[] = control.value;
      if (isRequired) {
        if (!value || value.length === 0) {
          return { required: true };
        }

        const hasValidEntry = value.some(item =>
          item.mailName &&
          new FormControl(item.mailName, Validators.email).valid &&
          item.emailTypeId
        );

        if (!hasValidEntry) {
          return { required: true };
        }
      }

      if (value && value.length > 0) {
        const hasInvalidFormat = value.some(item => {
          if (item.mailName) {
            const mailNameControl = new FormControl(item.mailName, Validators.email);
            const isMailNameValid = mailNameControl.valid;
            const isEmailTypeIdValid = !!item.emailTypeId;

            return !(isMailNameValid && isEmailTypeIdValid);
          }
          return false;
        });

        if (hasInvalidFormat) {
          return { invalidFormat: true };
        }
      }

      return null;
    };
  }

  static validatePhones(isRequired: boolean = true) {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!isRequired) {
        return null;
      }

      const value: InputCustomerPhonesValidation[] = control.value;
      if (!value || value.length === 0) {
        return { required: true };
      }

      const hasInvalidEntries = value.some(item => {
        return !(item.country && item.phoneNumber && item.phoneTypeId);
      });

      return hasInvalidEntries ? { invalidFormat: true } : null;
    };
  }
}
