<mat-select
  [tabIndex]="tabIndex"
  [formControl]="selectCtrl"
  [compareWith]="compareWith"
  (selectionChange)="onSelectionChange()">
  <ng-container *ngFor="let offerUnit of offerUnits">
    <mat-option *ngIf="showUnit(offerUnit)" [value]="offerUnit">{{ ('OfferUnit.' + offerUnit.name) | translate }}</mat-option>
  </ng-container>
</mat-select>

<mat-progress-spinner *ngIf="loading === true" diameter="16" mode="indeterminate"></mat-progress-spinner>
