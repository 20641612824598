<mat-select #categoriesSelect [tabIndex]="tabIndex" [formControl]="selectCtrl" [compareWith]="compareWith"
    (selectionChange)="onSelectionChange()" [multiple]="multiple" [placeholder]="'CX.CATEGORIES' | translate"
    (openedChange)="openedChange($event)" (closed)="searchText = ''; filteredCategories = categories">

    <mat-select-trigger *ngIf="selectCtrl?.value?.length > 2 && multiple; else trigger">
        {{'CX.CATEGORIES_SELECTED' | translate: {value:
        selectCtrl?.value?.length} }}
    </mat-select-trigger>
    <ng-template #trigger>
        <mat-select-trigger>
            {{ getSelectedValues() }}
        </mat-select-trigger>
    </ng-template>

    <input *ngIf="multiple || searchable" (keydown)="$event.stopPropagation()" [(ngModel)]="searchText"
        (keyup)="onKey($event)" matInput style="width: 95%;" class="pt-4 pb-2 pl-4" maxlength="10"
        placeholder="{{'GENERAL.SEARCH_ELLIPSIS' | translate}}" type="text">

    <mat-option *ngIf="multiple" disabled="disabled" class="mat-option-button">
        <button mat-raised-button [disabled]="filteredCategories.length === 0" (click)="selectAll()">
            {{'GENERAL.SELECT_ALL' | translate }}
        </button>
        <button mat-raised-button [disabled]="filteredCategories.length === 0" (click)="deselectAll()">
            {{'GENERAL.REMOVE_ALL' | translate }}
        </button>
    </mat-option>

    <mat-option *ngFor="let category of getFilteredList()" [value]="category.cxCategoryId"
        [class.mat-option-disabled]="!category.activeFlag">
        {{ category.name }} <span class="text-hint pl-2 float-right"
            *ngIf="category?.cxMainCategory?.cxMainCategoryId && mainCategoryUsage">{{ category.cxMainCategory.name
            }}</span>
    </mat-option>
</mat-select>

<mat-progress-spinner *ngIf="loading === true" diameter="16" mode="indeterminate"></mat-progress-spinner>