import {
  Component,
  Input,
  ElementRef,
  OnDestroy,
  DoCheck,
  HostBinding,
  Optional,
  Self,
  ViewChild
} from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { FocusMonitor } from '@angular/cdk/a11y';
import { TranslateService } from '@ngx-translate/core';

import { SystemUserService, SystemUser, SystemUserStatusType } from '@core/api';
import { ActiveUsers } from '@core/api/custom-dashboard-management/custom-dashboard-management.model';
import { MatSelect } from '@angular/material/select';
import { replaceTurkishCharacters } from '../../utils/replace-turkish-characters';

@Component({
  selector: 'net-multi-select-user[salesOrganizationIds]',
  templateUrl: './multi-select-user.component.html',
  styleUrls: ['./multi-select-user.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: MultiSelectUserComponent
    }
  ]
})
export class MultiSelectUserComponent implements OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<SystemUser[]> {

  static nextId = 0;

  loading = false;
  focused = false;
  errorState = false;
  controlType = 'multi-select-system-user';
  describedBy = '';
  stateChanges = new Subject<void>();
  isOpened = false;
  searchText = '';
  tooltip: string;
  filteredUsers: any[];
  filteredSystemUsers: any[];
  activatedUsers: ActiveUsers[];
  listUsers: any[];

  systemUsers: any[];
  selectCtrl = new UntypedFormControl();
  private _salesOrganizationIds: string[] | string;
  private _placeholder: string;
  private _required = false;
  private _disabled = false;

  @Input() activityStaticUserControl: boolean;
  @Input() showSearchBox = false;
  @Input() tabIndex;
  @Input() type: string;
  @Input() showOnlyActiveUsers = false;
  @Input() profilePicture: boolean;
  @Input() selectionWithId = false;
  @ViewChild('userSelect') userSelect: MatSelect;
  @HostBinding('id') id = `multi-select-system-user-${MultiSelectUserComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private systemUserService: SystemUserService,
    private translate: TranslateService,
    @Optional() @Self() public ngControl: NgControl
  ) {
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  @Input()
  get salesOrganizationIds(): string[] | string {
    return this._salesOrganizationIds;
  }

  set salesOrganizationIds(value: string[] | string) {

    this._salesOrganizationIds = value;

    if (value) {
      this.loading = true;
      this.systemUsers = [];
      this.selectCtrl.disable();

      this.initDefaultOptions().finally(() => {
        if (this.showOnlyActiveUsers) {
          this.value = this.value?.filter(user => !this.checkUserIsPassive(user.systemUserId));
        }
        this.loading = false;
        this.onSelectionChange();

        if (!this.disabled) {
          this.selectCtrl.enable();
        }

      });
    }
    this.stateChanges.next();
  }

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    if (value) {
      this.selectCtrl.disable();
    } else {
      this.selectCtrl.enable();
    }
    this._disabled = value;
    this.stateChanges.next();
  }

  @Input()
  get value(): SystemUser[] {
    return this.selectCtrl.value;
  }

  set value(value: SystemUser[]) {
    this.selectCtrl.setValue(value);
    this.onChange(value);
    this.stateChanges.next();
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get empty() {
    return !this.value;
  }

  onSelectionChange() {

    this.selectCtrl.patchValue(this.value);
    this.onChange(this.value);
    this.selectionChange(this.value);
    this.stateChanges.next();

    if (this.value?.length > 0) {
      this.tooltip = this.value.map((user) => user.displayName).join(', ');
    } else {
      switch (this.type) {
        case 'opportunityInitials':
          this.tooltip = this.translate.instant('OPPORTUNITY.SELECT_APPROVERS');
          break;

        default:
          break;
      }
    }
  }

  compareUserFn(first: any, second: any) {
    return first && second ? first.systemUserId === second.systemUserId : first === second;
  }

  selectAll() {
    this.selectCtrl.patchValue(this.systemUsers);
    this.onSelectionChange();
  }

  deselectAll() {
    this.selectCtrl.patchValue([]);
    this.onSelectionChange();
  }

  checkKey(event) {
    if (event.code === 'Space') {
      event.stopPropagation();
    }
  }

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }

  openedChange(isOpened: boolean) {
    if (isOpened) {
      const panel = this.userSelect.panel.nativeElement;
      panel.scrollTop = 0;
    }
    this.isOpened = isOpened;
    const value = '';
    this.onKey(value);
  }

  onKey(value) {
    this.searchText = value;
    this.filteredUsers = this.search(value);
    this.systemUsers = this.filteredUsers;
    this.filteredSystemUsers = this.systemUsers;
  }

  search(value: string) {
    const filter = replaceTurkishCharacters(value).toLocaleUpperCase();
    if (!filter) {
      return this.listUsers;
    }
    if (this.systemUsers.length < 1) {
      this.systemUsers = this.listUsers;
    }
    const users = this.listUsers.filter((option) => option?.displayName &&
      replaceTurkishCharacters(option?.displayName?.toUpperCase()).includes(filter)
    );

    if (users) {
      const selectedUsers = (this.value || []).map(item => {
        const isHave = users.find(key => key.systemUserId === item?.systemUserId);
        if (!isHave) {
          return item;
        }

      });
      this.systemUsers = [...new Set([...users, ...selectedUsers.filter(e => !!e)])];
    }
    return this.systemUsers;
  }


  checkUserIsPassive(userId: string) {
    for (const systemUser of this.filteredSystemUsers) {
      if (systemUser.systemUserId === userId) {
        return false;
      }
    }
    return true;
  }

  private initDefaultOptions(): Promise<void> {
    return new Promise<void>((resolve) => {

      this.systemUserService.userSearch({
        filter: {
          ...this.profilePicture ? { profilePicture: this.profilePicture } : {},
          salesOrganizationIds: this.salesOrganizationIds,
          systemUserStatusIds: [SystemUserStatusType.ACTIVE],
          ...this.activityStaticUserControl !== undefined ? { activityStaticUserControl: this.activityStaticUserControl } : {}
        },
      })
        .toPromise()
        .then((response) => {
          this.systemUsers = response.data.results.sort((a, b) => a.firstName.localeCompare(b.firstName));
          this.listUsers = this.systemUsers;
          this.filteredSystemUsers = this.systemUsers;
          if (this.selectionWithId) {
            const validUserIds = new Set(this.value);
            const filteredUsers = this.listUsers.filter(user => validUserIds.has(user.systemUserId));
            this.value = filteredUsers;
          }
        })
        .finally(() => resolve());
    });
  }

  onChange = (_: any) => {
  }

  selectionChange = (_: any) => {
  }

  onTouched = () => {
  }

  onContainerClick = () => {
  }

  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');
  writeValue = (value: SystemUser[]) => {
    this.value = value;
    this.selectCtrl.setValue(value);
  }
  ngOnDestroy = () => this.stateChanges.complete();

}
