import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

import {
  OpportunityCommunication,
  OpportunityCommunicationRequest,
  OpportunityCommunicationSearchRequest,
  OpportunityCommunicationSystemUser,
  PagedResponse,
  Response
} from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class OpportunityCommunicationService {

  constructor(private http: HttpClient) { }

  search(request: OpportunityCommunicationSearchRequest): Observable<PagedResponse<OpportunityCommunication>> {
    return this.http.post<PagedResponse<OpportunityCommunication>>(environment.apiUrl + '/api/OpportunityCommunication/Search', request);
  }

  insert(request: OpportunityCommunicationRequest): Observable<Response<OpportunityCommunication>> {
    return this.http.post<Response<OpportunityCommunication>>(environment.apiUrl + '/api/OpportunityCommunication/Insert', request);
  }

  getSystemUserList(opportunityId: string): Observable<Response<OpportunityCommunicationSystemUser[]>> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<OpportunityCommunicationSystemUser[]>>(
      environment.apiUrl + '/api/OpportunityCommunication/SystemUserList', JSON.stringify(opportunityId), { headers }
    );
  }
}
