import { Country } from '@core/api';

import { CountryActions, CountryActionTypes } from './country.actions';

export interface CountryState {
    countries: Country[];
    isLoading: boolean;
    errorMessage: string;
    errorCode: string;
}

export const countryState: CountryState = {
    countries: [],
    isLoading: false,
    errorMessage: null,
    errorCode: null,
};

export function countryReducer(state = countryState, action: CountryActions): CountryState {
    switch (action.type) {
        case CountryActionTypes.GET_COUNTRIES:
            return {
                ...state,
                errorMessage: null,
                isLoading: true
            };
        case CountryActionTypes.LOAD_COUNTRIES:
            return {
                ...state,
                errorMessage: null,
                errorCode: null,
                isLoading: false,
            };
        case CountryActionTypes.GET_COUNTRIES_SUCCESS:
            return {
                ...state,
                countries: action.payload,
                errorMessage: null,
                errorCode: null,
                isLoading: false,
            };
        case CountryActionTypes.GET_COUNTRIES_ERROR:
            return {
                ...state,
                isLoading: false,
                errorCode: action.payload.errorCode,
                errorMessage: action.payload.message
            };
        default:
            return state;
    }
}

export const countries = (state: CountryState) => state.countries;
