<mat-select #channelTypeSelect [tabIndex]="tabIndex" [formControl]="selectCtrl" [compareWith]="compareWith"
    (selectionChange)="onSelectionChange()" [multiple]="multiple" [placeholder]="'CX.CHANNEL_TYPE' | translate"
    (openedChange)="openedChange($event)" (closed)="searchText = ''; filteredChannelTypes = channelTypes">

    <mat-select-trigger *ngIf="selectCtrl?.value?.length > 2 && multiple">
        {{'CX.CHANNEL_TYPES_SELECTED' | translate: {value:
        selectCtrl?.value?.length} }}
    </mat-select-trigger>

    <input *ngIf="multiple" (keydown)="$event.stopPropagation()" [(ngModel)]="searchText" (keyup)="onKey($event)"
        matInput style="width: 95%;" class="pt-4 pb-2 pl-4" maxlength="10"
        placeholder="{{'GENERAL.SEARCH_ELLIPSIS' | translate}}" type="text">

    <mat-option *ngIf="multiple" disabled="disabled" class="mat-option-button">
        <button mat-raised-button [disabled]="filteredChannelTypes.length === 0" (click)="selectAll()">
            {{'GENERAL.SELECT_ALL' | translate }}
        </button>
        <button mat-raised-button [disabled]="filteredChannelTypes.length === 0" (click)="deselectAll()">
            {{'GENERAL.REMOVE_ALL' | translate }}
        </button>
    </mat-option>

    <mat-option *ngFor="let channelType of filteredChannelTypes" [value]="channelType.cxChannelTypeId">
        {{ channelType.name }}
    </mat-option>
</mat-select>

<mat-progress-spinner *ngIf="loading === true" diameter="16" mode="indeterminate"></mat-progress-spinner>