<div class="account-clicker" (click)="onClick()" *ngIf="disabled && isClickable"></div>
<input #input="matAutocompleteTrigger" matInput [tabIndex]="tabIndex" [formControl]="inputCtrl" [matAutocomplete]="auto"
  (blur)="onBlur()" [netAutocompletePosition]="input" [placeholder]="'GENERAL.SEARCH_TO_SELECT' |translate">
<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayName" [autoActiveFirstOption]="true"
  [panelWidth]="panelWidth" (optionSelected)="isSelected = true; onSelect($event)" (closed)="onClosed()">
  <ng-container *ngIf="this.accounts">
    <mat-option class="autocomplete-option" *ngFor="let account of accounts" [value]="account">
      {{ account.name }}
      <div class="sales-organization">
        <span class="text-hint">{{ account.salesOrganization.name }}</span>
      </div>
    </mat-option>
  </ng-container>
</mat-autocomplete>
<mat-progress-spinner *ngIf="loading === true" diameter="16" mode="indeterminate"></mat-progress-spinner>