import { BoardInfo } from '@core/api/board-info/board-info.model';
import { BoardResponse } from '../board-signal-r-response.model';
import { Board, SocketSearchRequest } from '@core/api/board/board.model';
import { BoardInfoActionType } from '@core/api/board-detail-signal-r-type/board-info-action-type.enum';
import * as signalR from '@microsoft/signalr';
import { BoardGroupType } from '@core/api/board-detail-signal-r-type/board-group-type.enum';
import { CardPaginationType } from '@core/api/board-detail-signal-r-type/card-pagination.enum';
import { Response } from '@core/api/api.model';
import { ReplaySubject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

export function setBoardInfoProperties(
    boardInfosResponse: BoardResponse<BoardInfo>[] | BoardResponse<BoardInfo>, board: Board,
    hubConnection: signalR.HubConnection, groupName: string, request: SocketSearchRequest, boardSubject: ReplaySubject<Board>
) {

    if (Array.isArray(boardInfosResponse)) {
        boardInfosResponse.map(boardInfoResponse => {
            if (board.skipNextBoardInfoAction && boardInfoResponse.enumBoardInfoActionType === BoardInfoActionType.BoardInfoSetOrder) {
                return;
            }
            setBoardInfoPropertiesLogic(boardInfoResponse, board, hubConnection, groupName, request, boardSubject);
        });
    } else {

        setBoardInfoPropertiesLogic(boardInfosResponse, board, hubConnection, groupName, request, boardSubject);
    }

    board.skipNextBoardInfoAction = false;
    return board;
}

export function setBoardInfoPropertiesLogic(boardInfosResponse: BoardResponse<BoardInfo>, board: Board, hubConnection: signalR.HubConnection, groupName, request: SocketSearchRequest, boardSubject: ReplaySubject<Board>) {

    switch (boardInfosResponse.enumBoardInfoActionType) {
        case BoardInfoActionType.BoardInfoAdd:
            addBoardInfo(board.boardInfos, boardInfosResponse.data[0], hubConnection, groupName, board);
            break;
        case BoardInfoActionType.BoardInfoDelete:
            deleteBoardInfo(board.boardInfos, boardInfosResponse.boardInfoId);
            break;
        case BoardInfoActionType.BoardInfoSetOrder:
            setBoardInfoOrder(board.boardInfos, boardInfosResponse.data, hubConnection, request, board, boardSubject);
            break;
        case BoardInfoActionType.BoardInfoUpdate:
            updateBoardInfo(board.boardInfos, boardInfosResponse.data[0]);
            break;
    }

    return board;
}

export function addBoardInfo(boardInfos: BoardInfo[], addedBoardInfo: BoardInfo, hubConnection: signalR.HubConnection, groupName: string, board?: Board) {

    addedBoardInfo = {
        ...addedBoardInfo,
        cards: [],
        collapse: false,
        boardInfoUuid: uuidv4(),
        page: 1
    };

    if (boardInfos?.length === 0) {
        boardInfos.push(addedBoardInfo);
        hubConnection.invoke(BoardGroupType.JoinGroup, groupName, addedBoardInfo.boardInfoId);
        return;
    }

    const lastBoardInfo = boardInfos[boardInfos.length - 1];

    if (lastBoardInfo.orderBy + 1 < addedBoardInfo.orderBy) {

        board.skipNextBoardInfoAction = true;
        return;
    }

    if ([BoardGroupType.Listview, BoardGroupType.Timeline].includes(groupName as BoardGroupType)) {

        if (!boardInfos[0].boardInfoId) {

            boardInfos.shift();

            boardInfos.unshift(addedBoardInfo);
        } else {
            boardInfos.push(addedBoardInfo);
        }

    }

    if (groupName === BoardGroupType.Kanban) {
        boardInfos.push(addedBoardInfo);
    }

    boardInfos.sort((a, b) => (a.orderBy > b.orderBy) ? 1 : -1);
    hubConnection.invoke(BoardGroupType.JoinGroup, groupName, addedBoardInfo.boardInfoId);
}

export function deleteBoardInfo(boardInfos: BoardInfo[], deletedBoardInfoId: string) {

    const deletedIndex: number = boardInfos.findIndex(boardInfo => boardInfo.boardInfoId === deletedBoardInfoId);

    if (deletedIndex !== -1) {
        // boardInfos.splice(deletedIndex, 1);
        boardInfos[deletedIndex].deleteFlag = true;
    }
}

export function setBoardInfoOrder(boardInfos: BoardInfo[], changedBoardInfos: BoardInfo[], hubConnection: signalR.HubConnection, request: SocketSearchRequest, board: Board, boardSubject: ReplaySubject<Board>) {

    if (!boardInfos || !boardInfos.length) {
        return;
    }

    const lastBoardInfo = boardInfos[boardInfos.length - 1];
    const lastBoardInfoIndex = changedBoardInfos.findIndex(boardInfo => boardInfo.boardInfoId === lastBoardInfo.boardInfoId);

    changedBoardInfos.map(changedBoardInfo => {
        const assignedBoardInfo = boardInfos.find(boardInfo => boardInfo.boardInfoId === changedBoardInfo.boardInfoId);

        if (assignedBoardInfo) {
            assignedBoardInfo.orderBy = changedBoardInfo.orderBy;

            boardInfos.sort((a, b) => (a.orderBy > b.orderBy) ? 1 : -1);
        } else {
            if (changedBoardInfo.orderBy > changedBoardInfos[lastBoardInfoIndex].orderBy) {
                return;
            }



            const newBoardInfo = {
                ...changedBoardInfo,
                boardInfoUuid: uuidv4()
            };
            boardInfos.push(newBoardInfo);

            request.filter.boardInfoId = changedBoardInfo.boardInfoId;

            let type: string;
            if (request.filter.boardViewType === 'Listview') {
                type = CardPaginationType.ListviewCardPagination;
            } else {
                type = CardPaginationType.TimelineCardPagination;
            }
            cardPagination(hubConnection, board, boardSubject);

            hubConnection.invoke(type, request);
        }
    });
}

export function updateBoardInfo(boardInfos: BoardInfo[], updatedBoardInfo: BoardInfo) {

    const updatedIndex = boardInfos.findIndex(boardInfo => boardInfo.boardInfoId === updatedBoardInfo.boardInfoId);

    if (updatedIndex !== -1) {
        boardInfos[updatedIndex].name = updatedBoardInfo.name;
        boardInfos[updatedIndex].boardInfoUuid = uuidv4();
    }
}

export function cardPagination(hubConnection: signalR.HubConnection, board: Board, boardSubject: ReplaySubject<Board>) {

    hubConnection.on(CardPaginationType.ListviewCardPagination, (response: Response<BoardInfo[]>) => {
        if (response.data[0]) {
            const boardInfo = board?.boardInfos?.find(bI => bI.boardInfoId === response.data[0].boardInfoId);

            boardInfo.cards = response.data[0].cards;
            boardInfo.boardInfoUuid = uuidv4();
        }

        board.boardInfos.sort((a, b) => (a.orderBy > b.orderBy) ? 1 : -1);

        boardSubject.next(board);

        hubConnection.off(CardPaginationType.ListviewCardPagination);
    });
}
