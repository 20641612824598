export function removeLocalStorageWithRegex(regex: RegExp): void {
  Object
    .keys(localStorage)
    .filter((key) => regex.test(key))
    .forEach(key => localStorage.removeItem(key));
}

export function removeSessionStorageWithRegex(regex: RegExp): void {
  Object
    .keys(sessionStorage)
    .filter((key) => regex.test(key))
    .forEach(key => sessionStorage.removeItem(key));
}
