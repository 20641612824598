import { combineLatest, Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectorRef,
  Component, DoCheck, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional, Self
} from '@angular/core';
import {
  ControlValueAccessor, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, NgControl, Validators
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Router } from '@angular/router';
import {
  Board, Card, CardObjectRequest, CardObjectsUpdateRequest, CardObjectTypeOption,
  Guid, OfferTypeOptions, SalesOrganizationAdvancedSettingType, SystemSettingType, User
} from '@core/api';
import { Permission } from '@core/auth/auth.enum';
import { getSystemSettingValue, getUser, hasUserPermission, isOpenSwitchSalesOrganizations } from '@core/store';

import icRemove from '@iconify/icons-fa-solid/trash-alt';
import icLink from '@iconify/icons-ic/link';
import icAdd from '@iconify/icons-ic/twotone-add';
import icMinus from '@iconify/icons-ic/twotone-minus';
import icEdit from '@iconify/icons-mdi/square-edit-outline';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { fadeInUp400ms } from '../../../../shared/animations/fade-in-up.animation';
import {
  ActivityFormDialogComponent
} from '../../../../shared/components/activity-form-dialog/activity-form-dialog.component';
import {
  ContactFormDialogComponent
} from '../../../../shared/components/contact-form-dialog/contact-form-dialog.component';
import {
  OpportunityFormDialogComponent
} from '../../../../shared/components/opportunity-form-dialog/opportunity-form-dialog.component';
import { SelectBoardLink } from './select-board-link.component.model';
import { CardSignalR } from '@core/api/board-detail-signal-r/card.signal-r';
import Swal from 'sweetalert2';

@UntilDestroy()
@Component({
  selector: 'net-select-board-link[board]',
  templateUrl: './select-board-link.component.html',
  styleUrls: ['./select-board-link.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: SelectBoardLinkComponent
    }
  ],
  animations: [
    fadeInUp400ms
  ]
})
export class SelectBoardLinkComponent implements OnInit, OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<SelectBoardLink[] | null> {
  static nextId = 0;

  loading = true;
  focused = false;
  errorState = false;
  controlType = 'select-board-link';
  describedBy = '';
  stateChanges = new Subject<void>();
  translations: { [key: string]: string };

  form = new UntypedFormGroup({
    boardLink: new UntypedFormArray([])
  });

  hasActivityInsertPermission: boolean;
  hasOpportunityInsertPermission: boolean;
  hasContactInsertPermission: boolean;

  user: User;
  cardObjectTypes: any[];
  cardObjectTypeOption = CardObjectTypeOption;
  lastRequestLength: number;

  icAdd = icAdd;
  icLink = icLink;
  icEdit = icEdit;
  icMinus = icMinus;
  icRemove = icRemove;

  isVisibleOfferOption: boolean;

  @Input() board: Board;

  @Input() cardSignalR: CardSignalR;

  @Input()
  get card(): Card { return this._card; }
  set card(value: Card) {
    this._card = value;
  }
  private _card: Card;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get value(): SelectBoardLink[] | null { return this._value; }
  set value(boardLink: SelectBoardLink[] | null) {
    this._value = boardLink;
    this.onChange(boardLink);
    this.stateChanges.next();
  }
  private _value: SelectBoardLink[];

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    if (value) {
      this.getBoardLinkForm().disable();
    } else {
      this.getBoardLinkForm().enable();
    }
    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get addObject(): number { return this._addObject; }
  set addObject(value: number) {
    if (value) {
      this.add();
    }
  }
  private _addObject: number;

  @Input() hubConnection: signalR.HubConnection;

  @HostBinding('id') id = `select-board-link-${SelectBoardLinkComponent.nextId++}`;
  @HostBinding('attr.tabindex') tabIndex = -1;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  get shouldLabelFloat() { return true; }

  get empty() { return !this.value || this.value.length === 0; }

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private router: Router,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
    private store: Store,
    private cdr: ChangeDetectorRef,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Material form field implementation
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    combineLatest([
      this.translate.get(['BOARD', 'GENERAL']),
      this.store.select(getUser).pipe(untilDestroyed(this))
    ]).subscribe(async ([translations, user]) => {
      if (user && this.user !== user) {
        this.user = user;
        this.isVisibleOfferOption = await this.store.select(isOpenSwitchSalesOrganizations(SalesOrganizationAdvancedSettingType.OFFER))
          .pipe(take(1)).toPromise();

        const doesContainsOffer = this.cardObjectTypes?.some(type => type.cardObjectTypeId === CardObjectTypeOption.OFFER);
        if (this.isVisibleOfferOption && !doesContainsOffer) {
          this.cardObjectTypes.push({
            cardObjectTypeId: CardObjectTypeOption.OFFER,
            name: this.translate.instant('CardObjectType.Offer'),
            permission: this.store.select(hasUserPermission(Permission.OFFER_SEARCH)),
          });
        }
      }

      if (translations && this.translations !== translations) {
        this.translations = {
          ...translations.BOARD,
          ...translations.GENERAL,
        };
      }
    });

    this.store.select(hasUserPermission(Permission.ACTIVITY_INSERT)).pipe(untilDestroyed(this))
      .subscribe(response => this.hasActivityInsertPermission = response);
    this.store.select(hasUserPermission(Permission.OPPORTUNITY_INSERT)).pipe(untilDestroyed(this))
      .subscribe(response => this.hasOpportunityInsertPermission = response);
    this.store.select(hasUserPermission(Permission.CONTACT_INSERT)).pipe(untilDestroyed(this))
      .subscribe(response => this.hasContactInsertPermission = response);
  }

  private isEmpty(item: SelectBoardLink) {
    return !item.cardObjectTypeId;
  }

  ngOnInit() {

    // Watch form value changes and update
    this.form.valueChanges.subscribe(() => {
      const boardLink = this.getBoardLinkForm().getRawValue().filter(item => false === this.isEmpty(item));
      const value = boardLink.length > 0 ? boardLink : null;

      this.value = value;
    });

    this.initCardObjectTypes();
    this.loading = false;
  }

  setCardObjects(cardObjects: any[]) {

    this.card.cardObjects = cardObjects;
    const value = [...this.getBoardLinkForm().getRawValue()];

    this.getBoardLinkForm().clear();
    cardObjects.forEach(item => this.add(item));

    value?.map(element => {
      if (!this.getObjectValueId(element)) {
        this.add(element);
      }
    });

  }

  updateCardObjects(type?: CardObjectTypeOption, accountId?: string) {
    if (type && CardObjectTypeOption.ACCOUNT !== type) {
      return;
    }

    const cardObjects: CardObjectRequest[] = this.form.value.boardLink?.filter(item => this.getObjectValueId(item))?.map((item: SelectBoardLink) => {

      // Disable this code when card obejcts type "CONTACT"  provides us accountId
      // let accountId = item.account ? item.account.accountId : null;
      // if (item.cardObjectTypeId === CardObjectTypeOption.CONTACT) {
      //   accountId = item?.customer?.accounts[0].accountId;
      // }
      return {
        cardObjectId: item.cardObjectId ?? Guid.EMPTY,
        cardId: this.card.cardId,
        parentCardId: this.card.parentCardId,
        cardStatusId: this.card.cardStatusId,
        accountId: item.account ? item.account.accountId : accountId,
        leadDraftId: item.leadDraft ? item.leadDraft.leadDraftId : null,
        opportunityId: item.opportunity ? item.opportunity.opportunityId : null,
        contactId: item.contact ? item.contact.contactId : null,
        activityId: item.activity ? item.activity.activityId : null,
        cardObjectTypeId: item.cardObjectTypeId,
        offerId: item.offer ? item.offer.offerId : null
      } as CardObjectRequest;
    }) ?? [];

    const request: CardObjectsUpdateRequest = {
      boardId: this.board.boardId,
      cardId: this.card.cardId,
      cardObjects: cardObjects.filter(item => item.cardObjectTypeId)
    };

    this.cardSignalR.updateCRMLink(request);
  }

  initCardObjectTypes() {
    this.cardObjectTypes = [
      {
        cardObjectTypeId: CardObjectTypeOption.LEAD_DRAFT,
        name: this.translate.instant('CardObjectType.Lead'),
        permission: combineLatest(
          this.store.select(getSystemSettingValue(SystemSettingType.ACCOUNT_USAGE_WITHOUT_LEAD_DRAFT)),
          this.store.select(hasUserPermission(Permission.LEAD_DRAFT_SEARCH))
        ).pipe(
          map(([leadlessUsage, leadDraftSearch]) => !leadlessUsage && leadDraftSearch)
        )
      },
      {
        cardObjectTypeId: CardObjectTypeOption.ACCOUNT,
        name: this.translate.instant('CardObjectType.Customer'),
        permission: this.store.select(hasUserPermission(Permission.ACCOUNT_SEARCH)),
      },
      {
        cardObjectTypeId: CardObjectTypeOption.CONTACT,
        name: this.translate.instant('CardObjectType.Contact'),
        permission: this.store.select(hasUserPermission(Permission.CONTACT_SEARCH)),
      },
      {
        cardObjectTypeId: CardObjectTypeOption.OPPORTUNITY,
        name: this.translate.instant('CardObjectType.Opportunity'),
        permission: this.store.select(hasUserPermission(Permission.OPPORTUNITY_SEARCH)),
      },
      {
        cardObjectTypeId: CardObjectTypeOption.ACTIVITY,
        name: this.translate.instant('CardObjectType.Activity'),
        permission: this.store.select(hasUserPermission(Permission.ACTIVITY_SEARCH)),
      }
    ];
  }

  findPermission(cardObjectTypeId: string) {
    switch (cardObjectTypeId) {
      case CardObjectTypeOption.ACCOUNT:
        return Permission.ACCOUNT_SEARCH;
      case CardObjectTypeOption.ACTIVITY:
        return Permission.ACTIVITY_SEARCH;
      case CardObjectTypeOption.CONTACT:
        return Permission.CONTACT_SEARCH;
      case CardObjectTypeOption.LEAD_DRAFT:
        return Permission.LEAD_DRAFT_SEARCH;
      case CardObjectTypeOption.OPPORTUNITY:
        return Permission.OPPORTUNITY_SEARCH;
      case CardObjectTypeOption.OFFER:
        return Permission.OFFER_SEARCH;
    }
  }

  getBoardLinkForm(): UntypedFormArray {
    return this.form.get('boardLink') as UntypedFormArray;
  }

  createBoardLinkForm(item?: SelectBoardLink): UntypedFormGroup {
    return this.formBuilder.group({
      cardObjectId: [item ? item.cardObjectId : null],
      cardObjectTypeId: [item ? item.cardObjectTypeId : null, Validators.required],
      account: [item ? item.account : null],
      opportunity: [item ? item.opportunity : null],
      contact: [item ? item.contact : null],
      activity: [item ? item.activity : null],
      leadDraft: [item ? item.leadDraft : null],
      offer: [item ? item.offer : null]
    });
  }

  async add(input?: SelectBoardLink) {
    const formGroup = this.createBoardLinkForm(input);
    let hasPermission = true;

    if (input) {
      hasPermission = await this.getPermission(input);

      if (CardObjectTypeOption.OFFER === input.cardObjectTypeId && !this.isVisibleOfferOption) {
        hasPermission = false;
      }
    }

    if (!hasPermission) {
      formGroup.disable();
    }

    this.getBoardLinkForm().push(formGroup);
  }

  getPermission(input?: SelectBoardLink): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.store.select(hasUserPermission(this.findPermission(input.cardObjectTypeId))).pipe(take(1)).subscribe(permission => resolve(permission));
    });
  }

  getObjectValueId(object: SelectBoardLink) {
    if (!object?.cardObjectTypeId) {
      return false;
    }

    switch (object.cardObjectTypeId) {
      case CardObjectTypeOption.ACCOUNT:
        return object.account?.accountId;
      case CardObjectTypeOption.ACTIVITY:
        return object.activity?.activityId;
      case CardObjectTypeOption.CONTACT:
        return object.contact?.contactId;
      case CardObjectTypeOption.LEAD_DRAFT:
        return object.leadDraft?.leadDraftId;
      case CardObjectTypeOption.OFFER:
        return object.offer?.offerId;
      case CardObjectTypeOption.OPPORTUNITY:
        return object.opportunity?.opportunityId;
    }
  }

  remove(index: number) {
    const form = this.getBoardLinkForm();

    if (form.length > 0) {
      form.removeAt(index);
    }

    this.updateCardObjects();
  }

  selectionChange(control: UntypedFormGroup) {
    control.patchValue({
      account: null,
      opportunity: null,
      contact: null,
      activity: null,
      leadDraft: null,
      offer: null
    });
  }

  accountSelectionChange(control: UntypedFormGroup) {
    control.patchValue({
      opportunity: null,
      activity: null,
      offer: null
    });
  }

  onClickAdd() {
    if (this.disabled) {
      return;
    }

    this.add();
  }

  onClickRemove(index: number) {
    if (this.disabled) {
      return;
    }

    Swal.fire({
      html: this.translate.instant('BOARD.CRM_LINK_DELETE_MESSAGE'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#4caf50',
      confirmButtonText: this.translate.instant('GENERAL.CONFIRM'),
      cancelButtonText: this.translate.instant('GENERAL.CANCEL')
    }).then((result) => {
      if (result.value) {

        this.remove(index);
        this.cdr.detectChanges();
      }
    });
  }

  isValidNavigate(formGroup: UntypedFormGroup): boolean {
    const value = formGroup.value;

    if (!value?.cardObjectTypeId || this.disabled || formGroup.disabled) {
      return false;
    }

    switch (value.cardObjectTypeId) {
      case CardObjectTypeOption.ACCOUNT:
        return value.account ? true : false;

      case CardObjectTypeOption.ACTIVITY:
        return value.account && value.activity ? true : false;

      case CardObjectTypeOption.CONTACT:
        return value.contact ? true : false;

      case CardObjectTypeOption.LEAD_DRAFT:
        return value.leadDraft ? true : false;

      case CardObjectTypeOption.OPPORTUNITY:
        return value.account && value.opportunity ? true : false;

      case CardObjectTypeOption.OFFER:
        return value.account && value.offer ? true : false;
    }
  }

  navigate(data: SelectBoardLink) {
    switch (data.cardObjectTypeId) {
      case CardObjectTypeOption.ACCOUNT:
        window.open(this.router.serializeUrl(this.router.createUrlTree(['leads', data.account.accountId, 'home'])), '_blank');
        break;

      case CardObjectTypeOption.ACTIVITY:
        window.open(this.router.serializeUrl(this.router.createUrlTree(['activities', data.activity.activityId])), '_blank');
        break;

      case CardObjectTypeOption.CONTACT:
        window.open(this.router.serializeUrl(this.router.createUrlTree(['contacts', data.contact.contactId])), '_blank');
        break;

      case CardObjectTypeOption.LEAD_DRAFT:
        window.open(this.router.serializeUrl(this.router.createUrlTree(['leads-drafts', data.leadDraft.leadDraftId])), '_blank');
        break;

      case CardObjectTypeOption.OPPORTUNITY:
        window.open(this.router.serializeUrl(this.router.createUrlTree(['opportunities', data.opportunity.opportunityId])), '_blank');
        break;

      case CardObjectTypeOption.OFFER:
        const offerType = [OfferTypeOptions.LIST, OfferTypeOptions.SPOT].includes(data.offer.offerTypeId as OfferTypeOptions) ? '1' : '2';
        window.open(this.router.serializeUrl(this.router.createUrlTree(['leads', data.account.accountId, 'offers', offerType, data.offer.serviceId,
          data.offer.offerId])), '_blank');
        break;
    }
  }

  isValidDialog(formGroup: UntypedFormGroup): boolean {
    const value = formGroup.value;
    const validType = [CardObjectTypeOption.OPPORTUNITY, CardObjectTypeOption.ACTIVITY, CardObjectTypeOption.CONTACT]
      .some(type => type === value?.cardObjectTypeId);

    if (!value?.cardObjectTypeId || this.disabled || !validType || this.isValidNavigate(formGroup)) {
      return false;
    }

    if (CardObjectTypeOption.ACTIVITY === value?.cardObjectTypeId && (!this.hasActivityInsertPermission || !value.account)) {
      return false;
    }

    if (CardObjectTypeOption.OPPORTUNITY === value?.cardObjectTypeId && (!this.hasOpportunityInsertPermission || !value.account)) {
      return false;
    }

    if (CardObjectTypeOption.CONTACT === value?.cardObjectTypeId && !this.hasContactInsertPermission) {
      return false;
    }

    return true;
  }

  openDialog(formGroup: UntypedFormGroup): void {
    const selectBoardLink: SelectBoardLink = formGroup.value;
    let dialogRef;
    let data;

    switch (selectBoardLink.cardObjectTypeId) {
      case CardObjectTypeOption.ACTIVITY:
        dialogRef = ActivityFormDialogComponent;
        data = { account: selectBoardLink.account, isActivityCreating: true };
        break;
      case CardObjectTypeOption.CONTACT:
        dialogRef = ContactFormDialogComponent;
        break;
      case CardObjectTypeOption.OPPORTUNITY:
        dialogRef = OpportunityFormDialogComponent;
        data = { account: selectBoardLink.account, card: this.card, cardSignalR: this.cardSignalR };
        break;
    }

    this.dialog
      .open(dialogRef, { autoFocus: false, data })
      .addPanelClass('cdk-overlay-full').beforeClosed().subscribe(value => {
        if (value) {
          switch (selectBoardLink.cardObjectTypeId) {
            case CardObjectTypeOption.ACTIVITY:
              formGroup.patchValue({ account: data.account, activity: value }, { emitEvent: true });
              this.updateCardObjects();
              break;
            case CardObjectTypeOption.OPPORTUNITY:
              formGroup.patchValue({ account: data.account, opportunity: value }, { emitEvent: true });
              break;
            case CardObjectTypeOption.CONTACT:
              formGroup.patchValue({ contact: value });
              break;
          }
          this.updateCardObjects();
        }
      });
  }

  helpText(data: SelectBoardLink): string {
    // tslint:disable-next-line:max-line-length
    return data.cardObjectTypeId === CardObjectTypeOption.ACTIVITY ? this.translate.instant('CardObjectType.Activity') : data.cardObjectTypeId === CardObjectTypeOption.OPPORTUNITY ?
      this.translate.instant('CardObjectType.Opportunity') : this.translate.instant('CardObjectType.Contact');
  }

  trackByProperty<T>(index: number, column: any) {
    return index;
  }

  writeValue(boardLink: SelectBoardLink[]) {
    if (boardLink && boardLink.length > 0) {
      this.value = boardLink;

      this.getBoardLinkForm().clear();
      boardLink.forEach(item => {
        this.add(item);
      });

      if (this.disabled) {
        this.getBoardLinkForm().disable();
      }
      this.form.updateValueAndValidity();
    } else {
      this.getBoardLinkForm().clear();
    }
  }

  onChange = (_: any) => { };
  onTouched = () => { };
  onContainerClick = () => { };
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');
  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }
}
