import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import { FilterRequest, Response, OfferRejectionReason } from '@core/api';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class OfferRejectionReasonService {
  constructor(private http: HttpClient) { }

  list(request?: FilterRequest): Observable<Response<OfferRejectionReason[]>> {
    return this.http.post<Response<OfferRejectionReason[]>>(environment.apiUrl + '/api/OfferRejectionReason/List', request);
  }
}
