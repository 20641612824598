<div class="flex items-center">
  <net-breadcrumb>
    <a routerLink="/">
      <ic-icon [icon]="icHome" inline="true" size="20px"></ic-icon>
    </a>
  </net-breadcrumb>

  <ng-container *ngFor="let item of (ppBreadcrumbs.crumbs$ |async); let first = first; let last = last">
    <div class="w-1 h-1 bg-gray-300 rounded-full ltr:mr-2 rtl:ml-2"></div>
    <net-breadcrumb [ngClass]="{ 'first': first, 'active': last, 'disabled': !item.path }">
      <a *ngIf="!last && item.path; else lastBreadcrumb" [routerLink]="item.path" [innerHTML]="item.text"></a>
      <ng-template #lastBreadcrumb>
        <span [innerHTML]="item.text"></span>
      </ng-template>
    </net-breadcrumb>
  </ng-container>
</div>