export enum CountryUUID {
  TURKEY = 'a046d041-18fd-4cbf-ab86-c531be9a3e24'
}

export enum OrganizationUUID {
  NETLOG = '13b490fd-d337-42ff-8c9f-b26b7442128e'
}

export enum CustomerAddressType {
  OFFICE = 1,
  OPERATION = 2,
  PRIVATE = 3
}

export enum CustomerPhoneType {
  OFFICE = 1,
  MOBILE = 3,
  FAX = 4,
  OTHER = 5
}

export enum CustomerEmailType {
  CORPORATE = 1,
  OTHER = 3
}

export enum CurrencyType {
  TRY = '5dcb6909-82d2-4161-9de7-758e657a1d6e' as any,
  USD = 'ec6f9e65-8873-45f9-988c-f7f7e24b4d7d' as any,
  GBP = '49ca0fae-5052-402f-a7c7-cf62f83574ff' as any,
  EUR = 'e20ece11-f2a7-408a-8367-2272cddec8af' as any
}

export enum PaymentMethodType {
  CHECK = 'c9bd4e55-07a4-477c-860f-46ecf2974755',
  CASH = '2608fa8c-7bbc-491d-b0e0-1b5b458acf6d',
  CREDIT = '195ba57a-8ecb-4ed3-8856-fabfe30b127c'
}

export enum ActivityPurposeTemplateType {
  SUPPORT = '888b2265-1d3b-4aff-8e14-cd8a0e6d6555',
  NEW_CUSTOMER = '01b3300b-f827-46ed-9eeb-f0f7b5b2e430',
  NEW_OPPORTUNITY = '8540d2db-9a64-4a50-b2e9-e5b35f8b00e2',
  COLLECTION = '415b377a-41bf-4ab0-a19b-7cef2ab56f4b',
  MUTUAL_PAYMENT = 'ff55faf4-04eb-11ec-9a03-0242ac130003',
  CUSTOM_1 = '9cc077d4-4b29-4682-b886-c799fa26e174',
  CUSTOM_2 = 'd5ec7493-35dd-4891-a8b2-8b1b39872440'
}

export enum NoteType {
  ACCOUNT = 1,
  CONTACT,
  LEAD_DRAFT,
  OPPORTUNITY
}

export enum NoteAccessType {
  PRIVATE = 1,
  INTERNAL = 2,
  PUBLIC = 3
}

export enum NoteAccountScreenType {
  MENU = 1,
  LEADS = 2,
  SALESROOM = 3
}

export enum OfferOperationTypeOptions {
  FTL = '330fa692-520b-464a-802b-cbee82170901',
  LTL = '6ddbecba-9a12-415c-8b33-b3845832b98e',
  SPOT = 'a31988dc-9e5b-4ef9-a98c-3d8437dc0381',
  COMPLETE = '705818db-89bf-4a9f-b137-4e12a725ab53',
  PARTIAL = 'a1534e28-bc18-4b87-86a6-0dde3be4596e',
}

export enum OfferUnitType {
  FULL = '6d84abce-6d88-4ff4-9757-2d3d560eb39e'
}

export enum OfferServiceType {
  AIR = '99b3a512-4906-4761-b930-fc3f25d46bd9',
  SEA = 'ad3ae358-b418-4d7c-9a5a-a71534779691',
  LAND = '2601d9a2-952b-40f0-abbe-0b4587006693',
}

export enum OfferListType {
  IMPORT = 1,
  EXPORT = 2
}

export enum ShipmentIntervalType {
  WEEK = 1,
  MONTH = 2,
  YEAR = 3,
}

export enum OfferRejectionReasonType {
  PRICE = '1b3a24b8-b8d3-4ee0-bcb0-30cdf05f4575',
  OTHER = 'e22eba99-8edb-4b11-885c-7c7230fc501a',
  OPERATIONAL = '0ab1b9de-e0f6-47ba-852b-33b009edfd2e',
}

export enum UserGuideStatusType {
  ACTIVE = 'fb4b808d-c667-4bc1-918f-47ae8fd7e9ee',
  PASSIVE = '1d147d7d-07dd-45ac-98f2-7a92e5a82dd9',
}

export enum UserGuideInfoStatusType {
  ACTIVE = '4525098f-1057-448c-84fe-84eb3f663990',
  PASSIVE = 'd8ca8090-fd59-477a-8ec8-8912d003d9ae',
}

export enum SystemUserStatusType {
  ACTIVE = 'abbe6769-f78c-432b-bee3-4ed1234ed5f0',
  PASSIVE = '4d50c04b-e233-44ef-99a4-c3f23f4028ca',
}

export enum ErrorCode {
  DUPLICATE_CUSTOMER_NAME = '41',
  DUPLICATE_CUSTOMER_SHORT_NAME = '42',
  WEB_VERSION_OUT_DATED = '99',
  USER_PASSWORD_MISS_MATCH = '100'
}

export enum Guid {
  EMPTY = '00000000-0000-0000-0000-000000000000'
}

export enum RevenueTypeEnum {
  MONTHLY = 'e13ad200-57bd-4083-8579-b36ff147d1f7',
  FORONCE = '3adb9de2-b991-4dd8-b61b-830885e657d6',
}
