<div class="max-w-full">
  <div class="card overflow-auto">
    <div class="bg-app-bar h-14 border-b sticky left-0 h" fxLayout="row" fxLayoutAlign="start center"
      [class.cursor-pointer]="collapsible" (click)="toggleCollapse($event)" [ngClass]="showIcon ? 'px-3' : 'px-6'">
      <div *ngIf="showIcon" class="rounded-full h-9 w-9 flex items-center justify-center bg-yellow-500"
        style="margin-right: 16px">
        <mat-icon class="opacity-50 text-white w-9" [icIcon]="icMail"></mat-icon>
      </div>
      <h2 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l" [class.border-r]="!collapse"
        fxHide.xs fxFlex="none" *ngIf="collapsible"><span>{{ 'GENERAL.MAIL_LISTING' | translate }}</span></h2>
      <div class="bg-card rounded-full border px-4 ignore-toggle-click" fxFlex="400px" fxHide.xs fxFlex.lt-md="auto"
        fxLayout="row" fxLayoutAlign="start center" (click)="collapse = false">
        <ic-icon [icIcon]="icSearch" size="20px"></ic-icon>
        <input type="text" class="px-4 py-3 border-0 outline-none w-full bg-card" [formControl]="searchCtrl"
          [placeholder]="'GENERAL.SEARCH_ELLIPSIS' | translate">
      </div>
      <button mat-icon-button type="button" *ngIf="collapsible"><mat-icon
          [icIcon]="collapse ? icKeyboardArrowDown : icKeyboardArrowUp"></mat-icon></button>
      <span fxFlex></span>
      <button mat-icon-button type="button" matTooltip="{{ 'GENERAL.MAIL_LIST_CLEAR_PREVIEW' | translate }}"
        (click)="clearPreviews()" *ngIf="hasPreviews"><mat-icon [icIcon]="icLayersClear"></mat-icon></button>
      <net-mat-table-column-filter [columns]="columns" [tableColumnFilterConfig]="tableColumnFilterConfig">
      </net-mat-table-column-filter>
    </div>

    <div [hidden]="collapsible && collapse">
      <table class="w-full" mat-table matSort @stagger [dataSource]="dataSource">
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <!-- text Column -->
          <ng-container *ngIf="column.type === 'text'" [matColumnDef]="column.property">
            <th *matHeaderCellDef mat-header-cell>{{ column.label }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{ row[column.property] ?? '' }}</td>
          </ng-container>

          <!-- uuid Column -->
          <ng-container *ngIf="column.type === 'uuid'" [matColumnDef]="column.property">
            <th *matHeaderCellDef mat-header-cell>
              {{ column.label }}
              <net-mat-header-filter [key]="column.key" [type]="matHeaderFilterType.select" [column]="column"
                [dataSource]="dataSource" [selectSource]="dataSource.linkTypes$"></net-mat-header-filter>
            </th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              {{ mailLinkTypeName(row[column.property]) }}
            </td>
          </ng-container>

          <!-- date Column -->
          <ng-container *ngIf="column.type === 'date'" [matColumnDef]="column.property">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ column.label }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              {{ row[column.property] | momentFormat:'DD MMMM YYYY HH:mm' }}
            </td>
          </ng-container>

          <!-- createdUser Column -->
          <ng-container *ngIf="column.type === 'createdUser'" [matColumnDef]="column.property">
            <th *matHeaderCellDef mat-header-cell>{{ column.label }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              {{ row['createdUser']?.firstName }} {{ row['createdUser']?.lastName }}
            </td>
          </ng-container>

          <!-- button Column -->
          <ng-container *ngIf="column.type === 'button'" matColumnDef="actions">
            <th *matHeaderCellDef mat-header-cell>{{ column.label }}</th>
            <td *matCellDef="let row" class="w-10 text-secondary" mat-cell>
              <button type="button" *ngIf="hasMailMenu || isDeleteMailLinkPermitted || (checkVisibility(row) | async)"
                mat-icon-button [matMenuTriggerData]="{ row }" [matMenuTriggerFor]="actionsMenu"
                (click)="$event.stopPropagation()">
                <mat-icon [icIcon]="icMoreHoriz"></mat-icon>
              </button>
            </td>
          </ng-container>
        </ng-container>

        <ng-container matColumnDef="not-found">
          <td mat-footer-cell class="text-center" [colSpan]="visibleColumns.length" *matFooterCellDef>{{
            'GENERAL.NO_RESULT_FOUND' | translate }}</td>
        </ng-container>

        <ng-container matColumnDef="loading">
          <td mat-footer-cell class="text-center" [colSpan]="visibleColumns.length" *matFooterCellDef>{{
            'GENERAL.LOADING' | translate }}</td>
        </ng-container>

        <ng-container matColumnDef="upload">
          <td mat-footer-cell class="text-center" [colSpan]="visibleColumns.length" *matFooterCellDef>
            <net-document-upload
              [systemUserParameter]="null"
              [type]="documentType"
              [options]="documentOptions"
              [url]="uploadUrl"
              (done)="onUploadDone()"
              ></net-document-upload>
          </td>
        </ng-container>

        <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
        <tr *matFooterRowDef="['loading']" [class.hidden]="initiated || !dataSource.loading" mat-footer-row></tr>

        <tr mat-footer-row [class.hidden]="!(initiated && dataSource.empty && !dataSource.loading)"
          *matFooterRowDef="['not-found']"></tr>

        <tr mat-row class="hover:bg-hover trans-ease-out cursor-pointer" @fadeInUp (click)="openPreview(row)"
          *matRowDef="let row; columns: visibleColumns;"></tr>
        
        <tr mat-footer-row *matFooterRowDef="['upload']"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" class="sticky left-0"></mat-paginator>
    </div>
  </div>
</div>

<mat-menu #actionsMenu="matMenu" xPosition="before" yPosition="below">
  <ng-template let-row="row" matMenuContent>
    <button mat-menu-item (click)="openMailBox(row)" *ngIf="hasMailMenu && row.mailId">
      <mat-icon [icIcon]="icMail"></mat-icon>
      <span>{{ 'GENERAL.OPEN_IN_MAILBOX' | translate }}</span>
    </button>
    <button mat-menu-item *ngIf="isDeleteMailLinkPermitted" (click)="deleteLink(row)">
      <mat-icon [icIcon]="icRemoveLink"></mat-icon>
      <span>{{ 'GENERAL.MAIL_LINK_REMOVE' | translate }}</span>
    </button>
    <button mat-menu-item *ngIf="(checkVisibility(row) | async)" (click)="openLink(row)">
      <mat-icon [icIcon]="icOpenLink"></mat-icon>
      <span>{{ 'DOCUMENT.OPEN_LINK' | translate }}</span>
    </button>
  </ng-template>
</mat-menu>