<input #input matInput class="autocomplete-input" [tabIndex]="tabIndex"
  [matTooltip]="'GENERAL.CONTACT_NOT_FOUND' | translate" [formControl]="inputCtrl" [matAutocomplete]="auto"
  [matTooltipDisabled]="loading || contacts.length > 0" (blur)="onBlur()">
<mat-autocomplete #auto="matAutocomplete" [panelWidth]="panelWidth" [displayWith]="displayName"
  [autoActiveFirstOption]="true" (closed)="onClosed()" (optionSelected)="onSelect($event)">
  <ng-container *ngIf="this.contacts">
    <mat-option *ngIf="contacts.length === 0 && !loading" disabled>{{ 'CONTACT.CONTACT_NOT_FOUND' |
      translate}}</mat-option>
    <mat-option matTooltipPosition="right" matTooltipShowDelay="800" [value]="contact"
      [matTooltip]="[[contact.firstName, contact.lastName].join(' '), contact.customer?.name, contact?.jobTitle].join(showProperty !== 'name' ? ' - ' :' ')"
      *ngFor="let contact of contacts">
      <span>{{ contact.firstName }} {{ contact.lastName }}</span>
      <span fxHide.lt-md class="text-hint" *ngIf="contact.jobTitle || contact.customer?.name"> - {{
        [contact.customer[showProperty], contact.jobTitle].join(showProperty !== 'name' ? ' - ' :' ') }}</span>
    </mat-option>
  </ng-container>
</mat-autocomplete>

<mat-progress-spinner *ngIf="loading === true" diameter="16" mode="indeterminate"></mat-progress-spinner>