/* tslint:disable:no-bitwise */
/**
 * DJB2 string hash function
 *
 * @param input string to hash
 * @returns hash code
 */
export function djb2HashCode(input: string): number {
  let hash = 5381;
  for (let i = 0; i < input.length; i++) {
    hash = (hash * 33) ^ input.charCodeAt(i);
  }
  return hash >>> 0;
}
/* tslint:enable:no-bitwise */
