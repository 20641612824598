import { UserPermission } from '@core/api';
import { Permission } from '@core/auth/auth.enum';
import { AuthState } from '@core/store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectAuthState = createFeatureSelector('auth');

export const getUser = createSelector(
    selectAuthState,
    (state: AuthState) => state.user
);

export const getUserTokens = createSelector(
    selectAuthState,
    (state: AuthState) => state.tokens
);

export const getUserPermissions = createSelector(
    selectAuthState,
    (state: AuthState) => state.user?.permissions
);

export const loginError = createSelector(
    selectAuthState,
    (state: AuthState) => ({ errorCode: state.errorCode, errorMessage: state.errorMessage })
);

export const loginLoading = createSelector(
    selectAuthState,
    (state: AuthState) => state.isLoading
);

export const hasUserPermission = (permission: Permission | Permission[]) => createSelector(
    selectAuthState,
    (state: AuthState) => {
        // If user not exists return with negative
        if (!state.user) {
            return false;
        }

        // If user has access all, not check further
        if (checkPermission(Permission.ACCESS_ALL, state.user.permissions)) {
            return true;
        }

        if (Array.isArray(permission)) {
            const permissions: boolean[] = [];

            for (const per of permission) {
                permissions.push(checkPermission(per, state.user.permissions));
            }

            return permissions.some(item => true === item);
        } else {
            return checkPermission(permission, state.user.permissions);
        }
    }
);

export function checkPermission(permission: Permission, list: UserPermission[]): boolean {
    return list.some(item => item.permissionCode === permission);
}
