import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';

import { Observable } from 'rxjs';

import {
  Response,
  PagedResponse,
  FilterRequest,
  ServiceMain,
  ServiceMainRequest,
} from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class ServiceMainService {
  constructor(private http: HttpClient) { }

  list(request: FilterRequest): Observable<PagedResponse<ServiceMain>> {
    return this.http.post<PagedResponse<ServiceMain>>(environment.apiUrl + '/api/ServiceMain/List', request);
  }

  get(uuid: string): Observable<Response<ServiceMain>> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<ServiceMain>>(environment.apiUrl + '/api/ServiceMain/Get', JSON.stringify(uuid), { headers });
  }

  insert(request: ServiceMainRequest): Observable<Response<ServiceMain>> {
    return this.http.post<Response<ServiceMain>>(environment.apiUrl + '/api/ServiceMain/Insert', request);
  }

  update(request: ServiceMainRequest): Observable<Response<ServiceMain>> {
    return this.http.post<Response<ServiceMain>>(environment.apiUrl + '/api/ServiceMain/Update', request);
  }

  delete(request: ServiceMain): Observable<Response<ServiceMain>> {
    return this.http.post<Response<ServiceMain>>(environment.apiUrl + '/api/ServiceMain/Delete', request);
  }
}
