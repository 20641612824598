import { isSupportedCountry } from 'libphonenumber-js';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AppValidators } from 'src/app/app.validators';

import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, DoCheck, ElementRef, Input, OnDestroy,
  OnInit, Optional, Self
} from '@angular/core';
import {
  ControlValueAccessor, UntypedFormBuilder, UntypedFormGroup, NgControl, Validators
} from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Country, Phone } from '@core/api';
import { getCountries, GetCountries } from '@core/store';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { maskPhone } from '../../utils/text-masks';

@Component({
  selector: 'net-input-lead-contact-phone',
  templateUrl: './input-lead-contact-phone.component.html',
  styleUrls: ['./input-lead-contact-phone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: InputLeadContactPhoneComponent
    }
  ]
})
export class InputLeadContactPhoneComponent implements OnInit, OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<Phone | null> {
  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    if (value) {
      this.form.disable();
    } else {
      this.form.enable();
    }

    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): Phone | null { return this._value; }
  set value(phone: Phone | null) {
    this._value = phone;
    this.onChange(phone);
    this.stateChanges.next();
    this.changeDetectorRef.detectChanges();
  }
  private _value: Phone;

  @Input() isReadOnly = false;

  @Input() hasInternal = false;

  countryTurkey = {
    code: '90',
    countryId: 'a046d041-18fd-4cbf-ab86-c531be9a3e24',
    createdOn: null,
    name: this.translate.instant('Country.Turkey'),
    nameEng: 'Turkey',
    orderBy: 1,
    phoneCode: '90',
    readOnlyFlag: false,
    sortName: 'TR'
  };

  focused = false;
  errorState = false;
  describedBy = '';
  stateChanges = new Subject<void>();
  form: UntypedFormGroup;
  phone: Phone;
  countries: Country[] = [];

  id: string;
  empty: boolean;
  shouldLabelFloat: boolean;

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private formBuilder: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private translate: TranslateService,
    private store: Store,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Material form field implementation
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) { return; }
      if (this.focused && !origin) { this.onTouched(); }

      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    this.form = this.createForm(this.value);
  }

  writeValue(phone: Phone) {
    if (phone) {
      this.value = phone;
      this.store.select(getCountries()).subscribe((countries) => {
        this.countries = countries.map((country) => ({ ...country, name: this.translate.instant('Country.' + country.name) }));
        const countryId = this.value?.countryId || this.value?.country?.countryId;
        this.value = { ...this.value, country: this.countries.find((country) => countryId === country.countryId) };
        this.onChange(this.value);
        this.form.controls.country.setValue(this.value?.country);
        this.form.controls.phone.setValue(this.value?.phoneNumber);
        this.form.controls.internalPhone.setValue(this.value?.internalPhone);
        this.form.updateValueAndValidity();
        if (!countryId) {
          this.form.markAsTouched();
        }
      });
    }
  }

  onChange = (_: any) => { };
  onTouched = () => { };
  onContainerClick = () => { };
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');
  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.touched && this.form.touched && (this.ngControl.invalid || this.form.invalid);
      this.stateChanges.next();
    }
  }

  ngOnInit() {
    this.form.valueChanges.pipe(debounceTime(200)).subscribe((form) => {
      if (form.phone && form.phone !== `+${form?.country?.phoneCode}`) {
        const phone = form.phone.replace(/\D+/g, '');
        this.value = { phoneNumber: `+${phone}`, internalPhone: form?.internalPhone, country: form?.country };
      } else {
        if (!form.phone) {
          this.form.get('country').setValue(form?.country, { emitEvent: false});
          this.form.get('phone').setValue(`+${form?.country?.phoneCode}`, { emitEvent: false});
        }
        if (form.phone === `+${form?.country?.phoneCode}`) {
          this.form.controls.phone.markAsPristine();
          this.form.controls.phone.markAsUntouched();
          this.form.controls.country.markAsPristine();
          this.form.controls.country.markAsUntouched();
          this.form.get('phone').setValue(`+${form?.country?.phoneCode}`, { emitEvent: false});
          // this.form.get('country').setValue(form.country, { emitEvent: false});

        }
      }
    });
  }

  removeValidators() {
    const phoneNumber = this.form.get('phone').value.replace(/\D+/g, '');
    const country = this.form.get('country').value;
    if (phoneNumber === `${country?.phoneCode}`) {
      this.form.controls.phone.markAsPristine();
      this.form.controls.phone.markAsUntouched();
      this.form.controls.country.markAsPristine();
      this.form.controls.country.markAsUntouched();

      this.value = null;
      this.form.get('phone').setValue(`+${country?.phoneCode}`, { emitEvent: false});
      this.form.get('country').setValue(country, { emitEvent: false});

    }
 }

  reset() {
    if (!this.value) { return; }

    this.value = null;
    this.form.controls.country.setValue(null);
    this.form.controls.phone.setValue('');
  }

  createForm(phone: Phone) {
    return this.formBuilder.group({
      country: [phone ? (phone.countryId ? phone.country : null) : this.countryTurkey, Validators.required],
      phone: [phone ? phone.phoneNumber : '+90', AppValidators.phone],
      internalPhone: [phone ? phone.internalPhone : null]
    });
  }

  onChangeCountry(country: Country) {
    const sortCode = country.sortName;

    if (this.form.get('phone').value.startsWith('+' + country.phoneCode)) {
      this.form.get('phone').updateValueAndValidity();
      return;
    }

    if (sortCode && isSupportedCountry(sortCode)) {
      this.form.controls.phone.patchValue('+' + country.phoneCode);
      this.form.controls.phone.setValidators(AppValidators.phone);
    } else {
      this.form.controls.phone.patchValue('');
      this.form.controls.phone.setValidators(Validators.required);
    }

    this.form.controls.phone.updateValueAndValidity();
  }

  onKeydown(event: KeyboardEvent) {
    if (event.key === 'Backspace' || event.key === 'Delete') {
      return true;
    }
    if (isNaN(Number(event.key))) {
      event.preventDefault();
    }
  }

  onKeyup(event: KeyboardEvent) {
    if ((event.target as HTMLInputElement)?.value) {
      const phone = (event.target as HTMLInputElement)?.value;
      const country = this.form.get('country').value;

      if (country?.sortName && isSupportedCountry(country?.sortName)) {
        const phoneCode = country.phoneCode;
        const countryCodeLenght = country.phoneCode.length;

        if (phoneCode !== phone.substring(1, countryCodeLenght + 1)) {
          this.form.get('phone').patchValue(country.phoneCode);
        }
      }
    }
  }

  typeControl(sortCode: string): boolean {
    return isSupportedCountry(sortCode);
  }

  textMaskCheck() {
    const sortCode = this.value?.country?.sortName;

    if (maskPhone(sortCode, true).possibleLength) {
      this.form.get('phone').value.slice(0, maskPhone(sortCode, true).possibleLength);
    }

    if (sortCode && isSupportedCountry(sortCode)) {
      return maskPhone(sortCode);
    }

    return { mask: false, showMask: false };
  }
}

