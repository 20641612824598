export function createFileWindow(): Window {
  return window.open('/file-loading.html');
}

export function openFileUrl(url: string, headers: { [key: string]: string } = {}, type: string = 'preview', fileName: string, fileWindow?: Window): Promise<boolean> {
  if (!fileWindow) {
    fileWindow = createFileWindow();
  }

  return new Promise<boolean>((resolve, reject) => {
    if (!fileWindow) {
      reject('Window create failed');
      return;
    }
    fetch(url, { method: 'GET', headers })
      .then((response) => response.blob())
      .then(blob => {
        const fileUrl = URL.createObjectURL(blob);

        if (type === 'preview') {
          // Change window url with downloaded file
          fileWindow.location.href = fileUrl;

          // Revoke created file url when finish load
          fileWindow.onload = () => URL.revokeObjectURL(fileUrl);

          // Resolve open file promise
          resolve(true);
        } else {
          // create <a> tag dinamically
          const fileLink = document.createElement('a');
          fileLink.href = fileUrl;

          // it forces the name of the downloaded file
          fileLink.download = fileName;

          // triggers the click event
          fileLink.click();
          fileWindow.close();
          resolve(false);
        }

      })
      .catch(() => reject('File fetch failed.'));
  });
}
