import { Action } from '@ngrx/store';

export enum ActivityActionType{
  ACTIVITY_INSERT_STATUS = '[Activity] ACTIVITY_INSERT_STATUS'
}

export class ActivityInsertStatus implements Action{
  readonly type = ActivityActionType.ACTIVITY_INSERT_STATUS;
  constructor(public message: boolean) {}
}

export type ActivityActions = ActivityInsertStatus;
