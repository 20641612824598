<div fxLayout="row" fxLayoutGap="24px">
  <div class="select-purpose">
    <mat-select (selectionChange)="onSelectPurpose($event)" [compareWith]="compareWith"
                [disabled]="isDisabled" [formControl]="purposeCtrl"
                [placeholder]="'ACTIVITY.SELECT_ACTIVITY_PURPOSE' | translate">
      <mat-option *ngFor="let activityPurpose of activityPurposes"
                  [disabled]="(activityPurposeType.NEW_CUSTOMER === activityPurpose.activityPurposeId && hasActiveOpportunity) ||
                    (type === activityDialogTypeEnum.LEAD_DRAFT && activityPurposeType.NEW_OPPORTUNITY === activityPurpose.activityPurposeId)"
                  [value]="activityPurpose">
        {{ ("ActivityPurpose." + activityPurpose.name) | translate }}</mat-option>
    </mat-select>
  </div>

  <div fxFlex [class.disabled]="type === activityDialogTypeEnum.LEAD_DRAFT">
    <mat-chip-list #chipList #origin="matAutocompleteOrigin" [disabled]="isDisabled || type === activityDialogTypeEnum.LEAD_DRAFT" matAutocompleteOrigin>
      <mat-chip (removed)="onRemoveOpportunity(opportunity)" *ngFor="let opportunity of value.opportunities"
                [removable]="true">
        {{ opportunity.name }}
        <ic-icon [icon]="icCancel" [inline]="true" matChipRemove></ic-icon>
      </mat-chip>
      <input #input (focus)="reset()"
             (matChipInputTokenEnd)="onAddToken($event)"
             [formControl]="opportunityCtrl" [matAutocompleteConnectedTo]="origin"
             [matAutocomplete]="auto" [matChipInputAddOnBlur]="true" [matChipInputFor]="chipList"
             [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matTooltipDisabled]="!!value.activityPurpose"
             [matTooltip]="'ACTIVITY.PLEASE_SELECT_ACTIVITY_PURPOSE_FIRST' | translate"
             [placeholder]="value.opportunities.length === 0 ? ('ACTIVITY.YOU_CAN_SELECT_RELATED_OPPORTUNITIES' | translate) : ''"
             [readOnly]="!value.activityPurpose || type === activityDialogTypeEnum.LEAD_DRAFT">
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectOpportunity($event)"
                      [autoActiveFirstOption]="filteredOpportunities.length > 0">
      <mat-option *ngIf="filteredOpportunities.length === 0" disabled="true">
        {{"OPPORTUNITY.OPPORTUNITY_NOT_FOUND" | translate}}
      </mat-option>
      <ng-container *ngIf="!loading">
        <mat-option *ngFor="let opportunity of filteredOpportunities" [disabled]="isDisabled || type === activityDialogTypeEnum.LEAD_DRAFT"
                    [value]="opportunity">{{ opportunity.name }}
        </mat-option>
      </ng-container>
    </mat-autocomplete>
  </div>
</div>

<button (click)="add()" *ngIf="!disabled && !!value.activityPurpose && type !== activityDialogTypeEnum.LEAD_DRAFT"
        [disabled]="isDisabled" [hidden]="this.loading"
        class="add-opportunity"
        mat-icon-button
        type="button">
  <ic-icon [icon]="icAdd" size="16px"></ic-icon>
</button>

<mat-progress-spinner *ngIf="loading === true" diameter="16" mode="indeterminate"></mat-progress-spinner>
