import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit
} from '@angular/core';
import { User } from '@core/api';
import { getUser } from '@core/store';
import icPerson from '@iconify/icons-ic/twotone-person';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';

import { PopoverService } from '../../../shared/components/popover/popover.service';
import theme from '../../../shared/utils/tailwindcss';
import {
    ToolbarUserDropdownComponent
} from './toolbar-user-dropdown/toolbar-user-dropdown.component';

@UntilDestroy()
@Component({
  selector: 'net-toolbar-user',
  templateUrl: './toolbar-user.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserComponent implements OnInit, OnDestroy {

  user: User;

  dropdownOpen: boolean;
  icPerson = icPerson;

  theme = theme;

  constructor(
    private popover: PopoverService,
    private cd: ChangeDetectorRef,
    private store: Store,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.store.select(getUser).pipe(untilDestroyed(this)).subscribe(user => {
      this.user = user;
      this.cdr.detectChanges();
    });
  }

  showPopover(originRef: HTMLElement) {
    this.dropdownOpen = true;
    this.cd.markForCheck();

    const popoverRef = this.popover.open({
      content: ToolbarUserDropdownComponent,
      origin: originRef,
      hasBackdrop: true,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ]
    });

    popoverRef.afterClosed$.subscribe(() => {
      this.dropdownOpen = false;
      this.cd.markForCheck();
    });
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy() { }
}
