import moment from 'moment';
import { combineLatest } from 'rxjs';
import { debounceTime, filter, map, skip, startWith } from 'rxjs/operators';
import icWarning from '@iconify/icons-mdi/alert-circle';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Account,
  Activity,
  ActivityAssignmentRequest,
  ActivityInfo,
  ActivityRequest,
  ActivityStatusType,
  ActivityTypeList,
  ActivityVideoConferenceTool,
  ActivityVideoConferenceToolService,
  Contact,
  Customer,
  DetailResponse,
  DynamicFieldEntityAsString,
  DynamicFieldEntityId,
  LeadDraftInfo,
  MeetingService,
  Opportunity,
  processDynamicFieldValues,
  SalesOrganizationAdvancedSettingType,
  SalesOrganizationSettingInfoValue,
  SalesOrganizationType,
  SystemUser,
  User
} from '@core/api';
import {
  ActivityVideoConferenceToolEnum
} from '@core/api/activity-video-conference-tool/activity-video-conference-tool.enum';
import { MeetingStatusType } from '@core/api/meeting/meeting-status.enum';
import { Permission } from '@core/auth/auth.enum';
import { GetAccount, getAccount, getSalesOrganizationSettingValue, getUser, hasUserPermission } from '@core/store';
import icClose from '@iconify/icons-ic/twotone-close';
import icPdf from '@iconify/icons-ic/twotone-picture-as-pdf';
import icSave from '@iconify/icons-ic/twotone-save';
import icDownload from '@iconify/icons-mdi/download';
import icPdfOutLine from '@iconify/icons-mdi/file-pdf-outline';
import icAssignment from '@iconify/icons-ic/twotone-assignment';
import icKeyboardArrowUp from '@iconify/icons-ic/keyboard-arrow-up';
import icKeyboardArrowDown from '@iconify/icons-ic/keyboard-arrow-down';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';

import { Participants } from '../input-participant/input-participant.component.model';
import { ValidateParticipant } from '../input-participant/input-participant.validator';
import { SelectActivityPurpose } from '../select-activity-purpose/select-activity-purpose.component.model';
import { SelectActivityPurposeValidator } from '../select-activity-purpose/select-activity-purpose.validators';
import { InputActivityAssignmentValidators } from '../input-activity-assignment/input-activity-assignment.validators';
import { SelectTime } from '../select-time/select-time.component.model';
import { scrollToError } from '../../utils/scroll-to-error';
import { ActivityStaticRecipientService } from '@core/api/activity-static-recipient/activity-static-recipient.service';
import { SalesOrganizationSettingInfoSearch } from '@core/api/activity-static-recipient/activity-static-recipient.model';
import { EnumSalesOrganizationSettingInfoDetail } from '@core/api/activity-static-recipient/activity-static-recipient.enum';
import { SurveyMailService } from '@core/api/survey-mail/survey-mail.service';
import { ActivityDialogType } from '../activity-form-dialog/activity-form-dialog.component.model';
import { ActivityDialogTypeEnum } from '../activity-form-dialog/activity-form-dialog.enum';
import { isEqual } from 'lodash';

@UntilDestroy()
@Component({
  selector: 'net-activity-form[account]',
  templateUrl: './activity-form.component.html',
  styleUrls: ['./activity-form.component.scss']
})
export class ActivityFormComponent implements OnInit, OnDestroy, AfterViewInit {

  Validators = Validators;
  @Input() loading = false;
  @Input() isAccountEnabled: boolean;
  @Input() contact: Contact;
  @Output() save = new EventEmitter<ActivityRequest>();
  form: UntypedFormGroup;
  isPage = false;
  isDialog = false;
  hasAssignmentPermission = false;
  hasActivityUpdatePermission = false;
  hasNPSSendingUpdatePermission = false;
  hasLeadDraftActivityUpdatePermission = false;
  isDisabled = false;
  isCreate = true;
  isCreateVideoActivity = false;
  isActivityAssignmentPanelOpened = false;
  isParticipantsChanged = false;
  openSeparator = false;
  isSubmit = false;
  today = moment();
  dateMin = null;
  dateMax = null;
  activityType = ActivityTypeList;
  activityStatus = ActivityStatusType;
  activityDialogTypeEnum = ActivityDialogTypeEnum;
  icSave = icSave;
  icClose = icClose;
  icPdf = icPdf;
  icDownload = icDownload;
  icWarning = icWarning;
  icPdfOutLine = icPdfOutLine;
  icAssignment = icAssignment;
  icKeyboardArrowUp = icKeyboardArrowUp;
  icKeyboardArrowDown = icKeyboardArrowDown;
  editorLength = 0;
  activityAction: 'save' | 'download' | 'preview' | 'save_send';
  reSelectAddress = true;
  user: User;
  sendUsers = [];
  salesOrganizationType = SalesOrganizationType;
  hasSendMailWithActivity: boolean | string | number | SalesOrganizationSettingInfoValue[];
  callDetail: DetailResponse;
  isFromVideo = false;
  activityVideoConferenceTool: ActivityVideoConferenceTool[] = [];
  DynamicFieldEntityId = DynamicFieldEntityId;
  DynamicFieldEntityAsString = DynamicFieldEntityAsString;

  selectedAccountSalesOrganizationId: string;
  activitySurveyStatus = false;

  SAVE_SEND_SETTING_INFO_ID = 'f5e5fc3b-b077-4aa8-8050-ff16c554d22e';
  hideSaveAndSendButton = false;
  activityCustomerName = null;
  leadDraftInfoActivityCustomer: Customer;

  @Input() formChanged = false;
  @Output() isFormChange = new EventEmitter<boolean>();
  @Input() closeActivityFormDialog: () => void;

  initialFormValue: any;
  isInitialized = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store,
    private meetingService: MeetingService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private activityVideoConferenceToolService: ActivityVideoConferenceToolService,
    private activityStaticRecipientService: ActivityStaticRecipientService,
    private cdr: ChangeDetectorRef,
    private surveyMailService: SurveyMailService
  ) {
    // Create contact form
    this.form = this.formBuilder.group({
      account: [null, Validators.required],
      type: [null, Validators.required],
      meetingTypeId: [null],
      status: [ActivityStatusType.PLANNED, Validators.required],
      address: [null],
      date: [null, Validators.required],
      timeStart: [null, Validators.required],
      timeEnd: [null, Validators.required],
      participants: [null, ValidateParticipant.contactRequired],
      purpose: [null, [Validators.required, SelectActivityPurposeValidator.validator]],
      description: [null, Validators.required],
      activityPdfTemplate: [null],
      activityAssignment: [null, { validators: [InputActivityAssignmentValidators.required] }],
      privateNote: [null],
      dynamicFieldValues: [null],
      sendSurveyMail: [true]
    }, { updateOn: 'change' });

    // Watch status change for detect date picker minimum value
    this.form.get('status').valueChanges
      .pipe(startWith(ActivityStatusType.PLANNED))
      .subscribe(status => {
        const date = this.form.get('date');
        const type = this.form.get('type');
        const statusCtrl = this.form.get('status');
        const description = this.form.get('description');
        const meetingTypeId = this.form.get('meetingTypeId');
        const timeStart = this.form.get('timeStart');
        const timeEnd = this.form.get('timeEnd');

        let min = null;
        let max = null;

        if (!this.activity) {
          if (this.type === ActivityDialogTypeEnum.LEAD_DRAFT && type.value === ActivityTypeList.VIDEOCONF) {
            meetingTypeId.disable();
            meetingTypeId.setValue(ActivityVideoConferenceToolEnum.OTHER);
          } else {
            if (status !== ActivityStatusType.PLANNED) {
              meetingTypeId.disable();
              meetingTypeId.setValue(ActivityVideoConferenceToolEnum.OTHER);
            } else {
              meetingTypeId.enable();
              meetingTypeId.setValue(null);
              meetingTypeId.markAsUntouched();
            }
          }
        } else {
          if (this.activity.leadDraftInfoActivity && this.activity.activityTypeId === ActivityTypeList.VIDEOCONF) {
            meetingTypeId.disable();
            meetingTypeId.setValue(ActivityVideoConferenceToolEnum.OTHER);
          } else if (this.activity.activityTypeId !== ActivityTypeList.VIDEOCONF) {
            if (ActivityStatusType.PLANNED !== status) {
              meetingTypeId.setValue(ActivityVideoConferenceToolEnum.OTHER);
            }

            ActivityStatusType.PLANNED !== status ? meetingTypeId.disable() : meetingTypeId.enable();
          }
        }

        let newControl = new UntypedFormControl(description.value);

        // description.setValidators(null);
        const participants = this.form.get('participants');

        switch (status) {
          case ActivityStatusType.PLANNED:
            min = this.today.clone();
            participants.clearValidators();
            break;

          case ActivityStatusType.COMPLETED:
            max = this.today.clone();
            if (!statusCtrl.disabled && date.value?.diff(this.today) > 0) {
              date.setValue(null);
              timeStart.setValue(null);
              timeEnd.setValue(null);
            }
            newControl = new UntypedFormControl(description.value, Validators.required);

            // description.setValidators(Validators.required);
            participants.setValidators(ValidateParticipant.contactRequired);
            break;
          case ActivityStatusType.CANCELLED:
            participants.clearValidators();
            break;
        }

        // description.updateValueAndValidity();

        this.form.setControl('description', newControl);

        this.dateMin = min;
        this.dateMax = max;
      });

    // Watch time start and auto select next value for time end
    this.form.get('timeStart').valueChanges.pipe(filter(value => !!value)).subscribe(selected => {
      const time: SelectTime = {
        hour: selected.hour,
        minute: selected.minute
      };

      if (time.minute >= 30) {
        time.hour += 1;
        time.minute = 0;
      } else {
        time.minute = 30;
      }

      this.form.get('timeEnd').setValue(time);
    });

    // Watch account changes and decide address input state
    this.form.get('account').valueChanges
      .pipe(startWith(null as string))
      .subscribe(value => {
        if (!value || [ActivityTypeList.VIDEOCONF, ActivityTypeList.CALL, ActivityTypeList.EMAIL].includes(this.form.get('type').value)) {
          this.form.get('address').disable();
        } else {
          this.form.get('address').enable();
        }

      });

    // Watch activity type and detect address required
    this.form.get('type').valueChanges.subscribe(value => {
      const address = this.form.get('address');
      const meetingTypeId = this.form.get('meetingTypeId');
      this.isCreateVideoActivity = false;

      if (ActivityTypeList.VISIT === value) {
        address.setValidators([Validators.required]);
      } else {
        address.setValidators(null);
      }

      if ([ActivityTypeList.VIDEOCONF, ActivityTypeList.CALL, ActivityTypeList.EMAIL].includes(value)) {
        address.disable();
        address.patchValue(null);
        this.reSelectAddress = false;
      } else {
        address.enable();
        this.reSelectAddress = true;
      }

      if (ActivityTypeList.VIDEOCONF === value) {
        meetingTypeId.setValidators([Validators.required]);
        this.isCreateVideoActivity = this.isCreate;

        this.activityVideoConferenceToolService.list({}).subscribe(response => {
          if (response.success) {
            const filteredResponse = response.data.filter(data => data.enabled);
            this.activityVideoConferenceTool = filteredResponse.sort((a, b) => (a.orderBy > b.orderBy) ? 1 : -1);
            if (this.activityVideoConferenceTool.length === 0) {
              meetingTypeId.setValidators(null);
              meetingTypeId.updateValueAndValidity();
            }
          }
        }).add(() => this.loading = false);

        if ((this.form.get('status').value !== ActivityStatusType.PLANNED && ActivityTypeList.VIDEOCONF !== this.activity?.activityTypeId) ||
          this.type === ActivityDialogTypeEnum.LEAD_DRAFT
        ) {
          meetingTypeId.setValue(ActivityVideoConferenceToolEnum.OTHER);
          meetingTypeId.disable();
        }
      } else {
        meetingTypeId.setValidators(null);
      }

      address.updateValueAndValidity();
      meetingTypeId.updateValueAndValidity();

      this.getActivitySurveyStatus();
    });

    this.form.get('description').valueChanges.subscribe(value => {
      if (this.editorLength > 5000) {
        this.form.get('description').setErrors({ incorrect: true });
      } else {
        this.form.get('description').setErrors(null);
      }
    });

    this.form.get('meetingTypeId').valueChanges.pipe(startWith(null as string))
      .subscribe(value => {
        const type = this.form.get('type').value;
        const status = this.form.get('status').value;
        const participants = this.form.get('participants');

        if ((ActivityTypeList.VIDEOCONF === type) && ActivityStatusType.PLANNED === status) {
          if (ActivityVideoConferenceToolEnum.JITSI === value) {
            participants.setValidators(ValidateParticipant.contactRequired);
          } else {
            participants.clearValidators();
          }
          participants.updateValueAndValidity();
        }
      });

    combineLatest([
      this.store.select(hasUserPermission(Permission.ASSIGNMENT_INSERT)).pipe(untilDestroyed(this)),
      this.store.select(hasUserPermission(Permission.ACTIVITY_UPDATE_ALL_USER_AUTHORİZED_ACTIVITIES)).pipe(untilDestroyed(this)),
      this.store.select(hasUserPermission(Permission.ACTIVITY_NPS_SENDING_UPDATE)).pipe(untilDestroyed(this))
    ]).subscribe(([hasAssignmentPermission, hasActivityUpdatePermission, hasNPSSendingUpdatePermission]) => {
      this.hasAssignmentPermission = hasAssignmentPermission;
      this.hasActivityUpdatePermission = hasActivityUpdatePermission;
      this.hasNPSSendingUpdatePermission = hasNPSSendingUpdatePermission;
    });

    this.store.select(getUser).pipe(untilDestroyed(this)).subscribe(user => this.user = user);
  }

  @Input()
  set mode(value: 'page' | 'dialog') {
    if (value === 'page') {
      this.isPage = true;
    }

    if (value === 'dialog') {
      this.isDialog = true;
    }
  }

  private _type: ActivityDialogType = ActivityDialogTypeEnum.ACCOUNT;
  get type(): ActivityDialogType {
    return this._type;
  }

  @Input() set type(value: ActivityDialogType) {
    if (value) {
      this._type = value;

      if (value === this.activityDialogTypeEnum.LEAD_DRAFT && !this.activity?.account) {
        this.form.get('account').clearValidators();

        this.store.select(hasUserPermission(Permission.LEAD_DRAFT_ACTIVITY_UPDATE)).pipe(untilDestroyed(this))
          .subscribe(hasLeadDraftActivityUpdatePermission => this.hasLeadDraftActivityUpdatePermission = hasLeadDraftActivityUpdatePermission);
      }
    }
  }

  get description() {
    return this.form.get('description');
  }

  private _activity: Activity = undefined;

  get activity(): Activity {
    return this._activity;
  }

  @Input()
  set activity(activity: Activity) {
    this._activity = activity;
    if (activity) {
      this.isCreate = false;
      const participants: Participants = {
        contacts: activity.activityContacts.filter(item => !!item.contact).map(item => item.contact),
        systemUsers: activity.activitySystemUsers.filter(item => !!item.systemUser).map(item => item.systemUser)
      };

      let purpose: SelectActivityPurpose;
      if (activity.activityPurposeId) {
        purpose = {
          activityPurpose: activity.activityPurpose,
          opportunities: []
        };

        activity.activityInfos.forEach(info => {
          if (info.opportunity) {
            purpose.opportunities.push(info.opportunity);
          }
        });
      }

      let date;
      let timeStart: SelectTime;
      let timeEnd: SelectTime;
      let activityAssignment;

      if (activity.activityStatusId === ActivityStatusType.COMPLETED) {
        date = activity.actualStartDate;
        timeStart = {
          hour: activity.actualStartDate.hour(),
          minute: activity.actualStartDate.minute(),
        };
        timeEnd = {
          hour: activity.actualEndDate.hour(),
          minute: activity.actualEndDate.minute(),
        };
      } else {
        date = activity.planningStartDate;
        timeStart = {
          hour: activity.planningStartDate.hour(),
          minute: activity.planningStartDate.minute(),
        };
        timeEnd = {
          hour: activity.planningEndDate.hour(),
          minute: activity.planningEndDate.minute(),
        };
      }

      if (activity.activityAssignments.length > 0) {
        activityAssignment = activity.activityAssignments.map(assignment => {
          const item: any = {
            assignmentId: assignment.assignmentId,
            description: assignment.assignment?.description,
            dueDate: assignment.assignment?.dueDate,
            assignedUser: assignment.assignment?.assignedUser,
            assignmentStatusId: assignment.assignment?.assignmentStatusId,
            assignmentStatus: assignment.assignment?.assignmentStatus,
            explanation: assignment.assignment?.explanation,
            actionDate: assignment.assignment?.actionDate,
            actionUser: assignment.assignment?.actionUser,
            id: assignment.id,
            salesOrganizationId: assignment.assignment?.salesOrganizationId,
            assignmentPriorityId: assignment.assignment?.assignmentPriorityId,
            dynamicFieldValues: assignment.assignment?.dynamicFieldValues,
            createdBy: assignment.assignment?.createdBy
          };
          return item;
        });
      }

      this.form.patchValue({
        type: activity.activityTypeId,
        status: activity.activityStatusId,
        address: activity.address,
        participants,
        purpose,
        date,
        timeStart,
        timeEnd,
        description: activity.description,
        privateNote: activity.privateNote,
        activityAssignment,
        activityPdfTemplate: activity.activityPdfTemplate,
        meetingTypeId: activity.meeting?.meetingTypeId,
        dynamicFieldValues: activity.dynamicFieldValues
      }, { emitEvent: true });

      if (activity.leadDraftInfoActivity && !activity.account) {
        this.type = this.activityDialogTypeEnum.LEAD_DRAFT;
        this.leadDraftInfoActivityCustomer = activity.leadDraftInfoActivity.leadDraftInfo.leadDraft.customer;
        this.activityCustomerName = this.leadDraftInfoActivityCustomer.name;

      } else {
        this.form.get('account').setValue(activity.account);

        if (this.activity.activityTypeId === this.activityType.VIDEOCONF) {
          this.form.get('type').disable();
          this.form.get('meetingTypeId').disable();

          if (this.activity.activityStatusId === this.activityStatus.COMPLETED || this.activity.activityStatusId === this.activityStatus.CANCELLED) {
            this.form.get('status').disable();
          }
        }
      }

      if (activity.activityTypeId === ActivityTypeList.VIDEOCONF && activity.meeting) {
        this.meetingService.detail(activity.meeting.meetingId).pipe(
          map(response => response.data)).subscribe(res => {
            this.callDetail = res;
          });
      }
    }

    if (!activity) {
      const now = new Date();
      const hour = now.getHours();
      let minute = now.getMinutes();

      minute < 30 ? minute = 0 : minute = 30;

      this.form.patchValue({
        date: this.today,
        timeStart: { hour, minute }
      });
    }
  }

  private _account: Account = null;

  get account(): Account {
    return this._account;
  }

  @Input() set account(account: Account) {
    this._account = account;

    if (account) {
      this.onSelectionChange(account);
      this.form.get('account').setValue(this.account);
      if (!this.isAccountEnabled) {
        this.form.get('account').disable();
      }
      this.getSalesOrganizationSetting();
    }
  }

  get ActivityTypeList() {
    return ActivityTypeList;
  }

  private _leadDraftInfo: LeadDraftInfo = null;
  get leadDraftInfo(): LeadDraftInfo {
    return this._leadDraftInfo;
  }

  @Input() set leadDraftInfo(leadDraftInfo) {
    this._leadDraftInfo = leadDraftInfo;

    if (leadDraftInfo) {
      this.activityCustomerName = leadDraftInfo.leadDraft.companyName ?? leadDraftInfo.leadDraft.customer.name;

      this.form.get('address').enable();
      this.form.get('account').clearValidators();
    }
  }

  ngOnInit() {
    // If account given with input, disable account changes.
    this.route.data.subscribe(data => {
      if (data.isFromVideo) {
        this.isFromVideo = true;
      }

      if (data.isNewForm) {
        this.activity = null;
      }
    });

    if (this.activity && this.user.userId) {

      this.activity['createdBy'] === this.user.userId || this.hasActivityUpdatePermission
        ? this.isDisabled = false : this.isDisabled = true;

      if (this.type === ActivityDialogTypeEnum.ACCOUNT) {
        this.getSaveAndSendButtonVisibility();
      }
    }

    this.store.select(getAccount).pipe(
      filter(() => !this.isDialog),
      untilDestroyed(this)
    ).subscribe(account => {
      if (account) {
        this.account = account;
        this.isPage = true;

        if (!this.activity && this.type === ActivityDialogTypeEnum.ACCOUNT) {
          this.getSaveAndSendButtonVisibility();
        }
      }
    });

    if (this.contact) {
      this.setParticipants([this.contact]);
    }

  }

  ngAfterViewInit(): void {
    if (!this.isInitialized) {
      this.initialFormValue = this.form.getRawValue();
      this.isInitialized = true;
      this.formChangeDetection();
    }
  }

  private formChangeDetection() {
    this.form.valueChanges.pipe(
      debounceTime(400),
      untilDestroyed(this),
      skip(1)
    ).subscribe(() => {
      const currentValue = this.form.getRawValue();
      const hasChanges = !isEqual(this.initialFormValue, currentValue);

      if (this.formChanged !== hasChanges) {
        this.formChanged = hasChanges;
        this.isFormChange.emit(hasChanges);
      }
    });
  }

  setParticipants(contacts: Contact[]) {

    const control = this.form.get('participants');

    const participants: Participants = {
      contacts,
      systemUsers: control.value?.systemUsers ?? []
    };

    control.patchValue(participants);

  }

  getSaveAndSendButtonVisibility(salesOrganizationId = this.account.salesOrganizationId) {
    const request: SalesOrganizationSettingInfoSearch = {
      filter: {
        salesOrganizationId,
        salesOrganizationSettingDetailIds: [EnumSalesOrganizationSettingInfoDetail.SAVE_AND_SEND],
        salesOrganizationSettingInfoIds: []
      }
    };


    this.activityStaticRecipientService.getSaveAndSendSettingInfo(request)
      .subscribe((res) => {
        this.hideSaveAndSendButton = res.data?.[0]?.booleanValue;
      });
  }

  onSubmit() {

    // If form is valid, open send dialog
    if (this.form.valid) {
      this.emitActivity();
    } else {
      // Touch all form fields and trigger validate
      this.form.markAllAsTouched();
      this.openSeparator = true;
      this.cdr.detectChanges();
      scrollToError();
      this.openSeparator = false;
    }
  }

  clearParticipants(data: Account) {

    this.setParticipants([]);

    this.onSelectionChange(data);

  }

  onSelectionChange(data: Account) {
    this.selectedAccountSalesOrganizationId = data.salesOrganizationId;
    this.store.select(getSalesOrganizationSettingValue(SalesOrganizationAdvancedSettingType.ACTIVITY_MAIL, data.salesOrganizationId))
      .subscribe(booleanValue => {
        this.hasSendMailWithActivity = booleanValue;
      });
    this.getActivitySurveyStatus();

    if (!this.account) {

      this.getSaveAndSendButtonVisibility(data.salesOrganizationId);
    }

  }

  onParticipantSelectionChange(data: Participants) {
    if (this.form.get('status').value === ActivityStatusType.COMPLETED) {
      this.isParticipantsChanged = false;

      for (const contact of data.contacts) {
        if (!this.checkActivityContact(contact)) {
          this.isParticipantsChanged = true;
          break;
        }
      }
    }
  }

  getActivitySurveyStatus() {
    if (this.selectedAccountSalesOrganizationId && this.form.get('type').value) {
      this.surveyMailService
        .getActivitySurveyStatus(this.selectedAccountSalesOrganizationId, this.form.get('type').value)
        .subscribe(res => this.activitySurveyStatus = res.data);
    }
  }

  checkActivityContact(contact: Contact): string | null {
    if (this.activity && this.activity.activityContacts.length > 0) {
      return this.activity.activityContacts.find(con => con.contact.contactId === contact.contactId)?.id;
    }

    return null;
  }

  checkActivitySystemUser(systemUser: SystemUser): string | null {
    if (this.activity && this.activity.activitySystemUsers.length > 0) {
      return this.activity.activitySystemUsers.find(con => con.systemUser.systemUserId === systemUser.systemUserId)?.id;
    }

    return null;
  }

  checkActivityInfo(opportunity?: Opportunity): string | null {
    if (this.activity && this.activity.activityInfos.length > 0) {
      if (opportunity) {
        return this.activity.activityInfos.find(info => info.opportunityId === opportunity.opportunityId)?.activityInfoId;
      } else {
        return this.activity.activityInfos[0].activityInfoId;
      }
    }
    return null;
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy() {
  }

  joinMeeting() {
    if (this.isDialog) {
      this.dialog.closeAll();
    }
    this.store.dispatch(new GetAccount(this.activity.accountId));
    if (this.activity.meeting.meetingTypeId === ActivityVideoConferenceToolEnum.ZOOM) {
      this.router.navigate(['video-conference/meeting', this.activity.accountId, this.activity.meeting.meetingId]);
    } else if (this.activity.meeting.meetingTypeId === ActivityVideoConferenceToolEnum.JITSI) {
      this.router.navigate(['video-conference/xooi', this.activity.accountId, this.activity.meeting.meetingId]);
    }
  }

  watchMeeting() {
    if (this.isDialog) {
      this.dialog.closeAll();
    }
    this.router.navigate(['video-conference/record', this.activity.accountId, this.activity.meeting.meetingId]);
  }

  canJoinMeeting(): boolean {
    if (this.callDetail && !this.isFromVideo && this.activity.meeting.meetingTypeId !== ActivityVideoConferenceToolEnum.OTHER) {
      if (this.activity?.meeting?.meetingStatusId === MeetingStatusType.ACTIVE) {
        return true;
      }
      if (this.callDetail.isHost
        && (this.activity?.meeting?.meetingStatusId === MeetingStatusType.WAITING
          || this.activity?.meeting?.meetingStatusId === MeetingStatusType.POSTPONED)) {
        const remainingMinutes = moment.duration(moment(this.callDetail.planningStartDate).diff(moment.now())).asMinutes();
        return remainingMinutes <= 10;
      }
    }
    return false;
  }

  getSalesOrganizationSetting() {
    this.store.select(getSalesOrganizationSettingValue(SalesOrganizationAdvancedSettingType.ACTIVITY_MAIL, this.account?.salesOrganizationId))
      .subscribe(booleanValue => {
        this.hasSendMailWithActivity = booleanValue;
      });
  }

  canSeeLink(): boolean {
    return this.callDetail && !this.isFromVideo && this.activity.meeting.meetingTypeId !== ActivityVideoConferenceToolEnum.OTHER;
  }

  canWatchMeeting(): boolean {
    return !!(this.callDetail && this.callDetail?.videoUrl);
  }

  changeEditorLength(length: number) {
    this.editorLength = length;
    if (this.editorLength > 5000) {
      setTimeout(() => {
        this.form.get('description').setErrors({ incorrect: true });
      }, 100);
    } else {
      this.form.get('description').setErrors(null);
    }
  }

  private emitActivity() {
    const status: ActivityStatusType = this.form.get('status').value;
    const purpose: SelectActivityPurpose = this.form.get('purpose').value;
    const type = this.form.get('type').value;
    const meetingTypeId = this.form.get('meetingTypeId').value;

    const activityInfos: ActivityInfo[] = [];
    let activityAssignments: ActivityAssignmentRequest[];

    const startDate = this.form.get('date').value.clone()
      .hour(this.form.get('timeStart').value.hour)
      .minute(this.form.get('timeStart').value.minute);

    const endDate = this.form.get('date').value.clone()
      .hour(this.form.get('timeEnd').value.hour)
      .minute(this.form.get('timeEnd').value.minute);

    if (purpose.opportunities.length > 0) {
      purpose.opportunities.forEach(opportunity => {
        const activityInfoId = this.checkActivityInfo(opportunity);

        activityInfos.push({
          opportunityId: opportunity.opportunityId,
          activityId: this.activity?.activityId,
          ...activityInfoId ? { activityInfoId } : {},
        });
      });
    } else {
      const activityInfoId = this.checkActivityInfo();

      if (this.activity?.activityId || activityInfoId) {
        activityInfos.push({
          activityId: this.activity?.activityId,
          ...activityInfoId ? { activityInfoId } : {},
        });
      }
    }

    // Emit save event with opportunity request object
    let request: ActivityRequest = {
      activityTypeId: this.form.get('type').value,
      activityStatusId: status,
      addressId: this.form.get('address').value?.addressId ? this.form.get('address').value.addressId : null,
      description: this.form.get('description').value,
      privateNote: this.form.get('privateNote').value,
      ...activityInfos.length > 0 ? { activityInfos } : {},
      activityPurposeId: purpose.activityPurpose?.activityPurposeId,
      activityAction: this.activityAction,
      dynamicFieldValues: processDynamicFieldValues(this.form.get('dynamicFieldValues')?.value)
    };

    if (this.type !== ActivityDialogTypeEnum.LEAD_DRAFT) {
      request = {
        ...request,
        account: this.form.get('account').value,
        accountId: this.form.get('account').value.accountId
      };
    }

    if (ActivityTypeList.VIDEOCONF === type && this.activityVideoConferenceTool.length !== 0 && meetingTypeId !== undefined) {
      request.meeting = {
        meetingTypeId
      };
    }

    if (ActivityStatusType.COMPLETED === status) {
      request.actualStartDate = startDate;
      request.actualEndDate = endDate;
    } else {
      request.planningStartDate = startDate;
      request.planningEndDate = endDate;
    }

    const participants: Participants = this.form.get('participants').value;
    if (participants.contacts.length > 0) {

      request.activityContacts = participants.contacts.map(contact => ({
        contactId: contact.contactId,
        firstName: contact.firstName,
        lastName: contact.lastName,
        ...this.checkActivityContact(contact) ? { id: this.checkActivityContact(contact) } : {},
        activityId: this.activity?.activityId
      }));
    }

    if (participants.systemUsers.length > 0) {
      request.activitySystemUsers = participants.systemUsers.map(systemUser => ({
        systemUserId: systemUser.systemUserId,
        firstName: systemUser.firstName,
        lastName: systemUser.lastName,
        ...this.checkActivitySystemUser(systemUser) ? { id: this.checkActivitySystemUser(systemUser) } : {},
        activityId: this.activity?.activityId,
      }));
    }

    if (this.form.get('activityAssignment').value && this.form.get('activityAssignment').value.length > 0) {

      activityAssignments = this.form.get('activityAssignment').value.filter(x => !!x.description).map((item: any) => {
        const assignment: ActivityAssignmentRequest = {
          assignment: {
            accountId: this.account?.accountId,
            description: item.description,
            assignedUserId: item.assignedUser.systemUserId,
            actionUserId: (item.actionUser && item.actionUser.systemUserId) ? item.actionUser.systemUserId : this.user.userId,
            dueDate: item.dueDate,
            actionDate: item.actionDate ? item.actionDate : new Date().toISOString(),
            explanation: item.explanation,
            assignmentStatusId: item.assignmentStatusId,
            salesOrganizationId: this.form.get('account').value?.salesOrganizationId ??
              this.activity?.leadDraftInfoActivity?.leadDraftInfo?.salesOrganizationId,
            assignmentPriorityId: item.assignmentPriorityId,
            dynamicFieldValues: processDynamicFieldValues(item.dynamicFieldValues)
          }
        };
        if (item.assignmentId) {
          assignment.assignment.assignmentId = item.assignmentId;
          assignment.assignmentId = item.assignmentId;
          assignment.id = item.id;

          const firstStatusId = this.activity.activityAssignments.find(asg => asg.assignmentId === item.assignmentId).assignmentStatusId;
          if (item.assignmentStatusId !== firstStatusId) {
            assignment.assignment.actionUserId = this.user.userId;
            assignment.assignment.actionDate = new Date().toISOString();
          }
        } else {
          assignment.assignment.actionUserId = this.user.userId;
          assignment.assignment.actionDate = new Date().toISOString();
        }

        if (this.activity) {
          assignment.activityId = this.activity.activityId;
        }

        return assignment;
      });

      request.activityAssignments = activityAssignments;
    } else {
      request.activityAssignments = [];
    }

    request.sendSurveyMail = this.getSendSurveyMailCondition() ? this.form.get('sendSurveyMail').value : false;

    // if (this.activity && this.form.get('activityPdfTemplate').value) {
    //   request.activityPdfTemplateId = this.form.get('activityPdfTemplate').value.activityPdfTemplateId;
    // } else if (!this.activity && this.form.get('status').value === ActivityStatusType.COMPLETED && this.form.get('activityPdfTemplate')?.value) {
    //   request.activityPdfTemplateId = this.form.get('activityPdfTemplate').value.activityPdfTemplateId;
    // }

    this.save.emit(request);
  }

  getSendSurveyMailCondition() {
    return (
      this.activitySurveyStatus &&
      ((!this.isPage && this.form.get('status').value === this.activityStatus.COMPLETED) ||
        (this.isPage && this.form.get('status').value === this.activityStatus.COMPLETED && this.isParticipantsChanged) ||
        (this.activity?.activityStatusId !== this.activityStatus.COMPLETED && this.form.get('status').value === this.activityStatus.COMPLETED))
    );
  }
}
