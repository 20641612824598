<mat-form-field fxFlex="100">
    <mat-label>{{ placeholder }}</mat-label>
    <mat-select [formControl]="selectCtrl" multiple 
        #userGroupsSelect (openedChange)="openedChange($event)" (selectionChange)="onSelectionChange()">
        <mat-select-trigger *ngIf="value.length > 2">
            {{ 'GENERAL.VALUE_USERS_SELECTED' | translate: { value: value.length } }}
        </mat-select-trigger>
        <input matInput class="mt-4 ml-4" (keydown)="checkKey($event)" [placeholder]="'GENERAL.SEARCH' | translate" [ngModelOptions]="{standalone: true}" 
            [(ngModel)]="searchText" (ngModelChange)="onKey($event)" [ngClass]="isOpened ? 'visible-searchbox': 'invisible-searchbox'">
        <ng-container *ngFor="let group of filteredUserGroups">
            <mat-optgroup [label]="group.groupName">
                <mat-option *ngFor="let item of group.detail" [value]="item.userId" (click)="changeActiveItem(item.userId)">
                    {{ item.userName }}
                </mat-option>
            </mat-optgroup>
        </ng-container>
    </mat-select>
</mat-form-field>