import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OpportunityParameter, OpportunityParameterRequest } from './opportunity-parameter.model';
import { environment } from '@env/environment';
import { Response } from '@core/api';

@Injectable({
    providedIn: 'root',
})
export class OpportunityParameterService {
    constructor( private http: HttpClient) { }

    search(request: OpportunityParameterRequest): Observable<Response<OpportunityParameter[]>> {
        return this.http.post<Response<OpportunityParameter[]>>(environment.apiUrl + '/api/OpportunityParameter/Search', request);
    }

    getCount(request: OpportunityParameterRequest): Observable<Response<number>> {
        return this.http.post<Response<number>>(environment.apiUrl + '/api/OpportunityParameter/GetCount', request);
    }
}
