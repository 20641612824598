import { Subject } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';

import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, DoCheck, ElementRef, EventEmitter,
  HostBinding, Input, OnDestroy, OnInit, Optional, Output, Self
} from '@angular/core';
import { ControlValueAccessor, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { OrganizationMainServiceServiceModel, RevenueType, Service, SystemSettingType } from '@core/api';
import icAdd from '@iconify/icons-ic/twotone-add';
import icMinus from '@iconify/icons-ic/twotone-minus';
import { TranslateService } from '@ngx-translate/core';

import { Guid, RevenueTypeEnum } from '../../../core/api/api.enum';
import { OpportunityService } from './input-opportunity-services.component.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '@core/auth/auth.service';
import { StaticField } from '@core/api/static-field/static-field.model';
import { StaticFieldEnum } from '@core/api/static-field/static-field.enum';

@UntilDestroy()
@Component({
  selector: 'net-input-opportunity-services[organizationId]',
  templateUrl: './input-opportunity-services.component.html',
  styleUrls: ['./input-opportunity-services.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: InputOpportunityServicesComponent
    }
  ]
})
export class InputOpportunityServicesComponent implements OnInit, OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<OpportunityService[] | null> {

  static nextId = 0;
  metricLabel = this.translate.instant('GENERAL.AMOUNT');
  revenueLabel = this.translate.instant('GENERAL.REVENUE');
  init = true;
  loading = false;
  focused = false;
  errorState = false;
  controlType = 'input-opportunity-services';
  describedBy = '';
  stateChanges = new Subject<void>();
  loadingStateChanges = new Subject<void>();
  systemSettingType = SystemSettingType;

  staticFieldEnum = StaticFieldEnum;

  services: Service[] = [];
  form = new UntypedFormGroup({});
  mainServices: any[];

  icAdd = icAdd;
  icMinus = icMinus;

  revenueType = RevenueTypeEnum;
  selectedServices: string[] = [];

  @Input() currencyCode: string;

  @Input() staticFields: StaticField[];

  @Input() get revenueTypes(): RevenueType[] { return this._revenueTypes; }
  set revenueTypes(value: RevenueType[]) {
    this._revenueTypes = value;
    if (value && value.length > 0) {
      this.form = new UntypedFormGroup({
        services: new UntypedFormArray([this.createServiceForm()])
      });
    }
  }

  private _revenueTypes: RevenueType[] = [];

  @Input()
  get organizationMainServiceService(): OrganizationMainServiceServiceModel[] { return this._organizationMainServiceService; }
  set organizationMainServiceService(organizationMainServiceService: OrganizationMainServiceServiceModel[]) {
    this._organizationMainServiceService = organizationMainServiceService;

    if (organizationMainServiceService) {
      this.syncServiceAndUnits(organizationMainServiceService, this.organizationId);
    }
  }
  private _organizationMainServiceService: OrganizationMainServiceServiceModel[];

  @Input()
  get organizationId() { return this._organizationId; }
  set organizationId(organizationId) {

    if (organizationId) {
      this.syncServiceAndUnits(this.organizationMainServiceService, organizationId);
    } else {
      this.getServicesForm().clear();
      this.selectedServices = [];
      this.add();
      this.form.disable();
    }
    this._organizationId = organizationId;
  }
  private _organizationId: string;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    if (value) {
      this.form.disable();
    } else {
      this.form.enable();
      this.writeValue(this.value);
    }

    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get optionalDisabled(): boolean { return this._optionalDisabled; }
  set optionalDisabled(value: boolean) {
    this._optionalDisabled = value;
    if (value) {
      this.disabled = true;
    }
    this.stateChanges.next();
  }
  private _optionalDisabled = false;

  @Input()
  get value(): OpportunityService[] | null { return this._value; }
  set value(services: OpportunityService[] | null) {
    this._value = services;
    this.onChange(services);
    this.stateChanges.next();
  }
  private _value: OpportunityService[];

  @Output() update = new EventEmitter<OpportunityService[]>();
  @Output() serviceLoading = new EventEmitter<boolean>();

  @HostBinding('id') id = `input-opportunity-services-${InputOpportunityServicesComponent.nextId++}`;
  @HostBinding('attr.tabindex') tabIndex = -1;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  get shouldLabelFloat() { return true; }

  get empty() { return !this.value; }

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private formBuilder: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private translate: TranslateService,
    private authService: AuthService,
    @Optional() @Self() public ngControl: NgControl
  ) {

    // Material form field implementation
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    // Watch form value changes and update
    this.form.valueChanges.pipe(debounceTime(300)).subscribe((values) => {
      const services = this.getServicesForm().getRawValue();
      const value = services.length > 0 ? services : null;

      this.value = value;
      this.selectedServices = value.map(item => item.serviceId);

      this.update.emit(value);
      this.changeDetectorRef.markForCheck();
    });

    // Init with disabled state
    this.disabled = true;

    this.changeDetectorRef.detectChanges();
  }

  getSelectedServiceId(index: number): string | null {
    const services = this.getServicesForm().getRawValue();
    return services[index].serviceId;
  }

  syncServiceAndUnits(organizationMainServiceService: any[], organizationId: string) {
    if (organizationMainServiceService && organizationId) {
      // Reset form
      this.loading = true;
      this.disabled = true;
      this.services = [];
      this.changeDetectorRef.detectChanges();

      // Fetch service with service by organization
      this.mainServices = organizationMainServiceService
        .map(item => item.organizations)
        .flat()
        .filter(item => item.organizationId === organizationId)
        .map(item => item.mainServices)
        .flat();

      if (!this.init && !this.value) {
        this.getServicesForm().clear();
        this.selectedServices = [];
        this.add();
      }

      this.init = false;
      this.loading = false;
      this.disabled = false;

      if (this.optionalDisabled) {
        setTimeout(() => { this.disabled = true; });
      }

      this.loadingStateChanges.next();
      this.changeDetectorRef.detectChanges();
    }
  }

  setOrganizationMainServiceServiceId(control: UntypedFormGroup) {
    const services = this.mainServices.map(mainService => mainService.services).flat();
    const organizationMainServiceServiceId = services.find(service => service.serviceId === control.value.serviceId).id;

    control.controls.organizationMainServiceServiceId.patchValue(organizationMainServiceServiceId);
    this.changeDetectorRef.detectChanges();
  }

  getServicesForm(): UntypedFormArray {
    return this.form.get('services') as UntypedFormArray;
  }

  createServiceForm(item?: OpportunityService): UntypedFormGroup {
    const newForm = this.formBuilder.group({
      serviceId: [item ? item.serviceId : null],
      detail: [item ? item.detail : null],
      detailRequired: [false],
      metric: [item ? item.metric : null],
      unit: [item ? item.unit : null],
      revenue: [item ? item.revenue : null],
      unitPrice: [item ? item.unitPrice : null],
      unitPriceRequired: [false],
      revenueTypeId: [item ? item.revenueTypeId : this.revenueTypes[0]?.revenueTypeId],
      organizationMainServiceServiceId: [item ? item.organizationMainServiceServiceId : null],
      opportunityInfoId: [item && item?.opportunityInfoId ? item?.opportunityInfoId : Guid.EMPTY]
    });
    this.listenForm(newForm);
    return newForm;
  }

  add(service?: OpportunityService) {
    if (service) {
      if (service.revenueTypeId === RevenueTypeEnum.FORONCE) {
        this.metricLabel = this.translate.instant('GENERAL.AMOUNT');
        this.revenueLabel = this.translate.instant('GENERAL.REVENUE');
      } else {
        this.metricLabel = this.translate.instant('GENERAL.RECURRING_AMOUNT');
        this.revenueLabel = this.translate.instant('GENERAL.RECURRING_REVENUE');
      }
    } else {
      this.metricLabel = this.translate.instant('GENERAL.RECURRING_AMOUNT');
      this.revenueLabel = this.translate.instant('GENERAL.RECURRING_REVENUE');
    }

    this.getServicesForm().push(this.createServiceForm(service));
    this.changeDetectorRef.detectChanges();
  }

  listenForm(form: UntypedFormGroup) {
    const unitPrice = form.get('unitPrice');
    const metric = form.get('metric');
    const revenue = form.get('revenue');
    const detailRequired = form.get('detailRequired');
    const unitPriceRequired = form.get('unitPriceRequired');

    if (this.getStaticFieldActiveFlag(StaticFieldEnum.OPPORTUNITY_DETAIL_DETAIL_FIELD_USAGE)) {
      detailRequired.patchValue(this.getStaticFieldRequiredFlag(StaticFieldEnum.OPPORTUNITY_DETAIL_DETAIL_FIELD_USAGE));
    }

    if (this.getStaticFieldActiveFlag(StaticFieldEnum.OPPORTUNITY_DETAIL_UNIT_PRICE_FIELD_USAGE)) {
      unitPriceRequired.patchValue(this.getStaticFieldRequiredFlag(StaticFieldEnum.OPPORTUNITY_DETAIL_UNIT_PRICE_FIELD_USAGE));
    }

    unitPrice.valueChanges.pipe(
      tap(() => this.serviceLoading.emit(true)),
      debounceTime(1000),
      untilDestroyed(this)
    ).subscribe(values => {
      if (values && metric.value) {
        revenue.patchValue(this.castingMetricAndUnitPrice(metric.value, values), { emitEvent: false });
        revenue.disable({ emitEvent: false });
      } else {
        if (this.organizationId) {
          revenue.enable({ emitEvent: false });
        }
      }

      this.serviceLoading.emit(false);
    });

    metric.valueChanges.pipe(
      tap(() => this.serviceLoading.emit(true)),
      debounceTime(1000),
      untilDestroyed(this)
    ).subscribe(values => {
      if (values && unitPrice.value && this.getStaticFieldActiveFlag(StaticFieldEnum.OPPORTUNITY_DETAIL_UNIT_PRICE_FIELD_USAGE)) {
        revenue.patchValue(this.castingMetricAndUnitPrice(values, unitPrice.value), { emitEvent: false });
        revenue.disable({ emitEvent: false });
      } else {
        if (this.organizationId) {
          revenue.enable({ emitEvent: false });
        }
      }

      this.serviceLoading.emit(false);
    });

  }

  castingMetricAndUnitPrice(metric: any, unitPrice: any) {
    const isTr = this.authService.numberLanguage === 'tr';
    const [thousandsSeparatorSymbol, decimalSymbol] = [(isTr ? '.' : ','), (isTr ? ',' : '.')];
    const unitPriceNumber = Number(unitPrice);
    const metricNumber = Number(metric.toString().replaceAll(thousandsSeparatorSymbol, ''));
    return unitPriceNumber * metricNumber;
  }

  remove(index: number) {
    const form = this.getServicesForm();

    if (form.length > 0) {
      form.removeAt(index);
    }

    if (form.length === 0) {
      this.getServicesForm().push(this.createServiceForm());
      this.selectedServices = [];
    }
    this.changeDetectorRef.detectChanges();
  }

  onClickAdd() {
    if (this.disabled) {
      return;
    }

    this.add();
  }

  onClickRemove(index: number) {
    if (this.disabled) {
      return;
    }

    this.remove(index);
  }

  writeValue(opportunityServices: OpportunityService[]) {
    if (opportunityServices && opportunityServices.length > 0) {
      this.value = opportunityServices;

      this.getServicesForm().clear();
      opportunityServices.forEach(service => {
        this.add(service);
        this.selectedServices.push(service.serviceId);
      });

      this.changeDetectorRef.detectChanges();
    }
  }

  serviceCompareWith(one: string, two: string) {
    return one && two && one === two;
  }

  onChange = (_: any) => { };
  onTouched = () => { };
  onContainerClick = () => { };
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');
  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }

  revenueTypeChange(event: any) {
    if (event.value === RevenueTypeEnum.FORONCE) {
      this.metricLabel = this.translate.instant('GENERAL.AMOUNT');
      this.revenueLabel = this.translate.instant('GENERAL.REVENUE');
    } else {
      this.metricLabel = this.translate.instant('GENERAL.RECURRING_AMOUNT');
      this.revenueLabel = this.translate.instant('GENERAL.RECURRING_REVENUE');
    }
  }

  getTooltipText(formControl: UntypedFormControl, controlName: string, maxChar: number): string {
    const detail = formControl.get(controlName).value;
    if (detail && detail.length > maxChar) {
      return formControl.get(controlName).value;
    }
    return '';
  }

  getStaticField(staticFieldId: string) {
    return this.staticFields?.find(f => f.entityFieldId === staticFieldId);
  }

  getStaticFieldRequiredFlag(staticFieldId: string): boolean {
    return this.staticFields?.find(f => f.entityFieldId === staticFieldId)?.requiredFlag ?? false;
  }

  getStaticFieldActiveFlag(staticFieldId: string): boolean {
    return this.staticFields?.find(f => f.entityFieldId === staticFieldId)?.activeFlag ?? false;
  }
}
