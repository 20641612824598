import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

import { BoardHashtag, BoardHashtagRequest, BoardHashtagSearchRequest, PagedResponse, Response, BoardHashtagSearchResponse } from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class BoardHashtagService {

  constructor(private http: HttpClient) { }

  insert(request: BoardHashtagRequest): Observable<Response<BoardHashtag>> {
    return this.http.post<Response<BoardHashtag>>(environment.apiUrl + '/api/BoardHashtag/Insert', request);
  }

  search(request: BoardHashtagSearchRequest): Observable<PagedResponse<BoardHashtagSearchResponse>> {
    return this.http.post<PagedResponse<BoardHashtagSearchResponse>>(environment.apiUrl + '/api/BoardHashtag/Search', request);
  }

  update(request: BoardHashtagRequest): Observable<Response<BoardHashtag>> {
    return this.http.post<Response<BoardHashtag>>(environment.apiUrl + '/api/BoardHashtag/Update', request);
  }

}
