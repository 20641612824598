import { DomSanitizer } from '@angular/platform-browser';
import { ApiDataSource } from '@core/api/api.data-source';
import {
  DynamicField,
  DynamicFieldEntityAsString,
  DynamicFieldEntityId,
  DynamicFieldService,
  DynamicFieldTypeId,
  DynamicFieldValue,
  DynamicFieldValueSearch,
  DynamicFieldValueType,
  DynamicFieldValueTypeAsString,
  Guid
} from '@core/api';

import moment from 'moment';
import { map } from 'rxjs/operators';

import { TableColumn } from '../interfaces/table-column.interface';
import { MatHeaderFilterType } from '../components/mat-header-filter/mat-header-filter.component.enum';
import { NormalizeDecimalSeparatorPipe } from '../pipes/normalize-decimal-seperator.pipe';
import { TranslateService } from '@ngx-translate/core';

function getFilterType(field: DynamicField): MatHeaderFilterType {
  const valueType = getValueTypeFromDynamicFieldTypeId(field.dynamicFieldTypeId);

  switch (valueType) {
    case DynamicFieldValueType.TEXT:
      return MatHeaderFilterType.search;

    case DynamicFieldValueType.PICKLIST:
      // if (field.multipleValueFlag) {
      return MatHeaderFilterType.paginationSelect;
    // }

    // return MatHeaderFilterType.oneSelect;

    case DynamicFieldValueType.INTEGER:
    case DynamicFieldValueType.DECIMAL:
      return MatHeaderFilterType.minMax;

    case DynamicFieldValueType.DATE:
    case DynamicFieldValueType.DATETIME:
      return MatHeaderFilterType.date;
  }

  throw new Error(`Unknown filter type for field ${field.dynamicFieldTypeId} and value type ${valueType}`);
}

export function updateWithDynamicFields<T>(
  dynamicFieldEntityId: DynamicFieldEntityId,
  dynamicFieldEntityName: DynamicFieldEntityAsString,
  columns: TableColumn<T>[],
  dataSource: ApiDataSource<T> | any,
  sanitizer: DomSanitizer,
  dynamicField: DynamicFieldService,
  normalizeDecimalSeparatorPipe: NormalizeDecimalSeparatorPipe,
  translate: TranslateService,
  columnInjectIndex = 0,
  direction: 'before' | 'after' = 'before',
  dynamicFieldDefaultVisibility = true,
): Promise<void> {
  const renderHelper = (field: DynamicField) => ((row) => {
    let values = row?.dynamicFieldValues ?? row?.dynamicFieldValuesJson ?? [];
    let output = '';

    // Update values accordingly lead draft entity model
    if (DynamicFieldEntityId.LEAD_DRAFT === field.dynamicFieldEntityId) {
      values = row?.leadDraft?.dynamicFieldValues ?? row?.dynamicFieldValuesJson ?? [];
    }

    if (values) {
      const value = values.find((v) => v.dynamicFieldId === field.dynamicFieldId);

      if (value) {
        switch (field.dynamicFieldTypeId) {
          case DynamicFieldTypeId.URL:
            const url = value.textValue.startsWith('http') ? '' : 'http://' + value.textValue;
            output = `<a href="${url}" target="_blank">${value.textValue}</a>`;
            break;

          case DynamicFieldTypeId.DATE:
            output = moment(value.dateValue).format('YYYY-MM-DD');
            break;

          case DynamicFieldTypeId.EMAIL:
            output = `<a href="mailto://${value.textValue}" target="_blank">${value.textValue}</a>`;
            break;

          case DynamicFieldTypeId.PHONE:
            output = `<a href="tel://${value.textValue}" target="_blank">${value.textValue}</a>`;
            break;

          case DynamicFieldTypeId.DATETIME:
            output = moment(value.dateValue).format('YYYY-MM-DD HH:mm');
            break;

          case DynamicFieldTypeId.PICKLIST:
            if (field.multipleValueFlag) {
              const mergedValues: string = value.nameValues.filter(item => item.value !== Guid.EMPTY)
                .map(item => item.name)
                .map(item => translate.instant(`${dynamicFieldEntityName}_${field.name}.${item}`)).join('; ');
              if (mergedValues.length > 50) {
                output = mergedValues.substring(0, 50) + '...';
              } else {
                output = mergedValues;
              }
            }

            if (!field.multipleValueFlag) {
              output = translate.instant(`${dynamicFieldEntityName}_${field.name}.${value.nameValue.name}`);
            }
            break;

          case DynamicFieldTypeId.SINGLE_LINE_TEXT:
            output = value.textValue;
            break;

          case DynamicFieldTypeId.NUMBER:
          case DynamicFieldTypeId.DECIMAL:
          case DynamicFieldTypeId.CURRENCY:
          case DynamicFieldTypeId.PERCENTAGE:
            const unit = field.unit ?? '';
            const symbol = field.dynamicFieldType.symbol ?? '';
            output = [normalizeDecimalSeparatorPipe.transform(value.numberValue), symbol, ' ', unit].join('');
            break;
        }
      }
    }

    return sanitizer.bypassSecurityTrustHtml(output);
  });

  return new Promise((resolve) => {
    // Lookup columns whit list view enabled and prepare columns
    dynamicField.search({ filter: { dynamicFieldEntityId, listFlag: true, activeFlag: true } })
      .subscribe((response) => {
        const fields = response?.data?.results ?? [];

        // Update datasource dynamic fields
        dataSource.dynamicFields = fields;

        // Return directly without modification when no fields found
        if (fields.length === 0) {
          resolve();
          return;
        }

        // Make reverse direction when unpacking to backward
        if ('before' === direction) {
          fields.reverse();
        }

        // Look over fields and inject columns
        fields.forEach((field, index, array) => {
          // Prepare table column
          const column: TableColumn<T> = {
            type: 'dynamicFieldValue',
            label: translate.instant(dynamicFieldEntityName + 'DynamicField.' + field.name),
            render: renderHelper(field),
            property: `dynamicField-${field.dynamicFieldId}`,
            visible: dynamicFieldDefaultVisibility,
            isDynamic: true,
            filterable: true,
            filterType: getFilterType(field),
            dynamicFieldId: field.dynamicFieldId,
            field
          };

          // Add filter source when filter type is select
          if ([MatHeaderFilterType.oneSelect, MatHeaderFilterType.select].includes(column.filterType)) {
            const payload = {
              page: 1,
              pageSize: 5, activeFlag: true,
              filter: {
                dynamicFieldId: field.pickListId ?? field.dynamicFieldId
              }
            };

            column.filterSelectSource = dynamicField
              .picklistPaginationSearch(payload)
              .pipe(
                map(picklist => {
                  return picklist.data.results.map((v) => {
                    return {
                      key: v.dynamicFieldPickListId,
                      value: translate.instant(`${dynamicFieldEntityName}_${field.name}.${v.name}`),
                    };
                  });
                })
              );
          }

          // Update inject index by direction
          if ('before' === direction) {
            --columnInjectIndex;
          }

          if ('after' === direction) {
            ++columnInjectIndex;
          }

          // Inject dynamic field column
          columns.splice(columnInjectIndex, 0, column);

          // Resolve promise when last field is processed
          if (index === array.length - 1) {
            resolve();
          }
        });
      });
  });
}


export function getDynamicFieldValueSearch(value, field: DynamicField): DynamicFieldValueSearch {
  const search: DynamicFieldValueSearch = {
    dynamicFieldId: field.dynamicFieldId,
    valueType: getValueTypeFromDynamicFieldTypeId(field.dynamicFieldTypeId),
  };

  // Prepare value filter by type
  switch (search.valueType) {
    case DynamicFieldValueType.TEXT:
      search.textValue = value;
      break;

    case DynamicFieldValueType.PICKLIST:
      if (field.multipleValueFlag) {
        search.guidValues = value;
        search.multiplePickListFlag = true;
      } else {
        search.guidValues = value;
        search.multiplePickListFlag = false;
      }
      break;

    case DynamicFieldValueType.DATE:
    case DynamicFieldValueType.DATETIME:
      search.dateValueMin = value.min ?? value.startDate;
      search.dateValueMax = value.max ?? value.endDate;
      break;

    case DynamicFieldValueType.INTEGER:
    case DynamicFieldValueType.DECIMAL:
      search.numberValueMin = value.min ? value.min : null;
      search.numberValueMax = value.max ? value.max : null;
      break;
  }

  return search;
}

export function getDynamicFieldIdFromFilterKey(input: string): string {
  const id = input.match(/^dynamicField-([0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12})/)?.[1];

  if (!id) {
    throw new Error(`Invalid dynamic field filter key ${input} id extract failed`);
  }

  return id;
}

export function getValueTypeFromDynamicFieldTypeId(typeId: string): DynamicFieldValueType {
  switch (typeId) {
    case DynamicFieldTypeId.DATE: return DynamicFieldValueType.DATE;
    case DynamicFieldTypeId.NUMBER: return DynamicFieldValueType.INTEGER;
    case DynamicFieldTypeId.PICKLIST: return DynamicFieldValueType.PICKLIST;
    case DynamicFieldTypeId.DATETIME: return DynamicFieldValueType.DATETIME;

    case DynamicFieldTypeId.URL:
    case DynamicFieldTypeId.PHONE:
    case DynamicFieldTypeId.EMAIL:
    case DynamicFieldTypeId.RICH_TEXT:
    case DynamicFieldTypeId.MULTI_LINE_TEXT:
    case DynamicFieldTypeId.SINGLE_LINE_TEXT:
      return DynamicFieldValueType.TEXT;

    case DynamicFieldTypeId.DECIMAL:
    case DynamicFieldTypeId.CURRENCY:
    case DynamicFieldTypeId.PERCENTAGE:
      return DynamicFieldValueType.DECIMAL;

  }

  throw new Error(`Unknown dynamic field type id: ${typeId}`);
}

export function getDynamicFieldValue(dynamicField: DynamicFieldValue, field: DynamicField, translate: TranslateService, entityName: DynamicFieldEntityAsString): string | number | Date {
  switch (dynamicField.valueType) {
    case DynamicFieldValueTypeAsString.DATE:
      return dynamicField.dateValue ? moment(dynamicField.dateValue).set({
        hour: 0,
        minute: 0,
        second: 0
      }).toDate() : '';
    case DynamicFieldValueTypeAsString.DATETIME:
      return dynamicField.dateValue ? moment(dynamicField.dateValue).toDate() : '';
    case DynamicFieldValueTypeAsString.DECIMAL:
    case DynamicFieldValueTypeAsString.INTEGER:
      return dynamicField.numberValue;
    case DynamicFieldValueTypeAsString.TEXT:
      return dynamicField.textValue;
    case DynamicFieldValueTypeAsString.PICKLIST:
      return dynamicField.multipleValueFlag ?
        dynamicField.nameValues.map(item => item.name).map(item => translate.instant(`${entityName}_${field.name}.${item}`)).join(', ') :
        translate.instant(`${entityName}_${field.name}.${dynamicField.nameValue.name}`);
  }

}

export function getDynamicFieldValueForCopySummary(
  dynamicField: DynamicFieldValue,
  field: DynamicField,
  translate: TranslateService,
  entityName: DynamicFieldEntityAsString = DynamicFieldEntityAsString.OPPORTUNITY
): string | number | Date {

  switch (dynamicField.valueType) {
    case DynamicFieldValueTypeAsString.DATE:
      return dynamicField.dateValue ? moment(dynamicField.dateValue).set({
        hour: 0,
        minute: 0,
        second: 0
      }).format('DD.MM.YYYY') : '';
    case DynamicFieldValueTypeAsString.DATETIME:
      return dynamicField.dateValue ? moment(dynamicField.dateValue).format('DD.MM.YYYY HH:mm') : '';
    case DynamicFieldValueTypeAsString.DECIMAL:
    case DynamicFieldValueTypeAsString.INTEGER:
      return dynamicField.numberValue;
    case DynamicFieldValueTypeAsString.TEXT:
      return dynamicField.textValue;
    case DynamicFieldValueTypeAsString.PICKLIST:
      return dynamicField.multipleValueFlag ?
        dynamicField.nameValues.map(item => item.name).map(item => translate.instant(`${entityName}_${field.name}.${item.name}`)).join(', ') :
        translate.instant(`${entityName}_${field.name}.${dynamicField.nameValue.name}`);
  }

}
