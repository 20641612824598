import moment from 'moment';
import { ValidatorFn, Validators } from '@angular/forms';
import {
  API_DATE_FORMAT,
  DynamicField,
  DynamicFieldDefaultValueId,
  DynamicFieldPicklist,
  DynamicFieldTypeId,
  DynamicFieldValue
} from '@core/api';
import { InputDynamicFieldValuesValidator } from './input-dynamic-field-values.validator';
import { AppValidators } from '../../../app.validators';
import { PipeTransform } from '@angular/core';

/**
 * Returns validations for dynamic field controller by given field
 */
export function getDynamicFieldValidations(field: DynamicField): ValidatorFn[] {
  const validations = [];

  // Add required validation when field is required
  if (field.requiredFlag && field.conditionVisibility) {
    validations.push(Validators.required);
  }

  // Add validations by type
  switch (field.dynamicFieldTypeId) {
    case DynamicFieldTypeId.PICKLIST:
      validations.push(InputDynamicFieldValuesValidator.picklistValue(field));
      break;
  }

  // Add pattern validator when expression exists
  if (field.dynamicFieldType.validatorExpression) {
    validations.push(Validators.pattern(field.dynamicFieldType.validatorExpression));
  }

  // Add max length validation when field has max length
  const maxLength = field.maxLength ?? field.dynamicFieldType.maxLength ?? null;
  if (maxLength) {
    validations.push(Validators.maxLength(maxLength));
  }

  // Add min value validation when field has min value
  const minValue = field.minValue ?? field.dynamicFieldType.minValue ?? null;
  if (minValue) {
    validations.push(
      isDecimalField(field.dynamicFieldTypeId) ? AppValidators.minDecimal(minValue) : Validators.min(minValue)
    );
  }

  // Add max value validation when field has min value
  const maxValue = field.maxValue ?? field.dynamicFieldType.maxValue ?? null;
  if (maxValue) {
    validations.push(
      isDecimalField(field.dynamicFieldTypeId) ? AppValidators.maxDecimal(maxValue) : Validators.max(maxValue)
    );
  }

  return validations;
}

/**
 * Returns value for dynamic field controller by given field
 */
export function getDynamicFieldControllerValue(mode: 'edit' | 'add', existValue: null | DynamicFieldValue, field: DynamicField) {
  // Decide default value bye dynamic field type
  switch (field.dynamicFieldTypeId) {
    case DynamicFieldTypeId.URL:
    case DynamicFieldTypeId.EMAIL:
    case DynamicFieldTypeId.PHONE:
    case DynamicFieldTypeId.RICH_TEXT:
    case DynamicFieldTypeId.MULTI_LINE_TEXT:
      return existValue ? existValue.textValue : null;
    case DynamicFieldTypeId.SINGLE_LINE_TEXT:
      if (existValue) {
        return existValue.textValue;
      }

      if ('edit' === mode) {
        return null;
      }

      return field.textDefaultValue ?? null;

    case DynamicFieldTypeId.NUMBER:
    case DynamicFieldTypeId.DECIMAL:
    case DynamicFieldTypeId.CURRENCY:
    case DynamicFieldTypeId.PERCENTAGE:
      // Return exist value directly when we have
      if (existValue) {
        return existValue.numberValue;
      }

      // Return null value when mode is edit
      if ('edit' === mode) {
        return null;
      }

      return field.defaultValue ?? null;

    case DynamicFieldTypeId.DATE:
      // Return exist value directly when we have
      if (existValue) {
        return moment(existValue.dateValue);
      }

      // Return null value when mode is edit
      if ('edit' === mode) {
        return null;
      }

      // Decide date default value by the default value id
      switch (field.dynamicFieldDefaultValueId) {
        case DynamicFieldDefaultValueId.DATE_TODAY: return moment();
        case DynamicFieldDefaultValueId.DATE_TOMORROW: return moment().add(1, 'days');
        case DynamicFieldDefaultValueId.DATE_NEXT_DAY: return moment().add(2, 'days');
        case DynamicFieldDefaultValueId.DATE_YESTERDAY: return moment().subtract(1, 'days');
      }

      // Return null when we have no default value
      return null;

    case DynamicFieldTypeId.DATETIME:
      // Return exist value directly when we have
      if (existValue) {
        return moment(existValue.dateValue);
      }

      // Return null value when mode is edit
      if ('edit' === mode) {
        return null;
      }

      if (DynamicFieldDefaultValueId.DATETIME_NOW === field.dynamicFieldDefaultValueId) {
        return moment();
      }

      // Return null when we have no default value
      return null;

    case DynamicFieldTypeId.PICKLIST:
      // Return exist value directly when we have
      if (existValue) {
        return field.multipleValueFlag ? existValue.nameValues.map(item => item.value) : existValue.nameValue.value;
      }

      // Return null value when mode is edit
      if ('edit' === mode) {
        return field.multipleValueFlag ? [] : null;
      }

      if (field.pickListDefaultValues && field.pickListDefaultValues.length > 0) {
        field.selectedDynamicFieldPicklistValues = [
          ...field.pickListDefaultValues.map((value) => {
            return {
              activeFlag: true,
              displayName: '',
              dynamicFieldId: field.dynamicFieldId,
              dynamicFieldPickListId: value.dynamicFieldPickListId,
              name: '',
            } as DynamicFieldPicklist;
          })
        ];
      }

      return field.pickListDefaultValues
        ? field.multipleValueFlag
          ? field.pickListDefaultValues.map(item => item.dynamicFieldPickListId)
          : field.pickListDefaultValues?.[0]?.dynamicFieldPickListId
        : null;

    case DynamicFieldTypeId.SEPARATOR:
      return null;
  }

  throw new Error(`Dynamic field controller value decision is unimplemented for field ${field.displayName} '${field.dynamicFieldTypeId}'`);
}

/**
 * Returns dynamic field value object from given input by field information
 */
export function getDynamicFieldValueFromInputByField(input: any, field: DynamicField): DynamicFieldValue {

  if (!field) {
    return;
  }
  // Create dynamic field value object
  const value: DynamicFieldValue = { dynamicFieldId: field.dynamicFieldId };

  // Decide value property by type
  switch (field.dynamicFieldTypeId) {
    case DynamicFieldTypeId.NUMBER:
      value.numberValue = input;
      break;

    case DynamicFieldTypeId.DATE:
      value.dateValue = (input as moment.Moment).format(API_DATE_FORMAT);
      break;

    case DynamicFieldTypeId.DATETIME:
      value.dateValue = (input as moment.Moment);
      break;

    case DynamicFieldTypeId.PICKLIST:
      if (!field.multipleValueFlag) {
        value.nameValue = { value: input };
      }

      if (field.multipleValueFlag) {
        value.nameValues = input.map(item => ({ value: item }));
      }
      break;

    case DynamicFieldTypeId.DECIMAL:
    case DynamicFieldTypeId.CURRENCY:
    case DynamicFieldTypeId.PERCENTAGE:
      value.numberValue = input;
      break;

    case DynamicFieldTypeId.URL:
    case DynamicFieldTypeId.EMAIL:
    case DynamicFieldTypeId.PHONE:
    case DynamicFieldTypeId.RICH_TEXT:
    case DynamicFieldTypeId.MULTI_LINE_TEXT:
    case DynamicFieldTypeId.SINGLE_LINE_TEXT:
      value.textValue = input;
      break;

    default:
      throw new Error(`Dynamic field value decision is unimplemented for field ${field.displayName} '${field.dynamicFieldTypeId}'`);
  }

  return value;
}
export function isDecimalField(id: string): boolean {
  return [
    DynamicFieldTypeId.DECIMAL,
    DynamicFieldTypeId.CURRENCY,
    DynamicFieldTypeId.PERCENTAGE,
  ].includes(id as DynamicFieldTypeId);
}

export function isStringField(id: string): boolean {
  return [
    DynamicFieldTypeId.SINGLE_LINE_TEXT,
    DynamicFieldTypeId.EMAIL,
    DynamicFieldTypeId.PHONE,
    DynamicFieldTypeId.URL,
    DynamicFieldTypeId.MULTI_LINE_TEXT,
    DynamicFieldTypeId.RICH_TEXT
  ].includes(id as DynamicFieldTypeId);
}

