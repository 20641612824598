import { Subject } from 'rxjs';

import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
    Component, DoCheck, ElementRef, HostBinding, Input, OnDestroy, Optional, Self
} from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Clearance } from '@core/api';
import { getUser } from '@core/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'net-select-clearance',
  templateUrl: './select-clearance.component.html',
  styleUrls: ['./select-clearance.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: SelectClearanceComponent
    }
  ]
})
export class SelectClearanceComponent implements OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<string|null> {

  static nextId = 0;

  loading = false;
  focused = false;
  errorState = false;
  controlType = 'select-clearance';
  describedBy = '';
  stateChanges = new Subject<void>();

  clearances: Clearance[] = [];
  selectCtrl = new UntypedFormControl();

  @Input() tabIndex;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    if (value) {
      this.selectCtrl.disable();
    } else {
      this.selectCtrl.enable();
    }

    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): string | null { return this.selectCtrl.value; }
  set value(value: string | null) {
    this.selectCtrl.setValue(value);
    this.onChange(value);
    this.stateChanges.next();
  }

  @HostBinding('id') id = `select-clearance-${SelectClearanceComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  get shouldLabelFloat() { return this.focused || !this.empty; }

  get empty() { return !this.value; }

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private store: Store,
    private translate: TranslateService,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Material form field implementation
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    // Generate available clearance list by user's clearance
    this.store.select(getUser).pipe(untilDestroyed(this)).subscribe(user => {
      if (user) {
        const clearances = [];
        for (let i = 1; i <= user.clearenceLevel; i++) {
          clearances.push({
            clearanceLevelId: i.toString(),
            name: this.translate.instant('GENERAL.CLEARANCE_LEVEL_VALUE', {value: i})
          });
        }
        this.clearances = clearances;
      }
    });
  }

  onSelectionChange() {
    this.onChange(this.value);
    this.stateChanges.next();
  }

  onChange = (_: any) => {};
  onTouched = () => {};
  onContainerClick = () => {};
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');
  writeValue = (value: string) => this.value = value;
  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }

}
