import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import { Observable } from 'rxjs';

import { PagedResponse, Hashtag, HashtagFilterRequest } from '@core/api';

@Injectable({
    providedIn: 'root'
})
export class HashtagService {
    constructor(private http: HttpClient) { }

    search(request: HashtagFilterRequest): Observable<PagedResponse<Hashtag>> {
        return this.http.post<PagedResponse<Hashtag>>(environment.apiUrl + '/api/Hashtag/Search', request);
    }
}
