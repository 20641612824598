import { Component, Input, OnInit } from '@angular/core';
import { Permission } from '@core/auth/auth.enum';
import { getCustomerExperienceTicketHeader, hasUserPermission } from '@core/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import {
  CustomerExperienceChatMessageTypeStringEnum,
  CustomerExperienceChatModel,
  CustomerExperienceTicketDetailChatHeaderModel
} from '@core/api';
import icChevronDown from '@iconify/icons-mdi/chevron-down';

@UntilDestroy()
@Component({
  selector: 'net-chat-middle',
  templateUrl: './chat-middle.component.html',
  styleUrls: ['./chat-middle.component.scss']
})
export class ChatMiddleComponent implements OnInit {

  @Input() conversation: CustomerExperienceChatModel[] = [];
  @Input() cxChannelId: string;
  @Input() unreadMessagesCount = 0;
  @Input() isPreview = false;

  headerInfo: CustomerExperienceTicketDetailChatHeaderModel;

  permissions = {
    createTask: false,
    createTicket: false,
    splitTicket: false
  };

  icChevronDown = icChevronDown;

  constructor(private store: Store) {
    this.setPermissions();

    this.store.select(getCustomerExperienceTicketHeader).pipe(untilDestroyed(this)).subscribe(headerInfo => {
      this.headerInfo = headerInfo;
    });
  }

  ngOnInit(): void {
  }

  setPermissions() {
    const permissionsToCheck = [
      { permission: Permission.ASSIGNMENT_INSERT, property: 'createTask' },
      { permission: Permission.CUSTOMER_EXPERIENCE_TICKET_INSERT, property: 'createTicket' },
      { permission: Permission.CUSTOMER_EXPERIENCE_TICKET_SPLIT, property: 'splitTicket' }
    ];

    permissionsToCheck.forEach(({ permission, property }) => {
      this.store.select(hasUserPermission(permission)).pipe(untilDestroyed(this))
        .subscribe(response => this.permissions[property] = response);
    });
  }

  isSelf(message: CustomerExperienceChatModel) {
    return message.chatMessageType === CustomerExperienceChatMessageTypeStringEnum.AgentMessage || this.isPrivateNote(message);
  }

  isLog(message: CustomerExperienceChatModel) {
    return message.chatMessageType === CustomerExperienceChatMessageTypeStringEnum.TicketLog;
  }

  isPrivateNote(message: CustomerExperienceChatModel) {
    return message.chatMessageType === CustomerExperienceChatMessageTypeStringEnum.PrivateNote;
  }

}
