import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  ChartResponse,
  DetailResponse,
  FilterRequest,
  MeetingImageRequest,
  MeetingListModel, MeetingStatusResponse,
  PagedResponse,
  ReportRangeType,
  Response,
  UpdateParticipantStatusRequest,
  UpdateStatusRequest
} from '@core/api';
import { environment } from '@env/environment';
import { ActivityTrendReportForm } from '@core/api/report/report.model';

@Injectable({
  providedIn: 'root'
})
export class MeetingService {

  constructor(private http: HttpClient) {
  }

  list(request: FilterRequest): Observable<PagedResponse<MeetingListModel>> {
    return this.http.post<PagedResponse<MeetingListModel>>(environment.apiUrl + '/api/Meeting/List', request);
  }

  search(request: FilterRequest): Observable<PagedResponse<MeetingListModel>> {
    return this.http.post<PagedResponse<MeetingListModel>>(environment.apiUrl + '/api/Meeting/Search', request);
  }

  detail(meetingId: string): Observable<Response<DetailResponse>> {
    return this.http.post<Response<DetailResponse>>(environment.apiUrl + '/api/Meeting/GetMeetingDetail', {meetingId});
  }

  updateParticipantStatus(request: UpdateParticipantStatusRequest, context?: HttpContext): Observable<Response<any>> {
    return this.http.post<PagedResponse<MeetingListModel>>(environment.apiUrl + '/api/MeetingParticipant/Update', request, {context});
  }

  updateStatus(request: UpdateStatusRequest): Observable<Response<any>> {
    return this.http.post<Response<DetailResponse>>(environment.apiUrl + '/api/Meeting/UpdateStatus', request);
  }

  getDoughnutChartData(rangeType: ReportRangeType): Observable<Response<ChartResponse>> {
    return this.http.post<Response<ChartResponse>>(environment.apiUrl + '/api/Meeting/GetDoughnutChartData', rangeType);
  }

  updateImage(request: MeetingImageRequest): Observable<Response<any>> {
    return this.http.post<Response<any>>(environment.apiUrl + '/api/Meeting/UpdateImage', request);
  }

  meetingListFilter(): Observable<Response<ActivityTrendReportForm>> {
    return this.http.post<Response<ActivityTrendReportForm>>(environment.apiUrl + '/api/Meeting/MeetingListFilter ', {});
  }

  meetingStatusSearch(): Observable<PagedResponse<MeetingStatusResponse>> {
    return this.http.post<PagedResponse<MeetingStatusResponse>>(environment.apiUrl + '/api/MeetingStatus/Search ', {});
  }
}
