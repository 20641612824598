import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import { Response, InflationType } from '@core/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InflationTypeService {
  constructor(private http: HttpClient) { }

  list(): Observable<Response<InflationType[]>> {
    return this.http.post<Response<InflationType[]>>(environment.apiUrl + '/api/InflationType/List', {});
  }
}
