import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';

import {
  FilterRequest,
  Response,
  PagedResponse,
  OpportunityStatus,
  OpportunityStatusRequest,
  BasicOpportunity,
  OpportunityStatusNotification
} from '@core/api';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class OpportunityStatusService {
  constructor(private http: HttpClient) { }

  search(request?: FilterRequest): Observable<PagedResponse<OpportunityStatus>> {
    return this.http.post<PagedResponse<OpportunityStatus>>(environment.apiUrl + '/api/OpportunityStatus/Search', request);
  }

  insert(request: OpportunityStatusRequest): Observable<Response<OpportunityStatus>> {
    return this.http.post<Response<OpportunityStatus>>(environment.apiUrl + '/api/OpportunityStatus/Insert', request);
  }

  update(request: OpportunityStatusRequest): Observable<Response<OpportunityStatus>> {
    return this.http.post<Response<OpportunityStatus>>(environment.apiUrl + '/api/OpportunityStatus/Update', request);
  }

  delete(request: OpportunityStatus): Observable<Response<OpportunityStatus>> {
    return this.http.post<Response<OpportunityStatus>>(environment.apiUrl + '/api/OpportunityStatus/Delete', request);
  }

  filterSearch(request?: FilterRequest): Observable<Response<OpportunityStatus[]>> {
    return this.http.post<Response<OpportunityStatus[]>>(environment.apiUrl + '/api/OpportunityStatus/FilterSearch', request);
  }

  basicOpportunities(opportunityId: string): Observable<Response<BasicOpportunity[]>> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<BasicOpportunity[]>>(
      environment.apiUrl + '/api/OpportunityStatus/BasicOpportunities',
      JSON.stringify(opportunityId),
      { headers });
  }

  opportunityStatusNotificationInsert(request: OpportunityStatusNotification) {
    return this.http.post<Response<any>>(environment.apiUrl + '/api/OpportunityStatusNotification/Insert', request);
  }

  opportunityStatusNotificationUpdate(request: OpportunityStatusNotification) {
    return this.http.post<Response<any>>(environment.apiUrl + '/api/OpportunityStatusNotification/Update', request);
  }

  getOpportunityStatusNotification(salesOrganizationId: string, opportunityStatusId: string) {
    return this.http.post<Response<OpportunityStatusNotification>>(
      environment.apiUrl + `/api/OpportunityStatusNotification/Get?salesOrganizationId=${salesOrganizationId}&opportunityStatusId=${opportunityStatusId}`, {});
  }
}
