import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import { FilterRequest, PagedResponse, CarryingFeature } from '@core/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CarryingFeatureService {
  constructor(private http: HttpClient) { }

  search(request: FilterRequest): Observable<PagedResponse<CarryingFeature>> {
    return this.http.post<PagedResponse<CarryingFeature>>(environment.apiUrl + '/api/CarryingFeature/Search', request);
  }
}
