import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

import { FilterRequest, SystemUserInfo } from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class SystemUserInfoService {

  constructor(private http: HttpClient) { }

  list(request?: FilterRequest): Observable<SystemUserInfo[]>  {
    return this.http.post<SystemUserInfo[]>(environment.apiUrl + '/api/SystemUserInfo/List', request);
  }
}
