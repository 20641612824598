import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import { Response, ThermalRegime, ThermalRegimeSearchRequest} from '@core/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThermalRegimeService {
  constructor(private http: HttpClient) { }

  search(request: ThermalRegimeSearchRequest): Observable<Response<ThermalRegime[]>> {
    return this.http.post<Response<ThermalRegime[]>>(environment.apiUrl + '/api/ThermalRegime/Search', request);
  }
}
