import { Action} from '@ngrx/store';

export enum UnsavedChangesActionTypes {
    SET_UNSAVED_CHANGES = '[Unsaved Changes] SET_UNSAVED_CHANGES',
}

export class SetUnsavedChanges implements Action {
    readonly type = UnsavedChangesActionTypes.SET_UNSAVED_CHANGES;
    constructor(public hasUnsavedChanges: boolean) { }
}

export type UnsavedChangesActions = SetUnsavedChanges;
