import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import { Response, PaymentTerm, PaymentTermFilterRequest } from '@core/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentTermService {
  constructor(private http: HttpClient) { }

  search(request: PaymentTermFilterRequest): Observable<Response<PaymentTerm[]>> {
    return this.http.post<Response<PaymentTerm[]>>(environment.apiUrl + '/api/PaymentTerm/Search', request);
  }
}
