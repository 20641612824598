import { Initial, Response } from '@core/api';
import { Action } from '@ngrx/store';

export enum SystemSettingActionTypes {
    GET_SYSTEM_SETTING = '[System Setting] GET_SYSTEM_SETTING',
    GET_SYSTEM_SETTING_SUCCESS = '[System Setting] GET SYSTEM_SETTING_SUCCESS',
    GET_SYSTEM_SETTING_ERROR = '[System Setting] GET_SYSTEM_SETTING_ERROR',
    SET_SYSTEM_SETTING = '[System Setting] SET_SYSTEM_SETTING'
}

export class GetSystemSetting implements Action {
    readonly type = SystemSettingActionTypes.GET_SYSTEM_SETTING;
}

export class GetSystemSettingSuccess implements Action {
    readonly type = SystemSettingActionTypes.GET_SYSTEM_SETTING_SUCCESS;
    constructor(public payload: Initial) { }
}

export class GetSystemSettingError implements Action {
    readonly type = SystemSettingActionTypes.GET_SYSTEM_SETTING_ERROR;
    constructor(public payload: Response<null>) { }
}

export class SetSystemSetting implements Action {
    readonly type = SystemSettingActionTypes.SET_SYSTEM_SETTING;
    constructor(public payload: { key: string; value: any }) { }
}

export type SystemSettingActions =
    GetSystemSetting |
    GetSystemSettingSuccess |
    GetSystemSettingError |
    SetSystemSetting;
