import { Action } from '@ngrx/store';

import { LeadDraft, SalesOrganization, Response, LeadDraftInfo } from '@core/api';

export enum LeadDraftActionTypes {
    ADD_SALES_ORGANIZATION_AS_UNPICKED = '[Lead Draft] Add Lead Draft Sales Organization As Unpicked',
    FILTER_LEAD_DRAFT_SALES_ORGANIZATIONS = '[Lead Draft] Filter Lead Draft Sales Organizations',
    FILTER_LEAD_DRAFT_SALES_ORGANIZATIONS_SUCCESS = '[Lead Draft] Filter Lead Draft Sales Organizations Success',
    FILTER_LEAD_DRAFT_SALES_ORGANIZATIONS_ERROR = '[Lead Draft] Filter Lead Draft Sales Organizations Error'
}

export class AddSalesOrganizationAsUnpicked implements Action {
    readonly type = LeadDraftActionTypes.ADD_SALES_ORGANIZATION_AS_UNPICKED;

    constructor(public payload: LeadDraftInfo) { }
}

export class FilterLeadDraftSalesOrganizations implements Action {
    readonly type = LeadDraftActionTypes.FILTER_LEAD_DRAFT_SALES_ORGANIZATIONS;

    constructor(public payload?: LeadDraft) { }
}

export class FilterLeadDraftSalesOrganizationsSuccess implements Action {
    readonly type = LeadDraftActionTypes.FILTER_LEAD_DRAFT_SALES_ORGANIZATIONS_SUCCESS;

    constructor(public payload: SalesOrganization[]) { }
}

export class FilterLeadDraftSalesOrganizationsError implements Action {
    readonly type = LeadDraftActionTypes.FILTER_LEAD_DRAFT_SALES_ORGANIZATIONS_ERROR;

    constructor(public payload: Response<null>) { }
}

export type LeadDraftActions =
    AddSalesOrganizationAsUnpicked |
    FilterLeadDraftSalesOrganizations |
    FilterLeadDraftSalesOrganizationsSuccess |
    FilterLeadDraftSalesOrganizationsError;
