import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BoardDetailReportState } from '@core/store';

export const boardDetailReportState = createFeatureSelector('boardDetailReport');

export const getBoardDetailReport = createSelector(
    boardDetailReportState,
    (state: BoardDetailReportState) => state
);

export const getBoardDetailReportBoard = createSelector(
    boardDetailReportState,
    (state: BoardDetailReportState) => state.board
);

export const getBoardDetailReportBoardInfos = createSelector(
    boardDetailReportState,
    (state: BoardDetailReportState) => state.boardInfos
);
