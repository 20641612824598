import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

import { CardLinkSearchRequest, PagedResponse, CardLink, Response, CardLinkRequest } from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class CardLinkService {
  constructor(private http: HttpClient) { }

  search(request: CardLinkSearchRequest): Observable<PagedResponse<CardLink>> {
    return this.http.post<PagedResponse<CardLink>>(environment.apiUrl + '/api/CardLink/Search', request);
  }

  get(uuid: string): Observable<Response<CardLink>> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<CardLink>>(environment.apiUrl + '/api/CardLink/Get', JSON.stringify(uuid), { headers });
  }

  insert(request: CardLinkRequest): Observable<Response<CardLink>> {
    return this.http.post<Response<CardLink>>(environment.apiUrl + '/api/CardLink/Insert', request);
  }

  update(request: CardLinkRequest): Observable<Response<CardLink>> {
    return this.http.post<Response<CardLink>>(environment.apiUrl + '/api/CardLink/Update', request);
  }

  delete(request: CardLink): Observable<Response<CardLink>> {
    return this.http.post<Response<CardLink>>(environment.apiUrl + '/api/CardLink/Delete', request);
  }
}
