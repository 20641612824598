import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatIconRegistry } from '@angular/material/icon';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { DomSanitizer } from '@angular/platform-browser';

import { normalize, SVG } from '../../utils/svg';

import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { MatProgressBarIcon } from './progress-button.component.model';

@Component({
  selector: 'net-progress-button',
  templateUrl: './progress-button.component.html',
  styleUrls: ['./progress-button.component.scss']
})
export class ProgressButtonComponent implements OnInit {
  @Input() isDisabled = false;
  options: MatProgressButtonOptions = {
    text: null,
    active: false,
    buttonIcon: this.icon,
  };
  @Input() block = false;
  @Input() raised = true;
  @Input() stroked = false;
  @Input() type = 'submit';
  @Input() mode: ProgressSpinnerMode = 'indeterminate';
  @Input() color: ThemePalette = 'primary';
  @Input() spinner = 18;
  @Output() btnClick = new EventEmitter<MouseEvent>();
  @ViewChild('contentRef', { static: true }) contentRef: ElementRef<HTMLDivElement>;

  constructor(private domSanitizer: DomSanitizer, private iconRegistry: MatIconRegistry) {
  }

  private _customClass;

  @Input()
  get customClass(): string {
    return this._customClass;
  }

  set customClass(value: string) {
    this._customClass = value;
    if (value) {
      this.options = { ...this.options, customClass: value };
    }
  }

  private _text;

  @Input()
  get text(): any {
    return this._text;
  }

  set text(value: any) {
    this._text = value;
    if (value) {
      this.options.text = value;
    }
  }

  private _disabled = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = value;

    this.options = { ...this.options, disabled: value };
  }

  get active() {
    return this.options.active;
  }

  @Input()
  set active(value: boolean) {
    this.options = { ...this.options, active: value };
  }

  private _icon: MatProgressBarIcon;

  get icon() {
    return this._icon;
  }

  @Input()
  set icon(value: object) {
    // Replicate iconify svg process steps and add literal svg to mat icon registry
    const name = 'net-progress-icon-' + Math.random();
    const svg = new SVG(normalize(value)).getSVG({
      width: '1em',
      height: '1em',
      hFlip: false,
      vFlip: false,
      flip: void 0,
      rotate: void 0,
      color: void 0,
      align: void 0,
      inline: false,
      box: true
    });

    this.iconRegistry.addSvgIconLiteral(name, this.domSanitizer.bypassSecurityTrustHtml(svg));

    this._icon = {
      inline: false,
      svgIcon: name
    };
  }

  ngOnInit() {
    this.options = {
      mode: this.mode,
      text: this.text,
      type: this.type,
      active: this.active,
      raised: this.raised,
      disabled: this.disabled,
      fullWidth: this.block,
      buttonColor: this.color,
      spinnerSize: this.spinner,
      buttonIcon: this.icon,
      stroked: this.stroked,
      customClass: this.customClass
    };

    if (this.contentRef.nativeElement.innerText) {
      this.options.text = this.contentRef.nativeElement.innerText;
    }
  }

  onBtnClick(event) {
    this.btnClick.emit(event);
  }

}
