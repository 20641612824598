<mat-select
  [tabIndex]="tabIndex"
  [formControl]="selectCtrl"
  [compareWith]="compareWith"
  (selectionChange)="onSelectionChange()"
  *ngIf="!showNotFound">
  <mat-option
    class="accordion-option"
    [value]="board"
    [class.has-description]="board.description"
    *ngFor="let board of boards">
    <div>{{ board.name }} </div>
    <div class="description" *ngIf="board.description">{{ board.description }}</div>
  </mat-option>
</mat-select>

<input
  matInput
  readonly
  type="text"
  value="{{ 'GENERAL.NO_RESULT_FOUND' | translate }}"
  *ngIf="showNotFound">

<mat-progress-spinner *ngIf="loading === true" diameter="16" mode="indeterminate"></mat-progress-spinner>
