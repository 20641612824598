import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

import { Response, OpportunityPriceList, OpportunityPriceListRequest } from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class OpportunityPriceListService {

  constructor(private http: HttpClient) { }

  insert(request: OpportunityPriceListRequest): Observable<Response<OpportunityPriceList>> {
    return this.http.post<Response<OpportunityPriceList>>(environment.apiUrl + '/api/OpportunityPriceList/Insert', request);
  }

  update(request: OpportunityPriceListRequest): Observable<Response<OpportunityPriceList>> {
    return this.http.post<Response<OpportunityPriceList>>(environment.apiUrl + '/api/OpportunityPriceList/Update', request);
  }

  get(uuid: string): Observable<Response<OpportunityPriceList>> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<OpportunityPriceList>>(environment.apiUrl + '/api/OpportunityPriceList/Get', JSON.stringify(uuid), { headers });
  }

  sendForApproval(uuid: string): Observable<Response<OpportunityPriceList>> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<OpportunityPriceList>>(environment.apiUrl + '/api/OpportunityPriceList/SendForApproval', JSON.stringify(uuid), { headers });
  }

  sendForApprovalFromBusinessDevelopment(request: any): Observable<Response<OpportunityPriceList>> {
    return this.http.post<Response<OpportunityPriceList>>(environment.apiUrl + '/api/OpportunityPriceList/SendForApprovalFromBusinessDevelopment', request);
  }
}
