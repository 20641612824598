export enum OpportunityCopySummarySettings {
    CUSTOMER = '940fb9d5-79d8-42e3-b500-c7d6a3a9b362',
    OPPORTUNITY_NAME = 'f123fd36-940e-4ee9-8fe6-1c5c53df5f8e',
    CONTACTS = 'a17e3aa6-e3bb-4d1a-8e95-001eb122cc07',
    ORGANIZATION = '942dc68f-f4fb-4849-be61-5c823490b553',
    OPPORTUNITY_TYPE = 'f50896ef-ce55-4b80-8198-1cdf718b4bb2',
    CURRENCY = '35ada042-172b-408f-8af0-894ad7746c3f',
    PROBABILITY = '5ea2971b-1557-4c7c-961a-fcf961a679ea',
    CLOSE_DATE = '26afe386-1012-40cb-baea-ebcf4b10f269',
    CREATION_DATE = '58dc476f-8ff1-4771-8e8a-825beb41b4c9',
    SERVICE_SERVICE_NAME = '39084102-81a2-4509-a416-757b6c953b52',
    SERVICE_DETAIL = 'd37228a2-7153-4f55-81f7-b93480aa09d2',
    SERVICE_REVENUE_TYPE = 'd00beabe-a247-46f8-ad1b-6c7569f83780',
    SERVICE_UNIT = '48f7acf3-3297-40fc-9360-3df6f06059ed',
    SERVICE_UNIT_PRICE = '0a8d148a-56c8-4066-83fc-3317fc1d264e',
    SERVICE_AMOUNT = '0f818423-8c9a-44f8-ade5-e47d71ef3f02',
    SERVICE_REVENUE = '79602e6d-f4ba-45cc-8d86-3f5f6a264657',
    SERVICE_TOTAL = '23628711-fdef-446a-a563-e99bee47dcbf',
    DESCRIPTION = 'cf6b8189-4664-4f1f-b5e7-7c24a7316432'
}
