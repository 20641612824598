import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { GetRecentPageError, GetRecentPageSuccess, RecentPageActionTypes } from './recent-page.action';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { RecentPage } from 'src/app/layout/toolbar/recent-pages-dropdown/recent-pages.model';
import { Response } from '@core/api';
import { RecentPageService } from 'src/app/layout/toolbar/recent-pages-dropdown/recent-page.service';
@Injectable()
export class RecentPageEffects {
    constructor(
        private recentPageService: RecentPageService,
        private actions$: Actions
    ) {}

    recentPages$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RecentPageActionTypes.GET_RECENT_PAGE),
            switchMap(() => this.recentPageService.getRecentPages()),
            map((pages: RecentPage[]) => new GetRecentPageSuccess(pages)),
            catchError((error: Response<null>) => of( new GetRecentPageError(error)))
        )
    );
}
