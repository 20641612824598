import { debounceTime, finalize, map } from 'rxjs/operators';

import { ApiDataSource } from '@core/api/api.data-source';
import { DynamicField } from '@core/api/dynamic-field/dynamic-field.model';
import { DynamicFieldFilter, DynamicFieldFilterRequest, DynamicFieldService, DynamicFieldTypeId, OrderType } from '@core/api';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';

export class DynamicFieldDataSource extends ApiDataSource<DynamicField> {


  public $status = of([
    { key: false, value: 'Passive' },
    { key: true, value: 'Active' }
  ]);

  public $boards = this.dynamicField.listBoards().pipe(
    map(response => response.data),
    map(boardList => boardList.map(item => {
      return {
        key: item.boardId,
        value: item.name
      };
    }))
  );

  public $dynamicFieldTypes = this.dynamicField.types().pipe(map(response => {
    const excludingTypes = [
      DynamicFieldTypeId.MULTI_LINE_TEXT,
      DynamicFieldTypeId.DATETIME,
      DynamicFieldTypeId.RICH_TEXT,
      DynamicFieldTypeId.SEPARATOR
    ];
    return this.arrayFlag ? response.data.filter(type => !excludingTypes.some(d => d === type.dynamicFieldTypeId)) : response.data;
  }), map(types => {
    return types.map(type => {
      return {
        key: type.dynamicFieldTypeId,
        value: type.name
      };
    });
  }));

  orderBy = 'orderBy';
  orderType = 'ASC' as OrderType;

  public filterReq: any = {};

  constructor(
    public ready: boolean,
    private translate: TranslateService,
    private dynamicField: DynamicFieldService,
    public initialFilter?: DynamicFieldFilter,
    public arrayFlag?: boolean) {
    super(initialFilter);
  }

  getFilter() {
    return this.filterReq;
  }

  load(): void {
    // Load dynamic fields when ready
    if (!this.ready) {
      return;
    }

    // Update loading state
    this.loadingSubject.next(true);

    // Create request parameters
    const request: DynamicFieldFilterRequest = {
      filter: { ...this.initialFilter, ...this.filter },
      ...this.getRequest()
    };

    if (!request.orderBy) {
      this.orderBy = 'orderBy';
      this.orderType = 'ASC' as OrderType;
      request.orderBy = this.orderBy;
      request.orderType = this.orderType;
    }

    // If filter keyword exists, filter data
    if (this.keyword) {
      request.filter.searchText = this.keyword;
    }

    if (this.paginator?.pageSize) {
      request.pageSize = this.paginator.pageSize;
    }

    this.filterReq = {...request};

    // Fetch data
    this.dynamicField.search(request).pipe(
      debounceTime(400),
      finalize(() => this.loadingSubject.next(false))
    ).subscribe(response => {
      // Update count and data subjects
      this.dataSubject.next(response.data.results);
      this.dataCountSubject.next(response.data.rowCount);

      // Update data source's empty based row count
      this.empty = response.data.rowCount === 0;
    });
  }
}
