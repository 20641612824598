import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountDocumentService {
  constructor(private http: HttpClient) {
  }

  list(request: any) {
    const headers = new HttpHeaders({'content-type': 'application/json'});
    return this.http.post<any>(environment.apiUrl + '/api/AccountDocument/List', JSON.stringify(request), {headers});
  }

  additionalDocumentsList(request: any) {
    const headers = new HttpHeaders({'content-type': 'application/json'});
    return this.http.post<any>(environment.apiUrl + '/api/AccountDocument/AdditionalDocumentList', JSON.stringify(request), {headers});
  }

  delete(request: any) {
    const headers = new HttpHeaders({'content-type': 'application/json'});
    return this.http.post<any>(environment.apiUrl + '/api/AccountDocument/Delete', JSON.stringify(request), {headers});
  }

}
