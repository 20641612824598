import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

import {
  Response,
  OpportunityContractDocument,
  OpportunityContractDocumentRequest,
  OpportunityContractDocumentSearchRequest,
  PagedResponse,
  SetAsContractRequest,
  ActiveContractDocument
} from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class OpportunityContractDocumentService {

  constructor(private http: HttpClient) { }

  insert(request: OpportunityContractDocumentRequest): Observable<Response<OpportunityContractDocument>> {
    return this.http.post<Response<OpportunityContractDocument>>(environment.apiUrl + '/api/OpportunityContractDocument/Insert', request);
  }

  update(request: OpportunityContractDocumentRequest): Observable<Response<OpportunityContractDocument>> {
    return this.http.post<Response<OpportunityContractDocument>>(environment.apiUrl + '/api/OpportunityContractDocument/Update', request);
  }

  search(request: OpportunityContractDocumentSearchRequest): Observable<PagedResponse<OpportunityContractDocument>> {
    return this.http.post<PagedResponse<OpportunityContractDocument>>(environment.apiUrl + '/api/OpportunityContractDocument/Search', request);
  }

  setAsContract(request: SetAsContractRequest): Observable<Response<OpportunityContractDocument>> {
    return this.http.post<Response<OpportunityContractDocument>>(environment.apiUrl + '/api/OpportunityContractDocument/SetAsContract', request);
  }

  unsetAsContract(request: SetAsContractRequest): Observable<Response<OpportunityContractDocument>> {
    return this.http.post<Response<OpportunityContractDocument>>(environment.apiUrl + '/api/OpportunityContractDocument/UnsetAsContract', request);
  }

  delete(request: OpportunityContractDocument): Observable<Response<OpportunityContractDocument>> {
    return this.http.post<Response<OpportunityContractDocument>>(environment.apiUrl + '/api/OpportunityContractDocument/Delete', request);
  }

  getActiveContractDocuments(request: {accountId: string}): Observable<Response<ActiveContractDocument[]>> {
    return this.http.post<Response<ActiveContractDocument[]>>(environment.apiUrl + '/api/OpportunityContractDocument/GetActiveContractDocuments', request);
  }
}
