import moment from 'moment';
import { Subject } from 'rxjs';

import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, NgControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldControl } from '@angular/material/form-field';
import {
  Account,
  AssignmentRequest,
  AssignmentService,
  AssignmentStatusOptions,
  AssignmentStatusService,
  DynamicFieldEntityAsString,
  DynamicFieldEntityId,
  LeadDraftInfo,
  User
} from '@core/api';
import { getUser } from '@core/store';
import icRemove from '@iconify/icons-fa-solid/trash-alt';
import icAdd from '@iconify/icons-ic/twotone-add';
import icCheckCircle from '@iconify/icons-ic/twotone-check-circle-outline';
import icMinus from '@iconify/icons-ic/twotone-minus';
import icSave from '@iconify/icons-ic/twotone-save';
import icEdit from '@iconify/icons-mdi/square-edit-outline';
import icArrowRight from '@iconify/icons-mdi/subdirectory-arrow-right';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { fadeInUp400ms } from '../../../shared/animations/fade-in-up.animation';
import { InputActivityAssignment } from './input-activity-assignment.component.model';
import { distinctUntilChanged } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'net-input-activity-assignment',
  templateUrl: './input-activity-assignment.component.html',
  styleUrls: ['./input-activity-assignment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: InputActivityAssignmentComponent
    }
  ],
  animations: [
    fadeInUp400ms
  ]
})
export class InputActivityAssignmentComponent implements OnInit, OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<InputActivityAssignment[] | null> {
  static nextId = 0;

  loading = false;
  focused = false;
  errorState = false;
  controlType = 'input-activity-assignment';
  describedBy = '';
  stateChanges = new Subject<void>();

  form = new UntypedFormGroup({
    activityAssignment: new UntypedFormArray([this.createActivityAssignmentForm()])
  });

  activeIndex: number;
  assignmentLoading = false;
  updateValidations = false;
  DynamicFieldEntityId = DynamicFieldEntityId;
  DynamicFieldEntityAsString = DynamicFieldEntityAsString;
  assingmentStatus = AssignmentStatusOptions;

  today = moment();

  icAdd = icAdd;
  icMinus = icMinus;
  icEdit = icEdit;
  icSave = icSave;
  icArrowRight = icArrowRight;
  icCheckCircle = icCheckCircle;
  icRemove = icRemove;

  @Input() isDisabled = false;
  @HostBinding('id') id = `input-activity-assignment-${InputActivityAssignmentComponent.nextId++}`;
  @HostBinding('attr.tabindex') tabIndex = -1;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;
  @Output() update = new EventEmitter<InputActivityAssignment[]>();
  @ViewChild('assignmentDetailDialog', { static: true }) assignmentDetailDialog: TemplateRef<HTMLElement>;
  dialogRef: MatDialogRef<any>;
  assignmentDetailForm = this.formBuilder.group({
    explanation: [null, Validators.required]
  });
  activeUser: User;
  assignmentStatuses: { key: string; value: string; backgroundColor: string; }[] = [];

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private formBuilder: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private store: Store,
    private translate: TranslateService,
    private assignmentService: AssignmentService,
    private assigmentStatusService: AssignmentStatusService,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Material form field implementation
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    this.store.select(getUser).pipe(untilDestroyed(this)).subscribe(user => this.activeUser = user);
  }


  @Input() leadDraftInfo: LeadDraftInfo;

  private _account: Account;

  @Input()
  get account(): Account {
    return this._account;
  }

  set account(value: Account) {
    this._account = value;
    this.stateChanges.next();
  }

  private _placeholder: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  private _required = false;

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private _value: InputActivityAssignment[];

  @Input()
  get value(): InputActivityAssignment[] | null {
    return this._value;
  }

  set value(activityAssignment: InputActivityAssignment[] | null) {
    this._value = activityAssignment;
    this.onChange(activityAssignment);
    this.stateChanges.next();
  }

  private _disabled = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    if (value) {
      this.form.disable();
    } else {
      this.form.enable();
    }
    this._disabled = value;
    this.stateChanges.next();
  }

  get shouldLabelFloat() {
    return true;
  }

  get empty() {
    return !this.value || this.value.length === 0;
  }

  ngOnInit() {
    this.getAssignmentStatuses();

    // Watch form value changes and update
    this.form.valueChanges.pipe(
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    ).subscribe(() => {
      const activityAssignment: InputActivityAssignment[] = this.getActivityAssignmentForm().getRawValue().filter(item => this.isExist(item));

      this.value = activityAssignment;
      this.update.emit(this.value);
    });

    // Trigger detection change on init
    this.changeDetectorRef.detectChanges();
  }

  getAssignmentStatuses() {
    this.assigmentStatusService.list({ filter: {} }).subscribe(res => {
      this.assignmentStatuses = res.data.map(status => ({
        key: status.assignmentStatusId,
        value: this.translate.instant('AssignmentStatus.' + status.name),
        backgroundColor: status.backgroundColor
      }));

      this.changeDetectorRef.detectChanges();
    });
  }

  getStatusColor(value: InputActivityAssignment) {
    if (value.assignmentStatusId) {
      return this.assignmentStatuses?.find(status => status.key === value.assignmentStatusId)?.backgroundColor;
    } else {
      return this.assignmentStatuses?.find(status => status.key === value.assignmentStatus?.assignmentStatusId)?.backgroundColor;
    }
  }

  checkStatusVisibility(key: string, index: number, control: any) {
    if (this.isDisabled) {
      return true;
    }

    switch (key) {
      case this.assingmentStatus.COMPLETE:
        return this.value && this.value.length && this.value[index].assignedUser && this.activeUser.userId === this.value[index].assignedUser.systemUserId
          || (this.activeUser.userId === this.value[index].assignedUser.systemUserId && this.activeUser.userId === control.value.actionUser.systemUserId);
      case this.assingmentStatus.REJECTED:
        return this.value && this.value.length && this.value[index].assignedUser && this.activeUser.userId === this.value[index].assignedUser.systemUserId;
      case this.assingmentStatus.CANCEL:
        return this.value && this.value.length && this.value[index].assignedUser && this.activeUser.userId === control.value.createdBy;
      case this.assingmentStatus.SERIES_CANCELLED:
        return false;
      default:
        return true;
    }
  }
  openDialog(control, index: number) {
    if (control.value.assignmentStatusId !== this.assingmentStatus.ASSIGNED) {
      this.activeIndex = index;
      // Reset form
      this.assignmentDetailForm.reset();
      if (control.value.explanation) {
        this.assignmentDetailForm.patchValue({ explanation: control.value.explanation });
      }

      // Open dialog by selected status
      this.dialogRef = this.dialog.open(this.assignmentDetailDialog, { autoFocus: false });
    }
  }

  updateAssignment(index: number) {
    this.assignmentLoading = true;

    const form = this.getActivityAssignmentForm();
    const value: InputActivityAssignment = form.getRawValue()[index];

    const request: AssignmentRequest = {
      assignmentId: value.assignmentId,
      description: value.description,
      assignedUserId: value.assignedUser.systemUserId,
      dueDate: value.dueDate,
      assignmentStatusId: value.assignmentStatusId,
      explanation: this.assignmentDetailForm.value.explanation,
      actionUserId: this.activeUser.userId,
      actionDate: new Date().toISOString(),
      salesOrganizationId: value.salesOrganizationId,
      assignmentPriorityId: value.assignmentPriorityId,
      dynamicFieldValues: value.dynamicFieldValues
    };

    this.assignmentService.update(request).subscribe(response => {
      if (response.success) {
        form.controls[index].patchValue({
          explanation: response.data.explanation,
          assignmentStatus: response.data.assignmentStatus
        });

        this.changeDetectorRef.detectChanges();
        this.dialogRef.close();
      } else {
        this.closeDialog();
      }

      this.assignmentLoading = false;
    }, () => this.closeDialog());
  }

  closeDialog() {
    const form = this.getActivityAssignmentForm();
    const appointed = {
      assignmentStatusId: AssignmentStatusOptions.ASSIGNED,
      name: this.translate.instant('TASK.ASSIGNED')
    };

    form.controls[this.activeIndex].patchValue({
      assignmentStatus: appointed,
      assignmentStatusId: appointed.assignmentStatusId
    });

    this.changeDetectorRef.detectChanges();
    this.dialogRef.close();
  }

  onSubmitDetail() {
    // Touch all form fields and trigger validate
    this.assignmentDetailForm.markAllAsTouched();

    // If form is valid, send the request
    if (this.assignmentDetailForm.valid) {
      this.updateAssignment(this.activeIndex);
    }
  }

  getActivityAssignmentForm(): UntypedFormArray {
    return this.form.get('activityAssignment') as UntypedFormArray;
  }

  createActivityAssignmentForm(item?: InputActivityAssignment): UntypedFormGroup {

    const newForm = this.formBuilder.group({
      description: [{
        value: item ? item.description : null,
        disabled: item ? true : false
      }, Validators.required],
      dueDate: [{
        value: item ? item.dueDate : null,
        disabled: item ? true : false
      }, Validators.required],
      assignedUser: [{
        value: item ? item.assignedUser : null,
        disabled: item ? true : false
      }, [Validators.required]],
      assignmentStatusId: [item ? item.assignmentStatusId : AssignmentStatusOptions.ASSIGNED, [Validators.required]],
      assignmentStatus: [item ? item.assignmentStatus : null],
      explanation: [item ? item.explanation : null],
      actionDate: [item ? item.actionDate : null],
      actionUser: [item ? item.actionUser : null],
      id: [item ? item.id : null],
      assignmentId: [item ? item.assignmentId : null],
      salesOrganizationId: [item ? item.salesOrganizationId : null],
      assignmentPriorityId: [item ? item.assignmentPriorityId : null],
      dynamicFieldValues: [item ? item.dynamicFieldValues : []],
      updateValidations: [false],
      createdBy: [item ? item.createdBy : null]
    });
    if (!item) {
      this.listenAddedItem(newForm);
    }
    if (this.isReadOnly(item?.assignmentStatusId)) {
      newForm.get('assignmentStatusId').disable();
    }
    return newForm;
  }

  isReadOnly(assignmentStatusId: string) {
    const readOnly = [AssignmentStatusOptions.CANCEL, AssignmentStatusOptions.COMPLETE,
    AssignmentStatusOptions.REJECTED, AssignmentStatusOptions.SERIES_CANCELLED];
    return readOnly.includes(assignmentStatusId as AssignmentStatusOptions);
  }

  listenAddedItem(form: UntypedFormGroup) {
    form.get('description').valueChanges.pipe(untilDestroyed(this)).subscribe(description => {
      if (description) {
        form.markAllAsTouched();
        form.get('updateValidations').patchValue(true);
        form.get('dueDate').setValidators(Validators.required);
        form.get('assignedUser').setValidators(Validators.required);
      } else {
        form.get('updateValidations').patchValue(false);
        form.get('dueDate').clearValidators();
        form.get('assignedUser').clearValidators();
      }

      form.get('dueDate').updateValueAndValidity();
      form.get('assignedUser').updateValueAndValidity();
    });
  }

  add(input?: InputActivityAssignment) {
    this.getActivityAssignmentForm().push(this.createActivityAssignmentForm(input));
    this.changeDetectorRef.detectChanges();
  }

  remove(index: number) {
    const form = this.getActivityAssignmentForm();

    if (form.length > 0) {
      form.removeAt(index);
    }

    if (form.length === 0) {
      this.getActivityAssignmentForm().push(this.createActivityAssignmentForm());
    }

    this.changeDetectorRef.detectChanges();
  }

  onClickAdd() {
    if (this.disabled) {
      return;
    }

    this.add();
  }

  onClickRemove(index: number) {
    if (this.disabled) {
      return;
    }

    this.remove(index);
  }

  removeExplation(control) {
    control.patchValue({ explanation: null });
    this.changeDetectorRef.detectChanges();
  }

  writeValue(activityAssignment: InputActivityAssignment[]) {
    if (activityAssignment && activityAssignment.length > 0) {
      this.value = activityAssignment;

      this.getActivityAssignmentForm().clear();
      activityAssignment.forEach(item => {
        this.add(item);
      });
    }
  }

  onChange = (_: any) => {
  }

  onTouched = () => {
  }

  onContainerClick = () => {
  }

  registerOnChange = (fn: any) => this.onChange = fn;

  registerOnTouched = (fn: any) => this.onTouched = fn;

  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');

  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }

  private isExist(item: InputActivityAssignment) {
    return item;
  }
}
