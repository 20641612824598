import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpEvent, HttpHeaders, HttpResponse } from '@angular/common/http';

import { map, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';

import { environment } from '@env/environment';
import {
  Mail,
  Response,
  MailFolder,
  MailList,
  MailLink,
  LoginInfo,
  BaseResponse,
  UploadedFile,
  PagedResponse,
  MailListRequest,
  MailInfoRequest,
  MailSendRequest,
  MailInfoResponse,
  MailSendResponse,
  GetMessageRequest,
  MailSignalRService,
  DownloadFileRequest,
  MailIntegrationType,
  MailLinkInsertRequest,
  MailLinkSearchRequest,
  MessageMarkAsReadUnreadRequest,
  MessageMarkAsReadUnreadResponse
} from '@core/api';

import { fileToBase64 } from 'src/app/shared/utils/file';
import { djb2HashCode } from 'src/app/shared/utils/djb-hash';
import { removeSessionStorageWithRegex } from 'src/app/shared/utils/storage-helper';
import { NotificationSignalRService } from 'src/app/notification/signal-r/notification-signal-r.service';

@Injectable({
  providedIn: 'root'
})
export class MailService {
  private linkChangeSubject = new ReplaySubject<{ type: 'add' | 'remove', mailLinkId: string, link?: MailLink }>();
  linkChange$ = this.linkChangeSubject.asObservable();

  constructor(private http: HttpClient) { }

  private loginInfoSubject = new BehaviorSubject<LoginInfo>(null);
  loginInfo$ = this.loginInfoSubject.asObservable();

  link = {
    insert: (request: MailLinkInsertRequest, notify = true): Observable<Response<MailLink>> => {
      // Append token connection id
      request.tokenConnectionId = MailSignalRService.tokenConnectionId;

      // Append mail integration type
      request.mailIntegrationType = MailService.getIntegrationType();

      return this.http.post<Response<MailLink>>(`${environment.apiUrl}/api/MailLink/Insert`, request)
        .pipe(
          tap(response => {
            if (!response.success) {
              return;
            }

            // Remove mail links cache
            removeSessionStorageWithRegex(/^mail:links:/);

            // Notify subject when link change
            if (notify) {
              this.linkChangeSubject.next({ type: 'add', mailLinkId: response.data.mailLinkId, link: response.data });
            }
          })
        );
    },
    search: (request: MailLinkSearchRequest, isCache = true, includeAttachmentId = false): Observable<PagedResponse<MailLink>> => {
      // Generate cache key and get cached data
      const key = `mail:links:${djb2HashCode(JSON.stringify(request))}`;
      const time = sessionStorage.getItem(`${key}:time`);
      const cache = sessionStorage.getItem(key);

      // Return cached data if exits and not expired, cache time is 300 seconds
      if (isCache && cache && time && (new Date().getTime() - parseInt(time, 10)) < 300 * 1000) {
        return of(JSON.parse(cache));
      }

      return this.http.post<PagedResponse<MailLink>>(`${environment.apiUrl}/api/MailLinkMailLinkType/Search`, request)
        .pipe(
          map((response) => {
            if (!response.success) {
              return response;
            }

            response.data.results = response.data.results.map((item: any) => ({
              ...item.mailLink,
              card: item.card,
              cardId: item.cardId,
              contactId: item.contactId,
              accountId: item.accountId,
              activityId: item.activityId,
              leadDraftId: item.leadDraftId,
              assignmentId: item.assignmentId,
              opportunityId: item.opportunityId,
              salesOrganizationId: item.salesOrganizationId,
              mailLinkTypeId: item.mailLinkTypeId,
              files: item.attachmentFileScanStatus?.map((scan) => ({
                ...includeAttachmentId ? { attachmentId: scan.attachmentId } : {},
                size: scan?.attachment?.fileSize,
                type: 'application/unknown',
                fileName: [scan?.attachment?.fileName, scan?.attachment?.extension.toLowerCase()].join(''),
              })),
            }));

            return response;
          }),
          tap((response) => {
            if (!response.success) {
              return;
            }

            sessionStorage.setItem(key, JSON.stringify(response));
            sessionStorage.setItem(`${key}:time`, new Date().getTime().toString());
          })
        );
    },
    delete: (mailLinkId: string, notify = true): Observable<BaseResponse> => {
      const headers = new HttpHeaders({ 'content-type': 'application/json' });
      return this.http.post<BaseResponse>(`${environment.apiUrl}/api/MailLink/Delete`, JSON.stringify(mailLinkId), { headers }).pipe(
        tap(response => {
          if (!response.success) {
            return;
          }

          // Remove mail links cache
          removeSessionStorageWithRegex(/^mail:links:/);

          // Notify subject when link change
          if (notify) {
            this.linkChangeSubject.next({ type: 'remove', mailLinkId });
          }
        })
      );
    }
  };

  history = {
    search: (mailId: string): Observable<Response<any>> => {
      return this.http.post<Response<any>>(`${environment.apiUrl}/api/MailHistory/Search`, { filter: { mailId } });
    }
  };

  static getIntegrationType(defaultValue: MailIntegrationType = null): MailIntegrationType {
    const type = localStorage.getItem('mail_integration_type');

    switch (type) {
      case '0': return MailIntegrationType.OUTLOOK;
      case '1': return MailIntegrationType.GMAIL;
    }

    if (null !== defaultValue) {
      return defaultValue;
    }

    throw new Error('Mail integration type not found');
  }

  logout(context?: HttpContext): Observable<Response<any>> {
    const request = { tokenConnectionId: MailSignalRService.tokenConnectionId };
    return this.http.post<Response<any>>(environment.apiUrl + '/api/MailIntegration/Logout', request, { context })
      .pipe(
        tap((response) => {
          if (response.success) {

            localStorage.removeItem('mail_integration_type');
            sessionStorage.clear();
            this.loginInfoSubject.next(null);
            MailSignalRService.onLogout();
          }
        })
      );
  }

  isLoggedIn(): Observable<boolean> {
    if (!MailSignalRService.tokenConnectionId) {
      return of(false);
    }

    const request = { tokenConnectionId: MailSignalRService.tokenConnectionId };
    return this.http
      .post<Response<LoginInfo>>(environment.apiUrl + '/api/MailIntegration/IsLoggedIn', request)
      .pipe(
        tap(response => this.loginInfoSubject.next(response.success ? response.data : null)),
        map(response => response.success)
      );

  }

  listenOutlookInbox() {
    const request = {
      tokenConnectionId: MailSignalRService.tokenConnectionId,
      signalRConnectionId: MailSignalRService.connectionId,
      notificationSignalRConnectionId: NotificationSignalRService.connectionId
    };

    return this.http
      .post<Response<LoginInfo>>(environment.apiUrl + '/api/MailIntegration/ListenOutlookInbox', request);
  }

  send(request: MailSendRequest): Promise<Response<MailSendResponse>> {
    return new Promise(async (resolve, reject) => {
      // Append token connection id
      request.tokenConnectionId = MailSignalRService.tokenConnectionId;

      // // Prepare files for upload
      // if (request.files) {
      //   // Crete file list to encode
      //   const files = request.files.filter((item) => item instanceof File) as File[];

      //   // Clear file instances from request
      //   request.files = request.files.filter((item) => !(item instanceof File));

      //   // Convert files to base64 encoded
      //   try {
      //     for (const file of files) {
      //       request.files.push({
      //         uploadFiles: await fileToBase64(file),
      //         contentType: file.type,
      //         uploadFilesName: file.name,
      //         attachmentId: file.attachmentId
      //       });
      //     }
      //   } catch (e) {
      //     console.error(e);
      //     reject('Mail send failed, error while converting files to base64.');
      //     return;
      //   }
      // }

      try {
        const headers = new HttpHeaders({ 'content-type': 'application/json' });
        const response = await this.http
          .post<Response<MailSendResponse>>(
            environment.apiUrl + '/api/MailIntegration/Send',
            request,
            { headers }
          )
          .toPromise();

        resolve(response);
      } catch (error) {
        console.error(error);
        reject('Mail send failed, error while sending mail.');
      }
    });
  }

  sendTrash(messageId: string): Observable<void> {
    const request = {
      tokenConnectionId: MailSignalRService.tokenConnectionId,
      messageId,
    };

    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<void>(environment.apiUrl + '/api/MailIntegration/SendTrash', request, { headers });
  }

  delete(messageId: string): Observable<void> {
    const request = {
      tokenConnectionId: MailSignalRService.tokenConnectionId,
      messageId,
    };

    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<void>(environment.apiUrl + '/api/MailIntegration/Delete', request, { headers });
  }

  getItBackFromTrash(messageId: string): Observable<void> {
    const request = {
      tokenConnectionId: MailSignalRService.tokenConnectionId,
      messageId,
    };

    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<void>(environment.apiUrl + '/api/MailIntegration/GetItBackFromTrash', request, { headers });
  }

  listMessage(request: MailListRequest): Observable<Response<MailList>> {
    request.tokenConnectionId = MailSignalRService.tokenConnectionId;
    return this.http.post<Response<MailList>>(environment.apiUrl + '/api/MailIntegration/ListMessage', request);
  }

  folderList(): Observable<Response<MailFolder[]>> {
    const request = { tokenConnectionId: MailSignalRService.tokenConnectionId };
    return this.http.post<Response<MailFolder[]>>(environment.apiUrl + '/api/MailIntegration/FolderList', request);
  }

  childFolderList(parentFolderId: string): Observable<Response<MailFolder[]>> {
    const request = { tokenConnectionId: MailSignalRService.tokenConnectionId, parentFolderId };
    return this.http.post<Response<MailFolder[]>>(environment.apiUrl + '/api/MailIntegration/FolderList', request);
  }

  getMessage(request: GetMessageRequest): Observable<Response<Mail>> {
    request.tokenConnectionId = MailSignalRService.tokenConnectionId;
    return this.http.post<Response<Mail>>(environment.apiUrl + '/api/MailIntegration/GetMessage', request);
  }

  uploadFile(file: File): Observable<Response<UploadedFile>> {
    const form = new FormData();
    form.append('file', file);

    return this.http.post<Response<UploadedFile>>(
      `${environment.apiUrl}/api/MailIntegration/UploadFile?connectionId=${MailSignalRService.connectionId}`,
      form,
    );
  }

  uploadFileFromCX(file: File): Observable<HttpEvent<Response<UploadedFile>>> {
    const form = new FormData();
    form.append('file', file);

    return this.http.post<Response<UploadedFile>>(
      `${environment.apiUrl}/api/MailIntegration/UploadFile`,
      form,
      { reportProgress: true, observe: 'events' }
    );
  }

  downloadFile(request: DownloadFileRequest): Observable<HttpResponse<Blob>> {
    request.tokenConnectionId = MailSignalRService.tokenConnectionId;
    request.connectionId = MailSignalRService.connectionId;
    const headers = new HttpHeaders({ 'content-type': 'application/json' });

    return this.http.post(
      environment.apiUrl + '/api/MailIntegration/DownloadFile',
      request,
      { headers, observe: 'response', responseType: 'blob' }
    );
  }

  downloadMailIntegration(id: string) {
    const headers = new HttpHeaders(
      { 'content-type': 'application/json' }
    );
    return this.http.post(environment.apiUrl + '/api/Attachment/DownloadMailIntegration', JSON.stringify(id), {
      headers,
      observe: 'response',
      responseType: 'blob'
    });
  }

  scanFile(request: DownloadFileRequest): Observable<Response<{ attachmentId: string; contentType: string; data: string; }>> {
    request.tokenConnectionId = MailSignalRService.tokenConnectionId;
    request.connectionId = MailSignalRService.connectionId;
    return this.http.post<Response<{ attachmentId: string; contentType: string; data: string; }>>(
      environment.apiUrl + '/api/MailIntegration/ScanFile',
      request
    );
  }

  getMailInfos(request: MailInfoRequest, isCache = true): Observable<Response<MailInfoResponse>> {
    request.tokenConnectionId = MailSignalRService.tokenConnectionId;

    // Generate cache key and get cached data
    const key = `mail:infos:${djb2HashCode(JSON.stringify(request))}`;
    const time = sessionStorage.getItem(`${key}:time`);
    const cache = sessionStorage.getItem(key);

    // Return cached data if exits and not expired, cache time is 300 seconds
    if (isCache && cache && time && (new Date().getTime() - parseInt(time, 10)) < 300 * 1000) {
      return of(JSON.parse(cache));
    }

    return this.http
      .post<Response<MailInfoResponse>>(environment.apiUrl + `/api/MailIntegration/GetMailInfos`, request)
      .pipe(
        tap((response) => {
          if (!response.success) {
            return;
          }

          sessionStorage.setItem(key, JSON.stringify(response));
          sessionStorage.setItem(`${key}:time`, new Date().getTime().toString());
        }),
      );
  }

  getRedirectUrl(mailIntegrationType: MailIntegrationType): Observable<string> {
    const params = {
      connectionId: MailSignalRService.connectionId,
      mailIntegrationType,
    };

    localStorage.setItem('mail_integration_type', mailIntegrationType.toString());

    return this.http.get<Response<string>>(`${environment.apiUrl}/api/MailIntegration/GetRedirectUrl`, { params })
      .pipe(map(response => response.data));
  }

  getUnreadMessagesCount(): Observable<Response<number>> {
    const request = { tokenConnectionId: MailSignalRService.tokenConnectionId };
    return this.http.post<Response<number>>(environment.apiUrl + '/api/MailIntegration/GetUnreadMessagesCount', request);
  }

  messageMarkAsReadUnread(request: MessageMarkAsReadUnreadRequest): Observable<Response<MessageMarkAsReadUnreadResponse>> {
    request.tokenConnectionId = MailSignalRService.tokenConnectionId;
    return this.http.post<Response<MessageMarkAsReadUnreadResponse>>(environment.apiUrl + '/api/MailIntegration/MessageMarkAsRead', request);
  }
}
