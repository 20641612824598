import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  City, CityFilterRequest, Country, CountryFilterRequest, County, CountyFilterRequest,
  Neighborhood, NeighborhoodFilterRequest, PagedResponse
} from '@core/api';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(private http: HttpClient) { }

  countries(request: CountryFilterRequest): Observable<PagedResponse<Country>> {
    return this.http.post<PagedResponse<Country>>(environment.apiUrl + '/api/Country/Search', request);
  }

  cities(request: CityFilterRequest): Observable<PagedResponse<City>> {
    return this.http.post<PagedResponse<City>>(environment.apiUrl + '/api/City/Search', request);
  }

  counties(request: CountyFilterRequest): Observable<PagedResponse<County>> {
    return this.http.post<PagedResponse<County>>(environment.apiUrl + '/api/County/Search', request);
  }

  neighborhoods(request: NeighborhoodFilterRequest): Observable<PagedResponse<Neighborhood>> {
    return this.http.post<PagedResponse<Neighborhood>>(environment.apiUrl + '/api/Neighborhood/Search', request);
  }

  countryList(request: CountryFilterRequest): Observable<PagedResponse<Country>> {
    return this.http.post<PagedResponse<Country>>(environment.apiUrl + '/api/Country/List', request);
  }
}
