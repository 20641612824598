import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import { Observable } from 'rxjs';

import { PagedResponse, FilterRequest } from '@core/api';
import { RegionMain } from './region-main.model';

@Injectable({
    providedIn: 'root'
})
export class RegionMainService {
    constructor(private http: HttpClient) { }

    search(salesOrganizationId: string): Observable<PagedResponse<RegionMain>> {
        const request: FilterRequest = { filter: { salesOrganizationId } };
        return this.http.post<PagedResponse<RegionMain>>(environment.apiUrl + '/api/RegionMain/Search', request);
    }
}
