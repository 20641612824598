import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { djb2HashCode } from '../../utils/djb-hash';

@Component({
  selector: 'net-mail-circle-avatar[key][identifier]',
  template: `
    <div class="w-8 h-8 rounded-full overflow-hidden mt-1 flex-none">
      <div
        class="w-full h-full object-fit rounded-full justify-center items-center flex bg-gray-500 font-medium text-contrast-white"
        [style.background-color]="initialsColor">
        {{ initials }}
      </div>
    </div>
  `,
})
export class MailCircleAvatarComponent implements OnChanges {
  private colors = ['#483e90', '#34529a', '#69b7c7', '#4674b4'];

  initials = '';
  initialsColor = '';

  @Input() key: string;
  @Input() identifier: string;

  private generateInitials(): void {
    // If input is empty return empty string
    if (!this.identifier || this.identifier === '') {
      return;
    }

    // Clean given input
    const clean = this.identifier
      .replace(/[^a-zğĞöÖüÜşŞçÇıI\s]/gi, '')
      .replace(/\s+/g, ' ')
      .trim();

    // If clean is empty return empty string
    if (clean === '') {
      return;
    }

    // Extract words
    const words = clean.split(' ');

    // Update initials with first char of first word and first char of last word
    this.initials = [
      words[0][0],
      words.length === 1 ? words[0][words[0].length - 1] : words[words.length - 1][0]
    ]
      .join('')
      .toLocaleUpperCase();
  }

  private generateInitialsColor(): void {
    const hash = djb2HashCode(this.key);
    const index = Math.abs(hash) % this.colors.length;

    this.initialsColor = this.colors[index];
  }

  ngOnChanges(changes: SimpleChanges) {
    // Make sure key or identifier is changed to calculate
    if (
      changes?.key?.previousValue === changes?.key?.currentValue &&
      changes?.identifier?.previousValue === changes?.identifier?.currentValue
    ) {
      return;
    }

    // If key or identifier is empty nothing to do
    if (!this.key || !this.identifier) {
      return;
    }

    this.generateInitials();
    this.generateInitialsColor();
  }
}
