<ng-container *ngIf="pageLoading">
  <div fxLayout="column">
    <ngx-skeleton-loader fxFlex animation="pulse" count="2" appearance="line"></ngx-skeleton-loader>
  </div>
</ng-container>
<ng-container *ngIf="!pageLoading">
  <div class="survey-preview net-crm-survey  {{ isPreview ? 'is--preview' : 'is--survey' }} ">

    <div class="net-crm-survey-container">

      <div class="net-crm-survey-title text-color-black">
        {{previewDataParent?.title}}
      </div>
      <div *ngIf="previewDataParent?.showCompanyLogo || isPreview === false">
        <img *ngIf="previewDataParent?.companyLogo?.mediaFilePath"
          [src]="getLogoSrc(previewDataParent?.companyLogo?.mediaFilePath)" alt="Logo" class="net-crm-survey-logo" />
      </div>
    </div>

    <div class="net-crm-survey-body">

      <ng-container *ngIf="previewDataParent?.showFeedback || isPreview">

        <h4 class="text-center text-color-black">{{previewDataParent?.surveyQuestions?.[0]?.question}}</h4>


        <div class="w-full flex justify-center">
          <ul class="net-crm-feedback-selectors feedback-container {{ selectedScore ? 'selector-disabled' : '' }}">
            <li *ngFor="let item of feedbackOptions"
              [class]="item.surveyScoreId === selectedScore ? 'selector-selected' : '' "
              (click)="onScoreChanged(item.surveyScoreId)">
              <span class="feedback-shape" [ngStyle]="{'background-color': item.color}"></span>
              <span class="feedback-value">{{item.label}}</span>
              <span class="feedback-caption">{{item.caption}}</span>
            </li>
          </ul>
        </div>
      </ng-container>

      <ng-container *ngIf="previewDataParent?.showFeedback">

        <h4 *ngIf="selectedScore text-color-black" class="text-center mt-40">{{ previewDataParent?.feedbackText }}</h4>

        <mat-form-field appearance="outline" style="display: block; width: 100%; margin-top: 4px;">
          <mat-label>{{'SURVEY.PLACEHOLDER_DESCRIPTION' | translate }}</mat-label>
          <textarea class="text-color-black" [(ngModel)]="surveyFeedback" name="surveyFeedback" ngDefaultControl
            cdkAutosizeMaxRows="10" [maxLength]="1000" cdkAutosizeMinRows="4" cdkTextareaAutosize matInput
            style="height: 82px;"></textarea>
        </mat-form-field>


        <div style="display: flex;">
          <net-progress-button class="ml-auto" [active]="loading" (btnClick)="onSubmit()"
            text="{{'GENERAL.SEND' | translate }}"></net-progress-button>
        </div>
      </ng-container>

      <ng-container *ngIf="messageStatus !== null">
        <div class="net-crm-feedback-message">
          <img src="/assets/image/layout/survey/{{ messageStatus === 'success' ? 'completed.png' : 'failed.png' }}"
            alt="Completed">
          <div class="content">
            <ng-container *ngIf="messageStatus === 'success'">
              <h4>{{ messageText }}</h4>
            </ng-container>
            <ng-container *ngIf="messageStatus === 'error'">
              <h5>{{ messageText }}</h5>
            </ng-container>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="isUnsubscribe">
        <div class="net-crm-feedback-message">
          <img src="/assets/image/layout/survey/mail.png" alt="Mail">
          <div class="content text-center">
            <h4>{{'SURVEY.UNSUBSCRIBE_MAIL_LIST' | translate}}</h4>
            <net-progress-button [active]="loading" (btnClick)="onSubscribe()"
              text="{{'SURVEY.UNSUBSCRIBE_BUTTON' | translate}}"></net-progress-button>
          </div>
        </div>
      </ng-container>

    </div>
  </div>
</ng-container>