import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StaticField, StaticFieldFilterRequest } from './static-field.model';
import { environment } from '@env/environment';
import { PagedResponse, Response } from '../api.model';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class StaticFieldService {
    constructor( private http: HttpClient) { }

    get(uuid: string): Observable<Response<StaticField>> {
        const headers = new HttpHeaders({ 'content-type': 'application/json' });
        return this.http.post<Response<StaticField>>(environment.apiUrl + '/api/EntityField/Get', JSON.stringify(uuid), {headers});
    }

    search(request: StaticFieldFilterRequest): Observable<PagedResponse<StaticField>> {
        return this.http.post<PagedResponse<StaticField>>(environment.apiUrl + '/api/EntityField/Search', request);
    }

    updateStatus(uuid: string): Observable<Response<StaticField>> {
        const headers = new HttpHeaders({ 'content-type': 'application/json' });
        return this.http.post<Response<StaticField>>(environment.apiUrl + '/api/EntityField/UpdateStatus', JSON.stringify(uuid), {headers});
    }

    update(request: StaticField): Observable<Response<StaticField>> {
        return this.http.post<Response<StaticField>>(environment.apiUrl + '/api/EntityField/Update', request);
    }
}
