import { Injectable } from '@angular/core';
import { CustomerExperienceTicket } from '@core/api';
import moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomerExperienceTicketPreviewService {

  private previews: CustomerExperienceTicket[] = [];
  private previewsSubject = new Subject<CustomerExperienceTicket[]>();
  private hasPreviewSubject = new BehaviorSubject<boolean>(false);

  previews$ = this.previewsSubject.asObservable();
  hasPreview$ = this.hasPreviewSubject.asObservable();

  private updateSubjects() {
    this.previewsSubject.next(this.previews);
    this.hasPreviewSubject.next(this.previews.length > 0);
  }

  add(preview: CustomerExperienceTicket) {
    if (this.isExists(preview.ticketId)) {
      return;
    }

    this.previews.push(preview);
    this.updateSubjects();
  }

  addLink(ticket: CustomerExperienceTicket) {
    this.add(ticket);
  }

  remove(preview: CustomerExperienceTicket) {
    if (!this.isExists(preview.ticketId)) {
      return;
    }

    this.previews = this.previews.filter((item) => preview.ticketId !== item.ticketId);
    this.updateSubjects();
  }

  clear() {
    this.previews = [];
    this.updateSubjects();
  }

  isExists(id: string) {
    return this.previews.some((item) => id === item.ticketId);
  }

}
