
import { Component, Input } from '@angular/core';
import {
  SystemSettingService, SystemSettingType,
  SystemSettingTypeAsString,
} from '@core/api';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { Survey } from '@core/api/survey/survey.model';
import { Store } from '@ngrx/store';
import { getSystemSettingValue } from '@core/store';
import { SurveyService } from '@core/api/survey/survey.service';
import { SurveyScoreOptions } from '@core/api/survey-mail/survey-mail.enum';
import Swal from 'sweetalert2';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'net-survey-preview',
  templateUrl: './survey-preview.component.html',
  styleUrls: ['./survey-preview.component.scss']
})
export class SurveyPreviewComponent {
  loading = false;
  pageLoading = true;
  translations: any;

  logoPath: any = {};

  previewDataParent: Survey | any;

  @Input()
  set previewData(previewData: Survey | any) {
    if (previewData) {
      this.feedbackOptions[0].caption = previewData.worstScore;
      this.feedbackOptions[10].caption = previewData.bestScore;

      this.previewDataParent = previewData;
    }
  }
  selectedScore: string;

  isPreview = true;
  isUnsubscribe = false;

  surveyFeedback = '';

  feedbackOptions = [{
    label: '0',
    surveyScoreId: SurveyScoreOptions.OPTION_0,
    value: 0,
    color: '#443786',
    caption: this.translate.instant('SURVEY.NOT_LIKELY')
  }, {
    label: '1',
    surveyScoreId: SurveyScoreOptions.OPTION_1,
    value: 1,
    color: '#434c8a'
  }, {
    label: '2',
    surveyScoreId: SurveyScoreOptions.OPTION_2,
    value: 2,
    color: '#43568c'
  }, {
    label: '3',
    surveyScoreId: SurveyScoreOptions.OPTION_3,
    value: 3,
    color: '#42688f'
  }, {
    label: '4',
    surveyScoreId: SurveyScoreOptions.OPTION_4,
    value: 4,
    color: '#437090'
  }, {
    label: '5',
    surveyScoreId: SurveyScoreOptions.OPTION_5,
    value: 5,
    color: '#438193'
  }, {
    label: '6',
    surveyScoreId: SurveyScoreOptions.OPTION_6,
    value: 6,
    color: '#408b94'
  }, {
    label: '7',
    surveyScoreId: SurveyScoreOptions.OPTION_7,
    value: 7,
    color: '#439494'
  }, {
    label: '8',
    surveyScoreId: SurveyScoreOptions.OPTION_8,
    value: 8,
    color: '#429e94'
  }, {
    label: '9',
    surveyScoreId: SurveyScoreOptions.OPTION_9,
    value: 9,
    color: '#40b194'
  }, {
    label: '10',
    surveyScoreId: SurveyScoreOptions.OPTION_10,
    value: 10,
    color: '#2bc291',
    caption: this.translate.instant('SURVEY.VERY_LIKELY')
  }];

  questionLabels = [];

  messageText: string = null;
  messageStatus: string = null;
  defaultLogo: string = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private systemSettingService: SystemSettingService,
    private surveyService: SurveyService,
    private store: Store,
    private toastService: ToastService,
  ) {

    this.systemSettingService.search({ filter: { systemSettingId: SystemSettingTypeAsString.LOGO_LIGHT } }).subscribe();

    this.questionLabels = [{
      placeholder: '0-6',
      scoreMin: 0,
      scoreMax: 6,
      feedback: this.translations?.SURVEY?.SCORE_0to6,
    }, {
      placeholder: '7-8',
      scoreMin: 7,
      scoreMax: 8,
      feedback: this.translations?.SURVEY?.SCORE_7to8,
    }, {
      placeholder: '9-10',
      scoreMin: 9,
      scoreMax: 10,
      feedback: this.translations?.SURVEY?.SCORE_9to10,
    }];

    translate.get(['GENERAL', 'SURVEY']).subscribe(translations => {
      this.translations = translations;
    });

    this.activatedRoute.data.subscribe((res) => {

      this.isUnsubscribe = res.isUnsubscribe;

      if (res.isPreview === false) {

        this.isPreview = res.isPreview;

      }
    });

    this.activatedRoute.queryParams.subscribe((res) => {

      if (res.surveyContactMailId || res.surveyScoreId) {
        this.isPreview = false;
      }

      this.previewDataParent = {
        surveyScoreId: res.surveyScoreId,
        surveyContactMailId: res.surveyContactMailId,
      };

      if (!(res.surveyContactMailId && res.surveyScoreId)) {
        this.pageLoading = false;
        return;
      }

      this.changeAnswer(res.surveyContactMailId, res.surveyScoreId);
    });

    this.setSystemSettings();
  }

  changeAnswer(surveyContactMailId, surveyScoreId, showMessage = false) {
    this.surveyService.createAnswer(surveyContactMailId, surveyScoreId).subscribe((response) => {

      this.previewDataParent = {
        ...this.previewDataParent,
        showFeedback: response.data.showFeedback, // true, // response.data.showFeedback,
        title: response.data.title,
        surveyName: response.data.surveyName,
        ['surveyQuestions']: [{
          question: response.data?.question || this.translations?.SURVEY?.SURVEY_QUESTION_TEMPLATE
        }],
        feedbackText: response.data?.feedbackText,
        endingText: response.data?.endingText,
        companyLogo: {
          mediaFilePath: response.data?.mediaFilePath
        },
        showCompanyLogo: !!response.data?.mediaFilePath
      };

      this.feedbackOptions[0].caption = response.data.worstScore;
      this.feedbackOptions[10].caption = response.data.bestScore;

      if (showMessage && response.success) {
        this.toastService.success(this.translate.instant('ResultMessage.Updated successfully'));
        return;
      }

      if (this.isUnsubscribe) {
        return;
      }

      if (response.data.showFeedback) {

      } else if (response.success && response.data.showFeedback === false) {
        this.messageStatus = 'success';
        this.messageText = this.previewDataParent?.endingText || this.translations?.SURVEY?.SURVEY_ENDING_MESSAGE;
        this.isPreview = false;
      } else {
        this.messageStatus = 'error';
        this.messageText = response.message ? this.translate.instant(`ResultMessage.${response.message}`) :
          this.translate.instant('ResultMessage.Your answer could not be saved because you have reached the maximum update limit.');
      }


    }, () => { }, () => {
      this.selectedScore = surveyScoreId;
      this.pageLoading = false;
    });
  }

  onScoreChanged(surveyScoreId) {
    if (!this.isPreview) {
      this.changeAnswer(this.previewDataParent.surveyContactMailId, surveyScoreId, true);
    }
  }

  setSystemSettings() {
    this.store.select(getSystemSettingValue(SystemSettingType.LOGO_LIGHT)).subscribe(result => {
      if (Array.isArray(result)) {
        this.logoPath[SystemSettingTypeAsString.LOGO_LIGHT] = result;
        this.logoPath['default'] = result;
      }
    });

    this.store.select(getSystemSettingValue(SystemSettingType.LOGO_DARK)).subscribe(result => {
      if (Array.isArray(result)) {
        this.logoPath[SystemSettingTypeAsString.LOGO_DARK] = result;
      }
    });
  }

  getLogoSrc(path: string): string {
    return `https://media.xooi.com/${path}`;
  }

  onSubmit() {
    if (!(this.isPreview)) {
      this.loading = true;
      this.surveyService.saveFeedback(this.previewDataParent.surveyContactMailId, this.surveyFeedback).subscribe((response) => {
        if (response.success) {
          this.messageStatus = 'success';
          this.messageText = this.previewDataParent?.endingText || this.translations?.SURVEY?.SURVEY_ENDING_MESSAGE;
          this.previewDataParent.showFeedback = false;
        } else {
          this.messageStatus = 'error';
          this.messageText = this.translations?.SURVEY?.SURVEY_ERROR_MESSAGE;
          this.previewDataParent.showFeedback = false;
        }

        this.loading = false;
      });
    }
  }

  onSubscribe() {

    Swal.fire({
      html: this.translate.instant('SURVEY.UNSUBSCRIBE_CONFIRMATION'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#4caf50',
      confirmButtonText: this.translations?.SURVEY.UNSUBSCRIBE_BUTTON,
      cancelButtonText: this.translations?.GENERAL.CANCEL
    }).then((result) => {

      if (result.value) {
        this.surveyService.unsubscribe(this.previewDataParent.surveyContactMailId).subscribe((response) => {
          if (response.success) {
            this.messageStatus = 'success';
            this.messageText = this.translate.instant('SURVEY.UNSUBSCRIBE_SUCCESS');
            this.isUnsubscribe = false;
          } else {
            this.messageStatus = 'error';
            this.messageText = this.translate.instant('SURVEY.UNSUBSCRIBE_ERROR');
            this.isUnsubscribe = false;
          }
        });
      }

    });
  }

}
