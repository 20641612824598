export enum DocumentUploadType {
  ACCOUNT,
  ACTIVITY,
  CUSTOMER,
  PRICE_LIST,
  OPPORTUNITY,
  ASSIGNMENT,
  CARD,
  MAIL_LIST
}
