<input
  #input
  matInput
  class="autocomplete-input"
  [matTooltip]="'GENERAL.PLEASE_SELECT_A_CUSTOMER' | translate"
  [tabIndex]="tabIndex"
  [formControl]="inputCtrl"
  [matAutocomplete]="auto"
  [matTooltipDisabled]="!!this.customer"
  [readonly]="!this.customer"
  (blur)="onBlur()">
<mat-autocomplete
  #auto="matAutocomplete"
  [displayWith]="displayName"
  [autoActiveFirstOption]="true"
  (optionSelected)="onSelect($event)"
  (closed)="onClosed()">
  <ng-container *ngIf="this.contacts">
    <mat-option *ngIf="contacts.length === 0 && !loading" disabled>{{"CONTACT.CONTACT_NOT_FOUND" | translate}}</mat-option>
    <mat-option *ngFor="let contact of contacts" [value]="contact">
        <span>{{ contact.firstName }} {{ contact.lastName }}</span>
        <span fxHide.lt-md class="contact-detail" *ngIf="contact.jobTitle"> - {{ contact.jobTitle }}</span>
    </mat-option>
  </ng-container> 
</mat-autocomplete>

<button type="button" class="edit-contact" mat-icon-button [hidden]="this.loading" (click)="add(value)" *ngIf="!disabled && value">
  <ic-icon [icon]="icEdit" size="16px"></ic-icon>
</button>

<button type="button" class="add-contact" mat-icon-button [hidden]="this.loading" (click)="add()" *ngIf="!disabled">
    <ic-icon [icon]="icAdd" size="16px"></ic-icon>
</button>

<mat-progress-spinner *ngIf="loading === true" diameter="16" mode="indeterminate"></mat-progress-spinner>
