import { Board, BoardInfo } from '@core/api';
import { BoardDetailReportActionTypes, BoardReportActions } from '@core/store';

export interface BoardDetailReportState {
    board: Board;
    boardInfos: BoardInfo[];
}

export const boardDetailReport: BoardDetailReportState = {
    board: null,
    boardInfos: []
};

export function boardDetailReportReducer(state = boardDetailReport, action: BoardReportActions): BoardDetailReportState {
    switch (action.type) {
        case BoardDetailReportActionTypes.GET_BOARD_REPORT_WITH_BOARD_INFOS:
            return {
                ...state
            };
        case BoardDetailReportActionTypes.SET_BOARD_REPORT_WITH_BOARD_INFOS_SUCCESS:
            return {
                ...state,
                boardInfos: action.boardInfos
            };
        case BoardDetailReportActionTypes.SET_BOARD_REPORT_WITH_BOARD_INFOS_ERROR:
            return {
                ...state
            };
        case BoardDetailReportActionTypes.SET_BOARD_REPORT_BOARD:
            return {
                ...state,
                board: action.board
            };
        default:
            return {
                ...state
            };
    }
}
