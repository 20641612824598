import { forkJoin } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { animate, AnimationBuilder, style } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { InitialService } from '@core/api';
import { AuthService } from '@core/auth/auth.service';
import { GetSalesOrganizationSettingSuccess, MeSuccess } from '@core/store';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class SplashScreenService {

  splashScreenElem: HTMLElement;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private auth: AuthService,
    private animationBuilder: AnimationBuilder,
    private translate: TranslateService,
    private initialService: InitialService,
    private store: Store
  ) {
    this.splashScreenElem = this.document.body.querySelector('#net-splash-screen');

    if (this.splashScreenElem) {
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        take(1)
      ).subscribe(async (result: NavigationEnd) => {
        setTimeout(() => {
          const element = document.createElement('p');
          const text = document.createTextNode(this.translate.instant('GENERAL.REFRESH_THE_PAGE_BY_PRESSING_THE_CTRL_SHIFT_R_BUTTONS_TOGETHER_OR_TRY_AGAIN_BY_CLOSING_AND_OPENING_YOUR_BROWSER'));
          element.appendChild(text);
          element.classList.add('reload-warning', 'text-white');
          this.splashScreenElem.appendChild(element);
        }, 10000);

        // If app is authenticated try to fetch user
        if (this.auth.isAuthenticated() && '/auth/login' !== result.url) {

          const combined = forkJoin([
            this.auth.me(),
            this.initialService.initialSettings(auth.authUser.userId),
          ]);

          combined.subscribe({
            complete: () => setTimeout(() => this.hide(), 200),
            next: ([user, settings]) => {
              if (user.success && settings) {
                this.store.dispatch(new GetSalesOrganizationSettingSuccess(settings));
                this.store.dispatch(new MeSuccess(user));
              }
            },
            error: () => this.hide()
          });
          return;
        }

        // If not authenticated hide splash after navigation end
        this.hide();
      });
    }
  }

  hide() {
    const player = this.animationBuilder.build([
      style({
        opacity: 1
      }),
      animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({
        opacity: 0
      }))
    ]).create(this.splashScreenElem);

    player.onDone(() => this.splashScreenElem.remove());
    player.play();
  }
}
