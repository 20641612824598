import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { MatSnackBar } from '@angular/material/snack-bar';

import { first } from 'rxjs/operators';
import { concat, interval } from 'rxjs';

import { ToastService } from '../../shared/services/toast.service';

import { AuthService } from '@core/auth/auth.service';
import { AppUpdateActivatedEvent, AppUpdateAvailableEvent } from '@core/sw/sw.service.model';

import { AlertService } from '../../shared/services/alert.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class SWService {

  constructor(
    private applicationRef: ApplicationRef,
    private swUpdate: SwUpdate,
    private snackBar: MatSnackBar,
    private toastService: ToastService,
    private alert: AlertService,
    private auth: AuthService,
    private translate: TranslateService
  ) {
    if (this.swUpdate.isEnabled) {
      this.watchUpdates();
      this.listenUpdates();
    }
  }

  private watchUpdates() {
    const appIsStable$ = this.applicationRef.isStable.pipe(first(isStable => isStable === true));
    concat(appIsStable$, interval(45 * 1000)).subscribe(() => this.swUpdate.checkForUpdate());
  }

  private listenUpdates() {
    // Open update snackbar on new version available
    this.swUpdate.available.subscribe((event: AppUpdateAvailableEvent) => {
      this.snackBar.open(this.translate.instant('GENERAL.NEW_VERSION_IS_AVAIBLE', {value: event.available.appData.version}),
        this.translate.instant('GENERAL.UPDATE_STARTED'));

      // Show update alert
      this.alert.show(this.translate.instant('GENERAL.INSTALLING_UPDATES_PLEASE_WAIT'), true);

      // After activation, logout and hard reload
      this.swUpdate.activateUpdate().then(() => {
        this.auth.logout(false);
      });
    });

    // Show toast message when new version is installed
    this.swUpdate.activated.subscribe((event: AppUpdateActivatedEvent) => {
      this.toastService.info(this.translate.instant('GENERAL.NEW_VERSION_IS_INSTALLED', {value: event.current.appData.version}));
    });
  }
}
