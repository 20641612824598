import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    PagedResponseData, Response, TransactionCurrency, TransactionCurrencyDefaultUpdateRequest,
    TransactionCurrencyFilterRequest, TransactionCurrencySearchRequest,
    TransactionCurrencyStatusUpdateRequest
} from '@core/api';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {

  constructor(private http: HttpClient) { }

  update(request: TransactionCurrency) {
    return this.http.post(environment.apiUrl + '/api/TransactionCurrency/Update', request);
  }

  list(request: TransactionCurrencyFilterRequest) {
    return this.http.post<Response<PagedResponseData<TransactionCurrency>>>(environment.apiUrl + '/api/TransactionCurrency/List', request);
  }

  search(request: TransactionCurrencySearchRequest) {
    return this.http.post<Response<PagedResponseData<TransactionCurrency>>>(environment.apiUrl + '/api/TransactionCurrency/Search', request);
  }

  getDefaultCurrency() {
    return this.http.post<Response<TransactionCurrency>>(environment.apiUrl + '/api/TransactionCurrency/DefaultCurrency', {});
  }

  updateDefaultCurrency(request: TransactionCurrencyDefaultUpdateRequest) {
    return this.http.post<Response<TransactionCurrency>>(environment.apiUrl + '/api/TransactionCurrency/UpdateDefaultCurrency', request);
  }

  updateCurrencyStatus(request: TransactionCurrencyStatusUpdateRequest) {
    return this.http.post<Response<TransactionCurrency>>(environment.apiUrl + '/api/TransactionCurrency/UpdateStatus', request);
  }
}
