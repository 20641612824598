<mat-select #warningSelect [placeholder]="placeholder" [formControl]="selectCtrl" multiple
    (selectionChange)="onSelectionChange()" [compareWith]="compareWith" (openedChange)="openedChange($event)"
    (closed)="searchText = ''; filteredWarnings = warnings">

    <mat-select-trigger *ngIf="value?.length > 1" [matTooltipDisabled]="!(value.length > 1 && disabled)"
        [matTooltip]="getToolTip()" [matTooltipClass]="'warning-tooltip'" [matTooltipPosition]="'before'">
        {{'MAIL_LIST.WARNINGS_VALUE_SELECTED' | translate: {value:
        value?.length} }}
    </mat-select-trigger>

    <input (keydown)="$event.stopPropagation()" [(ngModel)]="searchText" (keyup)="onKey($event)" matInput
        class="pt-4 pb-2 pl-4 w-full" maxlength="10" placeholder="{{'GENERAL.SEARCH_ELLIPSIS' | translate}}"
        type="text">

    <mat-option disabled="disabled" class="mat-option-button">
        <button mat-raised-button (click)="selectAll()">
            {{'GENERAL.SELECT_ALL' | translate }}
        </button>
        <button mat-raised-button (click)="deselectAll()">{{'GENERAL.REMOVE_ALL' |
            translate
            }}</button>
    </mat-option>

    <mat-option *ngFor="let item of filteredWarnings" [value]="item.warningId">{{item.name}}</mat-option>

</mat-select>