<div [class.boxed]="(isBoxed$ | async) === true" [class.horizontal-layout]="(isLayoutVertical$ | async) === false"
  [class.is-mobile]="(isDesktop$ | async)  === false" [class.vertical-layout]="(isLayoutVertical$ | async) === true"
  [class.has-fixed-footer]="(isFooterFixed$ | async) === true && (isFooterVisible$ | async) === true"
  [class.has-footer]="(isFooterVisible$ | async) === true" [class.scroll-disabled]="(scrollDisabled$ | async) === true"
  [class.toolbar-fixed]="(isToolbarFixed$ | async) === true"
  [class.sidenav-collapsed]="(sidenavCollapsed$ | async) === true"
  [class.content-container]="(containerEnabled$ | async) === true" [class.with-search]="(searchOpen$ | async) === true"
  class="page-container">

  <net-progress-bar></net-progress-bar>

  <net-search></net-search>

  <net-quick-action></net-quick-action>

  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav #sidenav [disableClose]="(isDesktop$ | async)=== true"
      [fixedInViewport]="(isDesktop$ | async) === false"
      [mode]="(isDesktop$ | async) === false || (isLayoutVertical$ | async) === true ? 'over' : 'side'"
      [opened]="(isDesktop$ | async) === true && (isLayoutVertical$ | async) === false" class="sidenav">
      <net-sidenav [collapsed]="(sidenavCollapsed$ | async) === true"></net-sidenav>
    </mat-sidenav>

    <mat-sidenav-content class="sidenav-content">
      <net-toolbar [hasShadow]="(toolbarShadowEnabled$ | async)=== true" [mobileQuery]="(isDesktop$ | async) === false"
        class="toolbar"></net-toolbar>

      <main class="content">
        <router-outlet></router-outlet>
      </main>

      <net-mail-preview [index]="index" [preview]="preview"
        *ngFor="let preview of mailPreviews; let index = index;"></net-mail-preview>

      <net-customer-experience-ticket-preview [index]="index" [preview]="preview" [conversation]="preview.conversation"
        *ngFor="let preview of ticketPreviews; let index = index;"></net-customer-experience-ticket-preview>

      <net-footer *ngIf="(isFooterVisible$ | async)=== true" class="footer"></net-footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>