import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[netSpaceControl]'
})
export class SpaceControlDirective {
  constructor(private control: NgControl) { }

  @HostListener('keyup', ['$event']) onKeyUp(event: KeyboardEvent) {
    const value = this.control.control.value;

    if (event.code === 'Space' && value.trim() === '') {
      this.control.control.setValue(null);
      return;
    }

    if (value) {
      const first = [...value].findIndex(char => char !== ' ');
      this.control.control.setValue(value.substring(first, value.length));
    }
  }
}
