export enum OpportunityStatusType {
  CREATED = 'c8eb8829-fcbc-4a39-9377-36d49fc797ec',
  OFFER = 'c3c53f67-26ae-4783-933f-5ffb4fc8da14',
  BUSINESS_DEVELOPMENT = 'ad894c5f-0210-455e-82a5-8af36c2e905b',
  CONTRACT = '0ecea972-9597-4835-ac69-6631626dd277',
  LOST = '21be7c52-73f8-43d1-b694-75dca549fca0',
  ACTIVE = '5656ff0e-09c6-4315-84a5-5e7be35b46c9',
  PASSIVE = '57a21c8c-eb8d-41e8-8cde-daf55ac5473c',
  CANCELLED = '3640e9f7-e749-43e2-b8d3-be903aa11ffc',
  APPROVED = '0ff5ca77-435f-4b2c-ab83-bcaec8cff924',
  APPROVAL_PROCESS = 'e8a5c7e1-a1a4-4343-9e69-77ab283fa67e'
}
