import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import { FilterRequest, Response, AccountStatus, AccountStatusRequest, PagedResponse } from '@core/api';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class AccountStatusService {
  constructor(private http: HttpClient) { }

  list(request?: FilterRequest): Observable<Response<AccountStatus[]>> {
    return this.http.post<Response<AccountStatus[]>>(environment.apiUrl + '/api/AccountStatus/List', request);
  }

  search(request?: FilterRequest): Observable<PagedResponse<AccountStatus>> {
    return this.http.post<PagedResponse<AccountStatus>>(environment.apiUrl + '/api/AccountStatus/Search', request);
  }

  insert(request: AccountStatusRequest): Observable<Response<AccountStatus>> {
    return this.http.post<Response<AccountStatus>>(environment.apiUrl + '/api/AccountStatus/Insert', request);
  }

  update(request: AccountStatusRequest): Observable<Response<AccountStatus>> {
    return this.http.post<Response<AccountStatus>>(environment.apiUrl + '/api/AccountStatus/Update', request);
  }

  delete(request: AccountStatus): Observable<Response<AccountStatus>> {
    return this.http.post<Response<AccountStatus>>(environment.apiUrl + '/api/AccountStatus/Delete', request);
  }

  filterSearch(request: FilterRequest = { filter: {} } ): Observable<Response<AccountStatus[]>> {
    return this.http.post<Response<AccountStatus[]>>(environment.apiUrl + '/api/AccountStatus/FilterSearch', request);
  }
}
