import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import { Observable } from 'rxjs';
import { AirLine, Response } from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class AirLineService {
  constructor(private http: HttpClient) { }

  list(): Observable<Response<AirLine[]>> {
    return this.http.post<Response<AirLine[]>>(environment.apiUrl + '/api/AirLine/List', {});
  }
}
