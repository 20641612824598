import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import { Observable } from 'rxjs';

import { PagedResponse, FilterRequest } from '@core/api';
import { Region } from './region.model';

@Injectable({
    providedIn: 'root'
})
export class RegionService {
    constructor(private http: HttpClient) { }

    search(regionMainId: string): Observable<PagedResponse<Region>> {
        const request: FilterRequest = { filter: { regionMainId } };
        return this.http.post<PagedResponse<Region>>(environment.apiUrl + '/api/Region/Search', request);
    }
}
