import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

import {
  Response,
  OpportunityPriceListDocument,
  OpportunityPriceListDocumentRequest,
  OpportunityPriceListDocumentSearchRequest,
  PagedResponse,
  SetAsPriceListRequest
} from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class OpportunityPriceListDocumentService {

  constructor(private http: HttpClient) { }

  insert(request: OpportunityPriceListDocumentRequest): Observable<Response<OpportunityPriceListDocument>> {
    return this.http.post<Response<OpportunityPriceListDocument>>(environment.apiUrl + '/api/OpportunityPriceListDocument/Insert', request);
  }

  update(request: OpportunityPriceListDocumentRequest): Observable<Response<OpportunityPriceListDocument>> {
    return this.http.post<Response<OpportunityPriceListDocument>>(environment.apiUrl + '/api/OpportunityPriceListDocument/Update', request);
  }

  search(request: OpportunityPriceListDocumentSearchRequest): Observable<PagedResponse<OpportunityPriceListDocument>> {
    return this.http.post<PagedResponse<OpportunityPriceListDocument>>(environment.apiUrl + '/api/OpportunityPriceListDocument/Search', request);
  }

  setAsPriceList(request: SetAsPriceListRequest): Observable<Response<OpportunityPriceListDocument>> {
    return this.http.post<Response<OpportunityPriceListDocument>>(environment.apiUrl + '/api/OpportunityPriceListDocument/SetAsPriceList', request);
  }

  delete(request: OpportunityPriceListDocumentRequest): Observable<Response<OpportunityPriceListDocument>> {
    return this.http.post<Response<OpportunityPriceListDocument>>(environment.apiUrl + '/api/OpportunityPriceListDocument/Delete', request);
  }
}
