export enum CustomerExperienceAutomationConditionType {
    CHANNEL = 'Channel',
    CHANNEL_TYPE = 'ChannelType',
    MESSAGE_TEXT = 'Message',
    CATEGORY = 'Category',
}

export enum CustomerExperienceAutomationConditionTypeAsString {
    Equals = 'Equals',
    NotEquals = 'NotEquals',
    Contains = 'Contains',
    Null = 'Null',
    NotNull = 'NotNull',
    LessThan = 'LessThan',
    GreaterThan = 'GreaterThan',
    NotContains = 'NotContains'
}
