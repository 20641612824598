import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import { Observable } from 'rxjs';
import { SeaLine, Response } from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class SeaLineService {
  constructor(private http: HttpClient) { }

  list(): Observable<Response<SeaLine[]>> {
    return this.http.post<Response<SeaLine[]>>(environment.apiUrl + '/api/SeaLine/List', {});
  }
}
