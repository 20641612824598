import { Pipe, PipeTransform } from '@angular/core';
import { Service } from '@core/api';

@Pipe({
  name: 'inputOpportunityServices'
})
export class InputOpportunityServicesPipe implements PipeTransform {

  transform(items: Service[], filter: string[], active: string): Service[] {
    if (!items || !filter) {
      return items;
    }

    return active ? items.filter(item => filter.includes(item.serviceId) && item.serviceId === active || item.enabled === true)
      : items.filter(item => item.enabled === true);
  }

}
