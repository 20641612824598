import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from '@env/environment';
import {
  Initial,
} from '@core/api';

import './extensions';
import { AppModule } from './app/app.module';
import { MaintenanceModule } from './maintenance/maintenance.module';

if (environment.production) {
  enableProdMode();
}

(async () => {
  fetch(environment.apiUrl + '/api/Home/Initial', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(
    async (response) => {
      if (response.status === 200) {
        const result: Initial = await response.json();
        localStorage.setItem('system_setting', JSON.stringify(result ?? []));

        const setting = result?.googleAnalytics;
        localStorage.setItem('googleTagManagerId', setting ?? '@TODO');

        platformBrowserDynamic()
          .bootstrapModule(AppModule)
          .then((res) => {
            // if (environment.googleTagManagerId === '@TODO') {
            //   const errorLogService = res.injector.get(ErrorLogService);
            //   const clientIpService = res.injector.get(ClientIpService);
            //   const user: User = JSON.parse(AuthService.getUser());
            //   clientIpService.getIpAddress().then(ip => {
            //     const request: ErrorLogRequest = {
            //       code: 'FE',
            //       exceptionMessage: 'Google tag manager ID not provided.',
            //       innerExceptionMessage: 'Error: Google tag manager ID not provided. at new GoogleTagManagerService',
            //       params: window.location.href,
            //       userId: user ? user.userId : null,
            //       userName: user ? user.userName : null,
            //       ipAddress: ip,
            //       actionName: window.navigator.userAgent
            //     };
            //     errorLogService.insert(request)
            //     .subscribe(() => {});
            //   }).finally(() => {});
            // }
          })
          .catch((err) => console.error(err));
      }
    },
    async (error) => {
      document.getElementById('net-splash-screen').style.display = 'none';

      platformBrowserDynamic()
        .bootstrapModule(MaintenanceModule)
        .catch((err) => console.error(err));
    }
  );
})();
