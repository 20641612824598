<ng-container *ngIf="systemUser; else loading">
    <ng-container *ngIf="!systemUser.systemUserPicture || !systemUser.systemUserPicture.pictureId || 
        (systemUser.systemUserPicture?.pictureId && systemUser.systemUserPicture.pictureId === guid.EMPTY)">
        <ng-container *ngTemplateOutlet="initialsPicture"></ng-container>
    </ng-container>
    <ng-container
        *ngIf="systemUser.systemUserPicture?.pictureId && systemUser.systemUserPicture?.pictureId !== guid.EMPTY">
        <ng-container *ngIf="getImageUrl()">
            <img [class]="externalClass ? externalClass:'rounded-full'" [width]="width" [height]="height"
                [src]="getImageUrl()" id="profile-photo-img">
        </ng-container>
    </ng-container>
</ng-container>

<ng-template #initialsPicture>
    <div [style.width]="width + 'px'" [style.height]="height + 'px'"
        [style.background-color]="systemUser.systemUserPicture?.backgroundColor ? systemUser.systemUserPicture.backgroundColor : '#9e9e9e'"
        [style.color]="systemUser.systemUserPicture?.textColor ? systemUser.systemUserPicture.textColor : '#ffffff'"
        [style.font-size]="fontSize + 'px'"
        class="{{externalClass}} rounded-full flex items-center justify-center font-medium">
        {{systemUser.firstName?.charAt(0) + systemUser.lastName?.charAt(0)}}
    </div>
</ng-template>

<ng-template #loading>
    <div [style.width]="width + 'px'" [style.height]="height + 'px'" [style.font-size]="fontSize + 'px'"
        [style.background-color]="externalBgColor ? externalBgColor : '#9e9e9e'" [style.color]="'#ffffff'"
        class="rounded-full flex items-center justify-center font-medium">
        {{ initials ? (initials | uppercase) : ''}}
    </div>
</ng-template>

<ng-content select="[channelInfo]"></ng-content>