import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import { Observable } from 'rxjs';

import { Response, ErrorLog, ErrorLogRequest } from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class ErrorLogService {
  constructor(private http: HttpClient) { }

  insert(request: ErrorLogRequest): Observable<Response<ErrorLog>> {
    return this.http.post<Response<ErrorLog>>(environment.apiUrl + '/api/ErrorLog/Insert', request);
  }
}
