import { RecentPage } from 'src/app/layout/toolbar/recent-pages-dropdown/recent-pages.model';
import { RecentPageActionTypes, RecentPageActions } from './recent-page.action';

export interface RecentPageState {
    recentPages: RecentPage[];
    isLoading: boolean;
    errorMessage: string;
    errorCode: string;
}

const initialState: RecentPageState = {
    recentPages: [],
    errorCode: null,
    errorMessage: null,
    isLoading: false
};

export function recentPageReducer(state: RecentPageState = initialState, actions: RecentPageActions) {
    switch (actions.type) {
        case RecentPageActionTypes.GET_RECENT_PAGE:
            return {
                ...state,
                recentPages: null,
                errorCode: null,
                errorMessage: null,
                isLoading: true
            };
        case RecentPageActionTypes.GET_RECENT_PAGE_SUCCESS:
            return {
                ...state,
                recentPages: actions.payload,
                errorCode: null,
                errorMessage: null,
                isLoading: false
            };
        case RecentPageActionTypes.GET_RECENT_PAGE_ERROR:
            return {
                ...state,
                recentPages: null,
                errorCode: actions.payload.errorCode,
                errorMessage: actions.payload.message,
                isLoading: true
            };
        default:
            return state;
    }
}
