import { Board, BoardHashtag, BoardInfo, BoardSystemUser } from '@core/api';

export enum BoardDetailActionTypes {
  SET_BOARD_DETAIL_HASHTAG = '[Board Detail] SET_BOARD_DETAIL_HASHTAG',
  SET_BOARD_DETAIL_SYSTEM_USER = '[Board Detail] SET_BOARD_DETAIL_SYSTEM_USER',
  SET_BOARD_DETAIL_BOARD_INFO = '[Board Detail] SET_BOARD_DETAIL_BOARD_INFO',
  SET_BOARD_DETAIL = '[Board Detail] SET_BOARD_DETAIL'
}

export class SetBoardDetail {
  readonly type = BoardDetailActionTypes.SET_BOARD_DETAIL;
  constructor(public board: Board) { }
}

export class SetBoardDetailBoardInfo {
  readonly type = BoardDetailActionTypes.SET_BOARD_DETAIL_BOARD_INFO;
  constructor(public boardInfos: BoardInfo[]) { }
}

export class SetBoardDetailSystemUser {
  readonly type = BoardDetailActionTypes.SET_BOARD_DETAIL_SYSTEM_USER;
  constructor(public boardSystemUsers: BoardSystemUser[]) { }
}

export class SetBoardDetailHashtag {
  readonly type = BoardDetailActionTypes.SET_BOARD_DETAIL_HASHTAG;
  constructor(public boardHashtags: BoardHashtag[]) { }
}

export type BoardDetailActions =
  SetBoardDetailHashtag |
  SetBoardDetailSystemUser |
  SetBoardDetailBoardInfo |
  SetBoardDetail;
