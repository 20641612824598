
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Country, Offer, OfferFilterRequest, PagedResponse, Response, SystemUser } from '@core/api';
import { AuthService } from '@core/auth/auth.service';
import { environment } from '@env/environment';

import { openFileUrl } from '../../../shared/utils/open-file-url';
import { OfferDownload, OfferUpdateStatusRequest } from './offer.model';

@Injectable({
  providedIn: 'root'
})
export class OfferService {
  constructor(private http: HttpClient) { }

  get(uuid: string): Observable<Response<Offer>> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<Offer>>(environment.apiUrl + '/api/Offer/Get', JSON.stringify(uuid), { headers }).pipe(
      tap((response) => {
        response.data = this.offerTransformer(response.data);

        return response;
      })
    );
  }

  save(request: any) {
    return this.http.post<Response<Offer>>(environment.apiUrl + '/api/Offer/Insert', request).pipe(
      switchMap(response => {
        if (response.success) {
          return this.get(response.data.offerId);
        }

        return of(response);
      })
    );
  }

  update(request: any) {
    return this.http.post<Response<Offer>>(environment.apiUrl + '/api/Offer/Update', request).pipe(
      switchMap(response => {
        if (response.success) {
          return this.get(response.data.offerId);
        }

        return of(response);
      })
    );
  }

  search(request: OfferFilterRequest) {
    return this.http.post<PagedResponse<Offer>>(environment.apiUrl + '/api/Offer/Search', request).pipe(
      tap(response => response.data.results.forEach(offer => this.offerTransformer(offer)))
    );
  }

  exportExcel(request: OfferFilterRequest) {
    return this.http.post<Response<OfferDownload[]>>(environment.apiUrl + '/api/Offer/Download', request.filter);
  }

  download(offer: Offer, type: string = 'preview', culture: string, fileWindow?: Window): Promise<boolean> {
    return openFileUrl(
      environment.apiUrl + `/api/Offer/ShowPdf?id=${offer.offerId}&culture=${culture}`,
      { Authorization: 'Bearer ' + AuthService.getToken() },
      type,
      `NETLOG-${offer.code}`,
      fileWindow
    );
  }

  updateStatus(request: OfferUpdateStatusRequest) {
    return this.http.post<Response<Offer>>(environment.apiUrl + '/api/Offer/UpdateStatus', request).pipe(
      switchMap(response => {
        if (response.success) {
          return this.get(response.data.offerId);
        }

        return of(response);
      })
    );
  }

  listCountries(origin: boolean, params?: HttpParams | {[param: string]: string | string[]}) {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<Country[]>>(environment.apiUrl + '/api/Offer/ListCountries', JSON.stringify(origin), {params, headers}).pipe(
      tap((response) => {
        return response;
      })
    );
  }

  listCreatedUsers(params?: HttpParams | {[param: string]: string | string[]}) {
    return this.http.post<Response<SystemUser[]>>(environment.apiUrl + '/api/Offer/ListCreatedUsers', {}, {params}).pipe(
      tap((response) => {
        return response;
      })
    );
  }

  addOption(offerId: string): Observable<Response<Offer>> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<Offer>>(environment.apiUrl + '/api/Offer/AddOption', JSON.stringify(offerId), { headers }).pipe(
      tap((response) => {
        response.data = this.offerTransformer(response.data);

        return response;
      })
    );
  }

  offerTransformer(offer: Offer): Offer {
    ['createdOn', 'offerExpireDate'].forEach((key) => {
      const date = moment(offer[key]);
      offer[key] = date.isValid() ? date : null;
    });

    return offer;
  }
}
