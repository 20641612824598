import { GoogleTagManagerService } from 'angular-google-tag-manager';
import moment from 'moment';
import { take } from 'rxjs/operators';

import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit, Renderer2, AfterViewInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ErrorLogRequest, ErrorLogService, NotificationService, NotificationSocketResponse, SystemSettingType, User } from '@core/api';
import { AuthService } from '@core/auth/auth.service';
import {
  GetNotification,
  GetMailUnreadCount,
  GetSystemSettingSuccess,
  getSystemSettingValue,
  getUser,
  IncreaseNotificationCount,
  GetMailUnreadCountSuccess
} from '@core/store';
import { SWService } from '@core/sw/sw.service';
import { Store } from '@ngrx/store';

import { SplashScreenService } from './layout/services/splash-screen.service';
import MaterialRequiredPatch from './shared/utils/material-required-patch';
import { DEFAULT_LOCALE } from './translation.config';
import packageJson from '../../package.json';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { environment } from '@env/environment';
import { ClientIpService } from '@core/api/client-ip/client-ip.service';
import { NotificationSignalRService } from './notification/signal-r/notification-signal-r.service';
import { of } from 'rxjs';

@Component({
  selector: 'net-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  user: User;
  interval: NodeJS.Timeout;
  favIcon: HTMLLinkElement;
  logoUrl: string;

  version: string = packageJson.version;
  hubConnection: signalR.HubConnection;

  constructor(
    private iconRegistry: MatIconRegistry,
    private renderer: Renderer2,
    private platform: Platform,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string,
    private router: Router,
    // private gtmService: GoogleTagManagerService,
    private splashScreenService: SplashScreenService,
    private swService: SWService,
    private auth: AuthService,
    private notificationService: NotificationService,
    private _snackBar: MatSnackBar,
    private titleService: Title,
    private dateAdapter: DateAdapter<Date>,
    private store: Store,
    private injector: Injector,
    private notificationSignalrService: NotificationSignalRService
  ) {
    console.info(`v${this.version}`);

    this.store.dispatch(new GetSystemSettingSuccess(JSON.parse(localStorage.getItem('system_setting'))));
    this.setSystemSettings();

    this.iconRegistry.setDefaultFontSetClass('iconify');

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    // Update GTM userID when user change
    this.store.select(getUser).pipe(take(1)).subscribe((user: User) => {
      // if ((this.interval && user) || !user) {
      //   clearInterval(this.interval);
      // }

      if (user) {
        this.user = user;
        const userId = user ? user.userId + '-' + user.userName : null;
        if (localStorage.getItem('googleTagManagerId') !== '@TODO') {
          this.requestGTM(userId);
        } else {
          this.writeGTMErrorMessage();
        }

        this.setLibraryLanguages(user.culture);

        // this.notificationListener(user);
        // this.interval = setInterval(() => {
        //   this.notificationListener(user);
        // }, 60 * 1000);

        // Get mail unread count
        this.store.dispatch(new GetMailUnreadCount());
      }
    });

  }

  ngOnInit() {
    MaterialRequiredPatch();
  }

  ngAfterViewInit() {
    this.store.select(getUser).subscribe((user: User) => {
      if (user && !this.hubConnection) {
        this.notificationSignalrService.startConnection();


        this.hubConnection = this.notificationSignalrService.getHubConnection();

        this.hubConnection.on('/notification', (notification: NotificationSocketResponse) => {
          if (notification) { this.store.dispatch(new GetNotification()); }
        });

        this.hubConnection.on('/mailintegration', (count: number) => {
          if (count) { this.store.dispatch(new GetMailUnreadCountSuccess(count)); }
        });
      }
    });
  }

  notificationListener(user: User) {
    this.store.dispatch(new GetNotification());
  }

  setLibraryLanguages(culture: string) {
    const locale = culture.slice(0, 2) || DEFAULT_LOCALE;

    this.dateAdapter.setLocale(locale);
    moment.locale(locale);
  }

  setSystemSettings() {
    this.favIcon = document.querySelector('#appIcon');

    this.store.select(getSystemSettingValue(SystemSettingType.FAVICON)).subscribe(result => {
      this.favIcon.href = `${window.location.origin}${result as string}`;
    });

    this.store.select(getSystemSettingValue(SystemSettingType.TITLE)).subscribe(result => {
      this.titleService.setTitle(result as string);
    });

    this.store.select(getSystemSettingValue(SystemSettingType.LOGO_LIGHT)).subscribe(result => {
      this.logoUrl = result as string;
    });
  }

  requestGTM(userId) {
    const gtmService: GoogleTagManagerService = this.injector.get(GoogleTagManagerService);
    gtmService.getDataLayer().push({ userId });

    // Add GTM script to dom
    gtmService.addGtmToDom().catch(() => of());
    gtmService.pushTag({
      userId
    }).catch(() => of());
  }

  writeGTMErrorMessage(errorMessage?: string) {
    const errorLogService = this.injector.get(ErrorLogService);
    const clientIpService = this.injector.get(ClientIpService);
    const browserInfo = navigator['userAgentData']?.brands?.map(browser => {
      return `(${browser.brand} - ${browser.version})`;
    });

    const platform = navigator['userAgentData']?.platform;

    clientIpService.getIpAddress().then(ip => {

      const request: ErrorLogRequest = {
        code: 'FE',
        exceptionMessage: 'Google tag manager ID not provided.',
        innerExceptionMessage: errorMessage ?? 'Error: Google tag manager ID not provided. at new GoogleTagManagerService',
        params: window.location.href,
        userId: this.user ? this.user.userId : null,
        userName: this.user ? this.user.userName : null,
        ipAddress: ip,
        actionName: `${platform} - ${browserInfo.join(' - ')}`,
        result: packageJson.version
      };
      errorLogService.insert(request).subscribe(() => { });
    }).finally(() => { });
  }

}
