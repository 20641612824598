
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

import { Response, AccountOrganizationCreditLimit, UpdateCreditLimitRequest } from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class AccountOrganizationCreditLimitService {

  constructor(private http: HttpClient) { }

  updateCreditLimit(request: UpdateCreditLimitRequest): Observable<Response<AccountOrganizationCreditLimit>> {
    return this.http.post<Response<AccountOrganizationCreditLimit>>(environment.apiUrl + '/api/AccountOrganizationCreditLimit/UpdateCreditLimit', request);
  }
}
