import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import icClose from '@iconify/icons-ic/twotone-close';

import { AlertDialogData } from './alert-dialog.component.model';

@Component({
  selector: 'net-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent {

  icClose = icClose;

  constructor(@Inject(MAT_DIALOG_DATA) public data: AlertDialogData, public dialogRef: MatDialogRef<AlertDialogComponent>) {
    this.dialogRef.addPanelClass('alert-dialog-pane');
  }

}
