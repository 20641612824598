import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, DoCheck, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl, UntypedFormControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { Warning } from '@core/api/warning/warning.model';
import { WarningService } from '@core/api/warning/warning.service';
import { Subject } from 'rxjs';
import { replaceTurkishCharacters } from 'src/app/shared/utils/replace-turkish-characters';

@Component({
  selector: 'net-multi-select-survey-mail-warning',
  templateUrl: './multi-select-survey-mail-warning.component.html',
  styleUrls: ['./multi-select-survey-mail-warning.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: MultiSelectSurveyMailWarningComponent
    }
  ]
})
export class MultiSelectSurveyMailWarningComponent implements OnInit, OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<string[]>  {

  static nextId = 0;
  stateChanges = new Subject<void>();
  selectCtrl = new UntypedFormControl();
  focused = false;
  errorState = false;
  describedBy = '';
  controlType = 'multi-select-warning';
  searchText = '';


  warnings: Warning[] = [];
  filteredWarnings: Warning[] = [];

  @Input() isDisabled = false;
  @Input() tabIndex;
  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    if (value) {
      this.selectCtrl.disable();
    } else {
      this.selectCtrl.enable();
    }
    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): string[] { return this.selectCtrl.value; }
  set value(value: string[]) {
    this.selectCtrl.setValue(value);
    this.onChange(value);
    this.stateChanges.next();
  }

  @ViewChild('warningSelect') warningSelect: MatSelect;
  @HostBinding('id') id = `multi-select-warning-${MultiSelectSurveyMailWarningComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  get shouldLabelFloat() { return this.focused || !this.empty; }

  get empty() { return !this.value; }


  constructor(
    private warningService: WarningService,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl
  ) {
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    this.getWarnings();
  }

  ngOnInit(): void {
  }


  getWarnings() {
    if (!this.warnings.length) {
      this.warningService.search({
        orderBy: 'name',
        orderType: 'ASC',
        filter: {
          isActive: true
        }
      }).subscribe((response) => {
        this.warnings = response.data.results;
        this.filteredWarnings = response.data.results;
      });
    }
  }
  getToolTip() {
    if (this.warnings.length > 0) {
      return this.value.map(x => this.warnings.find(y => y.warningId === x).name).join('\n');
    }
  }

  onSelectionChange() {
    this.onChange(this.value);
    this.stateChanges.next();
  }

  openedChange(isOpened: boolean) {
    if (isOpened) {
      const panel = this.warningSelect.panel.nativeElement;
      panel.scrollTop = 0;
    }
  }

  selectAll() {

    const allFilteredWarnings = this.filteredWarnings.map(s => s.warningId);
    this.selectCtrl.patchValue(allFilteredWarnings);
    this.value = allFilteredWarnings;

  }

  deselectAll() {

    this.selectCtrl.patchValue(null);
    this.value = [];
  }

  onKey(event) {
    const selectedWarning = this.warnings.filter(warning => this.value.includes(warning.warningId));
    this.filteredWarnings = [...new Set([...this.search(this.warnings, 'name', event.target.value), ...selectedWarning])];

  }

  search(searchList: any[], searchKey: string, searchText: string) {
    const filter = replaceTurkishCharacters(searchText).toUpperCase();
    return searchList?.filter(option => option[searchKey].toUpperCase().includes(filter));
  }


  compareWith = (one: string, two: string) => one && two && one === two;
  onChange = (_: any) => { };
  selectionChange = (_: any) => { };
  onTouched = () => { };
  onContainerClick = () => { };
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');
  writeValue = (value: string[]) => {
    this.value = value;
    this.selectCtrl.setValue(value);
  }

  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }
}
