import { Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[netScrollDirective]'
})
export class ScrollDirective {
    previousScrollHeightMinusTop: number; // the variable which stores the distance
    readyFor: string;
    toReset = false;

    constructor(public elementRef: ElementRef) {
        this.previousScrollHeightMinusTop = 0;
        this.readyFor = 'up';
        this.restore();
    }

    reset(scrollPos?: number) {
        this.previousScrollHeightMinusTop = 0;
        this.readyFor = 'up';
        const minus = this.elementRef.nativeElement.scrollHeight * (scrollPos / 100);
        this.elementRef.nativeElement.scrollTop = scrollPos ? this.elementRef.nativeElement.scrollHeight - minus : this.elementRef.nativeElement.scrollHeight;
        // resetting the scroll position to bottom because that is where chats start.
    }

    restore() {
        if (this.toReset) {
            if (this.readyFor === 'up') {
                // restoring the scroll position to the one stored earlier
                this.elementRef.nativeElement.scrollTop = this.elementRef.nativeElement.scrollHeight - this.previousScrollHeightMinusTop;
            } else {
                this.elementRef.nativeElement.scrollTop = this.previousScrollHeightMinusTop;
            }
            this.toReset = false;
        }
    }


    prepareFor(direction: 'down' | 'up') {
        this.toReset = true;
        this.readyFor = direction || 'up';
        this.elementRef.nativeElement.scrollTop = !this.elementRef.nativeElement.scrollTop // check for scrollTop is zero or not
            ? this.elementRef.nativeElement.scrollTop + 1
            : this.elementRef.nativeElement.scrollTop;
        this.previousScrollHeightMinusTop =
            this.elementRef.nativeElement.scrollHeight - this.elementRef.nativeElement.scrollTop;
        // the current position is stored before new messages are loaded
    }
}
