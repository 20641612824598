import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

import { FilterRequest, Response, PagedResponse, SalesRouteLevel, SalesRouteLevelRequest } from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class SalesRouteLevelService {
  constructor(private http: HttpClient) { }

  search(request?: FilterRequest): Observable<PagedResponse<SalesRouteLevel>>  {
    return this.http.post<PagedResponse<SalesRouteLevel>>(environment.apiUrl + '/api/SalesRouteLevel/Search', request);
  }

  update(request: SalesRouteLevelRequest): Promise<Response<SalesRouteLevel>> {
    return this.http.post<Response<SalesRouteLevel>>(environment.apiUrl + '/api/SalesRouteLevel/Update', request).toPromise();
  }

  insert(request: SalesRouteLevelRequest): Promise<Response<SalesRouteLevel>> {
    return this.http.post<Response<SalesRouteLevel>>(environment.apiUrl + '/api/SalesRouteLevel/Insert', request).toPromise();
  }

  delete(request: SalesRouteLevelRequest): Promise<Response<SalesRouteLevel>> {
    return this.http.post<Response<SalesRouteLevel>>(environment.apiUrl + '/api/SalesRouteLevel/Delete', request).toPromise();
  }
}
