import { Component, Input, ElementRef, OnDestroy, DoCheck, HostBinding, Optional, Self, ChangeDetectorRef } from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { FocusMonitor } from '@angular/cdk/a11y';
import { SystemUserService, SystemUser } from '@core/api';

@Component({
  selector: 'net-multi-select-system-user[salesOrganizationId]',
  templateUrl: './multi-select-system-user.component.html',
  styleUrls: ['./multi-select-system-user.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: MultiSelectSystemUserComponent
    }
  ]
})
export class MultiSelectSystemUserComponent implements OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<SystemUser[]> {

  static nextId = 0;

  loading = false;
  focused = false;
  errorState = false;
  controlType = 'multi-select-system-user';
  describedBy = '';
  stateChanges = new Subject<void>();

  systemUsers: SystemUser[];
  selectCtrl = new UntypedFormControl();

  @Input() tabIndex;

  @Input() defaultSelectedUserIds: string[];

  @Input()
  get salesOrganizationId(): string { return this._salesOrganizationId; }
  set salesOrganizationId(value: string) {
    this._salesOrganizationId = value;

    if (value) {
      this.loading = true;
      this.systemUsers = [];
      this.selectCtrl.disable();

      this.initDefaultOptions().finally(() => {
        if (this.defaultSelectedUserIds && this.defaultSelectedUserIds.length > 0) {

          this.value = this.systemUsers.filter(user => this.defaultSelectedUserIds?.includes(user.systemUserId));
        }

        this.onSelectionChange();

        if (!this.disabled) {
          this.selectCtrl.enable();
        }

        this.loading = false;
        this.cdr.detectChanges();
      });
    }
    this.stateChanges.next();
    this.cdr.detectChanges();
  }
  private _salesOrganizationId: string;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    if (value) {
      this.selectCtrl.disable();
    } else {
      this.selectCtrl.enable();
    }
    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): SystemUser[] { return this.selectCtrl.value; }
  set value(value: SystemUser[]) {
    this.selectCtrl.setValue(value);
    this.onChange(value);
    this.stateChanges.next();
  }

  @HostBinding('id') id = `multi-select-system-user-${MultiSelectSystemUserComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  get shouldLabelFloat() { return this.focused || !this.empty; }

  get empty() { return !this.value; }

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private systemUserService: SystemUserService,
    private cdr: ChangeDetectorRef,
    @Optional() @Self() public ngControl: NgControl
  ) {
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  private initDefaultOptions(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.systemUserService.userSearch({ filter: { salesOrganizationId: this.salesOrganizationId }, pageSize: 100 })
        .toPromise()
        .then((response) => {
          this.systemUsers = response.data.results;
        })
        .finally(() => resolve());
    });
  }

  onSelectionChange() {
    this.onChange(this.value);
    this.selectionChange(this.value);
    this.stateChanges.next();
  }

  selectAll() {
    this.selectCtrl.patchValue(this.systemUsers);
    this.onSelectionChange();
  }

  deselectAll() {
    this.selectCtrl.patchValue([]);
    this.onSelectionChange();
  }

  onChange = (_: any) => { };
  selectionChange = (_: any) => { };
  onTouched = () => { };
  onContainerClick = () => { };
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');
  writeValue = (value: SystemUser[]) => {
    this.value = value;
    this.selectCtrl.setValue(value);
  }

  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }
}
