import { Component, DoCheck, ElementRef, HostBinding, Input, OnDestroy, Optional, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatFormFieldControl } from '@angular/material/form-field';

import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';

import { ApiService, Country, CountryUUID, Custom } from '@core/api';

@Component({
  selector: 'net-select-custom',
  templateUrl: './select-custom.component.html',
  styleUrls: ['./select-custom.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: SelectCustomComponent
    }
  ]
})
export class SelectCustomComponent implements OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<Custom | string> {

  static nextId = 0;

  loading = false;
  focused = false;
  textMode = false;
  errorState = false;
  controlType = 'select-custom';
  describedBy = '';
  stateChanges = new Subject<void>();

  customs: Custom[] = [];
  inputCtrl = new UntypedFormControl();

  @ViewChild('auto') auto: MatAutocomplete;
  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  @Input() tabIndex;

  @Input()
  get country() { return this._country; }
  set country(country: Country) {
    if (this._country?.countryId !== country?.countryId) {
      this.reset(!!this._country && !!country);
    }

    this._country = country;
    if (country) {
      // Load custom list when country Turkey
      if (country.countryId === CountryUUID.TURKEY) {
        this.loading = true;
        this.inputCtrl.disable();
        this.initDefaultOptions().finally(() => {
          this.loading = false;
          if (!this.disabled) {
            this.inputCtrl.enable();
          }
        });
      }

      // If country is not Turkey turn input mode
      this.textMode = country.countryId !== CountryUUID.TURKEY;
    } else {
      this.textMode = false;
    }
  }
  private _country;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    if (value) {
      this.inputCtrl.disable();
    } else {
      this.inputCtrl.enable();
    }

    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): Custom | string { return this._value; }
  set value(value: Custom | string) {
    this._value = value;
    this.onChange(value);
    this.stateChanges.next();
  }
  private _value: Custom | string;

  @HostBinding('id') id = `select-custom-${SelectCustomComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  get shouldLabelFloat() { return this.focused || !this.empty; }

  get empty() { return !this.value; }

  private initDefaultOptions(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.api.customs.list({
        filter: { countryId: this.country.countryId },
        orderBy: 'name',
        orderType: 'ASC',
        pageSize: 100
      }).toPromise()
        .then(response => this.customs = response.data.results)
        .finally(() => resolve());
    });
  }

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private api: ApiService,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Material form field implementation
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    // Listen input controller for text mode and update value
    this.inputCtrl.valueChanges.pipe(filter(() => this.textMode)).subscribe(value => this.value = value);

    // Listen input controller for select state and filter results
    this.inputCtrl.valueChanges.pipe(
      filter(() => !this.textMode),
      debounceTime(400),
      distinctUntilChanged(),
      filter(value => typeof value === 'string'),
      tap(() => {
        this.loading = true;
        this.customs = [];
        this.value = null;
      }),
      switchMap(value => this.api.customs.list({
        filter: {
          countryId: this.country.countryId,
          name: value
        },
        pageSize: 20
      })),
      tap(() => this.loading = false)
    ).subscribe(response => this.customs = response.data.results);
  }

  displayName = (custom: Custom) => custom ? custom.name : '';

  reset(force = false) {
    if (!force && this.inputCtrl.pristine) {
      return;
    }

    this.value = null;
    this.customs = [];
    this.inputCtrl.setValue(null);
    this.inputCtrl.markAsPristine();

    if (!force) {
      this.initDefaultOptions();
    }
  }

  onSelect(event: MatAutocompleteSelectedEvent) {
    this.value = event.option.value;
    this.input.nativeElement?.blur();
  }

  onBlur() {
    // On blur with nothing selected reset component
    if (this.ngControl.dirty && !this.auto.isOpen && !this.value) {
      this.reset();
    }
  }

  onClosed() {
    // On panel close with nothing selected reset component
    if (this.ngControl.dirty && !this.value && !this.loading && this.customs.length !== 0) {
      this.reset();
    }
  }

  onChange = (_: any) => { };
  onTouched = () => { };
  onContainerClick = () => { };
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');

  writeValue(value: Custom | string) {
    this.value = value;
    this.textMode = !!value && typeof value === 'string';
    this.inputCtrl.setValue(value);
  }

  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }
}
