import { InitialSetting, Response } from '@core/api';
import { Action } from '@ngrx/store';

export enum SalesOrganizationSettingActionTypes {
    GET_SALES_ORGANIZATION_SETTING = '[Sales Organization Setting] GET_SALES_ORGANIZATION_SETTING',
    GET_SALES_ORGANIZATION_SETTING_SUCCESS = '[Sales Organization Setting] GET_SALES_ORGANIZATION_SETTING_SUCCESS',
    GET_SALES_ORGANIZATION_SETTING_ERROR = '[Sales Organization Setting] GET_SALES_ORGANIZATION_SETTING_ERROR',
    GET_SALES_ORGANIZATION_SETTING_CLEAR = '[Sales Organization Setting] GET_SALES_ORGANIZATION_SETTING_CLEAR'
}

export class GetSalesOrganizationSetting implements Action {
    readonly type = SalesOrganizationSettingActionTypes.GET_SALES_ORGANIZATION_SETTING;
}

export class GetSalesOrganizationSettingSuccess implements Action {
    readonly type = SalesOrganizationSettingActionTypes.GET_SALES_ORGANIZATION_SETTING_SUCCESS;
    constructor(public payload: InitialSetting[]) { }
}

export class GetSalesOrganizationSettingError implements Action {
    readonly type = SalesOrganizationSettingActionTypes.GET_SALES_ORGANIZATION_SETTING_ERROR;
    constructor(public payload: Response<null>) { }
}

export class GetSalesOrganizationSettingClear implements Action {
    readonly type = SalesOrganizationSettingActionTypes.GET_SALES_ORGANIZATION_SETTING_CLEAR;
}

export type SalesOrganizationSettingActions =
    GetSalesOrganizationSetting |
    GetSalesOrganizationSettingSuccess |
    GetSalesOrganizationSettingError |
    GetSalesOrganizationSettingClear;
