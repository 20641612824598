import { DynamicField, DynamicFieldFilterRequest, Response } from '@core/api';
import { Action } from '@ngrx/store';

export enum CustomListActionTypes {
    GET_CUSTOM_LIST = '[System Setting] GET_CUSTOM_LIST',
    GET_CUSTOM_LIST_SUCCESS = '[System Setting] GET_CUSTOM_LIST_SUCCESS',
    GET_CUSTOM_LIST_ERROR = '[System Setting] GET_CUSTOM_LIST_ERROR'
}

export class GetCustomList implements Action {
    readonly type = CustomListActionTypes.GET_CUSTOM_LIST;
    constructor(public payload: DynamicFieldFilterRequest) { }
}

export class GetCustomListSuccess implements Action {
    readonly type = CustomListActionTypes.GET_CUSTOM_LIST_SUCCESS;
    constructor(public payload: DynamicField[]) { }
}

export class GetCustomListError implements Action {
    readonly type = CustomListActionTypes.GET_CUSTOM_LIST_ERROR;
    constructor(public payload: Response<null>) { }
}

export type CustomListActions =
    GetCustomList |
    GetCustomListSuccess |
    GetCustomListError;
