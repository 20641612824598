import { NgModule } from '@angular/core';
import { PpBreadcrumbsModule } from 'pp-breadcrumbs';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { SharedModule } from '../shared/shared.module';
import { NavigationComponent } from './navigation/navigation.component';
import { NavigationItemComponent } from './navigation/navigation-item/navigation-item.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { SidenavItemComponent } from './sidenav/sidenav-item/sidenav-item.component';
import { FooterComponent } from './footer/footer.component';
import { SearchComponent } from './search/search.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { ToolbarNotificationsComponent } from './toolbar/toolbar-notifications/toolbar-notifications.component';
import {
  ToolbarNotificationsDropdownComponent
} from './toolbar/toolbar-notifications/toolbar-notifications-dropdown/toolbar-notifications-dropdown.component';
import { ToolbarUserComponent } from './toolbar/toolbar-user/toolbar-user.component';
import {
  ToolbarUserDropdownComponent
} from './toolbar/toolbar-user/toolbar-user-dropdown/toolbar-user-dropdown.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SetStyleComponent } from './set-style/set-style.component';
import { HotkeyDialogComponent } from './hotkey-dialog/hotkey-dialog.component';
import { SecondaryToolbarComponent } from './secondary-toolbar/secondary-toolbar.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { BreadcrumbComponent } from './breadcrumbs/breadcrumb/breadcrumb.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { GuestLayoutComponent } from './guest-layout/guest-layout.component';
import { MatIconModule } from '@angular/material/icon';
import {
  LeadDetailQuickActionDropdownComponent
} from '../lead/lead-detail/lead-detail-quick-action/lead-detail-quick-action-dropdown/lead-detail-quick-action-dropdown.component';
import {
  ToolbarUserChangePasswordComponent
} from './toolbar/toolbar-user/toolbar-user-change-password/toolbar-user-change-password.component';
import { RecentPagesDropdownComponent } from './toolbar/recent-pages-dropdown/recent-pages-dropdown.component';
import { MailPreviewComponent } from './mail-preview/mail-preview.component';
import { CustomerExperienceTicketPreviewComponent } from './customer-experience-ticket-preview/customer-experience-ticket-preview.component';

@NgModule({
  declarations: [
    NavigationComponent,
    NavigationItemComponent,
    SidenavComponent,
    SidenavItemComponent,
    FooterComponent,
    SearchComponent,
    ToolbarComponent,
    ToolbarNotificationsComponent,
    ToolbarNotificationsDropdownComponent,
    ToolbarUserComponent,
    ToolbarUserDropdownComponent,
    ProgressBarComponent,
    SidebarComponent,
    SetStyleComponent,
    HotkeyDialogComponent,
    SecondaryToolbarComponent,
    BreadcrumbsComponent,
    BreadcrumbComponent,
    MainLayoutComponent,
    GuestLayoutComponent,
    LeadDetailQuickActionDropdownComponent,
    ToolbarUserChangePasswordComponent,
    RecentPagesDropdownComponent,
    MailPreviewComponent,
    CustomerExperienceTicketPreviewComponent
  ],
  imports: [
    SharedModule,
    MatIconModule,
    MatProgressSpinnerModule
  ],
  exports: [
    MatProgressSpinnerModule
  ]
})
export class LayoutModule {
}
