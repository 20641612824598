<input
  #input
  (blur)="onBlur()"
  [formControl]="inputCtrl"
  [matAutocomplete]="auto"
  [readonly]="isDisabled"
  [tabIndex]="tabIndex"
  matInput>
<mat-autocomplete
  #auto="matAutocomplete"
  (closed)="onClosed()"
  (optionSelected)="onSelect($event)"
  [autoActiveFirstOption]="true"
  [displayWith]="displayName">
  <ng-container *ngIf="this.users">
    <mat-option *ngFor="let user of users" [disabled]="isDisabled"
                [value]="user">{{ user.firstName }} {{ user.lastName }}</mat-option>
  </ng-container>
</mat-autocomplete>
<mat-progress-spinner *ngIf="loading === true" diameter="16" mode="indeterminate"></mat-progress-spinner>
