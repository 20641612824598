import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';

import { Observable } from 'rxjs';

import {
  Response,
  PagedResponse,
  FilterRequest,
  Service,
  ServiceRequest,
  ServiceFilterRequest,
} from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  constructor(private http: HttpClient) { }

  search(request: ServiceFilterRequest): Observable<PagedResponse<Service>> {
    return this.http.post<PagedResponse<Service>>(environment.apiUrl + '/api/Service/Search', request);
  }

  list(request: FilterRequest): Observable<PagedResponse<Service>> {
    return this.http.post<PagedResponse<Service>>(environment.apiUrl + '/api/Service/List', request);
  }

  get(uuid: string): Observable<Response<Service>> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<Service>>(environment.apiUrl + '/api/Service/Get', JSON.stringify(uuid), { headers });
  }

  insert(request: ServiceRequest): Observable<Response<Service>> {
    return this.http.post<Response<Service>>(environment.apiUrl + '/api/Service/Insert', request);
  }

  update(request: ServiceRequest): Observable<Response<Service>> {
    return this.http.post<Response<Service>>(environment.apiUrl + '/api/Service/Update', request);
  }

  delete(request: Service): Observable<Response<Service>> {
    return this.http.post<Response<Service>>(environment.apiUrl + '/api/Service/Delete', request);
  }

  filterSearch(request: ServiceFilterRequest): Observable<Response<Service[]>> {
    return this.http.post<Response<Service[]>>(environment.apiUrl + '/api/Service/FilterSearch', request);
  }
}
