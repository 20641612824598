import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import { Response, FilterRequest, ContractType } from '@core/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContractTypeService {
  constructor(private http: HttpClient) { }

  list(request: FilterRequest): Observable<Response<ContractType[]>> {
    return this.http.post<Response<ContractType[]>>(environment.apiUrl + '/api/ContractType/List', request);
  }
}
