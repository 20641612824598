
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

import {
  Response,
  PagedResponse,
  Notification,
  NotificationSearchRequest,
  NotificationRequest,
  ActionNeededCounts
} from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private http: HttpClient) { }

  search(request: any, ignoreLoader?: boolean): Observable<PagedResponse<Notification>> {
    let headers;
    if (ignoreLoader) {
      headers = { ignoreLoadingBar: '' };
    }
    return this.http.post<PagedResponse<Notification>>(environment.apiUrl + '/api/Notification/Search', request, { headers });
  }

  getActionNeededCounts(ignoreLoader?: boolean): Observable<Response<ActionNeededCounts>> {
    let headers;
    if (ignoreLoader) {
      headers = { ignoreLoadingBar: '' };
    }
    return this.http.post<Response<ActionNeededCounts>>(environment.apiUrl + '/api/Notification/GetActionNeededCounts', {}, { headers });
  }

  read(notificationId: string, sendedUserId: string): Observable<Response<Notification>> {
    return this.http.post<Response<Notification>>(environment.apiUrl + '/api/Notification/Read',
      { notificationId, sendedUserId });
  }

  listCreatedUsers(): Observable<Response<any>>{
    return this.http.post<Response<any>>(environment.apiUrl + '/api/Notification/ListCreatedUsers', {});
  }

  readAll(){
    return this.http.post<Response<any>>(environment.apiUrl + '/api/Notification/ReadAll', {});
  }
}
