import { Board, BoardHashtag, BoardInfo, BoardSystemUser } from '@core/api';
import { BoardDetailActionTypes, BoardDetailActions } from './board-detail.action';

export interface BoardDetailState {
  boardHashtags: BoardHashtag[];
  boardSystemUsers: BoardSystemUser[];
  boardInfos: BoardInfo[];
  board: Board;
}

export const boardState: BoardDetailState = {
  boardHashtags: [],
  boardSystemUsers: [],
  boardInfos: [],
  board: null,
};

export function boardDetailReducer(state = boardState, action: BoardDetailActions): BoardDetailState {
  switch (action.type) {
    case BoardDetailActionTypes.SET_BOARD_DETAIL:
      return {
        ...state,
        board: action.board
      };
    case BoardDetailActionTypes.SET_BOARD_DETAIL_BOARD_INFO:
      return {
        ...state,
        boardInfos: [...action.boardInfos]
      };
    case BoardDetailActionTypes.SET_BOARD_DETAIL_HASHTAG:
      return {
        ...state,
        boardHashtags: action.boardHashtags
      };
    case BoardDetailActionTypes.SET_BOARD_DETAIL_SYSTEM_USER:
      return {
        ...state,
        boardSystemUsers: action.boardSystemUsers
      };
    default:
      return {
        ...state
      };
  }
}
