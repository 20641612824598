import { Observable } from 'rxjs';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BaseResponse, Customer, CustomerAccount, CustomerCheckNameRequest,
  CustomerFilterRequest, CustomerUpdateRequest, PagedResponse, Response
} from '@core/api';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  constructor(private http: HttpClient) { }

  search(request: CustomerFilterRequest): Observable<PagedResponse<Customer>> {
    return this.http.post<PagedResponse<Customer>>(environment.apiUrl + '/api/Customer/Search', request);
  }

  getCustomerAccounts(uuid: string, params?: HttpParams | { [param: string]: string | string[] }): Observable<Response<CustomerAccount>> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<CustomerAccount>>(environment.apiUrl + '/api/Customer/GetCustomerAccounts', JSON.stringify(uuid), { headers, params });
  }

  update(request: CustomerUpdateRequest): Observable<Response<Customer>> {
    return this.http.post<Response<Customer>>(environment.apiUrl + '/api/Customer/Update', request);
  }

  checkName(request: CustomerCheckNameRequest): Observable<BaseResponse> {
    return this.http.post<BaseResponse>(environment.apiUrl + '/api/Customer/CheckName', request);
  }
}
