<ng-container [formGroup]="form">
    <div class="input mt-3" fxLayout="column" formArrayName="boardLink">
        <div class="input-items" fxLayout="row" fxLayoutGap="24px" [formGroupName]="index"
            *ngFor="let control of getBoardLinkForm().controls; let index = index">

            <mat-form-field fxFlex="0 0 calc(32% - 25px)">
                <mat-select [placeholder]="'BOARD.LINK_TYPE' | translate" formControlName="cardObjectTypeId"
                    (selectionChange)="selectionChange(control)">
                    <ng-container *ngFor="let cardObjectType of cardObjectTypes">
                        <ng-container
                            *ngIf="{permission: cardObjectType.permission | async, selected: cardObjectType?.cardObjectTypeId === control.value.cardObjectTypeId} as o">
                            <mat-option *ngIf="o.permission || o.selected" [value]="cardObjectType.cardObjectTypeId">
                                {{cardObjectType.name}}
                            </mat-option>
                        </ng-container>
                    </ng-container>
                    <mat-option
                        *ngIf="!isVisibleOfferOption && cardObjectTypeOption.OFFER === control.value.cardObjectTypeId"
                        [value]="cardObjectTypeOption.OFFER">
                        {{'CardObjectType.Offer' | translate}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field fxFlex="0 0 calc(32% - 25px)"
                *ngIf="control.value.cardObjectTypeId && [cardObjectTypeOption.ACCOUNT, cardObjectTypeOption.OPPORTUNITY, cardObjectTypeOption.ACTIVITY, cardObjectTypeOption.OFFER].includes(control.value.cardObjectTypeId)">
                <mat-label>{{'GENERAL.CUSTOMER_NAME' | translate}}</mat-label>
                <net-select-account [click]="control.enabled" [panelWidth]="'auto'" formControlName="account"
                    (selectionChange)="accountSelectionChange(control); updateCardObjects(control.value.cardObjectTypeId)"></net-select-account>
            </mat-form-field>

            <mat-form-field fxFlex="0 0 calc(32% - 25px)"
                *ngIf="control.value.cardObjectTypeId && cardObjectTypeOption.LEAD_DRAFT === control.value.cardObjectTypeId">
                <mat-label>{{'GENERAL.CUSTOMER_NAME' | translate}}</mat-label>
                <net-input-lead-draft [panelWidth]="'auto'" (selectionChange)="updateCardObjects()"
                    formControlName="leadDraft">
                </net-input-lead-draft>
            </mat-form-field>

            <mat-form-field fxFlex="0 0 calc(32% - 25px)"
                *ngIf="control.value.cardObjectTypeId && cardObjectTypeOption.CONTACT === control.value.cardObjectTypeId">
                <mat-label>{{'GENERAL.CONTACT_NAME' | translate}}</mat-label>

                <net-input-contact [account]="control.value.account" [panelWidth]="'auto'"
                    (selectionChange)="updateCardObjects()" formControlName="contact">
                </net-input-contact>
            </mat-form-field>

            <mat-form-field fxFlex="0 0 calc(32% - 25px)"
                *ngIf="control.value.cardObjectTypeId && cardObjectTypeOption.OPPORTUNITY === control.value.cardObjectTypeId">
                <mat-label>{{'GENERAL.OPPORTUNITY' | translate}}</mat-label>
                <net-select-opportunity-searchable (optionSelected)="updateCardObjects()" formControlName="opportunity"
                    [account]="control.value.account" panelWidth="auto">
                </net-select-opportunity-searchable>
            </mat-form-field>

            <mat-form-field fxFlex="0 0 calc(32% - 25px)"
                *ngIf="control.value.cardObjectTypeId && cardObjectTypeOption.ACTIVITY === control.value.cardObjectTypeId">
                <mat-label>{{'GENERAL.ACTIVITY' | translate}}</mat-label>
                <net-select-activity formControlName="activity" (selectionChange)="updateCardObjects()"
                    [account]="control.value.account"></net-select-activity>
            </mat-form-field>

            <mat-form-field fxFlex="0 0 calc(32% - 25px)"
                *ngIf="control.value.cardObjectTypeId && cardObjectTypeOption.OFFER === control.value.cardObjectTypeId">
                <mat-label>{{'GENERAL.OFFER' | translate}}</mat-label>
                <net-select-offer-searchable formControlName="offer" (optionSelected)="updateCardObjects()"
                    [account]="control.value.account" panelWidth="auto">
                </net-select-offer-searchable>
            </mat-form-field>

            <div fxFlex="auto">
                <button *ngIf="isValidNavigate(control)" (click)="navigate(control.value)" type="button"
                    [matTooltip]="'GENERAL.OPEN_IN_NEW_TAB' | translate" class="navigation-button" mat-icon-button
                    [disabled]="disabled">
                    <ic-icon [icon]="icLink" size="15px"></ic-icon>
                </button>

                <button *ngIf="isValidDialog(control)" type="button"
                    [matTooltip]="('GENERAL.NEW' | translate) + ' ' + helpText(control.value)" class="add-button"
                    mat-icon-button (click)="openDialog(control)" [disabled]="disabled">
                    <ic-icon [icon]="icAdd" size="10px"></ic-icon>
                </button>

                <button *ngIf="control.enabled" type="button" [matTooltip]="'GENERAL.DELETE' | translate"
                    class="remove-button" mat-icon-button (click)="onClickRemove(index)" [disabled]="disabled">
                    <ic-icon [icon]="icMinus" size="10px"></ic-icon>
                </button>
            </div>

        </div>
    </div>
</ng-container>