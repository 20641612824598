import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import icBrightness4 from '@iconify/icons-ic/round-brightness-4';
import icBrightness5 from '@iconify/icons-ic/round-brightness-5';
import icBrightness6 from '@iconify/icons-ic/round-brightness-6';

import { Style, StyleService } from '../services/style.service';

import { UserConfigType, UserConfigValue } from '@core/user-config/user-config.service.enum';
import { UserConfigService } from '@core/user-config/user-config.service';

@UntilDestroy()
@Component({
  selector: 'net-set-style',
  templateUrl: './set-style.component.html',
  styleUrls: ['./set-style.component.scss']
})
export class SetStyleComponent implements OnInit, OnDestroy {

  Style = Style;
  active: Style;
  icBrightness4 = icBrightness4;
  icBrightness5 = icBrightness5;
  icBrightness6 = icBrightness6;

  constructor(private styleService: StyleService, private userConfig: UserConfigService) { }

  ngOnInit() {
    this.styleService.style$.pipe(untilDestroyed(this)).subscribe(style => this.active = style);
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy() { }

  setStyle(style: Style) {
    let userConfigStyle: UserConfigValue;

    switch (style) {
      case Style.default:
        userConfigStyle = UserConfigValue.STYLE_DEFAULT;
        break;

      case Style.light:
        userConfigStyle = UserConfigValue.STYLE_LIGHT;
        break;

      case Style.dark:
        userConfigStyle = UserConfigValue.STYLE_DARK;
        break;
    }

    this.userConfig.set(UserConfigType.STYLE, userConfigStyle);

    this.styleService.setStyle(style);
  }

}
