export enum StaticFieldTypeId {
  NUMBER = 'b86b64c6-368e-4d71-87ca-2244822e0a34',
  CURRENCY = '840bdfd4-e63c-4f6f-94c4-699a3f5d1b03',
  PICKLIST = '485a6430-5fb2-4a14-b140-8744d4f92fb1',
  MULTI_LINE_TEXT = '01d04b43-e43a-4c93-ac30-2d23e097c577',
  SINGLE_LINE_TEXT = '4bc8c646-8d72-4827-aee1-511a616d6994',
}

export enum StaticFieldEnum {
  OPPORTUNITY_NAME = '3f72587e-2a07-4ac7-afc9-5f7185c7e596',
  ORGANIZATION = '12dc3c4e-404b-4b60-9a74-c343ca386482',
  CONTACTS = '9de334c4-91e4-4eab-874d-098678678065',
  PROBABILITY = '8987ade2-3172-4578-85ea-b1133cec7a83',
  CURRENCY = 'a1c541c1-cdac-48bf-a122-a44d28f43514',
  DESCRIPTION = 'e2b6548a-ad2c-4fd3-ac19-64f217c1474d',
  OPPORTUNITY_DETAIL_DETAIL_FIELD_USAGE = 'a15b28a0-ce31-4b8a-806d-94994c5c3390',
  OPPORTUNITY_DETAIL_UNIT_PRICE_FIELD_USAGE = 'd09f82de-3199-4ed9-aa4e-91c57865843f',
  SECTOR_MAIN_ID = '87ea9eca-904b-41b5-8261-7641dddce115',
  URL = '98749451-f770-4d7c-86d9-2ce3c6424d32',
  EMAIL = '65212902-99cc-46fe-b588-8fc44bc6edbe',
  PHONE = 'f43e80cf-8e6b-45fa-82ba-02c69eda7fe2',
  FAX = 'de2134ff-e719-487d-a5c4-2759888defb1',
  CONTACT_ID = 'a3bb9ae7-98db-4568-a8ec-8842f53c04fa',
  ACCOUNT_SOURCE_ID = 'ac61a0e4-7cd1-4f48-b6cd-3609038220b3',
  SERVICES = '67f1febe-e7a8-462d-b86d-caf4d08e43b6',
  REVENUE = 'ff6ca2b5-859c-4e4c-8545-b5ef9ecc163d',
  NOTE = 'fa7c1693-2276-4e38-8115-317c5b873834',
  JOB_TITLE = 'fa02d564-b0f8-49bb-9ce1-19968473c241',
  CLEARANCE_LEVEL = 'c2f2708d-bcb6-4d88-aeed-7260a6a692a0',
  CONTACT_ROLE_GROUP_ID = 'ca814907-73b8-44fc-99c2-13c2b232a79b',
  ADDRESS_ID = '08f4ec83-ada2-4e34-9b51-26c1bbbbb2b8',
  EMAIL_CONTACT = '2c2f2c86-3d26-4b68-ae70-81e25e3711a5',
  PHONE_CONTACT = '194d679d-df0b-42c1-9c65-33db8683745b',
  CONTACT_SPECIAL_DAY = 'deb83fa5-e469-42d4-8803-ada525ace66c',
  CONTACT_HASHTAG = 'e8d25343-db60-43d2-8e04-b1f68a57e641',
  DESCRIPTION_CONTACT = 'a33308b8-db3d-4bab-96a4-3fd318d8935b',
}

export enum StaticFieldEntityEnum {
  OPPORTUNITY = '49309c21-0b9d-4706-a5ac-dbc85e5af4a5',
  OPPORTUNITY_INFO = '8f162d71-955b-462f-aaf5-f8dfcfc2a811',
  LEAD = '7ce215d8-dacb-45a1-8795-c56e487ab090',
  LEAD_DRAFT_INFO = '66c697cb-bb80-4db4-ab7b-a50f18980d8a',
  CONTACT = 'f0dd9866-4c1d-4768-b6fc-342729864509',
}
