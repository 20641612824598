import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Breadcrumb, PpBreadcrumbsService } from 'pp-breadcrumbs';
import icHome from '@iconify/icons-ic/twotone-home';

@UntilDestroy()
@Component({
  selector: 'net-breadcrumbs',
  templateUrl: './breadcrumbs.component.html'
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {

  icHome = icHome;

  constructor(public ppBreadcrumbs: PpBreadcrumbsService) {
  }

  ngOnInit() {
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy() { }
}
