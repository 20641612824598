export enum CardActionType {
    CardAdd = 'CardAdd',
    CardCopy = 'CardCopy',
    CardNameUpdate = 'CardNameUpdate',
    CardSetOrder = 'CardSetOrder',
    CardSectionChange = 'CardSectionChange',
    CardBulkSectionChange = 'CardBulkSectionChange',
    CardHistoryAdd = 'CardHistoryAdd',
    CardHistoryGet = 'CardHistoryGet',
    CardSetComplete = 'CardSetComplete',
    CardBulkSetComplete = 'CardBulkSetComplete',
    CardAssigneeUserUpdate = 'CardAssigneeUserUpdate',
    CardBulkAssigneeUserUpdate = 'CardBulkAssigneeUserUpdate',
    CardHashtagUpdate = 'CardHashtagUpdate',
    CardBulkHashtagUpdate = 'CardBulkHashtagUpdate',
    CardDescriptionUpdate = 'CardDescriptionUpdate',
    CardReminderDateUpdate = 'CardReminderDateUpdate',
    CardDueDateUpdate = 'CardDueDateUpdate',
    CardBulkDueDateUpdate = 'CardBulkDueDateUpdate',
    CardCommentAdd = 'CardCommentAdd',
    CardCommentUpdate = 'CardCommentUpdate',
    CardCommentDelete = 'CardCommentDelete',
    CardCommentCount = 'CardCommentCount',
    CardCommentGetAll = 'CardCommentGetAll',
    CardAttachmentAdd = 'CardAttachmentAdd',
    CardAttachmentUpdate = 'CardAttachmentUpdate',
    CardAttachmentDelete = 'CardAttachmentDelete',
    CardAttachmentCount = 'CardAttachmentCount',
    CardLinkAdd = 'CardLinkAdd',
    CardLinkDelete = 'CardLinkDelete',
    CardCRMLinkUpdate = 'CardCRMLinkUpdate',
    CardDynamicFieldUpdate = 'CardDynamicFieldUpdate',
    CardParentCardConvertToSubcard = 'CardParentCardConvertToSubcard',
    CardSubcardConvertToParentCard = 'CardSubcardConvertToParentCard',
    CardDelete = 'CardDelete',
    ChildCardAdd = 'ChildCardAdd',
    ChildCardCountUpdate = 'ChildCardCountUpdate',
    ChildCardSetOrder = 'ChildCardSetOrder',
    CardCommentSystemUserIsReaded = 'CardCommentSystemUserIsReaded',
    CardGet = 'GetCard',
    CardDependencyUpdate = 'CardDependencyUpdate',
    GetChildCards = 'GetChildCards'
}
