export const UserConfigTableSortPrefix = 't_s';
export const UserConfigTableVisibilityPrefix = 't_v';
export const UserPageSizePrefix = 't_p';

export enum UserConfigType {
  STYLE = 'style',
  SIDENAV = 'sidenav'
}

export enum UserConfigValue {
  STYLE_DARK = 'dark',
  STYLE_LIGHT = 'light',
  STYLE_DEFAULT = 'default',

  SIDENAV_EXPANDED = 'expanded',
  SIDENAV_COLLAPSED = 'collapsed',
}
