import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ColorScheme, FilterRequest, Response } from '@core/api';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class ColorSchemeService {
  constructor(private http: HttpClient) { }

  list(request: FilterRequest): Observable<Response<ColorScheme[]>> {
    return this.http.post<Response<ColorScheme[]>>(environment.apiUrl + '/api/ColorScheme/List', request);
  }
}
