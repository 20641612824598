import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Address, AddressRequest, Customer, AddressService } from '@core/api';
import { AddressDialogData } from './address-form-dialog.component.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'net-address-form-dialog',
  templateUrl: './address-form-dialog.component.html',
  styleUrls: ['./address-form-dialog.component.scss']
})
export class AddressFormDialogComponent {

  customer: Customer;
  address: Address;

  loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: AddressDialogData,
    private dialogRef: MatDialogRef<AddressFormDialogComponent>,
    private addressService: AddressService,
    private translate: TranslateService
  ) {
    if (data && data.customer) {
      this.customer = data.customer;
    }

    if (data && data.address) {
      this.address = data.address;
    } else {
      this.address = null;
    }
  }

  onSave(request: AddressRequest) {
    this.loading = true;

    if (this.address) {
      request.addressId = this.address.addressId;

      this.addressService.update(request).subscribe(
        response => {
          this.dialogRef.close(response.data);
        }
      ).add(() => this.loading = false);
      return;
    }
    request.enabled = true;
    this.addressService.save(request).subscribe(
      response => {
        this.dialogRef.close(response.data);
      }
    ).add(() => this.loading = false);
  }

}
