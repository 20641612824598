import { AccountStatus, SalesRouteDefinition, SectorMain, SurveyMail } from '@core/api';
import { Action } from '@ngrx/store';

export enum NetPromoterScoreReportActionTypes {
    SET_NET_PROMOTER_SCORE_REPORT_SALES_ROUTE_DEFINITION = '[Net Promoter Score Report] SET_NET_PROMOTER_SCORE_SALES_ROUTE_DEFINITION',
    SET_NET_PROMOTER_SCORE_REPORT_SECTOR_MAIN = '[Net Promoter Score Report] SET_NET_PROMOTER_SCORE_SECTOR_MAIN',
    SET_NET_PROMOTER_SCORE_REPORT_ACCOUNT_STATUS = '[Net Promoter Score Report] SET_NET_PROMOTER_SCORE_ACCOUNT_STATUS',
    SET_NET_PROMOTER_SCORE_REPORT_MAIL_LIST = '[Net Promoter Score Report] SET_NET_PROMOTER_SCORE_MAIL_LIST',
}

export class SetNetPromoterScoreReportSalesRouteDefinition implements Action {
    readonly type = NetPromoterScoreReportActionTypes.SET_NET_PROMOTER_SCORE_REPORT_SALES_ROUTE_DEFINITION;
    constructor(public salesRouteDefinitions: SalesRouteDefinition[]) { }
}

export class SetNetPromoterScoreReportSectorMain implements Action {
    readonly type = NetPromoterScoreReportActionTypes.SET_NET_PROMOTER_SCORE_REPORT_SECTOR_MAIN;
    constructor(public industries: SectorMain[]) { }
}

export class SetNetPromoterScoreReportAccountStatus implements Action {
    readonly type = NetPromoterScoreReportActionTypes.SET_NET_PROMOTER_SCORE_REPORT_ACCOUNT_STATUS;
    constructor(
        public accountStatuses: AccountStatus[],
        public salesOrganizationId: string
    ) { }
}

export class SetNetPromoterScoreReportMailList implements Action {
    readonly type = NetPromoterScoreReportActionTypes.SET_NET_PROMOTER_SCORE_REPORT_MAIL_LIST;
    constructor(public mailList: SurveyMail[]) { }
}

export type NetPromoterScoreReportActions =
    SetNetPromoterScoreReportSalesRouteDefinition |
    SetNetPromoterScoreReportSectorMain |
    SetNetPromoterScoreReportAccountStatus |
    SetNetPromoterScoreReportMailList;
