import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { PagedResponseData, Response } from '../api.model';
import {
    DropdownModel, DynamicFieldCondition, DynamicFieldConditionInsertRequest,
    DynamicFieldConditionProperty, DynamicFieldConditionPropertyFilterRequest
} from './dynamic-field-condition.model';
@Injectable({
    providedIn: 'root'
})

export class DynamicFieldConditionService {
    constructor(
        private http: HttpClient
    ) { }

    dynamicFieldConditionPropertySearch(request: DynamicFieldConditionPropertyFilterRequest) {
        return this.http.post<Response<DynamicFieldConditionProperty[]>>
            (environment.apiUrl + '/api/DynamicFieldConditionProperty/Search', request);
    }

    dynamicFieldConditionProperyGetFieldValues(fieldSource: string) {
        const headers = new HttpHeaders({ 'content-type': 'application/json' });
        return this.http.post<Response<DropdownModel[]>>
            (environment.apiUrl + '/api/DynamicFieldConditionProperty/GetFieldValues', JSON.stringify(fieldSource), { headers });
    }

    insert(request: DynamicFieldConditionInsertRequest) {
        return this.http.post<Response<any>>(environment.apiUrl + '/api/DynamicFieldCondition/Insert', request);
    }

    update(request: DynamicFieldConditionInsertRequest) {
        return this.http.post<Response<any>>(environment.apiUrl + '/api/DynamicFieldCondition/Update', request);
    }

    delete(uuid: string) {
        return this.http.post<Response<any>>(environment.apiUrl + '/api/DynamicFieldCondition/Delete', { dynamicFieldConditionId: uuid });
    }

    search(request: { dynamicFieldId: string, picklistFlag: boolean }) {
        return this.http.post<Response<DynamicFieldCondition[]>>(environment.apiUrl + '/api/DynamicFieldCondition/Search', request);
    }

}
