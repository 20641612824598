import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { SystemUserService, User } from '@core/api';
import { getUser } from '@core/store';
import { UserConfigType, UserConfigValue } from '@core/user-config/user-config.service.enum';
import { UserConfig } from '@core/user-config/user-config.service.model';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { ToastService } from '../../shared/services/toast.service';

@Injectable({
  providedIn: 'root'
})
export class UserConfigService {

  private user: User;
  private config: UserConfig = {};

  private saveSubject = new Subject();

  constructor(
    private systemUserService: SystemUserService,
    private store: Store,
    private toastService: ToastService,
    private translate: TranslateService
  ) {
    // Fetch user and load user config
    this.store.select(getUser).subscribe(user => {
      this.user = user;

      if (user && user.property) {
        this.load(user.property);
        return;
      }
      this.config = {};
    });

    // Watch save request and persist after debounce
    this.saveSubject
      .asObservable()
      .pipe(debounceTime(500))
      .subscribe(() => this.persist());
  }

  private load(json: string) {
    const config = JSON.parse(json);

    // Make sure parsed json is correct config object
    if (!config || Array.isArray(config) || !(typeof config === 'object')) {
      return;
    }

    this.config = config;
  }

  private persist() {
    const config = JSON.stringify(this.all());

    this.systemUserService
      .updateConfig(this.user.userId, config)
      .subscribe(
        () => { },
        () => this.toastService.error(this.translate.instant('GENERAL.AN_ERROR_OCCURRED_WHILE_SAVING_USER_SETTINGS'))
      );
  }

  public get(key: UserConfigType | string): UserConfigValue | any {
    if ( !this.config || !this.config[key]) {
      return null;
    }

    const config = this.config[key];

    if (!config) {
      return null;
    }

    return config;
  }

  public getUser(): User {
    return this.user;
  }

  public set(key: UserConfigType | string, value: UserConfigValue | any, save = true) {
    this.config[key] = value;

    if (save) {
      this.save();
    }
  }

  public delete(key: UserConfigType | string, save = true) {
    delete this.config[key];

    if (save) {
      this.save();
    }
  }

  public all() {
    return this.config;
  }

  public save() {
    this.saveSubject.next();
  }
}
