<div fxLayout="row" class="my-2" mat-dialog-title>
  <h2 fxFlex fxLayoutAlign="center center" class="mb-0 ml-10">
    <span>{{ 'LEAD.CREATE_CUSTOMER' | translate }}</span>
  </h2>
  
  <button type="button" class="text-secondary" mat-dialog-close mat-icon-button>
    <mat-icon [icIcon]="icClose"></mat-icon>
  </button>
</div>

<mat-dialog-content>
  <mat-stepper linear #stepper (selectionChange)="onStepChange($event)">
    <mat-step *ngIf="customerInfosStep" id="customer-infos-step">
      <form [formGroup]="customerInfosGroup" fxLayout="column">
        <mat-form-field fxFlex>
          <mat-label>{{ 'GENERAL.CUSTOMER_NAME' | translate }}</mat-label>
          <input matInput formControlName="name" netUpperCase>
        </mat-form-field>

        <mat-form-field fxFlex>
          <mat-label>{{ 'GENERAL.CUSTOMER_SHORT_NAME' | translate }}</mat-label>
          <input matInput formControlName="customerShortName" netUpperCase maxlength="30">
        </mat-form-field>
      </form>

      <div fxLayoutAlign="center center" fxLayoutGap="1em">
        <button fxFlex="45%" mat-raised-button matStepperPrevious *ngIf="!isAtStart">{{ 'GENERAL.BACK' | translate }}</button>
        <button fxFlex="45%" mat-raised-button color="primary" (click)="controlCustomerName()">{{ 'GENERAL.NEXT' | translate }}</button>
      </div>
    </mat-step>

    <mat-step [stepControl]="salesOrganizationsGroup" *ngIf="salesOrganizationsStep" id="sales-organizations-step">
      <form [formGroup]="salesOrganizationsGroup" fxLayout="column">
        <h4>{{ 'LEAD.SELECT_SALES_ORGANIZATION' | translate }}</h4>
        <mat-form-field fxLayoutAlign="center center">
          <mat-label>{{ 'LEAD.PLEASE_SELECT_YOUR_SALES_ORGANIZATION' | translate }}</mat-label>
          <net-select-sales-organization [systemUserId]="user.userId" [removalIds]="existingIds" formControlName="salesOrganization">
          </net-select-sales-organization>
        </mat-form-field>

        <div fxLayoutAlign="center center" fxLayoutGap="1em">
          <button fxFlex="45%" mat-raised-button matStepperPrevious *ngIf="!isAtStart">{{ 'GENERAL.BACK' | translate }}</button>
          <button fxFlex="45%" mat-raised-button matStepperNext color="primary" (click)="salesOrganizationsGroup.markAllAsTouched()">
            {{ 'GENERAL.NEXT' | translate }}
          </button>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="salesRouteDefinitionsGroup" *ngIf="salesRoutesStep" id="sales-routes-step">
      <form [formGroup]="salesRouteDefinitionsGroup">
        <h4>{{ 'LEAD.SELECT_SALES_ROUTE' | translate }}</h4>
        <mat-form-field fxLayoutAlign="center center">
          <mat-label>{{'LEAD.PLEASE_SELECT_YOUR_SALES_ROUTE' | translate}}</mat-label>
          <mat-select [placeholder]="'GENERAL.SELECT_SALES_ROUTE' | translate" formControlName="salesRouteDefinitionId"
            (openedChange)="openedChange($event)">
            <input matInput #searchInput class="search-text px-4" [(ngModel)]="searchText"
              [ngModelOptions]="{standalone: true}" [placeholder]="'GENERAL.SEARCH_ELLIPSIS' | translate"
              (ngModelChange)="onKey()" (keydown)="checkKey($event)">
            <mat-option *ngFor="let activeRoute of filteredActiveRoutes" [value]="activeRoute.salesRouteDefinitionId">
              {{ activeRoute.name }}
              <span class="text-hint" *ngIf="activeRoute.assignedUser">
                - {{ activeRoute.assignedUser.displayName }}
              </span>
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div fxLayoutAlign="center center" fxLayoutGap="1em">
          <button fxFlex="45%" mat-raised-button matStepperPrevious *ngIf="!isAtStart">{{ 'GENERAL.BACK' | translate }}</button>
          <button fxFlex="45%" mat-raised-button matStepperNext color="primary">{{ 'GENERAL.NEXT' | translate }}</button>
        </div>
      </form>
    </mat-step>

    <mat-step>
      <p class="my-5" fxLayoutAlign="center center"
        [innerHTML]="'LEAD.CUSTOMER_WILL_BE_CREATED_DO_YOU_CONFIRM' | translate: { customerName: customerNameParam }">
      </p>

      <div fxLayoutAlign="center center" fxLayoutGap="1em">
        <button fxFlex="45%" mat-raised-button class="confirm-button" (click)="createCustomer()">
          {{ 'GENERAL.CONFIRM' | translate }}
        </button>
      </div>
    </mat-step>
  </mat-stepper>
</mat-dialog-content>