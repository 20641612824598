import { isSupportedCountry } from 'libphonenumber-js';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, DoCheck, ElementRef, HostBinding, Input,
  OnDestroy, OnInit, Optional, Self
} from '@angular/core';
import {
  ControlValueAccessor, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, NgControl, Validators
} from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Country, Customer, CustomerPhoneType, Phone } from '@core/api';
import icAdd from '@iconify/icons-ic/twotone-add';
import icMinus from '@iconify/icons-ic/twotone-minus';
import { TranslateService } from '@ngx-translate/core';

import { AppValidators } from '../../../app.validators';
import { maskPhone } from '../../utils/text-masks';

@Component({
  selector: 'net-input-customer-phones[type][customer]',
  templateUrl: './input-customer-phones.component.html',
  styleUrls: ['./input-customer-phones.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: InputCustomerPhonesComponent
    }
  ]
})
export class InputCustomerPhonesComponent implements OnInit, OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<Phone[] | null> {

  static nextId = 0;

  countryTurkey = {
    code: '90',
    countryId: 'a046d041-18fd-4cbf-ab86-c531be9a3e24',
    createdOn: null,
    name: this.translate.instant('Country.Turkey'),
    nameEng: 'Turkey',
    orderBy: 1,
    phoneCode: '90',
    readOnlyFlag: false,
    sortName: 'TR'
  };

  loading = false;
  focused = false;
  errorState = false;
  controlType = 'input-customer-phones';
  describedBy = '';
  stateChanges = new Subject<void>();
  phones: Phone[] = [];
  form = new UntypedFormGroup({
    phones: new UntypedFormArray([this.createPhoneForm()])
  });

  icAdd = icAdd;
  icMinus = icMinus;
  maskPhone = maskPhone();

  typeOptions: { type: CustomerPhoneType, label: string }[] = [];
  countries: Country[] = [];

  @Input()
  get customer() { return this._customer; }
  set customer(customer: Customer) {
    this._customer = customer;

    if (customer) {
      this.form.enable();
    }
  }
  private _customer: Customer;

  @Input()
  get type() { return this._type; }
  set type(type) {
    this._type = type;

    // Generate phone types
    // Add office type for all options
    this.typeOptions = [{
      type: CustomerPhoneType.OFFICE,
      label: this.translate.instant('PhoneTypeId.' + CustomerPhoneType.OFFICE)
    }];

    switch (type) {
      case 'customer':
      case 'contact':
        this.typeOptions.push({
          type: CustomerPhoneType.MOBILE,
          label: this.translate.instant('PhoneTypeId.' + CustomerPhoneType.MOBILE)
        });
        break;
    }

    // Add other type for all options
    this.typeOptions.push({
      type: CustomerPhoneType.OTHER,
      label: this.translate.instant('PhoneTypeId.' + CustomerPhoneType.OTHER)
    });
  }
  private _type: 'customer' | 'contact';

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    if (value) {
      this.form.disable();
    } else {
      this.form.enable();
    }

    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): Phone[] | null { return this._value; }
  set value(phones: Phone[] | null) {
    this._value = phones;
    this.onChange(phones);
    this.stateChanges.next();
    this.changeDetectorRef.detectChanges();
  }
  private _value: Phone[];

  @HostBinding('id') id = `input-customer-phones-${InputCustomerPhonesComponent.nextId++}`;
  @HostBinding('attr.tabindex') tabIndex = -1;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  get shouldLabelFloat() { return true; }

  get empty() { return !this.value || this.value.length === 0; }

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private formBuilder: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private translate: TranslateService,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Material form field implementation
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    const phones = this.getPhonesForm().value;
    if (phones[0]?.type) {
      this.updatePhones();
    }

    this.form.valueChanges.pipe(debounceTime(200)).subscribe(() => {
      this.updatePhones();
    });

    if (!this.customer) {
      this.form.disable();
    }

    this.changeDetectorRef.detectChanges();
  }

  onKeyupCountryCode(ctrl: UntypedFormGroup) {
    const sortCode = ctrl.value.country?.sortName;
    if (sortCode && isSupportedCountry(sortCode)) {
      const countryCodeControl = ctrl.value.country.phoneCode;
      const countryCodeLenght = ctrl.value.country.phoneCode.length;
      const phoneControl = ctrl.value.phone;
      if (countryCodeControl !== phoneControl.substring(1, countryCodeLenght + 1)) {
        ctrl?.controls.phone.patchValue(ctrl.value.country.phoneCode);
      }
    }
  }

  onChangeCountry(ctrl) {
    const sortCode = ctrl.value.country?.sortName;
    if (sortCode && isSupportedCountry(sortCode)) {
      ctrl?.controls.phone.patchValue('+' + ctrl.value.country.phoneCode);
      ctrl?.controls.phone.setValidators(AppValidators.phone);
    } else {
      ctrl?.controls.phone.patchValue('');
      ctrl?.controls.phone.setValidators(Validators.required);
    }

    ctrl?.controls.phone.updateValueAndValidity();
  }

  typeControl(sortCode) {
    return isSupportedCountry(sortCode);
  }

  textMaskCheck(ctrl) {
    const sortCode = ctrl.value.country?.sortName;
    if (sortCode && isSupportedCountry(sortCode)) {
      return maskPhone(sortCode);
    }

    return { mask: false, showMask: false };
  }

  updatePhones() {
    const phones: Phone[] = [];
    this.getPhonesForm().controls.forEach((control, index) => {
      if (!this.customer) {
        return;
      }
      if (this.getPhoneValue(index, 'phoneId')) {
        phones.push({
          phoneTypeId: control.get('type').value,
          phoneNumber: control.get('phone').value,
          internalPhone: control.get('internal').value ? `${control.get('internal').value}` : '',
          customerId: this.customer.customerId,
          phoneId: this.getPhoneValue(index, 'phoneId'),
          contactId: this.getPhoneValue(index, 'contactId'),
          createdOn: this.getPhoneValue(index, 'createdOn'),
          code: this.getPhoneValue(index, 'code'),
          country: control.get('country').value,
        });
      } else {
        phones.push({
          phoneTypeId: control.get('type').value,
          phoneNumber: control.get('phone').value,
          internalPhone: control.get('internal').value ? `${control.get('internal').value}` : '',
          country: control.get('country').value,
          customerId: this.customer.customerId,
        });
      }
    });
    this.value = phones;
  }

  getPhonesForm(): UntypedFormArray {
    return this.form.get('phones') as UntypedFormArray;
  }

  getPhonesFormControls(): UntypedFormGroup[] {
    return this.getPhonesForm().controls as UntypedFormGroup[];
  }

  getPhoneValue(index: number, key: keyof Phone): any {
    if (!this.phones || !this.phones[index]) {
      return null;
    }

    return this.phones[index][key];
  }

  createPhoneForm(phone?: Phone): UntypedFormGroup {
    return this.formBuilder.group({
      type: [phone ? phone.phoneTypeId : CustomerPhoneType.OFFICE, this.required ? Validators.required : null],
      phone: [phone ? phone.phoneNumber : null, this.required ? [Validators.required, AppValidators.phone] : AppValidators.phone],
      internal: [phone ? phone.internalPhone : null],
      country: [phone ? (phone?.countryId ? phone?.country : this.countryTurkey) : this.countryTurkey, this.required ? Validators.required : null]
    });
  }

  add(phone?: Phone) {
    this.getPhonesForm().push(this.createPhoneForm(phone));
    this.changeDetectorRef.detectChanges();
  }

  remove(index: number) {
    const form = this.getPhonesForm();

    if (form.length > 0) {
      form.removeAt(index);
      this.stateChanges.next();
      this.changeDetectorRef.detectChanges();
    }
  }

  writeValue(phones: Phone[]) {
    if (phones?.length > 0) {
      this.getPhonesForm().clear();
      phones.forEach(phone => this.add(phone));

      this.phones = phones;
      this.value = phones;
    }
  }

  checkIsInputTypeOffice(control: UntypedFormGroup) {
    return control.controls.type.value === CustomerPhoneType.OFFICE;
  }

  onChange = (_: any) => { };
  onTouched = () => { };
  onContainerClick = () => { };
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');
  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.touched && (this.ngControl.invalid || this.form.invalid);

      this.stateChanges.next();
    }
  }

  onKeydown(event: KeyboardEvent) {
    if (event.key === 'Backspace' || event.key === 'Delete') {
      return true;
    }
    if (isNaN(Number(event.key))) {
      event.preventDefault();
    }
  }

  focusOutInput(index: number) {
    const formControl = this.getPhonesForm().at(index).get('phone');
    if (!formControl.valid) {
      // formControl.setValue(null);
    }
  }

  focusInInput(phone: HTMLInputElement, index: number) {
    if (!this.getPhonesForm().at(index).get('country').value) {
      phone.blur();
      return;
    }
    const formControl = this.getPhonesForm().at(index).get('phone');
    if (formControl.value === null) {
      const phoneCode = this.getPhonesForm().at(index).get('country').value?.phoneCode;
      formControl.setValue('+' + phoneCode);
    }
  }

  isFormValid() {
    return this.form.valid;
  }
}
