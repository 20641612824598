
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

import {
  Response,
  PagedResponse,
  OrganizationalStructureSearchRequest,
  OrganizationalStructure
} from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class OrganizationalStructureService {
  constructor(private http: HttpClient) { }

  search(request: OrganizationalStructureSearchRequest): Observable<PagedResponse<OrganizationalStructure>> {
    return this.http.post<PagedResponse<OrganizationalStructure>>(environment.apiUrl + '/api/OrganizationalStructure/Search', request);
  }

  update(request: any): Promise<Response<OrganizationalStructure>> {
    return this.http.post<Response<any>>(environment.apiUrl + '/api/OrganizationalStructure/Update', request).toPromise();
  }

  insert(request: any): Promise<Response<any>> {
    return this.http.post<Response<any>>(environment.apiUrl + '/api/OrganizationalStructure/Insert', request).toPromise();
  }

  delete(request: any): Promise<Response<any>> {
    return this.http.post<Response<any>>(environment.apiUrl + '/api/OrganizationalStructure/Delete', request).toPromise();
  }
}
