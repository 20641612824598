// tslint:disable
declare global {
  interface String {
    toCapitalize(): string;
    toTurkishLocaleLowerCase(): string;
    toTurkishLocaleUpperCase(): string;
  }
}

String.prototype.toCapitalize = function () {
  return this.replace(
    /\w\S*/g,
    (value) => value.charAt(0).toUpperCase() + value.substring(1).toLowerCase()
  );
};

String.prototype.toTurkishLocaleLowerCase = function () {
  let string = this;
  const letters = { 'İ': 'i', 'I': 'ı', 'Ş': 'ş', 'Ğ': 'ğ', 'Ü': 'ü', 'Ö': 'ö', 'Ç': 'ç' };
  string = string.replace(/(([İIŞĞÜÇÖ]))+/g,
    function (letter) {
      return letters[letter];
    });
  return string.toLowerCase();
};

String.prototype.toTurkishLocaleUpperCase = function () {
  let string = this;
  const letters = { 'i': 'İ', 'ş': 'Ş', 'ğ': 'Ğ', 'ü': 'Ü', 'ö': 'Ö', 'ç': 'Ç', 'ı': 'I' };
  string = string.replace(/(([iışğüçö]))+/g,
    function (letter) {
      return letters[letter];
    });
  return string.toUpperCase();
};

export { };
