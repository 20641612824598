import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

import { CardAttachment, CardAttachmentRequest, Response, PagedResponse, CardAttachmentSearchRequest } from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class CardAttachmentService {

  constructor(private http: HttpClient) { }

  insert(request: CardAttachmentRequest): Observable<Response<CardAttachment>> {
    return this.http.post<Response<CardAttachment>>(environment.apiUrl + '/api/CardAttachment/Insert', request);
  }

  search(request: CardAttachmentSearchRequest): Observable<PagedResponse<CardAttachment>> {
    return this.http.post<PagedResponse<CardAttachment>>(environment.apiUrl + '/api/CardAttachment/Search', request);
  }

  delete(request: CardAttachment): Observable<Response<CardAttachment>> {
    return this.http.post<Response<CardAttachment>>(environment.apiUrl + '/api/CardAttachment/Delete', request);
  }

  update(request: CardAttachmentRequest): Observable<Response<CardAttachment>> {
    return this.http.post<Response<CardAttachment>>(environment.apiUrl + '/api/CardAttachment/Update', request);
  }
}
