<div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
  <h2 class="m-0" fxFlex="auto">{{ data?.ticketMessage?.message?.subject || ('MAIL.ENTRY_NO_SUBJECT' | translate) }}
  </h2>
  <button type="button" class="text-secondary" mat-dialog-close mat-icon-button>
    <mat-icon [icIcon]="icClose"></mat-icon>
  </button>
</div>

<mat-divider class="-mx-6 mb-2 text-border"></mat-divider>

<mat-dialog-content>
  <div netScrollbar *ngIf="data" @fadeInUp class="flex-auto">

    <div class="flex items-center cursor-pointer" (click)="toggleContactDrawer()">
      <div class="flex-1 flex truncate">
        <div class="flex-none flex flex-col ltr:pr-4 rtl:pl-4">
          <net-mail-circle-avatar [key]="data.self ? data.headerInfo?.channelInfo : data?.ticketMessage?.contactInfo"
            [identifier]="data.self ? data.headerInfo?.channelInfo : data?.ticketMessage?.contactInfo"></net-mail-circle-avatar>
        </div>

        <div class="flex-1 truncate">
          <div class="flex truncate">
            <p class="text-base flex-1 truncate font-medium">{{ (data.self ? data.headerInfo?.channelInfo :
              data?.ticketMessage?.contactInfo) || ('MAIL.ENTRY_DRAFT'
              | translate) }}</p>
          </div>

          <div class="flex items-center cursor-pointer">
            <p class="text-xs text-secondary">{{ 'MAIL.TO' | translate | lowercase }} {{
              data.self ? data.headerInfo?.mailName : formattedRecipients(data?.ticketMessage?.to) }}</p>
            <ic-icon [icIcon]="contactDrawer ? icArrowDropUp : icArrowDropDown" class="text-secondary"
              size="18px"></ic-icon>
          </div>
        </div>
      </div>

      <p class="text-xs flex-none text-secondary truncate" style="padding-top: 3px;">{{
        data?.ticketMessage?.message?.messageTime | momentFormat:'DD.MM.YYYY - HH:mm' }}</p>

    </div>

    <div class="overflow-hidden" [@dropdown]="contactDrawer">
      <div class="border rounded mt-4 p-2 pr-8 relative">
        <table class="w-full truncate table-fixed">
          <tbody>
            <tr>
              <td class="pr-2 font-medium w-14">{{ 'MAIL.FROM' | translate }}:</td>
              <ng-container *ngIf="data.self; then template_2; else template_1"></ng-container>
            </tr>
            <tr>
              <td class="pr-2 font-medium w-14">{{ 'MAIL.TO' | translate }}:</td>
              <ng-container *ngIf="data.self; then template_1; else template_2"></ng-container>
            </tr>
            <tr *ngIf="data?.ticketMessage?.cc">
              <td class="pr-2 font-medium w-14">{{ 'MAIL.CC' | translate }}:</td>
              <ng-container *ngTemplateOutlet="template_3"></ng-container>
            </tr>
            <tr>
              <td class="pr-2 font-medium w-14">{{ 'MAIL.DATE' | translate }}:</td>
              <td class="truncate">{{ data?.ticketMessage?.message?.messageTime | momentFormat:'DD.MM.YYYY - HH:mm' }}
              </td>
            </tr>
          </tbody>
        </table>
        <button (click)="toggleContactDrawer()" class="absolute top-0 right-0" mat-icon-button type="button">
          <mat-icon [icIcon]="icClose" size="18px"></mat-icon>
        </button>
      </div>
    </div>

    <div class="vex-blogpost py-4">
      <net-html-viewer [defaultStyle]="defaultStyle" [html]="data?.ticketMessage?.message?.longText"></net-html-viewer>
    </div>

    <div *ngIf="ticketMessageAttachments.length > 0" class="flex flex-wrap gap-2">
      <net-customer-experience-mail-attachment class="mail-view flex flex-wrap"
        *ngFor="let file of ticketMessageAttachments" mode='mailView' [directDownload]="true" [file]="file.attachment">
      </net-customer-experience-mail-attachment>
    </div>

  </div>
</mat-dialog-content>

<mat-dialog-actions class="justify-end">
</mat-dialog-actions>

<ng-template #template_1>
  <td class="truncate">
    <span>{{ data.self ? data.headerInfo?.contactName : data?.ticketMessage?.contactName }}</span>
    <span class="mx-2">&bull;</span>
    <span class="text-secondary">{{ data.self ? data.headerInfo?.mailName : data?.ticketMessage?.contactInfo }}</span>
  </td>
</ng-template>

<ng-template #template_2>
  <td class="truncate">
    <span>{{ data.self ? data.headerInfo?.channelInfo : formattedRecipients(data?.ticketMessage?.to) }}</span>
  </td>
</ng-template>

<ng-template #template_3>
  <td class="truncate">
    <span>{{formattedRecipients(data?.ticketMessage?.cc, true) }}</span>
  </td>
</ng-template>