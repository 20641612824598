import {
    Opportunity,
    OpportunityApprovalRequest,
    OpportunityApprovalStatusEnum,
    SystemUserService
} from '@core/api';
import { ApiDataSource } from '@core/api/api.data-source';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { debounceTime, finalize, map } from 'rxjs/operators';
import { OpportunityApprovalsService } from './opportunity-approvals.service';

export class OpportunityApprovalsDataSource extends ApiDataSource<OpportunityApprovalRequest> {

    public $userList = this.systemUserService
        .userSearch({ filter: { salesOrganizationIds: [this.opportunity.account.salesOrganizationId] } }).pipe(
            map(response => response.data.results),
            map(statuses => statuses.map(item => {
                return {
                    key: item.systemUserId,
                    value: [item.firstName, item.lastName].join(' '),
                };
            }))
        );

    public $approvalStatuses = of([
        { key: OpportunityApprovalStatusEnum.APPROVED, value: this.translate.instant('OpportunityApprovalStatus.Approved') },
        { key: OpportunityApprovalStatusEnum.WAITING_FOR_APPROVAL, value: this.translate.instant('OpportunityApprovalStatus.Waiting for Approval') },
        { key: OpportunityApprovalStatusEnum.CANCELLED, value: this.translate.instant('OpportunityApprovalStatus.Cancelled') },
        { key: OpportunityApprovalStatusEnum.REJECTED, value: this.translate.instant('OpportunityApprovalStatus.Rejected') }
    ]);

    public $approvalTypes = this.approvalService
    .search({ filter: { salesOrganizationId: this.opportunity.account.salesOrganizationId } }).pipe(
        map(response => response.data.results.sort((a, b) => a.orderBy - b.orderBy)),
        map(types => types.map(item => {
            return {
                key: item.opportunityApprovalTypeId,
                value: this.translate.instant(`OpportunityApprovalType.${item.typeName}`),
            };
        }))
    );

    public $confirmatoryList = this.systemUserService
    .userSearch({ filter: { salesOrganizationIds: [this.opportunity.account.salesOrganizationId] } }).pipe(
        map(response => response.data.results),
        map(statuses => statuses.map(item => {
            return {
                key: item.systemUserId,
                value: [item.firstName, item.lastName].join(' '),
            };
        }))
    );

    constructor(
        private approvalService: OpportunityApprovalsService,
        private systemUserService: SystemUserService,
        private translate: TranslateService,
        private opportunity: Opportunity,
        protected initialFilter?: any
    ) {
        super(initialFilter);
    }

    load(): void {
        // Init filter with data source's default filter
        const filter: any = { opportunityId: this.opportunity.opportunityId, ...this.initialFilter, ...this.filter };

        // Update loading state
        this.loadingSubject.next(true);

        // Create request parameters
        const request = this.getRequest();

        if (!request.orderType) {
            request.orderType = 'ASC';
            request.orderBy = 'ApprovalType';
        }

        if (this.paginator?.pageSize) {
            request.pageSize = this.paginator.pageSize;
        }

        // Add filters to request
        request.filter = filter;

        // Fetch data
        this.approvalService
            .searchApprovalRequest(request)
            .pipe(
                debounceTime(400),
                finalize(() => this.loadingSubject.next(false))
            ).subscribe((response) => {
                // Update count and data subjects
                this.dataSubject.next(response.data.results);
                this.dataCountSubject.next(response.data.rowCount);
                this.rowCount$.next(response.data.rowCount);

                // Update data source's empty based row count
                this.empty = response.data.rowCount === 0;
            });

        return this.loadingSubject.next(false);
    }
}
