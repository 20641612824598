import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import {
  GetNotificationError,
  GetNotification,
  GetNotificationSuccess,
  NotificationActionType,
  GetLeadAssignmentCount,
  GetLeadAssignmentCountSuccess,
  GetLeadAssignmentCountError,
  GetMailUnreadCountSuccess,
  GetMailUnreadCountError,
  GetWarningCountSuccess,
  GetWarningCountError
} from '@core/store';
import { AccountService, AssignmentService, MailService, NotificationService } from '@core/api';

@Injectable()
export class NotificationEffects {
  constructor(
    private actions$: Actions,
    private assignmentService: AssignmentService,
    private notificationService: NotificationService,
    private mailService: MailService,
    private accountService: AccountService,
  ) { }

  getNotification$ = createEffect(() => this.actions$.pipe(
    ofType(NotificationActionType.GET_NOTIFICATION),
    mergeMap(() => this.notificationService.getActionNeededCounts(true)),
    switchMap(response => of(new GetNotificationSuccess(response.data))),
    catchError((error) => of(new GetNotificationError(error)))
  ));

  getMailUnreadCount$ = createEffect(() => this.actions$.pipe(
    ofType(NotificationActionType.GET_MAIL_UNREAD_COUNT),
    mergeMap(() => this.mailService.isLoggedIn()
      .pipe(
        mergeMap((isLoggedIn) => {
          if (!isLoggedIn) {
            return of(0);
          }

          return this.mailService
            .getUnreadMessagesCount()
            .pipe(map(response => response.data));
        })
      )
    ),
    switchMap(data => of(new GetMailUnreadCountSuccess(data))),
    catchError((error) => of(new GetMailUnreadCountError(error)))
  ));

  getLeadAssignmentCount$ = createEffect(() => this.actions$.pipe(
    ofType(NotificationActionType.GET_LEAD_ASSIGNMENT_COUNT),
    mergeMap((request: GetLeadAssignmentCount) => this.assignmentService.getAssignedCount(request.payload)),
    switchMap(response => of(new GetLeadAssignmentCountSuccess(response.data))),
    catchError((error) => of(new GetLeadAssignmentCountError(error)))
  ));

  getWarningCount$ = createEffect(() => this.actions$.pipe(
    ofType(NotificationActionType.GET_WARNING_COUNT),
    mergeMap(() => this.accountService.getWarningCount(true)),
    switchMap(response => of(new GetWarningCountSuccess(response.data))),
    catchError((error) => of(new GetWarningCountError(error)))
  ));
}
