import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UserConfigService } from '@core/user-config/user-config.service';
import icDrag from '@iconify/icons-ic/baseline-drag-indicator';
import icFilterList from '@iconify/icons-ic/twotone-filter-list';

import { TableColumn } from '../../interfaces/table-column.interface';
import { saveColumnOrder, saveUserColumnVisibility } from '../../utils/mat-table-user-config';

export interface TableColumnFilterConfig {
  sortId: string;
  orderId: string;
  visibilityId: string;
}

@Component({
  selector: 'net-mat-table-column-filter',
  templateUrl: './mat-table-column-filter.component.html',
  styleUrls: ['./mat-table-column-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MatTableColumnFilterComponent {
  @Input() columns: TableColumn<any>[];
  @Input() tableColumnFilterConfig: TableColumnFilterConfig;
  @Input() isOrderActive = true;
  @Input() columnOrderStartIndex = 0;
  @Input() showTitle = false;

  icFilterList = icFilterList;
  icDrag = icDrag;

  visibleChanged = false;

  constructor(
    private userConfig: UserConfigService,
  ) { }

  onFilterMenuClose() {
    if (!this.visibleChanged) {
      return;
    }

    saveUserColumnVisibility(this.tableColumnFilterConfig.visibilityId, this.columns, this.userConfig);
    this.visibleChanged = false;
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(
      this.columns,
      event.previousIndex + this.columnOrderStartIndex,
      event.currentIndex + this.columnOrderStartIndex
      );
    this.columns.forEach((element, index) => element.order = index);
    saveColumnOrder(this.tableColumnFilterConfig.orderId, this.columns, this.userConfig);
  }
}
