export const environment = {
  production: true,
  name: 'dev',
  apiUrl: 'https://api.xooi.cc',
  socketUrl: 'https://socket.xooi.cc',
  socketTenant: 'DEV',
  googleTagManagerId: '',
  callUrl: 'https://guest.xooi.cc',
  secretKey: 'bQeThWmZq4t7w!z%C*F)J@NcRfUjXn2r5u8x/A?D(G+KaPdSgVkYp3s6v9y$B&E)',
  fusioncharts: '1nH2bqC-13D2E6E1D3G3E3A5A2B3D3B4E4D4rmeD7E4jlbA-8uG1B6d1paB2E3C1F3H3A8B5D4F4G4E3B2A14A32B13B9tkeG4H3D3mA9C11A5e1cvA2I3A1ptkE2B2G2phhC2B2C8B3E4B2C3H2C3B10C6ogaD3D2TF2wqe1B2E2B2qlE1GC3C1xzkB4D1H4C6D4A2A2F4H2E1B3D1B2C5s==',
  googleTranslateKey: 'AIzaSyCaoUq7XoCxSZSGW6A0maCSOoJMvzWAPfo'
};
