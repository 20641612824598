import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import { ValueAddedSearchRequest, Response, ValueAddedService } from '@core/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ValueAddedServiceService {
  constructor(private http: HttpClient) { }

  search(request: ValueAddedSearchRequest): Observable<Response<ValueAddedService[]>> {
    return this.http.post<Response<ValueAddedService[]>>(environment.apiUrl + '/api/ValueAddedService/Search', request);
  }
}
