<div (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()" [class.collapsed]="collapsed"
  [class.open]="collapsed && collapsedOpen$ | async" class="sidenav flex flex-col">
  <div class="sidenav-toolbar flex-none flex items-center">
    <div class="netlog-logo">
      <img [src]="logoPath?.light" class="light" alt="XOOI">
      <img [src]="logoPath?.dark" class="dark" alt="XOOI">
    </div>

    <button (click)="toggleCollapse()" class="w-8 h-8 -mr-2 leading-none flex-none" mat-icon-button type="button">
      <mat-icon *ngIf="!collapsed" [icIcon]="icRadioButtonChecked" size="14px"></mat-icon>
      <mat-icon *ngIf="collapsed" [icIcon]="icRadioButtonUnchecked" size="14px"></mat-icon>
    </button>
  </div>

  <div class="sidenav-items flex-auto mb-10" #scrollBar=netScrollbarDirective netScrollbar>
    <net-sidenav-item *ngFor="let item of items; trackBy: trackByRoute" [item]="item" [level]="0"
      [isCollapseOpen]="collapsed && [false, undefined, null].includes(collapsedOpen$ | async)"
      (click)="onClickedSidenavItem(item)"></net-sidenav-item>
  </div>
  <net-set-style></net-set-style>
  <div class="text-center opacity-50 cursor-default">{{ appInfo }}</div>
</div>
