export enum OpportunityPriceListStatusOption {
  DRAFT = '4fcd0a2d-446c-447d-8150-df29ae412845',
  BUSINESS_DEVELOPMENT = 'cc351f27-d8b9-4179-ae10-453fff0bdde4',
  DENIED = '1ca115f4-2ab9-452e-9d65-79dffbd0fef6',
  APPROVED = 'fca43fbc-05a8-44cf-bf78-774fff323990',
  BACK_TO_SALESMAN = '4837fa60-04a5-4851-a756-7346c04632ea',
  SENIOR_MANAGER_APPROVAL = '773a5367-257f-403a-8137-eea1b76c8a92',
  TOP_EXECUTIVE_APPROVAL = '839fe862-f779-43b4-8d7e-5dc5cff563f0',
  BUSINESS_DEVELOPMENT_COMPLETE = '12333d17-b49f-4fef-a735-c104492b450e',
}
