<mat-select
  #select
  (selectionChange)="onSelectionChange()"
  [compareWith]="compareWith"
  [disabled]="isDisabled"
  [formControl]="selectCtrl" [tabIndex]="tabIndex">
  <mat-select-trigger>
    {{ getToolName(select.value) }}
  </mat-select-trigger>
  <mat-option *ngFor="let tool of activityVideoConferenceTool" [value]="tool.meetingTypeId">
    <div fxLayout="row">
      <span fxFlex="15">{{ ("MeetingType." + tool.name) | translate }}</span>
      <span class="description" fxFlex="85">|&nbsp;{{("MeetingType." + tool.description) | translate}}</span>
    </div>
  </mat-option>
</mat-select>

<mat-progress-spinner *ngIf="loading === true" diameter="16" mode="indeterminate"></mat-progress-spinner>
