<mat-form-field fxFlex="100">
    <mat-label>{{placeholder}}</mat-label>
    <mat-select [required]="required" [matTooltipDisabled]="!(value?.length > 1 && disabled)"
        [matTooltip]="getToolTip()" [matTooltipClass]="'sales-route-tooltip'" [matTooltipPosition]="'before'"
        [placeholder]="selectCtrl.value ? (selectCtrl.value.length > 1 ? ('GENERAL.ROUTE_SELECTED' | translate: { value: selectCtrl.value.length }) : selectedValue): placeholder"
        [tabIndex]="tabIndex" [formControl]="selectCtrl" (selectionChange)="onSelectionChange()"
        (openedChange)="openedChange()" panelClass="custom-panel" disableOptionCentering multiple>
        <mat-select-trigger *ngIf="selectCtrl.value && selectCtrl.value.length>1">
            {{"GENERAL.ROUTE_SELECTED" | translate: { value: selectCtrl.value.length } }}
        </mat-select-trigger>
        <input matInput class="mt-4 ml-4" [placeholder]="'GENERAL.SEARCH_ELLIPSIS' | translate" [(ngModel)]="searchText"
            (ngModelChange)="onKey($event)">
        <mat-option disabled="disabled" class="mat-option-button">
            <button mat-raised-button (click)="selectAll()"> {{"GENERAL.SELECT_ALL" | translate}}
            </button>
            <button mat-raised-button (click)="deselectAll()"> {{"GENERAL.REMOVE_ALL" | translate}}
            </button>
        </mat-option>
        <ng-container *ngIf="filteredSalesRoutes && filteredSalesRoutes.length">
            <mat-option *ngIf="hasUndefinedOption" [value]="Guid.EMPTY" (click)="changeActiveItem(Guid.EMPTY)">
                {{"GENERAL.UNDEFINED" | translate}} </mat-option>
            <ng-container *ngFor="let salesRoute of filteredSalesRoutes | orderBy: 'name': false">
                <mat-option *ngIf="salesRoute.salesRouteDefinitionId !== Guid.EMPTY" [disabled]="disabled"
                    [value]="salesRoute.salesRouteDefinitionId"
                    (click)="changeActiveItem(salesRoute.salesRouteDefinitionId)">
                    <span *ngIf="hasUser"> {{ salesRoute.name }} - {{salesRoute.assignedUser?.firstName}}
                        {{salesRoute.assignedUser?.lastName}} </span>
                    <span *ngIf="!hasUser"> {{ salesRoute.name }} </span>
                </mat-option>
            </ng-container>
        </ng-container>
    </mat-select>
</mat-form-field>
<mat-progress-spinner *ngIf="loading === true" diameter="16" mode="indeterminate"></mat-progress-spinner>