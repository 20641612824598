import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

import { Response, PagedResponse, SalesRouteStructure } from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class SalesRouteStructureService {
  constructor(private http: HttpClient) { }

  search(request?: any): Observable<Response<any>>  {
    return this.http.post<PagedResponse<any>>(environment.apiUrl + '/api/SalesRouteStructure/Search', request);
  }

  update(request: any): Promise<Response<SalesRouteStructure>> {
    return this.http.post<Response<SalesRouteStructure>>(environment.apiUrl + '/api/SalesRouteStructure/Update', request).toPromise();
  }

  insert(request: any): Promise<Response<SalesRouteStructure>> {
    return this.http.post<Response<SalesRouteStructure>>(environment.apiUrl + '/api/SalesRouteStructure/Insert', request).toPromise();
  }

  delete(request: any): Promise<Response<SalesRouteStructure>> {
    return this.http.post<Response<SalesRouteStructure>>(environment.apiUrl + '/api/SalesRouteStructure/Delete', request).toPromise();
  }
}
