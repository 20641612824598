import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

import { PagedResponse, Response, BoardInfo, BoardInfoRequest, BoardInfoSearchRequest, BoardInfoUpdateNameRequest, BoardInfoListForBoardRequest } from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class BoardInfoService {

  constructor(private http: HttpClient) { }

  insert(request: BoardInfoRequest): Observable<Response<BoardInfo>> {
    return this.http.post<Response<BoardInfo>>(environment.apiUrl + '/api/BoardInfo/Insert', request);
  }

  update(request: BoardInfoRequest): Observable<Response<BoardInfo>> {
    return this.http.post<Response<BoardInfo>>(environment.apiUrl + '/api/BoardInfo/Update', request);
  }

  delete(request: BoardInfoRequest): Observable<Response<BoardInfo>> {
    return this.http.post<Response<BoardInfo>>(environment.apiUrl + '/api/BoardInfo/Delete', request);
  }

  search(request?: BoardInfoSearchRequest): Observable<PagedResponse<BoardInfo>> {
    return this.http.post<PagedResponse<BoardInfo>>(environment.apiUrl + '/api/BoardInfo/Search', request);
  }

  listForBoard(request?: BoardInfoListForBoardRequest): Observable<PagedResponse<BoardInfo>> {
    return this.http.post<PagedResponse<BoardInfo>>(environment.apiUrl + '/api/BoardInfo/ListForBoard', request);
  }

  updateName(request: BoardInfoUpdateNameRequest): Observable<Response<BoardInfo>> {
    return this.http.post<Response<BoardInfo>>(environment.apiUrl + '/api/BoardInfo/UpdateName', request);
  }

}
