import {SystemUser, User} from '@core/api';

export class SystemUserAdapter {
  public static fromUser(user: User): SystemUser {
    return {
      systemUserId: user.userId,
      firstName: user.firstName,
      lastName: user.lastName,
      jobTitle: null,
      email: user.email,
      photo: user.image,
      samAccountName: null,
      employeeId: null
    };
  }
}
