import { KeyValue } from '@angular/common';
import { of } from 'rxjs';
import { ApiDataSource } from '../api.data-source';
import { ListviewDocumentDownloadService } from './listview-document-download.service';
import { debounceTime, finalize, map } from 'rxjs/operators';
import { SystemUserService } from '../system-user/system-user.service';
import { User } from '../api.model';
import { TranslateService } from '@ngx-translate/core';

export class ListviewDocumentDownloadDataSource extends ApiDataSource<any> {

    public $listviewDocuments = of<KeyValue<string, string>[]>([
        {
            key: '3b8dfa32-961e-4c21-b41e-3535b0bd06ee',
            value: this.translate.instant('ListviewDocument.Customers')
        },
        {
            key: '2a87cde8-e296-4159-815f-af2f97758c0a',
            value: this.translate.instant('ListviewDocument.Activities')
        },
        {
            key: 'e55c366c-3b1d-4cfe-914c-902c17604e57',
            value: this.translate.instant('ListviewDocument.Leads')
        },
        {
            key: '3fe7ac68-8f33-471e-ac67-192859fac72c',
            value: this.translate.instant('ListviewDocument.Tasks')
        },
        {
            key: 'ae12eeb3-55e4-4a38-81f2-1848530eda86',
            value: this.translate.instant('ListviewDocument.Open Opportunities')
        },
        {
            key: '111788eb-5fa7-4a71-bec2-6cac318e8666',
            value: this.translate.instant('ListviewDocument.Offers')
        },
        {
            key: '1b46c25d-fdad-4a25-a404-93347e70c948',
            value: this.translate.instant('ListviewDocument.Homepage')
        },
        {
            key: '7a6b7878-53b9-40df-b048-c2314c806cee',
            value: this.translate.instant('ListviewDocument.Closed Opportunities')
        },
        {
            key: '06c704d4-fde7-40eb-9f6f-e0fac7c7042f',
            value: this.translate.instant('ListviewDocument.Acquisition Report - Opportunities')
        },
        {
            key: '5deeb71e-aca0-4d6e-b604-85f26d9b982c',
            value: this.translate.instant('ListviewDocument.Sales Activities Report - Activities')
        },
        {
            key: '08472f67-5f3f-435a-a144-fb328b250303',
            value: this.translate.instant('ListviewDocument.Sales Activities Report - Activities by Type')
        },
        {
            key: 'c626d3f4-84c8-4bcf-b3f3-afcbb4aafef1',
            value: this.translate.instant('ListviewDocument.Sales Activities Report - Activities by Purpose')
        },
        {
            key: 'f6daea78-e6aa-41b0-8454-976fd624bad3',
            value: this.translate.instant('ListviewDocument.Pipeline Report - Opportunities')
        },
        {
            key: 'c369ba5c-9b7d-41e6-a3cb-d4de7a878aad',
            value: this.translate.instant('ListviewDocument.Leads Report - Assignments by Route')
        },
        {
            key: '38220973-abcb-4ac4-9237-e7f914c5b9ca',
            value: this.translate.instant('ListviewDocument.Leads Report - Leads')
        },
        {
            key: '10655259-5572-4c22-9a05-a18b7d64068d',
            value: this.translate.instant('ListviewDocument.Offer Analysis Report - Status Based Offers')
        },
        {
            key: '556dbe96-5282-49ec-a4ac-097e79a5f75c',
            value: this.translate.instant('ListviewDocument.Offers Analysis Report - Offers')
        },
        {
            key: '477c2f46-83bb-4ca3-8701-fab9401284ea',
            value: this.translate.instant('ListviewDocument.Opportunity Offer Report - Status Based Opportunities')
        },
        {
            key: 'b06fb50c-ad4b-4a85-9ad4-cda0727771f8',
            value: this.translate.instant('ListviewDocument.Opportunities Offer Report - Opportunities')
        },
        {
            key: '5f957ce7-068b-43cf-9a43-b8eeb7b56987',
            value: this.translate.instant('ListviewDocument.Shipment Profiles')
        },
        {
            key: '5b8474d5-e384-4f1c-ab31-6908975fb8fd',
            value: this.translate.instant('ListviewDocument.Board')
        }
    ].sort((a, b) => a.value.localeCompare(b.value)));

    public $userList = this.systemUserService
        .userSearch({ filter: { salesOrganizationIds: this.user.salesOrganizations.map(item => item) } }).pipe(
            map(response => response.data.results),
            map(statuses => statuses.map(item => {
                return {
                    key: item.systemUserId,
                    value: [item.firstName, item.lastName].join(' '),
                };
            }))
        );

    constructor(
        private listviewDocumentDownloadService: ListviewDocumentDownloadService,
        private systemUserService: SystemUserService,
        private user: User,
        private translate: TranslateService,
        protected initialFilter?: any
    ) {
        super(initialFilter);
    }

    load(): void {

        // Init filter with data source's default filter
        const filter: any = { ...this.initialFilter, ...this.filter };

        // If filter keyword exists, filter data
        if (this.keyword) {
            filter.searchText = this.keyword;
        }

        // Update loading state
        this.loadingSubject.next(true);

        // Create request parameters
        const request = this.getRequest();

        if (this.paginator?.pageSize) {
            request.pageSize = this.paginator.pageSize;
        }

        // Add filters to request
        request.filter = filter;

        // Fetch data
        this.listviewDocumentDownloadService
            .search(request)
            .pipe(
                debounceTime(400),
                finalize(() => this.loadingSubject.next(false))
            ).subscribe(response => {
                // Update count and data subjects
                this.dataSubject.next(response.data.results);
                this.dataCountSubject.next(response.data.rowCount);

                // Update data source's empty based row count
                this.empty = response.data.rowCount === 0;
            });


    }

}
