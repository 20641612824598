import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import { Observable } from 'rxjs';
import { FuelCity, PagedResponse, FuelCityFilterRequest } from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class FuelCityService {
  constructor(private http: HttpClient) { }

  search(request: FuelCityFilterRequest): Observable<PagedResponse<FuelCity>> {
    return this.http.post<PagedResponse<FuelCity>>(environment.apiUrl + '/api/FuelCity/Search', request);
  }
}
