import { take } from 'rxjs/operators';

import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  Account,
  Activity,
  ActivityMailRequest,
  ActivityMailService,
  ActivityRequest,
  ActivityService,
  ActivityStatusType,
  ApiService,
  Language,
  LeadDraftInfo,
  SalesOrganizationAdvancedSettingType
} from '@core/api';
import { getSalesOrganizationSettingValue, ActivityInsertStatus } from '@core/store';
import icSave from '@iconify/icons-ic/twotone-save';
import icPdf from '@iconify/icons-ic/twotone-picture-as-pdf';
import icDownload from '@iconify/icons-mdi/download';
import icPdfOutLine from '@iconify/icons-mdi/file-pdf-outline';
import { Store } from '@ngrx/store';

import { ActivityDialogData } from './activity-form-dialog.component.model';
import { ActivityDialogTypeEnum } from './activity-form-dialog.enum';
import { createFileWindow } from '../../utils/open-file-url';
import { FileLoadingTranslationService } from '../../services/translate/file-loading-translation.service';
import moment from 'moment/moment';
import { AlertActivityDialogComponent } from 'src/app/activity/activity-detail/alert-activity-dialog/alert-activity-dialog.component';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'net-activity-form-dialog',
  templateUrl: './activity-form-dialog.component.html',
  styleUrls: ['./activity-form-dialog.component.scss'],
})
export class ActivityFormDialogComponent implements OnInit {

  account: Account;
  activity: Activity;
  leadDraftInfo: LeadDraftInfo;
  language: Language;
  hasEmail: boolean;
  request: ActivityRequest;
  activityStatus = ActivityStatusType;

  loading = false;
  activityMailLoading = false;

  icSave = icSave;
  icPdf = icPdf;
  icDownload = icDownload;
  icPdfOutLine = icPdfOutLine;

  form = new UntypedFormGroup({
    sendUsers: new UntypedFormArray([]),
    ccUsers: new UntypedFormControl(),
    activityPdfTemplate: new UntypedFormControl(),
    assigmentFlag: new UntypedFormControl(false),
    language: new UntypedFormControl({ languageId: '00ebc2ef-16b8-40ef-accf-2ba912b40e1a' })
  });

  formChanged = false;
  translations: { [key: string]: string };

  dialogAlertRef: MatDialogRef<any>;
  @ViewChild('alertActivity', { static: true }) alertActivity: TemplateRef<HTMLElement>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ActivityDialogData,
    private dialogRef: MatDialogRef<ActivityFormDialogComponent>,
    private activityService: ActivityService,
    private activityMailService: ActivityMailService,
    private api: ApiService,
    private dialog: MatDialog,
    private store: Store,
    private formBuilder: UntypedFormBuilder,
    private fileLoadingTranslationService: FileLoadingTranslationService,
    private translate: TranslateService
  ) {
    // Add general form dialog pane
    this.dialogRef.addPanelClass('form-dialog-pane');

    if (data && data.account) {
      this.account = data.account;
    }

    if (data && data.activity) {
      this.activity = data.activity;
    } else {
      this.activity = null;
    }

    if (data?.type === ActivityDialogTypeEnum.LEAD_DRAFT) {
      this.leadDraftInfo = data?.leadDraftInfo;
    }

    this.form.get('sendUsers').valueChanges.subscribe(value => {
      const language = this.form.get('language');
      if (value.filter(user => user.checked)?.length) {
        language.setValidators([Validators.required]);
        this.hasEmail = true;
      } else {
        language.clearValidators();
        this.hasEmail = false;
      }

      language.updateValueAndValidity();
    });

    translate.get(['GENERAL', 'ACTIVITY']).subscribe(translations => {
      this.translations = {
        ...translations.GENERAL,
        ...translations.ACTIVITY
      };
    });
  }

  ngOnInit() {
    this.dialogRef.disableClose = true;
    this.dialogRef.backdropClick().subscribe(() => {
      this.confirmCloseDialog();
    });

    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === 'Escape') {
        this.confirmCloseDialog();
      }
    });
  }

  onSave(request: ActivityRequest) {
    switch (request.activityAction) {
      case 'save_send':
        this.saveAndSend(request);
        break;
      case 'save':
        this.onSubmit(request);
        break;
      case 'download':
        this.download(request);
        break;
      case 'preview':
        this.download(request);
        break;
    }
  }

  download(request: ActivityRequest) {
    this.fileLoadingTranslationService.setTranslations();
    // Create new file window
    const fileWindow = createFileWindow();
    // Helper for start activity download
    const download = () => {
      this.activity.activityPdfTemplateId = request.activityPdfTemplateId;
      this.activityService
        .download(this.activity, request.activityAction, this.form.get('language').value?.languageId, fileWindow)
        .catch(() => fileWindow.close())
        .finally(() => {
          this.fileLoadingTranslationService.removeTranslations();
        });
    };

    if (this.activity) {
      download();
      return;
    }
  }

  async saveAndSend(request: ActivityRequest) {
    this.request = { ...request, mailSentFlag: false };
    this.dialogAlertRef = this.dialog.open(AlertActivityDialogComponent,
      {
        autoFocus: false,
        data: {
          request,
          activity: this.activity
        }
      });
    // Watch for close and revert change in case of cancel
    this.dialogAlertRef
      .addPanelClass('confirm-dialog')
      .afterClosed()
      .subscribe((dialogCloseResponse) => {
        if (dialogCloseResponse.confirm) {
          this.save(this.request).then(response => {
            this.insertActivityMail(
              response.activityId,
              dialogCloseResponse.users,
              dialogCloseResponse.ccUsers,
              dialogCloseResponse.assignmentFlag,
              dialogCloseResponse.languageId,
              dialogCloseResponse.documentIds);
          });
        } else {
          this.loading = false;
        }
      });
  }

  async onSubmit(request: ActivityRequest) {
    this.loading = true;

    this.save(request);
  }

  private save(request: ActivityRequest): Promise<Activity> {
    request.mailSentFlag = true;
    return new Promise((resolve, reject) => {
      request.activityAssignments.map(item => {
        item.assignment.dueDate = moment(item.assignment.dueDate).format('YYYY/MM/DD').toString();
        item.assignment.salesOrganizationId = item.assignment.salesOrganizationId ?? this.leadDraftInfo.salesOrganizationId;
      });
      if (this.activity) {
        request.activityId = this.activity.activityId;

        this.activityService.update(request).subscribe(
          response => {
            this.dialogRef.close(response.data);
            this.store.dispatch(new ActivityInsertStatus(true));
            resolve(response.data);
          },
          (err) => {
            reject(err);
          }
        ).add(() => this.loading = false);
        return;
      }

      if (this.data?.type === ActivityDialogTypeEnum.LEAD_DRAFT) {
        this.api.leadDraft.info.activityInsert(request, this.leadDraftInfo.leadDraftInfoId).subscribe(response => {
          if (response.success) {
            this.dialogRef.close(response.data);
            resolve(response.data.activity);
          }
        });

        return;
      }

      this.activityService.insert(request).subscribe(
        response => {
          if (response.success) {
            this.dialogRef.close(response.data);
            this.store.dispatch(new ActivityInsertStatus(true));
            resolve(response.data);
          }
        },
        (err) => {
          reject(err);
        }
      ).add(() => this.loading = false);
    });
  }

  findActivityUsers(request: ActivityRequest) {
    this.getSendUserForm().clear();

    if (request.activityContacts.length > 0) {
      request.activityContacts.forEach(contact => {
        this.getSendUserForm().push(this.createSendUserForm({
          contactId: contact.contactId,
          name: `${contact.firstName} ${contact.lastName}`,
          checked: true
        }));
      });
    }

    if (request.activitySystemUsers.length > 0) {
      request.activitySystemUsers.forEach(systemUser => {
        this.getSendUserForm().push(this.createSendUserForm({
          systemUserId: systemUser.systemUserId,
          name: `${systemUser.firstName} ${systemUser.lastName}`,
          checked: true
        }));
      });
    }
  }

  getSendUserForm(): UntypedFormArray {
    return this.form.get('sendUsers') as UntypedFormArray;
  }

  createSendUserForm(item: { contactId?: string; name: string; checked: boolean; systemUserId?: string; }): UntypedFormGroup {
    return this.formBuilder.group({
      contactId: [item ? item.contactId : null],
      systemUserId: [item ? item.systemUserId : null],
      name: [item ? item.name : null],
      checked: [item ? item.checked : null],
    });
  }

  insertActivityMail(activityId: string, users, ccUsers, assignmentFlag, languageId: string, documentIds: string[]) {
    let mergedUsers = users;
    if (ccUsers?.length > 0) {
      mergedUsers = [...users, ...ccUsers];
    }

    if (users?.length > 0) {
      const request: ActivityMailRequest = {
        activityId,
        contactIdList: mergedUsers.map(user => user.contactId).filter(n => n),
        systemUserIdList: mergedUsers.map(user => user.systemUserId).filter(n => n),
        languageId,
        assignmentFlag
      };

      this.activityMailService.insert(request).subscribe(response => {
      });
    }
  }

  closeAlertDialog(submit = false) {
    this.form.markAllAsTouched();

    if (this.form.valid || !submit) {
      this.dialogAlertRef.close(submit);
    }
  }

  closeActivityFormDialog(submit = false) {
    this.form.markAllAsTouched();

    if (submit && this.form.valid) {
      this.dialogRef.close(submit);
    } else {
      this.confirmCloseDialog();
    }
  }

  onFormChanged(changed: boolean) {
    this.formChanged = changed;
  }

  confirmCloseDialog() {
    if (this.formChanged) {
      Swal.fire({
        text: this.translations?.PAGE_LEAVE_WITHOUT_SAVING,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#F44336',
        cancelButtonColor: '#7a7a7a',
        confirmButtonText: this.translations?.YES,
        cancelButtonText: this.translations?.NO
      }).then((result) => {
        if (result.isConfirmed) {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }
}
