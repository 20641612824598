import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, DoCheck, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl, UntypedFormControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { SurveyMailType } from '@core/api/survey-mail-type/survey-mail-type.model';
import { SurveyMailTypeService } from '@core/api/survey-mail-type/survey-mail-type.service';
import { SurveyMailTypesOption } from '@core/api/survey-mail/survey-mail.enum';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'net-select-list-type',
  templateUrl: './select-list-type.component.html',
  styleUrls: ['./select-list-type.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: SelectListTypeComponent
    }
  ]
})
export class SelectListTypeComponent implements OnInit, OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<string>  {

  static nextId = 0;
  stateChanges = new Subject<void>();
  selectCtrl = new UntypedFormControl();
  focused = false;
  errorState = false;
  describedBy = '';
  controlType = 'select-list-type';


  surveyMailListTypes$: Observable<SurveyMailType[]>;

  @Input() isWarningSearch = false;
  @Input() isDisabled = false;
  @Input() tabIndex;
  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    if (value) {
      this.selectCtrl.disable();
    } else {
      this.selectCtrl.enable();
    }
    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): string { return this.selectCtrl.value; }
  set value(value: string) {
    this.selectCtrl.setValue(value);
    this.onChange(value);
    this.stateChanges.next();
  }

  @ViewChild('listTypeSelect') listTypeSelect: MatSelect;
  @HostBinding('id') id = `select-list-type-${SelectListTypeComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  get shouldLabelFloat() { return this.focused || !this.empty; }

  get empty() { return !this.value; }


  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private surveyMailTypeService: SurveyMailTypeService,
    @Optional() @Self() public ngControl: NgControl
  ) {
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.surveyMailListTypes$ = this.getSurveyMailListTypes();
  }

  ngOnInit(): void {
  }


  getSurveyMailListTypes() {
    return this.surveyMailTypeService.list({ orderBy: 'name' })
      .pipe(
        map(response => response.data),
        map(mailTypes => this.isWarningSearch ? mailTypes : mailTypes.filter(x => x.surveyMailListTypeId !== SurveyMailTypesOption.WARNINGS))
      );
  }

  onSelectionChange() {
    this.onChange(this.value);
    this.stateChanges.next();
  }

  openedChange(isOpened: boolean) {
    if (isOpened) {
      const panel = this.listTypeSelect.panel.nativeElement;
      panel.scrollTop = 0;
    }
  }


  compareWith = (one: string, two: string) => one && two && one === two;
  onChange = (_: any) => { };
  selectionChange = (_: any) => { };
  onTouched = () => { };
  onContainerClick = () => { };
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');
  writeValue = (value: string) => {
    this.value = value;
    this.selectCtrl.setValue(value);
  }

  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }

}
