import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { SalesOrganizationService } from '@core/api';
import {
    FilterLeadDraftSalesOrganizations,
    FilterLeadDraftSalesOrganizationsError,
    FilterLeadDraftSalesOrganizationsSuccess,
    LeadDraftActionTypes
} from '@core/store';

@Injectable()
export class LeadDraftEffects {
    unpickedSalesOrganizations = createEffect(() => this.actions$.pipe(
        ofType(LeadDraftActionTypes.FILTER_LEAD_DRAFT_SALES_ORGANIZATIONS),
        switchMap((leadDraft: FilterLeadDraftSalesOrganizations) => {
            return this.salesOrganizationsService.list({}).pipe(
                map(response => {
                    const leadDraftInfos = leadDraft.payload.leadDraftInfos;

                    const filteredData = response.data.filter(data => {
                        return leadDraftInfos.every(info => info.salesOrganizationId !== data.salesOrganizationId);
                    });

                    return new FilterLeadDraftSalesOrganizationsSuccess(filteredData);
                }),
                catchError(error => of(new FilterLeadDraftSalesOrganizationsError(error)))
            );
        })
    ));

    constructor(private actions$: Actions, private salesOrganizationsService: SalesOrganizationService) { }
}
