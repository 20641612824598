import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { SystemSettingType } from '@core/api';
import { getSystemSettingValue } from '@core/store';
import { UserConfigService } from '@core/user-config/user-config.service';
import { UserConfigType, UserConfigValue } from '@core/user-config/user-config.service.enum';
import { environment } from '@env/environment';
import icRadioButtonChecked from '@iconify/icons-ic/twotone-radio-button-checked';
import icRadioButtonUnchecked from '@iconify/icons-ic/twotone-radio-button-unchecked';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';

import packageInfo from '../../../../package.json';
import { trackByRoute } from '../../shared/utils/track-by';
import { NavigationItem } from '../navigation/navigation-item/navigation-item.interface';
import { LayoutService } from '../services/layout.service';
import { NavigationService } from '../services/navigation.service';
import { ScrollbarDirective } from '../../shared/directives/scrollbar.directive';
import { Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'net-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnDestroy {

  @ViewChild('scrollBar') scrollBarDirective: ScrollbarDirective;

  @Input() collapsed: boolean;
  collapsedOpen$: Observable<boolean>;

  items: NavigationItem[];
  trackByRoute = trackByRoute;
  icRadioButtonChecked = icRadioButtonChecked;
  icRadioButtonUnchecked = icRadioButtonUnchecked;

  appInfo: string;
  version = packageInfo.version;
  logoPath = { light: '', dark: '' };

  isDropdown = this.navigationService.isDropdown;

  constructor(
    private navigationService: NavigationService,
    private layoutService: LayoutService,
    private userConfig: UserConfigService,
    private store: Store,
  ) {
    this.navigationService.navigationItems$.pipe(untilDestroyed(this)).subscribe(response => {
      this.items = response;
    });

    this.appInfo = 'v' + this.version + ' - ' + environment.name;

    this.setSystemSettings();

    this.collapsedOpen$ = this.layoutService.sidenavCollapsedOpen$;
  }

  onMouseEnter() {
    this.layoutService.collapseOpenSidenav();
  }

  onMouseLeave() {
    this.layoutService.collapseCloseSidenav();
  }

  onClickedSidenavItem(item: NavigationItem) {
    if (this.isDropdown(item)) {
      setTimeout(() => {
        const scrollElem = this.scrollBarDirective?.scrollbarRef.getScrollElement();
        scrollElem?.scrollTo({ top: scrollElem.scrollHeight, behavior: 'smooth' });
      }, 225);
    }
  }

  toggleCollapse() {
    if (this.collapsed) {
      this.layoutService.expandSidenav();
      this.onMouseEnter();
      this.userConfig.set(UserConfigType.SIDENAV, UserConfigValue.SIDENAV_EXPANDED);
    } else {
      this.layoutService.collapseSidenav();
      this.onMouseLeave();
      this.userConfig.set(UserConfigType.SIDENAV, UserConfigValue.SIDENAV_COLLAPSED);
    }
  }

  setSystemSettings() {
    this.store.select(getSystemSettingValue(SystemSettingType.LOGO_LIGHT)).pipe(untilDestroyed(this)).subscribe(result => {
      this.logoPath.light = result as string;
    });

    this.store.select(getSystemSettingValue(SystemSettingType.LOGO_DARK)).pipe(untilDestroyed(this)).subscribe(result => {
      this.logoPath.dark = result as string;
    });
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy(): void {
  }
}
