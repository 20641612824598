<mat-select
  [tabIndex]="tabIndex"
  [formControl]="selectCtrl"
  [compareWith]="compareWith"
  (selectionChange)="onSelectionChange()">
  <mat-option *ngFor="let dangerousGood of dangerousGoods" [value]="dangerousGood">
    {{ ('DangerousGoods.' + dangerousGood.name) | translate }}
    <span fxHide.lt-md class="text-hint">{{ ('DangerousGoods.' + dangerousGood.description) | translate }}</span>
  </mat-option>
</mat-select>

<mat-progress-spinner *ngIf="loading === true" diameter="16" mode="indeterminate"></mat-progress-spinner>
