import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import { Observable } from 'rxjs';
import { Response, SystemUserPasswordCodeRequest, SystemUserPasswordCodes } from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class SystemUserPasswordCodeService {

  constructor(private http: HttpClient) { }

  mailConfirmation(request: SystemUserPasswordCodeRequest): Observable<Response<SystemUserPasswordCodes>> {
    return this.http.post<Response<SystemUserPasswordCodes>>(environment.apiUrl + '/api/SystemUserPasswordCode/MailConfirmation', request);
  }
}
