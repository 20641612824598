<input
  #input
  matInput
  [matTooltip]="'GENERAL.PLEASE_SELECT_BOARD' | translate"
  [tabIndex]="tabIndex"
  [formControl]="inputCtrl"
  [matAutocomplete]="auto"
  [matTooltipDisabled]="!!this.board"
  [readonly]="!this.board"
  (blur)="onBlur()">
<mat-autocomplete
  #auto="matAutocomplete"
  [panelWidth]="panelWidth"
  [displayWith]="displayName"
  [autoActiveFirstOption]="true"
  (optionSelected)="onSelect($event)"
  (closed)="onClosed()">
  <ng-container *ngIf="this.cards">
    <mat-option *ngFor="let card of cards" [value]="card">{{ card.cardCode }} - {{ card.name }}</mat-option>
  </ng-container>
</mat-autocomplete>
<mat-progress-spinner *ngIf="loading === true" diameter="16" mode="indeterminate"></mat-progress-spinner>
