<ng-container [formGroup]="form">
  <input
    (click)="datePicker.open()"
    [disabled]="isDisabled"
    [matDatepicker]="datePicker"
    [readOnly]="isDisabled"
    class="cursor-pointer"
    formControlName="date" fxFlex="65"
    matInput
    readonly>
  <button
    (click)="datePicker.open()"
    [disabled]="isDisabled"
    class="mx-4" fxFlex="10"
    mat-icon-button
    type="button">
    <mat-icon [icIcon]="icToday"></mat-icon>
  </button>
  <net-input-time
    [isDisabled]="isDisabled"
    [start]="0"
    [end]="23"
    class="cursor-pointer"
    formControlName="time"
    fxFlex="25"
    [defaultValue]="form.get('date').value && !form.get('time').value ? '00:00' : null"></net-input-time>
  <button
    (click)="onClearValue()"
    *ngIf="form.get('date').value || form.get('time').value"
    [disabled]="isDisabled"
    class="button-clear-trigger"
    mat-icon-button
    type="button">
    <mat-icon [icIcon]="icClear"></mat-icon>
  </button>
</ng-container>
<mat-datepicker #datePicker></mat-datepicker>
