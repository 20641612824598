<mat-select #channelSelect [tabIndex]="tabIndex" [formControl]="selectCtrl" [compareWith]="compareWith"
    (selectionChange)="onSelectionChange()" [multiple]="multiple" [placeholder]="'CX.CHANNEL' | translate"
    (openedChange)="openedChange($event)" (closed)="searchText = ''; filteredChannels = channels">

    <mat-select-trigger>
        <ng-container *ngIf="multiple && selectCtrl?.value?.length > 0">
            <ng-container *ngIf="selectCtrl.value.length > 2">
                {{'CX.CHANNEL_SELECTED' | translate: { value: selectCtrl.value.length } }}
            </ng-container>
            <ng-container *ngIf="selectCtrl.value.length <= 2">
                <ng-container *ngFor="let channelId of selectCtrl.value">
                    <ng-container *ngIf="getChannelById(channelId)">
                        {{ getChannelById(channelId)?.name }}
                        <ng-container *ngIf="selectCtrl.value.length > 1 && !isLastItem(channelId)">, </ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="!multiple || selectCtrl?.value?.length === 0">
            {{ getChannelById(selectCtrl.value)?.name }}
        </ng-container>
    </mat-select-trigger>

    <input *ngIf="multiple" (keydown)="$event.stopPropagation()" [(ngModel)]="searchText" (keyup)="onKey($event)"
        matInput style="width: 95%;" class="pt-4 pb-2 pl-4" maxlength="10"
        placeholder="{{'GENERAL.SEARCH_ELLIPSIS' | translate}}" type="text">

    <mat-option *ngIf="multiple" disabled="disabled" class="mat-option-button">
        <button mat-raised-button [disabled]="filteredChannels.length === 0" (click)="selectAll()">
            {{'GENERAL.SELECT_ALL' | translate }}
        </button>
        <button mat-raised-button [disabled]="filteredChannels.length === 0" (click)="deselectAll()">
            {{'GENERAL.REMOVE_ALL' | translate }}
        </button>
    </mat-option>

    <mat-option *ngFor="let channel of getFilteredList()" [value]="channel.cxChannelId"
        [class.mat-option-disabled]="!channel.activeFlag">
        {{ channel.name }}
        <span style="color:#979fa2; float:right">{{ channel?.cxChannelType?.name }}</span>
    </mat-option>
</mat-select>

<mat-progress-spinner *ngIf="loading === true" diameter="16" mode="indeterminate"></mat-progress-spinner>