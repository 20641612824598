import { Country, Response } from '@core/api';
import { Action } from '@ngrx/store';

export enum CountryActionTypes {
    LOAD_COUNTRIES = '[Country] LOAD_COUNTRIES',
    GET_COUNTRIES = '[Country] GET_COUNTRIES',
    GET_COUNTRIES_SUCCESS = '[Country] GET_COUNTRIES_SUCCESS',
    GET_COUNTRIES_ERROR = '[Country] GET_COUNTRIES_ERROR'
}

export class LoadCountries implements Action {
    readonly type = CountryActionTypes.LOAD_COUNTRIES;
}

export class GetCountries implements Action {
    readonly type = CountryActionTypes.GET_COUNTRIES;
}

export class GetCountriesSuccess implements Action {
    readonly type = CountryActionTypes.GET_COUNTRIES_SUCCESS;
    constructor(public payload: Country[]) { }
}

export class GetCountriesError implements Action {
    readonly type = CountryActionTypes.GET_COUNTRIES_ERROR;
    constructor(public payload: Response<null>) { }
}

export type CountryActions =
    LoadCountries |
    GetCountries |
    GetCountriesSuccess |
    GetCountriesError;
