import { Clipboard } from '@angular/cdk/clipboard';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root',
})
export class ClipboardService {
  constructor(
    private toastService: ToastService,
    private clipboard: Clipboard,
    private translate: TranslateService
  ) {}

  copyCardLink(cardId: string) {
    const url = `${window.location.origin}${window.location.pathname}?cardId=${cardId}`;
    this.toastService.success(
      this.translate.instant('GENERAL.CARD_LINK_COPIED')
    );
    this.clipboard.copy(url);
  }

  copyMessage(msg: string) {
    this.clipboard.copy(msg);
  }
}
