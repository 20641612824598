import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

import { Response, OpportunityPriceListBusinessDevelopment, OpportunityPriceListBusinessDevelopmentRequest } from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class OpportunityPriceListBusinessDevelopmentService {

  constructor(private http: HttpClient) { }

  insert(request: OpportunityPriceListBusinessDevelopmentRequest): Observable<Response<OpportunityPriceListBusinessDevelopment>> {
    // tslint:disable-next-line: max-line-length
    return this.http.post<Response<OpportunityPriceListBusinessDevelopment>>(environment.apiUrl + '/api/OpportunityPriceListBusinessDevelopment/Insert', request);
  }

  update(request: any): Observable<Response<OpportunityPriceListBusinessDevelopment>> {
    // tslint:disable-next-line: max-line-length
    return this.http.post<Response<OpportunityPriceListBusinessDevelopment>>(environment.apiUrl + '/api/OpportunityPriceListBusinessDevelopment/Update', request);
  }

  get(uuid: string): Observable<Response<OpportunityPriceListBusinessDevelopment>> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<OpportunityPriceListBusinessDevelopment>>(environment.apiUrl + '/api/OpportunityPriceListBusinessDevelopment/Get',
      JSON.stringify(uuid), { headers });
  }

  assignMe(request: any) {
    return this.http.post<Response<OpportunityPriceListBusinessDevelopment>>(environment.apiUrl + '/api/OpportunityPriceListBusinessDevelopment/AssignMe',
      request);
  }
}
