import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import { FilterRequest, Response, ActivityVideoConferenceTool, MeetingTypeUpdateRequest } from '@core/api';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class ActivityVideoConferenceToolService {
  constructor(private http: HttpClient) { }

  list(request?: FilterRequest): Observable<Response<ActivityVideoConferenceTool[]>> {
    return this.http.post<Response<ActivityVideoConferenceTool[]>>(environment.apiUrl + '/api/MeetingType/List', request);
  }

  bulkUpdateUsage(request?: MeetingTypeUpdateRequest[]): Observable<Response<any>>{
    return this.http.post<Response<any>>(environment.apiUrl + '/api/MeetingType/BulkUpdateUsage', request);
  }
}
