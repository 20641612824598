<mat-select #activityTypeSelect [placeholder]="placeholder" [formControl]="selectCtrl" multiple
    (selectionChange)="onSelectionChange()" [compareWith]="compareWith" (openedChange)="openedChange($event)">

    <mat-select-trigger *ngIf="value?.length > 1" [matTooltipDisabled]="!(value.length > 1 && disabled)"
        [matTooltip]="getToolTip()" [matTooltipClass]="'activity-tooltip'" [matTooltipPosition]="'below'">
        {{'GENERAL.ACTIVITY_TYPES_VALUE_SELECTED' | translate: {value:
        value?.length} }}
    </mat-select-trigger>

    <mat-option disabled="disabled" class="mat-option-button">
        <button mat-raised-button (click)="selectAll()">
            {{'GENERAL.SELECT_ALL' | translate }}
        </button>
        <button mat-raised-button (click)="deselectAll()">{{'GENERAL.REMOVE_ALL' |
            translate
            }}</button>
    </mat-option>

    <mat-option *ngFor="let item of activityTypes | orderBy: 'order': false"
        [value]="item.activityTypeId">{{'ActivityType.'
        +item.name |translate}}</mat-option>

</mat-select>