import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

import { AlertType } from './alert.service.enum';
import { AlertDialogComponent } from '../components/alert-dialog/alert-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private dialogRef: MatDialogRef<AlertDialogComponent>;

  constructor(private dialog: MatDialog) { }

  display(message: string, type?: AlertType, close = true, options?: MatDialogConfig): MatDialogRef<AlertDialogComponent> {
    if (!type) {
      type = AlertType.PLAIN;
    }

    if (!options) {
      options = {};
    }

    // Apply basic options
    options.autoFocus = false;
    options.disableClose = !close;
    options.closeOnNavigation = close;

    // Add alert dialog data
    options.data = { type, message };

    // Open material dialog
    this.dialogRef = this.dialog.open(AlertDialogComponent, options);

    return this.dialogRef;
  }

  show(message: string, close?: boolean): MatDialogRef<AlertDialogComponent> {
    return this.display(message, AlertType.PLAIN, close);
  }

  error(message: string, close?: boolean): MatDialogRef<AlertDialogComponent> {
    return this.display(message, AlertType.ERROR, close);
  }

  warn(message: string, close?: boolean): MatDialogRef<AlertDialogComponent> {
    return this.display(message, AlertType.WARNING, close);
  }

  info(message: string, close?: boolean): MatDialogRef<AlertDialogComponent> {
    return this.display(message, AlertType.INFO, close);
  }
}
