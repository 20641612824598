import { Router } from '@angular/router';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Account } from '@core/api';

import icClose from '@iconify/icons-ic/twotone-close';
import icKeyboardArrowRight from '@iconify/icons-ic/keyboard-arrow-right';

import { openRouteNewTab } from 'src/app/shared/utils/open-route-new-tab';

@Component({
  selector: 'net-multi-account-dialog',
  templateUrl: './multi-account-dialog.component.html',
  styleUrls: ['./multi-account-dialog.component.scss']
})
export class MultiAccountDialogComponent {
  accounts: Account[];
  openInNewPage = false;

  icClose = icClose;
  icKeyboardArrowRight = icKeyboardArrowRight;

  constructor(
    private router: Router,
    private dialogRef: MatDialogRef<MultiAccountDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: { accounts: Account[], openInNewPage: false },
  ){
    this.accounts = data?.accounts ?? [];
    this.openInNewPage = data?.openInNewPage ?? false;
  }

  onSelectContactWithAccount(accountId: string) {
    // Close select account dialog
    this.dialogRef.close();

    // Prepare account page navigation commands
    const commands = ['/leads', accountId];

    // Open account in new tab when requested
    if (this.openInNewPage) {
      openRouteNewTab(this.router, commands);
      return;
    }

    this.router.navigate(commands);
  }
}
