<button type="button" class="add-day" mat-icon-button (click)="add()" *ngIf="!disabled">
  <ic-icon [icon]="icAdd" size="16px"></ic-icon>
</button>
<ng-container [formGroup]="form">
  <div fxLayout="column" formArrayName="days">
    <div class="relative" fxLayout="row" fxLayoutAlign="start center" [formGroupName]="index"
      *ngFor="let control of getDaysForm().controls; let index = index">

      <!-- <mat-form-field fxFlex="25" class="mr-6">
        <mat-select formControlName="type" placeholder="{{'GENERAL.TYPE' | translate}}" [compareWith]="compareWithType">
          <mat-option *ngFor="let type of types" [value]="type">{{ ("SpecialDayType." + type.name) | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field> -->

      <mat-form-field fxFlex="20" class="mr-6">
        <input matInput [placeholder]="'GENERAL.DATE' | translate" readonly formControlName="date" [matDatepicker]="datePicker"
          (click)="datePicker.open()">
        <mat-datepicker #datePicker startView="multi-year"></mat-datepicker>
        <mat-datepicker-toggle [for]="datePicker" matSuffix></mat-datepicker-toggle>
      </mat-form-field>

      <mat-form-field fxFlex="grow">
        <input matInput formControlName="description" placeholder="{{'GENERAL.DESCRIPTION' | translate}}">
      </mat-form-field>

      <button type="button" class="remove-day" mat-icon-button (click)="remove(index)">
        <ic-icon [icon]="icMinus" size="16px"></ic-icon>
      </button>
    </div>
  </div>
</ng-container>