import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class NotePassService {

  private dataSourceUnreadCount = new BehaviorSubject<number>(0);
  unreadCounts = this.dataSourceUnreadCount.asObservable();
  constructor() { }

  updateUnreadCount(data: number){
    this.dataSourceUnreadCount.next(data);
  }
}
