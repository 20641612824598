import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import {
  Response,
  OrganizationMainServiceServiceModel,
  OrganizationMainServiceServiceRequest,
  FilterRequest,
  PagedResponse
} from '@core/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrganizationMainServiceServiceService {
  constructor(private http: HttpClient) { }

  search(request?: OrganizationMainServiceServiceRequest): Observable<Response<any[]>> {
    return this.http.post<Response<any[]>>(environment.apiUrl + '/api/OrganizationMainServiceService/Search', request);
  }

  list(request: FilterRequest): Observable<PagedResponse<OrganizationMainServiceServiceModel>> {
    return this.http.post<PagedResponse<OrganizationMainServiceServiceModel>>(environment.apiUrl + '/api/OrganizationMainServiceService/List', request);
  }
}
