<input #input matInput class="autocomplete-input" [tabIndex]="tabIndex"
  [placeholder]="'GENERAL.SEARCH_TO_SELECT' |translate" [matTooltip]="'GENERAL.NOT_FOUND' | translate"
  [formControl]="inputCtrl" [matAutocomplete]="auto" [matTooltipDisabled]="loading || assignments.length > 0"
  (blur)="onBlur()">
<mat-autocomplete #auto="matAutocomplete" [panelWidth]="panelWidth" [displayWith]="displayName"
  [autoActiveFirstOption]="true" (closed)="onClosed()" (optionSelected)="onSelect($event)">
  <ng-container *ngIf="this.assignments">
    <mat-option *ngIf="assignments.length === 0 && !loading && inputCtrl?.value?.length > 0" disabled>{{
      'GENERAL.NOT_FOUND' | translate}}</mat-option>
    <mat-option matTooltipPosition="right" matTooltipShowDelay="800" [value]="assignment"
      [matTooltip]="assignment.description" [matTooltipDisabled]="assignment.description?.length < 50"
      *ngFor="let assignment of assignments">
      <span>{{ assignment.description }} - {{ assignment.assignedUser.firstName }} {{ assignment.assignedUser.lastName
        }}</span>
      <span fxHide.lt-md class="text-hint"> - {{ assignment.code }}</span>
    </mat-option>
  </ng-container>
</mat-autocomplete>

<mat-progress-spinner *ngIf="loading === true" diameter="16" mode="indeterminate"></mat-progress-spinner>