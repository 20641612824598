import { combineLatest } from 'rxjs';
import { filter, map, startWith, withLatestFrom } from 'rxjs/operators';

import { BreakpointObserver } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav, MatSidenavContainer } from '@angular/material/sidenav';
import { NavigationEnd, Router, Scroll } from '@angular/router';
import {
  CustomDashboardManagementService, CustomerExperienceTicket, DynamicFieldService, SystemSettingMailProviderType, SystemSettingService,
  SystemSettingType, SystemSettingValue, User
} from '@core/api';
import { Permission } from '@core/auth/auth.enum';
import { AuthService } from '@core/auth/auth.service';
import { getSystemSettingValue, getUser, getUserPermissions, hasUserPermission } from '@core/store';
import { UserConfigService } from '@core/user-config/user-config.service';
import { UserConfigType, UserConfigValue } from '@core/user-config/user-config.service.enum';
import icCalendarToday from '@iconify/icons-ic/baseline-calendar-today';
import icSettings from '@iconify/icons-ic/settings';
import icAssessment from '@iconify/icons-ic/twotone-assessment';
import icAssignment from '@iconify/icons-ic/twotone-assignment';
import icAttachFile from '@iconify/icons-ic/twotone-attach-file';
import icBusinessCenter from '@iconify/icons-ic/twotone-business-center';
import icDashboard from '@iconify/icons-ic/twotone-dashboard';
import icCustomDashboard from '@iconify/icons-ic/twotone-dashboard-customize';
import icDateRange from '@iconify/icons-ic/twotone-date-range';
import icFileCopy from '@iconify/icons-ic/twotone-file-copy';
import icNotificationsActive from '@iconify/icons-ic/twotone-notifications-active';
import icPersonPin from '@iconify/icons-ic/outline-perm-contact-calendar';
import mdiVideoBox from '@iconify/icons-mdi/video-box';
import icLeads from '@iconify/icons-ic/twotone-supervised-user-circle';
import icEmail from '@iconify/icons-mdi/email';
import uilMeetingBoard from '@iconify-icons/uil/meeting-board';
import riSurveyLine from '@iconify/icons-ri/survey-line';
import fluPerson from '@iconify/icons-fluent/person-star-16-filled';
import icRoundTableChart from '@iconify/icons-ic/round-table-chart';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { fadeInRight400ms } from '../../shared/animations/fade-in-right.animation';
import { fadeInUp400ms } from '../../shared/animations/fade-in-up.animation';
import { scaleIn400ms } from '../../shared/animations/scale-in.animation';
import { stagger40ms } from '../../shared/animations/stagger.animation';
import { checkRouterChildsData } from '../../shared/utils/check-router-childs-data';
import theme from '../../shared/utils/tailwindcss';
import { ConfigService } from '../services/config.service';
import { HotkeyService } from '../services/hotkey.service';
import { LayoutService } from '../services/layout.service';
import { NavigationService } from '../services/navigation.service';
import { Style, StyleService } from '../services/style.service';
import { MailPreviewModel } from '../mail-preview/mail-preview.component.model';
import { MailPreviewComponentService } from '../mail-preview/mail-preview.component.service';
import { CustomerExperienceTicketPreviewService } from '../customer-experience-ticket-preview/customer-experience-ticket-preview.service';

@UntilDestroy()
@Component({
  selector: 'net-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
  animations: [
    fadeInUp400ms,
    fadeInRight400ms,
    stagger40ms,
    scaleIn400ms
  ]
})
export class MainLayoutComponent implements OnInit, AfterViewInit, OnDestroy {

  notificationCount = 0;
  user: User;
  isLayoutVertical$ = this.configService.config$.pipe(map(config => config.layout === 'vertical'));
  isBoxed$ = this.configService.config$.pipe(map(config => config.boxed));
  isToolbarFixed$ = this.configService.config$.pipe(map(config => config.toolbar.fixed));
  isFooterFixed$ = this.configService.config$.pipe(map(config => config.footer.fixed));
  isFooterVisible$ = this.configService.config$.pipe(map(config => config.footer.visible));

  openedSidenav$ = this.layoutService.openedSidenav$;
  searchOpen$ = this.layoutService.searchOpen$;
  sidenavCollapsed$ = this.layoutService.sidenavCollapsed$;

  isDesktop$ = this.breakpointObserver.observe(`(min-width: ${theme.screens.lg})`).pipe(
    map(state => state.matches)
  );

  toolbarShadowEnabled$ = this.router.events.pipe(
    filter<NavigationEnd>(event => event instanceof NavigationEnd),
    startWith(null as string),
    map(() => checkRouterChildsData(this.router.routerState.root.snapshot, data => data.toolbarShadowEnabled))
  );

  scrollDisabled$ = this.router.events.pipe(
    filter<NavigationEnd>(event => event instanceof NavigationEnd),
    startWith(null as string),
    map(() => checkRouterChildsData(this.router.routerState.root.snapshot, data => data.scrollDisabled))
  );

  containerEnabled$ = this.router.events.pipe(
    filter<NavigationEnd>(event => event instanceof NavigationEnd),
    startWith(null as string),
    map(() => checkRouterChildsData(this.router.routerState.root.snapshot, data => data.containerEnabled))
  );

  navigationItems = [];

  isFinanceUser = false;
  isAccountingUser = false;
  isSalesDevelopment = false;
  isCustomDashboardGetUrl = false;
  isAccessAll = false;
  isCustomDashboardList = false;
  isCustomListSearch = false;
  isCustomListGet = false;
  isNoMailProvider = false;

  translations: any;
  customDashboards = [];
  customDashboardsDropdown: any;

  mailPreviews: MailPreviewModel[] = [];
  leadlessUsage: string | number | boolean | SystemSettingValue[];

  ticketPreviews: CustomerExperienceTicket[] = [];

  customLists = [];
  customListsDropdown: any;

  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;
  @ViewChild(MatSidenavContainer, { static: true }) sidenavContainer: MatSidenavContainer;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private navigationService: NavigationService,
    private customDashboardManagementService: CustomDashboardManagementService,
    private styleService: StyleService,
    private router: Router,
    private hotkey: HotkeyService,
    private store: Store,
    private userConfig: UserConfigService,
    private translate: TranslateService,
    private auth: AuthService,
    private mailPreviewService: MailPreviewComponentService,
    private systemSettingService: SystemSettingService,
    private customerExperienceTicketPreviewService: CustomerExperienceTicketPreviewService,
    private dynamicFieldService: DynamicFieldService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.store.select(getUserPermissions).pipe(untilDestroyed(this)).subscribe(permissions => {
      this.isFinanceUser = permissions?.some(permission => permission.permissionCode === Permission.FINANCIAL_INQUIRY_UPDATE);
      this.isAccountingUser = permissions?.some(permission => permission.permissionCode === Permission.ACCOUNT_PRODUCTION_ACCOUNT_CODE);
      this.isSalesDevelopment = permissions?.some(permission => permission.permissionCode === Permission.CONTRACT_UPDATE);
      this.isCustomDashboardList = permissions?.some(permission => permission.permissionCode === Permission.CUSTOM_DASHBOARD_LIST);
      this.isCustomDashboardGetUrl = permissions?.some(permission => permission.permissionCode === Permission.CUSTOM_DASHBOARD_GET_URL);
      this.isAccessAll = permissions?.some(permission => permission.permissionCode === Permission.ACCESS_ALL);
      this.isCustomListSearch = permissions?.some(permission => permission.permissionCode === Permission.CUSTOM_LIST_SEARCH);
      this.isCustomListGet = permissions?.some(permission => permission.permissionCode === Permission.CUSTOM_LIST_GET);
    });

    // Check leadless switch
    this.store.select(getSystemSettingValue(SystemSettingType.ACCOUNT_USAGE_WITHOUT_LEAD_DRAFT)).pipe(untilDestroyed(this))
      .subscribe(response => this.leadlessUsage = response as boolean);

    this.store.select(getSystemSettingValue(SystemSettingType.MAIL_PROVIDERS)).pipe(untilDestroyed(this))
      .subscribe((value: string[]) => {
        const noProvider = value.find(v => v === SystemSettingMailProviderType.NO_PROVIDER);
        this.isNoMailProvider = noProvider !== undefined;
      });

    combineLatest([
      this.translate.get(['MENU', 'GENERAL']),
      this.store.select(getUser).pipe(untilDestroyed(this))
    ]).subscribe(async ([translations, user]) => {
      this.user = user;
      this.translations = { ...translations.MENU, ...translations.GENERAL };

      if (user && translations) {

        if ((this.isCustomListSearch && this.isCustomListGet) || this.isAccessAll) {
          await this.initCustomLists();
        }

        if ((this.isCustomDashboardGetUrl && this.isCustomDashboardList) || this.isAccessAll) {
          await this.initCustomDashboards();
        }

        this.setNavigationItems();
        this.initMenu();
      }

    });

    this.configService.updateConfig({
      footer: {
        visible: false
      }
    });

    // close sidenav on routing
    this.router.events.subscribe(() => {
      this.layoutService.closeOpportunityCommunication();
      this.layoutService.closeAccountCommunication();
      this.layoutService.closeCustomReportsCommunication();
    });

    // Subscribe to mail link previews
    this.mailPreviewService.previews$.pipe(untilDestroyed(this)).subscribe((previews) => this.mailPreviews = previews);

    // Subscribe to ticket chat previews
    this.customerExperienceTicketPreviewService.previews$.pipe(untilDestroyed(this)).subscribe(previews => this.ticketPreviews = previews);

    // Add main layout hotkeys
    this.hotkey.add('h', this.translations?.SHOW_KEYBOARD_SHORTCUTS)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.hotkey.openDialog());

    this.hotkey.add('control.shift.l', this.translations?.SIGN_OUT)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.auth.logout());

    this.hotkey.add('control.d', this.translations?.VIEW_SUMMARY_SCREEN)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.router.navigate(['/']));

    this.hotkey.add('control.l', this.translations?.LIST_LEADS)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.router.navigate(['/leads']));

    this.hotkey.add('control.f', this.translations?.OPENS_THE_SEARCH_SCREEN)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.layoutService.openSearch());
  }

  ngOnInit() {
    this.isDesktop$.pipe(
      filter(matches => !matches),
      untilDestroyed(this)
    ).subscribe(() => this.layoutService.expandSidenav());

    this.layoutService.sidenavOpen$.pipe(
      untilDestroyed(this)
    ).subscribe(open => open ? this.sidenav.open() : this.sidenav.close());

    this.router.events.pipe(
      filter<NavigationEnd>(event => event instanceof NavigationEnd),
      withLatestFrom(this.isDesktop$),
      filter(([event, matches]) => !matches),
      untilDestroyed(this)
    ).subscribe(() => this.sidenav.close());

    // Set sidenav user config
    switch (this.userConfig.get(UserConfigType.SIDENAV)) {
      case UserConfigValue.SIDENAV_EXPANDED:
        this.layoutService.expandSidenav();
        break;

      case UserConfigValue.SIDENAV_COLLAPSED:
        this.layoutService.collapseSidenav();
        break;
    }

    // Set style user config
    switch (this.userConfig.get(UserConfigType.STYLE)) {
      case UserConfigValue.STYLE_DEFAULT:
        this.styleService.setStyle(Style.default);
        break;

      case UserConfigValue.STYLE_LIGHT:
        this.styleService.setStyle(Style.light);
        break;

      case UserConfigValue.STYLE_DARK:
        this.styleService.setStyle(Style.dark);
        break;
    }
  }

  ngAfterViewInit(): void {
    this.router.events.pipe(
      filter<Scroll>(e => e instanceof Scroll),
      untilDestroyed(this)
    ).subscribe(e => {
      if (e.position) {
        // backward navigation
        this.sidenavContainer.scrollable.scrollTo({
          start: e.position[0],
          top: e.position[1]
        });
      } else if (e.anchor) {
        // anchor navigation

        const scroll = (anchor: HTMLElement) => this.sidenavContainer.scrollable.scrollTo({
          behavior: 'smooth',
          top: anchor.offsetTop,
          left: anchor.offsetLeft
        });

        let anchorElem = this.document.getElementById(e.anchor);

        if (anchorElem) {
          scroll(anchorElem);
        } else {
          setTimeout(() => {
            anchorElem = this.document.getElementById(e.anchor);
            scroll(anchorElem);
          }, 100);
        }
      } else {
        // forward navigation
        if (e.routerEvent.url.includes('video-conference/')) {
          if (e.routerEvent.url.includes('new') || e.routerEvent.url.includes('opportunities')) {
            setTimeout(() => {
              this.sidenavContainer.scrollable.scrollTo({
                bottom: 0
              });
            }, 100);
          }
        } else {
          this.sidenavContainer.scrollable.scrollTo({
            top: 0,
            start: 0
          });
        }
      }
    });
  }

  initMenu() {
    const items = [];
    // Loop over navigation items and push to navigation services
    this.navigationItems.forEach((item) => {
      if (item === undefined) {
        return;
      }

      // Temporarily hide contact menu
      if (item.permission?.includes(Permission.CONTACT_LIST)) {
        return;
      }

      // Hide mail menu when no provider selected
      if (item.label === this.translate.instant('MENU.MAIL') && true === this.isNoMailProvider) {
        return;
      }

      // Hide lead draft field when leadless usage is active
      if (item.route === '/leads-drafts' && this.leadlessUsage) {
        return;
      }

      const tempItem: any = item;

      // Prepare nav item object
      const navItem: any = {
        type: tempItem.children ? 'dropdown' : 'link',
        label: tempItem.label,
        route: tempItem.route,
        icon: tempItem.icon,
        routerLinkActive: tempItem.routerLinkActive ? tempItem.routerLinkActive : { exact: false },
        children: [],
        badge: tempItem.badge,
      };

      // Add child menu if exists
      if (item.children) {
        item.children.forEach((child) => {
          this.store.select(hasUserPermission(child.permission)).pipe(untilDestroyed(this)).subscribe(resultChild => {
            if (resultChild) {
              navItem.children.push(child);
            }
          });
        });
      }

      // If menu needs permission definition check via auth service otherwise add directly
      if (item.permission) {
        this.store.select(hasUserPermission(item.permission)).pipe(untilDestroyed(this)).subscribe(result => {
          if (result) {
            items.push(navItem);
          }
        });
        return;
      }

      items.push(navItem);
    });

    // if ((this.isFinanceUser || this.isAccountingUser) && !this.isSalesDevelopment) {
    //   items.shift();
    // }

    // Update navigation items
    this.navigationService.updateNavigationItems(items);
  }

  async initCustomDashboards() {
    await this.customDashboardManagementService.list(this.user?.userId).toPromise().then(items => {
      if (this.customDashboards.length < 1) {
        items?.data?.map(item => {
          this.customDashboards.push({
            type: 'link',
            permission: [Permission.CUSTOM_DASHBOARD_LIST, Permission.CUSTOM_DASHBOARD_GET_URL],
            label: item.name,
            route: '/custom-dashboard/' + item.customDashboardId + '?' + item.name,
          });
        });
        if (this.customDashboards.length > 0) {
          this.customDashboardsDropdown = {
            type: 'link',
            label: this.translate.instant('MENU.CUSTOM_DASHBOARDS'),
            icon: icCustomDashboard,
            permission: [Permission.CUSTOM_DASHBOARD_LIST, Permission.CUSTOM_DASHBOARD_GET_URL],
            route: '/custom-report-table',
          };
        }
      }
    });
  }

  async initCustomLists() {
    if (this.customLists.length > 0) {
      return;
    }
    await this.dynamicFieldService.customList.list().toPromise().then(items => {
      this.customLists = items?.data?.map(item => ({
        type: 'link',
        permission: [Permission.CUSTOM_LIST_GET],
        label: this.translate.instant('CustomListDynamicField.' + item.name),
        route: '/custom-list/' + item.customListId,
      }));

      if (this.customLists.length > 0) {
        this.customListsDropdown = {
          type: 'link',
          label: this.translate.instant('MENU.CUSTOM_LISTS'),
          icon: icRoundTableChart,
          permission: [Permission.CUSTOM_LIST_SEARCH, Permission.CUSTOM_LIST_GET],
          children: [...this.customLists]
        };
      }
    });
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy(): void {
  }

  private setNavigationItems() {
    this.navigationItems = [
      {
        type: 'link',
        label: this.translate.instant('MENU.DASHBOARD'),
        route: '/',
        icon: icDashboard,
        routerLinkActive: { exact: true },
        permission: [Permission.HOME_DASHBOARD]
      },
      {
        label: this.translate.instant('MENU.LEADS'),
        icon: icFileCopy,
        route: '/leads-drafts',
        permission: [Permission.LEAD_DRAFT_LIST]
      },
      {
        label: this.translate.instant('MENU.CUSTOMERS'),
        icon: icLeads,
        route: '/leads',
        permission: [Permission.ACCOUNT_LIST]
      },
      {
        label: this.translate.instant('MENU.ACTIVITIES'),
        icon: icDateRange,
        route: '/activities',
        permission: [Permission.ACTIVITY_LIST]
      },
      {
        label: this.translate.instant('MENU.OPPORTUNITIES'),
        icon: icBusinessCenter,
        route: '/opportunities',
        permission: [Permission.OPPORTUNITY_LIST]
      },
      {
        label: this.translate.instant('MENU.OFFERS'),
        icon: icAttachFile,
        route: '/offers',
        permission: [Permission.OFFER_LIST]
      },
      {
        label: this.translate.instant('MENU.CONTACTS'),
        icon: icPersonPin,
        route: '/contacts',
        permission: [Permission.MENU_CONTACT]
      },
      {
        label: this.translate.instant('MENU.NPS_REPORT'),
        icon: riSurveyLine,
        route: '/nps-report',
        permission: [Permission.MENU_NPS_REPORT]
      },
      {
        icon: icAssignment,
        label: this.translate.instant('MENU.TASKS'),
        route: '/assignment',
        permission: [Permission.ASSIGNMENT_LIST],
        badge: {
          value: '',
          background: '#ff4946',
          color: '#ffeeee'
        },
      },
      {
        label: this.translate.instant('MENU.MAIL'),
        icon: icEmail,
        route: '/mail',
        badge: {
          value: '',
          background: '#ff4946',
          color: '#ffeeee'
        },
        permission: [Permission.MAIL_MAIN_MENU]
      },
      {
        label: this.translate.instant('MENU.BOARD'),
        icon: uilMeetingBoard,
        route: '/board',
        permission: [Permission.BOARD_ADMIN, Permission.BOARD_SEARCH],
      },
      {
        label: this.translate.instant('MENU.CX'),
        icon: fluPerson,
        route: '/customer-experience',
        permission: [Permission.CUSTOMER_EXPERIENCE_MENU],
      },
      {
        icon: icNotificationsActive,
        label: this.translate.instant('MENU.NOTIFICATIONS'),
        route: '/notification',
        badge: {
          value: '',
          background: '#ff4946',
          color: '#ffeeee'
        },
        notification: 'bildirimler'
      },
      {
        label: this.translate.instant('MENU.VIDEO_CONFERENCE'),
        icon: mdiVideoBox,
        route: '/video-conference',
        permission: [Permission.SALES_CALL_MONITOR]
      },
      {
        label: this.translate.instant('MENU.CALENDAR'),
        icon: icCalendarToday,
        route: '/calendar',
        permission: [Permission.TEMP_CALENDAR],
      },
      this.customListsDropdown,
      this.customDashboardsDropdown,
      {
        type: 'dropdown',
        label: this.translate.instant('MENU.REPORTS'),
        icon: icAssessment,
        permission: [Permission.REPORT_LIST],
        children: [
          {
            type: 'link',
            label: this.translate.instant('MENU.SALES_ACTIVITY_REPORT'),
            route: '/reports/activity-trend',
            section: 'reports',
            permission: [Permission.REPORT_SALES_ACTIVITY]
          },
          {
            type: 'link',
            label: this.translate.instant('MENU.OPPORTUNITY_REPORT'),
            route: '/reports/opportunity-report',
            section: 'reports',
            permission: [Permission.REPORT_OPPORTUNITY]
          },
          {
            type: 'link',
            label: this.translate.instant('MENU.OPPORTUNITY_OFFER_REPORT'),
            route: '/reports/opportunity-offer-report',
            section: 'reports',
            permission: [Permission.REPORT_OPPORTUNITY_OFFER]
          },
          {
            type: 'link',
            label: this.translate.instant('MENU.GAIN_REPORT'),
            route: '/reports/gain-report',
            section: 'reports',
            permission: [Permission.GAIN_REPORT]
          },
          {
            type: 'link',
            label: this.translate.instant('MENU.LEAD_DRAFT_REPORT'),
            route: '/reports/leads-drafts-report',
            section: 'reports',
            permission: [Permission.REPORT_LEADS_DRAFTS]
          },
          {
            type: 'link',
            label: this.translate.instant('MENU.OFFER_ANALYSIS'),
            route: '/reports/offers-report',
            section: 'reports',
            permission: [Permission.OFFERS_REPORT]
          },
          {
            type: 'link',
            label: this.translate.instant('MENU.SHIPMENT_PROFILE_REPORT'),
            route: '/reports/shipment-profile-report',
            section: 'reports',
            permission: [Permission.SHIPMENT_PROFILE_REPORT]
          },
          // {
          //   type: 'link',
          //   label: this.translate.instant('MENU.HASHTAG_REPORT'),
          //   route: '/reports/hashtag-report',
          //   permission: [Permission.HASHTAG_REPORT]
          // }
        ],
      },
      {
        type: 'dropdown',
        label: this.translate.instant('MENU.MANAGEMENT_PANEL'),
        icon: icSettings,
        permission: this.getManagementPanelPermissions(),
        children: [
          {
            type: 'link',
            label: 'User Guide',
            route: '/management/user-guide',
            permission: [Permission.USER_GUIDE_LIST],
          },
          {
            type: 'link',
            label: this.translate.instant('MENU.USER_LIST'),
            route: '/management/users',
            permission: [Permission.ACCESS_ALL],
          },
          {
            type: 'link',
            label: this.translate.instant('MENU.CURRENCY_MANAGEMENT'),
            route: '/management/currency-management',
            permission: [Permission.TRANSACTION_CURRENCY_MENU],
          },
          {
            type: 'link',
            label: 'Role Management',
            route: '/management/role-management',
            permission: [Permission.ACCESS_ALL],
          },
          {
            type: 'link',
            label: 'Sales Organization',
            route: '/management/sales-organization',
            permission: [Permission.SHOW_OPPORTUNITY_STATUS_PROCESS],
          },
          {
            type: 'link',
            label: 'Service Management',
            route: '/management/service-management',
            permission: [Permission.ACCESS_ALL],
          },
          {
            type: 'link',
            label: 'TAX Office',
            route: '/management/tax-office',
            permission: [Permission.ACCESS_ALL],
          },
          {
            type: 'link',
            label: this.translate.instant('MENU.ACCOUNT_MERGE'),
            route: '/management/account-merge',
            permission: [Permission.ACCOUNT_MERGE_INSERT],
          },
          {
            type: 'link',
            label: 'Dynamic Field Management',
            route: '/management/dynamic-field-management',
            permission: [Permission.DYNAMIC_FIELD_INSERT, Permission.DYNAMIC_FIELD_UPDATE],
          }, {
            type: 'link',
            label: this.translate.instant('MENU.DATA_EXPORTS'),
            route: '/management/data-exports',
            permission: [Permission.LISTVIEW_DOCUMENT_DOWNLOAD_SEARCH],
          },
          {
            type: 'link',
            label: this.translate.instant('MENU.DASHBOARD_MANAGEMENT'),
            route: 'management/custom-dashboard-management',
            permission: Permission.CUSTOM_DASHBOARD_SEARCH,
          },
          {
            type: 'link',
            label: this.translate.instant('MENU.SURVEY'),
            route: 'management/survey-management',
            permission: [Permission.MENU_SURVEY_MANAGEMENT],
          },
          {
            type: 'link',
            label: this.translate.instant('MENU.CX_SETTINGS'),
            route: '/management/customer-experience-management',
            permission: this.getCxSettingsPermissions()
          },
          {
            type: 'link',
            label: 'General Settings',
            route: '/management/system-setting-management',
            permission: [Permission.REDIS_FLUSH_DATABASES],
          },
          {
            type: 'link',
            label: this.translate.instant('MENU.LANGUAGE_MANAGEMENT'),
            route: '/management/language-management',
            permission: [Permission.LANGUAGE_MANAGEMENT],
          },
        ],
      },
    ];

    this.navigationItems = [...this.navigationItems];
  }

  private getManagementPanelPermissions(): Permission[] {
    return [
      Permission.ACCOUNT_MERGE_INSERT, Permission.USER_GUIDE_LIST, Permission.DYNAMIC_FIELD_MANAGEMENT_ACTIVITY_PURPOSE,
      Permission.DYNAMIC_FIELD_MANAGEMENT_CUSTOMER_TYPE, Permission.TRANSACTION_CURRENCY_MENU, Permission.SHOW_OPPORTUNITY_STATUS_PROCESS,
      Permission.LANGUAGE_MANAGEMENT, Permission.DYNAMIC_FIELD_INSERT, Permission.DYNAMIC_FIELD_UPDATE, Permission.CUSTOM_DASHBOARD_SEARCH,
      Permission.MENU_SURVEY_MANAGEMENT, Permission.LISTVIEW_DOCUMENT_DOWNLOAD_SEARCH, Permission.CUSTOMER_EXPERIENCE_CHANNEL_SETTINGS_MENU,
      Permission.CUSTOMER_EXPERIENCE_CATEGORIES_AND_STATUSES_MENU, Permission.CUSTOMER_EXPERIENCE_AUTOMATIONS_MENU, Permission.CUSTOMER_EXPERIENCE_SLA_MENU,
      Permission.CUSTOMER_EXPERIENCE_QUICK_REPLIES_MENU, Permission.CUSTOMER_EXPERIENCE_WORKING_HOURS_MENU, Permission.CUSTOMER_EXPERIENCE_CHARTS_MENU,
    ];
  }

  private getCxSettingsPermissions(): Permission[] {
    return [
      Permission.CUSTOMER_EXPERIENCE_CHANNEL_SETTINGS_MENU,
      Permission.CUSTOMER_EXPERIENCE_CATEGORIES_AND_STATUSES_MENU,
      Permission.CUSTOMER_EXPERIENCE_AUTOMATIONS_MENU,
      Permission.CUSTOMER_EXPERIENCE_SLA_MENU,
      Permission.CUSTOMER_EXPERIENCE_QUICK_REPLIES_MENU,
      Permission.CUSTOMER_EXPERIENCE_WORKING_HOURS_MENU,
      Permission.CUSTOMER_EXPERIENCE_CHARTS_MENU
    ];
  }

  close() {
    this.layoutService.closeAccountCommunication();
  }
}
