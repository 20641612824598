import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';

import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
    Component, DoCheck, ElementRef, HostBinding, Input, OnDestroy, Optional, Self, ViewChild
} from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatFormFieldControl } from '@angular/material/form-field';
import { County, LocationService, Neighborhood } from '@core/api';

@Component({
  selector: 'net-select-neighborhood[county]',
  templateUrl: './select-neighborhood.component.html',
  styleUrls: ['./select-neighborhood.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: SelectNeighborhoodComponent
    }
  ]
})
export class SelectNeighborhoodComponent implements OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<Neighborhood | null> {

  static nextId = 0;

  loading = false;
  focused = false;
  errorState = false;
  controlType = 'select-neighborhood';
  describedBy = '';
  stateChanges = new Subject<void>();

  inputCtrl = new UntypedFormControl();
  neighborhoods: Neighborhood[] = [];

  selectedCounty: County;

  @ViewChild('auto') auto: MatAutocomplete;
  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  @Input() tabIndex;

  @Input() set county(county: County) {
    this.selectedCounty = null;
    this.reset();

    if (county) {
      this.loading = true;
      this.selectedCounty = county;

      this.inputCtrl.disable();

      // Init with default list
      this.initDefaultOptions().finally(() => {
        this.loading = false;
        if (!this.disabled) {
          this.inputCtrl.enable();
        }
      });
    }
  }

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    if (value) {
      this.inputCtrl.disable();
    } else {
      this.inputCtrl.enable();
    }

    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): Neighborhood | null { return this._value; }
  set value(value: Neighborhood | null) {
    this._value = value;
    this.onChange(value);
    this.stateChanges.next();
  }
  private _value: Neighborhood;

  @HostBinding('id') id = `select-neighborhood-${SelectNeighborhoodComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  get shouldLabelFloat() { return !!this.selectedCounty && this.focused || !this.empty; }

  get empty() { return !this.value; }

  private initDefaultOptions(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.locationService.neighborhoods({
        filter: {
          countyId: this.selectedCounty.countyId
        },
        pageSize: 100
      }).toPromise()
        .then((response) => this.neighborhoods = response.data.results)
        .finally(() => resolve());
    });
  }

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private locationService: LocationService,
    @Optional() @Self() public ngControl: NgControl
  ) {
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    // Listen input control value changes and filter results
    this.inputCtrl.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      filter(value => this.selectedCounty && typeof value === 'string'),
      tap(() => {
        this.loading = true;
        this.neighborhoods = [];
        this.value = null;
      }),
      switchMap(value => this.locationService.neighborhoods({
        filter: {
          countyId: this.selectedCounty.countyId,
          neighborhoodName: value
        },
        pageSize: 100
      })),
      tap(() => this.loading = false)
    ).subscribe((response) => this.neighborhoods = response.data.results);
  }

  displayName = (neighborhood: Neighborhood) => neighborhood ? neighborhood.name : '';

  reset() {
    if (this.inputCtrl.pristine) {
      return;
    }

    this.value = null;
    this.neighborhoods = [];
    this.inputCtrl.setValue(null);
    this.inputCtrl.markAsPristine();

    if (this.selectedCounty) {
      this.initDefaultOptions();
    }
  }

  onSelect(event: MatAutocompleteSelectedEvent) {
    this.value = event.option.value;
    this.input.nativeElement?.blur();
  }

  onBlur() {
    // On blur with nothing selected reset component
    if (this.ngControl.dirty && !this.auto.isOpen && !this.value) {
      this.reset();
    }
  }

  onClosed() {
    // On panel close with nothing selected reset component
    if (this.ngControl.dirty && !this.value) {
      this.reset();
    }
  }

  onChange = (_: any) => { };
  onTouched = () => { };
  onContainerClick = () => { };
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');

  writeValue(value: Neighborhood) {
    this.value = value;
    this.inputCtrl.setValue(value);
  }

  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }
}
