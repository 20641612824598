import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    PagedResponseData, Response, TransactionCurrencyExchange,
    TransactionCurrencyExchangeFilterRequest, TransactionCurrencyExhangeSearchRequest
} from '@core/api';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class CurrencyExchangeService {

  constructor(private http: HttpClient) { }

  insert(request: TransactionCurrencyExchange) {
    return this.http.post(environment.apiUrl + '/api/TransactionCurrencyExchangeRate/Insert', request);
  }

  delete(request: TransactionCurrencyExchange) {
    return this.http.post(environment.apiUrl + '/api/TransactionCurrencyExchangeRate/Delete', request);
  }

  deleteAll(transactionCurrencyId: string) {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post(environment.apiUrl + '/api/TransactionCurrencyExchangeRate/DeleteAll',
      JSON.stringify(transactionCurrencyId), { headers });
  }

  update(request: TransactionCurrencyExchange) {
    return this.http.post(environment.apiUrl + '/api/TransactionCurrencyExchangeRate/Update', request);
  }

  list(request: TransactionCurrencyExchangeFilterRequest) {
    return this.http.post<Response<PagedResponseData<TransactionCurrencyExchange>>>
      (environment.apiUrl + '/api/TransactionCurrencyExchangeRate/List', request);
  }

  search(request: TransactionCurrencyExhangeSearchRequest) {
    return this.http.post<Response<PagedResponseData<TransactionCurrencyExchange>>>
      (environment.apiUrl + '/api/TransactionCurrencyExchangeRate/Search', request);
  }
}
