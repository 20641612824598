<input [formControl]="selectCtrl" matInput *ngIf="disabled">
<mat-select
  *ngIf="!disabled"
  [tabIndex]="tabIndex"
  [formControl]="selectCtrl"
  (selectionChange)="onSelectionChange()">
  <mat-option *ngFor="let clearance of clearances" [value]="clearance.clearanceLevelId">{{ clearance.name }}</mat-option>
</mat-select>

<mat-progress-spinner *ngIf="loading === true" diameter="16" mode="indeterminate"></mat-progress-spinner>
