import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import { Observable } from 'rxjs';
import { BaseResponse, Response, MailAddRangeRequest, MailAddRangeResponse, OpportunityMailRequest } from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class OpportunityMailService {
  constructor(private http: HttpClient) { }

  sendMail(request: OpportunityMailRequest): Observable<BaseResponse> {
    return this.http.post<BaseResponse>(environment.apiUrl + '/api/Opportunity/SendMail', request);
  }

  updateMail(request: MailAddRangeRequest) {
    return this.http.post<Response<MailAddRangeResponse[]>>(environment.apiUrl + '/api/Email/AddRange', request);
  }
}
