import { Card } from '@core/api/card/card.model';
import { BoardResponse } from '../board-signal-r-response.model';
import { CardActionType } from '@core/api/board-detail-signal-r-type/card-action-type.enum';
import { CardComment } from '@core/api/card-comment/card-comment.model';
import { Store } from '@ngrx/store';
import {
    GetBoardDetailFormDialogCardActionType, GetBoardDetailFormDialogCardAttachment,
    GetBoardDetailFormDialogCardChildCard, GetBoardDetailFormDialogCardComment,
    GetBoardDetailFormDialogCardDescription, GetBoardDetailFormDialogCardDynamicField,
    GetBoardDetailFormDialogCardObject, GetBoardDetailFormDialogCardReminderDate,
    SetBoardDetailFormDialog
} from '@core/store';

export function setCardPropertiesForBoardDetailFormDialog(cardsResponse: BoardResponse<Card>[], card: Card, store: Store): Card {

    if (Array.isArray(cardsResponse)) {
        cardsResponse.map(cardResponse => {
            setCardPropertiesLogic(cardResponse, card, store);
        });
    } else {

        setCardPropertiesLogic(cardsResponse, card, store);
    }

    return card;
}

export function setCardPropertiesLogic(cardResponse: BoardResponse<Card>, card: Card, store: Store): Card {
    switch (cardResponse.enumCardActionType) {
        case CardActionType.CardAdd:
            break;
        case CardActionType.CardDelete:
            cardDelete(cardResponse, card);
            break;
        case CardActionType.CardNameUpdate:
            nameUpdate(cardResponse, card);
            break;
        case CardActionType.CardSectionChange:
            sectionChange(cardResponse, card);
            break;
        case CardActionType.CardSetOrder:
            setOrder(cardResponse, card);
            break;
        case CardActionType.CardReminderDateUpdate:
            reminderDateUpdate(cardResponse, card, store);
            break;
        case CardActionType.CardSetComplete:
            setComplete(cardResponse, card, store);
            break;
        case CardActionType.CardParentCardConvertToSubcard:
            convertToSubcard(cardResponse, card, store);
            break;
        case CardActionType.CardSubcardConvertToParentCard:
            convertToParentCard(cardResponse, card, store);
            break;
        case CardActionType.CardAssigneeUserUpdate:
            assigneeUserUpdate(cardResponse, card);
            break;
        case CardActionType.CardAttachmentAdd:
            attachmentAdd(cardResponse, card, store);
            break;
        case CardActionType.CardAttachmentDelete:
            attachmentDelete(cardResponse, card, store);
            break;
        case CardActionType.CardLinkAdd:
            linkAdd(cardResponse, card);
            break;
        case CardActionType.CardLinkDelete:
            linkDelete(cardResponse, card);
            break;
        case CardActionType.CardCRMLinkUpdate:
            crmLinksUpdate(cardResponse, card, store);
            break;
        case CardActionType.CardCommentAdd:
            commentAdd(cardResponse, card, store);
            break;
        case CardActionType.CardCommentDelete:
            commentDelete(cardResponse, card, store);
            break;
        case CardActionType.CardCommentUpdate:
            commentUpdate(cardResponse, card, store);
            break;
        case CardActionType.CardCommentGetAll:
            getAllComments(cardResponse, card, store);
            break;
        case CardActionType.CardDueDateUpdate:
            dueDateUpdate(cardResponse, card);
            break;
        case CardActionType.CardDynamicFieldUpdate:
            updateDynamicFields(cardResponse, card, store);
            break;
        case CardActionType.CardDescriptionUpdate:
            descriptionUpdate(cardResponse, card, store);
            break;
        case CardActionType.CardHashtagUpdate:
            hashtagUpdate(cardResponse, card);
            break;
        case CardActionType.CardHistoryAdd:
            historyAdd(cardResponse, card);
            break;
        case CardActionType.ChildCardAdd:
            childCardAdd(cardResponse, card, store);
            break;
        case CardActionType.ChildCardCountUpdate:
            childCardCountUpdate(cardResponse, card);
            break;
        case CardActionType.ChildCardSetOrder:
            childCardSetOrder(cardResponse, card);
            break;
    }

    return card;

}

export function cardDelete(cardResponse: BoardResponse<Card>, card: Card) {

    cardResponse.data.map(_card => {

        if (_card.cardId === card.cardId) {

            card = null;

            return;

        }

        if (_card.parentCardId) {

            const subCardIndex = card.childCards.findIndex(c => c.cardId === _card.cardId);

            if (subCardIndex === -1) {
                return;
            }

            card.childCards.splice(subCardIndex, 1);
        }

    });

}

export function nameUpdate(cardResponse: BoardResponse<Card>, card: Card) {

    cardResponse.data.map(_card => {

        if (_card.cardId === card.cardId) {

            card.name = _card.name;

            return;

        }

        if (_card.cardId === card.parentCardId) {

            card.parentCard.name = _card.name;

        }

        if (_card.parentCardId) {

            const subCard = card.childCards.find(c => c.cardId === _card.cardId);

            subCard.name = _card.name;

        }

    });

}

export function setOrder(cardResponse: BoardResponse<Card>, card: Card) {

    cardResponse.data.map(_card => {

        if (_card.parentCardId) {

            const subCard = card.childCards.find(c => c.cardId === _card.cardId);

            if (subCard) {

                subCard.orderBy = _card.orderBy;

            }
        }

        if (card.boardInfoId === _card.boardInfoId) {

            card.boardInfo.name = _card.boardInfo.name;

        }

        card.childCards.sort((a, b) => a.orderBy > b.orderBy ? 1 : -1);

    });
}

export function sectionChange(cardResponse: BoardResponse<Card>, card: Card) {

    cardResponse.data.map(_card => {

        card.boardInfo = { ..._card.boardInfo };

        card.boardInfoId = _card.boardInfoId;

    });
}

export function childCardAdd(cardResponse: BoardResponse<Card>, card: Card, store: Store) {
    store.dispatch(new GetBoardDetailFormDialogCardActionType(CardActionType.ChildCardAdd));

    cardResponse.data.map(_card => {

        if (_card.parentCardId === card.cardId) {
            card.childCards.splice(card.childCards.length - 1, 0, _card);
            card.childCards = card.childCards.filter(_subcard => _subcard.cardId || _subcard.name.length === 0);

            store.dispatch(new GetBoardDetailFormDialogCardChildCard(card.childCards));
        }

    });
}

export function childCardCountUpdate(cardResponse: BoardResponse<Card>, card: Card) {

    cardResponse.data.map(_card => {

        if (_card.cardId === card.cardId) {

            card.childCardsCount = _card.childCardsCount;

        }

    });

}

export function childCardSetOrder(cardResponse: BoardResponse<Card>, card: Card) {

    cardResponse.data.map(_card => {

        if (_card.parentCardId === card.cardId) {
            const subCard = card.childCards.find(c => c.cardId === _card.cardId);
            subCard.orderBy = _card.orderBy;
        }

    });

    card.childCards.sort((a, b) => a.orderBy > b.orderBy ? 1 : -1);
}

export function dueDateUpdate(cardResponse: BoardResponse<Card>, card: Card) {

    cardResponse.data.map(_card => {

        if (_card.cardId === card.cardId) {

            card.dueDate = _card.dueDate;
            card.startDate = _card.startDate;

            return;

        }

        if (_card.parentCardId) {

            const subCard = card.childCards.find(c => c.cardId === _card.cardId);

            subCard.dueDate = _card.dueDate;
            subCard.startDate = _card.startDate;
        }

    });
}

export function reminderDateUpdate(cardResponse: BoardResponse<Card>, card: Card, store: Store) {

    cardResponse.data.map(_card => {

        if (_card.cardId === card.cardId) {

            card.reminderDate = _card.reminderDate;

            store.dispatch(new GetBoardDetailFormDialogCardReminderDate(_card.reminderDate));

            return;

        }

        if (_card.parentCardId) {

            const subCard = card.childCards.find(c => c.cardId === _card.cardId);

            subCard.reminderDate = _card.reminderDate;

            store.dispatch(new GetBoardDetailFormDialogCardReminderDate(_card.reminderDate));
        }

    });
}

export function setComplete(cardResponse: BoardResponse<Card>, card: Card, store: Store) {
    store.dispatch(new GetBoardDetailFormDialogCardActionType(CardActionType.CardSetComplete));

    cardResponse.data.map(_card => {

        if (_card.cardId === card.cardId) {

            card.cardStatus = _card.cardStatus;
            card.cardStatusId = _card.cardStatusId;

            return;

        }

        if (_card.parentCardId) {

            const subCard = card.childCards.find(c => c.cardId === _card.cardId);

            subCard.cardStatus = _card.cardStatus;
            subCard.cardStatusId = _card.cardStatusId;

            store.dispatch(new GetBoardDetailFormDialogCardChildCard(card.childCards));
        }

    });
}

export function hashtagUpdate(cardResponse: BoardResponse<Card>, card: Card) {

    cardResponse.data.map(_card => {

        if (_card.cardId === card.cardId) {
            card.cardHashtags = _card.cardHashtags;
            return;

        }

        if (_card.parentCardId) {

            const subCard = card.childCards.find(c => c.cardId === _card.cardId);

            subCard.cardHashtags = _card.cardHashtags;

        }

    });

}

export function assigneeUserUpdate(cardResponse: BoardResponse<Card>, card: Card) {

    cardResponse.data.map(_card => {

        if (_card.cardId === card.cardId) {

            card.cardSystemUsers = _card.cardSystemUsers;

            return;

        }

        if (_card.parentCardId) {

            const subCard = card.childCards.find(c => c.cardId === _card.cardId);

            subCard.cardSystemUsers = _card.cardSystemUsers;
        }

    });

}

export function descriptionUpdate(cardResponse: BoardResponse<Card>, card: Card, store: Store) {

    cardResponse.data.map(_card => {

        if (_card.cardId === card.cardId) {

            store.dispatch(new GetBoardDetailFormDialogCardDescription(_card.description));

            // card.description = getDescription(_card.description);
            // card.description = _card.description;

            return;

        }

        if (_card.parentCardId) {

            const subCard = card.childCards.find(c => c.cardId === _card.cardId);

            subCard.description = getDescription(_card.description);
            store.dispatch(new GetBoardDetailFormDialogCardDescription(_card.description));
            // subCard.description = _card.description;

        }

    });

}

export function historyAdd(cardResponse: BoardResponse<Card>, card: Card) {

    cardResponse.data.map(_card => {

        if (_card.cardId === card.cardId) {

            card.cardCommunications = _card.cardCommunications;

            return;

        }

        if (_card.parentCardId) {

            const subCard = card.childCards.find(c => c.cardId === _card.cardId);

            subCard.cardCommunications = _card.cardCommunications;
        }

    });

}

export function commentAdd(cardResponse: BoardResponse<Card>, card: Card, store: Store) {

    cardResponse.data.map(_card => {

        if (_card.cardId === card.cardId) {

            addCommentToCard(card, _card.cardComments);

            card.commentCount = _card.commentCount;

            store.dispatch(new GetBoardDetailFormDialogCardComment({
                cardComments: card.cardComments,
                commentCount: card.commentCount
            }));

            return;

        }

        if (_card.parentCardId) {

            const subCard = card.childCards.find(c => c.cardId === _card.cardId);

            addCommentToCard(subCard, _card.cardComments);

        }


    });

}

export function commentDelete(cardResponse: BoardResponse<Card>, card: Card, store: Store) {

    cardResponse.data.map(_card => {

        if (_card.cardId === card.cardId) {

            deleteCommentFromCard(card, _card.cardComments);

            card.commentCount = _card.commentCount;

            store.dispatch(new GetBoardDetailFormDialogCardComment({
                cardComments: card.cardComments,
                commentCount: card.commentCount
            }));

            return;

        }

        if (_card.parentCardId) {

            const subCard = card.childCards.find(c => c.cardId === _card.cardId);
            deleteCommentFromCard(subCard, _card.cardComments);

        }

    });

}

export function commentUpdate(cardResponse: BoardResponse<Card>, card: Card, store: Store) {

    cardResponse.data.map(_card => {

        if (_card.cardId === card.cardId) {

            updateCommentToCard(card, _card.cardComments);

            store.dispatch(new GetBoardDetailFormDialogCardComment({
                cardComments: card.cardComments,
                commentCount: card.commentCount
            }));

            return;

        }

        if (_card.parentCardId) {

            const subCard = card.childCards.find(c => c.cardId === _card.cardId);
            updateCommentToCard(subCard, _card.cardComments);


        }

    });

}

export function getAllComments(cardResponse: BoardResponse<Card>, card: Card, store: Store) {

    cardResponse.data.map(_card => {



        if (_card.cardId === card.cardId) {

            card.cardComments = _card.cardComments;
            store.dispatch(new GetBoardDetailFormDialogCardComment({
                cardComments: card.cardComments,
                commentCount: card.commentCount
            }));

            return;

        }

        if (_card.parentCardId) {

            const subCard = card.childCards.find(c => c.cardId === _card.cardId);
            subCard.cardComments = card.cardComments;


        }


    });

}

export function crmLinksUpdate(cardResponse: BoardResponse<Card>, card: Card, store: Store) {

    cardResponse.data.map(_card => {

        if (_card.cardId === card.cardId) {

            card.cardObjects = _card.cardObjects;

            store.dispatch(new GetBoardDetailFormDialogCardObject(_card.cardObjects));

            return;

        }

        if (_card.parentCardId) {

            const subCard = card.childCards.find(c => c.cardId === _card.cardId);

            subCard.cardObjects = _card.cardObjects;

            store.dispatch(new GetBoardDetailFormDialogCardObject(_card.cardObjects));

        }

    });

}

export function addCommentToCard(card: Card, comments: CardComment[]) {

    comments.map(comment => {

        if (!card.cardComments) {

            card.cardComments = [];

        }

        card.cardComments.push(comment);
        card.commentCount++;
    });

}

export function updateCommentToCard(card: Card, comments: CardComment[]) {

    comments.map(comment => {

        const commentIndex = card.cardComments.findIndex(_comment => _comment.cardCommentId === comment.cardCommentId);

        if (commentIndex === -1) {
            return;
        }

        card.cardComments[commentIndex] = comment;

    });

}

export function deleteCommentFromCard(card: Card, comments: CardComment[]) {

    comments.map(comment => {

        const commentIndex = card.cardComments.findIndex(_comment => _comment.cardCommentId === comment.cardCommentId);

        if (commentIndex === -1) {
            return;
        }

        card.cardComments.splice(commentIndex, 1);

    });

}

export function linkAdd(cardResponse: BoardResponse<Card>, card: Card) {

    cardResponse.data.map(_card => {

        if (_card.cardId === card.cardId) {

            card.cardLinks = _card.cardLinks;

        }

        if (_card.parentCardId) {

            const subCard = card.childCards.find(c => c.cardId === _card.cardId);


            subCard.cardLinks = _card.cardLinks;

        }

    });
}

export function linkDelete(cardResponse: BoardResponse<Card>, card: Card) {

    cardResponse.data.map(_card => {

        if (_card.cardId === card.cardId) {

            card.cardLinks = _card.cardLinks;

        }

        if (_card.parentCardId) {

            const subCard = card.childCards.find(c => c.cardId === _card.cardId);


            subCard.cardLinks = _card.cardLinks;

        }

    });

}

export function attachmentAdd(cardResponse: BoardResponse<Card>, card: Card, store: Store) {

    cardResponse.data.map(_card => {

        if (_card.cardId === card.cardId) {

            _card.cardAttachments.map(attachment => {

                card.cardAttachments.push(attachment);
                store.dispatch(new GetBoardDetailFormDialogCardAttachment(card.cardAttachments));

            });

            return;
        }

        if (_card.parentCardId) {

            const subCard = card.childCards.find(c => c.cardId === _card.cardId);


            subCard.cardAttachments.map(attachment => {

                card.cardAttachments.push(attachment);
                store.dispatch(new GetBoardDetailFormDialogCardAttachment(card.cardAttachments));

            });

        }

    });
}

export function attachmentDelete(cardResponse: BoardResponse<Card>, card: Card, store: Store) {

    cardResponse.data.map(_card => {

        if (_card.cardId === card.cardId) {

            _card.cardAttachments.map(_cardAttachment => {

                const attachIndex = card.cardAttachments.findIndex(cardAttachment => cardAttachment.cardAttachmentId === _cardAttachment.cardAttachmentId);

                if (attachIndex !== -1) {

                    card.cardAttachments.splice(attachIndex, 1);
                    store.dispatch(new GetBoardDetailFormDialogCardAttachment(card.cardAttachments));
                }
            });

        }

        if (_card.parentCardId) {

            const subCard = card.childCards.find(c => c.cardId === _card.cardId);

            subCard.cardAttachments.map(_cardAttachment => {

                const attachIndex = card.cardAttachments.findIndex(cardAttachment => cardAttachment.cardAttachmentId === _cardAttachment.cardAttachmentId);

                if (attachIndex !== -1) {

                    subCard.cardAttachments.splice(attachIndex, 1);
                    store.dispatch(new GetBoardDetailFormDialogCardAttachment(card.cardAttachments));
                }
            });

        }

    });

}

export function updateDynamicFields(cardResponse: BoardResponse<Card>, card: Card, store: Store) {

    cardResponse.data.map(_card => {

        if (_card.cardId === card.cardId) {

            // card.dynamicFieldValues = _card.dynamicFieldValues;

            store.dispatch(new GetBoardDetailFormDialogCardDynamicField(_card.dynamicFieldValues));

        }

        if (_card.parentCardId) {

            const subCard = card.childCards.find(c => c.cardId === _card.cardId);
            store.dispatch(new GetBoardDetailFormDialogCardDynamicField(_card.dynamicFieldValues));

        }

    });

}

export function convertToParentCard(cardResponse: BoardResponse<Card>, card: Card, store: Store) {

    if (cardResponse.cardId === card.cardId) {

        store.dispatch(new SetBoardDetailFormDialog({ cardId: card.cardId, boardId: card.boardInfo.boardId }));

        return;
    }

    const subCardIndex = card.childCards.findIndex(c => c.cardId === cardResponse.cardId);

    if (subCardIndex !== -1) {

        card.childCards.splice(subCardIndex, 1);
    }
}

export function convertToSubcard(cardResponse: BoardResponse<Card>, card: Card, store: Store) {

    cardResponse.data.map(_card => {

        if (cardResponse.cardId === card.cardId) {
            store.dispatch(new SetBoardDetailFormDialog({ cardId: card.cardId, boardId: card.boardInfo.boardId }));

            return;
        }

    });

}

export function getDescription(description: string) {
    const descriptionElement = document.createElement('div');
    descriptionElement.innerHTML = description;

    if (!descriptionElement.querySelectorAll('img') || Array.from(descriptionElement.querySelectorAll('img')).length === 0) {
        return description;
    }

    Array.from(descriptionElement.querySelectorAll('img')).forEach(image => {
        image.src = '';
        return descriptionElement.innerHTML;
    });

    return descriptionElement.innerHTML;
}
