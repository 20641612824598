import { DynamicField } from '@core/api';
import { CustomListActionTypes, CustomListActions } from './custom-list.actions';

export interface CustomListState {
    fields: DynamicField[];
    isLoading: boolean;
    errorMessage: string;
    errorCode: string;
}

export const customListState: CustomListState = {
    fields: null,
    isLoading: false,
    errorMessage: null,
    errorCode: null,
};

export function customListReducer(state = customListState, action: CustomListActions): CustomListState {
    switch (action.type) {
        case CustomListActionTypes.GET_CUSTOM_LIST:
            return {
                ...state,
                errorMessage: null,
                isLoading: true
            };
        case CustomListActionTypes.GET_CUSTOM_LIST_SUCCESS:
            const s = {
                ...state,
                fields: action.payload,
                errorMessage: null,
                errorCode: null,
                isLoading: false,
            };
            return s;
        case CustomListActionTypes.GET_CUSTOM_LIST_ERROR:
            return {
                ...state,
                isLoading: false,
                errorCode: action.payload.errorCode,
                errorMessage: action.payload.message
            };
        default:
            return state;
    }
}
