import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

import { SystemUserProperty, Response, UpdateDashboardFilterPropertyRequest } from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class SystemUserPropertyService {

  constructor(private http: HttpClient) { }

  updateDashboardFilter(request?: UpdateDashboardFilterPropertyRequest): Observable<Response<SystemUserProperty>> {
    return this.http.post<Response<SystemUserProperty>>(environment.apiUrl + '/api/SystemUserProperty/UpdateDashboardFilter', request);
  }

  get(systemUserId: string): Observable<Response<SystemUserProperty>> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<SystemUserProperty>>(environment.apiUrl + '/api/SystemUserProperty/Get', JSON.stringify(systemUserId), { headers });
  }
}
