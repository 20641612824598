import { UntypedFormGroup } from '@angular/forms';
import { DynamicField, DynamicFieldPicklist } from '@core/api';
import { resolveDynamicFieldConditions } from './input-dynamic-field-values-condition.helper';

export function resolveDynamicFieldPickListConditions(
    field: DynamicField,
    form: UntypedFormGroup,
    fields: DynamicField[],
    accountStatusId,
    activityStatusId,
    assignmentStatusId,
    opportunityStatusId,
    opportunityTypeId,
    organizationId,
    salesOrganizationId,
    serviceIds
): DynamicFieldPicklist[] {

    if (!field.dynamicFieldPickListConditions || field.dynamicFieldPickListConditions.length === 0) {
        return field.picklistValues?.map((picklist) => {
            return {
                ...picklist,
                conditionVisibility: true
            };
        }) ?? [];
    }

    if (!field.picklistValues || field.picklistValues.length === 0) {
        return;
    }

    const picklistConditions = resolveDynamicFieldConditions(
        field,
        form,
        fields,
        accountStatusId,
        activityStatusId,
        assignmentStatusId,
        opportunityStatusId,
        opportunityTypeId,
        organizationId,
        salesOrganizationId,
        serviceIds,
        'dynamicFieldPickListConditions',
        true
    );

    const conditionalValues = [];

    field.dynamicFieldPickListConditions.forEach((dynamicFieldPickList, index) => {
        dynamicFieldPickList.dynamicFieldPickListConditions.forEach((condition) => {
            conditionalValues.push(condition.dynamicFieldPickListId);
        });
    });

    if (picklistConditions.every((condition) => condition === false)) {
        return field.picklistValues.map((picklist) => {
            picklist.conditionVisibility = !conditionalValues.includes(picklist.dynamicFieldPickListId);
            return picklist;
        });
    }

    if (picklistConditions.some((condition) => condition === true)) {
        field.picklistValues = field.picklistValues.map((picklist) => {
            return {
                ...picklist,
                conditionVisibility: false
            };
        });
    }

    picklistConditions.forEach((condition, index) => {
        const cond = field.dynamicFieldPickListConditions[index];

        field.picklistValues = field.picklistValues.map((picklist) => {
            if (cond.dynamicFieldPickListConditions.map(x => x.dynamicFieldPickListId).includes(picklist.dynamicFieldPickListId)) {
                picklist.conditionVisibility = condition;
            }

            return picklist;
        });
    });

    return field.picklistValues;
}
