import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import { Observable } from 'rxjs';
import { FuelCounty, PagedResponse, FuelCountyFilterRequest } from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class FuelCountyService {
  constructor(private http: HttpClient) { }

  search(request: FuelCountyFilterRequest): Observable<PagedResponse<FuelCounty>> {
    return this.http.post<PagedResponse<FuelCounty>>(environment.apiUrl + '/api/FuelCounty/Search', request);
  }
}
