import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ListviewDocumentDownloadSearchRequest } from './listview-document-download.model';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { PagedResponse } from '../api.model';

@Injectable({ providedIn: 'root' })
export class ListviewDocumentDownloadService {

    constructor(private http: HttpClient) { }

    search(request: ListviewDocumentDownloadSearchRequest): Observable<PagedResponse<any>> {
        return this.http.post<PagedResponse<any>>(environment.apiUrl + '/api/ListviewDocumentDownload/Search', request);
    }

}
