import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { ToastType } from './toast.service.enum';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private toastr: ToastrService, private translate: TranslateService) { }

  show(message: string, toastType: ToastType) {
    if (!toastType) {
      toastType = ToastType.INFO;
    }

    switch (toastType) {
      case ToastType.INFO: this.info(message); break;
      case ToastType.ERROR: this.error(message); break;
      case ToastType.WARNING: this.warn(message); break;
      case ToastType.SUCCESS: this.success(message); break;
    }
  }

  error(message: string) {
    this.toastr.error(message, this.translate.instant('GENERAL.ERROR'), {
      closeButton: true,
      disableTimeOut: true
    });
  }

  warn(message: string) {
    this.toastr.warning(message, this.translate.instant('GENERAL.WARNING'), {
      closeButton: true,
      disableTimeOut: true
    });
  }

  success(message: string) {
    this.toastr.success(message, this.translate.instant('GENERAL.SUCCESS'), {
      timeOut: 5000,
      closeButton: true
    });
  }

  info(message: string) {
    this.toastr.info(message, this.translate.instant('GENERAL.INFO'), {
      timeOut: 5000,
      closeButton: true
    });
  }
}
