import { AbstractControl, ValidationErrors } from '@angular/forms';

import { SelectActivityPurpose } from './select-activity-purpose.component.model';

export class SelectActivityPurposeValidator {
  static validator(control: AbstractControl): ValidationErrors | null {
    const value: SelectActivityPurpose = control.value;

    if (!value) {
      return null;
    }

    if (!value.activityPurpose) {
      return { required: true };
    }

    if (!value.activityPurpose.isActive) {
      return { invalidPurpose: true };
    }

    return null;
  }
}
