import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { PagedResponse } from '../api.model';
import { RevenueType, RevenueTypeListRequest } from '@core/api';

@Injectable({
    providedIn: 'root'
})
export class RevenueTypeService {

    constructor(private http: HttpClient) { }

    list(request: RevenueTypeListRequest) {
        return this.http.post<PagedResponse<RevenueType>>(environment.apiUrl + '/api/RevenueType/List', request);
    }

}
