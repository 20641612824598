<mat-select [tabIndex]="tabIndex" [formControl]="selectCtrl" [compareWith]="compareWith"
  [matTooltip]="'GENERAL.PLEASE_SELECT_A_CUSTOMER' | translate" [matTooltipDisabled]="!!this.account"
  (selectionChange)="onSelectionChange()">
  <mat-option class="accordion-option" *ngFor="let activity of activities" [value]="activity">
    <div>
      {{activity.code }} - {{ (status.PLANNED === activity.activityStatusId ? activity.planningStartDate :
      activity.actualStartDate) | momentFormat:'DD MMMM YYYY' }}
    </div>
    <div class="description">
      {{ ('ActivityType.' +activity.activityType?.name) | translate }} - {{ ('ActivityStatus.' +
      activity.activityStatus?.name) | translate }}
    </div>
  </mat-option>
</mat-select>

<mat-progress-spinner *ngIf="loading === true" diameter="16" mode="indeterminate"></mat-progress-spinner>