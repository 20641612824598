import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { Response } from '../api.model';
import { OpportunityPriceListVolumeType } from './opportunity-price-list-volume-type.model';

@Injectable({
    providedIn: 'root'
})
export class OpportunityPriceListVolumeTypeService {
    constructor(
        private http: HttpClient
    ) { }

    list(): Observable<Response<OpportunityPriceListVolumeType[]>> {
        return this.http.post<Response<OpportunityPriceListVolumeType[]>>(environment.apiUrl + '/api/OpportunityPriceListVolumeType/List', {});
    }
}
