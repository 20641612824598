import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'includes'
})
export class IncludesPipe implements PipeTransform {

  transform(value: any, search: any, valueKey?: string, searchKey?: string): boolean {
    if (!value || !search) {
      return false;
    }

    if (Array.isArray(value)) {
      if (Array.isArray(search)) {
        if (valueKey && searchKey) {
          // If both value and search are arrays and both keys are provided
          return search.every(searchItem =>
            value.some(valueItem => this.getNestedValue(valueItem, valueKey) === this.getNestedValue(searchItem, searchKey))
          );
        } else if (valueKey || searchKey) {
          throw new Error('Both valueKey and searchKey must be provided if either is used.');
        } else {
          // If both value and search are arrays and no keys are provided
          return search.every(searchItem => value.includes(searchItem));
        }
      } else {
        if (valueKey && searchKey) {
          // If value is an array, search is an object, and both keys are provided
          return value.some(valueItem => this.getNestedValue(valueItem, valueKey) === this.getNestedValue(search, searchKey));
        } else if (valueKey || searchKey) {
          throw new Error('Both valueKey and searchKey must be provided if either is used.');
        } else {
          // If value is an array, search is a primitive value
          return value.includes(search);
        }
      }
    } else if (typeof value === 'string') {
      // If value is a string, use the includes function
      return typeof search === 'string' && value.includes(search);
    }

    return false;
  }

  private getNestedValue(obj: object, key: string): any {
    return key.split('.').reduce((o, k) => (o && o[k] !== undefined) ? o[k] : undefined, obj);
  }
}
